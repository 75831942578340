import React, { useMemo } from 'react'
import { Form, useFormikContext } from 'formik'
import { set } from 'date-fns'

import { usePartialMyIDUsersQuery } from 'api/workflow/partialMyIDUsers'
import { AssignmentConfiguration } from 'model/workflow/AssignmentConfiguration'
import { TaskTypeOption, useSourceOptionsForSelectedTitles } from 'model/workflow/TaskType'
import { WorkflowPriorityOptions } from 'model/workflow/WorkflowPriority'
import CellLabel from 'shared/components/CellLabel'
import CheckboxInput from 'shared/components/CheckboxRadioInput/Checkbox'
import Container from 'shared/components/Container'
import DatePicker from 'shared/components/DatePicker'
import ErrorMessage from 'shared/components/ErrorMessage'
import FormikSelect from 'shared/components/Select/FormikSelect'
import Text from 'shared/components/Text'
import Autosave from 'shared/form/Autosave'
import styled from 'shared/theme'
import { useInterpreter } from '../CreateAssignmentProvider'
import AssigneeSelect, {
    AssigneeTooltip,
    ASSIGNEE_TOOLTIP_TEXT,
    getAsigneeOptions,
    getTasksOptions,
} from './AssigneeSelect'

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 8px;
`

type GlobalConfigurationFormNodeProps = {
    taskTypeOptions: TaskTypeOption[]
}
const GlobalConfigurationFormNode: React.FC<GlobalConfigurationFormNodeProps> = ({
    taskTypeOptions,
}) => {
    const service = useInterpreter()
    const { values, setFieldValue } = useFormikContext<AssignmentConfiguration>()

    const { isLoading: isSourceTypeOptionsLoading, data: sourceTypeOptions = [] } =
        useSourceOptionsForSelectedTitles()

    const partialUsersQuery = usePartialMyIDUsersQuery()

    const assigneeOptions = useMemo(() => {
        return getAsigneeOptions(partialUsersQuery.data, values.tasks)
    }, [partialUsersQuery.data, values.tasks])

    const tasksOpts = useMemo(() => {
        return getTasksOptions(taskTypeOptions, assigneeOptions, values.assignee)
    }, [taskTypeOptions, assigneeOptions, values.assignee])

    return (
        <Container flexDirection="column" style={{ margin: '40px' }}>
            <Form>
                <Text as="h1">Global Assignment Configuration</Text>

                <Autosave<AssignmentConfiguration>
                    onChange={globalConfiguration =>
                        service.send({ type: 'GLOBAL_CONFIGURATION_UPDATE', globalConfiguration })
                    }
                />

                <br />

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Tasks
                        <AssigneeTooltip
                            text={ASSIGNEE_TOOLTIP_TEXT}
                            id="tasks-selection-tooltip"
                        />
                    </CellLabel>

                    <FormikSelect
                        id="global-configuration-from-node-tasks"
                        name="tasks"
                        isMulti
                        options={tasksOpts}
                        value={taskTypeOptions.filter(tto => values.tasks.includes(tto.value))}
                        onChange={
                            ((value: { value: string }[]) => {
                                const values = value ? value.map(v => v.value) : []
                                setFieldValue('tasks', values)
                            }) as any
                        }
                    />
                    <ErrorMessage name="tasks" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Assignee
                    </CellLabel>

                    <AssigneeSelect
                        id="global-configuration-from-node-assignee"
                        name="assignee"
                        tasks={values.tasks}
                        options={assigneeOptions}
                        isLoading={partialUsersQuery.isLoading}
                    />
                    <ErrorMessage name="assignee" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Priority
                    </CellLabel>

                    <FormikSelect
                        id="global-configuration-from-node-priority"
                        name="priority"
                        isClearable={true}
                        options={WorkflowPriorityOptions}
                        value={WorkflowPriorityOptions.find(po => po.value === values.priority)}
                        onChange={
                            ((value: { value: string } | null) =>
                                setFieldValue('priority', value ? value.value : null)) as any
                        }
                        placeholder="Default priority"
                    />
                    <ErrorMessage name="priority" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Due Date
                    </CellLabel>

                    <DatePicker
                        id="global-configuration-from-node-deadline-at"
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select..."
                        selected={values.deadlineAt ? new Date(values.deadlineAt) : undefined}
                        minDate={new Date()}
                        onChange={date =>
                            setFieldValue(
                                'deadlineAt',
                                date
                                    ? set(date as Date, { hours: 0, minutes: 0, seconds: 0 })
                                    : undefined,
                            )
                        }
                    />
                    <ErrorMessage name="deadlineAt" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Source
                    </CellLabel>

                    <FormikSelect
                        id="global-configuration-from-node-source"
                        name="source"
                        isClearable={true}
                        options={sourceTypeOptions}
                        isLoading={isSourceTypeOptionsLoading}
                        placeholder="Select Source"
                        value={sourceTypeOptions.find(so => so.value === values.source)}
                        onChange={
                            ((value: { value: string } | null) =>
                                setFieldValue('source', value ? value.value : null)) as any
                        }
                    />
                    <ErrorMessage name="source" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Needs Review
                    </CellLabel>

                    <CheckboxInput
                        id="global-configuration-from-node-review-required"
                        name="reviewRequired"
                        checked={values.reviewRequired}
                    />
                    <ErrorMessage name="reviewRequired" />
                </InputWrapper>
            </Form>
        </Container>
    )
}

export default GlobalConfigurationFormNode
