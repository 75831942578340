import * as t from 'io-ts'
import * as y from 'yup'
import nullable from 'codecs/util/nullable'

export const AttributeValueCodec = t.type({
    attributeId: t.string,
    attributeLabel: t.string,
})
export type AttributeValue = t.TypeOf<typeof AttributeValueCodec>

export const AttributeCodec = t.intersection([
    t.type({
        attributeId: t.string,
        attributeLabel: t.string,
        attributeType: t.string,
        attributeTypeName: nullable(t.string),
    }),
    t.partial({
        broaderId: t.string,
        suggestionScore: nullable(t.number),
    }),
])
export type Attribute = t.TypeOf<typeof AttributeCodec>
export const attribute = (label: string): Attribute => {
    return {
        attributeLabel: label,
        attributeId: `www.example.com/${label}`,
        attributeType: `www.example.com/type/${label}`,
        attributeTypeName: `type-${label}`,
        suggestionScore: null,
        broaderId: 'exampleId',
    }
}

/////////////////////////////////////////////
// YUP VALIDATION
/////////////////////////////////////////////

export const AttributeValueFormSchema = y
    .object({
        attributeId: y.string().required('Required'),
        attributeLabel: y.string().required('Required'),
    })
    .defined()
