import type {
    CreateWorkflowRequest_CREATIVE_WORK_TAGGING_Input,
    Workflow,
} from '@genome-web-forms/server'
import { assign } from 'lodash'
import { AssignmentConfiguration } from './AssignmentConfiguration'
import { getValidTaskTypesPredicate } from './TaskType'

export type AssignmentTitle = {
    resourceId: string
    resourceTitle: string
    cwmClassType: string
    cwmClassTypeLabel: string
    radarId: number
    publishedDate: string | null
    isPublished: boolean | null
    workflows: false | Workflow[]
    hasDescriptors: boolean | null
    hasRelationships: boolean | null
    hasStorylines: boolean | null
}

export const mergeAssignmentTitleConfiguration = (
    title: AssignmentTitle,
    titleConfiguration: Partial<AssignmentConfiguration>,
    globalConfiguration: AssignmentConfiguration,
): AssignmentConfiguration => {
    const tasks = (
        titleConfiguration.tasks?.length ? titleConfiguration.tasks : globalConfiguration.tasks
    ).filter(getValidTaskTypesPredicate(title))

    return {
        ...globalConfiguration,
        ...titleConfiguration,
        tasks,
    }
}

export const createRequestsFromTitleAndConfiguration = ({
    title,
    mergedConfiguration,
    editingConfiguration = false,
}: {
    title: AssignmentTitle
    mergedConfiguration: AssignmentConfiguration
    editingConfiguration?: false | Partial<AssignmentConfiguration>
}): CreateWorkflowRequest_CREATIVE_WORK_TAGGING_Input[] => {
    const requests: CreateWorkflowRequest_CREATIVE_WORK_TAGGING_Input[] = []

    const tasks =
        (editingConfiguration && 'tasks' in editingConfiguration
            ? editingConfiguration.tasks
            : mergedConfiguration.tasks) || []

    for (const task of tasks) {
        const config =
            editingConfiguration !== false
                ? assign(mergedConfiguration, editingConfiguration)
                : mergedConfiguration

        const request: CreateWorkflowRequest_CREATIVE_WORK_TAGGING_Input = {
            workflowType: 'CREATIVE_WORK_TAGGING',
            ...title,
            workflowConfig: {
                task,
                reviewRequired: editingConfiguration
                    ? !!editingConfiguration?.reviewRequired
                    : mergedConfiguration.reviewRequired,
            },
            ...config,
        }
        requests.push(request)
    }
    return requests
}
