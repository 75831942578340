import { isArray, keyBy } from 'lodash'
import * as t from 'io-ts'
import config from 'shared/config'
import { request } from '@genome-web-forms/common/api'

import { displayGenresRaw } from '__api_mocks__/trs_mocks'
import { title_types } from '__api_mocks__/title_types'
import { TaxonomyWithCategories } from 'codecs/TRS/Taxonomy'
import { useQuery, UseQueryResult } from 'react-query'
import { authCWR } from 'api/auth'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { useUser } from 'auth/Auth'
import { useTaggedTaxonomyLabels } from 'shared/hooks/useTRS'
import { useMemo } from 'react'

import { compare } from 'natural-orderby'
import { getDescriptorsVisibleLabel } from 'shared/components/GroupSelect'
import { CATEGORY_SEPARATOR } from 'pages/Search/ExtendedBasicSearch'
import {
    SEARCH_DESCRIPTORS_KEY,
    SEARCH_LOCATION_KEY,
    SEARCH_PRODUCT_ID_KEY,
    SEARCH_PUBLISHED_DATE_KEY,
    SEARCH_RELEASE_DATE_KEY,
    SEARCH_TEXT_KEY,
    SEARCH_TYPE_KEY,
} from 'shared/searchConstants'

const compareFn = compare()

const DISPLAY_TYPES: Array<FacetOption> = title_types as any
const DISPLAY_GENRES: Array<{
    displayGenreId: string
    displayGenreLabel: string
}> = displayGenresRaw as any

type TaggingAvailableAttributeLabel =
    | 'Age Groups'
    | 'Agents'
    | 'Disciplines & Industries'
    | 'Display Genre'
    | 'Gender'
    | 'Genre'
    | 'Generations'
    | 'Holidays & Seasons'
    | 'Locations'
    | 'Moods'
    | 'Other Genre'
    | 'Scopes and Scales'
    | 'Setting Types'
    | 'Source Material'
    | 'Story Elements'
    | 'Subjects'
    | 'Time Era'
    | 'Creative Elements'

export type TaggingAvailableAttributeKey =
    | 'agent'
    | 'discipline'
    | 'generation'
    | 'genreDisplay'
    | 'genreOther'
    | 'hasDisplayGenre'
    | 'holidaySeason'
    | 'leadAgeGroup'
    | 'leadGender'
    | 'mood'
    | 'scopeScale'
    | 'setIn'
    | 'setting'
    | 'sourceMaterial'
    | 'storyArchetype'
    | 'subjects'
    | 'timeEra'
    | 'creativeElements'

export const HAS_ATTRIBUTE_LABEL_BY_KEY: {
    [k in TaggingAvailableAttributeKey]: TaggingAvailableAttributeLabel
} = {
    agent: 'Agents',
    discipline: 'Disciplines & Industries',
    generation: 'Generations',
    genreDisplay: 'Display Genre',
    genreOther: 'Other Genre',
    hasDisplayGenre: 'Genre',
    holidaySeason: 'Holidays & Seasons',
    leadAgeGroup: 'Age Groups',
    leadGender: 'Gender',
    mood: 'Moods',
    scopeScale: 'Scopes and Scales',
    setting: 'Setting Types',
    sourceMaterial: 'Source Material',
    storyArchetype: 'Story Elements',
    subjects: 'Subjects',
    timeEra: 'Time Era',
    setIn: 'Locations',
    creativeElements: 'Creative Elements',
} as const

type CategoryOption = {
    label: string
    value: string
    categoryLabel: string
    type: string
    labelValue: string
}

export type SupportedQueryParamFacet = 'dueDate'

export type SupportedFacetGroup = 'dates' | 'genres' | 'descriptors'

export type SupportedFacet =
    | typeof SEARCH_TEXT_KEY
    | typeof SEARCH_PRODUCT_ID_KEY
    | typeof SEARCH_TYPE_KEY
    | typeof SEARCH_DESCRIPTORS_KEY
    | typeof SEARCH_RELEASE_DATE_KEY
    | typeof SEARCH_PUBLISHED_DATE_KEY
    | typeof SEARCH_LOCATION_KEY

export type SupportedFacetOption = { label: string; value: SupportedFacet }

export type SupportedFacetComponentType =
    | 'single-select'
    | 'multiple-select'
    | 'grouped-select'
    | 'grouped-date-range'
    | 'location-select'
    | 'text-input'

export type UngroupedFacetOption = { label: string; value: string; categoryLabel?: string }
export type GroupedFacetOption = {
    label: string
    options: UngroupedFacetOption[]
    categoryLabel?: string
}

export type FacetOption = UngroupedFacetOption | GroupedFacetOption

export type FacetSelect = {
    label: string
    placeholder?: string
    facet: SupportedFacet | SupportedFacetGroup
    componentType: SupportedFacetComponentType
    categoriesIncluded?: string[]
    categoriesExcluded?: string[]
    locked: boolean
    isMulti: boolean
    width: string
}

export const SUPPORTED_FACETS_OPTIONS: SupportedFacetOption[] = [
    { label: 'Type', value: 'www_w3_org_1999_02_22-rdf-syntax-ns.type' },
]

export const ALL_AVAILABLE_FACETS: FacetSelect[] = [
    {
        label: 'Type',
        facet: 'www_w3_org_1999_02_22-rdf-syntax-ns.type',
        componentType: 'single-select',
        locked: true,
        isMulti: false,
        width: '250px',
    },
    {
        label: 'Genres',
        facet: 'genres',
        componentType: 'grouped-select',
        categoriesIncluded: ['genre', 'genre other'],
        locked: true,
        isMulti: true,
        width: '400px',
    },
    {
        label: 'Descriptors',
        facet: 'descriptors',
        componentType: 'grouped-select',
        categoriesExcluded: ['genre', 'genre other'],
        locked: true,
        isMulti: true,
        width: '400px',
    },
    {
        label: 'Date',
        facet: 'dates',
        componentType: 'grouped-date-range',
        locked: true,
        isMulti: true,
        width: '250px',
    },
]

const NOT_TAGGED_CATEGORY_OPTION_LABEL = 'Not Tagged'

type UsefacetOptionsProps = {
    facet: SupportedFacet | SupportedFacetGroup
}

type UsefacetOptionsReturn = {
    options: FacetOption[] | undefined
    isLoading: boolean
}

const buildNotTaggedCategoryOptionLabel = ({
    categoryLabel,
}: Pick<CategoryOption, 'categoryLabel'>): string => {
    return `${NOT_TAGGED_CATEGORY_OPTION_LABEL} - ${getDescriptorsVisibleLabel(categoryLabel)}`
}

const buildNotTaggedCategoryOption = ({
    categoryLabel,
    value: category,
    type,
}: Pick<CategoryOption, 'label' | 'categoryLabel' | 'value' | 'type'>): CategoryOption => {
    return {
        label: buildNotTaggedCategoryOptionLabel({ categoryLabel }),
        categoryLabel,
        value: category,
        type,
        labelValue: `${category}${type ? `${CATEGORY_SEPARATOR}${type}` : ''}`,
    }
}

export const useDisplayTaggedTaxonomyLabels = (
    taxonomyIds: string | string[],
): { loaded: boolean; labels: string[] } => {
    const { loaded, data: taxonomies } = useTaggedTaxonomyLabels()

    const genresByCategory = useMemo(() => {
        if (!loaded) return {}
        return keyBy(taxonomies, 'category')
    }, [taxonomies, loaded])

    const labels = useMemo(() => {
        const _taxonomyIds = isArray(taxonomyIds) ? taxonomyIds : [taxonomyIds]
        return (
            _taxonomyIds
                // TODO: Remove when BE records doesn't contain duplicate ids
                .filter((t, i, arr) => arr.indexOf(t) === i)
                .map(t => genresByCategory[t]?.label)
        )
    }, [genresByCategory, taxonomyIds])

    return { loaded, labels }
}

export const displayGenreLabels: { [key: string]: string } = DISPLAY_GENRES.reduce((o, g) => {
    return { ...o, [g.displayGenreId]: g.displayGenreLabel }
}, {})

export const useFacetOptions = (props: UsefacetOptionsProps): UsefacetOptionsReturn => {
    const { facet } = props
    const user = useUser()

    const { isLoading = false, data: options } = useFetchTaxonomyOptionsQuery(user, facet)

    return { options, isLoading }
}

export const useFetchTaxonomyOptionsQuery = (
    user: MyIDUser,
    facet: SupportedFacet | SupportedFacetGroup,
): UseQueryResult<FacetOption[]> => {
    return useQuery(['fetch-descriptors', facet], () => fetchTaxonomyOptions(user, facet))
}

export const fetchTaxonomyOptions = (
    user: MyIDUser,
    facet: SupportedFacet | SupportedFacetGroup,
): Promise<FacetOption[]> => {
    if (facet === 'www_w3_org_1999_02_22-rdf-syntax-ns.type') return Promise.resolve(DISPLAY_TYPES)

    if (['genres', 'descriptors'].includes(facet))
        return request(
            t.array(t.any),
            authCWR(user, {
                url: `${config.urlTRS}/taxonomy/semaphore/tagged-taxonomy`,
                transformResponse,
            }),
        )

    return Promise.resolve([])

    function transformResponse(data: string): FacetOption[] {
        const { taxonomies }: { taxonomies: TaxonomyWithCategories[] } = JSON.parse(data)
        return taxonomies
            .sort((a, b) =>
                compareFn(getDescriptorsVisibleLabel(a.label), getDescriptorsVisibleLabel(b.label)),
            )
            .map(({ label: categoryLabel, categories, type }) => {
                const notTaggedOption: CategoryOption = buildNotTaggedCategoryOption({
                    categoryLabel,
                    label: categoryLabel,
                    type,
                    value: categoryLabel,
                })
                const options = categories
                    .filter(c => !c.notForTagging)
                    .flatMap(c => {
                        const categoriesOptions: CategoryOption[] = [
                            {
                                label: c.label,
                                value: c.category,
                                // Redundant attributes to make category available from flattened structures
                                categoryLabel,
                                type,
                                labelValue: `${c.category}${
                                    type ? `${CATEGORY_SEPARATOR}${type}` : ''
                                }`,
                            },
                        ]
                        const termsOptions = c.terms.map(t => ({
                            label: `${c.label} - ${t.label}`,
                            value: t.id,
                            categoryLabel,
                            type,
                            labelValue: `${c.category}${
                                type ? `${CATEGORY_SEPARATOR}${type}` : ''
                            }`,
                        }))
                        return categoriesOptions.concat(termsOptions)
                    })

                return {
                    label: categoryLabel,
                    type,
                    options: [...options, notTaggedOption],
                }
            })
    }
}
