import React from 'react'
import styled from 'shared/theme'
import { Input } from 'shared/components/Input'

const SearchInputContainer = styled.div`
    position: relative;
    width: 100%;
`

const SearchInputControl = styled(Input)`
    width: 100%;
    box-sizing: border-box;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`
const ClearButton = styled.button`
    position: absolute;
    cursor: pointer;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    background: transparent;
    color: ${props => props.theme.colors.textPrimary};
`

type SearchInputProps = JSX.IntrinsicElements['input'] & {
    onReset?: () => void
}
const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
    ({ onReset, ...props }, ref) => {
        return (
            <SearchInputContainer>
                <SearchInputControl
                    ref={ref as any}
                    onKeyPress={e => {
                        e.key === 'Enter' && e.preventDefault()
                    }}
                    {...props}
                />

                {props.value && onReset && (
                    <ClearButton role="button" type="button" onClick={onReset}>
                        &#10005;
                    </ClearButton>
                )}
            </SearchInputContainer>
        )
    },
)

export default SearchInput
