import Text from 'shared/components/Text'
import { Link } from 'react-router-dom'
import styled from 'shared/theme'
import Container from 'shared/components/Container'

import SearchInput from './SearchInput'
import SearchResultTitle from './SearchResultTitle'

export { SearchInput, SearchResultTitle }

export const PageContainer = styled(Container).attrs({
    flexDirection: 'column',
})`
    margin: 40px auto 0;
    max-width: 95%;
    @media (max-width: 1600px) {
        margin: 20px auto 0;
    }
    @media (max-width: 768px) {
        margin: 10px auto 0;
    }
`

export const WorkflowSearchContainer = styled(Container).attrs({
    flexDirection: 'column',
})`
    margin: 40px auto 0;
`

export const SearchContainer = styled(Container).attrs({
    flexDirection: 'column',
})<{ maxWidth?: string }>`
    margin: 2rem 40px 0.5rem;
`
export const SearchForm = styled.div``

export const SearchFormRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    > *:first-child {
        flex: 1;
    }
    > *:not(:first-child) {
        margin-left: 0.5rem;
    }
`

export const SearchFormGroup = styled.div`
    display: flex;
    flex-direction: column;
    > label {
        display: inline-block;
    }
`

export const SearchResultLink = styled(Link)`
    text-decoration: none;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    font-weight: 400;
`

export const SearchResultsHeader = styled.div`
    display: flex;
    align-items: baseline;
`
export const SearchHeaderText = styled(Text)`
    margin-bottom: 0.25rem;
`
