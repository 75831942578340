import React, { useEffect, useMemo, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { set } from 'date-fns'
import ReactDatePicker from 'react-datepicker'

import { useCreateDescriptorsReport } from 'api/workflow/createDescriptorsReport'
import {
    CreateDescriptorsReportModel,
    CreateDescriptorsReportValidationSchema,
    DEIdescriptorsReportTypes,
    createDescriptorsReportTypes,
} from 'model/workflow/CreateDescriptorsReport'

import Header from 'shared/components/Header'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import CellLabel from 'shared/components/CellLabel'
import Button from 'shared/components/Button'
import { notification } from 'shared/notification'
import ErrorMessage from 'shared/components/ErrorMessage'
import { useHasDEIRole } from 'shared/hooks/useHasRole'

import styled from 'shared/theme'
import { useAuthSnowplowPageView } from 'shared/hooks/useSnowPlowPageView'

const CreateDescriptorsReportWrapper = styled(Container)`
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    svg {
        height: 18px;
        width: 18px;
        padding-top: 1px;
    }
`

const CreateDescriptorsReportTitle = styled(Text)`
    font-size: 2em;
`

const CreateDescriptorsReportFormRow = styled.div`
    display: flex;
    flex-direction: row;
`

const InputWrapper = styled.div`
    padding: 1.5rem 1rem 0 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 8px;
`

const DatePicker = styled(ReactDatePicker)`
    outline: none;
    border: 1px solid ${props => props.theme.colors.gray};
    border-radius: 2px;
    padding: 0.75rem 0.6rem;
    width: 90%;

    &:focus {
        border-color: #1e7cff;
    }
`

const CheckboxRow = styled.label`
    margin: 9px 0 9px 0;

    & > input {
        margin-right: 6px;
    }

    & > a,
    & > b {
        margin-left: 12px;
    }
`

const ButtonWrapper = styled.div`
    margin-top: 12px;

    * > {
        margin: 0.5rem;
    }
`

const DownloadLink = styled.a`
    text-decoration: none;
    background-color: ${props => props.theme.colors.primary};
    border-radius: 3px;
    color: ${props => props.theme.colors.white};
    padding: 0.09rem 1.2rem;
`

const CreateDescriptorsReport: React.FC = (): React.ReactElement => {
    useAuthSnowplowPageView('Create Metadata Report', 'Create Metadata Report Page')

    const hasDEIRole = useHasDEIRole()
    const initialValues: CreateDescriptorsReportModel = {
        selectedDate: set(new Date(), { hours: 0, minutes: 0, seconds: 0 }),
        checkedDescriptors: [],
    }

    const [params, setParams] = useState<CreateDescriptorsReportModel>(initialValues)
    const { data, isLoading, error, clear } = useCreateDescriptorsReport(params)

    const metadataReports = useMemo(() => {
        const commonReports = Object.entries(createDescriptorsReportTypes)
        const deiReports = hasDEIRole ? Object.entries(DEIdescriptorsReportTypes) : []
        return [...commonReports, ...deiReports]
    }, [hasDEIRole])

    const onSubmit = (values: CreateDescriptorsReportModel) => {
        setParams(values)
    }

    useEffect(() => {
        if (isLoading) {
            notification.info('Waiting for backend to complete export', { autoClose: 4500 })
        }
    }, [isLoading])

    return (
        <>
            <Header />
            <CreateDescriptorsReportWrapper>
                <CreateDescriptorsReportTitle as="h2">
                    Metadata Reports
                </CreateDescriptorsReportTitle>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={CreateDescriptorsReportValidationSchema}
                >
                    {({ values, setValues }) => (
                        <Form>
                            <CreateDescriptorsReportFormRow>
                                <InputWrapper>
                                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                                        Select date
                                    </CellLabel>
                                    <DatePicker
                                        name="selectedDate"
                                        autoComplete="off"
                                        dateFormat="MMMM d, yyyy"
                                        maxDate={new Date()}
                                        placeholderText="Select date"
                                        selected={
                                            values.selectedDate
                                                ? new Date(values.selectedDate)
                                                : undefined
                                        }
                                        onChange={date => {
                                            clear()
                                            const selectedDate = Array.isArray(date)
                                                ? date[0]
                                                : date
                                            setValues({
                                                ...values,
                                                selectedDate: selectedDate
                                                    ? set(selectedDate, {
                                                          hours: 0,
                                                          minutes: 0,
                                                          seconds: 0,
                                                      })
                                                    : undefined,
                                            })
                                        }}
                                    />
                                    <ErrorMessage name="selectedDate" />
                                    <ButtonWrapper>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={isLoading}
                                            isLoading={isLoading}
                                        >
                                            Generate
                                        </Button>
                                    </ButtonWrapper>
                                </InputWrapper>
                                <InputWrapper>
                                    {metadataReports.map(([key, value]) => (
                                        <CheckboxRow key={key}>
                                            <Field
                                                type="checkbox"
                                                name="checkedDescriptors"
                                                value={key}
                                            />
                                            {value}{' '}
                                            {data[key] && (
                                                <DownloadLink href={data[key]}>
                                                    Download
                                                </DownloadLink>
                                            )}
                                            {!data[key] && error[key] && (
                                                <b>
                                                    {error[key].status === 404
                                                        ? 'No report available'
                                                        : error[key].error.message}
                                                </b>
                                            )}
                                        </CheckboxRow>
                                    ))}
                                </InputWrapper>
                            </CreateDescriptorsReportFormRow>
                        </Form>
                    )}
                </Formik>
            </CreateDescriptorsReportWrapper>
        </>
    )
}

export default CreateDescriptorsReport
