import { TaxonomyTerm } from 'codecs/TRS/Taxonomy'
import flatMap from 'lodash/flatMap'

export type HierarchyInput<T> = T & {
    label: string
    terms?: HierarchyInput<T>[]
}

export type HierarchyOutput<T> = T & {
    level: number
}

/**
 * Sort terms by naturalSort, flatten them and add a { level: number } for
 * the original level
 */
export const flattenHierarchy = <T extends { [key: string]: any }>(
    inputTerms: HierarchyInput<T>[] = [],
    level = 0,
): HierarchyOutput<T>[] => {
    return flatMap(inputTerms, inputTerm => {
        const { terms, ...term } = inputTerm
        const termWithLevel = {
            level,
            ...term,
        } as HierarchyOutput<T>

        return [termWithLevel, ...(terms ? flattenHierarchy(terms, level + 1) : [])]
    })
}

export const deepFlattenTaxonomies = (taxonomies: any[]): TaxonomyTerm[] => {
    return taxonomies.reduce((acum, { terms, ...rest }) => {
        acum.push(rest)
        if (terms) acum.push(...deepFlattenTaxonomies(terms))
        return acum
    }, [])
}
