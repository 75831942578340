import React, { useCallback, useEffect, useState } from 'react'
import { ValueType } from 'react-select'

import { useUser } from 'auth/Auth'

import { CastMember } from 'codecs/CastMember'

import { Dialog } from 'shared/components/Dialog'
import { ReactComponent as CloseIcon } from 'shared/components/Icons/close-icon.svg'

import { PortrayalForm, PortrayalMetadataLoading } from './PortrayalForm'
import Button from '../Button'
import { useResourceMachine } from 'shared/resource/ResourceMachineProvider'
import { createNewPortrayal, searchPortrayalByID, updatePortrayal } from 'api/portrayals'

import { Feature } from 'model/Feature'
import { Series } from 'model/Series'
import { Episode } from 'model/Episode'
import { Season } from 'model/Season'
import { TitleAssociation } from 'model/characters/Portrayal'

import {
    buildCastMemberFromFormState,
    buildFormStateForNewPortrayal,
    buildFormStateFromPortrayalFound,
    formatPortrayalsFoundOptions,
    notifyDataNotChangedWarning,
    notifyHasAssociationError,
    notifyNoAssociationError,
    notifyPortrayalsDuplicates,
    prepareNewPortrayalFromFormStatePayload,
    preparePortrayalToUpdateFromFormStatePayload,
    updateResouceData,
} from './utils'
import PortrayalSearch from './PortrayalSearch'
import { EditIconButton } from '../Icons/EditIcon'
import { ErrorText } from './ErrorText'
import { CharacterRelationships } from './CharacterRelationships'
import { EntityTypePill } from './EntityTypePill'
import { FormattedPortrayal, PortrayalFormInProgressState } from './types'

import styled from 'shared/theme'

const CloseModalIconBtn = styled(CloseIcon)`
    position: absolute;
    cursor: pointer;
    right: 2rem;
    top: 2rem;
`

export enum PortrayalDetailsViewMode {
    None = 'none',
    Adding = 'Adding',
    ReadWrite = 'readwrite',
    Relationships = 'relationships',
}

export enum PortrayalDetailsViewStage {
    None = 'none',
    Search = 'search',
    Associate = 'associate',
    CreateNew = 'createNew',
}

export type PortrayalsDetailsModalState = {
    open: boolean
    portrayal: CastMember | null
    mode: PortrayalDetailsViewMode
    stage: PortrayalDetailsViewStage
}

export const PortrayalDetailsModal: React.FC<{
    state: PortrayalsDetailsModalState
    hasLibReadRole: boolean
    hasLibWriteRole: boolean
    isPortrayalsEditingAllowed: boolean
    onPortrayalModalChange: (state: PortrayalsDetailsModalState) => void
}> = ({ state, onPortrayalModalChange, hasLibWriteRole, isPortrayalsEditingAllowed }) => {
    const { open, portrayal, mode, stage } = state
    const user = useUser()
    const [resState, send] = useResourceMachine<Feature | Series | Episode | Season>()
    const {
        context: { resourceId, resource },
    } = resState

    const [portrayalSelectVal, setPortrayalSelectVal] = useState<FormattedPortrayal | null>(null)

    const [portrayalInEdit, setPortrayalInEdit] = useState<FormattedPortrayal | null>(null)
    const [associatedWith, setAssociatedwith] = useState<TitleAssociation[]>([])

    const [isLoadingMeta, setIsLoadingMeta] = useState(false)
    const [isLoadingMetaError, setIsLoadingMetaError] = useState(false)

    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [isSavingError, setIsSavingError] = useState<boolean>(false)

    const [editingEnabled, setEditingEnabled] = useState<boolean>(false)
    const [lastSaved, setLastSaved] = useState<null | CastMember>(null)

    const [initialFormValues, setInitialFormValues] = useState<PortrayalFormInProgressState | null>(
        null,
    )

    const [isPortraysMdmSearchOn, setIsPortraysMdmSearchOn] = useState(false)
    const [isPortrayedByMdmSearchOn, setIsPortrayedByMdmSearchOn] = useState(false)

    const handlePortraysSearchModeChange = useCallback(
        (value: boolean) => {
            setIsPortraysMdmSearchOn(value)
        },
        [setIsPortraysMdmSearchOn],
    )

    const handlePortrayedBySearchModeChange = useCallback(
        (value: boolean) => {
            setIsPortrayedByMdmSearchOn(value)
        },
        [setIsPortrayedByMdmSearchOn],
    )

    useEffect(() => {
        let formState: PortrayalFormInProgressState | null = null
        if (portrayalInEdit) {
            formState = buildFormStateFromPortrayalFound(portrayalInEdit)
        } else if (portrayalSelectVal) {
            formState = buildFormStateFromPortrayalFound(portrayalSelectVal)
        } else if (stage === PortrayalDetailsViewStage.CreateNew) {
            formState = buildFormStateForNewPortrayal()
        }
        setInitialFormValues(formState)
    }, [portrayalInEdit, portrayalSelectVal, stage, setInitialFormValues])

    const handleEditngModeChange = useCallback(
        enabled => {
            setEditingEnabled(enabled)
            setIsSavingError(false)
        },
        [setEditingEnabled, setIsSavingError],
    )

    const onModalClose = useCallback(() => {
        onPortrayalModalChange({
            open: false,
            portrayal: null,
            mode: PortrayalDetailsViewMode.None,
            stage: PortrayalDetailsViewStage.None,
        })
        setEditingEnabled(false)
        setIsLoadingMeta(false)
        setIsLoadingMetaError(false)
        setPortrayalSelectVal(null)
        setLastSaved(null)
        setIsSaving(false)
        setIsSavingError(false)
        setAssociatedwith([])
        setPortrayalInEdit(null)
    }, [
        onPortrayalModalChange,
        setEditingEnabled,
        setIsLoadingMetaError,
        setIsLoadingMeta,
        setPortrayalSelectVal,
        setLastSaved,
        setIsSavingError,
        setIsSaving,
        setAssociatedwith,
        setPortrayalInEdit,
    ])

    const handleNextStep = useCallback(() => {
        onPortrayalModalChange({
            open: true,
            portrayal: null,
            mode: PortrayalDetailsViewMode.Adding,
            stage: !!portrayalSelectVal
                ? PortrayalDetailsViewStage.Associate
                : PortrayalDetailsViewStage.CreateNew,
        })
    }, [portrayalSelectVal, onPortrayalModalChange])

    const handleBackClick = useCallback(() => {
        onPortrayalModalChange({
            open: true,
            portrayal: null,
            mode: PortrayalDetailsViewMode.Adding,
            stage: PortrayalDetailsViewStage.Search,
        })
        setAssociatedwith([])
    }, [onPortrayalModalChange, setAssociatedwith])

    const handlePortrayalSelection = useCallback(
        (metadata: ValueType<FormattedPortrayal>) => {
            if (!!metadata) {
                setPortrayalSelectVal(metadata as FormattedPortrayal)
            } else {
                setPortrayalSelectVal(null)
            }
        },
        [setPortrayalSelectVal],
    )

    const handlePortrayalCreation = useCallback(
        (formState: PortrayalFormInProgressState) => {
            const metadata = prepareNewPortrayalFromFormStatePayload(formState, resourceId)
            const cm = buildCastMemberFromFormState(formState)

            setIsSaving(true)
            setIsSavingError(false)

            createNewPortrayal(metadata, user)
                .then(data => {
                    cm.portrayal.portrayalId = data.portrayalId
                    const resourceData = updateResouceData(resource, cm, true, false)
                    send({ type: 'REFRESH_RESOURCE', data: resourceData })
                    onPortrayalModalChange({
                        open: true,
                        portrayal: null,
                        mode: PortrayalDetailsViewMode.Adding,
                        stage: PortrayalDetailsViewStage.Search,
                    })
                    return data
                })
                .catch(err => {
                    setIsSavingError(true)
                    return err
                })
                .finally(() => {
                    setIsSaving(false)
                })
        },
        [resource, resourceId, user, onPortrayalModalChange, send, setIsSaving, setIsSavingError],
    )

    const createNewFromScratch = useCallback(
        (form: PortrayalFormInProgressState) => {
            handlePortrayalCreation(form)
        },
        [handlePortrayalCreation],
    )

    const handlePortrayalUpdate = useCallback(
        (formState: PortrayalFormInProgressState, isAssociation: boolean) => {
            const cm = buildCastMemberFromFormState(formState, portrayal?.attributes)
            const hasNoAssociationError = notifyNoAssociationError(
                associatedWith,
                resourceId,
                isAssociation,
            )

            if (hasNoAssociationError) {
                return
            }

            const hasAssociationError = notifyHasAssociationError(
                associatedWith,
                resourceId,
                isAssociation,
            )

            if (hasAssociationError) {
                return
            }

            //?  Is it better to compare with metadata?
            const dataIsNotChanged = notifyDataNotChangedWarning(lastSaved, cm, isAssociation)

            if (dataIsNotChanged) {
                return
            }

            const metadata = preparePortrayalToUpdateFromFormStatePayload(
                formState,
                resourceId,
                associatedWith,
                isAssociation,
            )

            setIsSaving(true)
            setIsSavingError(false)

            updatePortrayal(metadata, user)
                .then(data => {
                    const resourceData = updateResouceData(resource, cm, isAssociation, false)
                    send({ type: 'REFRESH_RESOURCE', data: resourceData })
                    if (isAssociation) {
                        onPortrayalModalChange({
                            open: true,
                            portrayal: null,
                            mode: PortrayalDetailsViewMode.Adding,
                            stage: PortrayalDetailsViewStage.Search,
                        })
                        setPortrayalSelectVal(null)
                        setAssociatedwith([])
                    } else {
                        onPortrayalModalChange({
                            open: true,
                            portrayal: { ...cm },
                            mode: PortrayalDetailsViewMode.ReadWrite,
                            stage: PortrayalDetailsViewStage.None,
                        })
                    }
                    setLastSaved(cm)
                    setEditingEnabled(false)
                    return data
                })
                .catch(err => {
                    setIsSavingError(true)
                    return err
                })
                .finally(() => {
                    setIsSaving(false)
                })
        },
        [
            associatedWith,
            lastSaved,
            resource,
            resourceId,
            user,
            portrayal,
            onPortrayalModalChange,
            send,
            setAssociatedwith,
            setIsSaving,
            setIsSavingError,
            setEditingEnabled,
            setPortrayalSelectVal,
            setLastSaved,
        ],
    )

    const associatePortrayal = useCallback(
        (formState: PortrayalFormInProgressState) => {
            handlePortrayalUpdate(formState, true)
        },
        [handlePortrayalUpdate],
    )

    const loadMetadata = useCallback(
        (portrayalId: string): void => {
            setIsLoadingMeta(true)
            setIsLoadingMetaError(false)
            searchPortrayalByID(portrayalId, user)
                .then(portrayals => {
                    notifyPortrayalsDuplicates(portrayals)
                    if (!!portrayals.length) {
                        const portrayalFoundById = formatPortrayalsFoundOptions(portrayals)[0]
                        setAssociatedwith(
                            // Filter out orphaned associations
                            portrayalFoundById.portrayal.isAssociatedWithTitle.filter(
                                a => !!a.titleUri,
                            ),
                        )
                        if (mode === PortrayalDetailsViewMode.ReadWrite) {
                            setPortrayalInEdit(portrayalFoundById)
                        }
                    } else {
                        setIsLoadingMetaError(true)
                    }
                    return portrayals
                })
                .catch(err => {
                    setIsLoadingMetaError(true)
                    return err
                })
                .finally(() => {
                    setIsLoadingMeta(false)
                })
        },
        [
            user,
            mode,
            setAssociatedwith,
            setIsLoadingMeta,
            setIsLoadingMetaError,
            setPortrayalInEdit,
        ],
    )

    useEffect(() => {
        if (mode === PortrayalDetailsViewMode.ReadWrite && portrayal) {
            loadMetadata(portrayal.portrayal.portrayalId)
        }
        if (stage === PortrayalDetailsViewStage.Associate && !!portrayalSelectVal) {
            loadMetadata(portrayalSelectVal.portrayalId)
        }
    }, [mode, stage, portrayal, portrayalSelectVal, loadMetadata])

    useEffect(() => {
        setLastSaved(portrayal)
    }, [portrayal, setLastSaved])

    return (
        <Dialog
            isOpen={open}
            onDismiss={onModalClose}
            aria-label="Portrayal Details"
            style={{ position: 'relative' }}
        >
            <CloseModalIconBtn onClick={onModalClose} />

            {mode === PortrayalDetailsViewMode.ReadWrite && (
                <div style={{ textAlign: 'left' }}>
                    {portrayal && (
                        <>
                            <h3>
                                {portrayal.portrayal.portrayalName}{' '}
                                {portrayalInEdit &&
                                    hasLibWriteRole &&
                                    isPortrayalsEditingAllowed &&
                                    !editingEnabled && (
                                        <EditIconButton
                                            label="Edit Mode"
                                            onClick={() => setEditingEnabled(true)}
                                            style={{ marginLeft: '1rem', verticalAlign: 'middle' }}
                                        />
                                    )}
                            </h3>
                            <EntityTypePill name="portrayal" />
                            <PortrayalMetadataLoading
                                isLoadingMeta={isLoadingMeta}
                                isLoadingMetaError={isLoadingMetaError}
                                portrayalId={portrayal.portrayal.portrayalId}
                                loadMetadata={loadMetadata}
                            />
                        </>
                    )}

                    {initialFormValues && (
                        <PortrayalForm
                            associations={associatedWith}
                            isLoadingMeta={isLoadingMeta}
                            isLoadingMetaError={isLoadingMetaError}
                            areControlsDisabled={!editingEnabled}
                            initialValues={initialFormValues}
                            mode={mode}
                            stage={stage}
                            isSaving={isSaving}
                            isSavingError={isSavingError}
                            onBack={handleBackClick}
                            onEditingModeChange={handleEditngModeChange}
                            onModalClose={onModalClose}
                            onSubmit={(e: any) => handlePortrayalUpdate(e, false)}
                            loadMetadata={loadMetadata}
                            isPortraysMdmSearchOn={isPortraysMdmSearchOn}
                            onPortraysSearchModesChange={handlePortraysSearchModeChange}
                            isPortrayedByMdmSearchOn={isPortrayedByMdmSearchOn}
                            onPortrayedBySearchModeChange={handlePortrayedBySearchModeChange}
                        />
                    )}
                </div>
            )}

            {/* Creation  */}
            {!portrayal && mode === PortrayalDetailsViewMode.Adding && (
                <div style={{ textAlign: 'left' }}>
                    {stage === PortrayalDetailsViewStage.Search && (
                        <div>
                            <h3>Add Portrayal</h3>

                            <div style={{ marginBottom: '2rem' }}>
                                <PortrayalSearch
                                    onChange={handlePortrayalSelection}
                                    value={portrayalSelectVal}
                                />
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <Button
                                    variant="outline"
                                    size="small"
                                    style={{ marginLeft: 'auto' }}
                                    onClick={() => handleNextStep()}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    )}
                    {stage === PortrayalDetailsViewStage.Associate &&
                        !!initialFormValues &&
                        !!portrayalSelectVal && (
                            <div>
                                <h3>{portrayalSelectVal.portrayal.portrayalLabel}</h3>
                                <PortrayalForm
                                    associations={associatedWith}
                                    isLoadingMeta={isLoadingMeta}
                                    isLoadingMetaError={isLoadingMetaError}
                                    areControlsDisabled={true}
                                    initialValues={initialFormValues}
                                    mode={mode}
                                    stage={stage}
                                    isSaving={isSaving}
                                    isSavingError={isSavingError}
                                    onBack={handleBackClick}
                                    onEditingModeChange={handleEditngModeChange}
                                    onModalClose={onModalClose}
                                    onSubmit={(e: any) => associatePortrayal(e)}
                                    loadMetadata={loadMetadata}
                                    isPortraysMdmSearchOn={isPortraysMdmSearchOn}
                                    onPortraysSearchModesChange={handlePortraysSearchModeChange}
                                    isPortrayedByMdmSearchOn={isPortrayedByMdmSearchOn}
                                    onPortrayedBySearchModeChange={
                                        handlePortrayedBySearchModeChange
                                    }
                                />
                            </div>
                        )}
                    {stage === PortrayalDetailsViewStage.CreateNew && initialFormValues && (
                        <div>
                            <h3>New Portrayal</h3>
                            <PortrayalForm
                                associations={[]}
                                isLoadingMeta={false}
                                isLoadingMetaError={false}
                                areControlsDisabled={!hasLibWriteRole}
                                initialValues={initialFormValues}
                                mode={mode}
                                stage={stage}
                                isSaving={isSaving}
                                isSavingError={isSavingError}
                                onBack={handleBackClick}
                                onEditingModeChange={handleEditngModeChange}
                                onModalClose={onModalClose}
                                onSubmit={(e: any) => createNewFromScratch(e)}
                                loadMetadata={loadMetadata}
                                isPortraysMdmSearchOn={isPortraysMdmSearchOn}
                                onPortraysSearchModesChange={handlePortraysSearchModeChange}
                                isPortrayedByMdmSearchOn={isPortrayedByMdmSearchOn}
                                onPortrayedBySearchModeChange={handlePortrayedBySearchModeChange}
                            />
                        </div>
                    )}
                </div>
            )}

            {mode !== PortrayalDetailsViewMode.Relationships && isSavingError && (
                <ErrorText>
                    Error has occurred while saving the portrayal. Please, try again.
                </ErrorText>
            )}

            {!!portrayal && mode === PortrayalDetailsViewMode.Relationships && (
                <CharacterRelationships
                    portrayal={portrayal}
                    areControlsDisabled={!hasLibWriteRole || !isPortrayalsEditingAllowed}
                />
            )}
        </Dialog>
    )
}
