import React, { useCallback, useMemo, useState } from 'react'
import { ActionMeta, FormatOptionLabelMeta, OptionTypeBase, ValueType } from 'react-select'
import * as t from 'io-ts'
import { debounce } from 'lodash'
import { useField, useFormikContext } from 'formik'
import { AsyncProps } from 'react-select/async'

import { request } from '@genome-web-forms/common/api'
import { MyIDUser } from '@genome-web-forms/common/auth'

import { MemoAsyncSelect } from 'shared/components/Select'
import { useUser } from 'auth/Auth'
import { authGWF } from 'api/auth'
import config from 'shared/config'
import { nullable } from 'codecs/util/nullable'

import { usePortrayalsContext } from './portrayalRowsContext'
import FormikSelect from '../Select/FormikSelect'
import ErrorMessage from 'shared/components/ErrorMessage'
import { abortableLoadOptions, generatePortrayalName, getMdmIdLabel } from './utils'
import {
    DEFAULT_DEBOUNCE_DELAY,
    EMPTY_FIELD_LABEL,
    NONE_OPTION,
    NONE_FIELD_LABEL,
    PortrayalType,
} from './constants'
import { MdmIdGrid } from './Layout'
import { PortrayalFormInProgressState } from './types'
import styled from 'shared/theme'

export const TalentCodec = t.type({
    id: t.string,
    firstName: t.string,
    lastName: t.string,
    mdmID: nullable(t.string),
})

export type TalentOption = t.TypeOf<typeof TalentCodec>

type FormatedOption = {
    label: string
    uri: string
    mdmId?: string | undefined
}

const getOptionLabel = (o: FormatedOption) => o.label
const getOptionValue = (o: FormatedOption) => o.uri

const formatOptions = (rowOpts: TalentOption[]): FormatedOption[] => {
    return rowOpts.map(opt => {
        let label = opt.lastName
        if (!!opt.firstName && !!opt.lastName) {
            label = `${opt.lastName}, ${opt.firstName}`
        } else if (!opt.lastName) {
            label = opt.firstName
        }
        return {
            label: label,
            uri: opt.id,
            mdmId: opt.mdmID || undefined,
        }
    })
}

async function fetchData<T>(
    user: MyIDUser,
    isMdmMode: boolean,
    optionsFormatter: (rowOpt: TalentOption[]) => T[],
    text = '',
    abortCtrl: AbortController,
): Promise<T[]> {
    if (text === '') {
        return []
    }

    const url = !isMdmMode
        ? `${config.urlGWFOntology}/talent?fullName=${encodeURIComponent(text)}`
        : `${config.urlGWFOntology}/talent?mdmID=${text}`

    return request(
        t.array(TalentCodec),
        authGWF(user, {
            url: url,
            method: 'GET',
            signal: abortCtrl.signal,
        }),
    ).then(resp => {
        return optionsFormatter(resp)
    })
}

const loadOptions = (
    user: MyIDUser,
    text: string,
    isMdmMode: boolean,
    abortCtrl: AbortController,
    callback: (opts: FormatedOption[]) => any,
) => {
    fetchData(user, isMdmMode, formatOptions, text, abortCtrl).then(resp => callback(resp))
}

const CustomTalentOption: React.FC<{
    option: FormatedOption
    meta: FormatOptionLabelMeta<FormatedOption>
}> = ({ option, meta: { context } }) => {
    if (context === 'menu') {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{option.label}</span>
                <span style={{ fontWeight: 'bold', marginLeft: '1rem' }}>
                    {option.uri === NONE_FIELD_LABEL ? '' : option.mdmId || EMPTY_FIELD_LABEL}
                </span>
            </div>
        )
    } else {
        return <div>{option.label}</div>
    }
}

interface FormikTalentSearchProps<T extends OptionTypeBase> {
    name: string
    disabled: boolean
    debouncedLoadOptions: (
        user: MyIDUser,
        val: string,
        ctrl: AbortController,
        callback: (opts: T[]) => any,
    ) => any
    getOptionValue: (opt: T) => string
    getOptionLabel: (opt: T) => string
    onChange: (value: ValueType<T>, meta: ActionMeta<T>) => void
    formatOptionLable?: (option: T, meta: FormatOptionLabelMeta<T>) => JSX.Element
    defaultOptions?: T[]
}

export const FormikTalentSearch = <T extends OptionTypeBase>({
    name,
    disabled,
    debouncedLoadOptions,
    getOptionValue,
    getOptionLabel,
    onChange,
    formatOptionLable,
    defaultOptions,
}: FormikTalentSearchProps<T>): React.ReactElement => {
    const { values } = useFormikContext<PortrayalFormInProgressState>()
    const user = useUser()

    const [prevCancelTokenSourceObj] = useState({
        ctrl: null,
    })

    const loadOptions = useCallback<AsyncProps<T>['loadOptions']>(
        (inputValue, callback) => {
            abortableLoadOptions<T>(
                user,
                inputValue,
                prevCancelTokenSourceObj,
                callback,
                debouncedLoadOptions,
            )
        },
        [user, prevCancelTokenSourceObj, debouncedLoadOptions],
    )

    const isOptionDisabled = useCallback(
        o => {
            const selected = values.portrayedBy.map(v => v?.uri)
            return selected.some(s => s === o.uri)
        },
        [values.portrayedBy],
    )

    return (
        <div>
            <FormikSelect<T>
                isOptionDisabled={isOptionDisabled}
                name={name}
                isDisabled={disabled}
                loadOptions={loadOptions}
                placeholder={'Search...'}
                cacheOptions={false}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onChange={onChange}
                selectComponent={MemoAsyncSelect}
                formatOptionLabel={formatOptionLable}
                defaultOptions={defaultOptions}
            />
            <ErrorMessage name={name} />
        </div>
    )
}

export const FormikTalentSearchAsTalent: React.FC<{
    name: string
    disabled: boolean
    index: number
    isMdmMode: boolean
}> = ({ name, disabled, index, isMdmMode }) => {
    const { values, setFieldValue } = useFormikContext<PortrayalFormInProgressState>()
    const [{ value }] = useField<{ uri: string; label: string; mdmId?: string | undefined } | null>(
        name,
    )

    const handleChange = useCallback(
        (value: ValueType<FormatedOption>) => {
            if (!!value) {
                const portrayedByValues = [...values.portrayedBy]
                portrayedByValues[index] = value as FormatedOption
                const generatedValue = generatePortrayalName(values.portrays, portrayedByValues)
                setFieldValue('portrayal.portrayalName', generatedValue)
            }
            setFieldValue(name, value)
        },
        [values, name, index, setFieldValue],
    )

    const formatOptionLable = useCallback(
        (option: FormatedOption, meta: FormatOptionLabelMeta<FormatedOption>) => {
            return <CustomTalentOption option={option} meta={meta} />
        },
        [],
    )

    const debouncedLoadOptionsForTalent = useMemo(
        () =>
            debounce(
                (
                    user: MyIDUser,
                    text: string,
                    abortCtrl: AbortController,
                    callback: (opts: FormatedOption[]) => void,
                ) => loadOptions(user, text, isMdmMode, abortCtrl, callback),
                DEFAULT_DEBOUNCE_DELAY,
            ),
        [isMdmMode],
    )

    const defaultOptions = useMemo(() => {
        return values.portrayal.portrayalType?.uri === PortrayalType.OfCharacter
            ? [NONE_OPTION]
            : undefined
    }, [values.portrayal.portrayalType])

    return (
        <MdmIdGrid disabled={disabled}>
            {disabled ? (
                <div>{value?.label}</div>
            ) : (
                <div>
                    <FormikTalentSearch
                        name={name}
                        disabled={disabled}
                        debouncedLoadOptions={debouncedLoadOptionsForTalent}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        onChange={handleChange}
                        formatOptionLable={formatOptionLable}
                        defaultOptions={defaultOptions}
                    />
                </div>
            )}
            <div>{getMdmIdLabel(value)}</div>
        </MdmIdGrid>
    )
}

export const FormikTalentSearchAsCharacter: React.FC<{
    name: string
    disabled: boolean
    index: number
    isMdmMode: boolean
}> = ({ name, disabled, index, isMdmMode }) => {
    const { values, setFieldValue } = useFormikContext<PortrayalFormInProgressState>()
    const [{ value }] = useField<{ uri: string; label: string; mdmId?: string | undefined } | null>(
        name,
    )

    const handleChange = useCallback(
        (value: ValueType<FormatedOption>) => {
            if (!!value) {
                const portraysValues = [...values.portrays]
                portraysValues[index] = value as FormatedOption
                const generatedValue = generatePortrayalName(portraysValues, values.portrayedBy)
                setFieldValue('portrayal.portrayalName', generatedValue)
            }
            setFieldValue(name, value)
        },
        [values, name, index, setFieldValue],
    )

    const formatOptionLable = useCallback(
        (option: FormatedOption, meta: FormatOptionLabelMeta<FormatedOption>) => {
            return <CustomTalentOption option={option} meta={meta} />
        },
        [],
    )

    const debouncedLoadOptionsForCharacter = useMemo(
        () =>
            debounce(
                (
                    user: MyIDUser,
                    text: string,
                    abortCtrl: AbortController,
                    callback: (opts: FormatedOption[]) => void,
                ) => loadOptions(user, text, isMdmMode, abortCtrl, callback),
                DEFAULT_DEBOUNCE_DELAY,
            ),
        [isMdmMode],
    )

    return (
        <MdmIdGrid disabled={disabled}>
            {disabled ? (
                <div>{value?.label}</div>
            ) : (
                <div>
                    <FormikTalentSearch
                        name={name}
                        disabled={disabled}
                        debouncedLoadOptions={debouncedLoadOptionsForCharacter}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        onChange={handleChange}
                        formatOptionLable={formatOptionLable}
                    />
                </div>
            )}
            <div>{getMdmIdLabel(value)}</div>
        </MdmIdGrid>
    )
}

interface InlineTalentSearchProps<T extends OptionTypeBase> {
    disabled: boolean
    value: ValueType<T>
    debouncedLoadOptions: (
        user: MyIDUser,
        val: string,
        ctrl: AbortController,
        callback: (opts: T[]) => any,
    ) => any
    getOptionValue: (opt: T) => string
    getOptionLabel: (opt: T) => string
    onChange?: (value: ValueType<T>, meta: ActionMeta<T>) => void
    formatOptionLable?: (option: T, meta: FormatOptionLabelMeta<T>) => JSX.Element
}

export const InlineTalentSearch = <T extends OptionTypeBase>({
    disabled,
    value,
    debouncedLoadOptions,
    getOptionValue,
    getOptionLabel,
    onChange,
    formatOptionLable,
}: InlineTalentSearchProps<T>): React.ReactElement => {
    const user = useUser()

    const [prevCancelTokenSourceObj] = useState({
        ctrl: null,
    })

    const loadOptions = useCallback<AsyncProps<T>['loadOptions']>(
        (inputValue, callback) => {
            abortableLoadOptions<T>(
                user,
                inputValue,
                prevCancelTokenSourceObj,
                callback,
                debouncedLoadOptions,
            )
        },
        [user, prevCancelTokenSourceObj, debouncedLoadOptions],
    )

    return (
        <MemoAsyncSelect
            isMulti={false}
            value={value}
            loadOptions={loadOptions}
            placeholder={'Search...'}
            cacheOptions={false}
            onChange={onChange}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            menuPlacement="auto"
            isDisabled={disabled}
            formatOptionLabel={formatOptionLable}
        />
    )
}

export const InlineTalentSearchAsTalentBase: React.FC<{
    disabled: boolean
    index: number
    isMdmMode?: boolean
}> = ({ disabled, index, isMdmMode }) => {
    const { portrayalInEdit, setCurrentPortrayal } = usePortrayalsContext()

    const value = useMemo(() => {
        const talent = portrayalInEdit?.portrayedBy[index] || null
        return talent
    }, [portrayalInEdit, index])

    const handleOnChange = useCallback(
        (opt: ValueType<FormatedOption>) => {
            // Use different type if select reset is allowed (value = null). Then CastMember cannot be used here
            const selectedOpt = opt as FormatedOption
            if (!!portrayalInEdit && !!selectedOpt) {
                let portrayalName = portrayalInEdit.portrayal.portrayalName
                let portrayedByValues = [...portrayalInEdit.portrayedBy]
                portrayedByValues[index] = selectedOpt
                portrayalName = generatePortrayalName(portrayalInEdit.portrays, portrayedByValues)
                setCurrentPortrayal({
                    ...portrayalInEdit,
                    portrayedBy: portrayedByValues,
                    portrayal: { ...portrayalInEdit.portrayal, portrayalName },
                })
            }
        },
        [portrayalInEdit, index, setCurrentPortrayal],
    )

    const formatOptionLable = useCallback(
        (option: FormatedOption, meta: FormatOptionLabelMeta<FormatedOption>) => {
            return <CustomTalentOption option={option} meta={meta} />
        },
        [],
    )

    const debouncedLoadOptionsForTalent = useMemo(
        () =>
            debounce(
                (
                    user: MyIDUser,
                    text: string,
                    abortCtrl: AbortController,
                    callback: (opts: FormatedOption[]) => void,
                ) => loadOptions(user, text, isMdmMode || false, abortCtrl, callback),
                DEFAULT_DEBOUNCE_DELAY,
            ),
        [isMdmMode],
    )

    return (
        <InlineTalentSearch
            disabled={disabled}
            value={value}
            debouncedLoadOptions={debouncedLoadOptionsForTalent}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onChange={handleOnChange}
            formatOptionLable={formatOptionLable}
        />
    )
}

export const InlineTalentSearchAsTalent = styled(InlineTalentSearchAsTalentBase)`
    display: flex;
    flex-basis: 180px
    flex-direction: column;
`

export const InlineTalentSearchAsCharacterBase: React.FC<{
    disabled: boolean
    index: number
    isMdmMode?: boolean
}> = ({ disabled, index, isMdmMode }) => {
    const { portrayalInEdit, setCurrentPortrayal } = usePortrayalsContext()

    const value = useMemo(() => {
        const character = portrayalInEdit?.portrays[index] || null
        return character
    }, [portrayalInEdit, index])

    const handleOnChange = useCallback(
        (opt: ValueType<FormatedOption>) => {
            // Use different type if select reset is allowed (value = null). Then CastMember cannot be used here
            const selectedOpt = opt as FormatedOption
            if (!!portrayalInEdit && !!selectedOpt) {
                let portrayalName = portrayalInEdit.portrayal.portrayalName
                let portraysValues = [...portrayalInEdit.portrays]
                portraysValues[index] = selectedOpt
                portrayalName = generatePortrayalName(portraysValues, portrayalInEdit.portrayedBy)
                setCurrentPortrayal({
                    ...portrayalInEdit,
                    portrays: portraysValues,
                    portrayal: { ...portrayalInEdit.portrayal, portrayalName },
                })
            }
        },
        [portrayalInEdit, index, setCurrentPortrayal],
    )

    const formatOptionLable = useCallback(
        (option: FormatedOption, meta: FormatOptionLabelMeta<FormatedOption>) => {
            return <CustomTalentOption option={option} meta={meta} />
        },
        [],
    )

    const debouncedLoadOptionsForCharacter = useMemo(
        () =>
            debounce(
                (
                    user: MyIDUser,
                    text: string,
                    abortCtrl: AbortController,
                    callback: (opts: FormatedOption[]) => void,
                ) => loadOptions(user, text, isMdmMode || false, abortCtrl, callback),
                DEFAULT_DEBOUNCE_DELAY,
            ),
        [isMdmMode],
    )

    return (
        <InlineTalentSearch
            disabled={disabled}
            value={value}
            debouncedLoadOptions={debouncedLoadOptionsForCharacter}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onChange={handleOnChange}
            formatOptionLable={formatOptionLable}
        />
    )
}

export const InlineTalentSearchAsCharacter = styled(InlineTalentSearchAsCharacterBase)`
    display: flex;
    flex-basis: 180px
    flex-direction: column;
`
