import React from 'react'
import { useSelector, useActor, useMachine } from '@xstate/react'
import Button from 'shared/components/Button'

import { useCommentsActorRef } from 'shared/resource/ResourceMachineProvider'
import { useIsXStateTransitionAvailable } from 'xstate-helpers/react/useIsXStateTransitionAvailable'
import Text from 'shared/components/Text'
import MediumDate from 'shared/components/MediumDate'
import { useUser } from 'auth/Auth'
import { singleCommentMachineFactory } from 'shared/resource/comments.machine'
import { Comment } from 'model/workflow/Comment'
import { ResourceType } from 'api/fetch/fetchResource'
import Panel from '../Panel'
import styled, { theme } from 'shared/theme'
import { ReactComponent as CloseIcon } from '../Icons/close-icon.svg'
import { ReactComponent as EditIcon } from '../Icons/pencil-icon.svg'
import { ReactComponent as DeleteIcon } from '../Icons/trash-can-icon.svg'
import HumanizeError from 'shared/errors/HumanizeError'
import Loader from '../Loader'
import Alert from '../Alert'
import LinkifiedText from 'shared/components/LinkifiedText'

const StyledPanel = styled(Panel)`
    z-index: 10;
    position: relative;
    top: 0;
    width: 95%%;
    height: calc(100vh - 16.5rem);
    paddingtop: 1.125rem;
    transform: translateX(101%);
    transition: transform 0.4s ease;
    border-radius: unset;
`

const StyledTextArea = styled.textarea`
    resize: none;
    border-color: ${props => props.theme.colors.border};
    border-radius: 2px;
`

const StyledCommentsList = styled.div`
    > * + * {
        margin-top: 1rem;
        border-top: 1px solid ${props => props.theme.colors.border};
    }
`
const StyledHr = styled.hr`
    box-sizing: border-box;
    width: 100%;
    border-top: 1px ${props => props.theme.colors.border};
`

const Wrapper = styled.div`
    height: 0;
`

const CommentsSideOverlay: React.FC = () => {
    const textareaRef = React.createRef<HTMLTextAreaElement>()
    const [state, send] = useActor(useCommentsActorRef())
    const isOpen = useSelector(useCommentsActorRef(), state => state.context.isOpen)
    const { resourceId, resourceType } = state.context
    const isCommentCreateAvailalbe = useIsXStateTransitionAvailable(
        useCommentsActorRef(),
        'CREATE_COMMENT',
    )

    const panelInlineStyle = isOpen ? { transform: 'none' } : {}

    return (
        <Wrapper>
            <StyledPanel style={panelInlineStyle}>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Text
                        as="h3"
                        style={{
                            fontSize: '1.375rem',
                            color: theme.colors.textSecondary,
                        }}
                    >
                        Comments in Metadata
                    </Text>

                    <Button
                        aria-label="Toggle comments overlay"
                        variant="outlineNoBorders"
                        onClick={() => send({ type: 'TOGGLE_OPEN' })}
                        style={{ paddingRight: 0 }}
                    >
                        <CloseIcon />
                    </Button>
                </div>

                <StyledHr />

                <div
                    style={{
                        maxHeight: 'calc(100% - calc(220px + 53px))',
                        overflowY: 'scroll',
                    }}
                >
                    {state.matches('loading') ? (
                        <Loader
                            data-testid="loader-comments"
                            size="smedium"
                            center
                            style={{ marginTop: '50px' }}
                        />
                    ) : state.matches('errorLoading') ? (
                        <div>
                            <br />
                            <Alert>
                                <h3>Error loading comments</h3>
                                <HumanizeError>{state.context.error!}</HumanizeError>

                                <br />

                                <Button onClick={() => send('LOAD_RETRY')}>Retry</Button>
                            </Alert>
                        </div>
                    ) : (
                        <StyledCommentsList>
                            {state.context.comments.map(comment => (
                                <EditableComment
                                    key={`comment-${comment.id}`}
                                    comment={comment}
                                    resourceId={resourceId}
                                    resourceType={resourceType}
                                />
                            ))}
                        </StyledCommentsList>
                    )}
                </div>

                <div
                    style={{
                        position: 'absolute',
                        height: '220px',
                        bottom: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        width: 'calc(100% - 3rem)',
                    }}
                >
                    <StyledHr />
                    <label
                        htmlFor="new-comment"
                        style={{
                            fontSize: '0.9375rem',
                            lineHeight: '1.5rem',
                            fontWeight: 600,
                            margin: '0.5rem 1rem',
                        }}
                    >
                        Your comment
                    </label>

                    <StyledTextArea
                        ref={textareaRef}
                        id="new-comment"
                        name="content"
                        value={state.context.newCommentContent}
                        disabled={state.matches('creating-new-comment')}
                        onChange={e => {
                            const newCommentContent = e.target.value
                            send({
                                type: 'NEW_COMMENT_INPUT',
                                newCommentContent,
                            })
                        }}
                        style={{ height: '100%' }}
                    />

                    <Button
                        disabled={!isCommentCreateAvailalbe}
                        isLoading={state.matches('creating-new-comment')}
                        type="button"
                        onClick={() => {
                            send({
                                type: 'CREATE_COMMENT',
                            })
                        }}
                        style={{
                            width: 'fit-content',
                            alignSelf: 'flex-end',
                            marginTop: '1rem',
                        }}
                    >
                        Post comment
                    </Button>
                </div>
            </StyledPanel>
        </Wrapper>
    )
}

const StyledActionButton = styled(Button)`
    padding: 0;
`

type EditableCommentProps = {
    comment: Comment
    resourceId: string
    resourceType: ResourceType
}
const EditableComment: React.FC<EditableCommentProps> = ({ comment, resourceId, resourceType }) => {
    const user = useUser()
    const isOwnComment = comment.authorId === user['relationship.employeeId']
    const [state, send] = useMachine(
        () =>
            singleCommentMachineFactory(
                {
                    resourceId,
                    resourceType,
                    isOwnComment,
                },
                comment,
            ),
        { devTools: true },
    )

    React.useEffect(() => {
        send({ type: 'UPDATE_COMMENT', comment })
    }, [comment, send])

    return (
        <div
            style={{
                lineHeight: '1.5rem',
                fontSize: '0.9375rem',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                paddingTop: '1rem',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    marginBottom: '0.5rem',
                    height: '30px',
                }}
            >
                <Text weight="bold" style={{ marginRight: '1rem' }}>
                    {comment.author.displayName}
                </Text>
                <Text
                    title={comment.addedOn.toISOString()}
                    style={{ color: theme.colors.textSecondary }}
                >
                    <MediumDate date={comment.addedOn} />
                </Text>

                {isOwnComment && state.matches('idle') && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 'auto',
                        }}
                    >
                        <StyledActionButton
                            type="button"
                            size="small"
                            variant="outlineNoBorders"
                            onClick={() => send({ type: 'EDIT_START' })}
                        >
                            <EditIcon />
                        </StyledActionButton>

                        <StyledActionButton
                            type="button"
                            size="small"
                            variant="outlineNoBorders"
                            onClick={() => send({ type: 'DELETE_COMMENT' })}
                        >
                            <DeleteIcon style={{color: '#4b5d80'}} />
                        </StyledActionButton>
                    </div>
                )}
            </div>

            {state.matches('editing') ? (
                <>
                    <StyledTextArea
                        value={state.context.editContent}
                        onChange={e =>
                            send({
                                type: 'EDIT_COMMENT_INPUT',
                                editContent: e.target.value,
                            })
                        }
                        style={{ minHeight: '80px' }}
                    />

                    <div style={{ margin: '1rem 0 0.5rem auto' }}>
                        <Button
                            type="button"
                            size="small"
                            onClick={() => send({ type: 'EDIT_COMMIT' })}
                        >
                            Save
                        </Button>

                        <Button
                            type="button"
                            size="small"
                            variant="outlineNoBorders"
                            onClick={() => {
                                send({ type: 'EDIT_CANCEL' })
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </>
            ) : (
                <div
                    style={{
                        color: theme.colors.textSecondary,
                    }}
                >
                    <LinkifiedText>{comment.content}</LinkifiedText>
                </div>
            )}
        </div>
    )
}

export default CommentsSideOverlay
