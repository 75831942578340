import {
    CREATIVE_WORK_QA_ASSIGNED,
    CREATIVE_WORK_QA_STARTED,
    CREATIVE_WORK_TAGGING_STARTED,
    Workflow,
    WorkflowHelper,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
} from '@genome-web-forms/server'
import { useSelector } from '@xstate/react'
import React from 'react'
import Container from 'shared/components/Container'
import {
    useResourceMachine,
    useResourceMachineSelector,
    useResourceMachineService,
} from 'shared/resource/ResourceMachineProvider'
import { WorkflowMachineActor } from 'shared/resource/workflow.machine'
import { EmittedFrom } from 'xstate'
import { useStateCan } from 'xstate-helpers/react'
import { AlertDialog, AlertDialogLabel } from '@reach/alert-dialog'
import { Formik, Form, Field } from 'formik'
import { AlertDialogButtons } from 'shared/components/AlertModal'
import TextualButton from 'shared/components/TextualButton'
import Button from 'shared/components/Button'
import Loader from 'shared/components/Loader'
import { useMemo } from 'react'
import {
    DropdownMenu,
    DropdownMenuList,
    DropdownMenuItem,
    DropdownMenuItemSeparator,
    DropdownMenuButton,
} from 'shared/components/DropdownMenu'
import NoWrap from 'shared/components/NoWrap'
import StartEditingControl from 'shared/resource/StartEditingControl'
import { useSelfAssign } from 'shared/hooks/useSelfAssign'
import { CREATIVE_WORK_TAGGING_ASSIGNED } from '@genome-web-forms/server'

const DISABLED_NON_WORKFLOW_ACTION_STATES = [
    CREATIVE_WORK_TAGGING_ASSIGNED,
    CREATIVE_WORK_TAGGING_STARTED,
    CREATIVE_WORK_QA_ASSIGNED,
    CREATIVE_WORK_QA_STARTED,
]

const ALL_WORKFLOW_STATES = [
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
]

type WorkflowControlProps = { workflowActorRef: WorkflowMachineActor }
const WorkflowControl = ({ workflowActorRef }: WorkflowControlProps): React.ReactElement | null => {
    const [showRejectModal, setShowRejectModal] = React.useState(false)

    const workflow = useSelector(
        workflowActorRef,
        React.useCallback(
            (state: EmittedFrom<typeof workflowActorRef>) => state.context.workflow,
            [],
        ),
    )
    const workflowId = useMemo(() => workflow.workflowId, [workflow])

    const service = useResourceMachineService()

    const canSelfAssignWorkflow = useStateCan(service, { type: 'WORKFLOW_SELF_ASSIGN', workflow })
    const canUseSelfAssign = useSelfAssign(workflow)
    const canSelfAssign = canSelfAssignWorkflow && canUseSelfAssign

    const canStartWorkflow = useStateCan(service, { type: 'WORKFLOW_START', workflow })
    const canContinueWorkflow = useStateCan(service, { type: 'WORKFLOW_CONTINUE', workflow })
    const canCompleteActiveWorfklow = useStateCan(service, {
        type: 'WORKFLOW_COMPLETE_ACTIVE_WORKFLOW',
        workflow,
    })
    const canPause = useStateCan(service, { type: 'WORKFLOW_PAUSE', workflow })
    const canResume = useStateCan(service, { type: 'WORKFLOW_RESUME', workflow })

    const canPublish = useStateCan(service, { type: 'WORKFLOW_PUBLISH', workflow })
    const canStartReview = useStateCan(service, {
        type: 'WORKFLOW_REVIEW_MAKE_AVAILABLE',
        workflow,
    })
    const canApprove = useStateCan(service, { type: 'WORKFLOW_REVIEW_APPROVE', workflow })
    const canReject = useStateCan(service, { type: 'WORKFLOW_REVIEW_REJECT', workflow })

    const canDeliver = useStateCan(service, { type: 'WORKFLOW_DELIVER', workflow })
    const canDeliverReject = useStateCan(service, { type: 'WORKFLOW_DELIVER_REJECT', workflow })

    const buttons: JSX.Element[] = []

    if (canSelfAssign) {
        const buttonMap: { [x: string]: string } = {
            'Tagging Available': 'Self-Assign Tagging',
            'QA Available': 'Self-Assign QA',
            'Review Available': 'Self-Assign Review',
        }
        buttons.push(
            <DropdownMenuItem
                id="workflow-self-assign-action"
                key={`${workflowId}-self-assign`}
                onSelect={() => service.send({ type: 'WORKFLOW_SELF_ASSIGN', workflow })}
                highlight={+true}
            >
                {buttonMap?.[workflow.state] || `Self-Assign`}
            </DropdownMenuItem>,
        )
    }

    if (canPublish) {
        buttons.push(
            <DropdownMenuItem
                id="workflow-publish-action"
                key={`${workflowId}-publish`}
                onSelect={() => service.send({ type: 'WORKFLOW_PUBLISH', workflow })}
                highlight={+true}
            >
                Publish Workflow
            </DropdownMenuItem>,
        )
    }

    if (canResume) {
        buttons.push(
            <DropdownMenuItem
                id="workflow-resume-action"
                key={`${workflowId}-resume`}
                onSelect={() => service.send({ type: 'WORKFLOW_RESUME', workflow })}
                highlight={+true}
            >
                Resume Workflow
            </DropdownMenuItem>,
        )
    }

    if (canCompleteActiveWorfklow) {
        const buttonMap: { [x: string]: string } = {
            'Tagging Started': 'Complete Tagging',
            'QA Started': 'Complete QA',
        }
        buttons.push(
            <DropdownMenuItem
                id="workflow-complete-active-action"
                key={`${workflowId}-complete-active`}
                onSelect={() =>
                    service.send({ type: 'WORKFLOW_COMPLETE_ACTIVE_WORKFLOW', workflow })
                }
                highlight={+true}
            >
                {buttonMap?.[workflow.state] || `Complete Workflow: ${workflow.state}`}
            </DropdownMenuItem>,
        )
    }

    if (canPause) {
        buttons.push(
            <DropdownMenuItem
                id="workflow-pause-action"
                key={`${workflowId}-pause`}
                onSelect={() => service.send({ type: 'WORKFLOW_PAUSE', workflow })}
            >
                Pause Workflow
            </DropdownMenuItem>,
        )
    }

    if (canStartWorkflow) {
        const buttonMap: { [x: string]: string } = {
            'Tagging Assigned': 'Start Tagging',
            'QA Assigned': 'Start QA',
        }
        buttons.push(
            <DropdownMenuItem
                id="workflow-start-action"
                key={`${workflowId}-start`}
                onSelect={() => service.send({ type: 'WORKFLOW_START', workflow })}
                highlight={+true}
            >
                {buttonMap?.[workflow.state] || `Start Workflow: ${workflow.state}`}
            </DropdownMenuItem>,
        )
    }

    if (canContinueWorkflow) {
        const buttonMap: { [x: string]: string } = {
            'Tagging Started': 'Continue Tagging',
            'QA Started': 'Continue QA',
        }
        buttons.push(
            <DropdownMenuItem
                id="workflow-continue-action"
                key={`${workflowId}-continue`}
                onSelect={() => {
                    service.send({ type: 'WORKFLOW_CONTINUE', workflow })
                }}
                highlight={+true}
            >
                {buttonMap?.[workflow.state] || `Start Working`}
            </DropdownMenuItem>,
        )
    }

    if (canStartReview) {
        buttons.push(
            <DropdownMenuItem
                id="workflow-start-review-action"
                key={`${workflowId}-start-review`}
                onSelect={() => service.send({ type: 'WORKFLOW_REVIEW_MAKE_AVAILABLE', workflow })}
            >
                Start Review
            </DropdownMenuItem>,
        )
    }

    if (canApprove) {
        buttons.push(
            <DropdownMenuItem
                id="workflow-review-approve-action"
                key={`${workflowId}-review-approve`}
                onSelect={() => service.send({ type: 'WORKFLOW_REVIEW_APPROVE', workflow })}
            >
                Approve
            </DropdownMenuItem>,
        )
    }

    if (canReject) {
        buttons.push(
            <DropdownMenuItem
                id="workflow-review-rejected-action"
                key={`${workflowId}-review-rejected`}
                onSelect={() => setShowRejectModal(true)}
            >
                Reject
                {showRejectModal && (
                    <RejectModal
                        workflowActorRef={workflowActorRef}
                        closeModal={() => setShowRejectModal(false)}
                    />
                )}
            </DropdownMenuItem>,
        )
    }

    if (canDeliver) {
        buttons.push(
            <DropdownMenuItem
                id="workflow-deliver-action"
                key={`${workflowId}-deliver`}
                onSelect={() => service.send({ type: 'WORKFLOW_DELIVER', workflow })}
            >
                Deliver
            </DropdownMenuItem>,
        )
    }

    if (canDeliverReject) {
        buttons.push(
            <DropdownMenuItem
                id="workflow-deliver-reject-action"
                key={`${workflowId}-deliver-reject`}
                onSelect={() => service.send({ type: 'WORKFLOW_DELIVER_REJECT', workflow })}
            >
                Reject
            </DropdownMenuItem>,
        )
    }

    return buttons.length !== 0 ? (
        <>
            <DropdownMenuItemSeparator key={`${workflowId}`} onSelect={() => {}}>
                <NoWrap>{WorkflowHelper.title(workflow)}</NoWrap>
            </DropdownMenuItemSeparator>
            {buttons}
        </>
    ) : (
        <DropdownMenuItemSeparator key="workflow-actions-unavailable" onSelect={() => {}}>
            Workflow actions unavailable
        </DropdownMenuItemSeparator>
    )
}

const NonWorkflowControl = ({ enabled }: { enabled: boolean }): React.ReactElement | null => {
    return (
        <>
            <DropdownMenuItemSeparator onSelect={() => {}}>
                <NoWrap>Non-workflow Actions</NoWrap>
            </DropdownMenuItemSeparator>
            {enabled ? (
                <StartEditingControl />
            ) : (
                <DropdownMenuItemSeparator
                    key="non-workflow-actions-unavailable"
                    onSelect={() => {}}
                >
                    Non-workflow actions unavailable
                    <br /> during workflow mode
                </DropdownMenuItemSeparator>
            )}
        </>
    )
}

const AllWorkflowControls = (): React.ReactElement | null => {
    // TODO react.usecallback
    const workflowActors = useResourceMachineSelector(state => state.context.workflowRefs)
    const [state] = useResourceMachine()

    const loading = React.useCallback(() => {
        const isloading = (
            [
                'aquiringLocks',
                'releasing',
                'releasingTaskWorkflow',
                'selfAssignWorkflow',
                'startReviewWorkflow',
                'completingActiveWorkflow',
                'approveWorkflow',
                'rejectWorkflow',
                'publishing',
            ] as any[]
        ).some(state.matches)
        return isloading
    }, [state])

    const isNonWorkflowActionsEnabled = React.useMemo(() => {
        const allWorkflowsStates = workflowActors.map(
            (wa: any) => (wa.getSnapshot().context.workflow as Workflow).state,
        )

        // If no workflow task at all, show non workflow actions
        if (workflowActors.length === 0) return true

        // If some type of content have no workflow task, show non workflow actions
        if (ALL_WORKFLOW_STATES.some(w => !allWorkflowsStates.includes(w))) return true

        // If workflow task states are not included in disabled non workflow actions, Show non workflow actions,
        // Otherwise (every task is under disabled non workflow), don't show it.
        if (allWorkflowsStates.every(s => DISABLED_NON_WORKFLOW_ACTION_STATES.includes(s)))
            return false

        return true
    }, [workflowActors])

    return (
        <Container flex="1" justifyContent="flex-end" pr="2">
            {loading() ? (
                <Loader data-testid="loader-comments" size="small" center />
            ) : (
                <>
                    <DropdownMenu>
                        {({ isExpanded }) =>
                            workflowActors.length !== 0 ? (
                                <>
                                    <DropdownMenuButton id="actions-dropdown">
                                        Actions{' '}
                                        <span aria-hidden>&nbsp;{isExpanded ? `▴` : `▾`}</span>
                                    </DropdownMenuButton>
                                    <DropdownMenuList>
                                        {workflowActors
                                            .map(workflowActorRef => (
                                                <WorkflowControl
                                                    key={
                                                        workflowActorRef.getSnapshot()!.context
                                                            .workflow.workflowId
                                                    }
                                                    workflowActorRef={workflowActorRef}
                                                />
                                            ))
                                            .filter(Boolean)}
                                    </DropdownMenuList>
                                </>
                            ) : null
                        }
                    </DropdownMenu>
                    <DropdownMenu>
                        {({ isExpanded }) => (
                            <>
                                <DropdownMenuButton id="actions-dropdown">
                                    Non-Workflow Actions{' '}
                                    <span aria-hidden>&nbsp;{isExpanded ? `▴` : `▾`}</span>
                                </DropdownMenuButton>
                                <DropdownMenuList>
                                    <NonWorkflowControl enabled={isNonWorkflowActionsEnabled} />
                                </DropdownMenuList>
                            </>
                        )}
                    </DropdownMenu>
                </>
            )}
        </Container>
    )
}

const RejectModal: React.FC<{ closeModal: Function; workflowActorRef: WorkflowMachineActor }> = ({
    closeModal,
    workflowActorRef,
}) => {
    const cancelRef = React.createRef<HTMLButtonElement>()
    const commentRef = React.createRef<HTMLButtonElement>()

    const service = useResourceMachineService()

    setTimeout(() => commentRef.current?.focus(), 0)

    const initialValues: { comment: string } = { comment: '' }

    const workflow = useSelector(
        workflowActorRef,
        React.useCallback(
            (state: EmittedFrom<typeof workflowActorRef>) => state.context.workflow,
            [],
        ),
    )

    const onSubmit = (values: { comment: string }): void => {
        const _workflow: Workflow = { ...workflow, comment: values.comment }
        service.send({ type: 'WORKFLOW_REVIEW_REJECT', workflow: _workflow })
        closeModal()
    }

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues, onSubmit }}>
                {({ values, setValues }) => (
                    <Form>
                        <AlertDialogLabel>
                            Please let us know why you are rejecting the data. Thanks!
                        </AlertDialogLabel>
                        <Container mt={3} mb="2" flexDirection="column">
                            <Field
                                as="textarea"
                                style={{ width: '100%', height: '80%' }}
                                rows="10"
                                name="comment"
                                innerRef={commentRef}
                            />
                        </Container>

                        <AlertDialogButtons>
                            <Button variant="primary" type="submit">
                                Reject
                            </Button>
                            <TextualButton
                                type="button"
                                onClick={() => closeModal()}
                                ref={cancelRef}
                            >
                                Cancel
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

export default AllWorkflowControls
