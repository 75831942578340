import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import noop from 'lodash/noop'

import {
    PERMISSION_DEBUG,
    PERMISSION_WRITE,
    PERMISSION_PUBLISH,
    PERMISSION_APPROVE,
} from '@genome-web-forms/common/auth'

import { Menu, MenuButton, MenuList, MenuItem } from 'shared/components/Menu'
import { ReactComponent as UserIcon } from 'shared/components/Menu/user-profile-icon.svg'
import {
    adminCreateWorkflowAssignmentLink,
    adminCreateWorkflowReportink,
    adminCreateDescriptorsReportLink,
    adminListWorkflowAssignmentLink,
    listWorkflowAssignmentLink,
    lockedTitlesLink,
} from 'routing/routes'
import LogoutButton from 'auth/LogoutButton'
import NotificationBubble, {
    InlineNotificationBubble,
} from 'shared/components/LockedTitles/NotificationBubble'

import styled from 'shared/theme'
import VisuallyHidden from '@reach/visually-hidden'
import { useHasPermission } from '../UserHasPermission'
import { flags } from 'shared/flags'

const REPORT_BUG_EXTERNAL_URL = 'https://issues.dmed.technology/report/digital-asset/cwr'

const StyledButton = styled(MenuButton)`
    position: relative;
`

const UserMenu: React.FC<{ disableLogout?: boolean }> = ({
    disableLogout = false,
}): React.ReactElement => {
    const isAdmin = useHasPermission(PERMISSION_DEBUG)
    const canWrite = useHasPermission(PERMISSION_WRITE)
    const canPublish = useHasPermission(PERMISSION_PUBLISH)
    const canApproveTagging = useHasPermission(PERMISSION_APPROVE)

    const handleReportBugSelect = useCallback(() => {
        window.open(REPORT_BUG_EXTERNAL_URL, '_blank')
    }, [])

    const canUseWorkflow = isAdmin || canWrite || canPublish || canApproveTagging

    return (
        <Menu>
            <StyledButton>
                <VisuallyHidden>User menu</VisuallyHidden>
                <UserIcon aria-hidden />
                <NotificationBubble aria-hidden />
            </StyledButton>

            <MenuList>
                {flags.workflows && canUseWorkflow && (
                    <MenuItem to={listWorkflowAssignmentLink()} as={Link} onSelect={noop}>
                        Workflow Home
                    </MenuItem>
                )}

                {flags.workflows && isAdmin && (
                    <>
                        <MenuItem
                            to={adminCreateWorkflowAssignmentLink()}
                            as={Link}
                            onSelect={noop}
                        >
                            Create Workflow Assignment
                        </MenuItem>

                        <MenuItem to={adminCreateWorkflowReportink()} as={Link} onSelect={noop}>
                            Create Workflow Report
                        </MenuItem>

                        <MenuItem to={adminCreateDescriptorsReportLink()} as={Link} onSelect={noop}>
                            Create Metadata Report
                        </MenuItem>

                        <MenuItem to={adminListWorkflowAssignmentLink()} as={Link} onSelect={noop}>
                            Workflow Admin
                        </MenuItem>
                        <hr />
                    </>
                )}

                <MenuItem to={lockedTitlesLink()} as={Link} onSelect={noop}>
                    Locked Titles
                    <InlineNotificationBubble aria-hidden />
                </MenuItem>
                <MenuItem onSelect={handleReportBugSelect}>Report a Bug</MenuItem>
                <MenuItem as={LogoutButton} disabled={disableLogout} onSelect={noop}>
                    Logout
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

export default UserMenu
