import React from 'react'
import filter from 'lodash/filter'

import styled from 'shared/theme'
import { STORYROLE_TYPE, StoryRoleAttribute } from 'codecs/storyline/StoryRole'
import { AttributeValue } from 'codecs/Attribute'
import { compareByArc } from 'codecs/Arc'
import Text from 'shared/components/Text'
import { matchTypeOrSubtype } from 'shared/components/Storyrole/matchTypeOrSubtype'

import { TRSValuesForTerms } from 'shared/components/TRS/TRSValue'
import { useTRSSimpleFind } from 'shared/hooks/useTRS'
import { usePureFn } from 'shared/hooks/usePureFn'

const ArcContainer = styled.div`
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: auto min-content;
`
const ArcText = styled(Text).attrs({
    size: '5',
    variant: 'secondary',
})`
    padding: 0.25rem 0;
`
interface StoryroleColumnProps {
    attributes: StoryRoleAttribute[]
    storyRoleType: STORYROLE_TYPE
}

const StoryroleColumn: React.FC<StoryroleColumnProps> = ({
    attributes,
    storyRoleType,
}): React.ReactElement => {
    const rows = React.useMemo(
        () => filter(attributes, matchTypeOrSubtype(storyRoleType)).sort(compareByArc),
        [attributes, storyRoleType],
    )

    const { terms } = useTRSSimpleFind(storyRoleType.typeId, false)
    const valueToTermId = usePureFn((value: AttributeValue) => value.attributeId)
    const getValueLabel = usePureFn((value: AttributeValue, term) =>
        term ? term.label : value.attributeLabel,
    )

    const commonValueProps = {
        terms,
        valueToTermId,
        getValueLabel,
    }

    return (
        <>
            {rows.map((row, i) => (
                <ArcContainer key={row.arc + String(i)}>
                    <TRSValuesForTerms<AttributeValue> {...commonValueProps} values={row.values} />
                    <ArcText>{row.arc}</ArcText>
                </ArcContainer>
            ))}
        </>
    )
}

export default StoryroleColumn
