import React from 'react'
import Highlight from 'shared/search/Highlight'
import Text from 'shared/components/Text'
import { SearchResult, getTitle } from 'model/search/SearchResult'
type SearchResultTitleProps = {
    result: SearchResult
}
export const SearchResultTitle: React.FC<SearchResultTitleProps> = ({
    result,
}): React.ReactElement => {
    const { genomeObject, highlights } = result

    const highlightedTitle: string = React.useMemo(() => {
        return highlights?.['data_disney_com_cpm.titleNameDisambiguated'] ?? getTitle(genomeObject)
    }, [highlights, genomeObject])

    return (
        <Text size="5">
            <Highlight title={getTitle(genomeObject)} highlight={highlightedTitle} />
        </Text>
    )
}

export default SearchResultTitle
