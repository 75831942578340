import React from 'react'
import { FieldArray } from 'formik'
import ErrorMessage from 'shared/components/ErrorMessage'
import styled from 'shared/theme'

import { StoryRole } from 'codecs/storyline/StoryRole'
import StoryroleAttributes from 'shared/components/Storyrole/StoryroleAttributes'
import CellLabel from 'shared/components/CellLabel'
import { ReactComponent as TrashCanIcon } from 'shared/components/Icons/trash-can-icon.svg'
import FormikCharacterSelect from 'shared/components/Select/FormikCharacterSelect'
import DeleteButton from 'shared/components/DeleteButton'
import DuplicateDataWarning from 'shared/components/DuplicateDataWarning'
import PublishErrors from 'shared/components/PublishErrors'
import { usePublishErrorManager } from 'shared/resource/ResourceMachineProvider'
import { CastMember } from 'codecs/CastMember'

const StoryroleContainer = styled.div<{ hasErrors?: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    position: relative;

    ${props => props.hasErrors && `background-color: ${props.theme.colors.backgroundError};`}
`

const CharacterContainer = styled.div`
    border-top: 1px solid ${props => props.theme.colors.border};
    padding: 0.5rem 1rem;
`

const PublishErrorsStyled = styled(PublishErrors)`
    padding: 0.5rem 1rem;
    border-top: 1px solid ${props => props.theme.colors.border};
`

interface Props {
    storyRole: StoryRole
    index: number
    characters: CastMember[]
    remove: (index: number) => void
}

const StoryroleEdit: React.FC<Props> = ({
    storyRole,
    index: storyRoleIndex,
    characters,
    remove,
}): React.ReactElement => {
    const showWarningForMultipleCharacters = storyRole.character.length > 1
    const publishErrors = usePublishErrorManager().getErrors(
        (u): u is StoryRole => u.storyRoleId === storyRole.storyRoleId,
    )

    return (
        <StoryroleContainer hasErrors={publishErrors.length > 0}>
            <CharacterContainer>
                <CellLabel>Character</CellLabel>

                <DeleteButton onClick={() => remove(storyRoleIndex)}>
                    <TrashCanIcon />
                </DeleteButton>

                <FormikCharacterSelect
                    name={`storyRoles[${storyRoleIndex}].character[0]`}
                    characters={characters}
                />
                <ErrorMessage name={`storyRoles[${storyRoleIndex}].character`} />
                {showWarningForMultipleCharacters && <DuplicateDataWarning title="characters" />}
            </CharacterContainer>

            <FieldArray name={`storyRoles[${storyRoleIndex}].attributes`}>
                {({ push, remove }) => (
                    <StoryroleAttributes
                        storyRoleAttributes={storyRole.attributes}
                        storyRoleIndex={storyRoleIndex}
                        push={push}
                        remove={remove}
                    />
                )}
            </FieldArray>

            <PublishErrorsStyled errors={publishErrors} />
        </StoryroleContainer>
    )
}

export default React.memo(StoryroleEdit)
