import styled from 'shared/theme'

import Text from 'shared/components/Text'

const CellLabel = styled(Text).attrs({
    mt: '0px',
    mb: '1',
    as: 'label',
    size: '6',
    weight: 'bold',
    variant: 'secondary',
})<{ htmlFor?: string }>`
`

export default CellLabel
