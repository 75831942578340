import styled from 'shared/theme'
import { ReactComponent as Icon } from './download-file-icon.svg'

export const DownloadFileIcon = styled(Icon)`
    path {
        fill: ${({ theme }) => theme.colors.primary};
    }
`

export default DownloadFileIcon
