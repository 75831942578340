import { UseQueryResult, useQuery } from 'react-query'
import * as t from 'io-ts'

import { request } from '@genome-web-forms/common/api'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { authGWF } from 'api/auth'
import { useUser } from 'auth/Auth'

import config from 'shared/config'

import { TaxonomyLinkFromBackend, TTaxonomyLinkFromBackend } from '../../../model/Associations'
import { useAssociationsContext } from './AssociationsProvider'

export const fetchAssociationsList = (
    user: MyIDUser,
    typeURI: string,
    resourceId: string,
): Promise<TTaxonomyLinkFromBackend> => {
    return request(
        t.exact(TaxonomyLinkFromBackend),
        authGWF(user, {
            url: `${config.urlGWFOntology}/creative-work/taxonomy-links`,
            params: {
                titleId: resourceId,
                typeURI: typeURI,
            },
            method: 'GET',
        }),
    )
}

export const useAssociationsList = (typeURI: string): UseQueryResult<TTaxonomyLinkFromBackend> => {
    const user = useUser()
    const { resourceId } = useAssociationsContext()

    return useQuery<TTaxonomyLinkFromBackend>([typeURI, typeURI], () => {
        return fetchAssociationsList(user, typeURI, resourceId)
    })
}
