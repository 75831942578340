// Hoo boy, here we go...
//
// This is the global config for the app that basically just inlines
// the settings in .env files, which are added to the build process
// either by react-scripts, or env-cmd of .env.ENVIROMENT on top of
// react-scripts.
//
// This means that the values stored here are what was available in env
// when the code was compiled.
//
// However!
//
// We also want to allow external configuration of these variables by config.js.
// That file can be built by ./scripts/build-config.sh, or manually created, and
// basically contains:
//
//      window.GWF_CONFIG = { /* overriding config goes here */}
//
// config.js is loaded by ./public/index.html before the actuall app code. So,
// when window.GWF_CONFIG is present, we use it to override the build-time variables
// that were inlined from .env into this file.

// We also enforce the existance of external config for certain envs by setting
// REACT_APP_REQUIRE_EXTERNAL_CONFIG env var

// basic config with env variables inlined by react-scripts build
const env = process.env as typeof process.env & {
    REACT_APP_URL_AUTH: string
    REACT_APP_URL_CWR: string
    REACT_APP_URL_GWF: string
    REACT_APP_URL_TRS: string
    REACT_APP_URL_GWF_PUBLISH: string
    REACT_APP_URL_GWF_WORKFLOWS: string
    REACT_APP_MY_ID_CLIENT_ID: string
    REACT_APP_REQUIRE_EXTERNAL_CONFIG?: '1'
    REACT_APP_URL_GWF_ONTOLOGY?: string
}

let config = {
    urlAuth: env.REACT_APP_URL_AUTH,
    urlCWR: env.REACT_APP_URL_CWR,
    urlGWF: env.REACT_APP_URL_GWF,
    urlTRS: env.REACT_APP_URL_TRS,
    urlGWFPublish: env.REACT_APP_URL_GWF_PUBLISH || env.REACT_APP_URL_GWF_WORKFLOW,
    urlGWFWorkflows: env.REACT_APP_URL_GWF_WORKFLOWS,
    myIdClientId: env.REACT_APP_MY_ID_CLIENT_ID ?? 'GWF20DevIMP',
    urlGWFOntology: env.REACT_APP_URL_GWF_ONTOLOGY || '',
    urlGWFOntologyV2: env.REACT_APP_URL_GWF_ONTOLOGY_V2 || '',
} as const

const win_ref = global as { GWF_CONFIG?: typeof config }

/**
 * Signify if the config was initialized sucessfully.
 */
export let initialized: boolean

// if available, apply overriding config from `%PUBLIC_URL%/config.js`
if (win_ref.GWF_CONFIG) {
    config = { ...config, ...win_ref.GWF_CONFIG }
    initialized = true
} else if (env.REACT_APP_REQUIRE_EXTERNAL_CONFIG === '1') {
    console.error(`Cannot initialize GWF: Could not load external config.js`)
    initialized = false
} else {
    initialized = true
}

export default config
