import React, { createContext, useCallback, useState } from 'react'
import * as t from 'io-ts'

import { request } from '@genome-web-forms/common/api'
import { authGWF } from 'api/auth'
import { useUser } from 'auth/Auth'
import { useContextHelper } from 'shared/hooks/useContextHelper'
import config from 'shared/config'

import { Association } from '../../../model/Associations'
import { notification } from 'shared/notification'

export type AssociationsContextAPI = {
    associationsList: Association[]
    association: Association | null
    resourceId: string
    isAssociationInEditMode: boolean
    isCreatingNew: boolean
    isSaving: boolean
    setAssociationsList: (associations: Association[]) => void
    updateAssociation: (association: Association, isDeleteRequest?: boolean) => Promise<void>
    editAssociation: (association: Association) => void
    setAssociationInEditMode: (isInEditMode: boolean) => void
    createNew: () => void
    setIsCreatingNew: (isCreatingNew: boolean) => void
    cancelEdit: () => void
}

export const useAssociation = (resourceId: string): AssociationsContextAPI => {
    const [associationsList, setAssociationsList] = useState<Association[]>([])
    const [isAssociationInEditMode, setAssociationInEditMode] = useState(false)
    const [isCreatingNew, setIsCreatingNew] = useState(false)
    const [association, setAssociation] = useState(null)
    const [isSaving, setIsSaving] = useState(false)

    const user = useUser()

    const editAssociation = useCallback(
        association => {
            setAssociation(association)
            setAssociationInEditMode(true)
        },
        [setAssociationInEditMode, setAssociation],
    )

    const cancelEdit = useCallback(() => {
        setAssociation(null)
        setAssociationInEditMode(false)
        setIsCreatingNew(false)
    }, [setAssociationInEditMode, setAssociation])

    const createNew = useCallback(() => {
        setIsCreatingNew(true)
        setAssociationInEditMode(false)
        setAssociation(null)
    }, [setAssociationInEditMode, setAssociation])

    const updateAssociation = (association: Association, isDeleteRequest = false) => {
        setIsSaving(true)
        const payload = {
            titleId: resourceId,
            typeURI: association.type.typeURI,
            associations: isDeleteRequest ? [] : association.type.associations,
        }

        const promise = request(
            t.unknown,
            authGWF(user, {
                url: `${config.urlGWFOntology}/creative-work/taxonomy-links`,
                data: { ...payload },
                method: 'PUT',
            }),
        )
            .then(() => {
                if (isDeleteRequest) {
                    const updated = associationsList.filter(i => i !== association)
                    setAssociationsList(updated)
                } else if (isAssociationInEditMode) {
                    const updated = associationsList.map(a => {
                        if (a.type.typeURI === association.type.typeURI) {
                            return { ...association }
                        }

                        return a
                    })

                    setAssociationsList(updated)
                } else {
                    setAssociationsList([...associationsList, association])
                }
                setIsCreatingNew(false)
                setAssociationInEditMode(false)
            })
            .finally(() => setIsSaving(false))
            .catch(e => {
                notification.error('Something went wrong, please try again.')
            })
        return promise
    }

    return {
        associationsList,
        resourceId,
        isAssociationInEditMode,
        isCreatingNew,
        isSaving,
        association,
        setAssociationsList,
        setAssociationInEditMode,
        setIsCreatingNew,
        editAssociation,
        cancelEdit,
        createNew,
        updateAssociation,
    }
}
export const AssociationsContext = createContext<AssociationsContextAPI | null>(null)

export const AssociationsProvider: React.FC<{ resourceId: string }> = ({
    children,
    resourceId,
}) => {
    const associationApi = useAssociation(resourceId)

    return (
        <AssociationsContext.Provider value={associationApi}>
            {children}
        </AssociationsContext.Provider>
    )
}

export const useAssociationsContext = (): AssociationsContextAPI => {
    const ctx = useContextHelper(AssociationsContext, 'Associations') as AssociationsContextAPI
    return ctx
}
