import React from 'react'
import { NonUndefined } from 'utility-types'

import { MemoSelect, normalizeReactSelectValue, SafeSelectProps } from 'shared/components/Select'
import { TaxonomyTerm } from 'codecs/TRS/Taxonomy'
import { ValueType, ActionMeta, FormatOptionLabelMeta } from 'react-select'

import { substitutePreferredTerms } from 'shared/components/TRS/substitutePreferredTerms'
import { filterTaxonomyTerms } from 'shared/components/TRS/filterTaxonomyTerms'
import { HierarchyOutput, flattenHierarchy } from 'shared/util/flattenHierarchy'
import { useField, useFormikContext } from 'formik'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useTRSLoaded } from 'shared/hooks/useTRS'
import { useStableParamsWarning } from 'shared/hooks/useStableParamsWarning'
import { TRSValue, TRSValueT } from 'shared/components/TRS/TRSValue'
import { TaxonomyTermWithBroaderId } from '../Metadata/StaticAttributeSelect'

type BasicOption = { [key: string]: any }

/**
 * We can either have select options be single or multi (using `isMulti` prop).
 * We use a discriminating union to provide better type interfaces for the
 * `onChange` and `value` props
 */
export type TRSSelectProps<T> = SingeValueSelectProps<T> | MultiValueSelectProps<T>

/**
 * The props that will be exposed from components that wrap a <TRSSelect> implementation
 */
export type ImplementerTRSSelectProps<T> = Omit<
    TRSSelectProps<T>,
    'terms' | 'termToOption' | 'isMulti' | 'getOptionLabel' | 'getOptionValue'
>

type SingeValueSelectProps<T> = CommonSelectProps<T> & {
    isMulti: false
    value: T
    onChange?: (value: T | null, meta: ActionMeta<T>) => void
}

type MultiValueSelectProps<T> = CommonSelectProps<T> & {
    isMulti: true
    value: T[]
    onChange?: (value: T[], meta: ActionMeta<T>) => void
}

/**
 * Common props for the TRSSelectProps discriminating union
 */
type CommonSelectProps<T> = Omit<SafeSelectProps<T>, 'getOptionLabel'> & {
    terms: TaxonomyTermWithBroaderId[]
    /** Transform a TaxonomyTerm to the OptionType for the select */
    termToOption: (term: TaxonomyTerm) => T
    getOptionValue: (option: T) => string
    /** defaults to getOptionValue() */
    optionToTermId?: (option: T) => string
    /**
     * Get the label for an option.
     * If available, the corresponding TaxonomyTerm will be provided, but it's not guaranteed.
     *
     * This means that your implementation should be something like:
     * (option, term) => term ? term.label : option.customLabelProperty
     * */
    getOptionLabel: (option: T, term?: TaxonomyTerm) => string
}

const TRS_SELECT_STABLE_DEPS = [
    'getOptionLabel',
    'getOptionValue',
    'optionToTermId',
    'onChange',
] as const

export function TRSSelect<T extends BasicOption>(props: TRSSelectProps<T>): React.ReactElement {
    const {
        terms,
        termToOption,
        optionToTermId: outerOptionToTermId,
        isMulti,
        value,
        onChange: outerOnChange,
        getOptionValue,
        getOptionLabel: outerGetOptionLabel,
        menuPlacement,
        ...restProps
    } = props

    useStableParamsWarning(props, TRS_SELECT_STABLE_DEPS, 'TRSSelect', 2)

    const optionToTermId = outerOptionToTermId || getOptionValue
    const TRSLoaded = useTRSLoaded()

    const [parsedTerms, rawTermsFlattened] = React.useMemo((): [
        Array<TaxonomyTerm>,
        Array<HierarchyOutput<TaxonomyTermWithBroaderId>>,
    ] => {
        const preferredTerms = substitutePreferredTerms(terms)
        const filteredTerms = filterTaxonomyTerms(preferredTerms)
        const flattenedTerms = flattenHierarchy(filteredTerms)

        return [flattenedTerms, flattenHierarchy<TaxonomyTermWithBroaderId>(terms)]
    }, [terms])

    const options = React.useMemo(() => parsedTerms.map(termToOption), [parsedTerms, termToOption])

    const getTermForHierarchyOption = React.useCallback(
        (option: T): HierarchyOutput<TaxonomyTermWithBroaderId> | undefined => {
            const optionTermId = optionToTermId(option)

            return rawTermsFlattened.find(term => {
                return term.id === optionTermId && term?.broaderId === option?.broaderId
            })
        },
        [optionToTermId, rawTermsFlattened],
    )

    const getTermForOption = React.useCallback(
        (option: T): HierarchyOutput<TaxonomyTermWithBroaderId> | undefined => {
            const optionTermId = optionToTermId(option)

            return rawTermsFlattened.find(term => {
                return term.id === optionTermId
            })
        },
        [optionToTermId, rawTermsFlattened],
    )

    /**
     * Format the option label for a TRS option, adding not for tagging and preferred info
     */
    const formatOptionLabel = React.useCallback(
        (option: T, { context }: FormatOptionLabelMeta<T>): React.ReactElement | string => {
            const termWithHierarchy = getTermForHierarchyOption(option)
            const term = getTermForOption(option)
            const label = outerGetOptionLabel(option, termWithHierarchy)

            switch (context) {
                case 'menu': {
                    // when we're rendering the dropdown menu,
                    // we want to represent hiearchical data

                    const prefix = HIEARCHY_LEVEL_OFFSET_STRING.repeat(
                        (termWithHierarchy && termWithHierarchy.level) || 0,
                    )

                    return prefix + label
                }

                case 'value': {
                    const notForTagging = rawTermsFlattened.length
                        ? term
                            ? // not for tagging if TRS data loaded and term marked as notForTagging
                              !!term.notForTagging
                            : // or if the term doesn't exist in TRS anymore
                              true
                        : false

                    const value: TRSValueT = {
                        label,
                        notForTagging,
                        preferredLabel:
                            term &&
                            term.notForTagging &&
                            term.preferredTerms &&
                            term.preferredTerms.length
                                ? term.preferredTerms[0].label
                                : null,
                    }

                    return <TRSValue value={value} />
                }
            }
        },
        [getTermForHierarchyOption, outerGetOptionLabel, getTermForOption, rawTermsFlattened],
    )

    const onChange = React.useCallback(
        (value: ValueType<T>, meta: ActionMeta<T>) => {
            if (!outerOnChange) {
                return
            }
            const normalizedVal = normalizeReactSelectValue(value, isMulti)
            outerOnChange(normalizedVal as any, meta)
        },
        [isMulti, outerOnChange],
    )

    const getOptionLabel = React.useCallback(
        (option: T) => {
            const term = getTermForHierarchyOption(option)
            return outerGetOptionLabel(option, term)
        },
        [getTermForHierarchyOption, outerGetOptionLabel],
    )

    const isOptionDisabled = React.useCallback(
        (option: T) => {
            const term = getTermForOption(option)
            return !term || !!term.notForTagging
        },
        [getTermForOption],
    )

    return (
        <MemoSelect<T>
            hideSelectedOptions={false}
            closeMenuOnSelect={isMulti ? false : true}
            {...restProps}
            options={options}
            onChange={onChange}
            value={value}
            isLoading={props.isLoading || !TRSLoaded}
            isMulti={isMulti}
            isOptionDisabled={isOptionDisabled}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            formatOptionLabel={formatOptionLabel}
            menuPlacement={menuPlacement}
        />
    )
}

const HIEARCHY_LEVEL_OFFSET_STRING = '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'

export type FormikTRSSelectProps<T> = Omit<TRSSelectProps<T>, 'value'> & {
    name: string
}
export type ImplementerFormikTRSSelectProps<T> = Omit<
    FormikTRSSelectProps<T>,
    'terms' | 'termToOption' | 'isMulti' | 'getOptionLabel' | 'getOptionValue'
>
export function FormikTRSSelect<T extends BasicOption>(
    props: FormikTRSSelectProps<T>,
): React.ReactElement {
    const [{ value }] = useField<T>(props.name)

    const { setFieldValue, setFieldTouched } = useFormikContext<any>()

    let { onChange: outerOnChange, onBlur: outerOnBlur, ...restProps } = props

    const onChange = useEventCallback((value: T | T[] | null, meta: ActionMeta<T>) => {
        setFieldValue(props.name, value)

        if (outerOnChange) {
            outerOnChange(value as any, meta)
        }
    })

    const onBlur: NonUndefined<TRSSelectProps<T>['onBlur']> = useEventCallback(event => {
        setFieldTouched(props.name)
        if (outerOnBlur) {
            outerOnBlur(event)
        }
    })

    const renderProps = { ...restProps, onChange, value, onBlur }

    return <TRSSelect<T> {...(renderProps as any)} />
}
