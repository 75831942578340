import React, { createContext, useContext } from 'react'

import { CastMember } from 'codecs/CastMember'
import { TCharacterCommentsApi, useCharacterComments } from './useCharacterComments'

const CharacterCommentsContext = createContext<TCharacterCommentsApi | null>(null)

export const CharacterCommentsProvider: React.FC<{
    characters: CastMember[]
    canEditCharacters: boolean
}> = ({ children, characters, canEditCharacters }) => {
    const characterCommentsApi = useCharacterComments(characters, canEditCharacters)

    return (
        <CharacterCommentsContext.Provider value={characterCommentsApi}>
            {children}
        </CharacterCommentsContext.Provider>
    )
}

export const useCharacterCommentsContext = (): TCharacterCommentsApi => {
    const ctx = useContext(CharacterCommentsContext) as TCharacterCommentsApi
    return ctx
}
