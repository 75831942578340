import { MyIDUser } from '@genome-web-forms/common/auth'
import { Workflow, WorkflowHelper } from '@genome-web-forms/server'
import { workflowAction, WorkflowActionEvent } from 'api/workflow/workflow'
import { ActorRefFrom, assign, createMachine, send, StateMachine } from 'xstate'
import { createModel } from 'xstate/lib/model'

type Context = {
    user: MyIDUser
    workflow: Workflow
    error?: Error
    lastEvent?: any
}
export const workflowModel = createModel<Context, WorkflowActionEvent>({
    user: null!,
    workflow: null!,
})

export function createWorkflowMachine(
    user: MyIDUser,
    workflow: Workflow,
): ReturnType<typeof workflowModel.createMachine> {
    return workflowModel.createMachine(
        {
            id: `workflow-${workflow.workflowType}-${
                workflow.workflowConfig?.task ?? JSON.stringify(workflow.workflowConfig)
            }`,
            context: { user, workflow },
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        '*': {
                            cond: 'canExecute',
                            target: 'executing',
                            actions: assign({ lastEvent: (_, e) => e }),
                        },
                    },
                },
                executing: {
                    invoke: {
                        src: (ctx, e) => {
                            ctx.workflow = {
                                ...ctx.workflow,
                                ...(ctx.lastEvent?.executionArn
                                    ? { executionArn: ctx.lastEvent.executionArn }
                                    : {}),
                            }
                            return workflowAction(ctx.user, ctx.workflow, e)
                        },
                        onDone: {
                            actions: assign({ workflow: (_, e) => e.data }),
                            target: 'idle',
                        },
                        onError: {
                            target: 'error',
                            actions: ['assignError', 'printError'],
                        },
                    },
                },
                error: {
                    type: 'final',
                },
            },
        },
        {
            actions: {
                assignError: assign({
                    error: (_, event: any) => {
                        return event.data
                    },
                }),
                printError: ({ error, lastEvent }) => {
                    console.error(
                        new Error(
                            `Error executing:\n${JSON.stringify(
                                lastEvent,
                                null,
                                2,
                            )}\n\nError:\n${JSON.stringify(error, null, 2)}`,
                        ),
                    )
                },
            },
            guards: {
                canExecute: ({ user, workflow }, event) =>
                    WorkflowHelper.can(workflow, { ...event, user }),
            },
        },
    )
}

export type WorkflowMachineActor = ActorRefFrom<ReturnType<typeof workflowModel.createMachine>>

type PollerContext = { workflowRef: WorkflowMachineActor }
export const createWorkflowMachinePoller = (
    event: WorkflowActionEvent,
    id = 'workflowMachinePoller',
): StateMachine<PollerContext, any, never, any> =>
    createMachine<{ workflowRef: WorkflowMachineActor }, never>(
        {
            id,
            context: { workflowRef: null! },
            initial: 'initial',
            states: {
                initial: {
                    always: [
                        {
                            target: 'idle',
                            actions: send(event, {
                                to: ctx => ctx.workflowRef,
                            }),
                            cond: ctx => ctx.workflowRef.getSnapshot()!.can(event),
                        },
                        {
                            target: 'failure',
                            actions: ctx =>
                                console.error(
                                    'Workflow poller precognition failed, cannot execute event',
                                    event,
                                    ctx.workflowRef.getSnapshot()!.context.workflow,
                                ),
                        },
                    ],
                },
                idle: { after: { 100: 'polling' } },
                polling: {
                    always: [
                        {
                            cond: 'workflowMachineIdle',
                            target: 'success',
                        },
                        {
                            cond: 'workflowMachineError',
                            target: 'failure',
                        },
                        {
                            target: 'idle',
                        },
                    ],
                },
                success: {
                    type: 'final',
                    data: ctx => ({
                        success: true,
                        workflow: ctx.workflowRef.getSnapshot()!.context.workflow,
                    }),
                },
                failure: {
                    type: 'final',
                    entry: ctx => {
                        console.error('Poller failure state', {
                            event,
                            error: ctx.workflowRef.getSnapshot()!.context.error,
                        })
                    },
                    data: ctx => ({
                        success: false,
                        error: ctx.workflowRef.getSnapshot()!.context.error,
                    }),
                },
            },
        },
        {
            guards: {
                workflowMachineIdle: ({ workflowRef }) => {
                    const state = workflowRef.getSnapshot()!
                    return state.matches('idle')
                },
                workflowMachineError: ({ workflowRef }) => {
                    const state = workflowRef.getSnapshot()!
                    return state.matches('error')
                },
            },
        },
    )
