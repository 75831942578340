import {
    SEARCH_DESCRIPTORS_KEY,
    SEARCH_LOCATION_KEY,
    SEARCH_PRODUCT_ID_KEY,
    SEARCH_PUBLISHED_DATE_KEY,
    SEARCH_RELEASE_DATE_KEY,
    SEARCH_TEXT_KEY,
    SEARCH_TYPE_KEY,
} from 'shared/searchConstants'

import { extractPermissions, MyIDUser } from '@genome-web-forms/common/auth'
import { SupportedFacet } from 'shared/search/useFacetOptions'
import { SearchParams } from 'shared/search/useSearch'

export const DEBOUNCE_TIMEOUT = 1000

export function isSnowplowReady(): boolean {
    return !!window.snowplowVendor
}

export function setUserId(userId: string): void {
    if (isSnowplowReady()) {
        window.snowplowVendor.setUserId(userId)
    } else {
        ;(window as any).spUID = userId
    }
}

export function firePageView(title: string): void {
    if (isSnowplowReady()) {
        window.snowplowVendor.firePageView({
            title: title,
        })
    }
}

export function trackComponent(
    componentType: string,
    componentName: string,
    actionName: string,
    label: string,
    options?: {
        value?: Record<string, any>[]
        params?: Record<string, any>
    },
): void {
    if (isSnowplowReady()) {
        window.snowplowVendor.trackComponent({
            componentType: componentType, // Button
            componentName: componentName, // 'Authority Task Submit'
            actionName: actionName, // click
            label: label, //'Submit Task'
            ...options,
        })
    }
}

export function trackComponentClick(componentType: string, name: string, label: string): void {
    trackComponent(componentType, name, 'click', label)
}

export function trackButtonClick(name: string, label: string): void {
    trackComponentClick('Button', name, label)
}

export function trackCheckboxSelect(name: string, label: string): void {
    trackComponent('Checkbox', name, 'select', label)
}

export function trackPageView(
    pageName: string,
    pageLabel: string,
    queryParams: any | null,
    user?: MyIDUser,
): void {
    const spUser = user ? getSnowplowUser(user) : null
    trackComponent('Page', pageName, 'Page View', pageLabel, {
        value: [
            {
                pageParams: queryParams,
                user: spUser
                    ? {
                          id: spUser.id,
                          name: spUser.name,
                          email: spUser.email,
                      }
                    : null,
            },
        ],
    })
}

export type SnowplowUser = {
    id: string
    email: string
    name: string
    permissions: string[]
}

export const getSnowplowUser = (u: MyIDUser): SnowplowUser => ({
    id: u['relationship.employeeId'],
    email: u.email,
    name: `${u.given_name}} ${u.family_name}`,
    permissions: extractPermissions(u),
})

// TODO: Find how to map facet values: uri -> label
const FacetsMap = {
    [SEARCH_TEXT_KEY]: 'Search text',
    [SEARCH_PRODUCT_ID_KEY]: 'Radar Product ID',
    [SEARCH_TYPE_KEY]: 'Type',
    [SEARCH_DESCRIPTORS_KEY]: 'Descriptors',
    [SEARCH_RELEASE_DATE_KEY]: 'Earliest Release Date',
    [SEARCH_PUBLISHED_DATE_KEY]: 'Earliest Published Date',
    [SEARCH_LOCATION_KEY]: 'Location',
}

export const getFacentNameByKey = (key: SupportedFacet): string => {
    return FacetsMap[key] || 'None'
}

export const getSnowPlowSearchPayload = (
    user: MyIDUser,
    text: string,
    inputParams: SearchParams | undefined,
): any => {
    return {
        searchText: text || null,
        type: '',
    }
}
