import { request } from '@genome-web-forms/common/api'
import * as t from 'io-ts'

import { MyIDUser } from '@genome-web-forms/common/auth'
import { authGWF } from 'api/auth'
import config from 'shared/config'
import {
    NewPortrayalResponse,
    NewPortrayalResponseCodec,
    PortrayalFound,
    PortrayalFoundCodec,
    PortrayalListItem,
    PortrayalListItemCodec,
    RelationshipListSnapshot,
    RelationshipListSnapshotCodec,
    UpdatedPortrayalResponse,
    UpdatedPortrayalResponseCodec,
} from 'model/characters/Portrayal'

export const getPortrayals = (
    resourceId: string, // 'http://data.disney.com/resources/zbd062100-cdc6-4f7f-befb-0d1b3984098e'
    user: MyIDUser,
): Promise<PortrayalListItem[]> => {
    const resIdHash = resourceId.replace('http://data.disney.com/resources/', '')
    const url = `${config.urlGWFOntology}/portrayals/${resIdHash}`
    return request(
        t.array(PortrayalListItemCodec),
        authGWF(user, {
            url: url,
            method: 'GET',
        }),
    )
}

export const searchPortrayals = (
    text: string,
    user: MyIDUser,
    abortCtrl: AbortController,
): Promise<PortrayalFound[]> => {
    const url = `${config.urlGWFOntology}/portrayals?name=${text}&searchSnapshots=false`
    return request(
        t.array(PortrayalFoundCodec),
        authGWF(user, {
            url: url,
            method: 'GET',
            signal: abortCtrl.signal,
        }),
    )
}

export const searchPortrayalByID = (
    portrayalId: string,
    user: MyIDUser,
): Promise<PortrayalFound[]> => {
    // Should we encode?
    const url = `${config.urlGWFOntology}/portrayals?portrayalUri=${encodeURIComponent(
        portrayalId,
    )}&searchSnapshots=false`
    return request(
        t.array(PortrayalFoundCodec),
        authGWF(user, {
            url: url,
            method: 'GET',
        }),
    )
}

export type NewPortrayalPayload = {
    portrayalName: string
    portrays: string[]
    hasScope: string
    portrayedBy: string[]
    portrayalType: string
    associations: string[]
}

export const createNewPortrayal = (
    portrayalMetadata: NewPortrayalPayload,
    user: MyIDUser,
): Promise<NewPortrayalResponse> => {
    const url = `${config.urlGWFOntology}/portrayals`
    return request(
        NewPortrayalResponseCodec,
        authGWF(user, {
            url: url,
            method: 'POST',
            data: portrayalMetadata,
        }),
    )
}

export type PortrayalToUpdate = {
    portrayalID: string
    portrayalName: string
    portrays: string[]
    portrayedBy: string[]
    portrayalType: string
    hasScope: string
    associations: string[]
}

export const updatePortrayal = (
    portrayalMetadata: PortrayalToUpdate,
    user: MyIDUser,
): Promise<UpdatedPortrayalResponse> => {
    const url = `${config.urlGWFOntology}/portrayals`
    return request(
        UpdatedPortrayalResponseCodec,
        authGWF(user, {
            url: url,
            method: 'PUT',
            data: portrayalMetadata,
        }),
    )
}

export type CharRelationshipToSave = {
    characterId: string
    type: string
    targetCharacterId: string
}

export const updateCharacterRelationships = (
    relationshipsMeta: CharRelationshipToSave[],
    user: MyIDUser,
): Promise<RelationshipListSnapshot> => {
    const url = `${config.urlGWFOntology}/characters/relationships/snapshot`
    return request(
        RelationshipListSnapshotCodec,
        authGWF(user, {
            url: url,
            method: 'PUT',
            data: relationshipsMeta,
        }),
    )
}

type PublishCharacterRelationshipsPayload = {
    characterId: string
    type: string
    targetCharacterId: string
}

export const publishCharacterRelationships = (
    character: PublishCharacterRelationshipsPayload[],
    user: MyIDUser,
): Promise<unknown> => {
    const url = `${config.urlGWFOntologyV2}/characters/relationships/published`
    return request(
        t.unknown,
        authGWF(user, {
            url: url,
            method: 'POST',
            data: character,
        }),
    )
}

export const saveCharacterRelationships = (
    relationshipsMeta: CharRelationshipToSave[],
    user: MyIDUser,
): Promise<unknown> => {
    return publishCharacterRelationships(relationshipsMeta, user).then(data => {
        return data
    })
}

type DeleteRelationshipListSnapshotPayload = {
    characterID: string
}

export const deleteRelationshipListSnapshot = (
    character: DeleteRelationshipListSnapshotPayload,
    user: MyIDUser,
): Promise<string> => {
    const url = `${config.urlGWFOntology}/characters/relationships/snapshot/lock`
    return request(
        t.string,
        authGWF(user, {
            url: url,
            method: 'DELETE',
            data: character,
        }),
    )
}

type DeleteRelationshipListPublishedPayload = {
    characterID: string
}

export const deleteRelationshipListPublished = (
    character: DeleteRelationshipListPublishedPayload,
    user: MyIDUser,
): Promise<string> => {
    const url = `${config.urlGWFOntology}/characters/relationships/snapshot/published`
    return request(
        t.string,
        authGWF(user, {
            url: url,
            method: 'DELETE',
            data: character,
        }),
    )
}

export const deleteRelationshipList = (characterID: string, user: MyIDUser): Promise<string> => {
    return deleteRelationshipListPublished({ characterID }, user)
    // ? Backend engineers said that snapshot lock deletion is not required
    // return deleteRelationshipListSnapshot({ characterID }, user).then(() => {
    //     return deleteRelationshipListPublished({ characterID }, user)
    // })
}
