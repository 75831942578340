import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { useErrorHandler } from 'react-error-boundary'
import { useMachine } from '@xstate/react'

import { Series as SeriesLocation } from 'routing/routes'

import { Series, SeriesCodec } from 'model/Series'
import { WIPDataType } from 'model/WIP'
import { metadataMergeAutofill } from 'model/metadata/Metadata'

import { useUser } from 'auth/Auth'

import { fetchResource } from 'api/fetch'
import { metadataFetchAutofill } from 'api/autofill/metadataFetchAutofill'

import { createResourceMachine } from 'shared/resource/resource.machine'
import Loader from 'shared/components/Loader'
import Header from 'shared/components/Header'
import HeaderEditing from 'shared/components/Header/Editing'
import { ResourceMachineServiceContext } from 'shared/resource/ResourceMachineProvider'
import { useRefreshLockedTitles } from 'shared/LockedTitles/LockedTitlesProvider'
import { useHasPortrayalsEditingRoles } from 'shared/hooks/useHasRole'
import { NotFoundError, ForbiddenError } from 'shared/errors/ServerError'

import SeriesNode from 'pages/Series/SeriesNode'

const SeriesPage: React.FC = (): React.ReactElement => {
    const { seriesId } = useParams<SeriesLocation>()
    const handleError = useErrorHandler()
    const refreshLockedTitles = useRefreshLockedTitles()

    const user = useUser()
    const canEditPortrayals = useHasPortrayalsEditingRoles()

    const wips: WIPDataType[] = useMemo(() => {
        return ['metadata', ...(canEditPortrayals ? ['characters' as WIPDataType] : [])]
    }, [canEditPortrayals])

    const [state, , service] = useMachine(
        () =>
            createResourceMachine<Series>({
                resourceId: seriesId,
                resourceType: 'series',
                user,
                wips,
                fetchFn: ctx => fetchResource({ codec: SeriesCodec, ...ctx }),
                handleCriticalError: (_, e: any) => handleError(e.data),
                refreshLockedTitles,
                autofill: {
                    metadata: {
                        fetch: ctx => metadataFetchAutofill(ctx),
                        merge: metadataMergeAutofill,
                    },
                },
            }),
        {
            devTools: true,
        },
    )

    if (state.matches('loading')) {
        return (
            <>
                <Header />
                <Loader center size="normal" />
            </>
        )
    }

    if (state.matches('404')) {
        return (
            <>
                <Header />
                <NotFoundError />
            </>
        )
    }

    if (state.matches('403')) {
        return (
            <>
                <Header />
                <ForbiddenError />
            </>
        )
    }

    return (
        <ResourceMachineServiceContext.Provider value={service}>
            <HeaderEditing />
            <SeriesNode />
        </ResourceMachineServiceContext.Provider>
    )
}

export default SeriesPage
