import { FilterValue } from 'react-table'
import { checkboxFilterType } from './Filters/CheckboxListFilter'
import {
    dateRangeBetweenFilterType,
    dateRangeBetweenPlusFilterType,
} from './Filters/DateRangeBetweenListFilter'

export const filterTypes: Record<string, FilterValue> = {
    checkbox: checkboxFilterType,
    dateRangeBetween: dateRangeBetweenFilterType,
    dateRangeBetweenPlus: dateRangeBetweenPlusFilterType,
}
