import { Workflow } from '@genome-web-forms/server'
import differenceBy from 'lodash/differenceBy'
import omit from 'lodash/omit'
import { getTitle, SearchResult } from 'model/search/SearchResult'
import { AssignmentTitle } from 'model/workflow/CreateWorkflowRequest'
import React from 'react'
import { When } from 'react-if'
import {
    CellProps,
    Column,
    TableInstance,
    TableRowComponentProps,
    useExpanded,
    useRowSelect,
    useTable,
} from 'react-table'
import ReactTooltip from 'react-tooltip'
import Button from 'shared/components/Button'
import Container from 'shared/components/Container'
import IndeterminateCheckbox from 'shared/components/IndeterminateCheckbox'
import Loader from 'shared/components/Loader'
import MediumDate from 'shared/components/MediumDate'
import {
    SearchContainer,
    SearchFormRow,
    SearchHeaderText,
    SearchInput,
    SearchResultsHeader,
    SearchResultTitle,
} from 'shared/components/search'
import { TableNode } from 'shared/components/Table'
import Text from 'shared/components/Text'
import WorkflowTaskName from 'shared/components/Workflow/WorkflowTaskName'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { QuerySizeParams, SearchParams, useSearch } from 'shared/search/useSearch'
import { useInterpreter, useSelector } from './CreateAssignmentProvider'
import TitleSearchRow from './TitleSearchRow'
import { ReactComponent as Warning } from 'shared/components/Icons/warning-icon.svg'
import TitleContentTypeName, {
    DESCRIPTORS_TITLE,
    STORYLINES_RELATIONSHIPS_TITLE,
} from 'shared/components/Workflow/TitleContentTypeName'
import { StyledInfo, TableOptionButtons } from 'pages/Search'
import ExtendedBasicSearch from 'pages/Search/ExtendedBasicSearch'
import styled from 'shared/theme'
import SearchTablePagination, { PaginationOptions } from 'pages/Search/SearchTablePagination'
import CellLabel from 'shared/components/CellLabel'

const fetchWorkflowTasks = true

const StyledWarning = styled(Warning)`
    fill: ${props => props.theme.colors.warning};
    max-width: 20px;
`

const SearchResultsTableWrapper = styled.div`
    th:first-child,
    td:first-child {
        width: 0.8125rem;
    }
`

export type WorkflowTitleSearch = AssignmentTitle & {
    searchResult: SearchResult
    workflows: false | Workflow[]
}

export const TitleSearch: React.FC = (): React.ReactElement => {
    const service = useInterpreter()
    const upstreamTitles = useSelector(React.useCallback(state => state.context.selection, [])).map(
        s => s.title,
    )
    const upstreamTitlesRef = React.useRef(upstreamTitles)
    React.useLayoutEffect(() => {
        upstreamTitlesRef.current = upstreamTitles
    })

    const [inputSearchText, setInputSearchText] = React.useState<string>('')
    const [ebsSearchParams, setEBSSearchParams] = React.useState<SearchParams>()
    const [querySizeParams, setQuerySizeParams] = React.useState<QuerySizeParams>({
        from: 0,
        size: 100,
    })
    const [paginationOptions, setPaginationOptions] = React.useState<PaginationOptions>({
        currentPage: 0,
        totalPages: 1,
    })

    const {
        isLoading,
        isFetching,
        results: _raw_search_results,
        totalHits,
        refetch,
    } = useSearch({
        searchText: inputSearchText,
        searchParams: ebsSearchParams,
        querySizeParams,
        paginationOptions,
        fetchWorkflowTasks,
    })

    const searchResults = React.useMemo(
        () => _raw_search_results.map(searchResultToWorkflowTitle),
        [_raw_search_results],
    )

    const searchWithDueDate = Boolean(
        ebsSearchParams?.dates?.filter((date: any) => date.key === 'dueDate').length,
    )

    const [selectedTitles, _setSelectedTitles] = React.useState<WorkflowTitleSearch[]>([])

    React.useEffect(
        () => {
            if (totalHits)
                setPaginationOptions({
                    ...paginationOptions,
                    totalPages: Math.ceil(totalHits / querySizeParams.size),
                })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [totalHits, querySizeParams],
    )

    React.useEffect(() => {
        refetch()
    }, [refetch, querySizeParams])

    const showSearchResults = React.useMemo(() => {
        return !isFetching && searchResults.length > 0
    }, [isFetching, searchResults.length])

    const setSelectedTitles = React.useCallback<typeof _setSelectedTitles>(v => {
        _setSelectedTitles(selectedTitles => {
            return differenceBy(
                typeof v === 'function' ? v(selectedTitles) : v,
                upstreamTitlesRef.current,
                t => t.resourceId,
            )
        })
    }, [])

    const addSelection = useEventCallback((selectedTitles: WorkflowTitleSearch[]): void => {
        const titles = selectedTitles.map(t => omit(t, 'searchResult'))
        service.send({ type: 'ADD_SELECTED_TITLES', titles })
    })

    const table = useTitleSelectionTable({
        selectedTitles,
        searchResults,
        upstreamTitles,
        setSelectedTitles,
        addSelection,
    })

    const executeAddSelection = (): void => {
        addSelection(selectedTitles)
        setSelectedTitles([])
    }

    const handleSearch = React.useCallback(() => {
        setPaginationOptions({ ...paginationOptions, currentPage: 0 })
        setTimeout(() => refetch(), 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])

    const handleReset = React.useCallback(() => {
        setInputSearchText('')
        setEBSSearchParams(undefined)
        setQuerySizeParams({ ...querySizeParams, from: 0 })
        setPaginationOptions({
            currentPage: 0,
            totalPages: Math.ceil((totalHits ?? 0) / 100),
        })
    }, [totalHits, querySizeParams])

    return (
        <SearchContainer maxWidth="85%">
            <SearchHeaderText size="2" as="h2" mt="2" mr="2">
                Search for titles
            </SearchHeaderText>

            <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                Title Name
            </CellLabel>
            <SearchFormRow>
                <SearchInput
                    autoFocus
                    placeholder="Search using title fragment"
                    value={inputSearchText}
                    onChange={e => setInputSearchText(e.target.value)}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            handleSearch()
                        }
                    }}
                    onReset={() => setInputSearchText('')}
                />
                <Button id="search" type="button" size="large" onClick={handleSearch}>
                    Search
                </Button>
            </SearchFormRow>

            <ExtendedBasicSearch
                isWorkflowSearch={true}
                initialValues={ebsSearchParams}
                onFacetsChange={setEBSSearchParams}
                onSubmit={handleSearch}
            />

            {showSearchResults && (
                <>
                    <SearchResultsHeader>
                        <Container
                            alignItems="flex-end"
                            justifyContent="space-between"
                            style={{ width: '100%' }}
                        >
                            <SearchHeaderText size="2" as="h2" mt="2" mr="2">
                                Search results
                            </SearchHeaderText>

                            <TableOptionButtons>
                                <Button id="reset" type="reset" size="small" onClick={handleReset}>
                                    Clear Search
                                </Button>
                            </TableOptionButtons>
                        </Container>
                    </SearchResultsHeader>

                    <Container mt={2} mb={2} justifyContent={'space-between'}>
                        <Text ml={1}>{totalHits ? `${totalHits} matches found` : ''}</Text>
                        <div>
                            <When condition={searchResults.length > 1}>
                                <Button
                                    size="small"
                                    onClick={executeAddSelection}
                                    disabled={selectedTitles.length === 0}
                                >
                                    Add ({selectedTitles.length}) selected
                                </Button>
                            </When>
                        </div>
                    </Container>
                </>
            )}

            <When condition={isLoading || isFetching}>
                <Loader center size="normal" />
            </When>
            <When condition={!(isLoading || isFetching) && searchResults.length}>
                <SearchResultsTableWrapper>
                    <TableNode tableInstance={table} RowComponent={TitleSearchRow} />
                    <SearchTablePagination
                        {...{
                            querySizeParams,
                            setQuerySizeParams,
                            paginationOptions: searchWithDueDate
                                ? { totalPages: 1, currentPage: 0 }
                                : paginationOptions,
                            setPaginationOptions,
                        }}
                    />
                </SearchResultsTableWrapper>
            </When>
        </SearchContainer>
    )
}

type useTitleSelectionTableOpts = {
    upstreamTitles: AssignmentTitle[]
    searchResults: WorkflowTitleSearch[]
    selectedTitles: WorkflowTitleSearch[]
    setSelectedTitles: React.Dispatch<React.SetStateAction<WorkflowTitleSearch[]>>
    addSelection: (selection: WorkflowTitleSearch[]) => void
}
const useTitleSelectionTable = ({
    searchResults,
    selectedTitles,
    upstreamTitles,
    setSelectedTitles,
    addSelection,
}: useTitleSelectionTableOpts): TableInstance<WorkflowTitleSearch> => {
    const upstreamTitlesRef = React.useRef(upstreamTitles)
    const searchResultsRef = React.useRef(searchResults)
    React.useLayoutEffect(() => {
        upstreamTitlesRef.current = upstreamTitles
        searchResultsRef.current = searchResults
    })

    const toggleSelectedTitle = React.useCallback(
        (title: WorkflowTitleSearch, value?: boolean): void => {
            setSelectedTitles(selectedTitles => {
                const isSelected = !!selectedTitles.find(t => t.resourceId === title.resourceId)
                const shouldExist = typeof value !== 'undefined' ? value : !isSelected

                if (!isSelected && shouldExist) {
                    return [...selectedTitles, title]
                } else if (isSelected && !shouldExist) {
                    return selectedTitles.filter(t => t.resourceId !== title.resourceId)
                }

                return selectedTitles
            })
        },
        [setSelectedTitles],
    )

    const toggleAllSelectedTitles = React.useCallback((): void => {
        setSelectedTitles(selectedTitles => {
            const selectAll = selectedTitles.length === 0
            if (selectAll) {
                return searchResultsRef.current
            }
            return []
        })
    }, [setSelectedTitles])

    const selectedRowIds = React.useMemo<Record<string, boolean>>(() => {
        const result: Record<string, boolean> = {}
        for (const title of selectedTitles) {
            const rowId = searchResults.findIndex(t => t.resourceId === title.resourceId)
            if (rowId > -1) {
                result[rowId] = true
            }
        }
        return result
    }, [searchResults, selectedTitles])

    const getIsRowDisabled = React.useCallback(
        (resourceId: string): boolean => upstreamTitles.map(t => t.resourceId).includes(resourceId),
        [upstreamTitles],
    )

    const columns = React.useMemo<Column<WorkflowTitleSearch>[]>(
        () => [
            {
                id: 'selection',
                disableSortBy: true,
                width: 10,
                Header: instance => (
                    <IndeterminateCheckbox
                        checked={instance.isAllRowsSelected}
                        indeterminate={
                            !instance.isAllRowsSelected &&
                            !!Object.keys(instance.state.selectedRowIds).length
                        }
                        onChange={toggleAllSelectedTitles}
                    />
                ),
                Cell: ({ row, row: { original: title } }: CellProps<WorkflowTitleSearch>) => (
                    <input
                        type="checkbox"
                        checked={row.isSelected}
                        disabled={upstreamTitlesRef.current
                            .map(t => t.resourceId)
                            .includes(title.resourceId)}
                        onChange={e => toggleSelectedTitle(title, e.target.checked)}
                    />
                ),
            },
            {
                id: 'title',
                disableSortBy: true,
                Header: 'Title',
                Cell: ({ row: { original: title } }: CellProps<WorkflowTitleSearch>) => (
                    <SearchResultTitle {...{ result: title.searchResult! }} />
                ),
                width: 900,
            },
            {
                id: 'radarid',
                Header: 'ID',
                disableSortBy: true,
                accessor: t => t.radarId,
                Cell: ({ row: { original: title } }: CellProps<WorkflowTitleSearch>) => (
                    <Text size="5" variant="secondary" as="span" style={{ whiteSpace: 'nowrap' }}>
                        {title.radarId}
                    </Text>
                ),
                width: 100,
            },
            {
                id: 'type',
                Header: 'Type',
                disableSortBy: true,
                accessor: t => t.cwmClassTypeLabel,
                Cell: ({ row: { original: title } }: CellProps<WorkflowTitleSearch>) => (
                    <Text size="5" variant="secondary" as="span" style={{ whiteSpace: 'nowrap' }}>
                        {title.cwmClassTypeLabel}
                    </Text>
                ),
                width: 100,
            },
            {
                id: 'seasonNumber',
                Header: 'Season #',
                accessor: t => t.searchResult,
                Cell: ({
                    row: {
                        original: {
                            searchResult: {
                                genomeObject: { seasonNumber },
                            },
                        },
                    },
                }: CellProps<WorkflowTitleSearch>) => (
                    <Text size="5" variant="secondary" as="span" style={{ whiteSpace: 'nowrap' }}>
                        {seasonNumber}
                    </Text>
                ),
                width: 100,
            },
            {
                id: 'episodeNumber',
                Header: 'Episode #',
                accessor: t => t.searchResult,
                Cell: ({
                    row: {
                        original: {
                            searchResult: {
                                genomeObject: { episodeNumber },
                            },
                        },
                    },
                }: CellProps<WorkflowTitleSearch>) => (
                    <Text size="5" variant="secondary" as="span" style={{ whiteSpace: 'nowrap' }}>
                        {episodeNumber}
                    </Text>
                ),
                width: 100,
            },
            {
                id: 'data',
                Header: 'Data',
                accessor: t => t.resourceId,
                width: 50,
                Cell: ({
                    row,
                    row: {
                        original: {
                            resourceId,
                            searchResult: {
                                genomeObject: {
                                    hasDescriptors,
                                    hasRelationships,
                                    hasStorylines,
                                    publishedDate,
                                },
                            },
                        },
                    },
                }: CellProps<WorkflowTitleSearch>) => {
                    if ([hasDescriptors, hasRelationships, hasStorylines].every(h => !h))
                        return null
                    const hasFlags = [
                        { label: DESCRIPTORS_TITLE, hasData: hasDescriptors },
                        {
                            label: STORYLINES_RELATIONSHIPS_TITLE,
                            hasData: hasStorylines || hasRelationships,
                        },
                    ]
                    const hasDataLabels = hasFlags
                        .filter(({ hasData }) => hasData)
                        .map(({ label }) => label)

                    return (
                        <div style={{ textAlign: 'center' }}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a data-for={`search-row-status-tooltip-${resourceId}`} data-tip>
                                <StyledInfo
                                    onClick={e => {
                                        e.stopPropagation()
                                        row.toggleRowExpanded()
                                    }}
                                ></StyledInfo>
                            </a>
                            <ReactTooltip
                                id={`search-row-status-tooltip-${resourceId}`}
                                multiline={true}
                                effect="solid"
                                getContent={() => (
                                    <Container
                                        flexDirection="column"
                                        style={{ textAlign: 'justify' }}
                                    >
                                        <Text>{`This title has ${hasDataLabels.length} types of data: `}</Text>
                                        <ul>
                                            {hasDataLabels.map(contentType => (
                                                <li
                                                    key={`title-search-title-row-status-task-${resourceId}-${contentType}`}
                                                >
                                                    <TitleContentTypeName
                                                        contentType={contentType}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                        {publishedDate && (
                                            <Text>
                                                {`Last Published Date: `}
                                                <MediumDate date={new Date(publishedDate)} />
                                            </Text>
                                        )}
                                    </Container>
                                )}
                            ></ReactTooltip>
                        </div>
                    )
                },
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: t => t.publishedDate,
                width: 50,
                Cell: ({
                    row,
                    row: {
                        original: { workflows, resourceId },
                    },
                }: CellProps<WorkflowTitleSearch>) => {
                    if (workflows === false || workflows.length === 0) return null
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a data-for={`title-search-title-row-status-${resourceId}`} data-tip>
                                <StyledWarning
                                    onClick={e => {
                                        e.stopPropagation()
                                        row.toggleRowExpanded()
                                    }}
                                ></StyledWarning>
                            </a>
                            <ReactTooltip
                                id={`title-search-title-row-status-${resourceId}`}
                                multiline={true}
                                effect="solid"
                                getContent={() => (
                                    <Container
                                        flexDirection="column"
                                        style={{ textAlign: 'justify' }}
                                    >
                                        <Text>{`There are ${workflows.length} workflow task(s) created for this title`}</Text>
                                        <ul>
                                            {workflows.map(
                                                ({ workflowConfig: { task }, workflowId }) => (
                                                    <li
                                                        key={`title-search-title-row-status-task-${workflowId}`}
                                                    >
                                                        <WorkflowTaskName task={task} />
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                        <Text>Click to show more details</Text>
                                    </Container>
                                )}
                            ></ReactTooltip>
                        </div>
                    )
                },
            },
            {
                id: 'actions',
                Header: 'Actions',
                disableSortBy: true,
                accessor: t => t.cwmClassTypeLabel,
                Cell: ({ row: { original: title } }: CellProps<WorkflowTitleSearch>) => (
                    <Container justifyContent="flex-end">
                        <Button
                            size="small"
                            onClick={() => addSelection([title])}
                            style={{ padding: '0.375rem 1rem' }}
                        >
                            Add
                        </Button>
                    </Container>
                ),
                width: 60,
            },
        ],
        [addSelection, toggleAllSelectedTitles, toggleSelectedTitle],
    )

    return useTable(
        {
            columns,
            data: searchResults,
            useControlledState: React.useCallback(
                state => ({
                    ...state,
                    selectedRowIds,
                }),
                [selectedRowIds],
            ),
            getRowProps: ({ original: title }) => {
                const isDisabled = getIsRowDisabled(title.resourceId)
                return isDisabled ? { isDisabled, title: 'Already selected' } : undefined
            },
            onRowClick: ({ row }: TableRowComponentProps<WorkflowTitleSearch>): void => {
                toggleSelectedTitle(row.original)
            },
        },
        useExpanded,
        useRowSelect,
    )
}

const searchResultToWorkflowTitle = (s: SearchResult): WorkflowTitleSearch => {
    return {
        resourceTitle: getTitle(s.genomeObject),
        resourceId: s.id,
        cwmClassType: s.genomeObject.cwmClassType,
        cwmClassTypeLabel: s.genomeObject.cwmClassTypeLabel,
        radarId: Number(s.genomeObject.productId),
        publishedDate: s.genomeObject.publishedDate,
        isPublished: s.genomeObject.isPublished,
        searchResult: s,
        workflows: s.workflows,
        hasDescriptors: s.genomeObject.hasDescriptors,
        hasRelationships: s.genomeObject.hasRelationships,
        hasStorylines: s.genomeObject.hasStorylines,
    }
}

export default TitleSearch
