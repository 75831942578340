import React, { useCallback } from 'react'
import { Formik } from 'formik'
import * as y from 'yup'
import { ValueType } from 'react-select'

import { Association } from '../../../model/Associations'
import AssociationFormNode from './AssociationsFormNode'
import { useAssociationsContext } from './AssociationsProvider'
import { TOP_CONCEPT_LIST } from './constants'
import { AssocMultiple, AssocSingle, NewAssociation, TopConceptOption } from './types'

const associationsTypeSchema = y
    .object({
        uri: y.string(),
        label: y.string(),
    })
    .required('This field is required')

const validationSchema = y.object({
    type: y
        .object({
            typeURI: y.string().required('This field is required'),
            label: y.string().required('This field is required'),
            associations: y
                .array()
                .of(associationsTypeSchema)
                .min(1, 'To create association you need to select at least one')
                .required('This field is required'),
        })
        .required('This field is required')
        .nullable(),
})

const validationSchemaForSingle = y.object({
    type: y
        .object({
            typeURI: y.string().required('This field is required'),
            label: y.string().required('This field is required'),
            associations: associationsTypeSchema.default(undefined).nullable(),
        })
        .required('This field is required')
        .nullable(),
})

const getValidationSchema = (
    association: Association | null,
): typeof validationSchema | typeof validationSchemaForSingle => {
    const optionCfg = TOP_CONCEPT_LIST.find(c => c.typeURI === association?.type.typeURI)
    return optionCfg?.isSingle ? validationSchemaForSingle : validationSchema
}

const getInitalValues = (
    association: Association | null,
): AssocMultiple | AssocSingle | NewAssociation => {
    let initialValues: any = { type: null }
    if (association) {
        const optionCfg = TOP_CONCEPT_LIST.find(c => c.typeURI === association?.type.typeURI)
        initialValues = optionCfg?.isSingle
            ? {
                  ...association,
                  type: { ...association.type, associations: association.type.associations[0] },
              }
            : association
    }
    return initialValues
}

const AssociationsForm: React.FC<{
    association: Association | null
}> = ({ association }): React.ReactElement => {
    const { updateAssociation } = useAssociationsContext()

    const handleSubmit = useCallback(
        association => {
            const optionCfg = TOP_CONCEPT_LIST.find(c => c.typeURI === association.type.typeURI)
            if (optionCfg?.isSingle) {
                // Normalize single select form value into array
                // Incoming association should be a valid form object
                association.type.associations = [association.type.associations]
            }
            updateAssociation(association)
        },
        [updateAssociation],
    )

    const initSchema = React.useMemo(() => {
        return getValidationSchema(association)
    }, [association])

    const initVal = React.useMemo(() => {
        return getInitalValues(association)
    }, [association])

    const [schema, setSchema] = React.useState<
        typeof validationSchema | typeof validationSchemaForSingle
    >(initSchema)

    const [initialValues, setInitialValues] = React.useState<any>(initVal)

    React.useEffect(() => {
        setSchema(getValidationSchema(association))
        setInitialValues(getInitalValues(association))
    }, [association, setSchema, setInitialValues])

    const handleTopCategoryChange = React.useCallback(
        (value: ValueType<TopConceptOption>) => {
            const optionCfg = TOP_CONCEPT_LIST.find(
                c => c.typeURI === (value as TopConceptOption | null)?.typeURI,
            )
            setSchema(optionCfg?.isSingle ? validationSchemaForSingle : validationSchema)
        },
        [setSchema],
    )

    return (
        <Formik
            initialValues={initialValues}
            validateOnMount={true}
            validationSchema={schema}
            enableReinitialize={true}
            onSubmit={association => {
                handleSubmit(association)
            }}
        >
            {formikProps => {
                return (
                    <AssociationFormNode
                        {...formikProps}
                        onTopCategoryChange={handleTopCategoryChange}
                    />
                )
            }}
        </Formik>
    )
}
export default AssociationsForm
