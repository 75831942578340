import React, { useCallback } from 'react'
import { useField, useFormikContext } from 'formik'

import { usePortrayalsContext } from './portrayalRowsContext'
import { inputStyle } from 'shared/components/Input/index'

import styled from 'shared/theme'

const Input = styled.input`
    ${inputStyle}
`

type PortrayalNameInputProps = { disabled: boolean }

export const PortrayalNameInput: React.FC<PortrayalNameInputProps> = ({ disabled }) => {
    const { portrayalInEdit, setCurrentPortrayal } = usePortrayalsContext()

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value
            portrayalInEdit &&
                setCurrentPortrayal({
                    ...portrayalInEdit,
                    portrayal: { ...portrayalInEdit.portrayal, portrayalName: val },
                })
        },
        [portrayalInEdit, setCurrentPortrayal],
    )

    return (
        <Input
            value={portrayalInEdit?.portrayal.portrayalName || ''}
            onChange={handleChange}
            disabled={disabled}
        />
    )
}

export const FormikPortrayalNameInput: React.FC<PortrayalNameInputProps> = ({ disabled }) => {
    const name = 'portrayal.portrayalName'
    const [{ value }] = useField<string>(name)
    const { setFieldValue } = useFormikContext<any>()

    const handleOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value
            setFieldValue(name, val)
        },
        [setFieldValue],
    )
    return <Input value={value} onChange={handleOnChange} disabled={disabled} />
}
