import { Workflow, WorkflowPriority } from '@genome-web-forms/server'
import { capitalize } from 'lodash'
import React from 'react'
import { CellProps, Column } from 'react-table'
import Table from 'shared/components/Table'
import Text from 'shared/components/Text'
import MediumDate from 'shared/components/MediumDate'
import YesNoBooleanValue from 'shared/components/YesNoBooleanValue'
import NoWrap from 'shared/components/NoWrap'

type EditWorkflowsListProps = {
    data: Workflow[]
}

const EditWorkflowsList: React.FC<EditWorkflowsListProps> = ({ data }): React.ReactElement => {
    const columns = React.useMemo<Column<Workflow>[]>(
        () => [
            {
                accessor: s => s.resourceTitle,
                Header: 'Title',
                width: 200,
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <NoWrap>{original.resourceTitle}</NoWrap>
                ),
            },
            {
                Header: 'Priority',
                width: 100,
                accessor: s => s.priority,
                Cell: ({ value }: CellProps<Workflow, WorkflowPriority | undefined>) =>
                    capitalize(value ?? ''),
            },
            {
                id: 'deadlineAt',
                Header: 'Due Date',
                width: 1,
                accessor: s => s.deadlineAt,
                Cell: ({ value }: CellProps<Workflow, string | undefined>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {value ? <MediumDate date={new Date(value)} /> : null}
                    </Text>
                ),
            },
            {
                id: 'source',
                Header: 'Source',
                accessor: s => s.source,
                width: 1,
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <span style={{ whiteSpace: 'nowrap' }}>{original.source}</span>
                ),
            },
            {
                id: 'reviewRequired',
                Header: 'Needs Review',
                width: 1,
                accessor: s => s.workflowConfig.reviewRequired,
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <YesNoBooleanValue value={original.workflowConfig.reviewRequired} />
                ),
            },
        ],
        [],
    )

    return (
        <Table
            {...{
                data,
                columns,
                scrollable: true,
                customHeight: '300px',
            }}
        />
    )
}

export default EditWorkflowsList
