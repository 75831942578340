import React, { useMemo } from 'react'
import { fromPairs, get, noop } from 'lodash'
import { FieldArray, Form, Formik } from 'formik'
import { TableRowComponentProps } from 'react-table'
import * as y from 'yup'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'

import { CastMember, DEIAttribute, DEnICharacterAttributes } from 'codecs/CastMember'
import { AlertDialogButtons, AlertDialog, AlertDialogLabel } from 'shared/components/AlertModal'
import Button from 'shared/components/Button'
import Container from 'shared/components/Container'
import StaticAttributeSelect from 'shared/components/Metadata/StaticAttributeSelect'
import { RowComponentDefault, TableCell, TableRow } from 'shared/components/Table'
import TextualButton from 'shared/components/TextualButton'
import ToggleButton from 'shared/components/ToggleButton'
import Autosave from 'shared/form/Autosave'
import { useFormControlsEnabled } from 'shared/resource/ResourceMachineProvider'
import { SafeForFormik, safeFormikData, safeFormikName } from 'shared/util/formikSafe'
import { ErrorText } from 'shared/components/Characters/ErrorText'
import { useCharacterCommentsContext } from 'shared/components/Characters/characterCommentsContext'

import { MemoizedCompoundAttribute as CompoundAttribute } from './CompoundAttribute'
import { UNKNOWN_FIELD_LABEL } from 'shared/components/Characters/constants'

type DEnICharacterAttributeDropdown = {
    type: DEIAttribute
    label: string
    placeholder: `Select ${string}`
    isMulti?: boolean
}

const SINGLE_ATTRIBUTES_CONFIG: { [key: string]: DEnICharacterAttributeDropdown } = {
    GENDER: {
        type: DEIAttribute.Genders,
        label: 'Gender',
        placeholder: 'Select Gender',
        isMulti: true,
    },
    RACE_OR_ETHNICITY: {
        type: DEIAttribute.RaceOrEthnicity,
        label: 'Race/Ethnicity',
        placeholder: 'Select Race/Ethnicity',
        isMulti: true,
    },
    SEXUAL_ORIENTATION: {
        type: DEIAttribute.SexualOrientation,
        label: 'Sexual Orientation',
        placeholder: 'Select Sexual Orientation',
        isMulti: true,
    },
    PLACE_OF_ORIGIN: {
        type: DEIAttribute.PlaceOfOrigin,
        label: 'Place of Origin',
        placeholder: 'Select Place of Origin',
        isMulti: true,
    },
    AGE: {
        type: DEIAttribute.AgeGroups,
        label: 'Age',
        placeholder: 'Select Age',
        isMulti: true,
    },
    RELIGION: {
        type: DEIAttribute.Religion,
        label: 'Religion',
        placeholder: 'Select Religion',
        isMulti: true,
    },
}

const COMPOUND_ATTRIBUTES_CONFIG = {
    DISABILITY: {
        PRIMARY: {
            type: DEIAttribute.Disability,
            label: 'Disability',
            placeholder: 'Select Disability',
        },
        SECONDARY: {
            type: DEIAttribute.DisabilityState,
            label: 'Disability State',
            placeholder: 'Select Disability State',
        },
    },
    MILITARY_BODY: {
        PRIMARY: {
            type: DEIAttribute.MilitaryBody,
            label: 'Military Body',
            placeholder: 'Select Military Body',
        },
        SECONDARY: {
            type: DEIAttribute.MilitaryStatus,
            label: 'Military Status',
            placeholder: 'Select Military Status',
        },
    },
}

const ATTRIBUTE_TYPES = [
    ...Object.keys(SINGLE_ATTRIBUTES_CONFIG).map(k => SINGLE_ATTRIBUTES_CONFIG[k].type),
    COMPOUND_ATTRIBUTES_CONFIG.DISABILITY.PRIMARY.type,
    COMPOUND_ATTRIBUTES_CONFIG.MILITARY_BODY.PRIMARY.type,
]

const SAFE_DISABILITY_ATTR_GROUP_ID = safeFormikName(
    COMPOUND_ATTRIBUTES_CONFIG.DISABILITY.PRIMARY.type,
)

const SAFE_MILITARY_BODY_ATTR_GROUP_ID = safeFormikName(
    COMPOUND_ATTRIBUTES_CONFIG.MILITARY_BODY.PRIMARY.type,
)

// TODO: Consider pulling out a separate interface/type from parent codec "CastMember"
const DEFAULT_ATTRIBUTES: { [x: string]: any } = {}

const attrSchema = y.object({
    // attributeId: y.string().required(),
    // attributeLabel: y.string().required(),
})

const disabilityGroupAttrSchema = y.object({
    primary: attrSchema.required('Field Required'),
    secondary: attrSchema.optional().default(undefined),
})

const militaryBodyGroupAttrSchema = y.object({
    primary: attrSchema.required('Field Required'),
    secondary: attrSchema.required('Field Required'),
})
const validationSchema = y.object({
    attributes: y.object({
        [SAFE_DISABILITY_ATTR_GROUP_ID]: y.array().of(disabilityGroupAttrSchema).optional(),
        [SAFE_MILITARY_BODY_ATTR_GROUP_ID]: y.array().of(militaryBodyGroupAttrSchema).optional(),
    }),
})

const CharacterForm = styled(Form)`
    position: relative;
`

const MainBtnGroup = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`

const ExpandButtonContainer = styled.div`
    text-align: center;
    padding: 0.25rem;
`

const OuterGridGrid = styled.div`
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-column-gap: 1rem;
    padding: 3rem 0.5rem 0;
`

const SingleAttrsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0.5rem;
    place-content: baseline;
`

const SingleSelectContainer = styled.div`
    margin-bottom: 1rem;
`

type CharacterDetailsFormProps = {
    character: CastMember
    onCommentsBtnClick(castMember: CastMember): void
    onUpdateAttributes: (attributes: DEnICharacterAttributes) => void
}

const hasCompoundAttribute = (form: DEnICharacterAttributes, id: string): boolean => {
    return !!form.attributes?.[id]?.length
}

const CharacterDetailsForm = (props: CharacterDetailsFormProps): React.ReactElement | null => {
    const {
        character,
        character: {
            portrayal: { portrayalId, portrayalName },
        },
        onCommentsBtnClick,
        onUpdateAttributes,
    } = props

    const formControlsEnabled = useFormControlsEnabled('characters')

    const { safe: safeAttributes, safeToOriginal } = useAttributes(character.attributes)
    //const { safe: safeAttributes, safeToOriginal } = useAttributes({})
    const {
        allCommentsCounts: commentsCountsMap,
        isCountsLoading,
        isCountsLoadingError,
        loadCommentsCounts,
    } = useCharacterCommentsContext()

    const commentsCount = useMemo(() => {
        const id = character.portrayal.portrayalId
        return (commentsCountsMap as { [key: string]: number })[id] || 0
    }, [commentsCountsMap, character])

    const [showClearAllConfirm, setShowClearAllConfirm] = React.useState(false)

    const updateCharacterAttributes = React.useCallback(
        (character: CastMember) => {
            const { ...updatedCharacter } = character
            const { attributes: unsafeAttributes } = updatedCharacter
            const attributes = unsafeAttributes
                ? safeToOriginal(unsafeAttributes)
                : DEFAULT_ATTRIBUTES

            onUpdateAttributes({ ...updatedCharacter, attributes })
        },
        [onUpdateAttributes, safeToOriginal],
    )
    const debouncedUpdateCharacterAttributes = useDebouncedCallback(updateCharacterAttributes, 1000)

    const initialValues = React.useMemo(() => {
        return {
            ...character,
            attributes: safeAttributes,
        }
    }, [safeAttributes, character])

    if (!safeAttributes) return null

    return (
        <Formik<CastMember>
            initialValues={initialValues}
            onSubmit={debouncedUpdateCharacterAttributes}
            validationSchema={validationSchema}
            enableReinitialize={!formControlsEnabled}
        >
            {({ setFieldValue, values }) => {
                return (
                    <CharacterForm>
                        {formControlsEnabled && <Autosave />}

                        <OuterGridGrid>
                            <SingleAttrsGrid>
                                <SingleSelectContainer>
                                    <StaticAttributeSelect
                                        {...{
                                            formControlsEnabled,
                                            attributeMeta: SINGLE_ATTRIBUTES_CONFIG.GENDER,
                                            isMulti: SINGLE_ATTRIBUTES_CONFIG.GENDER.isMulti,
                                        }}
                                    />
                                </SingleSelectContainer>
                                <SingleSelectContainer>
                                    <StaticAttributeSelect
                                        {...{
                                            formControlsEnabled,
                                            attributeMeta:
                                                SINGLE_ATTRIBUTES_CONFIG.RACE_OR_ETHNICITY,
                                            isMulti:
                                                SINGLE_ATTRIBUTES_CONFIG.RACE_OR_ETHNICITY.isMulti,
                                        }}
                                    />
                                </SingleSelectContainer>
                                <SingleSelectContainer>
                                    <StaticAttributeSelect
                                        {...{
                                            formControlsEnabled,
                                            attributeMeta:
                                                SINGLE_ATTRIBUTES_CONFIG.SEXUAL_ORIENTATION,
                                            isMulti:
                                                SINGLE_ATTRIBUTES_CONFIG.SEXUAL_ORIENTATION.isMulti,
                                        }}
                                    />
                                </SingleSelectContainer>
                                <SingleSelectContainer>
                                    <StaticAttributeSelect
                                        {...{
                                            formControlsEnabled,
                                            attributeMeta: SINGLE_ATTRIBUTES_CONFIG.RELIGION,
                                            isMulti: SINGLE_ATTRIBUTES_CONFIG.RELIGION.isMulti,
                                        }}
                                    />
                                </SingleSelectContainer>
                                <SingleSelectContainer>
                                    <StaticAttributeSelect
                                        {...{
                                            formControlsEnabled,
                                            attributeMeta: SINGLE_ATTRIBUTES_CONFIG.AGE,
                                            isMulti: SINGLE_ATTRIBUTES_CONFIG.AGE.isMulti,
                                        }}
                                    />
                                </SingleSelectContainer>
                                <SingleSelectContainer>
                                    <StaticAttributeSelect
                                        {...{
                                            formControlsEnabled,
                                            attributeMeta: SINGLE_ATTRIBUTES_CONFIG.PLACE_OF_ORIGIN,
                                            isMulti:
                                                SINGLE_ATTRIBUTES_CONFIG.PLACE_OF_ORIGIN.isMulti,
                                        }}
                                    />
                                </SingleSelectContainer>
                            </SingleAttrsGrid>
                            <div>
                                <div
                                    style={{
                                        marginBottom: formControlsEnabled ? '1rem' : 'auto',
                                    }}
                                >
                                    <FieldArray
                                        name={`attributes.${SAFE_DISABILITY_ATTR_GROUP_ID}`}
                                    >
                                        {({ push, remove }) => (
                                            <div
                                                style={{
                                                    marginTop:
                                                        !hasCompoundAttribute(
                                                            values as DEnICharacterAttributes,
                                                            SAFE_DISABILITY_ATTR_GROUP_ID,
                                                        ) && formControlsEnabled
                                                            ? '1.5rem'
                                                            : 'auto',
                                                }}
                                            >
                                                <CompoundAttribute
                                                    push={push}
                                                    remove={remove}
                                                    compoundAttrsMetadata={
                                                        COMPOUND_ATTRIBUTES_CONFIG.DISABILITY
                                                    }
                                                    attributes={values.attributes}
                                                    formControlsEnabled={formControlsEnabled}
                                                    isMulti={false}
                                                />
                                            </div>
                                        )}
                                    </FieldArray>
                                </div>
                                <FieldArray name={`attributes.${SAFE_MILITARY_BODY_ATTR_GROUP_ID}`}>
                                    {({ push, remove }) => (
                                        <>
                                            <CompoundAttribute
                                                push={push}
                                                remove={remove}
                                                compoundAttrsMetadata={
                                                    COMPOUND_ATTRIBUTES_CONFIG.MILITARY_BODY
                                                }
                                                attributes={values.attributes}
                                                formControlsEnabled={formControlsEnabled}
                                                isMulti={false}
                                            />
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        </OuterGridGrid>
                        <Container flexDirection="column">
                            {isCountsLoadingError && (
                                <Container p={1}>
                                    <ErrorText>
                                        An error has occurred while trying to fetch character
                                        comments.
                                    </ErrorText>
                                </Container>
                            )}

                            <Container p={1} style={{ display: 'flex', justifyContent: 'end' }}>
                                {isCountsLoadingError && (
                                    <Button
                                        isLoading={isCountsLoading}
                                        size="small"
                                        type="button"
                                        onClick={() => {
                                            loadCommentsCounts()
                                        }}
                                    >
                                        Retry
                                    </Button>
                                )}
                                <Button
                                    isLoading={isCountsLoading}
                                    size="small"
                                    type="button"
                                    variant="outline"
                                    onClick={() => {
                                        onCommentsBtnClick(character)
                                    }}
                                >
                                    Comments <span>({commentsCount})</span>
                                </Button>
                            </Container>
                        </Container>

                        {formControlsEnabled && (
                            <MainBtnGroup>
                                <Button
                                    id={`reset-${portrayalId}`}
                                    type="button"
                                    size="small"
                                    onClick={() => setShowClearAllConfirm(true)}
                                >
                                    Clear All
                                </Button>
                            </MainBtnGroup>
                        )}

                        {showClearAllConfirm && (
                            <ConfirmClearAllModal
                                closeModal={() => setShowClearAllConfirm(false)}
                                confirm={() => setFieldValue('attributes', DEFAULT_ATTRIBUTES)}
                                portrayalName={portrayalName || UNKNOWN_FIELD_LABEL}
                            />
                        )}
                    </CharacterForm>
                )
            }}
        </Formik>
    )
}

const ConfirmClearAllModal: React.FC<{
    closeModal: Function
    confirm: Function
    portrayalName: string
}> = ({ closeModal, confirm, portrayalName }) => {
    const cancelRef = React.createRef<HTMLButtonElement>()

    const onSubmit = React.useCallback(() => {
        confirm()
        closeModal()
    }, [confirm, closeModal])

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues: {}, onSubmit }}>
                {() => (
                    <Form>
                        <AlertDialogLabel>
                            Are you sure you want to clear all tags for portrayal "{portrayalName}"?
                        </AlertDialogLabel>

                        <AlertDialogButtons>
                            <Button variant="primary" type="submit">
                                Yes
                            </Button>
                            <TextualButton
                                type="button"
                                onClick={() => closeModal()}
                                ref={cancelRef}
                            >
                                Cancel
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

type TCharacterTableProps = TableRowComponentProps<CastMember> & {
    rowProps: any
    canReadWriteAttributes: boolean
    onCommentsBtnClick(): void
}

export const CharactersRow = (props: TableRowComponentProps<CastMember>): React.ReactElement => {
    const {
        allColumns: { length: colSpan },
        row,
        row: { original: character, index },
        rowProps,
        canReadWriteAttributes,
        onCommentsBtnClick,
    } = props as TCharacterTableProps // TODO: To check why 'rowProps' isn't in type TableRowComponentProps

    if (!canReadWriteAttributes) return <RowComponentDefault {...props} />

    const { onUpdateCharacter = noop() } = rowProps ?? {}

    return (
        <>
            <RowComponentDefault {...props} />

            <TableRow>
                <TableCell
                    removeBottomBorderRadius
                    removeTopBorderRadius
                    alignWithPreviousRow
                    colSpan={colSpan}
                    removePadding
                >
                    <ExpandButtonContainer>
                        <ToggleButton
                            onClick={() => row.toggleRowExpanded()}
                            toggled={row.isExpanded}
                        >
                            {row.isExpanded
                                ? 'Hide Character Attributes'
                                : 'Show Character Attributes'}
                        </ToggleButton>
                    </ExpandButtonContainer>
                </TableCell>
            </TableRow>

            {row.isExpanded && (
                <TableRow style={{ position: 'relative', top: '-0.75rem' }}>
                    <TableCell colSpan={props.columns.length}>
                        <CharacterDetailsForm
                            character={character}
                            onCommentsBtnClick={onCommentsBtnClick}
                            onUpdateAttributes={updatedAttributes =>
                                onUpdateCharacter(updatedAttributes, index)
                            }
                        />
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

function useAttributes(inputAttributes: any | null): SafeForFormik<any> {
    // Ensure that the input data has all expected fields populated with empty arrays
    const attributes = React.useMemo(() => {
        return fromPairs(
            ATTRIBUTE_TYPES.map(type => {
                return [type, get(inputAttributes, [type], [])]
            }),
        )
    }, [inputAttributes])

    const res = React.useMemo(() => safeFormikData(attributes), [attributes])

    return res
}

export default CharactersRow
