import React, { useMemo } from 'react'
import Container from 'shared/components/Container'
import { QuerySizeParams } from 'shared/search/useSearch'
import { range } from 'lodash'
import styled from 'shared/theme'

const PaginationButtonsContainer = styled(Container)`
    position: sticky;
    bottom: 15px;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 3px;
    width: fit-content;
    padding: 0.5rem 1.5rem;
    margin: 1rem auto 0;
`
const TablePaginationButton = styled.button`
    cursor: pointer;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 2px;
    margin: 0 0.15rem;
    padding: 0.45rem;
`
const TablePaginationNumber = styled.button<{ selected: boolean; isDots: boolean }>`
    display: block;
    cursor: ${props => (props.isDots ? 'unset' : 'pointer')};
    background-color: ${props => (props.selected ? props.theme.colors.border : 'transparent')};
    border: 1px solid ${props => (props.selected ? props.theme.colors.border : 'transparent')};
    border-radius: 50%;
    height: 2.4rem;
    width: 2.4rem;
    margin: 0 0.15rem;
    padding-bottom: 3px;
`

export type PaginationOptions = {
    currentPage: number
    totalPages: number
}

type SearchTablePaginationProps = {
    querySizeParams: QuerySizeParams
    setQuerySizeParams: (params: QuerySizeParams) => void
    paginationOptions: PaginationOptions
    setPaginationOptions: (params: PaginationOptions) => void
}
const SearchTablePagination = ({
    querySizeParams,
    setQuerySizeParams,
    paginationOptions,
    setPaginationOptions,
}: SearchTablePaginationProps): React.ReactElement | null => {
    const goToPage = (page: number) => {
        setQuerySizeParams({
            ...querySizeParams,
            from: page * 100,
        })
        setPaginationOptions({
            ...paginationOptions,
            currentPage: page,
        })
    }

    const paginationRange: ('...' | number)[] = useMemo(() => {
        const totalPages = paginationOptions.totalPages
        const currentPage = paginationOptions.currentPage
        const SIBLING_COUNT = 1
        const maxPaginationNumbers = SIBLING_COUNT + 5

        if (maxPaginationNumbers >= totalPages) return range(0, totalPages)

        const leftSiblingIdx = Math.max(currentPage - SIBLING_COUNT, 0)
        const rightSiblingIdx = Math.min(currentPage + SIBLING_COUNT, totalPages)
        const showLeftDots = leftSiblingIdx > 2
        const showRightDots = rightSiblingIdx < totalPages - 1

        const firstPageIdx = 0

        if (!showLeftDots && showRightDots) {
            const leftItemCount = 3 + 2 * SIBLING_COUNT
            const leftRange = range(0, leftItemCount)
            return [...leftRange, '...', totalPages - 1]
        }

        if (showLeftDots && !showRightDots) {
            const rightItemCount = 3 + 2 * SIBLING_COUNT
            const rightRange = range(totalPages - rightItemCount, totalPages)
            return [firstPageIdx, '...', ...rightRange]
        }

        if (showLeftDots && showRightDots) {
            const middleRange = range(leftSiblingIdx, rightSiblingIdx)
            return [firstPageIdx, '...', ...middleRange, '...', totalPages - 1]
        }

        return range(0, totalPages)
    }, [paginationOptions])

    return paginationOptions.totalPages ? (
        <PaginationButtonsContainer justifyContent={'center'} alignItems={'center'} mt={1} mb={2}>
            <TablePaginationButton
                onClick={() => {
                    setQuerySizeParams({
                        ...querySizeParams,
                        from: 0,
                    })
                    setPaginationOptions({
                        ...paginationOptions,
                        currentPage: 0,
                    })
                }}
                disabled={paginationOptions.currentPage === 0}
            >
                {'<<'}
            </TablePaginationButton>
            <TablePaginationButton
                onClick={() => {
                    setQuerySizeParams({
                        ...querySizeParams,
                        from: querySizeParams.from - 100,
                    })
                    setPaginationOptions({
                        ...paginationOptions,
                        currentPage: paginationOptions.currentPage - 1,
                    })
                }}
                disabled={paginationOptions.currentPage === 0}
            >
                {'<'}
            </TablePaginationButton>
            {paginationRange.map(page => (
                <TablePaginationNumber
                    key={`btn-num-${page}`}
                    onClick={() => {
                        if (typeof page === 'string') return
                        goToPage(page)
                    }}
                    selected={paginationOptions.currentPage === page}
                    isDots={typeof page === 'string'}
                >
                    {page === '...' ? page : page + 1}
                </TablePaginationNumber>
            ))}
            <TablePaginationButton
                onClick={() => {
                    setQuerySizeParams({
                        ...querySizeParams,
                        from: querySizeParams.from + 100,
                    })
                    setPaginationOptions({
                        ...paginationOptions,
                        currentPage: paginationOptions.currentPage + 1,
                    })
                }}
                disabled={paginationOptions.currentPage === paginationOptions.totalPages - 1}
            >
                {'>'}
            </TablePaginationButton>
            <TablePaginationButton
                onClick={() => {
                    setQuerySizeParams({
                        ...querySizeParams,
                        from: (paginationOptions.totalPages - 1) * 100,
                    })
                    setPaginationOptions({
                        ...paginationOptions,
                        currentPage: paginationOptions.totalPages,
                    })
                }}
                disabled={paginationOptions.currentPage === paginationOptions.totalPages - 1}
            >
                {'>>'}
            </TablePaginationButton>
        </PaginationButtonsContainer>
    ) : null
}

export default SearchTablePagination
