import React from 'react'
import Loader from 'shared/components/Loader'
import Text from 'shared/components/Text'
import styled from 'styled-components'

import {
    TaggingAvailableAttributeKey,
    useDisplayTaggedTaxonomyLabels,
} from 'shared/search/useFacetOptions'
import { flatten, isArray, uniqueId, values } from 'lodash'
import { useDidMountEffect } from 'shared/hooks/useDidMountEffect'
import { CATEGORY_SEPARATOR } from './ExtendedBasicSearch'

const StyledText = styled(Text)`
    color: black;
    padding: 0 0.25em;
    background-color: ${props => props.theme.colors.backgroundPrimary};
    border: 1px solid ${props => props.theme.colors.border};
    word-wrap: break-word;
`

const DisplayGenreLabel: React.FC<{ id?: string; displayGenre: string }> = ({
    displayGenre,
    id = '',
}) => {
    const { loaded, labels } = useDisplayTaggedTaxonomyLabels(displayGenre)

    const [displayLabel] = labels
    if (!loaded) return <Loader center size="tiny" inline={true} />
    return (
        <StyledText id={id} size="5" variant="secondary" as="span">
            {displayLabel}
        </StyledText>
    )
}

const Expand = styled(Text)`
    cursor: pointer;
`

const StyledDisplayGenreLabels = styled.div<{
    expanded: boolean
    maxHeight: number
}>`
    overflow-y: hidden;

    display: flex;
    flex-wrap: wrap;

    min-width: 200px;

    max-height: ${props => props.maxHeight + 'px'};

    transition: all 0.25s linear;

    > * {
        margin: 0 0.5rem 0.5rem 0;
    }
`

type GenresByGroup = { [k in TaggingAvailableAttributeKey]: string[] }

type DisplayGenreLabelsProps = {
    genres: GenresByGroup | string[] | string
    productId: number
    maxCollapsedItems?: number
}

const DisplayGenreLabels: React.FC<DisplayGenreLabelsProps> = ({
    genres,
    productId,
    maxCollapsedItems = 4,
}) => {
    const ref = React.useRef<any>(null)

    const [collapsed, setCollapsed] = React.useState<boolean>(true)
    const [expandedMaxHeight, setExpandedMaxHeight] = React.useState<number>(maxCollapsedItems * 40)

    useDidMountEffect(() => {
        setTimeout(() => setExpandedMaxHeight((ref?.current as any)?.scrollHeight), 0)
    }, [ref])

    React.useEffect(() => {
        if (!collapsed) setExpandedMaxHeight((ref?.current as any)?.scrollHeight)
    }, [ref, collapsed])

    const _genres: string[] = React.useMemo(() => {
        if (!genres) return []
        if (isArray(genres) && !genres?.length) return []
        if (typeof genres === 'string') return [genres]
        //map and split added to support categoryId---termId format
        if (isArray(genres))
            return Array.from(new Set(genres)).map(
                v => v.split(CATEGORY_SEPARATOR)?.[0],
            ) as string[]
        if (typeof genres === 'object') return flatten(values(genres))
        return []
    }, [genres])

    const _displayedItems: string[] = React.useMemo(() => {
        if (!genres) return []
        return collapsed ? _genres?.slice(0, maxCollapsedItems) : _genres
    }, [_genres, collapsed, maxCollapsedItems, genres])

    const _displayExpand = React.useMemo(() => {
        return _genres.length > maxCollapsedItems && collapsed
    }, [_genres, collapsed, maxCollapsedItems])

    const expand = React.useCallback(() => setCollapsed(false), [])

    if (!_displayedItems) return null

    return (
        <StyledDisplayGenreLabels ref={ref} expanded={!collapsed} maxHeight={expandedMaxHeight}>
            {_displayedItems.map((hdg: string, index: number) => (
                <DisplayGenreLabel
                    id={`genre-${productId}-${hdg}-${index}`}
                    key={`genre-${productId}-${hdg}-${uniqueId()}`}
                    displayGenre={hdg}
                ></DisplayGenreLabel>
            ))}
            {_displayExpand && (
                <Expand onClick={expand} title={`Show ${_genres.length - maxCollapsedItems} more`}>
                    ...
                </Expand>
            )}
        </StyledDisplayGenreLabels>
    )
}

const areDisplayGenreLabelsEqual = (prev: DisplayGenreLabelsProps, curr: DisplayGenreLabelsProps) =>
    prev.productId === curr.productId

export const EBSDisplayGenreLabels = React.memo(DisplayGenreLabels, areDisplayGenreLabelsEqual)
