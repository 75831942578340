import React from 'react'

import styled from 'shared/theme'
import Empty from 'shared/components/Empty'
import RelationshipForm from './RelationshipForm'
import { useRelationshipsContext } from './index'
import { Table, CheckboxFilter, RowComponentDefault } from 'shared/components/Table'
import { AttributeValue } from 'codecs/Attribute'
import { Column, Row, CellProps, TableRowComponentProps } from 'react-table'
import { Relationship, RelationshipArcAttribute, TRS_RELATIONSHIP_TYPES } from 'codecs/Relationship'
import { compareByArc } from 'codecs/Arc'
import { TRSValuesForTerms } from 'shared/components/TRS/TRSValue'
import { TaxonomyTerm } from 'codecs/TRS/Taxonomy'
import { useTRSSimpleFind } from 'shared/hooks/useTRS'
import Text from 'shared/components/Text'
import flatMap from 'lodash/flatMap'
import { usePublishErrorManager } from 'shared/resource/ResourceMachineProvider'

const ArcText = styled(Text).attrs<{}>({
    size: 5,
    variant: 'secondary',
})`
    padding: 0.25rem 0;
    text-transform: capitalize;
`

const ArcContainer = styled.div`
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: auto min-content;
`

const ArcRows = styled.div`
    display: grid;
    grid-row-gap: 0.5rem;
`

const columns: Column<Relationship>[] = [
    {
        id: 'character1',
        Header: 'Character 1',
        accessor: r => r.characters[0]?.characterName,
        Filter: CheckboxFilter,
        filter: filterCharacters,
        filterAccessor: relationship => {
            return [
                relationship.characters[0]?.characterName,
                relationship.characters[1]?.characterName,
            ]
        },
    },
    {
        id: 'character2',
        Header: 'Character 2',
        accessor: r => r.characters[1]?.characterName,
    },
    {
        Header: 'Relationship Development',
        accessor: relationship => {
            return flatMap(relationship.attributes, attribute =>
                attribute.values.map(a => a.attributeLabel),
            )
        },
        Cell: ({ row: { original: relationship } }: CellProps<Relationship>) => {
            return <RelationshipDevelopment {...{ relationship }} />
        },
        sortType: 'array',
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
]

export function RelationshipsList(): React.ReactElement {
    const publishErrorManager = usePublishErrorManager()
    const { formControlsEnabled, relationships, edit } = useRelationshipsContext()

    return (
        <Table<Relationship>
            {...{
                data: relationships,
                columns,
                onRowClick: ({ row: { original: relationship } }) => {
                    if (formControlsEnabled) {
                        edit(relationship)
                    }
                },
                RowComponent: RelationshipRow,
                getRowProps: ({ original: { relationshipId } }) => ({
                    clickable: formControlsEnabled,
                    hasErrors:
                        formControlsEnabled &&
                        publishErrorManager.hasErrors(
                            (u): u is Relationship => u.relationshipId === relationshipId,
                        ),
                }),
            }}
            emptyMessage={<EmptyRelationships />}
        />
    )
}
const RelationshipRow = (props: TableRowComponentProps<Relationship>): React.ReactElement => {
    const { editIdentifier } = useRelationshipsContext()
    const {
        allColumns,
        row: {
            original: relationship,
            original: { relationshipId },
        },
    } = props

    if (relationshipId !== editIdentifier) {
        return <RowComponentDefault {...props} />
    }

    return (
        <tr key={`editing-${relationshipId}`}>
            <td colSpan={allColumns.length}>
                <RelationshipForm relationship={relationship} />
            </td>
        </tr>
    )
}

type RelationshipAttributeValuesProps = {
    attribute: RelationshipArcAttribute
}
const RelationshipAttributeValues: React.FC<RelationshipAttributeValuesProps> = ({
    attribute,
}): React.ReactElement => {
    const { terms } = useTRSSimpleFind(TRS_RELATIONSHIP_TYPES, true)
    const commonValueProps = React.useMemo(
        () => ({
            terms,
            valueToTermId: (value: AttributeValue) => value.attributeId,
            getValueLabel: (value: AttributeValue, term?: TaxonomyTerm) =>
                term ? term.label : value.attributeLabel,
        }),
        [terms],
    )

    return <TRSValuesForTerms<AttributeValue> {...commonValueProps} values={attribute.values} />
}

function filterCharacters(
    rows: Row<Relationship>[],
    _: any[],
    filterValue: undefined | string[],
): Row<Relationship>[] {
    if (!filterValue) return rows
    if (filterValue.length === 0) return []
    const columnIds = ['character1', 'character2']

    return rows.filter(row => {
        const values = columnIds.map<string>(id => row.values[id])
        return values.some(value => filterValue.includes(value))
    })
}

export default RelationshipsList

const EmptyRelationships: React.FC = (): React.ReactElement => {
    const { formControlsEnabled } = useRelationshipsContext()
    const subtitle = formControlsEnabled
        ? 'Click “+Add relationship” to add a new relationship'
        : undefined

    return <Empty title="No relationships added yet" subtitle={subtitle} />
}

const RelationshipDevelopment: React.FC<{ relationship: Relationship }> = ({
    relationship,
}): React.ReactElement => {
    // display attributes in ARC_SORT order
    const attributes = relationship.attributes.slice().sort(compareByArc)

    return (
        <ArcRows>
            {attributes.map(attribute => (
                <ArcContainer key={attribute.arc}>
                    <RelationshipAttributeValues attribute={attribute} />
                    <ArcText>{attribute.arc}</ArcText>
                </ArcContainer>
            ))}
        </ArcRows>
    )
}
