import React, { FC } from 'react'
import { SearchType } from './types'

export const MdmSearchModeCtrl: FC<{
    isMdmIDOn: boolean
    searchType: SearchType
    onChange: (value: boolean) => void
}> = ({ isMdmIDOn, searchType, onChange }) => {
    return (
        <div>
            <span style={{ paddingLeft: '1rem', fontWeight: 'bold' }}>Search by</span>
            <label
                style={{
                    paddingLeft: '1rem',
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                }}
            >
                <input
                    type="radio"
                    value="searchByMdmId"
                    checked={isMdmIDOn}
                    onChange={() => onChange(true)}
                />
                <span style={{ paddingLeft: '.5rem' }}>MDM ID</span>
            </label>
            <label
                style={{
                    paddingLeft: '1rem',
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                }}
            >
                <input
                    type="radio"
                    value="searchByName"
                    checked={!isMdmIDOn}
                    onChange={() => onChange(false)}
                />
                <span style={{ paddingLeft: '.5rem' }}>
                    {' '}
                    {searchType === SearchType.Character ? 'Character Name' : 'Talent Name'}
                </span>
            </label>
        </div>
    )
}
