import React from 'react'
import PageLayout from 'pages/util/PageLayout'

import { CreateAssignmentsProvider, useActor } from './CreateAssignmentProvider'

import TitleSelection from './TitleSelection'
import AssignmentsConfiguration from './AssignmentsConfiguration'
import AssignmentsCreationStatus from './AssignmentsCreationStatus'
import { useAuthSnowplowPageView } from 'shared/hooks/useSnowPlowPageView'

const CreateAssignments: React.FC = (): React.ReactElement => {
    useAuthSnowplowPageView('Create Workflow Assignment', 'Create Workflow Assignment Page')

    return (
        <PageLayout>
            <CreateAssignmentsProvider>
                <CreateAssignmentsNode />
            </CreateAssignmentsProvider>
        </PageLayout>
    )
}

const CreateAssignmentsNode: React.FC = () => {
    const [state] = useActor()
    const creatingWorkflowsStates = ['create-assignments', 'error', 'success'] as const
    const isCreatingWorkflows = creatingWorkflowsStates.some(state.matches)

    return (
        <>
            {state.matches('selection') && <TitleSelection />}
            {state.matches('configuration') && <AssignmentsConfiguration />}
            {isCreatingWorkflows && <AssignmentsCreationStatus />}
        </>
    )
}

export default CreateAssignments
