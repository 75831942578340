import { Dialog as BaseAlertDialog } from '@reach/dialog'

import '@reach/dialog/styles.css'

import styled from 'shared/theme'

export const Dialog = styled(BaseAlertDialog)`
    border: 1px solid ${props => props.theme.colors.gray};
    box-shadow: 0 0.25rem 0.5rem 0 rgba(75, 93, 128, 0.3);
    border-radius: 0.5rem;
    line-height: 2.5rem;
    text-align: center;
    &[data-reach-dialog-content] {
        padding: 3.5rem;
        min-width: 39.75rem;
        max-width: 60rem;
    }
`

export { DialogContent, DialogOverlay } from '@reach/dialog'
