import React from 'react'

import LinkifyIt from 'linkify-it'
import tlds from 'tlds'

const linkify = new LinkifyIt()
linkify.tlds(tlds)

type LinkifiedTextProps = {
    children: string
}
export function LinkifiedText({ children: text }: LinkifiedTextProps): JSX.Element {
    const matches = linkify.match(text) || []

    let elements: Array<string | JSX.Element> = []
    let lastIndex = 0
    matches.forEach((match, i) => {
        if (match.index > lastIndex) {
            elements.push(text.substring(lastIndex, match.index))
        }
        const aHref = match.url
        const aText = match.text
        const aComponent = (
            // eslint-disable-next-line react/no-array-index-key
            <a key={i} href={aHref} target="_blank" rel="noreferrer">
                {aText}
            </a>
        )
        elements.push(aComponent)
        lastIndex = match.lastIndex
    })

    if (text.length > lastIndex) {
        elements.push(text.substring(lastIndex))
    }

    return <div style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{elements}</div>
}

export default LinkifiedText
