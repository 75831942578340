import React from 'react'

import { cwmClassTypeToLabel } from '@genome-web-forms/common/model/CWMClassType'
import { Workflow } from '@genome-web-forms/server'
import styled from 'shared/theme'
import Text from 'shared/components/Text'
import Table from 'shared/components/Table'
import Container from 'shared/components/Container'
import { Column, CellProps } from 'react-table'
import { useActor } from './CreateAssignmentProvider'
import { Request } from './createAssignment.machine'
import { useFullNameMyIDUser } from 'api/workflow/partialMyIDUsers'
import CreateAssignmentsStepper from './CreateAssignmentsStepper'
import { TASK_TYPE_OPTIONS_ALL } from 'model/workflow/TaskType'
import Loader from 'shared/components/Loader'
import Button from 'shared/components/Button'
import { ReactComponent as CheckIconBase } from 'shared/components/CheckboxRadioInput/Checkbox/icon.svg'
import capitalize from 'lodash/capitalize'
import words from 'lodash/words'
import { Link } from 'react-router-dom'
import { listWorkflowAssignmentLink } from 'routing/routes'
import YesNoBooleanValue from 'shared/components/YesNoBooleanValue'
import MediumDate from 'shared/components/MediumDate'

const Wrapper = styled(Container)`
    max-width: 70%;
    margin: 40px auto 0;
`

const Wrapper2 = styled(Container)`
    margin: 40px auto 0px;
    max-width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
`

const CheckIcon = styled(CheckIconBase)`
    path {
        stroke: ${({ theme }) => theme.colors.primary};
    }
    width: 3.75rem;
    height: 3.75rem;
    vertical-align: middle;
    margin-bottom: -0.125rem;
`
const columns: Column<Request>[] = [
    {
        disableSortBy: true,
        Header: 'Title',
        accessor: 'resourceTitle',
        width: 600,
    },
    {
        id: 'radarId',
        Header: 'ID',
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                {original.radarId}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'type',
        Header: 'Type',
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {cwmClassTypeToLabel(original.cwmClassType)}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'assignee',
        Header: 'Assignee',
        width: 1,
        Cell: ({ row: { original } }: CellProps<Workflow>) => {
            const createdByFullName = useFullNameMyIDUser(original.assignee)
            return <span style={{ whiteSpace: 'nowrap' }}>{createdByFullName}</span>
        },
    },
    {
        id: 'task',
        Header: 'Tasks',
        Cell: ({ row: { original } }: CellProps<Request>) => (
            <span style={{ whiteSpace: 'nowrap' }}>
                {TASK_TYPE_OPTIONS_ALL.find(o => o.value === original.workflowConfig.task)?.label ??
                    (original.workflowConfig.task as string)}
            </span>
        ),
        width: 150,
    },
    {
        Header: 'Priority',
        Cell: ({ row: { original } }: CellProps<Workflow>) => capitalize(original.priority ?? ''),
        width: 1,
    },
    {
        id: 'deadlineAt',
        Header: 'Due Date',
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {original.deadlineAt && <MediumDate date={new Date(original.deadlineAt)} />}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'source',
        Header: 'Source',
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {original.source}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'review',
        Header: 'Review',
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                <YesNoBooleanValue value={original.workflowConfig.reviewRequired} />
            </Text>
        ),
        width: 1,
    },
    {
        id: 'status',
        Header: 'Status',
        disableSortBy: true,
        Cell: ({
            row: {
                original: { _status },
            },
        }: CellProps<Request>) => (
            <div style={{ display: 'flex' }}>
                <>
                    <Text
                        variant={
                            _status === 'error'
                                ? 'danger'
                                : _status === 'success'
                                ? 'success'
                                : 'default'
                        }
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {words(_status).map(capitalize).join(' ')}
                    </Text>
                    {_status === 'pending' && <Loader size="tiny" style={{ paddingTop: '5px' }} />}
                </>
            </div>
        ),
        width: 1,
    },
]

const AssignmentsCreationStatus: React.FC = () => {
    const [state, send] = useActor()
    const requests = state.context.requests

    const created = requests.filter(wf => wf._status === 'success').length

    return (
        <>
            <CreateAssignmentsStepper activeStep={3} />

            {state.matches('success') && (
                <Wrapper2>
                    <CheckIcon />

                    <Container mt={1}>
                        <Text size="2" as="h1" variant="primary">
                            {`${created} Assignment${created === 1 ? '' : 's'} Created`}
                        </Text>
                    </Container>

                    <Container mt={5}>
                        <Button type="button" onClick={() => send({ type: 'RESET' })}>
                            Create another workflow
                        </Button>
                    </Container>
                    <Container mt={2}>
                        <Link to={listWorkflowAssignmentLink()}>
                            <Button type="button">Workflow Home</Button>
                        </Link>
                    </Container>

                    <Container mt={5}></Container>
                </Wrapper2>
            )}

            <Wrapper flexDirection="column" justifyContent="center">
                {state.matches('create-assignments') && (
                    <Container>
                        <Text size="1" as="h1">
                            Creating workflow assignments...
                        </Text>
                    </Container>
                )}

                {state.matches('error') && (
                    <Container>
                        <Text variant="danger">
                            There was an error trying to create the workflow assignments
                        </Text>
                        <Button
                            variant="danger"
                            size="small"
                            type="button"
                            onClick={() => send({ type: 'RETRY_CREATION' })}
                        >
                            Retry creation
                        </Button>
                    </Container>
                )}

                <Table columns={columns} data={requests} />
            </Wrapper>
        </>
    )
}

export default AssignmentsCreationStatus
