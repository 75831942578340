import { cwmClassTypeToLabel } from '@genome-web-forms/common/model/CWMClassType'
import { Workflow } from '@genome-web-forms/server'
import {
    useReleaseWorkflow,
    useSelfAssignWorkflow,
    useWorkflowsQuery,
    WorkflowAssignmentTableType,
} from 'api/workflow/workflow'
import React from 'react'
import { CellProps, Column, TableInstance } from 'react-table'
import { workflowLink } from 'routing/routes'
import Button from 'shared/components/Button'
import Container from 'shared/components/Container'
import { capitalize, toLower, uniqueId } from 'lodash'
import Loader from 'shared/components/Loader'
import MediumDate from 'shared/components/MediumDate'
import NoWrap from 'shared/components/NoWrap'
import { SearchResultLink, WorkflowSearchContainer } from 'shared/components/search'
import SearchInput from 'shared/components/SearchInput'
import Table, { CheckboxFilter, DateRangeBetweenFilter } from 'shared/components/Table'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import Text from 'shared/components/Text'
import { routeTo } from 'model/search/routeTo'
import { notification } from 'shared/notification'
import YesNoBooleanValue from 'shared/components/YesNoBooleanValue'
import workflowTasksTypeLabels from 'shared/components/WorkflowTasksTypeLabels'
import { useSelfAssign } from 'shared/hooks/useSelfAssign'
import { useFullNameMyIDUser, usePartialMyIDUsersQuery } from 'api/workflow/partialMyIDUsers'
import { ActionButtonsGrid } from 'pages/admin/workflow/list'
import { AlertDialog, AlertDialogLabel } from '@reach/alert-dialog'
import { Form, Formik } from 'formik'
import { AlertDialogButtons } from 'shared/components/AlertModal'
import TextualButton from 'shared/components/TextualButton'
import styled from 'shared/theme'
import { WorkflowPriorities } from 'model/workflow/WorkflowPriority'
import { ReactComponent as HistoryIcon } from 'shared/components/Icons/history-icon.svg'

export const ButtonContainer = styled(Button)`
    width: fit-content;
`

export const SearchContainer = styled.div`
    margin: 0 40px;
`

const HistoryIconStyled = styled(HistoryIcon)`
    cursor: pointer;
    padding-top: 0.35rem;
`

const PaddedButton = styled(Button)`
    padding: 0.375rem 1.25rem;
`

const columns = (users: { [key: string]: string }, uid: string): Column<Workflow>[] => [
    {
        id: 'history',
        Header: '',
        sortDescFirst: true,
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <SearchResultLink to={workflowLink(original)}>
                <HistoryIconStyled />
            </SearchResultLink>
        ),
        width: 1,
    },
    {
        id: 'title',
        Header: 'Title',
        accessor: 'resourceTitle',
        width: 200,
        Cell: ({ row: { original } }: CellProps<Workflow>) => {
            // TODO remove this when backend fixes itself
            try {
                return (
                    <SearchResultLink to={routeTo(original)}>
                        <div style={{ textDecoration: 'underline' }}>{original.resourceTitle}</div>
                    </SearchResultLink>
                )
            } catch (e) {
                return original.resourceTitle
            }
        },
    },
    {
        id: 'radarId',
        Header: 'ID',
        accessor: (original: Workflow) => original.radarId.toString(),
        sortDescFirst: true,
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                {original.radarId}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'type',
        Header: 'Type',
        accessor: 'cwmClassTypeLabel',
        filter: 'checkbox',
        Filter: props => <CheckboxFilter {...props} key={uid} />,
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {cwmClassTypeToLabel(original.cwmClassType)}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'task',
        Header: 'Assignment Type',
        accessor: (original: Workflow) =>
            toLower(workflowTasksTypeLabels[original.workflowConfig.task]),
        filter: 'checkbox',
        Filter: props => <CheckboxFilter {...props} key={uid} />,
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {workflowTasksTypeLabels[original.workflowConfig.task as string]}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'assignee',
        Header: 'Assignee',
        accessor: (original: Workflow) =>
            original.assignee && users[original.assignee]
                ? users[original.assignee]
                : original.assignee,
        filter: 'checkbox',
        Filter: props => <CheckboxFilter {...props} key={uid} />,
        Cell: ({ row: { original } }: CellProps<Workflow>) => {
            const asigneeFullName = useFullNameMyIDUser(original?.assignee)

            return (
                <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                    {asigneeFullName}
                </Text>
            )
        },
        width: 1,
    },
    {
        Header: 'Priority',
        accessor: 'priority',
        filter: 'checkbox',
        Filter: props => <CheckboxFilter {...props} key={uid} />,
        sortType: ({ original: a }, { original: b }) => {
            if (!a.priority) {
                return -1
            }
            if (!b.priority) {
                return 1
            }
            return WorkflowPriorities.indexOf(a.priority) - WorkflowPriorities.indexOf(b.priority)
        },
        sortDescFirst: true,
        Cell: ({ row: { original } }: CellProps<Workflow>) => capitalize(original.priority ?? ''),
        width: 1,
    },
    {
        id: 'deadlineAt',
        Header: 'Due Date',
        accessor: 'deadlineAt',
        filter: 'dateRangeBetween',
        Filter: props => <DateRangeBetweenFilter {...props} key={uid} />,
        sortType: 'datetime',
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {original.deadlineAt && <MediumDate date={original.deadlineAt} />}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'dateAssigned',
        Header: 'Creation Date',
        accessor: 'createdAt',
        filter: 'dateRangeBetweenPlus',
        Filter: props => <DateRangeBetweenFilter {...props} key={uid} />,
        sortType: 'datetime',
        Cell: ({
            row: {
                original: { createdAt },
            },
        }: CellProps<Workflow>) => {
            return (
                <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                    {createdAt && <MediumDate date={createdAt} />}
                </Text>
            )
        },
        width: 1,
    },
    {
        id: 'status',
        Header: 'Status',
        accessor: 'state',
        filter: 'checkbox',
        Filter: props => <CheckboxFilter {...props} key={uid} />,
        sortDescFirst: true,
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {original.state}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'source',
        Header: 'Source',
        accessor: (original: Workflow) => original.source,
        filter: 'checkbox',
        Filter: props => <CheckboxFilter {...props} key={uid} />,
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {original.source}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'review',
        Header: 'Review',
        accessor: (original: Workflow) => original.workflowConfig.reviewRequired,
        filter: 'checkbox',
        Filter: props => <CheckboxFilter {...props} key={uid} />,
        sortDescFirst: true,
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                <YesNoBooleanValue value={original.workflowConfig.reviewRequired} />
            </Text>
        ),
        width: 1,
    },
]

const actionsForType = (
    type: WorkflowAssignmentTableType,
    setSelectedWorkflow: Function,
    setShowReleaseModal: Function,
): Column<Workflow> => {
    let Cell: ({ row: { original } }: CellProps<Workflow>) => JSX.Element | null = () => null

    switch (type) {
        case 'TAGGING':
        case 'APPROVAL':
        case 'QA': {
            Cell = ({ row: { original } }) => {
                const selfAssignWorkflow = useSelfAssignWorkflow()

                const canSelfAssign = useSelfAssign(original)

                return canSelfAssign ? (
                    <Button
                        size="small"
                        onClick={() => {
                            selfAssignWorkflow.mutate(original, {
                                onSuccess: () => {
                                    notification.success('Task was self assigned successfully')
                                },
                            })
                        }}
                    >
                        <NoWrap>Self-Assign</NoWrap>
                    </Button>
                ) : null
            }
            break
        }
        case 'SELF': {
            Cell = ({ row: { original } }: CellProps<Workflow>) => {
                return (
                    <ActionButtonsGrid>
                        <ButtonContainer
                            size="small"
                            onClick={() => {
                                setSelectedWorkflow([original])
                                setShowReleaseModal(true)
                            }}
                        >
                            <NoWrap>Abandon</NoWrap>
                        </ButtonContainer>
                    </ActionButtonsGrid>
                )
            }
        }
    }

    return {
        Header: 'Actions',
        Cell,
    }
}

type AssignmentTableProps = {
    type: WorkflowAssignmentTableType
}

export function AssignmentTable({ type }: AssignmentTableProps): React.ReactElement {
    const { isLoading, data = [] } = useWorkflowsQuery(type)
    const [searchText, setSearchText] = React.useState('')

    const [selectedWorkflows, setSelectedWorkflows] = React.useState<Workflow[]>([])
    const [showReleaseModal, setShowReleaseModal] = React.useState(false)

    const [tableInst, setTableInst] = React.useState<TableInstance<Workflow> | null>(null)
    const initialUid = React.useMemo(() => uniqueId(), [])
    const [colFilterUid, setColFilterUid] = React.useState(initialUid)

    const partialUsersQuery = usePartialMyIDUsersQuery()
    const users = React.useMemo<{ [key: string]: string }>(
        () =>
            partialUsersQuery.data!.reduce(
                (obj, key) => ({
                    ...obj,
                    [key['relationship.employeeId']]: `${key.given_name} ${key.family_name}`,
                }),
                {},
            ),
        [partialUsersQuery.data],
    )

    const resolvedColumns = React.useMemo(
        () =>
            [
                ...columns(users, colFilterUid),
                actionsForType(type, setSelectedWorkflows, setShowReleaseModal),
            ].filter(col => {
                switch (type) {
                    case 'TAGGING':
                    case 'DELIVERY':
                    case 'QA':
                        return col.id !== 'status' && col.id !== 'assignee'
                    case 'SELF':
                        return col.id !== 'assignee'
                    case 'APPROVAL':
                        return col.id !== 'status' && col.id !== 'assignee' && col.id !== 'review'
                    default:
                        return true
                }
            }),
        [type, users, colFilterUid],
    )

    const handleTableInstSet = React.useCallback(
        (tbl: TableInstance<Workflow>) => {
            setTableInst(tbl)
        },
        [setTableInst],
    )

    const handleClearFiltersClick = React.useCallback(() => {
        tableInst && tableInst.setAllFilters([])
        setSearchText('')
        setColFilterUid(uniqueId())
    }, [tableInst, setSearchText, setColFilterUid])

    return (
        <WorkflowSearchContainer>
            <Container mb={2} alignItems="center">
                <TableHeaderContent>
                    <Text as="h3" size="2" my="2">
                        Search
                    </Text>

                    <SearchInput value={searchText} onChange={e => setSearchText(e.target.value)} />
                </TableHeaderContent>
                <PaddedButton
                    title="Clear filters to default settings"
                    variant="outlineNoBorders"
                    onClick={handleClearFiltersClick}
                >
                    Clear Filters
                </PaddedButton>
            </Container>

            {isLoading ? (
                <Loader center size="normal" />
            ) : (
                <Table
                    {...{
                        columns: resolvedColumns,
                        data,
                        searchText,
                        initialState: {
                            sortBy: [
                                { desc: false, id: 'deadlineAt' },
                                { desc: true, id: 'priority' },
                            ],
                            pageIndex: 0,
                            pageSize: 100,
                        },
                        pagination: true,
                        onTableInstInit: handleTableInstSet,
                    }}
                />
            )}

            {showReleaseModal && selectedWorkflows.length > 0 && (
                <ConfirmReleaseModal
                    closeModal={() => setShowReleaseModal(false)}
                    workflows={selectedWorkflows}
                />
            )}
        </WorkflowSearchContainer>
    )
}

const ConfirmReleaseModal: React.FC<{ closeModal: Function; workflows: Workflow[] }> = ({
    closeModal,
    workflows,
}) => {
    const cancelRef = React.createRef<HTMLButtonElement>()

    const [loading, setLoading] = React.useState<boolean>(false)

    const releaseWorkflow = useReleaseWorkflow()

    const onSubmit = (): void => {
        setLoading(true)
        releaseWorkflow.mutate(workflows, {
            onSuccess: () => {
                closeModal()
                setLoading(false)
            },
        })
    }

    const initialValues = {}

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues, onSubmit }}>
                {() => (
                    <Form>
                        <AlertDialogLabel>
                            Are you sure you want to abandon the following task(s)?
                        </AlertDialogLabel>

                        <ul>
                            {workflows.map(w => (
                                <li key={w.workflowId}>
                                    {w.resourceTitle} -{' '}
                                    {toLower(
                                        workflowTasksTypeLabels[w.workflowConfig.task as string],
                                    )}
                                </li>
                            ))}
                        </ul>

                        <AlertDialogButtons>
                            <Button
                                variant="primary"
                                disabled={loading}
                                isLoading={loading}
                                type="submit"
                            >
                                <span>Abandon</span>
                            </Button>
                            <TextualButton
                                type="button"
                                onClick={() => closeModal()}
                                ref={cancelRef}
                            >
                                Close
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

export default AssignmentTable
