import * as t from 'io-ts'
import axios, { AxiosResponse } from 'axios'

import { request } from '@genome-web-forms/common/api'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { useUser } from 'auth/Auth'
import {
    CreateWorkflowReport,
    CreateWorkflowReportStartResponse,
    CreateWorkflowReportStatusResponse,
} from 'model/workflow/CreateWorkflowReport'
import { useCallback, useEffect, useState } from 'react'
import config from 'shared/config'
import { authGWF } from '../auth'
import { useQuery } from 'react-query'
import React from 'react'

export const ESResultCodec = t.any

type ExportUrls = {
    start: () => string
    status: () => string
    file: (trackId: string) => string
}

const workflowUrls: ExportUrls = {
    start: () => `${config.urlGWF}/workflow-report/start`,
    status: () => `${config.urlGWF}/workflow-report/status`,
    file: (trackId: string) => `${config.urlGWF}/workflow-report/file?trackId=${trackId}`,
}

const searchTitleUrls: ExportUrls = {
    start: () => `${config.urlGWF}/es-report/start`,
    status: () => `${config.urlGWF}/es-report/status`,
    file: (trackId: string) => `${config.urlGWF}/es-report/file?trackId=${trackId}`,
}

export type UseCreateWorkflowReportReturn = {
    start: any
    trackId: string | undefined
    status: CreateWorkflowReportStatusResponse | undefined
    downloadURL: string
    isLoading: boolean
    error: Error | undefined
}

export const useCreateSearchReport = (refreshOnMillis = 2000): UseCreateWorkflowReportReturn => {
    return useCreateReport(refreshOnMillis, searchTitleUrls)
}

export const useCreateWorkflowReport = (refreshOnMillis = 2000): UseCreateWorkflowReportReturn => {
    return useCreateReport(refreshOnMillis, workflowUrls)
}

export const useCreateReport = (
    refreshOnMillis: number,
    urls: ExportUrls,
): UseCreateWorkflowReportReturn => {
    const user = useUser()
    const [trackId, setTrackId] = useState<string>()
    const [status, setStatus] = useState<CreateWorkflowReportStatusResponse>()
    const [downloadURL, setDownloadURL] = useState<string>('')
    const [isLoading, setIsloading] = useState<boolean>(false)
    const [error, setError] = useState<Error>()

    const start = useCallback(
        async params => {
            setIsloading(true)
            try {
                const response = await createReportStart(urls, user, params)
                setTrackId(response?.trackId)
            } catch (e) {
                setError(e as Error)
            }
        },
        [urls, user],
    )

    useEffect(() => {
        if (!trackId) return

        const refreshStatus = async () => {
            try {
                const status = await fetchReportStatus(urls, user, trackId)

                if (status.status === 'completed') {
                    setDownloadURL(urls.file(status.trackId))
                    setIsloading(false)
                    setTrackId(undefined)
                }

                if (status.status !== 'inprogress') clearInterval(interval)

                setStatus(status)
            } catch (e) {
                clearInterval(interval)
                setIsloading(false)
                console.error(e)
                setError(e as Error)
            }
        }

        refreshStatus()
        const interval = setInterval(refreshStatus, refreshOnMillis)
        return () => clearInterval(interval)
        // eslint-disable-next-line
    }, [trackId])

    return {
        start,
        isLoading,
        trackId,
        status,
        downloadURL,
        error,
    }
}

export const createReportStart = (
    urls: ExportUrls,
    user: MyIDUser,
    params: CreateWorkflowReport | undefined,
): Promise<CreateWorkflowReportStartResponse> => {
    return request(
        t.any,
        authGWF(user, {
            method: 'GET',
            url: urls.start(),
            params: params || {},
        }),
    )
}

export const fetchReportStatus = (
    urls: ExportUrls,
    user: MyIDUser,
    trackId: string,
): Promise<CreateWorkflowReportStatusResponse> => {
    return request(
        t.any,
        authGWF(user, {
            method: 'GET',
            url: urls.status(),
            params: { trackId },
        }),
    )
}

export const fetchWorkflowReportFile = (
    urls: ExportUrls,
    user: MyIDUser,
    trackId: string,
): Promise<CreateWorkflowReportStatusResponse> => {
    return request(
        t.any,
        authGWF(user, {
            method: 'GET',
            url: urls.status(),
            params: { trackId },
        }),
    )
}

export const fetchSearchReportFile = async (user: MyIDUser, url: string): Promise<{url: string | null}>  => {
    if (!url) {
        return  Promise.resolve({ url: null })
    }
    return request(
        t.type({url: t.string}),
        authGWF(user, {
            method: 'GET',
            url: url,
        }), 
    )
}

export const fetchReportFileBlob = async (user: MyIDUser, url: string): Promise<AxiosResponse<Blob>>  => {
    return axios(authGWF(user, {
        method: 'GET',
        responseType: 'blob',
        url: url,
    }));
}

export const downloadReportBlob = (user: MyIDUser, url: string): void => {
    fetchReportFileBlob(user, url)
        .then(response => {
            const fileName =
                response.headers[
                    'content-disposition'
                ].split('filename=')[1]
            const href = URL.createObjectURL(
                response.data,
            )
            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            if(URL.revokeObjectURL) {
                URL.revokeObjectURL(href)
            }
        })
        .catch(err => {
            console.log(err)
        })
}

type UseFetchSearchReportFileReturn = {
    url: string
    isLoading: boolean
}

export const useFetchSearchReportFile = (fetchUrl: string): UseFetchSearchReportFileReturn => {
    const user = useUser()
    const { isLoading, data } = useQuery(['search-report', fetchUrl], () =>
        fetchSearchReportFile(user,fetchUrl),
    )

    const url = React.useMemo(() => (data as any)?.url, [data])

    return {
        isLoading,
        url,
    }
}
