import React, { useEffect } from 'react'
import * as D from 'io-ts/lib/Decoder'
import { useUser } from 'auth/Auth'
import config from 'shared/config'
import { useQuery, UseQueryResult } from 'react-query'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { request } from '@genome-web-forms/common/api'
import { authGWF } from 'api/auth'
import {
    SearchResult,
    SearchResultPayload,
    SearchResultPayloadCodec,
} from 'model/search/SearchResult'
import { Workflow, WorkflowDec, WorkflowSearchRequest } from '@genome-web-forms/server'
import { flags } from 'shared/flags'
import { values } from 'lodash'
import { queryParamsToESQuery } from 'shared/util/QueryBuilder'
import { SupportedFacet, SupportedFacetGroup } from './useFacetOptions'
import { PaginationOptions } from 'pages/Search/SearchTablePagination'
import { DESCRIPTORS_FACET_KEY } from 'shared/util/QueryBuilder'
import { SEARCH_TEXT_KEY } from 'shared/searchConstants'

export type SearchParams = {
    [x in SupportedFacet | SupportedFacetGroup]: string[] | string | any
}

export type UseSearchParams = SearchParams & { text: string; type: string }

export type QuerySizeParams = { from: number; size: number }

type useSearchOpts = {
    searchText?: string
    searchParams?: SearchParams | undefined
    paginationOptions?: PaginationOptions
    querySizeParams?: QuerySizeParams
    fetchWorkflowTasks?: boolean
}
type useSearchReturn = {
    searchParams?: SearchParams
    isLoading: boolean
    isFetching: boolean
    isFetched: boolean
    isFinished: boolean
    results: SearchResult[]
    totalHits: number | undefined
    refetch: any
    remove: () => void
}
export const useSearch = (
    params: useSearchOpts = { fetchWorkflowTasks: false },
): useSearchReturn => {
    const user = useUser()

    const { fetchWorkflowTasks = false } = params

    const { paginationOptions = { currentPage: 0 }, querySizeParams = { from: 0, size: 100 } } =
        params

    const [_data, setData] = React.useState<SearchResultPayload>()

    const lastSearchParams = React.useMemo(
        () => ({
            text: params.searchText,
            params: params.searchParams,
        }),
        [params.searchText, params.searchParams],
    )

    const _querySizeParams = React.useMemo<QuerySizeParams>(
        () => ({
            from: paginationOptions.currentPage * querySizeParams.size,
            size: querySizeParams.size,
        }),
        [paginationOptions, querySizeParams],
    )

    let {
        isLoading,
        isFetching,
        refetch,
        data: baseData,
        remove,
    } = useSearchQuery(
        {
            user,
            ...lastSearchParams,
        },
        fetchWorkflowTasks,
        _querySizeParams,
    )
    // this exists to avoid overwritting data if useSearchQuery is executed due it is connected to search form, If hook
    // params change, but useQuery is not enabled it will cause useSearchQuery to return undefined and an unintended
    // results cleanup
    useEffect(() => {
        if (baseData) setData(baseData)
        else setData(undefined)
    }, [baseData])

    let resourceId: string[] = _data?.results.map(result => result.id) || []
    resourceId.sort()
    const { data: allWorkflows = [], isSuccess: isFetched } = useQuery<Array<Workflow>>(
        ['search-workflows', resourceId],
        () => {
            if (!flags.workflows || !params.fetchWorkflowTasks) return []
            if (resourceId?.length === 0) return []
            return request(
                D.array(WorkflowDec),
                authGWF<WorkflowSearchRequest>(user, {
                    url: `${config.urlGWFWorkflows}/workflows/search`,
                    method: 'PUT',
                    data: { resourceId, completed: false },
                }),
            )
        },
        { initialData: [] },
    )

    const data = React.useMemo<typeof _data>(() => {
        if (!_data) return _data

        _data.results = _data.results.map(result => {
            result.workflows = allWorkflows.filter(workflow => workflow.resourceId === result.id)
            return result
        })

        return _data
    }, [_data, allWorkflows])

    const { results } = React.useMemo<SearchResultPayload>(
        () => (data ? data : { totalHits: 0, results: [] }),
        [data],
    )

    const totalHits = React.useMemo(() => {
        return data?.totalHits
    }, [data])

    const isFinished = React.useMemo<boolean>(() => {
        return totalHits !== undefined
    }, [totalHits])

    return {
        isLoading,
        isFetching,
        results,
        isFetched,
        isFinished,
        totalHits,
        refetch,
        remove,
    }
}

type useSearchQueryArgs = {
    user: MyIDUser
    text: string | undefined
    params: SearchParams | undefined
}
const useSearchQuery = (
    args: useSearchQueryArgs,
    isWorkflowSearch: boolean,
    querySizeParams?: QuerySizeParams,
): UseQueryResult<SearchResultPayload> => {
    return useQuery(
        ['search', querySizeParams],
        () => executeSearch(args, isWorkflowSearch, querySizeParams),
        { enabled: false },
    )
}
const executeSearch = async (
    { user, text, params: inputParams }: useSearchQueryArgs,
    isWorkflowSearch: boolean,
    querySizeParams?: QuerySizeParams,
): Promise<SearchResultPayload | null> => {
    if (!text && !inputParams) return null
    const queryParams = {
        ...inputParams,
        [SEARCH_TEXT_KEY]: text,
    } as any

    const [_text, _additionalParams] = queryParamsToESQuery(queryParams, isWorkflowSearch)
    const _queryType = 'boolean'
    const _from = querySizeParams ? querySizeParams.from : 0
    const _size = querySizeParams ? querySizeParams.size : 1000

    const params = {
        from: _from,
        size: _size,
        text: _text,
        queryType: _queryType,
        ..._additionalParams,
    }

    return request(
        SearchResultPayloadCodec,
        authGWF(user, {
            url: `${config.urlGWF}/search`,
            params,
            transformResponse,
        }),
    )

    // Search can have some bad data.
    // We want to handle the most egregious parts before they hit the codec,
    // omit them from what the user sees, and notify Rollbar instead
    function transformResponse(data: any): any {
        try {
            data = JSON.parse(data)
        } catch (e) {
            return data
        }

        let { results } = data

        // If new ES format, flatten the results. Also, make 'type' and 'typeLabel' into 'cwmClassType' and 'cwmClassTypeLabel'
        const lastItem = results[results.length - 1]
        if (results.length > 0 && lastItem?.genomeObject.data_disney_com_cpm) {
            results = results.map((result: any) => ({
                ...result,
                genomeObject: values(result.genomeObject).reduce(
                    (acum: any, c: any) => Object.assign(acum, c),
                    {
                        cwmClassType:
                            result.genomeObject['www_w3_org_1999_02_22-rdf-syntax-ns']?.type,
                        cwmClassTypeLabel:
                            result.genomeObject['www_w3_org_2000_01_rdf-schema']?.typeLabel,
                        // Workaround for moved hasDisplaygenres into grouped object structure
                        hasDisplayGenre:
                            result.genomeObject[DESCRIPTORS_FACET_KEY]?.hasDisplayGenre ?? [],
                        descriptors: result.genomeObject[DESCRIPTORS_FACET_KEY],
                    },
                ),
            }))
        }

        data.results = results
        return data
    }
}
