import { Row, SortByFn } from 'react-table'
import warning from 'tiny-warning'
import { compare } from 'natural-orderby'

const compareFn = compare()

export const sortTypes: Record<string, SortByFn<any>> = {
    // overwrite the default alphanumeric sort with natural-sort
    alphanumeric: (
        a: Row<Record<string, unknown>>,
        b: Row<Record<string, unknown>>,
        columnId: string,
    ) => {
        return compareFn(a.values[columnId], b.values[columnId])
    },

    // allow sorting of arrays of values
    array: (a: Row<Record<string, unknown>>, b: Row<Record<string, unknown>>, id: string) => {
        if (!Array.isArray(a.values[id]) || !Array.isArray(b.values[id])) {
            warning(true, `<Table> cannot perform array sort for column "${id}"`)
            return 0
        }

        const aa: string[] = a.values[id].sort(compare())
        const bb: string[] = b.values[id].sort(compare())

        return compareFn(aa.join(), bb.join())
    },

    // Sort dates placing empty/undefined/null dates always at the bottom
    datetime: (
        a: Row<Record<string, unknown>>,
        b: Row<Record<string, unknown>>,
        id: string,
        desc: boolean | undefined,
    ) => {
        if (desc === true) {
            if (!a.values[id]) return -Infinity
            if (!b.values[id]) return Infinity
        } else {
            if (!b.values[id]) return -Infinity
            if (!a.values[id]) return Infinity
        }

        if (a.values[id] > b.values[id]) return 1
        if (a.values[id] < b.values[id]) return -1
        return 0
    },
}
