import React from 'react'

import { orderBy } from 'natural-orderby'

import { FeatureStoryline, storylineCompareByComponent } from 'codecs/storyline/FeatureStoryline'
import Table, { CheckboxFilter } from 'shared/components/Table'
import { Column, CellProps } from 'react-table'
import { Pill } from 'shared/components/Table/TablePills'
import StorylineArchetypes from 'shared/components/StorylineArchetypes'

import { StorylineRow } from './StorylineRow'
import { EmptyStorylines } from 'shared/storylines/EmptyStorylines'

import { useStorylinesContext } from './index'
import { usePublishErrorManager } from 'shared/resource/ResourceMachineProvider'

const columns: Column<FeatureStoryline>[] = [
    {
        id: 'component',
        Header: 'Component',
        accessor: s => s.storylineComponent?.label,
        sortType: ({ original: storylineA }, { original: storylineB }) => {
            return storylineCompareByComponent(storylineA, storylineB)
        },
        filter: 'checkbox',
        Filter: CheckboxFilter,
        width: 80,
    },
    {
        Header: 'Description',
        accessor: 'storylineLogLine',
        width: 300,
    },
    {
        Header: 'Lead Character',
        accessor: ({ storylineLeadCharacter }) => {
            return orderBy(storylineLeadCharacter, c => c.storylineLeadCharacterName).map(
                c => c.storylineLeadCharacterName,
            )
        },
        Cell: ({
            row: {
                original: { storylineLeadCharacter },
            },
        }: CellProps<FeatureStoryline>) => {
            return orderBy(storylineLeadCharacter, c => c.storylineLeadCharacterName).map(c => (
                <Pill key={c.storylineLeadCharacterId}>{c.storylineLeadCharacterName}</Pill>
            ))
        },
        sortType: 'array',
        filter: 'checkbox',
        Filter: CheckboxFilter,
    },
    {
        Header: 'Storyline Elements',
        accessor: ({ storylineArchetypes }) => {
            return storylineArchetypes.map(sa => sa.label)
        },
        Cell: ({
            row: {
                original: { storylineArchetypes },
            },
        }: CellProps<FeatureStoryline>) => {
            return <StorylineArchetypes {...{ storylineArchetypes }} />
        },
        sortType: 'array',
        filter: 'checkbox',
        Filter: CheckboxFilter,
    },
]

const initialTableState = {
    sortBy: [{ id: 'component' }, { id: 'storylineLogLine' }],
}

type StorylinesListProps = { searchText: string }
export const StorylinesList: React.FC<StorylinesListProps> = ({ searchText }) => {
    const publishErrorManager = usePublishErrorManager()
    const { formControlsEnabled, storylines, edit, lastInsertedStorylineIdentifier } =
        useStorylinesContext()

    return (
        <Table<FeatureStoryline>
            {...{
                data: storylines,
                columns,
                initialState: initialTableState,
                searchText,
                RowComponent: StorylineRow,
                onRowClick: ({ row: { original: storyline } }) => {
                    if (formControlsEnabled) {
                        edit(storyline)
                    }
                },
                highlightRow: ({
                    row: {
                        original: { storylineId },
                    },
                }) => {
                    return storylineId === lastInsertedStorylineIdentifier
                },
                scrollToRow: ({
                    row: {
                        original: { storylineId },
                    },
                }) => {
                    return storylineId === lastInsertedStorylineIdentifier
                },
                getRowProps: ({ original: { storylineId } }) => ({
                    clickable: formControlsEnabled,
                    hasErrors:
                        formControlsEnabled &&
                        publishErrorManager.hasErrors(
                            (u): u is FeatureStoryline => u.storylineId === storylineId,
                        ),
                }),
                getCellProps: ({ original: { storyRoles } }) => ({
                    removeBottomBorderRadius: !!storyRoles.length,
                }),
            }}
            emptyMessage={<EmptyStorylines />}
        />
    )
}
