import * as t from 'io-ts'
import { UseQueryResult, useQuery } from 'react-query'

import { request } from '@genome-web-forms/common/api'
import { MyIDUser } from '@genome-web-forms/common/auth'

import { useUser } from 'auth/Auth'
import { authGWF } from 'api/auth'
import config from 'shared/config'

export type PortrayalRole = {
    scopeUri: string
    scopeLabel: string
}

export const PortrayalRoleCodec = t.type({
    scopeUri: t.string,
    scopeLabel: t.string,
})

const fetchPortrayalRoles = (user: MyIDUser): Promise<PortrayalRole[]> => {
    const url = `${config.urlGWFOntology}/portrayals/scopes/all`
    return request(
        t.array(PortrayalRoleCodec),
        authGWF(user, {
            url: url,
            method: 'GET',
        }),
    )
}

export const usePortrayalRoles = (): UseQueryResult<PortrayalRole[]> => {
    const user = useUser()
    return useQuery<PortrayalRole[]>(
        'portrayal-roles',
        () => {
            return fetchPortrayalRoles(user)
        },
        {
            cacheTime: Infinity,
            staleTime: Infinity,
        },
    )
}
