import * as t from 'io-ts'
import { BooleanFromString } from 'io-ts-types/lib/BooleanFromString'
import { fromNullable } from 'codecs/util/fromNullable'

import nullable from 'codecs/util/nullable'
import { AbridgedEpisodeCodec } from 'model/Episode'
import { CastMemberCodec } from 'codecs/CastMember'
import { CrewMemberCodec } from 'codecs/CrewMember'
import { LocationCodec } from 'codecs/Location'
import { DateFromXDS } from 'codecs/util/DateFromXDS'
import { RecordedAttributesCodec } from 'model/metadata/RecordedAttributes'
import * as cwm from '@genome-web-forms/common/model/CWMClassType'

export const AbridgedSeasonCodec = t.type({
    seasonId: t.string,
    seasonTitle: nullable(t.string),
    seasonNumber: nullable(t.string),
})

const SeasonCodecBase = t.type({
    ...AbridgedSeasonCodec.props,
    seriesCwmClassType: t.keyof(cwm.CWM_SERIES_CLASSES),
    seriesCwmClassTypeLabel: t.string,
    productionCompany: fromNullable(t.array(t.string), []),
    attributes: nullable(RecordedAttributesCodec),

    episodes: t.array(AbridgedEpisodeCodec),

    //tabs
    characters: fromNullable(t.array(CastMemberCodec), []),
    crew: fromNullable(t.array(CrewMemberCodec), []),
    locations: fromNullable(t.array(LocationCodec), []),

    // optional props
    static: t.type({
        seriesId: t.string,
        seriesTitle: t.string,
        earliestReleaseDate: nullable(DateFromXDS),
        originalPremiereDate: nullable(DateFromXDS),
        hasDescription: nullable(t.string),
        titleNameDisambiguated: nullable(t.string),
        titleNameStandardized: nullable(t.string),
        fka: fromNullable(t.array(t.string), []),
        akaTitle: nullable(t.string),
        reachLabel: nullable(t.string),
        groupId: nullable(t.string),
        _unverified_radarGroupNumber: nullable(t.string),
        productId: nullable(t.string),
        productNumber: nullable(t.string),
        eidrL1: nullable(t.string),
        hasMDMID: nullable(t.string),
        tmsSeriesId: nullable(t.string),
        networkTitle: nullable(t.string),
        worldTitle: nullable(t.string),
        brandTitle: nullable(t.string),
        reachId: nullable(t.string),
        synopsis: nullable(t.string),
        synopsisMedium: nullable(t.string),
        creativeFormat: nullable(t.string),
        productionCompany: fromNullable(t.array(t.string), []),

        _unverified_rating: nullable(t.string),
        _unverified_runtime: nullable(t.string),
        episodeNumber: nullable(t.string),

        disneyPlusSelected: fromNullable(BooleanFromString, false),
        DisneyPlusOriginal: fromNullable(BooleanFromString, false),

        starSelected: fromNullable(BooleanFromString, false),
    }),
})

export interface Season extends t.TypeOf<typeof SeasonCodecBase> {}
export const SeasonCodec: t.Type<Season> = cwm
    .makeCWMTypeLabelCodec(
        'seriesCwmClassType',
        'seriesCwmClassTypeLabel',
        cwm.CWM_CLASS_TYPE_SERIES,
    )
    .pipe(SeasonCodecBase, SeasonCodecBase.name) as t.Type<Season>

export interface AbridgedSeason extends t.TypeOf<typeof AbridgedSeasonCodec> {}

export const SeasonJSONViewOmittedValues = [
    'creativeWorkType',
    'creativeWorkTypeLabel',
    'cwmClassType',
    'static.type',
    'static.typeLabel',
    'static.parentClass',
    'static.parentClassLabel',
]
