import * as y from 'yup'

// const talentSchema = y
//     .object({
//         talentName: y.string(),
//         talentId: y.string(),
//     })
//     .required('This field is required')
//     .nullable()

// const characterSchema = y
//     .object({
//         characterName: y.string(),
//         characterId: y.string(),
//     })
//     .required('This field is required')
//     .nullable()

const roleSchema = y
    .object({
        roleId: y.string(),
        roleLabel: y.string(),
    })
    .required('This field is required')
    .nullable()

const portrayalTypeChema = y
    .object({
        uri: y.string().required('This field is required'),
        label: y.string().required('This field is required'),
    })
    .required('This field is required')
    .nullable()

const portraysItemSchema = y
    .object({
        uri: y.string(),
        label: y.string(),
    })
    .required('This field is required')
    .nullable()

const portrayedByItemSchema = y
    .object({
        uri: y.string(),
        label: y.string(),
    })
    .required('This field is required')
    .nullable()

export const PortrayalFormSchema = y
    .object({
        // character: characterSchema,
        // talent: talentSchema,
        role: roleSchema,
        portrayal: y.object({
            portrayalType: portrayalTypeChema,
            portrayalName: y.string().required('This field is required'),
        }),
        portrays: y.array().of(portraysItemSchema).optional(),
        portrayedBy: y.array().of(portrayedByItemSchema).optional(),
    })
    .defined()
