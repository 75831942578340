import * as t from 'io-ts'
import * as s from 'shared/diff/schema'
import nullable from 'codecs/util/nullable'
import { orderBy } from 'natural-orderby'
import { BooleanFromString } from 'io-ts-types/lib/BooleanFromString'
import { withFallback } from 'io-ts-types/lib/withFallback'

export interface DisplayGenre {
    displayGenreId: string
    displayGenreLabel: string
    suggestionScore: number | null
    notForTagging: boolean
}
export const DisplayGenreCodec: t.Type<DisplayGenre> = t.type({
    displayGenreId: t.string,
    displayGenreLabel: t.string,
    suggestionScore: nullable(t.number),
    notForTagging: withFallback(t.union([BooleanFromString, t.boolean]), false, 'boolean'),
}) as any
export const displayGenre = (label: string, opts?: Partial<DisplayGenre>): DisplayGenre => {
    return {
        displayGenreLabel: label,
        displayGenreId: `http://example.com/${label}`,
        suggestionScore: null,
        notForTagging: false,
        ...opts,
    }
}

export interface OtherGenre {
    otherGenreId: string
    otherGenreLabel: string
    suggestionScore: number | null
    notForTagging: boolean
}
export const OtherGenreCodec: t.Type<OtherGenre> = t.type({
    otherGenreId: t.string,
    otherGenreLabel: t.string,
    suggestionScore: nullable(t.number),
    notForTagging: withFallback(t.union([BooleanFromString, t.boolean]), false, 'boolean'),
}) as any
export const otherGenre = (label: string): OtherGenre => {
    return {
        otherGenreLabel: label,
        otherGenreId: `http://example.com/${label}`,
        suggestionScore: null,
        notForTagging: false,
    }
}

export const GenreCodec = t.type({
    displayGenre: nullable(DisplayGenreCodec),
    otherGenres: t.array(OtherGenreCodec),
})
export type Genre = t.TypeOf<typeof GenreCodec>

export interface TaggedTaxonomyCategory {
    category: string
    label: string
    // TODO: Update if terms are included
    terms: unknown[]
    notForTagging: boolean
}

export interface TaggedTaxonomy {
    type: string
    label: string
    categories: TaggedTaxonomyCategory[]
}

///////////////////////////
// Constants
///////////////////////////

export const GenreDiffSchema = s.schema({
    'Display Genre': s.value<string>(),
    'Other Genres': s.array(s.value<string>()),
})
export type DiffableGenre = s.TypeOf<typeof GenreDiffSchema>
export const toDiffableGenre = (genre: Genre): DiffableGenre => {
    return {
        'Display Genre': genre.displayGenre?.displayGenreLabel ?? '<empty>',
        'Other Genres': orderBy(genre.otherGenres.map(g => g.otherGenreLabel)),
    }
}
