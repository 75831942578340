import React from 'react'
import FormikSelect, { FormikSelectProps } from 'shared/components/Select/FormikSelect'
import { usePureFn } from 'shared/hooks/usePureFn'

import { StorylineLeadCharacter } from 'codecs/storyline/FeatureStoryline'
import { CastMember } from 'codecs/CastMember'
import { buildFilteredMergedPortrayals } from 'shared/components/Characters/utils'

export type StorylineLeadCharacterSelectProps = {
    characters: CastMember[]
} & Omit<FormikSelectProps<StorylineLeadCharacter>, 'options' | 'getOptionLabel' | 'getOptionValue'>
export const StorylineLeadCharacterSelect: React.FC<StorylineLeadCharacterSelectProps> = ({
    characters,
    ...props
}): React.ReactElement => {
    const options = React.useMemo(() => {
        const mergedPortrayals = buildFilteredMergedPortrayals(characters)
        return (
            mergedPortrayals
                // We can get characters that are { notForTagging } from MWD
                .filter(({ notForTagging = false }: any) => !notForTagging)
                .map<StorylineLeadCharacter>(({ character: { characterId, characterName } }) => ({
                    storylineLeadCharacterId: characterId,
                    storylineLeadCharacterName: characterName,
                }))
        )
    }, [characters])

    return (
        <FormikSelect<StorylineLeadCharacter>
            placeholder="Select lead character"
            {...props}
            isMulti={true}
            options={options}
            getOptionValue={usePureFn(option => option.storylineLeadCharacterId)}
            getOptionLabel={usePureFn(option => option.storylineLeadCharacterName)}
        />
    )
}

export default StorylineLeadCharacterSelect
