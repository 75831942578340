import axios from 'axios';
import omit from 'lodash/omit';
import * as TE from 'fp-ts/TaskEither';
import * as E from 'fp-ts/Either';
import { pipe as pipe$1 } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/pipeable';
import { retrying } from 'retry-ts/lib/Task';
import { monoidRetryPolicy, exponentialBackoff, limitRetries } from 'retry-ts';
import 'fp-ts/lib/Either';
import 'io-ts/lib/Decoder';
import 'io-ts-reporters';
import { i as isAxiosError, C as CodecError } from '../../dist/AxiosError-011dd670.esm.js';
import Debug from 'debug';
import '../../dist/defineProperty-990a7015.esm.js';
import '../../dist/isTagged-19de68b6.esm.js';

var debug = Debug('Retriable request');
function axiosRequest(config) {
  return axiosRequestTask(config)();
}
function axiosRequestTask(config) {
  var request = TE.tryCatch(function () {
    return axios.request(config);
  }, function (e) {
    return e;
  });

  if (config.retry) {
    var retry = config.retry !== true ? config.retry : {
      retries: 5,
      backoff: 2000
    };

    var log = function log(status) {
      debug((config.method || 'GET') + ' ' + config.url, pipe(status.previousDelay, O.map(function (delay) {
        return "executing a retry after ".concat(delay, " milliseconds wait");
      }), O.getOrElse(function () {
        return "first request attempt, out of maximum of ".concat(retry.retries);
      })));
    };

    var policy = monoidRetryPolicy.concat(exponentialBackoff(retry.backoff), limitRetries(retry.retries));
    return retrying(policy, function (status) {
      log(status);
      return request;
    }, E.fold(function (e) {
      return isAxiosError(e) && isRetryableError(e);
    }, function () {
      return false;
    }));
  }

  return request;
}

function isRetryableError(e) {
  return (// only 5XX errors
    !e.response || e.response.status >= 500 && e.response.status <= 599
  );
}

function request(dec, config) {
  return pipe$1(requestTask(dec, config), TE.fold(function (error) {
    return function () {
      return Promise.reject(error);
    };
  }, function (decoded) {
    return function () {
      return Promise.resolve(decoded);
    };
  }))();
}
function requestTask(dec, config) {
  return pipe$1(axiosRequestTask(omit(config, ['dataProp'])), TE.map(function (res) {
    var data = config.dataProp ? res === null || res === void 0 ? void 0 : res.data[config.dataProp] : res === null || res === void 0 ? void 0 : res.data;
    return pipe$1(dec.decode(data), E.mapLeft(function (errors) {
      return new CodecError(errors, data);
    }));
  }), TE.getOrElse(function (e) {
    return axios.isCancel(e) ? // never return if the request was cancelled
    TE.fromTask(function () {
      return new Promise(function () {});
    }) : TE.left(e);
  }));
}

export { axiosRequest, axiosRequestTask, request, requestTask };
