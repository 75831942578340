import { cloneDeep, flatten, keys, uniqueId, values } from 'lodash'
import React from 'react'
import BaseSelect, {
    components as componentsBase,
    MultiValueProps,
    OptionProps,
    Props as BaseSelectProps,
    ValueType,
} from 'react-select'
import { StylesConfig } from 'react-select/src/styles'
import Container from 'shared/components/Container'
import styled, { theme } from 'shared/theme'
import { inputStyle } from '../Input'
import { useOnClickOutsideRef } from 'shared/hooks/useOnClickOutside'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { SupportedFacetComponentType } from 'shared/search/useFacetOptions'
import DatePicker from '../DatePicker'
import { mediumDateFormatter } from 'shared/mediumDateFormatter'
import { useEffect } from 'react'
import { endOfDay } from 'date-fns'

export interface OverridenAttrs {
    hideSelectedOptions?: boolean
    isClearable?: boolean
    classNamePrefix?: 'rs'
}

const OverridenAttrsDefault: OverridenAttrs = {
    hideSelectedOptions: false,
    isClearable: false,
    classNamePrefix: 'rs',
}

// As described in DMM2-783, some category labels under "Descriptors" must be mapped
export const getDescriptorsVisibleLabel = (categorylabel: string): string => {
    const visibleLabels: any = {
        agent: 'agents',
        'discipline or industry': 'disciplines/industries',
        'has display genre': 'display genre',
        'has other genre': 'other genre',
        'holiday or season': 'holidays & seasons',
        'lead age': 'lead(s) age',
        'lead gender': 'lead(s) gender',
        'set in': 'locations',
        // TODO: IMPORTANT: Remove when replacement to story elements is completed on BE
        'story archetype': 'story elements',
        'Source Material': 'source material', // required for simplifying automation
        genre: 'display genre',
        'genre other': 'other genres',
        'scope scale': 'scope/scale',
        'time era': 'time/era',
        'creative elements': 'creative elements',
    }

    return visibleLabels?.[categorylabel] ?? categorylabel
}

export type BaseOptionType = { [x: string]: any }

export type SelectProps<OptionType = { label: string; value: string }> =
    BaseSelectProps<OptionType> & OverridenAttrs

const filterOption = ({ label, value }: any, rawInput: string, options: any) => {
    // default search
    if (label.includes(rawInput) || value.includes(rawInput)) return true

    // check if a group as the filter string as label
    const go = options.filter((group: any) => group.label.toLocaleLowerCase().includes(rawInput))

    if (go) {
        for (const groupOption of go) {
            // Check if current option is in group
            const option = groupOption.options.find((opt: any) => opt.value === value)
            if (option) {
                return true
            }
        }
    }
    return false
}

const dot = (color = '#ccc') =>
    ({
        alignItems: 'center',
        display: 'flex',

        ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
        },
    } as const)

const customStyles: StylesConfig = {
    groupHeading: (provided, state) => {
        return {
            ...provided,
            display: 'flex',
            alignItems: 'center',
            color: `${theme.colors.textPrimary}`,
        }
    },
    control: (provided, state: any) => ({
        ...provided,
        minHeight: '3rem',
        minWidth: '10rem',
        transition: 'none',
        boxShadow: state.menuIsOpen ? 'none' : 'initial',
        outline: state.menuIsOpen ? '0' : 'initial',
        borderRadius: state.menuIsOpen ? '2px 2px 0 0' : '2px',
        backgroundColor: `${theme.colors.white}`,
        borderWidth: state.isDisabled ? '0' : state.menuIsOpen ? '1px 1px 0 1px' : '1px',
        borderColor: state.isFocused
            ? `${theme.colors.primary}`
            : state.isFocused
            ? `${theme.colors.primary}`
            : `${theme.colors.border}`,
        ':hover': { borderColor: `${theme.colors.primary}` },
        ':after': {
            backgroundColor: `${theme.colors.border}`,
            position: 'absolute',
            display: state.menuIsOpen ? 'block' : 'none',
            height: '1px',
            content: '""',
            bottom: '0',
            right: '0',
            left: '0',
        },
    }),
    container: provided => ({
        ...provided,
        pointerEvents: 'auto',
    }),
    menu: provided => ({
        ...provided,
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        marginTop: '0',
        border: `1px solid ${theme.colors.primary}`,
        boxShadow: '0 4px 8px 0 rgba(75, 93, 128, 0.3)',
    }),
    menuList: provided => ({
        ...provided,
        padding: '0',
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '0.9375rem',
        lineHeight: '1.5rem',
        color: state.isDisabled
            ? `${theme.colors.textPrimaryDisabled}`
            : state.isSelected
            ? `${theme.colors.white}`
            : `${theme.colors.textPrimary}`,

        backgroundColor: state.isDisabled
            ? `${theme.colors.white}`
            : state.isSelected
            ? `${theme.colors.primary}`
            : state.isFocused
            ? `${theme.colors.activeField}`
            : 'transparent',

        padding: '0.5rem 1rem',
    }),
    input: provided => ({
        ...provided,
        padding: '0',
        margin: '0',
        lineHeight: '1.5rem',
        position: 'relative',
    }),
    indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        display: state.isDisabled ? 'none' : 'flex',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: state.isDisabled
            ? '0'
            : state.isMulti & state.hasValue
            ? '.3125rem'
            : '.75rem 1rem',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        fontSize: '0.9375rem',
        lineHeight: '1.5rem',
        color: `${theme.colors.textPrimary}`,
        marginLeft: '0',
        ...(!!state?.data?.suggestionScore ? dot(theme.colors.backgroundAutofill) : {}),
    }),
    multiValue: (provided, state) => {
        return {
            ...provided,
            minHeight: '2rem',
            margin: state.isDisabled ? '3px 3px 0 0' : '3px',
            backgroundColor: `${
                !!state?.data?.suggestionScore
                    ? theme.colors.backgroundAutofill
                    : theme.colors.activeField
            }`,
            textOverflow: 'ellipsis',
            ':hover': state.isDisabled
                ? {}
                : {
                      backgroundColor: `${theme.colors.tagHover}`,
                  },
            color: `${theme.colors.textPrimary}`,
        }
    },
    multiValueLabel: provided => ({
        ...provided,
        alignSelf: 'center',
        fontSize: '.9375rem',
        lineHeight: '1.5rem',
        color: `${theme.colors.textPrimary}`,
        padding: '.25rem .5rem !important',
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        color: `${theme.colors.textPrimary}`,
        paddingRight: '.5rem',
        paddingLeft: '0',
        display: state.isDisabled ? 'none' : 'flex',
        ':hover': {
            color: 'inherit',
            backgroundColor: 'inherit',
            cursor: 'pointer',
        },
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled
            ? 'transparent'
            : state.isFocused
            ? `${theme.colors.textPrimary}`
            : `${theme.colors.textSecondary}`,
        fontSize: '.9375rem',
        lineHeight: '.5rem',
        marginLeft: '0',
    }),
    dropdownIndicator: (provided, state) => {
        return {
            ...provided,
            color: state.isDisabled ? `${theme.colors.border}` : `${theme.colors.textPrimary}`,

            ':hover': {
                color: `${theme.colors.textPrimary}`,
            },
        }
    },
}

export const StyledInputCheckbox = styled.input`
    fill: ${props => props.theme.colors.primary};
    cursor: default;
    width: 1em;
    aspect-ratio: 1;
`

export const StyledLabel = styled.label`
    flex: 1;
    padding-left: 0.5em;
`

export const GroupStyledInput = styled.input`
    ${inputStyle}
    margin-top: 0.5em;
    padding: 0.25rem 0.5rem;
    width: 100%;
    box-sizing: border-box;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`

const components = { Input, MultiValueLabel, Option, GroupHeading, Group, Menu }

function GroupSelect<OptionType extends BaseOptionType>(
    props: SelectProps<OptionType> & { type?: SupportedFacetComponentType },
): React.ReactElement {
    const {
        options,
        type = 'grouped-select',
        value = [],
        isSingleInterval = false,
        onChange = () => {},
    } = props

    const [groupFilters, setGroupFilters] = React.useState<{
        [group: string]: { checked: boolean; value: string }
    }>({})

    const [_value, setValue] = React.useState<ValueType<OptionType>>(value)

    const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false)

    useEffect(() => {
        if (value === null) setValue([])
        if (type !== 'grouped-date-range') setValue(value ?? [])
    }, [value, type])

    const filteredOptions = React.useMemo(() => {
        return (
            options?.map(optGroup => {
                const { checked, value } = groupFilters?.[optGroup.label] || { checked: false }

                if (!checked) return { ...optGroup, options: [] }

                if (type === 'grouped-date-range') return optGroup.options

                const filteredGroupOptions = optGroup.options.filter(({ label }: any) => {
                    return !!value && label.toLowerCase().includes(value.toLowerCase())
                })
                return { ...optGroup, options: filteredGroupOptions }
            }) || []
        )
    }, [options, type, groupFilters])

    // This is used to avoid isVisible flag recalculation in Option child components
    const flattenedOptions = React.useMemo(() => {
        if (type === 'grouped-date-range') {
            return flatten(keys(groupFilters))
        }
        return flatten(filteredOptions.map((fo: any) => fo.options)).map((opt: any) => opt?.label)
    }, [filteredOptions, groupFilters, type])

    const ref = useOnClickOutsideRef<HTMLDivElement>(
        useEventCallback(() => {
            setMenuIsOpen(false)
        }),
    )

    const _onChange = React.useCallback(
        (selected, actionObj) => {
            const { action, removedValue } = actionObj
            const updatedSelection =
                isSingleInterval && action === 'remove-value'
                    ? selected.filter((s: any) => s.value !== removedValue.value)
                    : selected
            setValue(updatedSelection)
            onChange(updatedSelection, actionObj)
        },
        [onChange, isSingleInterval],
    )

    const addOption = React.useCallback(
        (value, label, key) => {
            let updatedValues = (cloneDeep(_value) as any[]) || []

            const updatedValueIndex = updatedValues?.findIndex((uv: any) => uv?.label === label)

            if (isSingleInterval) updatedValues = [{ label, value: { key, value } }]
            else if (updatedValueIndex === -1) updatedValues.push({ label, value: { key, value } })
            else updatedValues[updatedValueIndex] = { label, value: { key, value } }

            if (
                updatedValues[updatedValueIndex] &&
                values(updatedValues[updatedValueIndex].value).every(v => !v)
            )
                delete updatedValues[updatedValueIndex]

            setValue(updatedValues)
            onChange(updatedValues, 'set-value' as any)
        },
        [_value, isSingleInterval, onChange],
    )

    //Workaround for known key issue when value is object: https://github.com/JedWatson/react-select/issues/2844#issuecomment-584925290
    const getOptionValue = (option: any) => JSON.stringify(option.value + uniqueId())
    return (
        <div ref={ref} onClick={() => setMenuIsOpen(true)}>
            <BaseSelect
                {...OverridenAttrsDefault}
                {...(props as any)}
                {...{
                    groupFilters,
                    setGroupFilters,
                    filteredOptions,
                    flattenedOptions,
                    type,
                    addOption,
                }}
                getOptionValue={getOptionValue}
                value={_value}
                filterOption={(option, rawInput) => filterOption(option, rawInput, props.options)}
                components={components}
                styles={customStyles}
                closeMenuOnSelect={false}
                menuIsOpen={menuIsOpen}
                menuPlacement="auto"
                menuPortalTarget={document.body}
                onChange={_onChange}
            />
        </div>
    )
}

/**
 * We don't need an input at root level, so, return empty fragment
 */
function Input(): React.ReactElement {
    return <></>
}

/**
 * Attach a title to multi-values
 */
function MultiValueLabel(props: MultiValueProps<any>): React.ReactElement {
    const {
        selectProps: { type, options },
        data,
    } = props
    props.innerProps.title = props.data?.reactSelectTitle
        ? props.data.reactSelectTitle
        : typeof props.children === 'string'
        ? props.children
        : undefined

    if (type === 'grouped-date-range') {
        const { from, to } = data?.value?.value ?? data?.value ?? { from: null, to: null }
        const label = data?.label ?? options?.find(({ key }) => key === data?.key)?.label

        const fromDate = typeof from === 'string' ? new Date(from) : from
        const toDate = typeof to === 'string' ? new Date(to) : to
        const formattedFrom = from ? `After ${mediumDateFormatter.format(fromDate)}` : ''
        const formattedTo = to
            ? `${from ? 'and before' : 'Before'} ${mediumDateFormatter.format(toDate)}`
            : ''
        return (
            <span>
                {label}: <br /> {formattedFrom} {formattedTo}
            </span>
        )
    }

    return <componentsBase.MultiValueLabel {...props} />
}

/**
 * Attach title to Option
 */
export function Option(props: OptionProps<any>): React.ReactElement {
    const { data, selectProps, options } = props
    const { filteredOptions, flattenedOptions, type, addOption, value = [] } = selectProps

    ;(props.innerProps as any).title = props.data?.reactSelectTitle
        ? props.data.reactSelectTitle
        : typeof props.children === 'string'
        ? props.children
        : undefined

    const currentOption = React.useMemo(() => {
        return options.find(o => o.label === data.label)
    }, [options, data.label])

    const currentGroupOptions = React.useMemo(
        () => filteredOptions.find((fo: any) => fo.label === data?.categoryLabel)?.options ?? [],
        [filteredOptions, data?.categoryLabel],
    )

    const isVisible = React.useMemo<boolean>(() => {
        if (type === 'grouped-date-range') return flattenedOptions.includes(data.label)
        return currentGroupOptions.map((o: any) => o.label).includes(data.label)
    }, [flattenedOptions, currentGroupOptions, data?.label, type])

    const dateValue = React.useMemo(() => {
        const selectedOption = value
            ? value.find((val: any) => val?.label === data?.label)?.value
            : []
        return selectedOption?.value ?? { from: undefined, to: undefined }
    }, [value, data.label])

    if (!isVisible) return <></>

    if (type === 'grouped-date-range') {
        return (
            <>
                <DatePicker
                    name={`${data.label}-from`}
                    selected={dateValue?.from}
                    dateFormat="MMMM d, yyyy"
                    placeholderText="From"
                    maxDate={dateValue?.to}
                    onChange={(e: Date) => {
                        addOption(
                            { from: e, to: dateValue?.to ?? null },
                            data.label,
                            currentOption.key,
                        )
                    }}
                />
                <DatePicker
                    name={`${data.label}-to`}
                    selected={dateValue?.to}
                    dateFormat="MMMM d, yyyy"
                    placeholderText="To"
                    minDate={dateValue?.from}
                    onChange={(e: Date) => {
                        addOption(
                            { from: dateValue?.from ?? null, to: endOfDay(e) },
                            data.label,
                            currentOption.key,
                        )
                    }}
                />
            </>
        )
    }

    return <componentsBase.Option {...props} />
}

/**
 * Attach custom heading option
 */

export function Group(props: GroupHeadingProps): React.ReactElement {
    const { children } = props

    return <componentsBase.Group {...props}>{children}</componentsBase.Group>
}

/**
 * Attach custom heading option
 */
type GroupHeadingProps = any

export function GroupHeading(props: GroupHeadingProps): React.ReactElement {
    const inputRef = React.useRef<HTMLInputElement>(null)

    const { placeholder, children, selectProps } = props
    const { type, isSingleInterval } = selectProps

    const group = children

    const groupFilters: { [group: string]: { checked: boolean; value: string } } =
        selectProps.groupFilters
    const setGroupFilters: React.Dispatch<
        React.SetStateAction<{ [group: string]: { checked: boolean; value: string } }>
    > = selectProps.setGroupFilters

    const [checked, setChecked] = React.useState(groupFilters[group]?.checked ?? false)

    useEffect(() => {
        setChecked(groupFilters[group]?.checked ?? false)
    }, [groupFilters, group])

    const groupInputVisible = React.useMemo(
        () => type !== 'grouped-date-range' && checked,
        [type, checked],
    )

    const onCheckboxChange = ({ target: { checked } }: any) => {
        setChecked(checked)
        let _groupFilters = cloneDeep(groupFilters)
        if (!checked) delete _groupFilters[group]
        else {
            setTimeout(() => inputRef?.current?.focus())
            _groupFilters = {
                ...(!isSingleInterval ? _groupFilters : {}),
                [group]: { checked, value: '' },
            }
        }
        setGroupFilters(_groupFilters)
    }

    const onInputClick = (e: any) => {
        inputRef?.current?.focus()
    }

    const onKeyDown = (e: any) => {
        e.stopPropagation()
    }

    const onInputChange = ({ target: { value } }: any) => {
        let _groupFilters = cloneDeep(groupFilters)
        _groupFilters[group].value = value
        setGroupFilters(_groupFilters)
    }

    return (
        <componentsBase.GroupHeading {...props}>
            <Container flexDirection="column" justifyContent="center" flex={1}>
                <Container style={{ padding: 0 }}>
                    <StyledInputCheckbox
                        type="checkbox"
                        id={children}
                        {...{ placeholder, checked, onChange: onCheckboxChange }}
                    ></StyledInputCheckbox>
                    <StyledLabel htmlFor={children}>
                        {getDescriptorsVisibleLabel(children)}
                    </StyledLabel>
                </Container>
                {groupInputVisible && (
                    <GroupStyledInput
                        ref={inputRef}
                        type="text"
                        onKeyDown={onKeyDown}
                        onChange={onInputChange}
                        onClick={onInputClick}
                        readOnly={false}
                        disabled={false}
                    />
                )}
            </Container>
        </componentsBase.GroupHeading>
    )
}

type MenuListProps = any

export function Menu(props: MenuListProps): React.ReactElement {
    const { children } = props

    return <componentsBase.Menu {...props}>{children}</componentsBase.Menu>
}

export default GroupSelect
