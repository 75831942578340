import React from 'react'
import * as s from 'shared/diff/schema'

import { Column, CellProps, TableRowComponentProps } from 'react-table'
import { Table, RowComponentDefault } from 'shared/components/Table'
import { Op } from 'shared/diff/Op'
import { DiffValue } from 'shared/diff/DiffValue'
import { DiffArrayPrimitive } from 'shared/diff/DiffArrayPrimitive'
import { StoryRolesForOp } from 'shared/diff/components/StoryRolesForOp'
import Container from 'shared/components/Container'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import styled from 'shared/theme'

import {
    DiffableEpisodeStoryline,
    EpisodeStoryline,
    EpisodeStorylineDiffSchema,
    toDiffableEpisodeStoryline,
} from 'codecs/storyline/EpisodeStoryline'
import Text from 'shared/components/Text'
import { Pill, PillsWrapper } from 'shared/components/Table/TablePills'
import { useWIPDiff } from 'shared/resource/ResourceMachineProvider'

const WeightContainer = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 19px;
    border: 1px solid ${props => props.theme.colors.border};
    display: flex;
    align-items: center;
    justify-content: center;
`

type EpisodeStorylinesDiffOps = s.ArrayDiffOps<DiffableEpisodeStoryline>

const EpisodeStorylinesDiffsTableColumns: Column<EpisodeStorylinesDiffOps>[] = [
    {
        Header: 'Weight',
        accessor: op => op.value.storylineWeight,
        Cell: ({ row: { original: op } }: CellProps<EpisodeStorylinesDiffOps>) => {
            return s.isSchemaDiff(op) ? (
                <DiffValue diff={op.ops.storylineWeight} />
            ) : (
                <WeightContainer>
                    <Op type={op.type}>{op.value.storylineWeight}</Op>
                </WeightContainer>
            )
        },
        width: 10,
    },
    {
        Header: 'Logline',
        accessor: op => op.value.storylineLogLine,
        Cell: ({ row: { original: op } }: CellProps<EpisodeStorylinesDiffOps>) => {
            return s.isSchemaDiff(op) ? (
                <DiffValue diff={op.ops.storylineLogLine} />
            ) : (
                <Op type={op.type}>{op.value.storylineLogLine}</Op>
            )
        },
    },
    {
        Header: 'Description',
        accessor: op => op.value.description,
        Cell: ({ row: { original: op } }: CellProps<EpisodeStorylinesDiffOps>) => {
            return s.isSchemaDiff(op) ? (
                <DiffValue diff={op.ops.description} />
            ) : (
                <Op type={op.type}>{op.value.description}</Op>
            )
        },
        width: 300,
    },
    {
        Header: 'Type',
        accessor: op => op.value.type,
        Cell: ({ row: { original: op } }: CellProps<EpisodeStorylinesDiffOps>) => {
            return s.isSchemaDiff(op) ? (
                <DiffValue diff={op.ops.type} />
            ) : (
                <Op type={op.type}>{op.value.type}</Op>
            )
        },
        width: 60,
    },
    {
        Header: 'Storyline Elements',
        accessor: op => op.value.storylineArchetypes,
        Cell: ({ row: { original: op } }: CellProps<EpisodeStorylinesDiffOps>) => {
            return s.isSchemaDiff(op) ? (
                <DiffArrayPrimitive diff={op.ops.storylineArchetypes} />
            ) : (
                <PillsWrapper>
                    {op.value.storylineArchetypes.map((label, i) => (
                        <Pill key={label + String(i)} diffType={op.type}>
                            {label}
                        </Pill>
                    ))}
                </PillsWrapper>
            )
        },
        width: 100,
    },
]
const initialStateBase = {
    sortBy: [{ id: 'Weight' }],
}

export const EpisodeStorylinesDiff: React.FC = (): React.ReactElement => {
    const { ops } = useWIPDiff(
        'storylines',
        s.array(EpisodeStorylineDiffSchema, 'storylineId'),
        storylines => (storylines as EpisodeStoryline[]).map(toDiffableEpisodeStoryline),
    )
    const expanded = React.useMemo(() => {
        let expanded: Record<string, boolean> = {}
        const len = ops.length
        for (let i = 0; i < len; i++) {
            const op = ops[i]
            if (s.isSchemaDiff(op)) {
                if (op.ops.storyRoles.changed) {
                    expanded[i] = true
                }
            } else if (!s.isDiffOpSame(op)) {
                expanded[i] = true
            }
        }
        return expanded
    }, [ops])
    const initialState = React.useMemo(() => {
        return { ...initialStateBase, expanded }
    }, [expanded])

    return (
        <>
            <Container alignItems="center">
                <TableHeaderContent>
                    <Text as="h3" size="2" my="2">
                        Storylines
                    </Text>
                </TableHeaderContent>
            </Container>
            <Table<EpisodeStorylinesDiffOps>
                {...{
                    columns: EpisodeStorylinesDiffsTableColumns,
                    RowComponent: CustomRow,
                    initialState,
                    data: ops,
                    emptyMessage: 'No changes',
                    getCellProps: () => ({
                        removeBottomBorderRadius: true,
                    }),
                    getRowProps: ({ original: op }) => ({
                        diffType: s.isSchemaDiff(op) ? 'same' : op.type,
                    }),
                }}
            />
        </>
    )
}
export default React.memo(EpisodeStorylinesDiff)

function CustomRow(props: TableRowComponentProps<EpisodeStorylinesDiffOps>): React.ReactElement {
    return (
        <>
            <RowComponentDefault {...props} />
            <StoryRolesForOp {...props} />
        </>
    )
}
