import { useEffect } from 'react'

import { Workflow } from '@genome-web-forms/server'

import { useUser } from 'auth/Auth'
import { Feature } from 'model/Feature'
import { Series } from 'model/Series'
import { Episode } from 'model/Episode'
import { Season } from 'model/Season'

import { firePageView, trackPageView } from 'shared/util/snowplowUtils'

export const useAuthSnowplowPageView = (
    pageName: string,
    pageLabel: string,
    queryParams: any = null,
): void => {
    const user = useUser()
    useEffect(() => {
        trackPageView(pageName, pageLabel, queryParams, user)
        firePageView(pageName)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

export const useNonAuthSnowplowPageView = (
    pageName: string,
    pageLabel: string,
    queryParams: any = null,
): void => {
    useEffect(() => {
        trackPageView(pageName, pageLabel, queryParams)
        firePageView(pageName)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

export const useWorkflowDetailsPageView = (workflow: Workflow | undefined): void => {
    const user = useUser()
    useEffect(() => {
        if (workflow?.resourceTitle) {
            trackPageView('Workflow Details', workflow.resourceTitle, user)
            firePageView('Workflow Details')
        }
        // Tracking entire workflow object leads to double hook execution, probably because ref to data is changing twice at least
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflow?.resourceTitle])
}

export const useFeaturePageView = (resource: Feature | undefined): void => {
    const user = useUser()
    useEffect(() => {
        if (resource?.featureTitle) {
            const pageName = 'Feature'
            trackPageView(pageName, resource.featureTitle, user)
            firePageView(pageName)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resource?.featureTitle])
}

export const useSeriesPageView = (resource: Series | undefined): void => {
    const user = useUser()
    useEffect(() => {
        if (resource?.seriesTitle) {
            const pageName = 'Series'
            trackPageView(pageName, resource.seriesTitle, user)
            firePageView(pageName)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resource?.seriesTitle])
}

export const useEpisodePageView = (resource: Episode | undefined): void => {
    const user = useUser()
    useEffect(() => {
        if (resource?.episodeTitle) {
            const pageName = 'Episode'
            trackPageView(pageName, resource.episodeTitle, user)
            firePageView(pageName)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resource?.episodeTitle])
}

export const useSeasonPageView = (resource: Season | undefined): void => {
    const user = useUser()
    useEffect(() => {
        if (resource?.seasonTitle) {
            const pageName = 'Season'
            trackPageView(pageName, resource.seasonTitle, user)
            firePageView(pageName)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resource?.seasonTitle])
}
