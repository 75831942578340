import React, { useMemo } from 'react'

import { SerieJSONViewOmittedValues, Series } from 'model/Series'

import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import SaveIndicator from 'shared/components/SaveIndicator'
import PageTitle from 'shared/components/PageTitle'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import ResourceTabs from 'shared/components/Tabs/ResourceTabs'
import Cast from 'shared/components/Cast'
import Metadata from 'shared/components/Metadata'
import { flags } from 'shared/flags'
import { useIsSaving, useResourceMachine } from 'shared/resource/ResourceMachineProvider'
import WIPInfoAndAquire from 'shared/resource/WIPInfoAndAquire'
import { JSONViewer } from 'shared/components/JSONViewer'
import AllWorkflowControls from 'shared/components/Workflow/all-workflow-controls'
import PreviewControl from 'shared/components/PreviewControl'
import { useHasLibReadRole, useHasPortrayalsEditingRoles } from 'shared/hooks/useHasRole'

import MasterData from './MasterData'
import EpisodesTab from './EpisodesTab'
import { Associations } from 'shared/components/Associations'
import { useSeriesPageView } from 'shared/hooks/useSnowPlowPageView'

const SeriesNode = (): React.ReactElement => {
    const [state] = useResourceMachine<Series>()
    const {
        context: { resource },
    } = state

    useSeriesPageView(resource)

    const { cwmClassTypeLabel, seasons, episodes, seriesTitle, characters } = resource
    const { publishErrorManager } = state.context
    const canEditPortrayals = useHasPortrayalsEditingRoles()
    const hasViewAssociationsPermission = useHasLibReadRole()

    const tabsToDisplay = useMemo(() => {
        const associationTab = {
            id: 'Associations',
            panel: <Associations resourceId={resource.seriesId} />,
            label: undefined,
        }
        const debugTab = {
            id: 'JSON',
            panel: <JSONViewer json={resource} omit={SerieJSONViewOmittedValues} />,
        }
        const primaryTabs = [
            {
                id: 'Primary Metadata',
                panel: <MasterData />,
            },
            {
                id: 'Descriptors',
                label: <WIPInfoAndAquire title="Descriptors" dataType="metadata" />,
                panel: <Metadata />,
                hasErrors: publishErrorManager.hasComponentErrors('metadata'),
            },
            {
                id: 'Episodes',
                panel: <EpisodesTab {...{ seasons, episodes }} />,
            },
            {
                id: 'Portrayals',
                panel: <Cast label="Portrayals" data={characters} />,
                label: canEditPortrayals ? (
                    <WIPInfoAndAquire title="Portrayals" dataType="characters" />
                ) : undefined,
            },
        ]

        return hasViewAssociationsPermission
            ? [...primaryTabs, associationTab, debugTab]
            : [...primaryTabs, debugTab]
    }, [
        publishErrorManager,
        canEditPortrayals,
        characters,
        hasViewAssociationsPermission,
        resource,
        episodes,
        seasons,
    ])

    return (
        <Container px="3" pb="3" pt="1" flexDirection="column">
            <Breadcrumbs items={[{ title: cwmClassTypeLabel }]} />
            <PageTitle title={seriesTitle} />
            {useIsSaving() && <SaveIndicator />}

            <Container flexDirection="row" justifyContent="space-between" alignItems="center">
                <Text size="1" as="h2">
                    {seriesTitle}
                </Text>

                {flags.workflows && <AllWorkflowControls />}

                <PreviewControl />
            </Container>

            <ResourceTabs tabs={tabsToDisplay} />
        </Container>
    )
}

export default SeriesNode
