import * as t from 'io-ts'
import { useState } from 'react'
import { useQueries } from 'react-query'
import { AxiosError } from 'axios'
import config from 'shared/config'

import {
    CreateDescriptorsReportModel,
    FetchCreateDescriptorsReportParams,
} from 'model/workflow/CreateDescriptorsReport'
import { request } from '@genome-web-forms/common/api'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { useUser } from 'auth/Auth'
import { authGWF } from '../auth'

const url = {
    start: `${config.urlGWF}/s3-report`,
}

interface RequestErrorResponse {
    [key: string]: {
        data: string | undefined
        status: number | undefined
        error: Error
    }
}

export const useCreateDescriptorsReport = ({
    selectedDate,
    checkedDescriptors,
}: CreateDescriptorsReportModel): {
    data: { [key: string]: string }
    isLoading: boolean
    error: RequestErrorResponse
    clear: Function
} => {
    const [data, setData] = useState<{ [key: string]: string }>({})
    const [error, setError] = useState<RequestErrorResponse>({})
    const user = useUser()

    const response = useQueries(
        checkedDescriptors.map(descriptorsId => {
            const fetchParams = { date: selectedDate, fileName: descriptorsId }
            const CACHE_TIME_IN_MINUTES = 2
            return {
                queryKey: ['descriptors-report', descriptorsId, selectedDate],
                queryFn: () => fetchDescriptorsReport(user, fetchParams),
                enabled: checkedDescriptors.length > 0,
                cacheTime: CACHE_TIME_IN_MINUTES * 60 * 1000,
                refetchInterval: CACHE_TIME_IN_MINUTES * 60 * 1000,
                refetchIntervalInBackground: true,
                onSuccess: (data: any) =>
                    setData(prevState => ({
                        ...prevState,
                        [descriptorsId]: data.url,
                    })),
                onError: (error: AxiosError) =>
                    setError(prevState => ({
                        ...prevState,
                        [descriptorsId]: {
                            data: error.response?.data,
                            status: error.response?.status,
                            error: error,
                        },
                    })),
            }
        }),
    )

    const isLoading = response.some(query => query.isLoading)

    const clear = () => {
        setData({})
        setError({})
    }

    return {
        data,
        isLoading,
        error,
        clear,
    }
}

export const fetchDescriptorsReport = (
    user: MyIDUser,
    params: FetchCreateDescriptorsReportParams | { date: undefined },
): Promise<any> => {
    return request(
        t.any,
        authGWF(user, {
            method: 'GET',
            url: url.start,
            params: params,
        }),
    )
}
