import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'shared/theme'

import SearchSvg from './search.svg'
import { homeLink, searchLink } from 'routing/routes'
import UserMenu from 'shared/components/UserMenu'
import { AuthContext } from 'auth/Auth'
import Logo from './Logo'

const VerticleAlignCenter = styled.div`
    display: flex;
    align-items: center;
`

const StyledHeader = styled(VerticleAlignCenter)<{
    variant?: 'normal' | 'edit' | 'preview'
}>`
    padding: 0 2rem 0 1.5rem;
    height: 4rem;
    justify-content: center;
    background-color: ${({ variant = 'normal', ...props }): string => {
        switch (variant) {
            case 'normal':
                return props.theme.colors.white
            case 'preview':
                return props.theme.colors.gray
            case 'edit':
                return props.theme.colors.textPrimary
        }
    }};
    border-bottom: 1px solid
        ${({ variant = 'normal', ...props }): string => {
            switch (variant) {
                case 'normal':
                    return props.theme.colors.border
                case 'preview':
                    return props.theme.colors.gray
                case 'edit':
                    return props.theme.colors.textPrimary
            }
        }};
`

const HomeLink = styled(Link)`
    font-weight: 400;
    font-size: 1.125rem;
    color: ${props => props.theme.colors.textSecondary};
    position: absolute;
`

const StyledSearchLink = styled(Link)`
    padding: 0.25rem 0.5rem 0;
    margin-right: 1rem;
`

export const HeaderNav = (): React.ReactElement => {
    return (
        <>
            <HomeLink to={homeLink()}>Home</HomeLink>
            <VerticleAlignCenter>
                <StyledSearchLink to={searchLink()}>
                    <img src={SearchSvg} alt="Search" />
                </StyledSearchLink>

                <UserMenu />
            </VerticleAlignCenter>
        </>
    )
}
const Header: React.FC = (): React.ReactElement => {
    const { signedIn } = React.useContext(AuthContext)

    return (
        <StyledHeader>
            <Logo />

            {signedIn && <HeaderNav />}
        </StyledHeader>
    )
}

export default Header
export { StyledHeader }
