import React from 'react'
import { FilterProps, Row, ColumnInstance, IdType } from 'react-table'
import { useDebouncedCallback } from 'use-debounce'
import flatMap from 'lodash/flatMap'
import without from 'lodash/without'
import uniq from 'lodash/uniq'
import { compare } from 'natural-orderby'

import { DataItem } from 'shared/components/Table'
import styled from 'styled-components'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import { ReactComponent as FilterInactiveIcon } from './filter-icon.svg'
import { ReactComponent as FilterActiveIcon } from './filter-active-icon.svg'
import { useOnClickOutsideRef } from 'shared/hooks/useOnClickOutside'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import SearchInput from 'shared/components/SearchInput'
import { searchPredicate } from 'shared/util/searchPredicate'
import useEventListener from 'shared/hooks/useEventListener'

import CheckboxList from './CheckboxList'

export const EMPTY_SENTINEL = '#$#EMPTY#$#'

const FilterContainer = styled.div`
    margin: 0.25rem 0.25rem 0 0.25rem;
`

const ListContainer = styled(Container)<{ left: number }>`
    z-index: 1;
    position: absolute;
    left: ${props => props.left}px;
    top: 1.5rem;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.gray};
    border-radius: 4px;
    flex-direction: column;
    padding: 1rem;
    overflow-y: auto;
    max-height: 20.5rem;
    min-width: 20.625rem;
`

const FilterSearchInput = styled(SearchInput)`
    margin: 0 0 1rem 0;
    width: 18.625rem;
`

const ButtonsWrap = styled.div`
    display: flex;
`

const TextualButton = styled(Text).attrs({
    variant: 'primary',
    size: '5',
})`
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
`

type FilterValue = string[] | undefined

export const checkboxFilterType = <D extends DataItem>(
    rows: Array<Row<D>>,
    columnIds: Array<IdType<D>>,
    filterValue: FilterValue,
): Row<D>[] => {
    if (!filterValue) return rows

    if (filterValue.length === 0) return []

    return rows.filter(row => {
        return columnIds.some(columnId => {
            const columnValues = row.values[columnId]
            const columnStrings = stringifyDataItemProperty(columnValues)
            return columnStrings.some(columnString => filterValue.includes(columnString))
        })
    })
}

export type ColumnInstanceForFilter<D extends DataItem> = Pick<
    ColumnInstance<D>,
    'id' | 'filterAccessor'
>
export type RowWithValues<D extends DataItem> = Pick<Row<D>, 'original' | 'values'>
export function useColumnListItems<D extends DataItem>(
    { id, filterAccessor }: ColumnInstanceForFilter<D>,
    preFilteredRows: RowWithValues<D>[],
): string[] {
    const filterValueAccessor = React.useCallback(
        (row: RowWithValues<D>): string | string[] => {
            return filterAccessor ? filterAccessor(row.original) : row.values[id]
        },
        [id, filterAccessor],
    )

    return React.useMemo<string[]>(() => {
        // This is the raw column data,
        // converted to the format used by our filter
        const stringifiedColumnData: string[] = flatMap(preFilteredRows, row =>
            stringifyDataItemProperty(filterValueAccessor(row)),
        )

        // We keep only the unique values from our column data.
        const uniqueColumnData = uniq(stringifiedColumnData)

        const naturalCompare = compare()
        // always sort the empty sentinel on top
        return uniqueColumnData.sort((a, b) => {
            return a === EMPTY_SENTINEL ? -1 : b === EMPTY_SENTINEL ? 1 : naturalCompare(a, b)
        })
    }, [filterValueAccessor, preFilteredRows])
}

/**
 * We convert a to an array string representation.
 * We use an array, because it's the least common denominator to represent
 * the value of a column
 */
function stringifyDataItemProperty(prop: unknown | unknown[]): string[] {
    let array: unknown[] = Array.isArray(prop) ? prop : [prop]
    array = array.filter(Boolean)
    return array.length === 0 ? [EMPTY_SENTINEL] : array.map(u => String(u))
}

const FilterListContainer: React.FC = ({ children }) => {
    const listContainerRef = React.useRef<HTMLDivElement>(null)
    const [leftPosition, setLeftPosition] = React.useState<number>(0)

    const fixPosition = React.useCallback(() => {
        if (!listContainerRef.current) return

        const MARGIN_RIGHT = 20
        const containerPosition = listContainerRef.current.getBoundingClientRect()
        const rightPosition = containerPosition.right
        const viewportWidth = window.innerWidth
        const newLeftPosition = leftPosition + viewportWidth - rightPosition - MARGIN_RIGHT
        const shouldAddLeftOffset = newLeftPosition < 0

        setLeftPosition(shouldAddLeftOffset ? newLeftPosition : 0)
    }, [leftPosition])

    React.useEffect(fixPosition, [fixPosition])

    const debouncedFixPosition = useDebouncedCallback(fixPosition, 250)
    useEventListener('resize', debouncedFixPosition)

    return (
        <ListContainer
            ref={listContainerRef}
            left={leftPosition}
            data-testid="filter-list-container"
        >
            {children}
        </ListContainer>
    )
}

export type CheckboxListFilterProps<D extends DataItem> = {
    column: Pick<FilterProps<D>['column'], 'id' | 'filterAccessor' | 'filterValue' | 'setFilter'>
    preFilteredRows: FilterProps<D>['preFilteredRows']
    hasSearch?: boolean
}

export function CheckboxListFilter<D extends DataItem>({
    column,
    preFilteredRows,
    hasSearch = true,
}: CheckboxListFilterProps<D>): React.ReactElement | null {
    const { filterValue, setFilter } = column

    const [dropdownOpen, setDropdownOpen] = React.useState(false)
    const [searchText, setSearchText] = React.useState('')

    const allItems = useColumnListItems(column, preFilteredRows)
    const visibleItems = React.useMemo(
        () => allItems.filter(searchPredicate(searchText)),
        [allItems, searchText],
    )

    const filterContainerRef = useOnClickOutsideRef<HTMLDivElement>(
        useEventCallback(() => {
            setDropdownOpen(false)
        }),
    )

    const hasFilter = filterValue && filterValue.length !== allItems.length
    const FilterIcon = hasFilter ? FilterActiveIcon : FilterInactiveIcon

    return (
        <FilterContainer ref={filterContainerRef} data-testid="filter-container">
            <FilterIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    setDropdownOpen(!dropdownOpen)
                }}
                data-testid="filter-button"
            />
            {dropdownOpen ? (
                <FilterListContainer>
                    <Text variant="primary" size="5" weight="bold" mb="1">
                        Filter
                    </Text>
                    {hasSearch && (
                        <FilterSearchInput
                            data-testid="filter-search-input"
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            hideClearButton
                            autoFocus
                        />
                    )}
                    <ButtonsWrap>
                        <TextualButton
                            data-testid="check-all-checkboxes-button"
                            onClick={() => {
                                setFilter(visibleItems)
                            }}
                        >
                            Select all
                        </TextualButton>
                        <TextualButton
                            data-testid="uncheck-checkboxes-button"
                            ml="2"
                            onClick={() => {
                                const currentItems: string[] = filterValue || allItems
                                setFilter(without(currentItems, ...visibleItems))
                            }}
                        >
                            Clear
                        </TextualButton>
                    </ButtonsWrap>
                    <CheckboxList {...{ column, visibleItems, allItems }} />
                </FilterListContainer>
            ) : null}
        </FilterContainer>
    )
}

export default CheckboxListFilter
