import { cwmClassTypeToLabel } from '@genome-web-forms/common/model/CWMClassType'
import { Workflow } from '@genome-web-forms/server'
import { useFullNameMyIDUser, usePartialMyIDUsersQuery } from 'api/workflow/partialMyIDUsers'
import { capitalize } from 'lodash'
import { SearchResult } from 'model/search/SearchResult'
import { WorkflowTitleSearch } from 'pages/admin/workflow/create-assignments/TitleSearch'
import React from 'react'
import { CellProps, Column, TableRowComponentProps } from 'react-table'
import Container from 'shared/components/Container'
import MediumDate from 'shared/components/MediumDate'
import Table, { RowComponentDefault, TableCell, TableRow } from 'shared/components/Table'
import Text from 'shared/components/Text'
import workflowTasksTypeLabels from 'shared/components/WorkflowTasksTypeLabels'
import YesNoBooleanValue from 'shared/components/YesNoBooleanValue'

export const WorkflowsDetailsTable = ({
    workflows,
}: {
    workflows: Workflow[]
}): React.ReactElement => {
    const partialUsersQuery = usePartialMyIDUsersQuery()
    const users = React.useMemo<{ [key: string]: string }>(
        () =>
            partialUsersQuery.data!.reduce(
                (obj, key) => ({
                    ...obj,
                    [key['relationship.employeeId']]: `${key.given_name} ${key.family_name}`,
                }),
                {},
            ),
        [partialUsersQuery.data],
    )

    const columns = React.useMemo<Column<Workflow>[]>(
        () => [
            {
                id: 'radarId',
                Header: 'ID',
                accessor: (original: Workflow) => original.radarId.toString(),
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                        {original.radarId}
                    </Text>
                ),
                disableSortBy: true,
                width: 1,
            },
            {
                id: 'type',
                Header: 'Type',
                accessor: 'cwmClassTypeLabel',
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                        {cwmClassTypeToLabel(original.cwmClassType)}
                    </Text>
                ),
                disableSortBy: true,
                width: 1,
            },
            {
                id: 'task',
                Header: 'Assignment Type',
                accessor: (original: Workflow) => original.workflowConfig.task,
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                        {workflowTasksTypeLabels[original.workflowConfig.task as string]}
                    </Text>
                ),
                disableSortBy: true,
                width: 1,
            },
            {
                id: 'assignee',
                Header: 'Assignee',
                accessor: (original: Workflow) =>
                    original.assignee && users[original.assignee]
                        ? users[original.assignee]
                        : original.assignee,
                Cell: ({ row: { original } }: CellProps<Workflow>) => {
                    const asigneeFullName = useFullNameMyIDUser(original?.assignee)
                    return (
                        <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                            {asigneeFullName}
                        </Text>
                    )
                },
                disableSortBy: true,
                width: 1,
            },
            {
                Header: 'Priority',
                accessor: 'priority',
                Cell: ({ row: { original } }: CellProps<Workflow>) =>
                    capitalize(original.priority ?? ''),
                disableSortBy: true,
                width: 1,
            },
            {
                id: 'deadlineAt',
                Header: 'Due Date',
                accessor: 'deadlineAt',
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                        {original.deadlineAt && <MediumDate date={original.deadlineAt} />}
                    </Text>
                ),
                disableSortBy: true,
                width: 1,
            },
            {
                id: 'dateAssigned',
                Header: 'Creation Date',
                Cell: ({ row: { original } }: CellProps<Workflow>) => {
                    const transitions = original.transitions || []
                    const createdAt =
                        transitions.length > 0
                            ? transitions[transitions.length - 1].createdAt
                            : null
                    return (
                        <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                            {createdAt && <MediumDate date={createdAt} />}
                        </Text>
                    )
                },
                disableSortBy: true,
                width: 1,
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: 'state',
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                        {original.state}
                    </Text>
                ),
                disableSortBy: true,
                width: 1,
            },
            {
                id: 'source',
                Header: 'Source',
                accessor: (original: Workflow) => original.source,
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                        {original.source}
                    </Text>
                ),
                disableSortBy: true,
                width: 1,
            },
            {
                id: 'review',
                Header: 'Review',
                accessor: (original: Workflow) => original.workflowConfig.reviewRequired,
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                        <YesNoBooleanValue value={original.workflowConfig.reviewRequired} />
                    </Text>
                ),
                disableSortBy: true,
                width: 1,
            },
        ],
        [users],
    )

    return (
        <Container p={1}>
            <Table columns={columns} data={workflows} />
        </Container>
    )
}

export const TitleSearchRow = (
    props: TableRowComponentProps<SearchResult | WorkflowTitleSearch | any>,
): React.ReactElement => {
    const {
        row,
        row: { original },
    } = props

    const workflows: Workflow[] =
        ((original as WorkflowTitleSearch).searchResult
            ? (original as WorkflowTitleSearch).searchResult.workflows
            : (original as SearchResult).workflows) || []

    const defaultRow = <RowComponentDefault {...props} />

    if (!workflows || !workflows.length) {
        return defaultRow
    }

    return (
        <>
            <RowComponentDefault {...props} />
            {row.isExpanded && (
                <TableRow>
                    <TableCell colSpan={props.columns.length}>
                        <WorkflowsDetailsTable workflows={workflows}></WorkflowsDetailsTable>
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

export default TitleSearchRow
