import React from 'react'
import { Column, CellProps } from 'react-table'

import { LockedTitle } from 'model/LockedTitle'
import { PERMISSION_DEBUG } from '@genome-web-forms/common/auth'

import { useHasPermission } from 'shared/components/UserHasPermission'
import {
    useLockedTitlesInterpreter,
    useLockedTitlesActor,
} from 'shared/LockedTitles/LockedTitlesProvider'
import Table, { CheckboxFilter } from 'shared/components/Table'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import Loader from 'shared/components/Loader'
import { Link } from 'react-router-dom'
import styled from 'shared/theme'
import { cwmClassTypeToLabel, isSeries } from '@genome-web-forms/common/model/CWMClassType'
import { WIP, WIPDataType } from 'model/WIP'
import LockIcon from 'shared/components/Icons/LockIcon'
import PageTitle from 'shared/components/PageTitle'
import { AlertDialog, AlertDialogDescription, AlertDialogLabel } from 'shared/components/AlertModal'
import { Form, Formik } from 'formik'
import CheckboxInput from 'shared/components/CheckboxRadioInput/Checkbox'
import { uniq, uniqBy } from 'lodash'
import { AlertDialogButtons } from 'shared/components/AlertModal'
import TextualButton from 'shared/components/TextualButton'
import { routeTo } from 'model/search/routeTo'
import { WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA } from '@genome-web-forms/server'

const ReleaseButtonStyled = styled(Button).attrs({
    variant: 'outlineNoBorders',
})`
    white-space: nowrap;
    padding: 0;
    padding-right: 1.5rem;
`

const StyledContainer = styled(Container).attrs({
    flexDirection: 'column',
    px: '3',
    pb: '3',
    pt: '1',
})`
    margin-top: 20px;
`

const LockedTitles: React.FC = () => {
    const [state, send] = useLockedTitlesActor()
    const { myLockedTitles, otherLockedTitles } = state.context
    const canAdministrate = useHasPermission(PERMISSION_DEBUG)

    // trigger a refresh on when the page is loaded
    React.useEffect(() => send({ type: 'REFRESH' }), [send])

    if (state.matches('refreshing')) {
        return <Loader center size="normal" />
    }

    return (
        <StyledContainer>
            <PageTitle title="Locked Titles" />

            <Container pb="3" flexDirection="row" justifyContent="space-between">
                <Text size="1" as="h2">
                    Titles In Progress
                </Text>
                <Container
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                ></Container>
                <Button onClick={() => send('REFRESH')}>Refresh titles</Button>
            </Container>

            <Text size="3" as="h3">
                My Locked Titles
            </Text>
            <Table
                {...{
                    data: myLockedTitles,
                    columns: columns(false),
                    initialState,
                }}
                emptyMessage="You haven't locked any titles"
            />
            {canAdministrate && (
                <>
                    <Text size="3" as="h3" pt="3">
                        Other Locked Titles
                    </Text>
                    <Table
                        {...{
                            data: otherLockedTitles,
                            columns: columns(true),
                            initialState,
                        }}
                        emptyMessage="There are no titles in progress"
                    />
                </>
            )}
        </StyledContainer>
    )
}

const initialState = {
    sortBy: [{ id: 'title' }],
}

const columns = (canSortByLockedBy: boolean): Column<LockedTitle>[] => [
    {
        id: 'title',
        Header: 'Title',
        accessor: 'title',
        Cell: ({ row: { original: lockedTitle } }: CellProps<LockedTitle>) => {
            return (
                <Link to={routeTo(lockedTitle)}>
                    <Text size="5">{lockedTitle.title}</Text>
                </Link>
            )
        },
        width: 400,
    },
    {
        id: 'type',
        Header: 'Type',
        accessor: lockedTitle => cwmClassTypeToLabel(lockedTitle.cwmClassType),
        Cell: ({ row: { original: lockedTitle } }: CellProps<LockedTitle>) => (
            <Text size="5">{cwmClassTypeToLabel(lockedTitle.cwmClassType)}</Text>
        ),
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    {
        id: 'lockedBy',
        Header: 'Locked By',
        disableSortBy: !canSortByLockedBy,
        accessor: t =>
            uniq(
                t.wips
                    .map(wip => wip.state!)
                    .filter(Boolean)
                    .map(
                        ({ lockedByFirstName, lockedByLastName }) =>
                            `${lockedByFirstName}, ${lockedByLastName}`,
                    ),
            ).join(', '),
        Cell: ({ row: { original: lockedTitle } }: CellProps<LockedTitle>) =>
            uniqBy(
                lockedTitle.wips.map(wip => wip.state!).filter(Boolean),
                lock => lock?.lockedByUser,
            ).map((lock, index) => (
                <React.Fragment key={String(index)}>
                    {index ? ', ' : ''}
                    <span title={lock.lockedByUser}>
                        {lock.lockedByFirstName} {lock.lockedByLastName}
                    </span>
                </React.Fragment>
            )),
    },
    {
        id: 'metadata',
        Header: 'Descriptors',
        accessor: t => t.wips.find(wip => wip.dataType === 'metadata')?.state?.lockedDateTime,
        Cell: ({ row: { original: lockedTitle } }: CellProps<LockedTitle>) => (
            <LockIndicator lockedTitle={lockedTitle} type="metadata" />
        ),
    },
    {
        id: 'storylines',
        Header: 'Storylines',
        accessor: t => t.wips.find(wip => wip.dataType === 'storylines')?.state?.lockedDateTime,
        Cell: ({ row: { original: lockedTitle } }: CellProps<LockedTitle>) => (
            <LockIndicator lockedTitle={lockedTitle} type="storylines" />
        ),
    },
    {
        id: 'relationships',
        Header: 'Relationships',
        accessor: t => t.wips.find(wip => wip.dataType === 'relationships')?.state?.lockedDateTime,
        Cell: ({ row: { original: lockedTitle } }: CellProps<LockedTitle>) => (
            <LockIndicator lockedTitle={lockedTitle} type="relationships" />
        ),
    },
    {
        id: 'characters',
        Header: 'Portrayals',
        accessor: t => t.wips.find(wip => wip.dataType === 'characters')?.state?.lockedDateTime,
        Cell: ({ row: { original: lockedTitle } }: CellProps<LockedTitle>) => (
            <LockIndicator lockedTitle={lockedTitle} type="characters" />
        ),
    },
    {
        id: 'actions',
        Header: 'Actions',
        disableSortBy: true,
        Cell: ({ row: { original: lockedTitle } }: CellProps<LockedTitle>) => (
            <ReleaseControl lockedTitle={lockedTitle} />
        ),
    },
]

type LockIndicatorProps = {
    lockedTitle: LockedTitle
    type: WIPDataType
}
const LockIndicator: React.FC<LockIndicatorProps> = ({ lockedTitle, type }) => {
    const [
        {
            context: { releasingWIPs },
        },
    ] = useLockedTitlesActor()

    const wip = lockedTitle.wips.find(wip => wip.dataType === type)
    return wip ? (
        releasingWIPs.includes(wip) ? (
            <Container p={0}>
                <Loader center size="tiny" />
            </Container>
        ) : (
            <Container p={0}>
                <LockIcon lock={wip.state} />
            </Container>
        )
    ) : isValidType(lockedTitle, type) ? (
        <>
            &nbsp;&nbsp;&nbsp;
            <span title="Not locked">&mdash;</span>
        </>
    ) : (
        <>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span title="This title cannot have this lock type">&times;</span>
        </>
    )
}

/**
 * Series only support metadata type and no other
 */
const isValidType = (title: LockedTitle, type: WIPDataType): boolean => {
    return isSeries(title.cwmClassType) && type !== 'metadata' ? false : true
}

type ReleaseControlProps = {
    lockedTitle: LockedTitle
}
const ReleaseControl: React.FC<ReleaseControlProps> = ({ lockedTitle }) => {
    const [showModal, setShowModal] = React.useState(false)
    return (
        <>
            <ReleaseButtonStyled onClick={() => setShowModal(true)}>
                Release locks
            </ReleaseButtonStyled>
            {showModal && (
                <ReleaseModal closeModal={() => setShowModal(false)} wips={lockedTitle.wips} />
            )}
        </>
    )
}
type ReleaseModalProps = {
    closeModal: () => void
    wips: WIP[]
}
export const lockTypeLabels: { [x: string]: string } = {
    [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA]: 'Descriptors',
    storylines: 'Storylines',
    relationships: 'Relationships',
    characters: 'Portrayals',
}

const ReleaseModal: React.FC<ReleaseModalProps> = ({ wips: inputWips, closeModal }) => {
    const TABS: WIPDataType[] = ['metadata', 'storylines', 'relationships', 'characters']
    const wips = inputWips
        .slice()
        .sort((a, b) => TABS.indexOf(a.dataType) - TABS.indexOf(b.dataType))

    const service = useLockedTitlesInterpreter()
    const cancelRef = React.createRef<HTMLButtonElement>()
    const availableTypes = wips.map(wip => wip.dataType)
    const initialValues: {
        selection: WIPDataType[]
    } = { selection: [] }

    const onSubmit = ({ selection }: { selection: WIPDataType[] }): void => {
        service.send({
            type: 'RELEASE',
            wips: wips.filter(wip => selection.includes(wip.dataType)),
        })
        closeModal()
    }

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues, onSubmit }}>
                {({ values, setValues }) => (
                    <Form>
                        <AlertDialogLabel>Which tabs do you want to release?</AlertDialogLabel>
                        <AlertDialogDescription>Select the tabs to release:</AlertDialogDescription>
                        <Container mt={3} mb="2" flexDirection="column">
                            {TABS.map(dataType => {
                                return (
                                    <CheckboxInput
                                        name="selection"
                                        key={dataType}
                                        value={dataType}
                                        checked={values.selection.includes(dataType)}
                                        disabled={!availableTypes.includes(dataType)}
                                    >
                                        <span>{lockTypeLabels[dataType]}</span>
                                    </CheckboxInput>
                                )
                            })}
                            <div style={{ display: 'flex' }}>
                                <Button
                                    variant="outline"
                                    type="button"
                                    size="small"
                                    onClick={() =>
                                        setValues({
                                            selection: [...availableTypes],
                                        })
                                    }
                                >
                                    Select All
                                </Button>
                            </div>
                        </Container>
                        <AlertDialogButtons>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={values.selection.length < 1}
                            >
                                Release
                            </Button>
                            <TextualButton type="button" onClick={closeModal} ref={cancelRef}>
                                Cancel
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

export default LockedTitles
