import React, { forwardRef, InputHTMLAttributes, LegacyRef } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'

import styled from 'shared/theme'
import Container from 'shared/components/Container'
import { inputStyle } from 'shared/components/Input'
import { Portal } from 'react-overlays'
import { ReactComponent as DatePickerIcon } from './datePicker.svg'
import { getMonth, getYear, isMatch, parse } from 'date-fns'
import { range } from 'lodash'
import BaseSelect, { StylesConfig } from 'react-select'
import { theme } from 'shared/theme'

import 'react-datepicker/dist/react-datepicker.css'

const DEFAULT_DATE_FORMAT = 'MMMM d, yyyy'
const nextYear = getYear(new Date()) + 1
const years = range(1900, nextYear + 1)
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const customStyles: StylesConfig = {
    control: (provided, state: any) => ({
        ...provided,
        minHeight: '1em',
        transition: 'none',
        boxShadow: state.menuIsOpen ? 'none' : 'initial',
        outline: state.menuIsOpen ? '0' : 'initial',
        borderRadius: state.menuIsOpen ? '2px 2px 0 0' : '2px',
        backgroundColor: `${theme.colors.white}`,
        borderWidth: state.isDisabled ? '0' : state.menuIsOpen ? '1px 1px 0 1px' : '1px',
        borderColor: state.isFocused
            ? `${theme.colors.primary}`
            : state.isFocused
            ? `${theme.colors.primary}`
            : `${theme.colors.border}`,
        ':hover': { borderColor: `${theme.colors.primary}` },
        ':after': {
            backgroundColor: `${theme.colors.border}`,
            position: 'absolute',
            display: state.menuIsOpen ? 'block' : 'none',
            height: '1px',
            content: '""',
            bottom: '0',
            right: '0',
            left: '0',
        },
    }),
    container: provided => ({
        ...provided,
        fontSize: '12px',
        pointerEvents: 'auto',
    }),
    menu: provided => ({
        ...provided,
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        marginTop: '0',
        border: `1px solid ${theme.colors.primary}`,
        boxShadow: '0 4px 8px 0 rgba(75, 93, 128, 0.3)',
    }),
    menuList: provided => ({
        ...provided,
        padding: '0',
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '0.9375em',
        lineHeight: '1.5em',
        color: state.isDisabled
            ? `${theme.colors.textPrimaryDisabled}`
            : state.isSelected
            ? `${theme.colors.white}`
            : `${theme.colors.textPrimary}`,

        backgroundColor: state.isDisabled
            ? `${theme.colors.white}`
            : state.isSelected
            ? `${theme.colors.primary}`
            : state.isFocused
            ? `${theme.colors.activeField}`
            : 'transparent',

        padding: '0.125em 0.25em',
    }),
    input: provided => ({
        ...provided,
        padding: '0',
        margin: '0',
        lineHeight: '1.5em',
        position: 'relative',
    }),
    indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        display: state.isDisabled ? 'none' : 'flex',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: state.isDisabled ? '0' : state.isMulti & state.hasValue ? '.3125em' : '.5em 1em',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        fontSize: '0.9375em',
        lineHeight: '1.5em',
        color: `${theme.colors.textPrimary}`,
        marginLeft: '0',
    }),
    multiValue: (provided, state) => {
        return {
            ...provided,
            minHeight: '2em',
            margin: state.isDisabled ? '3px 3px 0 0' : '3px',
            backgroundColor: `${
                !!state?.data?.suggestionScore
                    ? theme.colors.backgroundAutofill
                    : theme.colors.activeField
            }`,
            textOverflow: 'ellipsis',
            ':hover': state.isDisabled
                ? {}
                : {
                      backgroundColor: `${theme.colors.tagHover}`,
                  },
        }
    },
    multiValueLabel: provided => ({
        ...provided,
        alignSelf: 'center',
        fontSize: '.9375em',
        lineHeight: '1.5em',
        color: `${theme.colors.textPrimary}`,
        padding: '.125em .25em !important',
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        color: `${theme.colors.textPrimary}`,
        paddingRight: '.5em',
        paddingLeft: '0',
        display: state.isDisabled ? 'none' : 'flex',
        ':hover': {
            color: 'inherit',
            backgroundColor: 'inherit',
            cursor: 'pointer',
        },
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled
            ? 'transparent'
            : state.isFocused
            ? `${theme.colors.textPrimary}`
            : `${theme.colors.textSecondary}`,
        fontSize: '.9375em',
        lineHeight: '.5em',
        marginLeft: '0',
    }),
    dropdownIndicator: (provided, state) => {
        return {
            ...provided,
            color: state.isDisabled ? `${theme.colors.border}` : `${theme.colors.textPrimary}`,

            ':hover': {
                color: `${theme.colors.textPrimary}`,
            },
        }
    },
}

const Wrapper = styled(Container)<{ error?: boolean; disabled?: boolean }>`
    width: 100%;
    .react-datepicker__input-container input {
        ${inputStyle};
        width: 100%;

        padding-right: 74px;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker__close-icon {
        padding: 0px;
        margin-right: 48px;
    }
    .react-datepicker__header {
        width: 100%;
        background-color: ${({ theme }) => theme.colors.activeField};
    }
    .react-datepicker,
    .react-datepicker__header {
        border-color: ${({ theme }) => theme.colors.border};
    }
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
        border-bottom-color: ${({ theme }) => theme.colors.activeField};
    }
    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
        border-top-color: ${({ theme }) => theme.colors.border};
    }
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
        border-bottom-color: ${({ theme }) => theme.colors.border};
    }
    .react-datepicker__day:hover,
    && .react-datepicker__day--keyboard-selected:hover {
        background-color: #f0f0f0;
    }
    && .react-datepicker__day--selected {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
    && .react-datepicker__day--keyboard-selected {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.textPrimary};
    }
    .react-datepicker__day,
    .react-datepicker__day-name,
    .react-datepicker__current-month {
        color: ${({ theme }) => theme.colors.textPrimary};
    }
    .react-datepicker__navigation.react-datepicker__navigation--previous {
        border-right-color: ${({ theme }) => theme.colors.textPrimary};
    }
    .react-datepicker__navigation.react-datepicker__navigation--previous:hover {
        border-right-color: ${({ theme }) => theme.colors.textSecondary};
    }
    .react-datepicker__navigation.react-datepicker__navigation--next {
        border-left-color: ${({ theme }) => theme.colors.textPrimary};
    }
    .react-datepicker__navigation.react-datepicker__navigation--next:hover {
        border-left-color: ${({ theme }) => theme.colors.textSecondary};
    }
    .react-datepicker__navigation.react-datepicker__navigation--previous,
    .react-datepicker__navigation.react-datepicker__navigation--next {
        outline: none;
    }
`

const Icon = styled(DatePickerIcon)`
    pointer-events: none;
    position: absolute;
    right: 1rem;
    width: 1.125rem;
`

const calendarContainer = ({ children }: any) => {
    const el = document.getElementById('calendar-portal')

    return <Portal container={el}>{children}</Portal>
}

const DatePicker: React.FC<ReactDatePickerProps> = props => {
    const { onFocus, onBlur, onChange, ...restProps } = props
    const yearOptions = React.useMemo(() => years.map(m => ({ label: m, value: m })), [])
    const monthOptions = React.useMemo(() => months.map(m => ({ label: m, value: m })), [])

    const refCustomInput = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const refDatepicker = React.useRef() as React.MutableRefObject<ReactDatePicker>

    const [stateHasFocus, setStateHasFocus] = React.useState(false) //Initial value

    //Custom input
    const CustomInput = forwardRef((props: InputHTMLAttributes<HTMLInputElement> = {}, ref) => {
        return <input type="text" ref={ref as LegacyRef<HTMLInputElement>} {...props} />
    })

    return (
        <Wrapper alignItems="center" style={{ position: 'relative' }}>
            <ReactDatePicker
                ref={refDatepicker}
                isClearable
                placeholderText={DEFAULT_DATE_FORMAT}
                dateFormat={DEFAULT_DATE_FORMAT}
                popperContainer={calendarContainer}
                autoFocus={stateHasFocus}
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                        }}
                    >
                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {'<'}
                        </button>

                        <div
                            style={{
                                flex: 1,
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                            }}
                        >
                            <BaseSelect
                                value={yearOptions.find(m => m.value === getYear(date))}
                                options={yearOptions}
                                onChange={({ value }: any) => {
                                    changeYear(value)
                                }}
                                styles={customStyles}
                            ></BaseSelect>

                            <BaseSelect
                                value={monthOptions[getMonth(date)]}
                                options={monthOptions}
                                onChange={({ value }: any) => {
                                    changeMonth(
                                        monthOptions.findIndex(
                                            ({ value: optValue }) => value === optValue,
                                        ),
                                    )
                                }}
                                styles={customStyles}
                            ></BaseSelect>
                        </div>

                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {'>'}
                        </button>
                    </div>
                )}
                onInputClick={() => {
                    setStateHasFocus(true)
                }}
                onFocus={evt => {
                    setStateHasFocus(true)
                    //Notify parent
                    if (onFocus) {
                        onFocus.call(this, evt)
                    }
                }}
                onBlur={evt => {
                    setStateHasFocus(false)
                    const {
                        target: { value: dateAsString },
                    } = evt
                    const date = parse(dateAsString, DEFAULT_DATE_FORMAT, new Date())

                    if (isMatch(dateAsString, DEFAULT_DATE_FORMAT)) onChange(date, undefined)

                    // Notify parent
                    if (onBlur) {
                        onBlur.call(this, evt)
                    }
                }}
                onChange={(date, evt) => {
                    if (!(evt as unknown as MouseEvent)?.pageX) return
                    onChange(date, evt)
                }}
                onKeyDown={evt => {
                    if (evt.key === 'Tab' || evt.key === 'Enter')
                        refDatepicker.current.setOpen(false)
                }}
                customInput={<CustomInput ref={refCustomInput} />}
                {...restProps}
            />
            <Icon />
        </Wrapper>
    )
}

export default DatePicker
