import React from 'react'

import { StorylineAttribute } from 'codecs/storyline/StorylineAttribute'

import { TRSValuesForTerms } from 'shared/components/TRS/TRSValue'
import { useTRSSimpleFind, TRS_STORY_ARCHETYPES } from 'shared/hooks/useTRS'
import { usePureFn } from 'shared/hooks/usePureFn'

const StorylineArchetypes: React.FC<{
    storylineArchetypes: StorylineAttribute[]
}> = ({ storylineArchetypes }): React.ReactElement => {
    const { terms } = useTRSSimpleFind(TRS_STORY_ARCHETYPES, false)

    return (
        <TRSValuesForTerms<StorylineAttribute>
            values={storylineArchetypes}
            terms={terms}
            valueToTermId={usePureFn(value => value.id)}
            getValueLabel={usePureFn((value, term) => (term ? term.label : value.label))}
        />
    )
}

export default StorylineArchetypes
