import React, { useState } from 'react'
import PageLayout from 'pages/util/PageLayout'
import {
    useDeleteWorkflow,
    useEditWorkflow,
    useReleaseWorkflow,
    useWorkflowsQuery,
} from 'api/workflow/workflow'

import Table, { CheckboxFilter, DateRangeBetweenFilter } from 'shared/components/Table'
import Text from 'shared/components/Text'
import { Column, CellProps, useRowSelect, Row, TableInstance } from 'react-table'
import { Workflow } from '@genome-web-forms/server'
import { SearchResultLink } from 'shared/components/search'
import { cwmClassTypeToLabel } from '@genome-web-forms/common/model/CWMClassType'
import { capitalize, toLower, uniqueId } from 'lodash'
import Loader from 'shared/components/Loader'
import Container from 'shared/components/Container'
import SearchInput from 'shared/components/SearchInput'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import { WorkflowPriorities } from 'model/workflow/WorkflowPriority'
import MediumDate from 'shared/components/MediumDate'
import Button from 'shared/components/Button'
import { flags } from 'shared/flags'
import { routeTo } from 'model/search/routeTo'
import { AlertDialog, AlertDialogLabel } from '@reach/alert-dialog'
import { Form, Formik } from 'formik'
import { AlertDialogButtons } from 'shared/components/AlertModal'
import TextualButton from 'shared/components/TextualButton'
import styled from 'shared/theme'
import { useFullNameMyIDUser, usePartialMyIDUsersQuery } from 'api/workflow/partialMyIDUsers'
import IndeterminateCheckbox from 'shared/components/IndeterminateCheckbox'
import EditWorkflowForm, { WorkflowUpdates } from './EditWorkflowForm'
import EditWorkflowsList from './EditWorkflowsList'
import YesNoBooleanValue from 'shared/components/YesNoBooleanValue'
import workflowTasksTypeLabels from 'shared/components/WorkflowTasksTypeLabels'
import { ReactComponent as DeleteIcon } from 'shared/components/Icons/trash-can-icon.svg'
import { useAuthSnowplowPageView } from 'shared/hooks/useSnowPlowPageView'

type ActionType = 'edit' | 'delete' | 'release'

export const ActionButtonsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    row-gap: 0.5rem;
    align-items: flex-start;
    margin-bottom: 0.5rem;
`

export const SearchContainer = styled.div`
    margin: 0 40px;
`

const DeleteIconStyled = styled(DeleteIcon)`
    cursor: pointer;
    padding-top: 0.35rem;
    color: #4b5d80;
`

const PaddedButton = styled(Button)`
    padding: 0.375rem 1.25rem;
`

const WorkflowsList: React.FC = () => {
    useAuthSnowplowPageView('Workflow Admin', 'Workflow Admin Page')

    const { isLoading, data = [] } = useWorkflowsQuery()
    const [searchText, setSearchText] = React.useState('')

    const [selectedTasks, setSelectedTasks] = React.useState<Workflow[]>([])
    const [showDeleteModal, setShowDeleteModal] = React.useState(false)
    const [showReleaseModal, setShowReleaseModal] = React.useState(false)
    const [showEditModal, setShowEditModal] = React.useState(false)

    const [selectAllEnabled, setSelectAllEnabled] = React.useState<boolean>(false)
    const [selectedAction, setSelectedAction] = React.useState<ActionType>()

    const [scrollYPosition, setScrollYPosition] = React.useState<number>(0)
    const [workflowIdToDelete, setWorkflowIdToDelete] = React.useState<null | number>(null)

    const [tableInst, setTableInst] = React.useState<TableInstance<Workflow> | null>(null)
    const initialUid = React.useMemo(() => uniqueId(), [])
    const [colFilterUid, setColFilterUid] = React.useState(initialUid)

    React.useLayoutEffect(() => {
        const isWorkflowIdIncluded = (): Boolean => {
            const workflowIds = data.map(id => id.workflowId)
            return workflowIdToDelete ? workflowIds.includes(workflowIdToDelete) : true
        }

        const isIncluded = isWorkflowIdIncluded()
        if (!isIncluded) {
            window.scrollTo(0, scrollYPosition)
            setScrollYPosition(0)
            setWorkflowIdToDelete(null)
        }
    }, [scrollYPosition, workflowIdToDelete, data])

    const enableBulkSelection = React.useCallback((action: ActionType) => {
        setSelectedAction(action)
        setSelectAllEnabled(true)
    }, [])

    const cancelBulkSelection = React.useCallback(() => {
        setSelectedTasks([])
        setSelectAllEnabled(false)
    }, [])

    const closeBulkModal = React.useCallback(() => {
        setSelectedTasks([])
        if (selectedAction === 'edit') setShowEditModal(false)
        if (selectedAction === 'delete') setShowDeleteModal(false)
        if (selectedAction === 'release') setShowReleaseModal(false)
    }, [selectedAction])

    const confirmBulkModal = React.useCallback(() => {
        closeBulkModal()
        setSelectedTasks([])
    }, [closeBulkModal])

    const confirmBulkSelection = React.useCallback(() => {
        if (selectedAction === 'edit') setShowEditModal(true)
        if (selectedAction === 'delete') setShowDeleteModal(true)
        if (selectedAction === 'release') setShowReleaseModal(true)
    }, [selectedAction])

    const toggleAllSelectedTasks = React.useCallback(
        (filteredRows: Row<Workflow>[]) => {
            const selectAll = selectedTasks.length === 0
            const selected = selectAll ? filteredRows.map(fr => fr.original) : []
            setSelectedTasks(selected)
            return selected
        },
        [selectedTasks.length],
    )

    const getIsRowSelected = React.useCallback(
        (workflowId: number): boolean => selectedTasks.map(t => t.workflowId).includes(workflowId),
        [selectedTasks],
    )

    const toggleSelectedTask = React.useCallback(
        (task: Workflow, value?: boolean): void => {
            setSelectedTasks(selectedTasks => {
                const isSelected = !!selectedTasks.find(t => t.workflowId === task.workflowId)
                const shouldExist = typeof value !== 'undefined' ? value : !isSelected

                if (!isSelected && shouldExist) {
                    return [...selectedTasks, task]
                } else if (isSelected && !shouldExist) {
                    return selectedTasks.filter(t => t.workflowId !== task.workflowId)
                }

                return selectedTasks
            })
        },
        [setSelectedTasks],
    )

    const isAllRowsSelected = React.useMemo(
        (): boolean => selectedTasks.length > 0 && selectedTasks.length === data.length,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedTasks],
    )

    const isPartialRowsSelected = React.useMemo(
        (): boolean => selectedTasks.length > 0 && selectedTasks.length !== data.length,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedTasks],
    )

    const selectColumn = React.useMemo<Column<Workflow>>(
        () => ({
            id: 'selection',
            disableSortBy: true,
            width: 10,
            Header: ({ globalFilteredRows }) => (
                <IndeterminateCheckbox
                    checked={isAllRowsSelected}
                    onChange={() => toggleAllSelectedTasks(globalFilteredRows)}
                    indeterminate={isPartialRowsSelected}
                />
            ),
            Cell: ({ row: { original: task } }: CellProps<Workflow>) => (
                <input
                    type="checkbox"
                    checked={getIsRowSelected(task.workflowId)}
                    onChange={e => toggleSelectedTask(task, e.target.checked)}
                />
            ),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedTasks],
    )

    const partialUsersQuery = usePartialMyIDUsersQuery()
    const users = React.useMemo<{ [key: string]: string }>(
        () =>
            partialUsersQuery.data!.reduce(
                (obj, key) => ({
                    ...obj,
                    [key['relationship.employeeId']]: `${key.given_name} ${key.family_name}`,
                }),
                {},
            ),
        [partialUsersQuery.data],
    )

    const columns = React.useMemo<Column<Workflow>[]>(
        () => [
            ...(selectAllEnabled ? [selectColumn] : []),
            {
                id: 'delete',
                Header: '',
                Cell: ({ row: { original: workflow } }: CellProps<Workflow>) => (
                    <DeleteIconStyled
                        onClick={() => {
                            setSelectedTasks([workflow])
                            setSelectedAction('delete')
                            setShowDeleteModal(true)
                            setScrollYPosition(window.scrollY)
                            setWorkflowIdToDelete(workflow.workflowId)
                        }}
                    />
                ),
                width: 1,
            },
            {
                Header: 'Title',
                accessor: 'resourceTitle',
                width: 200,
                Cell: ({ row: { original } }: CellProps<Workflow>) => {
                    // TODO remove this when backend fixes itself
                    try {
                        return (
                            <SearchResultLink to={routeTo(original)}>
                                <div style={{ textDecoration: 'underline' }}>
                                    {original.resourceTitle}
                                </div>
                            </SearchResultLink>
                        )
                    } catch (e) {
                        return original.resourceTitle
                    }
                },
            },
            {
                id: 'radarId',
                Header: 'ID',
                accessor: (original: Workflow) => original.radarId.toString(),
                sortDescFirst: true,
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                        {original.radarId}
                    </Text>
                ),
                width: 1,
            },
            {
                id: 'type',
                Header: 'Type',
                accessor: 'cwmClassType',
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {cwmClassTypeToLabel(original.cwmClassType)}
                    </Text>
                ),
                width: 1,
            },
            {
                id: 'task',
                Header: 'Assignment Type',
                accessor: (original: Workflow) => original.workflowConfig.task,
                filter: 'checkbox',
                Filter: props => <CheckboxFilter {...props} key={colFilterUid} />,
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {workflowTasksTypeLabels[original.workflowConfig.task as string]}
                    </Text>
                ),
                width: 1,
            },
            {
                Header: 'Assignee',
                accessor: (original: Workflow) =>
                    original.assignee && users[original.assignee]
                        ? users[original.assignee]
                        : original.assignee,
                Cell: ({ row: { original } }: CellProps<Workflow>) => {
                    const asigneeFullName = useFullNameMyIDUser(original?.assignee)

                    return (
                        <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                            {asigneeFullName}
                        </Text>
                    )
                },
                filter: 'checkbox',
                Filter: props => <CheckboxFilter {...props} key={colFilterUid} />,
                width: 1,
            },
            {
                Header: 'Priority',
                accessor: 'priority',
                filter: 'checkbox',
                Filter: props => <CheckboxFilter {...props} key={colFilterUid} />,
                sortType: ({ original: a }, { original: b }) => {
                    if (!a.priority) {
                        return -1
                    }
                    if (!b.priority) {
                        return 1
                    }
                    return (
                        WorkflowPriorities.indexOf(a.priority) -
                        WorkflowPriorities.indexOf(b.priority)
                    )
                },
                sortDescFirst: true,
                Cell: ({ row: { original } }: CellProps<Workflow>) =>
                    capitalize(original.priority ?? ''),
                width: 1,
            },
            {
                Header: 'Due Date',
                accessor: 'deadlineAt',
                filter: 'dateRangeBetween',
                Filter: props => <DateRangeBetweenFilter {...props} key={colFilterUid} />,
                sortType: 'datetime',
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {original.deadlineAt ? <MediumDate date={original.deadlineAt} /> : null}
                    </Text>
                ),
                width: 1,
            },
            {
                Header: 'Creation Date',
                accessor: 'createdAt',
                id: 'createdDate',
                filter: 'dateRangeBetweenPlus',
                Filter: props => <DateRangeBetweenFilter {...props} key={colFilterUid} />,
                sortType: 'datetime',
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {<MediumDate date={original.createdAt} />}
                    </Text>
                ),
                width: 1,
            },
            {
                Header: 'Status',
                accessor: 'state',
                filter: 'checkbox',
                Filter: props => <CheckboxFilter {...props} key={colFilterUid} />,
                sortDescFirst: true,
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {original.state}
                    </Text>
                ),
                width: 1,
            },
            {
                id: 'source',
                Header: 'Source',
                accessor: (original: Workflow) => original.source,
                filter: 'checkbox',
                Filter: props => <CheckboxFilter {...props} key={colFilterUid} />,
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {original.source}
                    </Text>
                ),
                width: 1,
            },
            {
                Header: 'Review',
                accessor: (original: Workflow) => original.workflowConfig.reviewRequired,
                filter: 'checkbox',
                Filter: props => <CheckboxFilter {...props} key={colFilterUid} />,
                sortDescFirst: true,
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        <YesNoBooleanValue value={original.workflowConfig.reviewRequired} />
                    </Text>
                ),
                width: 1,
            },
            {
                Header: 'Actions',
                Cell: ({ row: { original: workflow } }: CellProps<Workflow>) => {
                    if (!flags.workflowsDelete) return

                    return (
                        <ActionButtonsGrid>
                            {workflow.assignee && (
                                <Button
                                    size="small"
                                    variant="primary"
                                    onClick={() => {
                                        setSelectedTasks([workflow])
                                        setShowReleaseModal(true)
                                    }}
                                >
                                    Abandon
                                </Button>
                            )}
                        </ActionButtonsGrid>
                    )
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            data,
            selectAllEnabled,
            selectedTasks,
            colFilterUid,
            toggleAllSelectedTasks,
            toggleSelectedTask,
        ],
    )

    const handleTableInstSet = React.useCallback(
        (tbl: TableInstance<Workflow>) => {
            setTableInst(tbl)
        },
        [setTableInst],
    )

    const handleClearFiltersClick = React.useCallback(() => {
        tableInst && tableInst.setAllFilters([])
        setSearchText('')
        setColFilterUid(uniqueId())
    }, [tableInst, setSearchText, setColFilterUid])

    return (
        <PageLayout>
            <SearchContainer>
                <Container mb={2} alignItems="center">
                    <TableHeaderContent>
                        <Text as="h3" size="2" my="2">
                            Workflows List
                        </Text>

                        <SearchInput
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                        />
                    </TableHeaderContent>
                    <>
                        <PaddedButton
                            title="Clear filters to default settings"
                            variant="outlineNoBorders"
                            onClick={handleClearFiltersClick}
                        >
                            Clear Filters
                        </PaddedButton>
                        {!selectAllEnabled ? (
                            <>
                                <Button
                                    size="small"
                                    variant="primary"
                                    onClick={() => enableBulkSelection('delete')}
                                >
                                    Bulk Delete
                                </Button>
                                <Button
                                    size="small"
                                    variant="primary"
                                    onClick={() => enableBulkSelection('edit')}
                                >
                                    Bulk Edit
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    size="small"
                                    variant="primary"
                                    onClick={() => confirmBulkSelection()}
                                >
                                    {capitalize(selectedAction)}
                                </Button>
                                <Button
                                    size="small"
                                    variant="outline"
                                    onClick={() => cancelBulkSelection()}
                                >
                                    Cancel
                                </Button>
                            </>
                        )}
                    </>
                </Container>

                {isLoading ? (
                    <Loader center size="normal" />
                ) : (
                    <Table<Workflow>
                        {...{
                            columns,
                            data,
                            searchText,
                            initialState: {
                                sortBy: [{ desc: true, id: 'createdDate' }],
                                pageIndex: 0,
                                pageSize: 100,
                            },
                            useRowSelect,
                            pagination: true,
                            onTableInstInit: handleTableInstSet,
                        }}
                    />
                )}
            </SearchContainer>

            {showDeleteModal && (
                <ConfirmDeleteModal closeModal={closeBulkModal} workflows={selectedTasks} />
            )}

            {showEditModal && (
                <ConfirmEditModal
                    closeModal={closeBulkModal}
                    confirmModal={confirmBulkModal}
                    workflows={selectedTasks}
                />
            )}

            {showReleaseModal && selectedTasks.length > 0 && (
                <ConfirmReleaseModal
                    closeModal={() => setShowReleaseModal(false)}
                    workflows={selectedTasks}
                />
            )}
        </PageLayout>
    )
}

const ConfirmEditModal: React.FC<{
    closeModal: Function
    confirmModal: Function
    workflows: Workflow[]
}> = ({ closeModal, confirmModal, workflows }) => {
    const cancelRef = React.createRef<HTMLButtonElement>()

    const editWorkflows = useEditWorkflow()

    const handleSubmit = (values: WorkflowUpdates): void => {
        editWorkflows.mutate(workflows.map(w => ({ workflowId: w.workflowId, ...values } as any)))
        confirmModal()
    }

    const initialValues = {}

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues }} onSubmit={handleSubmit}>
                {({ values }) => (
                    <Form>
                        <AlertDialogLabel>Editable fields</AlertDialogLabel>

                        <Container pt="2" pb="2" flexDirection="column">
                            <EditWorkflowForm />
                        </Container>

                        <AlertDialogLabel>The following tasks will be updated:</AlertDialogLabel>

                        <Container pt="0" flexDirection="column">
                            <EditWorkflowsList data={workflows} />
                        </Container>

                        <AlertDialogButtons>
                            <Button variant="primary" type="submit">
                                Confirm changes
                            </Button>
                            <TextualButton
                                type="button"
                                onClick={() => closeModal()}
                                ref={cancelRef}
                            >
                                Cancel
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

const ConfirmDeleteModal: React.FC<{ closeModal: Function; workflows: Workflow[] }> = ({
    closeModal,
    workflows,
}) => {
    const cancelRef = React.createRef<HTMLButtonElement>()

    const deleteWorkflow = useDeleteWorkflow()

    const onSubmit = (): void => {
        deleteWorkflow.mutate(workflows)
        closeModal()
    }

    const initialValues = {}

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues, onSubmit }}>
                {() => (
                    <Form>
                        <AlertDialogLabel>
                            Are you sure you want to delete the following task(s)?
                        </AlertDialogLabel>
                        <ul>
                            {workflows.map(w => (
                                <li key={w.workflowId}>
                                    {w.resourceTitle} -{' '}
                                    {toLower(
                                        workflowTasksTypeLabels[w.workflowConfig.task as string],
                                    )}
                                </li>
                            ))}
                        </ul>
                        <AlertDialogButtons>
                            <Button variant="danger" type="submit">
                                Delete
                            </Button>
                            <TextualButton
                                type="button"
                                onClick={() => closeModal()}
                                ref={cancelRef}
                            >
                                Cancel
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

const ConfirmReleaseModal: React.FC<{ closeModal: Function; workflows: Workflow[] }> = ({
    closeModal,
    workflows,
}) => {
    const cancelRef = React.createRef<HTMLButtonElement>()

    const [loading, setLoading] = useState<boolean>(false)

    const releaseWorkflow = useReleaseWorkflow()

    const onSubmit = (): void => {
        setLoading(true)
        releaseWorkflow.mutate(workflows, {
            onSuccess: () => {
                closeModal()
                setLoading(false)
            },
        })
    }

    const initialValues = {}

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues, onSubmit }}>
                {() => (
                    <Form>
                        <AlertDialogLabel>
                            Are you sure you want to abandon the following task(s)?
                        </AlertDialogLabel>

                        <ul>
                            {workflows.map(w => (
                                <li key={w.workflowId}>
                                    {w.resourceTitle} -{' '}
                                    {toLower(
                                        workflowTasksTypeLabels[w.workflowConfig.task as string],
                                    )}
                                </li>
                            ))}
                        </ul>

                        <AlertDialogButtons>
                            <Button
                                variant="primary"
                                disabled={loading}
                                isLoading={loading}
                                type="submit"
                            >
                                <span>Abandon</span>
                            </Button>
                            <TextualButton
                                type="button"
                                onClick={() => closeModal()}
                                ref={cancelRef}
                            >
                                Close
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

export default WorkflowsList
