import { orderBy } from 'natural-orderby'

function isObject<T>(obj: T) {
    return obj && typeof obj === 'object'
}

export function sortDeep<T extends any>(
    root: T | T[],
    callback: (prop: T) => string | number,
    attr: keyof T,
): T[] | T {
    if (Array.isArray(root)) {
        return orderBy(
            root.map(i => sortDeep<any>(i, callback, attr)),
            callback,
        ) as T[]
    }

    if (isObject(root)) {
        return Object.keys(root as any)
            .sort()
            .reduce(function (sortedRoot, key) {
                ;(sortedRoot as any)[key] = sortDeep((root as any)[key], callback, attr)
                return sortedRoot
            }, {}) as T
    }

    return root
}
