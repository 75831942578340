import styled from 'shared/theme'
import Container from 'shared/components/Container'

export default styled(Container).attrs({
    alignItems: 'center',
})`
    flex-grow: 1;
    > * {
        margin-right: 1rem;
    }
`
