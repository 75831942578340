import React from 'react'

export const TitleError: React.FC = ({ children }) => {
    return <h1 style={{ padding: '1.5rem' }}>{children}</h1>
}

export const NotFoundError: React.FC = () => {
    return <TitleError>404: Not Found</TitleError>
}

export const ForbiddenError: React.FC = () => {
    return <TitleError>403: Forbidden</TitleError>
}
