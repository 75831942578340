import * as t from 'io-ts'
import { nullable } from 'codecs/util/nullable'

export const NewPortrayalResponseCodec = t.type({
    portrayalId: t.string,
    portrays: t.array(t.string),
    portrayedBy: t.array(t.string),
    hasScope: t.string,
    associations: t.array(t.string),
})

export interface NewPortrayalResponse extends t.TypeOf<typeof NewPortrayalResponseCodec> {}

export const UpdatedPortrayalResponseCodec = t.type({
    portrayalId: t.string,
    portrays: t.array(t.string),
    portrayedBy: t.array(t.string),
    // Shouldn't be possible to have portrayals with no role/scope but some of them come from semaphore and may not have it
    hasScope: t.union([t.string, t.null]),
    associations: t.unknown,
})

export interface UpdatedPortrayalResponse extends t.TypeOf<typeof UpdatedPortrayalResponseCodec> {}

export const TitleAssociationCodec = t.type({
    titleName: t.string,
    titleUri: t.string,
})

export interface TitleAssociation extends t.TypeOf<typeof TitleAssociationCodec> {}

export const PortraysItemCodec = t.type({
    id: t.string,
    type: t.string,
    label: t.string,
    firstName: t.string,
    lastName: t.string,
    mdmID: t.string,
})

export interface PortraysItem extends t.TypeOf<typeof PortraysItemCodec> {}

export const PortrayedByItemCodec = t.type({
    id: t.string,
    type: t.string,
    label: t.string,
    firstName: t.string,
    lastName: t.string,
    mdmID: t.string,
})

export interface PortrayedByItem extends t.TypeOf<typeof PortrayedByItemCodec> {}

export const PortrayalFoundCodec = t.type({
    genomeId: t.string, // "http://data.disney.com/resources/a6b0f264-3e26-471c-9797-49af4a7eaa21" // portrayalId
    portrayalLabel: t.string, // aka portrayal name
    portrayalType: t.string, // http://data.disney.com/creativeWork#PortrayalOfCharacter
    portrayalTypeLabel: t.string,
    scope: t.string, // aka role
    scopeName: t.string,
    portrays: t.array(PortraysItemCodec),
    portrayedBy: t.array(PortrayedByItemCodec),
    // ! Note: We get the array below only searching by portrayalUri for performance reason
    isAssociatedWithTitle: t.array(TitleAssociationCodec), // http://data.disney.com/resources/4e11f653-29c7-4939-a3eb-f9a58b61f072
})

export interface PortrayalFound extends t.TypeOf<typeof PortrayalFoundCodec> {}

export const PortrayalListItemCodec = t.type({
    characterName: t.string,
    genomeId: t.string,
    isAssociatedWith: t.string,
    portrayalType: t.string,
    portrayedBy: t.string,
    portrays: t.string,
    talentFirstName: t.string,
    talentLastName: t.string,
})

export interface PortrayalListItem extends t.TypeOf<typeof PortrayalListItemCodec> {}

export const RelationshipTargetCodec = t.type({
    prefLabel: t.string,
    uri: t.string,
})

export interface RelationshipTarget extends t.TypeOf<typeof RelationshipTargetCodec> {}

export const CharacterRelationshipCodec = t.type({
    type: t.string, // "http://data.disney.com/character#hasYounger",
    targetCharacter: RelationshipTargetCodec,
    productionStatus: t.string,
    snapShotID: nullable(t.string),
    lockedByUri: nullable(t.string),
    lockedByUsername: nullable(t.string),
    snapShotCreatedDate: nullable(t.string),
})

export interface CharacterRelationship extends t.TypeOf<typeof CharacterRelationshipCodec> {}

export const AdditionalCharacterFieldCodec = t.type({
    prefLabel: t.string,
    uri: t.string,
})

export const CharacterRelationshipDataCodec = t.type({
    character: t.type({
        prefLabel: t.string,
        uri: t.string,
        aka: t.array(AdditionalCharacterFieldCodec),
        fka: t.array(AdditionalCharacterFieldCodec),
    }),
    guid: nullable(t.string),
    mdmID: nullable(t.string),
    relationships: t.array(CharacterRelationshipCodec),
})

export interface CharacterRelationshipData
    extends t.TypeOf<typeof CharacterRelationshipDataCodec> {}

export const RelationshipListSnapshotCodec = t.type({
    currentUser: t.string,
    snapShotID: t.string,
})

export interface RelationshipListSnapshot extends t.TypeOf<typeof RelationshipListSnapshotCodec> {}
