import * as t from 'io-ts'
import * as s from 'shared/diff/schema'
import { ArrayToUnique } from 'codecs/util/ArrayToUnique'
import fromPairs from 'lodash/fromPairs'
import { orderBy } from 'natural-orderby'
import uniqBy from 'lodash/uniqBy'

import { AttributeCodec } from 'codecs/Attribute'

/**
 * Represents the `attributes` key in Features/Series/Episodes/etc
 *
 * Has a format like:
 *
 *  {
 *      "http://data.disney.com/resources/AgeGroups: [
 *          {
 *              attributeType: "http://data.disney.com/resources/AgeGroups",
 *              attributeTypeName: "Age",
 *              attributeId: "http://data.disney.com/resources/6366b8ef-5ea2-4a12-ad6e-797bacbdffdc",
 *              attributeLabel: "Adult"
 *          }
 *      ]
 *  }
 */
export const RecordedAttributesCodec = t.record(
    t.string,
    ArrayToUnique(
        AttributeCodec,
        attribute => attribute.attributeId,
        `Array<${AttributeCodec.name}>`,
    ),
)
export type RecordedAttributes = t.TypeOf<typeof RecordedAttributesCodec>

/////////////////////////////////////////////
// Constants
/////////////////////////////////////////////

export type AttributeMeta = {
    type: string
    label: string
    placeholder: string
    menuPlacement?: 'top' | 'bottom' | 'auto'
}

type TaggingAvailableAttributeLabels =
    | 'Agents'
    | 'Disciplines/Industries'
    | 'Gender'
    | 'Holidays & Seasons'
    | 'Lead(s) Age'
    | 'Lead(s) Gender'
    | 'Mood'
    | 'Scope/Scale'
    | 'Setting'
    | 'Source Material'
    | 'Story Elements'
    | 'Subject'
    | 'Time/Era'
    | 'Creative Elements'

export const ATTRIBUTE_META_LIST: {
    type: string
    label: TaggingAvailableAttributeLabels
    placeholder: `Select ${TaggingAvailableAttributeLabels}`
    menuPlacement?: 'top' | 'bottom' | 'auto'
}[] = [
    {
        type: 'http://data.disney.com/resources/Moods',
        label: 'Mood',
        placeholder: 'Select Mood',
    },
    {
        type: 'http://data.disney.com/resources/StoryElements',
        label: 'Story Elements',
        placeholder: 'Select Story Elements',
    },
    {
        type: 'http://data.disney.com/resources/Subjects',
        label: 'Subject',
        placeholder: 'Select Subject',
    },
    {
        type: 'http://data.disney.com/resources/DisciplinesIndustries',
        label: 'Disciplines/Industries',
        placeholder: 'Select Disciplines/Industries',
    },
    {
        type: 'http://data.disney.com/resources/Agents',
        label: 'Agents',
        placeholder: 'Select Agents',
    },
    {
        type: 'http://data.disney.com/resources/AgeGroups',
        label: 'Lead(s) Age',
        placeholder: 'Select Lead(s) Age',
    },
    {
        type: 'http://data.disney.com/resources/Genders',
        label: 'Lead(s) Gender',
        placeholder: 'Select Lead(s) Gender',
    },
    {
        type: 'http://data.disney.com/resources/TimeEra',
        label: 'Time/Era',
        placeholder: 'Select Time/Era',
    },
    {
        type: 'http://data.disney.com/resources/SettingTypes',
        label: 'Setting',
        placeholder: 'Select Setting',
        menuPlacement: 'top',
    },
    {
        type: 'http://data.disney.com/resources/HolidaysSeasons',
        label: 'Holidays & Seasons',
        placeholder: 'Select Holidays & Seasons',
    },
    // {
    //     type: 'http://data.disney.com/resources/ScopesScales',
    //     label: 'Scope/Scale',
    //     placeholder: 'Select Scope/Scale',
    // },
    {
        type: 'http://data.disney.com/resources/SourceMaterials',
        label: 'Source Material',
        placeholder: 'Select Source Material',
    },
    {
        type: 'http://data.disney.com/resources/CreativeElements',
        label: 'Creative Elements',
        placeholder: 'Select Creative Elements',
    },
]

// util
export const attributesMergeAutofill = (
    attributes: RecordedAttributes | null,
    autofill: RecordedAttributes,
): RecordedAttributes | null => {
    if (!attributes) {
        return autofill
    }

    return fromPairs(
        ATTRIBUTE_META_LIST.map(({ type }) => {
            const values = attributes[type] ?? []
            const autofillValues = autofill[type] ?? []
            return [type, uniqBy([...values, ...autofillValues], a => a.attributeId)]
        }),
    )
}

// diff
export const AttributesDiffSchema = s.schema(
    fromPairs(
        ATTRIBUTE_META_LIST.map(({ label }) => {
            return [label, s.array(s.value<string>())]
        }),
    ) as {
        [K in typeof ATTRIBUTE_META_LIST[number]['label']]: s.ArraySchemaC<s.ValueSchema<string>>
    },
)
export type DiffableAttributes = s.TypeOf<typeof AttributesDiffSchema>
export const toDiffableAttributes = (
    recordedAttributes: RecordedAttributes | null,
): DiffableAttributes => {
    return fromPairs(
        ATTRIBUTE_META_LIST.map(({ label, type }) => {
            const attributes = (recordedAttributes && recordedAttributes[type]) || []
            const valueLabels = orderBy(attributes.map(attr => attr.attributeLabel))
            return [label, valueLabels]
        }),
    ) as {
        [K in typeof ATTRIBUTE_META_LIST[number]['label']]: string[]
    }
}
