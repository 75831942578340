import React from 'react'
import * as D from 'io-ts/lib/Decoder'
import { omit } from 'lodash'
import invariant from 'tiny-invariant'
import { useMutation, useQuery, UseQueryResult } from 'react-query'

import { request } from '@genome-web-forms/common/api'
import { MyIDUser } from '@genome-web-forms/common/auth'
import {
    CreateWorkflowRequest_CREATIVE_WORK_TAGGING_Input,
    CreativeWorkTaggingEvent,
    CREATIVE_WORK_QA_AVAILABLE,
    CREATIVE_WORK_TAGGING_AVAILABLE,
    CREATIVE_WORK_REVIEW_AVAILABLE,
    CREATIVE_WORK_READY_FOR_DELIVERY,
    Workflow,
    WorkflowDec,
    CREATIVE_WORK_PUBLISHED,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS,
} from '@genome-web-forms/server'

import { authGWF } from 'api/auth'
import { useUser } from 'auth/Auth'
import { useQueryClient } from 'react-query/react'
import config from 'shared/config'
import { queryClient } from 'shared/queryClient'
import { DistributiveOmit } from 'shared/types'
import { notification } from 'shared/notification'
import { useHasPortrayalsEditingRoles } from 'shared/hooks/useHasRole'
import { WorkflowUpdates } from 'pages/admin/workflow/list/EditWorkflowForm'

export type WorkflowAssignmentTableType =
    | 'SELF'
    | 'TAGGING'
    | 'QA'
    | 'ALL'
    | 'APPROVAL'
    | 'DELIVERY'

type WorkflowUpdatesRequest = WorkflowUpdates & { workflowId: number }

const workflowURLs = {
    // query
    all: `${config.urlGWFWorkflows}/workflows`,
    one: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${encodeURIComponent(workflowId)}`,
    activeByResourceId: (resourceId: Workflow['resourceId']) =>
        `${config.urlGWFWorkflows}/workflows/active-by-resource-id/${encodeURIComponent(
            resourceId,
        )}`,
    // mutate
    selfAssign: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/self-assign`,
    start: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/start`,
    pause: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/pause`,
    resume: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/resume`,
    taggingComplete: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/tagging-complete`,
    releaseTask: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/release-task`,
    publishStart: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/publish-start`,
    publishSuccess: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/publish-success`,
    publishError: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/publish-error`,
    reviewAvailable: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/review-available`,
    reviewApprove: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/review-approve`,
    reviewReject: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/review-reject`,
    deliver: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/deliver`,
    deliverReject: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/deliverReject`,
    edit: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}`,
} as const

const workflowKeys = {
    all: [{ scope: 'workflows' }],
    activeByResourceId: (resourceId: string) => [{ scope: 'workflows', active: true, resourceId }],
} as const

export const createAssignment = (
    createRequest: CreateWorkflowRequest_CREATIVE_WORK_TAGGING_Input,
    user: MyIDUser,
): Promise<Workflow> => {
    return request(
        WorkflowDec,
        authGWF(user, {
            url: workflowURLs.all,
            method: 'POST',
            data: createRequest,
        }),
    )
}

export const WORKFLOW_ACTIONS = {
    SELF_ASSIGN: workflowURLs.selfAssign,
    START: workflowURLs.start,
    PAUSE: workflowURLs.pause,
    RESUME: workflowURLs.resume,
    TAGGING_COMPLETE: workflowURLs.taggingComplete,
    RELEASE_TASK: workflowURLs.releaseTask,
    PUBLISH_START: workflowURLs.publishStart,
    PUBLISH_SUCCESS: workflowURLs.publishSuccess,
    PUBLISH_ERROR: workflowURLs.publishError,
    REVIEW_MAKE_AVAILABLE: workflowURLs.reviewAvailable,
    REVIEW_APPROVE: workflowURLs.reviewApprove,
    REVIEW_REJECT: workflowURLs.reviewReject,
    EDIT: workflowURLs.edit,
    DELIVER: workflowURLs.deliver,
    DELIVER_REJECT: workflowURLs.deliverReject,
} as const

export type WorkflowActionEvent = DistributiveOmit<
    Extract<CreativeWorkTaggingEvent, { type: keyof typeof WORKFLOW_ACTIONS }>,
    'user'
>

export const workflowAction = async (
    user: MyIDUser,
    workflow: Workflow,
    event: WorkflowActionEvent,
): Promise<Workflow> => {
    const type: keyof typeof WORKFLOW_ACTIONS = event.type
    const url = WORKFLOW_ACTIONS[type]
    invariant(url)
    const res = await request(
        WorkflowDec,
        authGWF(user, {
            method: 'PUT',
            url: url(workflow.workflowId),
            data: omit({ ...event, ...workflow }, ['user', 'type']),
        }),
    )

    await queryClient.invalidateQueries(workflowKeys.all)

    return res
}

// eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
export const useSelfAssignWorkflow = () => {
    const user = useUser()
    const queryClient = useQueryClient()
    return useMutation<Workflow, unknown, Workflow>({
        mutationFn: workflow => workflowAction(user, workflow, { type: 'SELF_ASSIGN' }),
        onMutate: async _ => {
            await queryClient.cancelQueries(workflowKeys.all)
        },
        onSettled: () => {
            queryClient.invalidateQueries(workflowKeys.all)
        },
    })
}

// eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
export const useRejectWorkflow = () => {
    const user = useUser()
    const queryClient = useQueryClient()
    return useMutation<Workflow, unknown, Workflow>({
        mutationFn: workflowWithComment => {
            return request(
                WorkflowDec,
                authGWF(user, {
                    method: 'PUT',
                    url: workflowURLs.reviewReject(workflowWithComment.workflowId),
                    data: workflowWithComment,
                }),
            )
        },
        onMutate: async _ => {
            await queryClient.cancelQueries(workflowKeys.all)
        },
        onSettled: () => {
            queryClient.invalidateQueries(workflowKeys.all)
        },
    })
}

// eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
export const useEditWorkflow = () => {
    const user = useUser()
    const queryClient = useQueryClient()
    return useMutation<
        WorkflowUpdatesRequest[],
        unknown,
        Workflow[],
        { previousWorkflows: Workflow[] }
    >({
        mutationFn: workflowsToUpdate => {
            const promises = workflowsToUpdate.map(async data => {
                await request(
                    WorkflowDec,
                    authGWF(user, {
                        method: 'PUT',
                        url: workflowURLs.edit(data.workflowId),
                        data,
                    }),
                )
            })

            return Promise.all(promises) as any
        },
        onMutate: async workflowsToUpdate => {
            await queryClient.cancelQueries(workflowKeys.all)

            const previousWorkflows: Workflow[] = queryClient.getQueryData(workflowKeys.all) ?? []

            queryClient.setQueryData(workflowKeys.all, (workflows?: Workflow[]) => {
                return (
                    workflows?.filter(
                        workflow =>
                            !workflowsToUpdate.map(w => w.workflowId).includes(workflow.workflowId),
                    ) ?? []
                )
            })
            return { previousWorkflows }
        },
        onSuccess: async workflowsToDelete => {
            notification.success(`${workflowsToDelete.length} task have been updated`)
        },
        onError: (_err, _workflow, context) => {
            if (context) {
                queryClient.setQueryData(workflowKeys.all, context.previousWorkflows)
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(workflowKeys.all)
        },
    })
}

// eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
export const useDeleteWorkflow = () => {
    const user = useUser()
    const queryClient = useQueryClient()

    return useMutation<Workflow[], unknown, Workflow[], { previousWorkflows: Workflow[] }>({
        mutationFn: workflowsToDelete => {
            const promises = workflowsToDelete.map(async w => {
                await request(
                    WorkflowDec,
                    authGWF(user, {
                        method: 'DELETE',
                        url: workflowURLs.one(w.workflowId),
                    }),
                )
            })

            return Promise.all(promises) as any
        },
        onMutate: async workflowsToDelete => {
            await queryClient.cancelQueries(workflowKeys.all)

            const previousWorkflows: Workflow[] = queryClient.getQueryData(workflowKeys.all) ?? []

            queryClient.setQueryData(workflowKeys.all, (workflows?: Workflow[]) => {
                return (
                    workflows?.filter(
                        workflow =>
                            !workflowsToDelete.map(w => w.workflowId).includes(workflow.workflowId),
                    ) ?? []
                )
            })
            return { previousWorkflows }
        },
        onSuccess: async workflowsToDelete => {
            notification.success(`${workflowsToDelete.length} task have been deleted`)
        },
        onError: (_err, _workflow, context) => {
            if (context) {
                queryClient.setQueryData(workflowKeys.all, context.previousWorkflows)
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(workflowKeys.all)
        },
    })
}

// eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
export const useReleaseWorkflow = (refresh = false) => {
    const user = useUser()
    const queryClient = useQueryClient()
    return useMutation<Workflow[], unknown, Workflow[], { previousWorkflows: Workflow[] }>({
        mutationFn: workflowsToRelease => {
            const promises = workflowsToRelease.map(async w =>
                workflowAction(user, w, { type: 'RELEASE_TASK' }),
            )

            return Promise.all(promises) as any
        },
        onMutate: async workflowsToRelease => {
            await queryClient.cancelQueries(workflowKeys.all)

            const previousWorkflows: Workflow[] = queryClient.getQueryData(workflowKeys.all) ?? []

            queryClient.setQueryData(workflowKeys.all, (workflows?: Workflow[]) => {
                return (
                    workflows?.filter(
                        workflow =>
                            !workflowsToRelease
                                .map(w => w.workflowId)
                                .includes(workflow.workflowId),
                    ) ?? []
                )
            })
            return { previousWorkflows }
        },
        onSuccess: async workflowsToRelease => {
            notification.success(
                `${workflowsToRelease.length} task(s) succesfully abandoned.${
                    refresh ? ' Refreshing title data...' : ''
                }`,
            )

            if (refresh) {
                setTimeout(() => window.location.reload(), 1000)
            }
        },
        onError: (_err, _workflow, context) => {
            if (context) {
                queryClient.setQueryData(workflowKeys.all, context.previousWorkflows)
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(workflowKeys.all)
        },
    })
}

export const fetchActiveWorkflowsByResourceId = (
    user: MyIDUser,
    resourceId: string,
): Promise<Workflow[]> => {
    return request(
        D.array(WorkflowDec),
        authGWF(user, {
            url: workflowURLs.activeByResourceId(resourceId),
        }),
    )
}

export const useWorkflowsQuery = (
    assignmentType: WorkflowAssignmentTableType = 'ALL',
): UseQueryResult<Workflow[]> => {
    const user = useUser()
    const canEditPortrayals = useHasPortrayalsEditingRoles()
    return useQuery(workflowKeys.all, () => fetchWorkflowsList(user), {
        select: React.useCallback(
            (workflows: Workflow[]) => {
                let _workflows = workflows.filter(w => w.state !== CREATIVE_WORK_PUBLISHED)
                if (!canEditPortrayals) {
                    _workflows = _workflows.filter(
                        w =>
                            w.workflowConfig.task !==
                            WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS,
                    )
                }
                switch (assignmentType) {
                    case 'SELF':
                        return _workflows.filter(
                            w => w.assignee === user['relationship.employeeId'],
                        )
                    case 'TAGGING':
                        return _workflows.filter(w => w.state === CREATIVE_WORK_TAGGING_AVAILABLE)
                    case 'QA':
                        return _workflows.filter(w => w.state === CREATIVE_WORK_QA_AVAILABLE)
                    case 'APPROVAL':
                        return _workflows.filter(w => w.state === CREATIVE_WORK_REVIEW_AVAILABLE)
                    case 'DELIVERY':
                        return _workflows.filter(w => w.state === CREATIVE_WORK_READY_FOR_DELIVERY)
                    case 'ALL':
                    default:
                        return _workflows
                }
            },
            [user, assignmentType, canEditPortrayals],
        ),
    })
}

export const useWorkflowQuery = (workflowId: number): UseQueryResult<Workflow> => {
    const user = useUser()
    return useQuery(workflowURLs.one(workflowId), () => fetchWorkflow(user, workflowId))
}

export const fetchWorkflowsList = (user: MyIDUser): Promise<Workflow[]> => {
    return request(
        D.array(WorkflowDec),
        authGWF(user, {
            url: workflowURLs.all,
        }),
    )
}

export const fetchWorkflow = (user: MyIDUser, workflowId: number): Promise<Workflow> => {
    return request(
        WorkflowDec,
        authGWF(user, {
            url: workflowURLs.one(workflowId),
        }),
    )
}
