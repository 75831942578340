import * as t from 'io-ts'
import { nullable } from 'codecs/util/nullable'

/////////////////////////////////////////////
// Associations
/////////////////////////////////////////////
export const AssociationsTaxonomyCodec = t.type({
    typeURI: nullable(t.string),
    label: nullable(t.string),
})

export type TAssociationsTaxonomy = {
    uri: string
    label: string
    broaderId?: string
}
export const TaxonomyLinkFromBackend = t.type({
    titleId: t.string,
    typeURI: t.string,
    associations: t.array(
        t.type({
            uri: t.string,
            label: t.string,
        }),
    ),
})

export const AssociationsCodec = t.type({
    type: t.type({
        typeURI: t.string,
        label: t.string,
        associations: t.array(
            t.type({
                uri: t.string,
                label: t.string,
            }),
        ),
    }),
})

export const AssociationWithNotesCodec = t.intersection([
    AssociationsCodec,
    t.type({
        notes: t.string,
    }),
])

export type Association = t.TypeOf<typeof AssociationsCodec>
export type AssociationWithNotes = t.TypeOf<typeof AssociationWithNotesCodec>
export type TTaxonomyLinkFromBackend = t.TypeOf<typeof TaxonomyLinkFromBackend>
