import React, { useMemo, useState } from 'react'
import { Row } from 'react-table'
import ReactDatePicker from 'react-datepicker'
import without from 'lodash/without'

import styled from 'shared/theme'
import { ReactComponent as FilterInactiveIcon } from './filter-icon.svg'
import { ReactComponent as FilterActiveIcon } from './filter-active-icon.svg'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useOnClickOutsideRef } from 'shared/hooks/useOnClickOutside'
import { isEmpty, omitBy, isNil } from 'lodash'

const FilterContainer = styled.div`
    margin: 0.25rem 0.25rem 0 0.25rem;
`

const ListContainer = styled.div`
    z-index: 1;
    position: absolute;
    left: 0;
    top: 1.5rem;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.gray};
    border-radius: 4px;
    padding: 1rem;
    max-height: 20.5rem;
    min-width: 20.625rem;
`

const DatePicker = styled(ReactDatePicker)`
    outline: none;
    border: 1px solid ${props => props.theme.colors.gray};
    border-radius: 2px;
    padding: 0.75rem 0.6rem;
    margin: 0 9px;

    &:focus {
        border-color: #1e7cff;
    }
`

const ResetButton = styled.button`
    cursor: pointer;
    display: block;
    border: none;
    border-radius: 3px;
    background-color: #1f7cff;
    font-size: 1rem;
    color: ${props => props.theme.colors.white};
    padding: 0.3rem 0.6rem;
    margin: 15px auto 0 auto;
`

export function dateRangeBetweenFilterType(
    rows: Array<Row>,
    columnIds: string | number,
    filterValues: string | Date[],
): Array<Row> {
    let startDate = new Date(filterValues[0])
    let endDate = new Date(filterValues[1])
    return rows.filter(row => {
        const time = new Date(row.values[columnIds])
        const isFilterValuesEmpty = Array.isArray(filterValues)
            ? isEmpty(omitBy(filterValues, isNil))
            : false
        if (filterValues.length === 0 || isFilterValuesEmpty) return rows
        return time >= startDate && time <= endDate
    })
}

export function dateRangeBetweenPlusFilterType(
    rows: Array<Row>,
    columnIds: string | number,
    filterValues: string | Date[],
): Array<Row> {
    let startDate = new Date(filterValues[0])
    let endDate = new Date(filterValues[1])
    endDate = new Date(endDate.setDate(endDate.getDate() + 1))
    return rows.filter(row => {
        const time = new Date(row.values[columnIds])
        const isFilterValuesEmpty = Array.isArray(filterValues)
            ? isEmpty(omitBy(filterValues, isNil))
            : false
        if (filterValues.length === 0 || isFilterValuesEmpty) return rows
        return time >= startDate && time <= endDate
    })
}

export type DateRangeBetweenListFilterProps = {
    column: any
    preFilteredRows: any
}

export function DateRangeBetweenFilter({
    column,
    preFilteredRows,
}: DateRangeBetweenListFilterProps): JSX.Element {
    const { filterValue = [], setFilter, id } = column

    const [max, min] = useMemo(() => {
        let min: any
        let max: any
        if (preFilteredRows.length !== 0) {
            min = new Date(preFilteredRows[0].values[id])
            max = new Date(preFilteredRows[0].values[id])

            preFilteredRows.forEach((row: any) => {
                min = new Date(row.values[id]) <= min ? new Date(row.values[id]) : min
                max = new Date(row.values[id]) >= max ? new Date(row.values[id]) : max
            })
        }
        return [max, min]
    }, [id, preFilteredRows])

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [minDate, setMinDate] = useState()
    const [maxDate, setMaxDate] = useState()

    const filterContainerRef = useOnClickOutsideRef<HTMLDivElement>(
        useEventCallback(() => {
            setDropdownOpen(false)
        }),
    )

    const FilterIcon = filterValue.length !== 0 ? FilterActiveIcon : FilterInactiveIcon

    return (
        <FilterContainer ref={filterContainerRef}>
            <FilterIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    setDropdownOpen(!dropdownOpen)
                }}
            />
            {dropdownOpen ? (
                <ListContainer>
                    <div style={{ display: 'flex' }}>
                        <DatePicker
                            selected={minDate}
                            minDate={min}
                            maxDate={max}
                            placeholderText="From"
                            dateFormat="MMMM d, yyyy"
                            onChange={(date: any) => {
                                setMinDate(date)
                                setFilter((old = []) => [date || undefined, old[1]])
                                if (maxDate === undefined) {
                                    setFilter((old = []) => [old[0], max || undefined])
                                }
                            }}
                        />
                        <DatePicker
                            selected={maxDate}
                            minDate={filterValue[0] || min}
                            maxDate={max}
                            placeholderText="To"
                            dateFormat="MMMM d, yyyy"
                            onChange={(date: any) => {
                                setMaxDate(date)
                                setFilter((old = []) => [old[0], date || undefined])
                            }}
                        />
                    </div>
                    <ResetButton
                        onClick={() => {
                            const currentItems: string[] = filterValue
                            setFilter(without(currentItems, ...filterValue))
                            setMinDate(undefined)
                            setMaxDate(undefined)
                        }}
                    >
                        Clear
                    </ResetButton>
                </ListContainer>
            ) : null}
        </FilterContainer>
    )
}

export default DateRangeBetweenFilter
