export const TOP_CONCEPT_LIST = [
    {
        value: 'http://data.disney.com/resources#FictionalLocations',
        typeURI: 'http://data.disney.com/creativeWork#FictionalLocation',
        label: 'Fictional Locations',
    },

    {
        value: 'http://data.disney.com/resources/GenericLocations',
        typeURI: 'http://data.disney.com/creativeWork#GenericLocations',
        label: 'Generic Locations',
    },

    {
        value: 'http://data.disney.com/resources#GeographicNames',
        typeURI: 'http://data.disney.com/creativeWork#GeographicLocation',
        label: 'Geographic Names',
    },

    {
        value: 'http://data.disney.com/resources#MarvelGroups',
        typeURI: 'http://data.disney.com/creativeWork#MarvelGroup',
        label: 'Marvel Groups',
    },

    {
        value: 'http://data.disney.com/resources#NamedBusinessLocations',
        typeURI: 'http://data.disney.com/creativeWork#NamedPlace',
        label: 'Named Business Locations',
    },

    {
        value: 'http://data.disney.com/resources#NamedProps',
        typeURI: 'http://data.disney.com/creativeWork#Prop',
        label: 'Named Objects',
    },

    {
        value: 'http://data.disney.com/resources#NamedVehicles',
        typeURI: 'http://data.disney.com/creativeWork#NamedVehicle',
        label: 'Named Vehicles',
    },

    {
        value: 'http://data.disney.com/resources#PublicLocations',
        typeURI: 'http://data.disney.com/creativeWork#PublicLocation',
        label: 'Public Locations',
    },

    {
        value: 'http://data.disney.com/resources#Universes',
        typeURI: 'http://data.disney.com/creativeWork#Universe',
        label: 'Universes',
        isSingle: true,
    },
]
