import { useCallback, useEffect, useState } from 'react'

import { useUser } from 'auth/Auth'

import { CastMember } from 'codecs/CastMember'
import { CharacterComment } from 'model/characters/CharacterComment'

import {
    fetchAllCommentsCounts,
    fetchCommentsForCharacter,
    createComment,
    updateComment,
    deleteComment,
} from 'api/characterComments'

export const sortByDateDesc = (comments: CharacterComment[]): CharacterComment[] => {
    return comments.sort((a, b) => {
        const dateA = new Date(a.updatedAt) as any
        const dateB = new Date(b.updatedAt) as any
        return ((dateB as number) - dateA) as number
    })
}

export type TAllCommentsCountMap = { [key: string]: number }
export type TCharacterCommentsApi = {
    isCountsLoading: boolean
    isCountsLoadingError: boolean
    allCommentsCounts: TAllCommentsCountMap
    fetchComments(castMember: CastMember): void
    isLoadingComments: boolean
    isLoadingCommentsError: boolean
    comments: CharacterComment[]
    createCharacterComment(content: string, castMember: CastMember): Promise<CharacterComment>
    isCommentSaving: boolean
    isCommentSavingError: boolean
    updateCharacterComment(
        content: string,
        portrayalId: string,
        commentId: string,
    ): Promise<CharacterComment>
    isCommentUpdating: boolean
    isCommentUpdateError: boolean
    deleteCharacterComment(portrayalId: string, commentId: string): Promise<unknown>
    isCommentDeleting: boolean
    isCommentDeletionError: boolean
    loadCommentsCounts(): void
}

export const useCharacterComments = (
    characters: CastMember[],
    needToDisplayAttributes: boolean,
): TCharacterCommentsApi => {
    const user = useUser()

    const [isCountsLoading, setIsCountsLoading] = useState<boolean>(false)
    const [isCountsLoadingError, setIsCountsLoadingError] = useState<boolean>(false)
    const [allCommentsCounts, setAllCommentsCount] = useState<{ [key: string]: number }>({})

    const [isLoadingComments, setIsLoadingComments] = useState<boolean>(false)
    const [isLoadingCommentsError, setIsLoadingCommentsError] = useState<boolean>(false)
    const [commentsForCharacter, setCommentsForCharacter] = useState<CharacterComment[]>([])

    const [isCommentSaving, setIsCommentSaving] = useState<boolean>(false)
    const [isCommentSavingError, setIsCommentSavingError] = useState<boolean>(false)

    const [isCommentUpdating, setIsCommentUpdating] = useState<boolean>(false)
    const [isCommentUpdateError, setIsCommentUpdateError] = useState<boolean>(false)

    const [isCommentDeleting, setIsCommentDeleting] = useState<boolean>(false)
    const [isCommentDeletionError, setIsCommentDeletionError] = useState<boolean>(false)

    const resetCommentError = useCallback(() => {
        setIsLoadingCommentsError(false)
        setIsCommentSavingError(false)
        setIsCommentUpdateError(false)
        setIsCommentDeletionError(false)
    }, [
        setIsLoadingCommentsError,
        setIsCommentSavingError,
        setIsCommentUpdateError,
        setIsCommentDeletionError,
    ])

    const loadCommentsCounts = useCallback(() => {
        setIsCountsLoading(true)
        setIsCountsLoadingError(false)
        const ids = characters.map(ch => ch.portrayal.portrayalId)

        return fetchAllCommentsCounts(ids, user)
            .then(result => {
                setAllCommentsCount(result.metadata.notesCount)
            })
            .catch(() => {
                setIsCountsLoadingError(true)
            })
            .finally(() => {
                setIsCountsLoading(false)
            })
    }, [characters, user, setIsCountsLoading, setIsCountsLoadingError, setAllCommentsCount])

    useEffect(() => {
        if (!characters.length || !user || !needToDisplayAttributes) {
            return
        }
        loadCommentsCounts()
    }, [characters, user, needToDisplayAttributes, loadCommentsCounts])

    const fetchComments = useCallback(
        (castMember: CastMember) => {
            setCommentsForCharacter([])
            setIsLoadingComments(true)
            setIsLoadingCommentsError(false)
            fetchCommentsForCharacter(castMember.portrayal.portrayalId, user)
                .then(result => {
                    sortByDateDesc(result.notes)
                    setCommentsForCharacter(result.notes)
                })
                .catch(() => {
                    resetCommentError()
                    setIsLoadingCommentsError(true)
                })
                .finally(() => {
                    setIsLoadingComments(false)
                })
        },
        [
            user,
            setIsLoadingComments,
            setIsLoadingCommentsError,
            setCommentsForCharacter,
            resetCommentError,
        ],
    )

    const createCharacterComment = useCallback(
        (content: string, castMember: CastMember) => {
            setIsCommentSaving(true)
            setIsCommentSavingError(false)
            return createComment(content, castMember.portrayal.portrayalId, user)
                .then(result => {
                    setCommentsForCharacter(sortByDateDesc([...commentsForCharacter, result]))
                    setAllCommentsCount({
                        ...allCommentsCounts,
                        [result.characterId]: (allCommentsCounts[result.characterId] || 0) + 1,
                    })
                    return result
                })
                .catch(e => {
                    resetCommentError()
                    setIsCommentSavingError(true)
                    return e
                })
                .finally(() => {
                    setIsCommentSaving(false)
                })
        },
        [
            user,
            commentsForCharacter,
            allCommentsCounts,
            setIsCommentSaving,
            setIsCommentSavingError,
            setCommentsForCharacter,
            setAllCommentsCount,
            resetCommentError,
        ],
    )

    const updateCharacterComment = useCallback(
        (content: string, portrayalId: string, commentId: string) => {
            setIsCommentUpdating(true)
            setIsCommentUpdateError(false)
            return updateComment(content, portrayalId, commentId, user)
                .then(result => {
                    setCommentsForCharacter(
                        sortByDateDesc(
                            commentsForCharacter.map(ch => {
                                if (result.noteId === ch.noteId) {
                                    return result
                                }
                                return ch
                            }),
                        ),
                    )
                    return result
                })
                .catch(e => {
                    resetCommentError()
                    setIsCommentUpdateError(true)
                    return e
                })
                .finally(() => {
                    setIsCommentUpdating(false)
                })
        },
        [
            user,
            commentsForCharacter,
            setIsCommentUpdating,
            setIsCommentUpdateError,
            setCommentsForCharacter,
            resetCommentError,
        ],
    )

    const deleteCharacterComment = useCallback(
        (portrayalId: string, commentId: string) => {
            setIsCommentDeleting(true)
            setIsCommentDeletionError(false)
            return deleteComment(portrayalId, commentId, user)
                .then(result => {
                    setCommentsForCharacter(
                        sortByDateDesc(commentsForCharacter.filter(ch => commentId !== ch.noteId)),
                    )
                    setAllCommentsCount({
                        ...allCommentsCounts,
                        [portrayalId]: (allCommentsCounts[portrayalId] || 0) - 1,
                    })
                    return result
                })
                .catch(e => {
                    resetCommentError()
                    setIsCommentDeletionError(true)
                    return e
                })
                .finally(() => {
                    setIsCommentDeleting(false)
                })
        },
        [
            user,
            commentsForCharacter,
            allCommentsCounts,
            setIsCommentDeleting,
            setIsCommentDeletionError,
            setCommentsForCharacter,
            setAllCommentsCount,
            resetCommentError,
        ],
    )

    return {
        isCountsLoading,
        isCountsLoadingError,
        allCommentsCounts,
        fetchComments,
        isLoadingComments,
        isLoadingCommentsError,
        comments: commentsForCharacter,
        createCharacterComment,
        isCommentSaving,
        isCommentSavingError,
        updateCharacterComment,
        isCommentUpdating,
        isCommentUpdateError,
        deleteCharacterComment,
        isCommentDeleting,
        isCommentDeletionError,
        loadCommentsCounts,
    }
}
