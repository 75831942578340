import React from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import styled, { css } from 'shared/theme'
import { useField, useFormikContext } from 'formik'
import { useEventCallback } from 'shared/hooks/useEventCallback'

export const inputStyle = css<{ error?: boolean; disabled?: boolean }>`
    padding: 0.75rem 1rem;
    font-size: 0.9375rem;
    line-height: 1.375rem;
    border: 1px solid
        ${props => (props.error ? props.theme.colors.danger : props.theme.colors.border)};
    border-radius: 2px;
    background-color: ${props => props.theme.colors.backgroundSecondary};
    color: ${props =>
        props.disabled ? props.theme.colors.textSecondary : props.theme.colors.textPrimary};
    outline: none;
    resize: none;
    box-sizing: border-box;

    :focus {
        border-color: ${props =>
            props.error ? props.theme.colors.danger : props.theme.colors.borderActive};
    }
`

const StyledInput = styled.input<{ error: boolean }>`
    ${inputStyle}
`

// Don't pass the `error` prop to the DOM textarea
export const StyledMultilineInput = styled(({ error, ...rest }) => <TextareaAutosize {...rest} />)<{
    error: boolean
}>`
    ${inputStyle}
    box-sizing: border-box;
    line-height: 22px;
    width: 100%;
    padding: 0.75rem 1rem;
`

type InputProps = JSX.IntrinsicElements['input'] & {
    multiline?: boolean
    error?: boolean
}
export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ multiline = false, error = false, ...props }, ref): React.ReactElement => {
        const Component = multiline ? StyledMultilineInput : StyledInput
        return <Component ref={ref} error={error} {...props} />
    },
)

type FormikInputProps = InputProps & {
    name: string
    fastValidate?: boolean
    rows?: number
}
export const FormikInput = React.forwardRef<HTMLInputElement, FormikInputProps>(
    ({ fastValidate = false, multiline = false, ...props }, ref): React.ReactElement => {
        let [{ value, onChange: formikOnChange, onBlur: formikOnBlur }, { touched, error }] =
            useField<string>(props.name)
        const { setFieldValue } = useFormikContext<any>()
        const Component = multiline ? StyledMultilineInput : StyledInput

        const onChange = useEventCallback((e: React.ChangeEvent<HTMLInputElement>) => {
            if (!fastValidate) {
                formikOnChange(e)
                return
            }

            const oldValue = value
            const newValue = e.target.value
            // trigger validation when field switches
            // from empty to non-empty or vice-versa
            const shouldValidate =
                (newValue === '' && oldValue !== '') || (newValue !== '' && oldValue === '')
            setFieldValue(props.name, newValue, shouldValidate)
        })

        return (
            <Component
                ref={ref}
                error={!!touched && !!error}
                onChange={onChange}
                onBlur={formikOnBlur}
                value={value}
                {...props}
            />
        )
    },
)
