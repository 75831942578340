import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { CastMember } from 'codecs/CastMember'
import { useUser } from 'auth/Auth'
import { notification } from 'shared/notification'
import { useResourceMachine } from 'shared/resource/ResourceMachineProvider'
import { searchPortrayalByID, updatePortrayal } from 'api/portrayals'
import { Feature } from 'model/Feature'
import { Series } from 'model/Series'
import { Episode } from 'model/Episode'
import { Season } from 'model/Season'
import {
    buildCastMemberFromFormState,
    buildFormStateFromPortrayalFound,
    formatPortrayalsFoundOptions,
    notifyNoAssociationError,
    notifyPortrayalsDuplicates,
    preparePortrayalToUpdateFromFormStatePayload,
    updateResouceData,
} from './utils'
import { PortrayalFormInProgressState } from './types'

export type TPortrayalsContextApi = {
    inlineEditingInProgress: boolean
    isInlineEditingAllowed: boolean
    isLoadingMeta: boolean
    isLoadingMetaError: boolean
    isSaving: boolean
    isSavingError: boolean
    portrayalInEdit: PortrayalFormInProgressState | null
    resource: Feature | Series | Episode | Season
    resourceId: string
    setCurrentPortrayal(portrayal: PortrayalFormInProgressState | null): void
    startEditing(portrayalId: string): void
    updatePortrayal(portrayalId: string, oldCm: CastMember): void
    unlinkPortrayal(portrayalId: string, oldCM: CastMember): void
}

export const usePortrayals = (
    _characters: CastMember[],
    _needToDisplayAttributes: boolean,
): TPortrayalsContextApi => {
    const user = useUser()
    const [state, send] = useResourceMachine<Feature | Series | Episode | Season>()

    const {
        context: { resourceId, resource },
    } = state

    const [isLoadingMeta, setIsLoadingMeta] = useState(false)
    const [isLoadingMetaError, setIsLoadingMetaError] = useState(false)

    const [portrayalInEdit, setPortrayalInEdit] = useState<PortrayalFormInProgressState | null>(
        null,
    )
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [isSavingError, setIsSavingError] = useState<boolean>(false)

    const inlineEditingInProgress = useMemo(() => {
        return !!portrayalInEdit
    }, [portrayalInEdit])

    const isInlineEditingAllowed = useMemo(() => {
        return state.matches({ idle: 'readonly' })
    }, [state])

    const setCurrentPortrayal = useCallback(
        (portrayal: PortrayalFormInProgressState | null) => {
            setPortrayalInEdit(portrayal)
        },
        [setPortrayalInEdit],
    )

    const startEditing = useCallback(
        (portrayalId: string) => {
            // TODO: Handle display loading while metadata is loading
            setIsLoadingMeta(true)
            setIsLoadingMetaError(false)
            searchPortrayalByID(portrayalId, user)
                .then(portrayals => {
                    notifyPortrayalsDuplicates(portrayals)
                    if (!!portrayals.length) {
                        const portrayalFoundById = formatPortrayalsFoundOptions(portrayals)[0]
                        const formState = buildFormStateFromPortrayalFound(portrayalFoundById)
                        setPortrayalInEdit(formState)
                    } else {
                        setIsLoadingMetaError(true)
                    }
                    return portrayals
                })
                .catch(err => {
                    setIsLoadingMetaError(true)
                    return err
                })
                .finally(() => {
                    setIsLoadingMeta(false)
                })
        },
        [user, setIsLoadingMeta, setIsLoadingMetaError, setPortrayalInEdit],
    )

    const modifyPortrayal = useCallback(
        (portrayalId: string, isDeletion: boolean, oldCM: CastMember) => {
            if (!isDeletion && !portrayalInEdit) {
                return
            }

            setIsSaving(true)
            setIsSavingError(false)
            searchPortrayalByID(portrayalId as string, user)
                .then(portrayals => {
                    notifyPortrayalsDuplicates(portrayals)
                    const portrayalFoundById = formatPortrayalsFoundOptions(portrayals)[0]

                    // We either need to copy existent data befor unlinking or we pick active portrayl in edit mode
                    const formState: PortrayalFormInProgressState = isDeletion
                        ? buildFormStateFromPortrayalFound(portrayalFoundById)
                        : (portrayalInEdit as PortrayalFormInProgressState)

                    let associations = !!portrayals.length
                        ? portrayals[0].isAssociatedWithTitle
                        : []

                    const hasNoAssociationError = notifyNoAssociationError(
                        associations,
                        resourceId,
                        false,
                    )

                    if (hasNoAssociationError) {
                        return
                    }

                    if (isDeletion) {
                        associations = associations.filter(a => a.titleUri !== resourceId)
                    }

                    const metadata = preparePortrayalToUpdateFromFormStatePayload(
                        formState,
                        resourceId,
                        associations,
                        false,
                    )

                    return updatePortrayal(metadata, user).then(resp => {
                        const cm = buildCastMemberFromFormState(formState, oldCM.attributes)
                        const resourceData = updateResouceData(resource, cm, false, isDeletion)
                        send({ type: 'REFRESH_RESOURCE', data: resourceData })
                        return resp
                    })
                })
                .then(() => {
                    setPortrayalInEdit(null)
                })
                .catch(err => {
                    notification.error(
                        'Error has occurred while saving the portrayal. Please, try again.',
                    )
                    setIsSavingError(true)
                    return err
                })
                .finally(() => {
                    setIsSaving(false)
                })
        },
        [
            user,
            resource,
            resourceId,
            portrayalInEdit,
            setIsSaving,
            setIsSavingError,
            setPortrayalInEdit,
            send,
        ],
    )

    const updateExistentPortrayal = useCallback(
        (portrayalId: string, oldCM: CastMember) => {
            modifyPortrayal(portrayalId, false, oldCM)
        },
        [modifyPortrayal],
    )

    const unlinkPortrayal = useCallback(
        (portrayalId: string, oldCM: CastMember) => {
            modifyPortrayal(portrayalId, true, oldCM)
        },
        [modifyPortrayal],
    )

    return {
        inlineEditingInProgress,
        isInlineEditingAllowed,
        isLoadingMeta,
        isLoadingMetaError,
        isSaving,
        isSavingError,
        portrayalInEdit,
        resource,
        resourceId,
        startEditing,
        updatePortrayal: updateExistentPortrayal,
        setCurrentPortrayal,
        unlinkPortrayal,
    }
}

const PortrayalsContext = createContext<TPortrayalsContextApi | null>(null)

export const PortrayalsProvider: React.FC<{
    characters: CastMember[]
    canEditCharacters: boolean
}> = ({ children, characters, canEditCharacters }) => {
    const portrayalsApi = usePortrayals(characters, canEditCharacters)

    return <PortrayalsContext.Provider value={portrayalsApi}>{children}</PortrayalsContext.Provider>
}

export const usePortrayalsContext = (): TPortrayalsContextApi => {
    const ctx = useContext(PortrayalsContext) as TPortrayalsContextApi
    return ctx
}
