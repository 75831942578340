import { space, SpaceProps } from 'styled-system'

import styled from 'shared/theme'

type OwnProps = {
    name: string
    required?: boolean
    disabled?: boolean
}
export type Props = OwnProps & SpaceProps

export const Wrapper = styled.div`
    ${space}
`

export const Content = styled.div<{ error: boolean }>`
    display: flex;
    align-items: center;
    justify-content: top;
    height: 3rem;
`

export const Label = styled.label<{ error?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid
        ${({ error, theme }) => (error ? theme.colors.danger : theme.colors.textSecondary)};
    background-color: transparent;
    margin-right: 0.5rem;
`

export const TextWrapper = styled.div`
    user-select: none;
    cursor: pointer;
`

const Input = styled.input`
    --webkit-appearance: none;
    --moz-appearance: none;
    appearance: none;
    cursor: pointer;
    position: absolute;
    outline: none;
    width: 1.5rem;
    height: 1.5rem;
    &:focus + label {
        border-color: ${({ theme }) => theme.colors.primary};
    }
    &:checked + ${Label} {
        border-color: ${({ theme }) => theme.colors.primary};
    }
    &:disabled {
        cursor: not-allowed;
        & + ${Label} {
            border-color: ${({ theme }) => theme.colors.border};
            background-color: ${({ theme }) => theme.colors.border};
            img {
                display: none;
            }
            & + ${TextWrapper} {
                cursor: not-allowed;
            }
        }
    }
`

export const Checkbox = styled(Input)`
    border-radius: 4px;
    & + ${Label} {
        border-radius: 2px;
        svg {
            display: 'none';
        }
    }
    &:checked + ${Label} {
        background-color: ${({ theme }) => theme.colors.primary};
        svg {
            stroke: ${({ theme }) => theme.colors.white};
        }
    }
    &:disabled + ${Label} {
        background-color: transparent;
    }
    &:disabled:checked + ${Label} {
        svg {
            stroke: ${({ theme }) => theme.colors.border};
        }
    }
    &:disabled:not(:checked) + ${Label} {
        svg {
            display: 'none';
        }
    }
`
export const Radio = styled(Input)`
    & + ${Label} {
        border-radius: 50%;
    }
    &:checked + ${Label} {
        border: 5px solid ${({ theme }) => theme.colors.primary};
    }
`
