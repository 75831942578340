import { UseQueryResult, useQuery } from 'react-query'

import { request } from '@genome-web-forms/common/api'
import { MyIDUser } from '@genome-web-forms/common/auth'

import { useUser } from 'auth/Auth'
import { authGWF } from 'api/auth'
import config from 'shared/config'
import {
    CharacterRelationshipData,
    CharacterRelationshipDataCodec,
} from 'model/characters/Portrayal'

import { extractResIdHash } from './utils'

export type PortrayalType = {
    label: string
    uri: string
}

const fetchCharacterRelationships = (
    characterId: string,
    user: MyIDUser,
): Promise<CharacterRelationshipData> => {
    //const url = `${config.urlGWFOntology}/characters/relationships?limit=50&offset=0`
    // const url = `${config.urlGWFOntology}/characters/relationships/${extractResIdHash(characterId)}`
    const url = `${config.urlGWFOntology}/characters/${extractResIdHash(characterId)}/relationships`
    return request(
        CharacterRelationshipDataCodec,
        authGWF(user, {
            url: url,
            method: 'GET',
        }),
    )
}

export const useCharacterRelationships = (
    characterId: string,
): UseQueryResult<CharacterRelationshipData> => {
    const user = useUser()
    return useQuery<CharacterRelationshipData>(['portrayal-type', characterId], () => {
        return fetchCharacterRelationships(characterId, user)
    })
}
