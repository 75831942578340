import React from 'react'
import PageLayout from 'pages/util/PageLayout'
import { useNonAuthSnowplowPageView } from 'shared/hooks/useSnowPlowPageView'

const NotFound: React.FC = (): React.ReactElement => {
    useNonAuthSnowplowPageView('Not Found', 'Not Found Page')

    return (
        <PageLayout>
            <h1>Not found</h1>
        </PageLayout>
    )
}

export default NotFound
