import {
    Workflow,
    WorkflowHelper,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
} from '@genome-web-forms/server'
import { useFullNameMyIDUser } from 'api/workflow/partialMyIDUsers'
import { SearchResult } from 'model/search/SearchResult'
import React from 'react'
import { Link } from 'react-router-dom'
import { TableRowComponentProps } from 'react-table'
import { workflowLink } from 'routing/routes'
import Button from 'shared/components/Button'
import MediumDate from 'shared/components/MediumDate'
import NoWrap from 'shared/components/NoWrap'
import { RowComponentDefault, TableCell, TableRow } from 'shared/components/Table'
import Text from 'shared/components/Text'
import ToggleButton from 'shared/components/ToggleButton'
import { ButtonContainer } from 'shared/storylines/StoryRolesTableRow/ButtonContainer'

export const SearchRow = (props: TableRowComponentProps<SearchResult>): React.ReactElement => {
    const {
        allColumns: { length: colSpan },
        row,
        row: {
            original: { workflows },
        },
    } = props

    const defaultRow = <RowComponentDefault {...props} />

    if (!workflows || !workflows.length) {
        return defaultRow
    }

    const workflowMetadata = workflows.find(
        workflow => workflow.workflowConfig.task === WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
    )
    const workflowStoryRel = workflows.find(
        workflow =>
            workflow.workflowConfig.task ===
            WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
    )

    return (
        <>
            <RowComponentDefault {...props} />
            <TableRow>
                <TableCell
                    removeBottomBorderRadius
                    removeTopBorderRadius
                    alignWithPreviousRow
                    colSpan={colSpan}
                    removePadding
                >
                    <ButtonContainer>
                        <ToggleButton
                            onClick={() => row.toggleRowExpanded()}
                            toggled={row.isExpanded}
                        >
                            {row.isExpanded ? 'Hide Workflows' : 'Show Workflows'}
                        </ToggleButton>
                    </ButtonContainer>
                </TableCell>
            </TableRow>

            {row.isExpanded && (
                <>
                    <TableRow>
                        <TableCell removeTopBorderRadius secondAlignWithPreviousRow />

                        <TableCell removeTopBorderRadius secondAlignWithPreviousRow>
                            {workflowMetadata && (
                                <WorkflowDescription>{workflowMetadata}</WorkflowDescription>
                            )}
                        </TableCell>
                        <TableCell colSpan={3} removeTopBorderRadius secondAlignWithPreviousRow>
                            {workflowStoryRel && (
                                <WorkflowDescription>{workflowStoryRel}</WorkflowDescription>
                            )}
                        </TableCell>
                    </TableRow>
                </>
            )}
        </>
    )
}

export default React.memo(SearchRow)

type WorkflowDescriptionProps = { children: Workflow }
function WorkflowDescription({ children: workflow }: WorkflowDescriptionProps): React.ReactElement {
    const asigneeFullName = useFullNameMyIDUser(workflow?.assignee)

    return (
        <NoWrap as="div">
            <dl>
                <dt>Title:</dt>
                <dd>{WorkflowHelper.title(workflow)}</dd>

                <dt>Created:</dt>
                <dd>
                    <WorkflowDate date={workflow.createdAt} />
                </dd>

                <dt>State:</dt>
                <dd>
                    <NoWrap>{workflow.state}</NoWrap>
                </dd>

                <dt>Assignee:</dt>
                <dd>
                    {asigneeFullName ?? workflow.assignee ?? (
                        <Text size="5" variant="secondary">
                            <em>&lt;no assignee&gt;</em>
                        </Text>
                    )}
                </dd>

                {workflow.priority !== 'MEDIUM' && (
                    <>
                        <dt>Priority:</dt>
                        <dd>{workflow.priority}</dd>
                    </>
                )}

                {workflow.deadlineAt && (
                    <>
                        <dt>Due Date:</dt>
                        <dd>
                            <WorkflowDate date={workflow.deadlineAt} />
                        </dd>
                    </>
                )}

                <Link to={workflowLink(workflow)}>
                    <Button size="small" style={{ padding: '0.375rem 1rem' }}>
                        Workflow Details
                    </Button>
                </Link>
            </dl>
        </NoWrap>
    )
}

function WorkflowDate({ date }: { date: Date }): React.ReactElement {
    return <MediumDate style={{ whiteSpace: 'nowrap' }} date={date} />
}
