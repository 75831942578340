import React from 'react'
import * as t from 'io-ts'
import { orderBy } from 'natural-orderby'

import config from 'shared/config'
import { request } from '@genome-web-forms/common/api'
import { useUser } from 'auth/Auth'
import {
    TaxonomyWithCategoriesCodec,
    SimpleTaxonomy,
    TaxonomyTerm,
    NATaxonomyTerm,
} from 'codecs/TRS/Taxonomy'
import { MyIDUser } from '@genome-web-forms/common/auth'
import {
    DisplayGenre,
    DisplayGenreCodec,
    OtherGenre,
    OtherGenreCodec,
    TaggedTaxonomy,
    TaggedTaxonomyCategory,
} from 'model/metadata/Genre'
import { authCWR } from 'api/auth'

import { useQuery, UseQueryOptions } from 'react-query'
import { queryClient } from 'shared/queryClient'
import { flatten, keyBy } from 'lodash'
import { sortDeep } from 'shared/util/sortDeep'
import { deepFlattenTaxonomies } from 'shared/util/flattenHierarchy'

export const TRS_STORY_ARCHETYPES = 'http://data.disney.com/resources/StoryElements'

// Used for descriptors, other genres, display genres and locations
export const UNIQUE_NA_ID = 'http://data.disney.com/resources/ff75a813-9879-4081-a4cd-5e56b0b94d15'

export const NA_TAXONOMY_TERM: NATaxonomyTerm = {
    // TODO: fill this value
    id: UNIQUE_NA_ID,
    label: 'N/A',
    notForTagging: false,
    preferredTerms: [],
    terms: [],
}

/**
 * @param key Key from TRSDefinitions
 * @param transformer A function to transform TRS data into a `transformed` value.
 *  MUST BE WRAPPED IN React.useCallback()!
 * @param transformedDefaultValue Default value for `transformed`
 * @return Object with `TRSData`, `TRSLoaded` and optionally a `transformed`
 *  property based on the transformer function output
 */

export function useTRSLoaded(): boolean {
    return true
}

/**
 * Convert the deep taxonomy structure of
 *  { type, label, categories: [ { terms: [...] } ]}
 * into
 *  { type, label, terms: [...] }
 * and expose it as SimpleTaxonomy[]
 *
 * The way categories transformed to terms like this:
 *  - In the case a taxonomy has only one category, it's terms are used
 *  - In the case a taxonomy has multiple categories, each category is converted into a
 *      term, with subterms that are the category's terms
 */
const taxonomiesQueryConfig = (user: MyIDUser): UseQueryOptions<SimpleTaxonomy[]> => ({
    queryKey: ['trs', 'taxonomies'],
    queryFn: async () => {
        const taxonomies = await request(
            t.array(TaxonomyWithCategoriesCodec),
            authCWR(user, {
                url: `${config.urlTRS}/taxonomy/semaphore/get-taxonomy`,
                dataProp: 'taxonomies',
            }),
        )

        return taxonomies.map<SimpleTaxonomy>(({ type, label, categories }) => {
            const terms = categories.map<TaxonomyTerm>(category => ({
                id: category.category,
                label: category.label,
                terms: orderBy(category.terms, term => term?.label),
                notForTagging: category.notForTagging,
            }))

            const sortedTerms = sortDeep(terms, term => term?.label, 'terms') as TaxonomyTerm[]

            return {
                type,
                label,
                terms: sortedTerms,
            }
        })
    },
    staleTime: Infinity,
    cacheTime: Infinity,
})
export function useTRSSimple(): {
    TRSLoaded: boolean
    taxonomies: SimpleTaxonomy[]
} {
    const { isFetched: TRSLoaded, data: taxonomies = [] } = useQuery(
        taxonomiesQueryConfig(useUser()),
    )
    return { taxonomies, TRSLoaded }
}

/**
 * Find terms among one fo the simple taxonomies (ones where there is only 1 category
 *
 * @param type A taxonomy type RfID
 */
export function useTRSSimpleFind(
    type: string,
    includeNA = false,
): { terms: TaxonomyTerm[]; TRSLoaded: boolean } {
    const { taxonomies, TRSLoaded } = useTRSSimple()

    // https://github.com/facebook/react/issues/14981#issuecomment-468460187
    const [, setErrorState] = React.useState()

    return React.useMemo(() => {
        const taxonomy = taxonomies.find(taxonomy => taxonomy.type === type)
        const taxonomyNA = includeNA ? [NA_TAXONOMY_TERM] : []
        const terms: TaxonomyTerm[] = taxonomy ? [...taxonomy.terms, ...taxonomyNA] : taxonomyNA

        if (TRSLoaded && !taxonomy) {
            // https://github.com/facebook/react/issues/14981#issuecomment-468460187
            setErrorState(() => {
                throw new Error(`Could not find taxonomy that matches type "${type}"`)
            })
        }

        return {
            terms,
            TRSLoaded,
        }
    }, [type, taxonomies, TRSLoaded, includeNA])
}

/**
 * Prime all TRS caches, one at a time
 */
export async function primeTRSCache(user: MyIDUser): Promise<any> {
    return Promise.all([
        queryClient.prefetchQuery(taxonomiesQueryConfig(user)),
        queryClient.prefetchQuery(displayGenresQueryConfig(user)),
        queryClient.prefetchQuery(taggedTaxonomyGenresQueryConfig(user)),
        queryClient.prefetchQuery(otherGenresQueryConfig(user)),
    ])
}

const displayGenresQueryConfig = (user: MyIDUser): UseQueryOptions<DisplayGenre[]> => ({
    queryKey: ['trs', 'display-genres'],
    queryFn: async () => {
        let displayGenres = await request(
            t.array(DisplayGenreCodec),
            authCWR(user, {
                url: `${config.urlTRS}/queries/taxonomy/display-genres`,
            }),
        )

        displayGenres = displayGenres.filter(dg => !dg.notForTagging)

        displayGenres = orderBy(displayGenres, dg => dg.displayGenreLabel)

        return displayGenres
    },
    staleTime: Infinity,
    cacheTime: Infinity,
})

export function useDisplayGenres(): { loaded: boolean; data: DisplayGenre[] } {
    const { isFetched: loaded, data = [] } = useQuery(displayGenresQueryConfig(useUser()))
    return { loaded, data }
}

const taggedTaxonomyGenresQueryConfig = (
    user: MyIDUser,
): UseQueryOptions<{ taxonomies: TaggedTaxonomy[] }> => ({
    queryKey: ['trs', 'tagged-taxonomy'],
    queryFn: async () => {
        let taggedTaxonomy = await request(
            t.any,
            authCWR(user, {
                url: `${config.urlTRS}/taxonomy/semaphore/tagged-taxonomy`,
            }),
        )

        return taggedTaxonomy
    },
    staleTime: Infinity,
    cacheTime: Infinity,
})

export function useFlattenedTaxonomyTermsById(): {
    data: { [id: string]: TaxonomyTerm }
    isFetched: boolean
} {
    const { data, isFetched } = useQuery(taxonomiesQueryConfig(useUser()))
    // filters out categories (categories don't have id)
    const taxonomies = deepFlattenTaxonomies(data ?? []).filter(taxonomy => !!taxonomy?.id)
    const taxonomiesById = keyBy(taxonomies, 'id')
    return { data: taxonomiesById, isFetched }
}

export function useTaggedTaxonomyLabels(): { loaded: boolean; data: TaggedTaxonomyCategory[] } {
    const { isFetched: loaded, data } = useQuery(taggedTaxonomyGenresQueryConfig(useUser()))
    const flattenedTaxonomies = flatten(data?.taxonomies.map(t => t.categories, []))
    return { loaded, data: flattenedTaxonomies }
}

const otherGenresQueryConfig = (user: MyIDUser): UseQueryOptions<OtherGenre[]> => ({
    queryKey: ['trs', 'other-genres'],
    queryFn: async () => {
        let otherGenres = await request(
            t.array(OtherGenreCodec),
            authCWR(user, {
                url: `${config.urlTRS}/queries/taxonomy/other-genres`,
            }),
        )

        otherGenres = otherGenres.filter(og => !og.notForTagging)

        otherGenres = orderBy(otherGenres, og => og.otherGenreLabel)

        return otherGenres
    },
    staleTime: Infinity,
    cacheTime: Infinity,
})
export function useOtherGenres(): { loaded: boolean; data: OtherGenre[] } {
    const { isFetched: loaded, data = [] } = useQuery(otherGenresQueryConfig(useUser()))
    return { loaded, data }
}
