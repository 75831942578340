import React from 'react'

import '@reach/tabs/styles.css'
import ReactDOM from 'react-dom'
import { initialized } from 'shared/config'
import 'shared/yupLocale'
import 'shared/Yup.array.uniqBy.ts'
import { UpdatePoller } from 'api/UpdatePoller'

import './index.css'
import App from './App'

import { hijackEffectHook } from 'stop-runaway-react-effects'
if (process.env.NODE_ENV === 'development') {
    hijackEffectHook('useEffect', { callCount: 60, timeLimit: 1000 })
}

interface ISnowplowApi {
    setUserId(id: string): void
    firePageView(options: { title: string }): void
    trackComponent(config: {
        componentType: string // Button
        componentName: string // 'Login'
        actionName: string // click
        label: string // login
        value?: Record<string, any>[] // metadata to send
        params?: Record<string, unknown>
    }): void
    enableMediaTracking: (opts: any) => void
}

declare global {
    interface Window {
        snowplowVendor: ISnowplowApi
    }
}

if (process.env.NODE_ENV === 'production') {
    UpdatePoller.create()
    UpdatePoller.instance().start()
}

if (initialized) {
    if (process.env.NODE_ENV === 'development') {
        import('__api_mocks__').then(({ setupMocks }) => {
            setupMocks().then(() => {
                ReactDOM.render(<App />, document.getElementById('root'))
            })
        })
    } else {
        ReactDOM.render(<App />, document.getElementById('root'))
    }
} else {
    ReactDOM.render(
        <>
            <h1>
                Cannot initialize GWF: Missing <code>config.js</code>
            </h1>
            <h3>
                Make sure the <code>config.js</code> file is present in the root of the S3 bucket.
            </h3>
        </>,
        document.getElementById('root'),
    )
}
