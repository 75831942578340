import React, { FC } from 'react'
import { Pill, PillsWrapper } from '../Table/TablePills'

export const EntityTypePill: FC<{ name: string }> = ({ name }) => {
    return (
        <PillsWrapper style={{ display: 'inline' }}>
            <Pill style={{ fontWeight: 'bold' }}>{name}</Pill>
        </PillsWrapper>
    )
}
