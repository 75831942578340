import React from 'react'
import { ReactComponent as TrashBinIcon } from 'shared/components/Icons/trash-can-icon.svg'
import styled from 'shared/theme'
import { useButton } from '@react-aria/button'

export default TrashBinIcon

type TrashBinIconButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, 'children'> & {
    isDisabled?: boolean
}
const TrashBinButtonElement = styled(TrashBinIcon)<TrashBinIconButtonProps>`
    cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
    color: ${props =>
        props.isDisabled
            ? props.theme.colors.textPrimaryDisabled
            : props.theme.colors.textSecondary};
    pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
`

export function TrashBinButton(props: TrashBinIconButtonProps): JSX.Element {
    const ref = React.useRef<HTMLButtonElement>()
    const { buttonProps } = useButton({ elementType: 'svg' }, ref as any)
    return <TrashBinButtonElement {...buttonProps} {...(props as any)} />
}
