import React from 'react'

import PageLayout from 'pages/util/PageLayout'
import LockedTitlesNode from 'pages/LockedTitles/LockedTitlesNode'
import { useAuthSnowplowPageView } from 'shared/hooks/useSnowPlowPageView'

const LockedTitles: React.FC = (): React.ReactElement => {
    useAuthSnowplowPageView('Locked Titles', 'Locked Titles Page')

    return (
        <PageLayout>
            <LockedTitlesNode />
        </PageLayout>
    )
}

export default LockedTitles
