import React from 'react'
import * as t from 'io-ts'
import { forwardTo } from 'xstate'
import { createModel } from 'xstate/lib/model'
import { Location, LocationBase, LocationCodec } from 'codecs/Location'
import { useInterpret } from '@xstate/react'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { useUser } from 'auth/Auth'
import { request } from '@genome-web-forms/common/api'
import { authGWF } from 'api/auth'
import config from 'shared/config'
import { betterToJSON } from 'shared/util/betterToJSON'
import { createReactContextHelpers } from 'xstate-helpers/react/createReactContextHelpers'
import { isEvent } from 'xstate-helpers/events'
import { useErrorHandler } from 'react-error-boundary'
import invariant from 'tiny-invariant'

export const {
    Provider: LocationsHierarchy,
    useInterpreter,
    useSend,
    useSelector,
} = createReactContextHelpers('LocationsHierarchy', () => {
    const errorHandler = useErrorHandler()
    return useInterpret(locationsHierarchyMachine, { context: { errorHandler }, devTools: true })
})

export const useAddHierarchy = (locations: Location[] = []): Location[] => {
    const send = useSend()
    const hierarchies = useSelector(React.useCallback(state => state.context.hierarchies, []))

    const user = useUser()

    React.useEffect(() => {
        send({ type: 'REQUEST_HIERARCHIES', locations, user })
    }, [send, locations, user])

    return React.useMemo<Location[]>(() => {
        return locations?.map<Location>(location => ({
            ...location,
            __ap_locations_heirarchy:
                location.__ap_locations_heirarchy ?? hierarchies.get(location.locationId) ?? null,
        }))
    }, [locations, hierarchies])
}

interface LocationsHierarchyContext {
    errorHandler: (error: Error) => void
    hierarchies: Map<string, LocationBase[]>
    inFlightIds: Set<string>
}

type LocationsHierarchyEvent =
    | {
          type: 'REQUEST_HIERARCHIES'
          locations: Location[]
          user: MyIDUser
      }
    | {
          type: 'ADD_HIERARCHIES'
          hierarchies: Map<string, LocationBase[]>
      }
    | {
          type: 'ADD_IN_FLIGHT_IDS'
          ids: string[]
      }

const locationsHierarchyModel = createModel<LocationsHierarchyContext, LocationsHierarchyEvent>({
    errorHandler: null!,
    hierarchies: new Map(),
    inFlightIds: new Set(),
})
const addHierarchies = locationsHierarchyModel.assign((ctx, e) => {
    const completedIDs = new Set(e.hierarchies.keys())
    return {
        hierarchies: new Map([...e.hierarchies, ...ctx.hierarchies]),
        inFlightIds: new Set([...ctx.inFlightIds].filter(id => !completedIDs.has(id))),
    }
}, 'ADD_HIERARCHIES')
const addInFlightIds = locationsHierarchyModel.assign(
    (ctx, e) => ({
        inFlightIds: new Set([...e.ids, ...ctx.inFlightIds]),
    }),
    'ADD_IN_FLIGHT_IDS',
)

export const locationsHierarchyMachine = locationsHierarchyModel.createMachine({
    id: 'location-hierarchies',
    context: betterToJSON(locationsHierarchyModel.initialContext),
    on: {
        REQUEST_HIERARCHIES: {
            actions: forwardTo('request-hierarchies'),
        },
        ADD_HIERARCHIES: {
            actions: addHierarchies,
        },
        ADD_IN_FLIGHT_IDS: {
            actions: addInFlightIds,
        },
    },
    invoke: {
        id: 'request-hierarchies',
        src: ctx => (send, onReceive) => {
            let stopped = false

            onReceive(handleEvent)

            return () => (stopped = true)

            ////////////////////////////////////////////////////////////

            async function handleEvent(event: LocationsHierarchyEvent): Promise<void> {
                if (!isEvent(event, 'REQUEST_HIERARCHIES')) {
                    return
                }

                const ids = event.locations
                    .map(l => l?.locationId)
                    .filter(id => !ctx.inFlightIds.has(id) && !ctx.hierarchies.has(id))
                if (ids.length === 0) {
                    return
                }

                send({ type: 'ADD_IN_FLIGHT_IDS', ids })

                try {
                    const hierarchies = await getHierarchies(event.user, ids)
                    if (!stopped) {
                        send({ type: 'ADD_HIERARCHIES', hierarchies })
                    }
                } catch (e: any) {
                    ctx.errorHandler(e)
                }
            }
        },
    },
})

const getHierarchies = async (
    user: MyIDUser,
    ids: string[],
): Promise<Map<string, LocationBase[]>> => {
    const res = await request(
        t.strict({
            results: t.array(
                t.strict({
                    genomeObject: LocationCodec,
                }),
            ),
        }),
        authGWF(user, {
            url: `${config.urlGWF}/search`,
            params: {
                queryType: 'ap-locations',
                'ap-ids': ids.join(','),
            },
        }),
    )

    const hierarchies = res.results.map<[string, Array<LocationBase>]>(({ genomeObject }) => {
        invariant(genomeObject.__ap_locations_heirarchy)
        return [genomeObject.locationId, genomeObject.__ap_locations_heirarchy]
    })

    return new Map(hierarchies)
}
