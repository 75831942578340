import { WorkflowTransition } from '@genome-web-forms/server'
import { useFullNameMyIDUser } from 'api/workflow/partialMyIDUsers'
import { useWorkflowQuery } from 'api/workflow/workflow'
import { routeTo } from 'model/search/routeTo'
import React from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { CellProps, Column } from 'react-table'
import { Workflow as WorkflowLocation } from 'routing/routes'
import Button from 'shared/components/Button'
import Container from 'shared/components/Container'
import FormattedDate from 'shared/components/FormattedDate'
import Header from 'shared/components/Header'
import Loader from 'shared/components/Loader'
import MetadataBooleanValue from 'shared/components/MasterData/BooleanValue'
import { Grid } from 'shared/components/MasterData/Grid'
import ListSectionDivider from 'shared/components/MasterData/ListSectionDivider'
import MediumDate from 'shared/components/MediumDate'
import NoWrap from 'shared/components/NoWrap'
import Panel from 'shared/components/Panel'
import Table from 'shared/components/Table'
import Text from 'shared/components/Text'
import styled from 'shared/theme'
import {
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
} from '@genome-web-forms/server'
import { useWorkflowDetailsPageView } from 'shared/hooks/useSnowPlowPageView'

const WorkflowDetailsWrapper = styled(Container)`
    padding: 1rem 1.5rem;

    svg {
        height: 18px;
        width: 18px;
        padding-top: 1px;
    }
`

export const WorkflowDetailsGrid = styled(Grid)`
    grid-template-columns: 1fr 2fr;
    align-items: start;
    margin-top: 1.5rem;
    .sectionStart,
    .sectionStart + dd {
        margin-top: 1rem;
    }
`

export const Comment = styled.div`
    max-width: 500px;
`
const workflowTasksTypeLabels: { [x: string]: string } = {
    [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA]: 'Descriptors',
    [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS]: 'Storylines/Relationships',
}

const columns: Column<WorkflowTransition>[] = [
    {
        id: 'transitionNumber',
        Header: 'Transition Number',
        accessor: 'transitionNumber',
        Cell: ({ row: { original } }: CellProps<WorkflowTransition>) => {
            return (
                <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                    {original.transitionNumber}
                </Text>
            )
        },
        width: 1,
    },
    {
        id: 'createdAt',
        Header: 'Date',
        accessor: 'createdAt',
        Cell: ({ row: { original } }: CellProps<WorkflowTransition>) => {
            return (
                <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                    {original.createdAt && <MediumDate date={original.createdAt} />}
                </Text>
            )
        },
        width: 1,
    },
    {
        id: 'createdBy',
        Header: 'User',
        accessor: 'createdBy',
        Cell: ({ row: { original } }: CellProps<WorkflowTransition>) => {
            const createdByFullName = useFullNameMyIDUser(original?.createdBy)

            return (
                <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                    {createdByFullName} ({original.createdBy})
                </Text>
            )
        },
        width: 1,
    },
    {
        id: 'from_state',
        Header: 'From State',
        accessor: 'from_state',
        Cell: ({ row: { original } }: CellProps<WorkflowTransition>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {original.from_state}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'to_state',
        Header: 'To State',
        accessor: 'to_state',
        Cell: ({ row: { original } }: CellProps<WorkflowTransition>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {original.to_state}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'comment',
        Header: 'Comment',
        Cell: ({ row: { original } }: CellProps<WorkflowTransition>) => (
            <Text size="5">
                <Comment>{original.comment || ''}</Comment>
            </Text>
        ),
        width: 400,
        minWidth: 300,
    },
]

const WorkflowDetails: React.FC = (): React.ReactElement => {
    // @ts-ignore react-router useParams() wrongly thinks a parameter is always string,
    // but fp-ts-routing can convert string params to numbers
    const { workflowId } = useParams<WorkflowLocation>()

    let { isLoading, data: workflow } = useWorkflowQuery(+workflowId)

    useWorkflowDetailsPageView(workflow)

    const asigneeFullName = useFullNameMyIDUser(workflow?.assignee)
    const createdByFullName = useFullNameMyIDUser(workflow?.createdBy)
    if (isLoading) {
        return (
            <>
                <Header />
                <Loader center size="normal" />
            </>
        )
    }

    if (!workflow) {
        return (
            <>
                <Header />
                <h1>404: Not Found</h1>
            </>
        )
    }

    return (
        <>
            <Header />
            <WorkflowDetailsWrapper>
                <Container px="3" pb="3" pt="1" flexDirection="column">
                    <Container
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Text size="1" as="h2">
                            {`${workflow.resourceTitle} - ${
                                workflowTasksTypeLabels[workflow.workflowConfig.task]
                            }`}
                        </Text>
                        <Link
                            to={routeTo({
                                resourceId: workflow.resourceId,
                                cwmClassType: workflow.cwmClassType,
                            })}
                        >
                            <Button>Go to title</Button>
                        </Link>
                    </Container>

                    <WorkflowDetailsGrid>
                        <Grid>
                            <Panel>
                                <Text as="h3" size="3" variant="secondary" mb="2">
                                    Workflow Details
                                </Text>
                                <dl>
                                    <dt>Assignee</dt>
                                    <dd>{asigneeFullName}</dd>
                                    <ListSectionDivider />
                                    <dt>
                                        <NoWrap>Completed At</NoWrap>
                                    </dt>
                                    <dd>
                                        <FormattedDate date={workflow.completedAt} />
                                    </dd>
                                    <dt>
                                        <NoWrap>Created</NoWrap>
                                    </dt>
                                    <dd>
                                        <FormattedDate date={workflow.createdAt} />
                                    </dd>
                                    <dt>
                                        <NoWrap>Last updated</NoWrap>
                                    </dt>
                                    <dd>
                                        <FormattedDate date={workflow.updatedAt} />
                                    </dd>
                                    <dt>
                                        <NoWrap>Created By</NoWrap>
                                    </dt>
                                    <dd>{createdByFullName}</dd>
                                    <dt>
                                        <NoWrap>Due Date</NoWrap>
                                    </dt>
                                    <dd>
                                        {workflow.deadlineAt && (
                                            <MediumDate date={workflow.deadlineAt} />
                                        )}
                                    </dd>
                                    <dt>Priority</dt>
                                    <dd>{workflow.priority}</dd>
                                    <dt>
                                        <NoWrap>Genome ID</NoWrap>
                                    </dt>
                                    <dd>{workflow.resourceId}</dd>
                                    <dt>
                                        <NoWrap>Title</NoWrap>
                                    </dt>
                                    <dd>{workflow.resourceTitle}</dd>
                                    <dt>Status</dt>
                                    <dd>{workflow.state}</dd>
                                    <dt>Version</dt>
                                    <dd>{workflow.version}</dd>
                                    <dt>
                                        <NoWrap>Workflow Id</NoWrap>
                                    </dt>
                                    <dd>{workflow.workflowId}</dd>
                                    <dt>
                                        <NoWrap>Workflow Type</NoWrap>
                                    </dt>
                                    <dd>{workflowTasksTypeLabels[workflow.workflowConfig.task]}</dd>
                                    <dt>Source</dt>
                                    <dd>{workflow.source}</dd>
                                    <dt>
                                        <NoWrap>Needs Review?</NoWrap>
                                    </dt>
                                    <dd>
                                        <MetadataBooleanValue
                                            value={workflow.workflowConfig.reviewRequired}
                                        />
                                    </dd>
                                </dl>
                            </Panel>
                        </Grid>
                        <Grid>
                            <Panel>
                                <Text as="h3" size="3" variant="secondary" mb="2">
                                    Workflow Transitions
                                </Text>
                                {workflow.transitions ? (
                                    <Table
                                        {...{
                                            columns,
                                            data: workflow.transitions,
                                            initialState: {
                                                sortBy: [{ desc: true, id: 'updatedAt' }],
                                            },
                                        }}
                                    />
                                ) : (
                                    <>
                                        <Text as="h3" size="3" variant="secondary" mb="2">
                                            No transitions available
                                        </Text>
                                    </>
                                )}
                            </Panel>
                        </Grid>
                    </WorkflowDetailsGrid>
                </Container>
            </WorkflowDetailsWrapper>
        </>
    )
}

export default WorkflowDetails
