import { Column } from 'react-table'
import { CastMember } from 'codecs/CastMember'
import { CharactersDiffOps } from './types'

export enum CharacterRelationship {
    HasOlder = 'hasOlder',
    IsOlderOf = 'isOlderOf',
    HasYounger = 'hasYounger',
    IsYoungerOf = 'isYoungerOf',
    HasIdentity = 'hasIdentity',
    IsIdentityOf = 'isIdentityOf',
    Succeeds = 'succeeds',
    SucceededBy = 'succeededBy',
}

export const RELATIONSHIPS_LABELS_MAP = {
    [CharacterRelationship.HasOlder]: 'Has older',
    [CharacterRelationship.IsOlderOf]: 'Is older of',
    [CharacterRelationship.HasYounger]: 'Has younger',
    [CharacterRelationship.IsYoungerOf]: 'Is younger of',
    [CharacterRelationship.HasIdentity]: 'Has identity',
    [CharacterRelationship.IsIdentityOf]: 'Is identity of',
    [CharacterRelationship.Succeeds]: 'Succeeds',
    [CharacterRelationship.SucceededBy]: 'Succeeded by',
}

export const relationshipTypeResouceBase = 'http://data.disney.com/character'

export const EMPTY_FIELD_LABEL = 'N/A'
export const UNDEFINED_FIELD_LABEL = 'Undefined'
export const UNKNOWN_FIELD_LABEL = 'Unknown'
export const NONE_FIELD_LABEL = 'None'

export const DEFAULT_DEBOUNCE_DELAY = 1000

export enum PortrayalType {
    OfPerson = 'http://data.disney.com/creativeWork#PortrayalOfPerson',
    OfMetaCharacter = 'http://data.disney.com/creativeWork#PortrayalOfMetaCharacter',
    OfCrewRole = '"http://data.disney.com/creativeWork#PortrayalOfCrewRole',
    ByTwins = 'http://data.disney.com/creativeWork#PortrayalByTwins',
    OfCharacter = 'http://data.disney.com/creativeWork#PortrayalOfCharacter',
    OfRole = 'http://data.disney.com/creativeWork#PortrayalOfRole',
}

export const portrayalsPermanentFilterColDef: Column<CastMember> = {
    id: 'portrayalFilter',
    filter: rows => {
        const rowsMap: { [portrayalId: string]: boolean } = {}
        return rows.filter(r => {
            const { portrayal } = r.original
            if (portrayal.portrayalType?.uri === PortrayalType.ByTwins) {
                if (rowsMap[portrayal.portrayalId]) {
                    return false
                } else {
                    rowsMap[portrayal.portrayalId] = true
                    return true
                }
            }

            return true
        })
    },
}

export const portrayalPermanentFilterDiffCOlDef: Column<CharactersDiffOps> = {
    id: 'portrayalFilter',
    filter: rows => {
        const rowsMap: { [portrayalId: string]: boolean } = {}
        return rows.filter(v => {
            const r = v.original.value
            const { portrayal } = r
            if (portrayal.portrayalType?.uri === PortrayalType.ByTwins) {
                if (rowsMap[portrayal.portrayalId as string]) {
                    return false
                } else {
                    rowsMap[portrayal.portrayalId as string] = true
                    return true
                }
            }

            return true
        })
    },
}

export const NONE_OPTION = {
    label: NONE_FIELD_LABEL,
    uri: NONE_FIELD_LABEL,
    mdmId: undefined,
}
