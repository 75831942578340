import React, { useState } from 'react'
import { routeTo } from 'model/search/routeTo'
import history from 'routing/history'
import { ESResult, SearchResult } from 'model/search/SearchResult'
import PageLayout from 'pages/util/PageLayout'
import { CellProps, Column } from 'react-table'
import { Search as SearchLocation, searchLink } from 'routing/routes'
import Container from 'shared/components/Container'
import ReactTooltip from 'react-tooltip'
import { ReactComponent as Warning } from 'shared/components/Icons/warning-icon.svg'
import Loader from 'shared/components/Loader'
import { notification } from 'shared/notification'
import {
    SearchContainer,
    SearchForm,
    SearchFormRow,
    SearchFormGroup,
    SearchHeaderText,
    SearchInput,
    SearchResultLink,
    SearchResultsHeader,
    SearchResultTitle,
} from 'shared/components/search'
import styled from 'shared/theme'
import TitleContentTypeName, {
    DESCRIPTORS_TITLE,
    STORYLINES_RELATIONSHIPS_TITLE,
} from 'shared/components/Workflow/TitleContentTypeName'
import MediumDate from 'shared/components/MediumDate'
import Table from 'shared/components/Table'
import Text from 'shared/components/Text'
import { QuerySizeParams, SearchParams, useSearch } from 'shared/search/useSearch'
import SearchTablePagination, { PaginationOptions } from './SearchTablePagination'
import SearchRow from './SearchRow'
import Button from 'shared/components/Button'
import { displayGenreLabels } from 'shared/search/useFacetOptions'
import { useDidMountEffect } from 'shared/hooks/useDidMountEffect'
import { queryParamsToESQuery, queryParamsToStateTerms } from 'shared/util/QueryBuilder'
import DownloadFileIcon from 'shared/components/Icons/DownloadFileIcon'
import { useFetchSearchReportFile, useCreateSearchReport } from 'api/workflow/createReport'
import { EBSDisplayGenreLabels } from './EBSDisplayGenreLabel'
import { useDebounce } from 'shared/hooks/useDebounce'
import CellLabel from 'shared/components/CellLabel'
import Empty from 'shared/components/Empty'
import ExtendedBasicSearch from './ExtendedBasicSearch'
import { trackComponent } from 'shared/util/snowplowUtils'
import { useAuthSnowplowPageView } from 'shared/hooks/useSnowPlowPageView'

export const StyledInfo = styled(Warning)`
    fill: ${props => props.theme.colors.primary};
    cursor: default;
    max-width: 1.25rem;
`
export const TableOptionButtons = styled.div`
    display: flex;
    height: 3rem;
    > * {
        margin-left: 1rem;
    }
    > svg {
        cursor: pointer;
        width: 2rem;
        aspect-ratio: 1;
    }
`

const TableContainer = styled.div`
    max-width: 100%;
    overflow-y: auto;
`

type SearchProps = {
    match: { params: SearchLocation }
}
const Search: React.FC<SearchProps> = ({
    match: {
        params: { q = '', from = 0, size = 100, text = undefined },
    },
}): React.ReactElement => {
    useAuthSnowplowPageView('Search', 'Search Page')

    const downloadLinkRef = React.useRef<HTMLAnchorElement>(null)

    const [inputSearchText, setInputSearchText] = useState<string>(q)
    const [debouncedInputSearchText] = useDebounce(inputSearchText, 200)
    const [querySizeParams, setQuerySizeParams] = React.useState<QuerySizeParams>({
        from,
        size,
    })

    const [ebsSearchParams, setEBSSearchParams] = useState<SearchParams | undefined>(
        text ? JSON.parse(text) : undefined,
    )
    // Redudant param to show under descriptors column
    const [lastEbsSearchParams, setLastEBSSearchParams] = useState<SearchParams | undefined>(
        undefined,
    )

    const [totalResults, setTotalResults] = useState<null | number>(null)

    const ebsSearchParamsIsEmpty = Object.getOwnPropertyNames(ebsSearchParams || {}).length === 0

    const {
        start: startCreatingSearchReport,
        status: searchReportStatus,
        downloadURL,
        isLoading: isGeneratingSearchReport,
        error,
    } = useCreateSearchReport(2000)

    const { url: searchReportUrl } = useFetchSearchReportFile(downloadURL)

    const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>({
        currentPage: from / size,
        totalPages: 1,
    })

    const { isFetching, results, totalHits, isFinished, refetch, remove } = useSearch({
        searchText: inputSearchText,
        searchParams: ebsSearchParams,
        querySizeParams: ebsSearchParams?.dates
            ? { ...querySizeParams, size: 100 }
            : querySizeParams,
        paginationOptions,
    })

    const handleSearch = React.useCallback(() => {
        setPaginationOptions({
            ...paginationOptions,
            currentPage: 0,
        })
        setLastEBSSearchParams(ebsSearchParams)
        if (q || !ebsSearchParamsIsEmpty) {
            setPaginationOptions({
                ...paginationOptions,
                currentPage: 0,
            })
            setTimeout(() => refetch(), 100)
        }

        trackComponent('Search', 'Search title', 'search', 'Search title', {
            value: [
                {
                    query: q,
                    params: ebsSearchParams, // TODO: Find a way to convert uris to labels
                },
            ],
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch, ebsSearchParams, q])

    const handleReset = React.useCallback(() => {
        setInputSearchText('')
        setEBSSearchParams(undefined)
        setLastEBSSearchParams(undefined)
        setTotalResults(null)
        remove()
        setTimeout(() => refetch(), 0)
    }, [refetch, remove])

    React.useEffect(() => {
        if (q || !ebsSearchParamsIsEmpty) {
            setLastEBSSearchParams(ebsSearchParams)
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (totalHits) {
            setPaginationOptions({
                ...paginationOptions,
                totalPages: Math.ceil(totalHits / querySizeParams.size),
            })
            setTotalResults(totalHits)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalHits])

    React.useEffect(() => {
        if (searchReportStatus?.status === 'error')
            notification.error('An error has ocurred while generating search results file!')
        if (error) throw error
    }, [searchReportStatus?.status, error])

    React.useLayoutEffect(() => {
        if (searchReportUrl) {
            downloadLinkRef?.current?.click()
        }
    }, [downloadLinkRef, searchReportUrl])

    useDidMountEffect(() => {
        setTimeout(() => refetch(), 1000)
    }, [refetch, querySizeParams])

    useDidMountEffect(() => {
        const link = searchLink({
            q: debouncedInputSearchText,
            text: queryParamsToStateTerms(ebsSearchParams),
            ...querySizeParams,
        })
        history.replace(link)
    }, [debouncedInputSearchText, ebsSearchParams, querySizeParams])

    const onGenerateReport = React.useCallback(() => {
        // TODO: Figure out a better place for this logic
        const [allQueryParams] = queryParamsToESQuery({
            'data_disney_com_cpm.titleNameDisambiguated': debouncedInputSearchText,
            ...ebsSearchParams,
        })
        if (!allQueryParams) return

        startCreatingSearchReport({ query: allQueryParams })
    }, [debouncedInputSearchText, ebsSearchParams, startCreatingSearchReport])

    const isLoading = React.useMemo(() => {
        return isFetching && results.length === 0
    }, [isFetching, results.length])

    const isRefetching = React.useMemo(() => {
        return isFetching && results.length > 0
    }, [isFetching, results.length])

    const showSearchResults = React.useMemo(() => {
        return !isFetching && isFinished && results.length > 0
    }, [isFetching, isFinished, results.length])

    const showEmpty = React.useMemo(() => {
        return !isFetching && isFinished && results.length === 0
    }, [isFetching, isFinished, results.length])

    const lastDescriptorsSearched = React.useMemo(() => {
        return lastEbsSearchParams?.['descriptors']
    }, [lastEbsSearchParams])

    const columns = React.useMemo<Column<SearchResult>[]>(
        () => [
            {
                id: 'title',
                Header: 'Title',
                accessor: r => r.genomeObject.titleNameDisambiguated,
                Cell: ({ row: { original: result } }: CellProps<SearchResult>) => (
                    <SearchResultLink to={routeTo(result)}>
                        <SearchResultTitle {...{ result }} />
                    </SearchResultLink>
                ),
                width: 900,
            },
            {
                id: 'radarId',
                Header: 'RADAR Product ID',
                accessor: r => r.genomeObject.productId,
                Cell: ({ row: { original: result } }: CellProps<SearchResult>) => (
                    <Text size="6" style={{ whiteSpace: 'nowrap' }}>
                        {result.genomeObject.productId}
                    </Text>
                ),
                width: 1,
            },
            {
                id: 'type',
                Header: 'Type',
                filter: 'checkbox',
                accessor: r => r.genomeObject.cwmClassTypeLabel,
                Cell: ({ row: { original: result } }: CellProps<SearchResult>) => (
                    <Text size="5" variant="secondary" as="span" style={{ whiteSpace: 'nowrap' }}>
                        {result.genomeObject.cwmClassTypeLabel}
                    </Text>
                ),
                width: 1,
            },
            {
                id: 'genre',
                Header: 'Display Genre',
                filter: 'checkbox',
                accessor: r => displayGenreLabels[r.genomeObject.genreDisplay],
                Cell: ({
                    row: {
                        original: {
                            genomeObject: { productId, genreDisplay },
                        },
                    },
                }: CellProps<SearchResult>) => (
                    <EBSDisplayGenreLabels productId={productId} genres={genreDisplay} />
                ),
                width: 1,
            },
            {
                id: 'descriptors',
                Header: 'Descriptors',
                filter: 'checkbox',
                accessor: r => r.genomeObject.productId,
                Cell: ({
                    row: {
                        original: {
                            genomeObject: { productId },
                        },
                    },
                }: CellProps<ESResult>) => (
                    <EBSDisplayGenreLabels productId={productId} genres={lastDescriptorsSearched} />
                ),
                width: 1,
            },
            {
                id: 'attribute',
                disableSortBy: true,
                filter: 'checkbox',
                accessor: r => r.genomeObject.hasAttribute,
                Cell: false,
                width: 1,
            },
            {
                id: 'earliestReleaseDate',
                Header: 'Earliest Release Date',
                accessor: r => r.genomeObject.earliestReleaseDate,
                filter: 'dateRangeBetweenPlus',
                sortType: 'datetime',
                Cell: ({ row: { original: result } }: CellProps<SearchResult>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {result.genomeObject.earliestReleaseDate && (
                            <MediumDate date={new Date(result.genomeObject.earliestReleaseDate)} />
                        )}
                    </Text>
                ),
                width: 1,
            },
            {
                id: 'data',
                Header: ' Has Data',
                accessor: t => t.id,
                sortType: (a, b) => {
                    const aData = a.original.genomeObject
                    const aDataRate =
                        +!!aData.hasDescriptors + +!!aData.hasRelationships + +!!aData.hasStorylines
                    const bData = b.original.genomeObject
                    const bDataRate =
                        +!!bData.hasDescriptors + +!!bData.hasRelationships + +!!bData.hasStorylines
                    return bDataRate - aDataRate
                },
                Cell: ({
                    row,
                    row: {
                        original: {
                            id,
                            genomeObject: {
                                hasDescriptors,
                                hasRelationships,
                                hasStorylines,
                                publishedDate,
                            },
                        },
                    },
                }: CellProps<SearchResult>) => {
                    if ([hasDescriptors, hasRelationships, hasStorylines].every(h => !h))
                        return null
                    const hasFlags = [
                        { label: DESCRIPTORS_TITLE, hasData: hasDescriptors },
                        {
                            label: STORYLINES_RELATIONSHIPS_TITLE,
                            hasData: hasStorylines || hasRelationships,
                        },
                    ]
                    const hasDataLabels = hasFlags
                        .filter(({ hasData }) => hasData)
                        .map(({ label }) => label)

                    return (
                        <div style={{ textAlign: 'center' }}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a data-for={`search-row-status-tooltip-${id}`} data-tip>
                                <StyledInfo
                                    onClick={e => {
                                        e.stopPropagation()
                                        row.toggleRowExpanded()
                                    }}
                                ></StyledInfo>
                            </a>
                            <ReactTooltip
                                id={`search-row-status-tooltip-${id}`}
                                multiline={true}
                                effect="solid"
                                getContent={() => (
                                    <Container
                                        flexDirection="column"
                                        style={{ textAlign: 'justify' }}
                                    >
                                        <Text>{`This title has ${hasDataLabels.length} types of data: `}</Text>
                                        <ul>
                                            {hasDataLabels.map(contentType => (
                                                <li
                                                    key={`title-search-title-row-status-task-${id}-${contentType}`}
                                                >
                                                    <TitleContentTypeName
                                                        contentType={contentType}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                        {publishedDate && (
                                            <Text>
                                                {`Last Published Date: `}
                                                <MediumDate date={new Date(publishedDate)} />
                                            </Text>
                                        )}
                                    </Container>
                                )}
                            ></ReactTooltip>
                        </div>
                    )
                },
                width: 1,
            },
        ],
        [lastDescriptorsSearched],
    )

    return (
        <PageLayout>
            <SearchContainer>
                <SearchForm>
                    <SearchFormRow>
                        <SearchFormGroup>
                            <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                                Title Name
                            </CellLabel>
                            <SearchInput
                                autoFocus
                                placeholder="Search using title fragment"
                                value={inputSearchText}
                                onChange={e => setInputSearchText(e.target.value)}
                                onReset={() => setInputSearchText('')}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                        handleSearch()
                                    }
                                }}
                            />
                        </SearchFormGroup>
                        <SearchFormGroup>
                            <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                                &nbsp;
                            </CellLabel>
                            <Button id="search" type="button" size="large" onClick={handleSearch}>
                                Search
                            </Button>
                        </SearchFormGroup>
                    </SearchFormRow>
                    <SearchFormRow>
                        <ExtendedBasicSearch
                            isWorkflowSearch={false}
                            initialValues={ebsSearchParams}
                            onFacetsChange={setEBSSearchParams}
                            onSubmit={handleSearch}
                        />
                    </SearchFormRow>
                </SearchForm>

                {isLoading && <Loader center size="normal" />}

                {showSearchResults && (
                    <>
                        <SearchResultsHeader>
                            <Container
                                alignItems="flex-end"
                                justifyContent="space-between"
                                style={{ width: '100%' }}
                            >
                                <SearchHeaderText size="2" as="h2" mt="2" mr="2">
                                    Search results
                                </SearchHeaderText>

                                <TableOptionButtons>
                                    <Button
                                        id="reset"
                                        type="reset"
                                        size="small"
                                        onClick={handleReset}
                                    >
                                        Clear Search
                                    </Button>
                                    <a
                                        id="download-results"
                                        ref={downloadLinkRef}
                                        rel="noreferrer"
                                        style={{ display: 'none' }}
                                        href={searchReportUrl}
                                        download
                                    >
                                        Download
                                    </a>
                                    {!isGeneratingSearchReport && results.length > 0 && (
                                        <DownloadFileIcon
                                            id="generate-results-file"
                                            title="Generate results"
                                            onClick={onGenerateReport}
                                        ></DownloadFileIcon>
                                    )}
                                    {isGeneratingSearchReport && (
                                        <Loader size="ssmallmedium" inline={true} center={true} />
                                    )}
                                </TableOptionButtons>
                            </Container>
                        </SearchResultsHeader>
                        <Container mt={1} mb={2} justifyContent={'space-between'}>
                            <Text ml={1}>
                                {totalResults ? `${totalResults} matches found` : ''}
                            </Text>
                        </Container>

                        <TableContainer>
                            <Table RowComponent={SearchRow} data={results} columns={columns} />
                        </TableContainer>

                        <SearchTablePagination
                            {...{
                                querySizeParams,
                                setQuerySizeParams,
                                paginationOptions,
                                setPaginationOptions,
                            }}
                        />
                    </>
                )}

                {showEmpty && (
                    <Container
                        style={{ height: 'calc(100vh - 300px)' }}
                        alignItems={'center'}
                        justifyContent={'space-around'}
                    >
                        <Empty title="No search results" />
                    </Container>
                )}

                {isRefetching && (
                    <Loader
                        style={{ position: 'fixed', bottom: 0, right: 0 }}
                        size="smedium"
                        inline={true}
                    />
                )}
            </SearchContainer>
        </PageLayout>
    )
}

export default Search
