import React from 'react'
import * as t from 'io-ts'
import { useContextHelper } from 'shared/hooks/useContextHelper'
import { useQuery } from 'react-query'
import { request } from '@genome-web-forms/common/api'
import { authGWF } from 'api/auth'
import { useUser } from 'auth/Auth'
import config from 'shared/config'
import { CastMember, CastMemberCodec } from 'codecs/CastMember'

type CastContextValue<C extends CastMember = CastMember> = C[]
const CastContext = React.createContext<CastContextValue>([])
CastContext.displayName = 'Characters'
export const useCharacters = <C extends CastMember>(): C[] => {
    return useContextHelper(CastContext, 'CharactersContext') as C[]
}

type CharactersProviderProps = {
    characters?: CastMember[]
    resourceId: string
}
export const CharactersProvider: React.FC<CharactersProviderProps> = ({
    characters,
    resourceId,
    children,
}) => {
    const user = useUser()
    const { data } = useQuery<CastMember[]>(
        ['characters', resourceId],
        () => {
            return request(
                t.array(t.exact(CastMemberCodec)),
                authGWF(user, {
                    url: `${config.urlGWF}/lists/characters/${encodeURIComponent(resourceId)}`,
                }),
            )
        },
        {
            enabled: !characters,
            initialData: characters || [],
        },
    )

    return <CastContext.Provider value={data!}>{children}</CastContext.Provider>
}
export default CharactersProvider
