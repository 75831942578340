import * as t from 'io-ts'
import { BooleanFromString } from 'io-ts-types/lib/BooleanFromString'
import { fromNullable } from 'codecs/util/fromNullable'
import nullable from 'codecs/util/nullable'

import { LocationCodec } from 'codecs/Location'
import { CastMemberCodec } from 'codecs/CastMember'
import { CrewMemberCodec } from 'codecs/CrewMember'
import { RelationshipCodec } from 'codecs/Relationship'
import { EpisodeStorylineCodec } from 'codecs/storyline/EpisodeStoryline'
import { DateFromXDS } from 'codecs/util/DateFromXDS'
import * as cwm from '@genome-web-forms/common/model/CWMClassType'
import { MetadataCodec } from 'model/metadata/Metadata'

export const AbridgedEpisodeCodec = t.type({
    episodeId: t.string,
    episodeTitle: t.string,
    seasonId: nullable(t.string),
    seasonTitle: nullable(t.string),
    seasonNumber: nullable(t.string),

    airOrderNumber: nullable(t.string),
    episodeNumber: nullable(t.string),
    foreignEpisodeNumber: nullable(t.string),
})

const EpisodeCodecBase = t.type({
    episodeId: t.string,
    episodeTitle: t.string,
    cwmClassType: t.keyof(cwm.CWM_EPISODE_CLASSES),
    cwmClassTypeLabel: t.string,
    seriesCwmClassType: t.keyof(cwm.CWM_SERIES_CLASSES),
    seriesCwmClassTypeLabel: t.string,

    metadata: MetadataCodec,
    storylines: fromNullable(t.array(EpisodeStorylineCodec), []),
    relationships: fromNullable(t.array(RelationshipCodec), []),

    locations: fromNullable(t.array(LocationCodec), []),
    characters: fromNullable(t.array(CastMemberCodec), []),
    crew: fromNullable(t.array(CrewMemberCodec), []),

    static: t.type({
        seriesId: t.string,
        seriesTitle: t.string,
        titleNameDisambiguated: nullable(t.string),
        titleNameStandardized: nullable(t.string),
        seasonId: nullable(t.string),
        seasonTitle: nullable(t.string),
        seasonNumber: nullable(t.string),

        originalAirDate: nullable(DateFromXDS),
        earliestReleaseDate: nullable(DateFromXDS),

        groupId: nullable(t.string),
        _unverified_radarGroupNumber: nullable(t.string),
        productId: nullable(t.string),
        productNumber: nullable(t.string),
        eidrL1: nullable(t.string),
        hasMDMID: nullable(t.string),

        synopsis: nullable(t.string),
        hasDescription: nullable(t.string),
        airOrderNumber: nullable(t.string),
        episodeNumber: nullable(t.string),
        foreignEpisodeNumber: nullable(t.string),

        brandTitle: nullable(t.string),
        synopsisMedium: nullable(t.string),
        creativeFormat: nullable(t.string),
        productionCompany: fromNullable(t.array(t.string), []),

        _unverified_rating: nullable(t.string),
        _unverified_runtime: nullable(t.string),

        disneyPlusSelected: fromNullable(BooleanFromString, false),
        DisneyPlusOriginal: fromNullable(BooleanFromString, false),

        starSelected: fromNullable(BooleanFromString, false),
    }),
})

export interface Episode extends t.TypeOf<typeof EpisodeCodecBase> {}
export const EpisodeCodec: t.Type<Episode> = cwm
    .CWMTypeLabelCodec(cwm.CWM_CLASS_TYPE_EPISODE)
    .pipe(
        cwm.makeCWMTypeLabelCodec(
            'seriesCwmClassType',
            'seriesCwmClassTypeLabel',
            cwm.CWM_CLASS_TYPE_SERIES,
        ) as any,
    )
    .pipe(EpisodeCodecBase, EpisodeCodecBase.name) as t.Type<Episode>

export interface AbridgedEpisode extends t.TypeOf<typeof AbridgedEpisodeCodec> {}

export const EpisodeJSONViewOmittedValues = [
    'creativeWorkType',
    'creativeWorkTypeLabel',
    'cwmClassType',
    'static.type',
    'static.typeLabel',
    'static.parentClass',
    'static.parentClassLabel',
]
