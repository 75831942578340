import React from 'react'

export const DESCRIPTORS_TITLE = 'Descriptors'
export const STORYLINES_RELATIONSHIPS_TITLE = 'Storylines / Relationships'

const titleContentTypelabels: { [x: string]: string } = {
    [DESCRIPTORS_TITLE]: 'Descriptors',
    [STORYLINES_RELATIONSHIPS_TITLE]: 'Storylines / Relationships',
}

const TitleContentTypeName: React.FC<{ contentType: string }> = ({ contentType }) => (
    <>{titleContentTypelabels[contentType] || contentType}</>
)
export default TitleContentTypeName
