import { rest } from 'msw'
import config from 'shared/config'

const trsMocks = [
    rest.get(`${config.urlTRS}/queries/taxonomy/other-genres`, (_req, res, ctx) => {
        return res(ctx.json(otherGenresRaw))
    }),
    rest.get(`${config.urlTRS}/queries/taxonomy/display-genres`, (_req, res, ctx) => {
        return res(ctx.json(displayGenresRaw))
    }),
    rest.get(`${config.urlTRS}/taxonomy/semaphore/get-taxonomy`, (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(taxonomyTermsRaw))
    }),
    rest.get(`${config.urlTRS}/taxonomy/semaphore/tagged-taxonomy`, (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(taxonomyTermsRaw))
    }),
]

export default trsMocks

export const otherGenresRaw = [
    {
        otherGenreId: 'http://data.disney.com/resources/73e25652-5c39-4200-a6f4-ad6bd92f01a7',
        otherGenreLabel: 'action-adventure',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/673d9e06-aa2c-ca5e-62ce-4dbf587d078e',
        otherGenreLabel: 'animal',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/57dcaae3-99cc-4913-a410-45247470f6e4',
        otherGenreLabel: 'animals & nature',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/7f04aaa0-bead-434f-bf4d-db80b62abfc4',
        otherGenreLabel: 'animation',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/a781ed35-f9f4-4a91-89c0-3dcf7c6fb4d1',
        otherGenreLabel: 'anime',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/88377a70-0340-33bb-2eeb-882202acc2ac',
        otherGenreLabel: 'anthology',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/7f3cc61a-e4a2-0490-d74f-3ff636276e89',
        otherGenreLabel: 'biographical',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/445f3ff8-1131-4aaf-951d-70c64412672c',
        otherGenreLabel: 'buddy',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/41f1b19f-fd71-491f-99d6-33c6ada443b4',
        otherGenreLabel: 'caper',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/973d06a8-70aa-1389-7b43-15b9384c269c',
        otherGenreLabel: "children's",
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/453e62ae-7d87-fa3d-3ebd-75f7065283b7',
        otherGenreLabel: 'comedy',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/f12a3124-5d46-4533-85d6-321b08b8ccc8',
        otherGenreLabel: 'coming of age',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/427b0fe9-ac99-4351-8ba5-bf6c056e16d6',
        otherGenreLabel: 'concert film',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/b1762989-983a-4cef-9c50-4031517b1a0d',
        otherGenreLabel: 'cooking',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/bfb3d7c3-9e25-4c19-974c-32528a63a61d',
        otherGenreLabel: 'crime (genre)',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/be00b911-1214-4d05-a87d-b520606b9415',
        otherGenreLabel: 'dance',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/9e6f3fda-57ec-435a-8e2d-ee98b2705493',
        otherGenreLabel: 'disaster (genre)',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/de322b05-a912-a10e-93f3-19e5341df0bf',
        otherGenreLabel: 'documentary',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/0e9748c0-8454-4906-8629-0ca0dd108a95',
        otherGenreLabel: 'docuseries',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/4736a8b7-a13b-74de-237d-c826e55e778a',
        otherGenreLabel: 'drama',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/3c3ad6b6-1351-bf95-0201-4bd1e81c90b0',
        otherGenreLabel: 'educational',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/0eaebccc-abee-4c88-b149-9cc844ebd388',
        otherGenreLabel: 'family (D+ genre)',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/b53e4274-0a31-ed37-daf3-ebfcbafc0dbe',
        otherGenreLabel: 'fantasy',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/9045f541-b7fe-05dc-f7fb-eb39f282439b',
        otherGenreLabel: 'film noir',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/af3a421c-6cd5-df55-9840-2cbcc1a28eaa',
        otherGenreLabel: 'game show/competition',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/f43180ee-5b2d-6e85-2cc0-304ad2b538a8',
        otherGenreLabel: 'historical',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/b03b159a-9ba9-530a-e724-87534b82efb4',
        otherGenreLabel: 'horror',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/2633c158-65c2-6953-7404-be76ecd7ab96',
        otherGenreLabel: 'instructional',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/68b4075b-7aee-4a1b-b9b5-162171996694',
        otherGenreLabel: 'kids',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/dd431684-f8ac-42c1-8a15-877d85ddd149',
        otherGenreLabel: 'legal (genre)',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/9328d57d-b941-4b0b-8e86-653a6ad3c2e6',
        otherGenreLabel: 'lifestyle',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/323d4a4d-b6d7-1369-3219-d9bc3cba8991',
        otherGenreLabel: 'magazine',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/e24eb390-ff36-2fe2-6e83-3c08e3c0829a',
        otherGenreLabel: 'martial arts',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/d9ee5dd7-08ab-4f65-87a4-f21aa54e8359',
        otherGenreLabel: 'medical (genre)',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/d333c079-4300-54be-0d6f-2e25296b899a',
        otherGenreLabel: 'military',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/ce3674bd-91b5-ab0e-099c-df4e831c62bc',
        otherGenreLabel: 'music (genre)',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/853fd702-189b-ac3c-4ae1-19514ea9089e',
        otherGenreLabel: 'music video',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/7c300e5c-6875-5729-529d-3955fe1d72af',
        otherGenreLabel: 'musical',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/5d3874ac-a94d-f8f3-a2ec-af7e015874a0',
        otherGenreLabel: 'mystery',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/533ec9e9-7759-40a9-9839-80f6487073ba',
        otherGenreLabel: 'nature',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/e93c1b92-aa6c-9ce3-edce-9f5bdb66b285',
        otherGenreLabel: 'news',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/fd346aea-e1b2-2d0f-8095-71b8b081c6b0',
        otherGenreLabel: 'parody (genre)',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/013d2ab5-3160-6141-b8b8-3399c1d170a0',
        otherGenreLabel: 'police/cop',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/f7c515c9-9a9d-46cd-a572-b799fec224f1',
        otherGenreLabel: 'political (genre)',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/4f5c53f2-cf1c-40d2-9001-d0c8ac068c40',
        otherGenreLabel: 'procedural',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/8b376c79-c689-c54c-8fa9-ac93f4a3d382',
        otherGenreLabel: 'public affairs',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/bc37d71d-9504-77e2-7cd5-6f1e513c0f82',
        otherGenreLabel: 'reality',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/aa3be381-fb83-fa5b-6fc1-b57eec07268b',
        otherGenreLabel: 'religious',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/bac206f2-e0c5-4ab3-be14-4eb3280fef1e',
        otherGenreLabel: 'road (genre)',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/d8343820-5ac3-c5fc-c4e5-70e50b1dbca7',
        otherGenreLabel: 'romance (genre)',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/7d67ea14-a80f-43f4-ad6c-094563a43c6c',
        otherGenreLabel: 'romantic comedy',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/33d0cad6-5c3c-4b72-9271-7621603adc04',
        otherGenreLabel: 'satire',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/df3cb718-ea7c-17a8-9565-5ecfdea59c98',
        otherGenreLabel: 'science fiction',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/ad3b69a6-ffc8-dcdb-1a50-a2c4144d6dad',
        otherGenreLabel: 'soap opera/melodrama',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/3e14bf1e-6e29-4001-8853-600e662332f9',
        otherGenreLabel: 'sports (genre)',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/61543603-decd-4348-94b8-97035525357c',
        otherGenreLabel: 'spy/espionage',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/fbd342f6-22f2-45b5-bcc6-a2c01e881685',
        otherGenreLabel: 'superhero (genre)',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/87340901-ca01-477a-9158-285969b02f67',
        otherGenreLabel: 'survival (genre)',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/7839bd33-9e67-c6d4-9b14-aa39acd61699',
        otherGenreLabel: 'talk show',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/9035e016-26af-60aa-8541-67fc91047982',
        otherGenreLabel: 'thriller',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/f334230a-cb59-758f-49ca-928388581bbd',
        otherGenreLabel: 'travel (genre)',
        notForTagging: 'true',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/cb37e9d7-6733-06cb-5a0b-3bbe228f1496',
        otherGenreLabel: 'variety',
        notForTagging: '',
    },
    {
        otherGenreId: 'http://data.disney.com/resources/ea3b750a-8909-6a41-ee63-905a1c2ab982',
        otherGenreLabel: 'western',
        notForTagging: '',
    },
]

export const displayGenresRaw = [
    {
        displayGenreId: 'http://data.disney.com/resources/73e25652-5c39-4200-a6f4-ad6bd92f01a7',
        displayGenreLabel: 'action-adventure',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/57dcaae3-99cc-4913-a410-45247470f6e4',
        displayGenreLabel: 'animals & nature',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/7f04aaa0-bead-434f-bf4d-db80b62abfc4',
        displayGenreLabel: 'animation',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/88377a70-0340-33bb-2eeb-882202acc2ac',
        displayGenreLabel: 'anthology',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/453e62ae-7d87-fa3d-3ebd-75f7065283b7',
        displayGenreLabel: 'comedy',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/de322b05-a912-a10e-93f3-19e5341df0bf',
        displayGenreLabel: 'documentary',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/0e9748c0-8454-4906-8629-0ca0dd108a95',
        displayGenreLabel: 'docuseries',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/4736a8b7-a13b-74de-237d-c826e55e778a',
        displayGenreLabel: 'drama',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/b53e4274-0a31-ed37-daf3-ebfcbafc0dbe',
        displayGenreLabel: 'fantasy',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/af3a421c-6cd5-df55-9840-2cbcc1a28eaa',
        displayGenreLabel: 'game show/competition',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/b03b159a-9ba9-530a-e724-87534b82efb4',
        displayGenreLabel: 'horror',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/68b4075b-7aee-4a1b-b9b5-162171996694',
        displayGenreLabel: 'kids',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/dd431684-f8ac-42c1-8a15-877d85ddd149',
        displayGenreLabel: 'legal (genre)',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/ce3674bd-91b5-ab0e-099c-df4e831c62bc',
        displayGenreLabel: 'music (genre)',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/7c300e5c-6875-5729-529d-3955fe1d72af',
        displayGenreLabel: 'musical',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/5d3874ac-a94d-f8f3-a2ec-af7e015874a0',
        displayGenreLabel: 'mystery',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/e93c1b92-aa6c-9ce3-edce-9f5bdb66b285',
        displayGenreLabel: 'news',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/bc37d71d-9504-77e2-7cd5-6f1e513c0f82',
        displayGenreLabel: 'reality',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/d8343820-5ac3-c5fc-c4e5-70e50b1dbca7',
        displayGenreLabel: 'romance (genre)',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/df3cb718-ea7c-17a8-9565-5ecfdea59c98',
        displayGenreLabel: 'science fiction',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/3e14bf1e-6e29-4001-8853-600e662332f9',
        displayGenreLabel: 'sports (genre)',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/fbd342f6-22f2-45b5-bcc6-a2c01e881685',
        displayGenreLabel: 'superhero (genre)',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/7839bd33-9e67-c6d4-9b14-aa39acd61699',
        displayGenreLabel: 'talk show',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/9035e016-26af-60aa-8541-67fc91047982',
        displayGenreLabel: 'thriller',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/cb37e9d7-6733-06cb-5a0b-3bbe228f1496',
        displayGenreLabel: 'variety',
    },
    {
        displayGenreId: 'http://data.disney.com/resources/ea3b750a-8909-6a41-ee63-905a1c2ab982',
        displayGenreLabel: 'western',
    },
]

export const taxonomyTermsRaw = {
    taxonomies: [
        {
            label: 'Activities',
            type: 'http://data.disney.com/resources/Activities',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                    notForTagging: true,
                    label: 'Communication',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/c1a4065f-999c-40ff-8213-31fe4fad8f58',
                            notForTagging: false,
                            label: 'silencing',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/767f526e-cceb-45bd-88a4-c16e14ce539d',
                            notForTagging: false,
                            label: 'threats',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/11560bf7-a6d7-4689-9936-358833ad5491',
                            notForTagging: false,
                            label: 'clapping hands',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4131f002-1e7d-4c16-8f6c-fbacd8620850',
                            notForTagging: false,
                            label: 'fist bumping',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/aba31910-49d9-49cd-a9e1-55a334bed4f6',
                            notForTagging: false,
                            label: 'screaming',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d24e97e0-b9bc-24c7-4609-e26f2010c2a6',
                            notForTagging: false,
                            label: 'shaking hands',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/778e3e2e-7ec2-4b8a-9b06-4fe7430867d4',
                            notForTagging: false,
                            label: 'telepathy',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3b5e6556-a33a-4b2a-b1ae-cb49de92e210',
                            notForTagging: false,
                            label: 'texting',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e547ab00-c2bf-385f-7534-67ad989c1390',
                            notForTagging: false,
                            label: 'barking',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9b3335c1-b2d9-472a-9421-3842f68c0f32',
                            notForTagging: false,
                            label: 'high-fiving',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b93fceb1-2ffc-f22e-9923-9bc43e1e57b1',
                            notForTagging: false,
                            label: 'begging',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4e48b2f2-87ce-7461-a0d8-f29c772d8c8b',
                            notForTagging: false,
                            label: 'arguing',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/55436187-75bc-54c9-50c7-e85629efc78a',
                            notForTagging: false,
                            label: 'winking',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/132791cf-291a-48b4-b032-73829cc7cf74',
                            notForTagging: false,
                            label: 'confessing',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d8453e8a-8104-4c09-95a9-d373344c1244',
                            notForTagging: false,
                            label: 'bullying',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/7757c2ee-0edf-46d8-ae41-3e66c877b926',
                                    notForTagging: true,
                                    label: 'cyberbullying',
                                    broaderId:
                                        'http://data.disney.com/resources/d8453e8a-8104-4c09-95a9-d373344c1244',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/b83b0891-a87e-290c-9b40-7639146a4291',
                            notForTagging: false,
                            label: 'hugging',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0f5d466f-3841-4f90-a077-ad637260b78d',
                            notForTagging: false,
                            label: 'sign language',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bc4ba1d6-228b-5678-87b2-9c3f6b440398',
                            notForTagging: false,
                            label: 'laughing',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/aec337b6-4828-40f1-9e57-696025f47651',
                            notForTagging: false,
                            label: 'apology',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8f43247a-1807-e40e-dfc6-563172dfe9bd',
                            notForTagging: false,
                            label: 'whispering',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2632552e-5706-fb0e-427e-7d0b7ec58884',
                            notForTagging: false,
                            label: 'crying',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4c34cc35-0fe7-fad0-fe33-121433fc72b9',
                            notForTagging: false,
                            label: 'kissing',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7c6efd93-4aa6-4db6-9732-6ef949d3af45',
                            notForTagging: false,
                            label: 'warning',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5a407be0-4e8e-4882-a1ce-e2d005c377d5',
                            notForTagging: false,
                            label: 'slamming door',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/17dfb882-3518-4f0d-930f-82d4e24ab45e',
                            notForTagging: false,
                            label: 'greeting',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3346494b-1591-118e-f05c-e694d7ebcd99',
                            notForTagging: false,
                            label: 'flirting',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b2b693b0-b565-4feb-a484-7782def8fe5a',
                            notForTagging: false,
                            label: 'sexting',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/925cd0cf-d11b-4493-8721-f595b502acb5',
                            notForTagging: false,
                            label: 'practical jokes',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/eebaef70-23a8-429e-886c-a539111547a6',
                            notForTagging: false,
                            label: 'patting on back',
                            broaderId:
                                'http://data.disney.com/resources/392a3072-c4c2-474f-9ff9-ae76baf3db60',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/eb4e2f76-7c8b-8cb6-b2c7-b56a5648979b',
                    notForTagging: true,
                    label: 'Gestures',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/29980a75-c906-4545-8b74-31a6a85108e2',
                            notForTagging: false,
                            label: 'giving thumbs up gesture',
                            broaderId:
                                'http://data.disney.com/resources/eb4e2f76-7c8b-8cb6-b2c7-b56a5648979b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/49832aa7-2d82-4c48-9097-8fadfb7194b1',
                            notForTagging: false,
                            label: 'middle-finger gesture',
                            broaderId:
                                'http://data.disney.com/resources/eb4e2f76-7c8b-8cb6-b2c7-b56a5648979b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1f83b8d9-0607-4aa9-9410-ffa6e0f0426a',
                            notForTagging: false,
                            label: 'looking over shoulder',
                            broaderId:
                                'http://data.disney.com/resources/eb4e2f76-7c8b-8cb6-b2c7-b56a5648979b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dbcdc202-a711-448e-8456-914d99b8191e',
                            notForTagging: false,
                            label: 'looking through window',
                            broaderId:
                                'http://data.disney.com/resources/eb4e2f76-7c8b-8cb6-b2c7-b56a5648979b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1111998a-452b-48f6-8c7f-863e8d83cb82',
                            notForTagging: false,
                            label: 'eye-rolling',
                            broaderId:
                                'http://data.disney.com/resources/eb4e2f76-7c8b-8cb6-b2c7-b56a5648979b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b9414d70-aee1-9dd3-0c6d-4536708cda82',
                            notForTagging: false,
                            label: 'holding hands',
                            broaderId:
                                'http://data.disney.com/resources/eb4e2f76-7c8b-8cb6-b2c7-b56a5648979b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1148afc6-b2d2-439e-abe1-b3f41b31defc',
                            notForTagging: false,
                            label: 'shrugging',
                            broaderId:
                                'http://data.disney.com/resources/eb4e2f76-7c8b-8cb6-b2c7-b56a5648979b',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/df479771-d1e2-4e1d-a925-4d07c032ddf8',
                    notForTagging: false,
                    label: 'TV watching',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/04109e8d-8052-9d05-9c75-0768b3f89d82',
                    notForTagging: true,
                    label: 'acting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6c6c1e41-fd35-4ee9-a4f2-8285cfaa14f9',
                    notForTagging: false,
                    label: 'animal welfare',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6b3ebd4b-1545-b2f2-f4e5-04efcf1f87ae',
                    notForTagging: true,
                    label: 'aquatic sports',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/536b394a-23b2-4a90-9df4-6b7936e923b5',
                            notForTagging: false,
                            label: 'water polo',
                            broaderId:
                                'http://data.disney.com/resources/6b3ebd4b-1545-b2f2-f4e5-04efcf1f87ae',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8736180a-df99-9f6c-90d4-704fb3c0fc98',
                            notForTagging: true,
                            label: 'scuba diving',
                            broaderId:
                                'http://data.disney.com/resources/6b3ebd4b-1545-b2f2-f4e5-04efcf1f87ae',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b8338e95-cbcf-382e-14a9-b3c83dadd798',
                            notForTagging: false,
                            label: 'traveling by boat',
                            broaderId:
                                'http://data.disney.com/resources/6b3ebd4b-1545-b2f2-f4e5-04efcf1f87ae',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ef341d6d-cd84-cc06-2e85-fa38c983e893',
                            notForTagging: true,
                            label: 'kayaking',
                            broaderId:
                                'http://data.disney.com/resources/6b3ebd4b-1545-b2f2-f4e5-04efcf1f87ae',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7536675d-8250-b95f-5c00-cfb3cf464092',
                            notForTagging: false,
                            label: 'swimming',
                            broaderId:
                                'http://data.disney.com/resources/6b3ebd4b-1545-b2f2-f4e5-04efcf1f87ae',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fa479012-d732-aed0-b7e5-f167a6aaf8a4',
                            notForTagging: true,
                            label: 'canoeing',
                            broaderId:
                                'http://data.disney.com/resources/6b3ebd4b-1545-b2f2-f4e5-04efcf1f87ae',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b83f7d74-424e-4020-9ebb-e33ea0934187',
                            notForTagging: true,
                            label: 'surfing',
                            broaderId:
                                'http://data.disney.com/resources/6b3ebd4b-1545-b2f2-f4e5-04efcf1f87ae',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/11388714-bb88-02c2-5e0b-264fad10549a',
                            notForTagging: false,
                            label: 'sailing',
                            broaderId:
                                'http://data.disney.com/resources/6b3ebd4b-1545-b2f2-f4e5-04efcf1f87ae',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/ba58cfea-510b-42c9-b993-b771c88fc6d9',
                    notForTagging: false,
                    label: 'assault',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/68c31cd4-b8c6-4cd5-8d46-afb01775f84d',
                            notForTagging: false,
                            label: 'electrocution',
                            broaderId:
                                'http://data.disney.com/resources/ba58cfea-510b-42c9-b993-b771c88fc6d9',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/a7756c9b-bd38-4042-a9b3-c924168cc407',
                    notForTagging: false,
                    label: 'astral projection',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4690becb-4453-4282-8596-034dddc3c5b5',
                    notForTagging: false,
                    label: 'babysitting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c43b20e3-cdea-ed2e-0df5-dc44b9fe1c82',
                    notForTagging: false,
                    label: 'bathing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cd3b0314-df83-d23c-9623-8f646f98a989',
                    notForTagging: false,
                    label: 'camping',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e2473ead-b5d9-2c76-0c24-fb7b660db5a7',
                    notForTagging: true,
                    label: 'carving',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4c11519a-1cb5-48ce-8e90-5274db47e8c6',
                    notForTagging: false,
                    label: 'charity',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3d4811eb-2edb-a8d3-60a4-615e7a170e96',
                    notForTagging: false,
                    label: 'cheerleading',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e94c7e02-6791-63e7-c9f7-e1a7b8f613aa',
                    notForTagging: false,
                    label: 'choking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1b4a81c2-b1c1-929f-f1e1-5c8f2c0d1cbd',
                    notForTagging: false,
                    label: 'cleaning',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/1e468b18-1ce7-372a-2e38-12bfe370eab1',
                            notForTagging: true,
                            label: 'laundering',
                            broaderId:
                                'http://data.disney.com/resources/1b4a81c2-b1c1-929f-f1e1-5c8f2c0d1cbd',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/ef318d5c-f071-b30f-0c3d-b536becf609d',
                    notForTagging: true,
                    label: 'coaching',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f655f495-ca22-4380-8fbf-a47cbf2647e3',
                    notForTagging: false,
                    label: 'cockfighting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                    notForTagging: false,
                    label: 'committing a crime',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/fc9cab33-f835-46cc-ad10-4046cdd1ec70',
                            notForTagging: false,
                            label: 'robbing',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/91b84e56-b443-4a4d-9eb6-5eda61afbd30',
                                    notForTagging: true,
                                    label: 'car theft',
                                    broaderId:
                                        'http://data.disney.com/resources/fc9cab33-f835-46cc-ad10-4046cdd1ec70',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a5d681a9-b0ad-4bd9-9dc6-d1e69f3fa3ab',
                                    notForTagging: false,
                                    label: 'bank robbery',
                                    broaderId:
                                        'http://data.disney.com/resources/fc9cab33-f835-46cc-ad10-4046cdd1ec70',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/05c59b34-4277-41fe-a108-188a9131c54d',
                                    notForTagging: true,
                                    label: 'carjacking',
                                    broaderId:
                                        'http://data.disney.com/resources/fc9cab33-f835-46cc-ad10-4046cdd1ec70',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/a1a8431c-fa75-40e2-a23d-98844753ee96',
                            notForTagging: false,
                            label: 'extorting',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/854dccea-a962-441b-898f-401d158774bb',
                            notForTagging: true,
                            label: 'transit crime',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c3936a4e-c7eb-4bec-85ef-f3161ca62dcc',
                            notForTagging: false,
                            label: 'arson',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3183b843-2bdd-4b5b-a016-2ce7ca5d19b5',
                            notForTagging: false,
                            label: 'drive-by shooting',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6dd60f9f-c210-48a3-8a6c-f8c076eab6a3',
                            notForTagging: false,
                            label: 'stalking',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/24f89cd5-7909-4827-a8bf-1e87c53e4fff',
                            notForTagging: false,
                            label: 'bribing',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c3d4103d-523d-4c27-8c1e-74dfa18fb90f',
                            notForTagging: false,
                            label: 'pet theft',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c2a86c14-77dc-4f85-94db-32b13885ec0c',
                            notForTagging: false,
                            label: 'elder abuse',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/85e39a63-e297-4be0-be54-e9c3174ce55a',
                            notForTagging: false,
                            label: 'intoxication',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1e603d25-b6fa-433e-890a-38ad77145e31',
                            notForTagging: true,
                            label: 'child pornography',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e130327a-f92f-4736-9c98-f59da95bd4bb',
                            notForTagging: false,
                            label: 'smuggling',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/862575be-3e5e-4e7e-852e-525a11c92009',
                            notForTagging: false,
                            label: 'murder',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/11082a63-f580-46a3-a718-15495370f7e0',
                                    notForTagging: false,
                                    label: 'assassination',
                                    broaderId:
                                        'http://data.disney.com/resources/862575be-3e5e-4e7e-852e-525a11c92009',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/f0534ab4-3e1e-476c-89d1-aae70da06f6c',
                                    notForTagging: false,
                                    label: 'murder for hire',
                                    broaderId:
                                        'http://data.disney.com/resources/862575be-3e5e-4e7e-852e-525a11c92009',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/8ad2cf29-2777-41f2-8f9f-679fa18657d5',
                            notForTagging: true,
                            label: 'hate crime',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4aa17224-861e-4d95-a9a8-a98ac066ae1f',
                            notForTagging: false,
                            label: 'burglary',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dd1eb47d-27e8-40eb-baa8-a07499959f4b',
                            notForTagging: false,
                            label: 'kidnapping',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/71bbd2b4-cbeb-42af-946d-890736be4e1b',
                            notForTagging: false,
                            label: 'disorderly conduct',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d7216163-7168-4780-81c7-af9e1944ff21',
                            notForTagging: true,
                            label: 'hijacking',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b56c20c4-b2d6-4f1c-b5c7-ec905cb5dc8d',
                            notForTagging: false,
                            label: 'domestic violence',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cf2d78e5-dfd5-4d50-8611-31f1050e9649',
                            notForTagging: true,
                            label: 'embezzlement',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9d65170b-03d8-4353-afb2-10c5a6c8368d',
                            notForTagging: false,
                            label: 'rape',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/9240bd7d-ac37-4acb-b5eb-a73530edd575',
                                    notForTagging: false,
                                    label: 'statutory rape',
                                    broaderId:
                                        'http://data.disney.com/resources/9d65170b-03d8-4353-afb2-10c5a6c8368d',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/222e8346-48ea-4013-80c3-e85d0007d198',
                            notForTagging: false,
                            label: 'shoplifting',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1abde4d9-a7d6-4ee4-b8ea-3f18a2626694',
                            notForTagging: true,
                            label: 'cybercrime',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bbfce32f-96bb-463d-875a-3d0f381d7b5f',
                            notForTagging: false,
                            label: 'human trafficking',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bab3d19c-2d92-41cf-b745-4d496f48fc7f',
                            notForTagging: false,
                            label: 'committing fraud',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/62d06c4f-040e-4536-b32a-9b8209b9714d',
                                    notForTagging: false,
                                    label: 'identity theft',
                                    broaderId:
                                        'http://data.disney.com/resources/bab3d19c-2d92-41cf-b745-4d496f48fc7f',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/ca487485-68e3-48f6-8ad6-c1bb2990fa7c',
                            notForTagging: false,
                            label: 'vandalizing',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a875bd1c-8ad6-49a7-8809-d4784ec12f7a',
                            notForTagging: false,
                            label: 'suicide',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dc300204-4f35-4ab3-8ef8-2b501f641c8d',
                            notForTagging: false,
                            label: 'terrorism',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/65e98df5-55f6-4167-9db4-93980e4fac29',
                            notForTagging: false,
                            label: 'school shooting',
                            broaderId:
                                'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/2938303c-4efb-4aee-97de-7e4c0cf7da90',
                    notForTagging: false,
                    label: 'cooking (activity)',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/e846a31b-ec0d-f413-cacb-dddc850374a3',
                            notForTagging: false,
                            label: 'baking',
                            broaderId:
                                'http://data.disney.com/resources/2938303c-4efb-4aee-97de-7e4c0cf7da90',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/34457c9e-a484-4ae3-6762-8b8b59529a94',
                            notForTagging: true,
                            label: 'grilling',
                            broaderId:
                                'http://data.disney.com/resources/2938303c-4efb-4aee-97de-7e4c0cf7da90',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/034feacf-8dc7-1403-ca5f-875571304ea0',
                            notForTagging: true,
                            label: 'roasting',
                            broaderId:
                                'http://data.disney.com/resources/2938303c-4efb-4aee-97de-7e4c0cf7da90',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/14465d5d-c40e-923b-0839-29538f935bac',
                            notForTagging: true,
                            label: 'boiling',
                            broaderId:
                                'http://data.disney.com/resources/2938303c-4efb-4aee-97de-7e4c0cf7da90',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2d4601ef-7ea6-c75a-6ba7-e72eaebac291',
                            notForTagging: true,
                            label: 'sautéing',
                            broaderId:
                                'http://data.disney.com/resources/2938303c-4efb-4aee-97de-7e4c0cf7da90',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6748b38f-a78d-4bfe-54de-e26b1ad6cd89',
                            notForTagging: true,
                            label: 'broiling',
                            broaderId:
                                'http://data.disney.com/resources/2938303c-4efb-4aee-97de-7e4c0cf7da90',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/ddef70bb-1239-44a3-b239-776c935ea888',
                    notForTagging: false,
                    label: 'cosmetics--application',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0efc79a1-2134-4035-b644-a404fd7d8347',
                    notForTagging: false,
                    label: 'counseling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/817e4be2-f4cb-4398-a22f-4512a2d81fec',
                    notForTagging: false,
                    label: 'crafts',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/99e31090-1f0c-49c9-8882-f7e64800ba9d',
                    notForTagging: false,
                    label: 'creating visual art',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f54de28d-65c8-9e08-4e3e-31dff0bb508a',
                    notForTagging: false,
                    label: 'cycling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/373a487f-eb60-ea18-0759-848d389ac080',
                    notForTagging: false,
                    label: 'dancing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5170c7da-df9a-4c57-b153-ed13e58b06f2',
                    notForTagging: false,
                    label: 'dating',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1ea74470-1d57-44b1-88a4-e3a9f72e36f6',
                    notForTagging: true,
                    label: 'day care',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e828fdae-406d-4cec-ba8e-a28df64826bb',
                    notForTagging: false,
                    label: 'demolition',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/45e9cf78-b5c5-467c-9334-072424c75654',
                    notForTagging: false,
                    label: 'dining out',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1afcfba6-b146-4232-bcc0-4b7a21a95c5a',
                    notForTagging: false,
                    label: 'discredit',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/12392472-46a1-8d7b-0091-a358343aa49f',
                    notForTagging: true,
                    label: 'drinking',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/32a83b65-9778-4c9b-9c26-6d52a2ddae71',
                            notForTagging: false,
                            label: 'coffee drinking',
                            broaderId:
                                'http://data.disney.com/resources/12392472-46a1-8d7b-0091-a358343aa49f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4a00d19c-1b81-426e-8864-92140327e568',
                            notForTagging: false,
                            label: 'drinking of alcoholic beverages',
                            broaderId:
                                'http://data.disney.com/resources/12392472-46a1-8d7b-0091-a358343aa49f',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/2e4ab8f2-00d9-16d6-10fb-b472bf2e07b6',
                    notForTagging: false,
                    label: 'driving',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/a5938440-a56d-40e0-8109-62f2f766b39d',
                            notForTagging: true,
                            label: 'distracted driving',
                            broaderId:
                                'http://data.disney.com/resources/2e4ab8f2-00d9-16d6-10fb-b472bf2e07b6',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/070a9aa9-d496-49bf-9a6d-7dbbc4c4a013',
                    notForTagging: false,
                    label: 'drug abuse',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/803910b6-6b7f-3cfe-aac5-68fa9f1b6093',
                    notForTagging: false,
                    label: 'eating',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f0752d44-f3e2-4a17-b160-99eb94215a46',
                    notForTagging: false,
                    label: 'enforcing the law',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/824990b1-591a-e99c-e286-78d104d60194',
                    notForTagging: false,
                    label: 'exercising',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9947f393-a881-bf5f-af86-e8c66475d3b0',
                    notForTagging: false,
                    label: 'feeling ill',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a9feb50f-334a-4143-9c69-9cfa467770f1',
                    notForTagging: false,
                    label: 'fencing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/24de80dd-1d84-489a-b646-0af26dadeb54',
                    notForTagging: true,
                    label: 'fire prevention',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/31ac1ac9-530e-444e-811f-1014b5ab356c',
                    notForTagging: false,
                    label: 'firing of employees',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8b69f141-e4da-4550-bd1c-e654ede038f1',
                    notForTagging: false,
                    label: 'fishing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ad4951f0-b233-40cb-af2b-05105e39e795',
                    notForTagging: false,
                    label: 'flying',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f8425b82-e682-44d2-a9b5-b7a33535b8f1',
                    notForTagging: false,
                    label: 'fortune telling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3741c5af-5ec4-35de-ba8e-e8d2c1ec9cb7',
                    notForTagging: true,
                    label: 'frying',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cc6222ea-1368-4a3a-9198-cab9fc36d3d2',
                    notForTagging: true,
                    label: 'fundraising',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b87195e4-7332-42cd-b9ed-81d58365edc3',
                    notForTagging: false,
                    label: 'gambling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1437aaf3-a7a0-d5d0-8d9a-36f5d8a0ae96',
                    notForTagging: false,
                    label: 'games',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/1e4f86a4-6e66-83e5-5b18-76ead4b93ab9',
                            notForTagging: true,
                            label: 'chess',
                            broaderId:
                                'http://data.disney.com/resources/1437aaf3-a7a0-d5d0-8d9a-36f5d8a0ae96',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9544fe66-eb5e-ab16-f119-46018f1f409b',
                            notForTagging: true,
                            label: 'foosball',
                            broaderId:
                                'http://data.disney.com/resources/1437aaf3-a7a0-d5d0-8d9a-36f5d8a0ae96',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f74bf7d8-34b7-de86-8756-1b0476e30b97',
                            notForTagging: false,
                            label: 'video game',
                            broaderId:
                                'http://data.disney.com/resources/1437aaf3-a7a0-d5d0-8d9a-36f5d8a0ae96',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ca9d6288-e1d4-4870-96cb-678be24480b2',
                            notForTagging: false,
                            label: 'card game',
                            broaderId:
                                'http://data.disney.com/resources/1437aaf3-a7a0-d5d0-8d9a-36f5d8a0ae96',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/6e2132ad-da9e-4ef5-8130-57dd8f3ae9fd',
                    notForTagging: false,
                    label: 'gardening (activity)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7d328301-d77d-487c-ac81-a80344d467ce',
                    notForTagging: false,
                    label: 'gardening activity test',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bb3166ec-98bb-77ca-98cc-d243a41dceb7',
                    notForTagging: false,
                    label: 'golf',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/e93adfde-65aa-485f-4969-59cb86792b95',
                            notForTagging: true,
                            label: 'miniature golf',
                            broaderId:
                                'http://data.disney.com/resources/bb3166ec-98bb-77ca-98cc-d243a41dceb7',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/8b0357d4-3a20-4e80-89c0-816a90f2fe61',
                    notForTagging: true,
                    label: 'grooming',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/49e2b445-6798-4063-b725-d62d3a687533',
                            notForTagging: false,
                            label: 'shaving',
                            broaderId:
                                'http://data.disney.com/resources/8b0357d4-3a20-4e80-89c0-816a90f2fe61',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ee481275-1f3d-475d-3f8c-bf2f2febeeb7',
                            notForTagging: true,
                            label: 'hairdressing',
                            broaderId:
                                'http://data.disney.com/resources/8b0357d4-3a20-4e80-89c0-816a90f2fe61',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d9578cad-1046-4c92-ab5e-3f0fc0dd12da',
                            notForTagging: false,
                            label: 'dressing',
                            broaderId:
                                'http://data.disney.com/resources/8b0357d4-3a20-4e80-89c0-816a90f2fe61',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/0a243cbc-adf8-4189-be91-bd8e774760c9',
                    notForTagging: false,
                    label: 'growing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8a47b408-979a-f8cc-cba1-a5689e16c0b7',
                    notForTagging: false,
                    label: 'hand-to-hand fighting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b420d56f-c46e-4422-b1bc-8759d9a89891',
                    notForTagging: true,
                    label: 'hazing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6257f9ac-ce8d-4c91-8251-a4acf8fae680',
                    notForTagging: false,
                    label: 'heabanging',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/509e4209-47fb-49f3-ba7a-093b0fe40caf',
                    notForTagging: false,
                    label: 'healing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0042898c-8d4f-c2fc-2c53-be7341ba6987',
                    notForTagging: false,
                    label: 'hiding',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9e411403-c1bc-b64d-cfed-2c2f734247b3',
                    notForTagging: false,
                    label: 'hiking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bcd081ea-e4c8-44c0-bf97-66fd5320f10d',
                    notForTagging: false,
                    label: 'hiring',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/016f85a7-5cb0-482f-afed-4d96deb39f18',
                    notForTagging: true,
                    label: 'horse racing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/97309c0a-189d-06dc-e624-a5bb7dbb43aa',
                    notForTagging: false,
                    label: 'hunting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/de261999-4aa5-4d84-b43a-14a73fe97de8',
                    notForTagging: false,
                    label: 'interrogating',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9fa2b197-fde8-4a90-a334-765f9f58a5b5',
                    notForTagging: false,
                    label: 'intoxication',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3870f258-dded-4945-b930-869bda190e4d',
                    notForTagging: false,
                    label: 'introduction',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5574215f-789f-4f74-a251-6d4a63f8962a',
                    notForTagging: false,
                    label: 'investigation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1434b798-3961-7a29-782e-71fc9d8740b9',
                    notForTagging: false,
                    label: 'jumping',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ec29d9cc-f15f-42a6-bfc8-053f80188178',
                    notForTagging: false,
                    label: 'karaoke',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c6e3f474-3089-4e8f-848e-921fec919aec',
                    notForTagging: false,
                    label: 'knocking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/522dc2cf-8512-47f2-a2bd-aed572cf10e1',
                    notForTagging: false,
                    label: 'looking in mirror',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/64166d8c-a572-422a-bc9a-650c81140ef6',
                    notForTagging: true,
                    label: 'lottery',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ef69695c-4b91-4b90-b879-c46da8ba12d1',
                    notForTagging: false,
                    label: 'massage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dd1c51ac-00c6-468f-8118-3c662d7c4a9b',
                    notForTagging: false,
                    label: 'medical examination',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/2fc4fee2-bb92-401b-822b-0882167f07d8',
                            notForTagging: true,
                            label: 'eye care',
                            broaderId:
                                'http://data.disney.com/resources/dd1c51ac-00c6-468f-8118-3c662d7c4a9b',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/b0207b29-e9b8-4e0d-95c2-6b557f1b4219',
                    notForTagging: false,
                    label: 'meditation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/808ba185-293e-4882-a015-850f03c5fe29',
                    notForTagging: false,
                    label: 'montage of activities',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/70899f10-97c6-4fe5-b078-bc30b3af20b2',
                    notForTagging: true,
                    label: 'mountain climbing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0cf1c487-66cf-48e9-aafd-3cf215c96564',
                    notForTagging: false,
                    label: 'moviegoing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/19c15c40-6a3e-45aa-ba71-64e00f89daea',
                    notForTagging: false,
                    label: 'mutilation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/624e8a0a-33c7-7218-3784-87b8464786b4',
                    notForTagging: false,
                    label: 'packing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3285c310-c624-424c-80e6-b62cb6b83390',
                    notForTagging: false,
                    label: 'partying',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8017e665-94bc-48c8-a30a-2f9c049c051a',
                    notForTagging: false,
                    label: 'performing an illusion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/db3ad9c4-1cfd-c846-a973-89c60c552686',
                    notForTagging: false,
                    label: 'photography (activity)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3a067761-2a97-4a4c-aa33-4075f03f0192',
                    notForTagging: false,
                    label: 'praying',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5c6b8f5f-3520-4f44-8b95-3bc08d09f0b8',
                    notForTagging: false,
                    label: 'promoting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9bc44efb-5b4a-4205-93b9-c83aeb61b885',
                    notForTagging: false,
                    label: 'public speaking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/004d208b-ef44-63c2-5404-84432830349f',
                    notForTagging: false,
                    label: 'push',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ae361a7b-f1ce-d893-541a-ed476f675d8c',
                    notForTagging: false,
                    label: 'reading',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d30305b1-dfb6-4d3d-9d29-4502f6a7eac9',
                    notForTagging: true,
                    label: 'recycling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/add90f04-a176-4ba9-818e-2f1d53b15257',
                    notForTagging: false,
                    label: 'repairing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8aff82dc-83ff-428e-a39c-e12b093ea8c5',
                    notForTagging: false,
                    label: 'research',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9b40a824-36d0-42eb-9a46-2046bb8cff6a',
                    notForTagging: false,
                    label: 'resuscitation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3e696563-4f50-4175-8c8b-2dbe07d786b9',
                    notForTagging: false,
                    label: 'riding',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/98980b32-5d80-4f87-9462-6c0cc7e156e0',
                            notForTagging: false,
                            label: 'horseback riding',
                            broaderId:
                                'http://data.disney.com/resources/3e696563-4f50-4175-8c8b-2dbe07d786b9',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/43389580-d398-9497-0f63-0354aa83da9d',
                    notForTagging: false,
                    label: 'running',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b148f1a5-78be-5827-3d02-2623f8aab38e',
                    notForTagging: false,
                    label: 'scold',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0850dc7f-8187-49d2-bdd2-9a3a5bc9f6c6',
                    notForTagging: false,
                    label: 'search and rescue operations',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e8e3de31-a556-4b3d-8a49-cb2e46f394a5',
                    notForTagging: true,
                    label: 'security measures',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b182f074-d470-4f6a-ba20-93e6f46f6ca9',
                    notForTagging: false,
                    label: 'sentencing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/26ac89b2-3964-46fc-8401-cace633bd821',
                    notForTagging: false,
                    label: 'sex (activity)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/533b170a-fc53-f880-e90c-17e2c041e88a',
                    notForTagging: false,
                    label: 'shopping (activity)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9b36c1d0-cdef-4760-bc0e-6c7dc5445282',
                    notForTagging: false,
                    label: 'sighing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/583695ef-a4fc-c7f1-945d-d7b96b8b2d83',
                    notForTagging: false,
                    label: 'singing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dffe8b22-24fe-475f-88a8-5e3854fc7097',
                    notForTagging: false,
                    label: 'skateboarding',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4f42dec9-4b3d-19ae-b725-90404d68f28b',
                    notForTagging: false,
                    label: 'slapping',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/404785e7-9a48-e934-44d5-8e6c9dfe57a6',
                    notForTagging: false,
                    label: 'sleeping',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bffd9329-8ad8-43e7-adf5-005dfdcaf61e',
                    notForTagging: false,
                    label: 'smoking',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/2f05efc8-5a5a-46e3-944d-3211443a0050',
                            notForTagging: false,
                            label: 'vaping',
                            broaderId:
                                'http://data.disney.com/resources/bffd9329-8ad8-43e7-adf5-005dfdcaf61e',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/d1092400-1979-4978-b3e7-1bb3d1ec8ad2',
                    notForTagging: false,
                    label: 'snuggle',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/21a93de9-6621-4b89-ba1d-6be886e09220',
                    notForTagging: false,
                    label: 'speed contest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e49f2814-64bc-4981-bcfb-c560a4122757',
                    notForTagging: false,
                    label: 'spitting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                    notForTagging: true,
                    label: 'sports (activity)',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/31e42429-c735-4c21-b26f-c52ec916cda5',
                            notForTagging: false,
                            label: 'automobile racing',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/273a886f-6f6f-c27f-083a-796943b74797',
                            notForTagging: false,
                            label: 'soccer',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5d38de1b-d1b0-7386-9e58-ff0f97e502bf',
                            notForTagging: true,
                            label: 'tennis',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4236dbac-ade4-6969-60ea-b068aa110e88',
                            notForTagging: false,
                            label: 'basketball',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9fe45bc7-29ab-4ef3-8bea-cd36d630141d',
                            notForTagging: false,
                            label: 'gymnastics',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/274a45ff-24e0-2856-2968-7d0b36c7c7b0',
                            notForTagging: true,
                            label: 'snowboarding',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a33ce7cd-d915-2df4-e40a-c6b109488da7',
                            notForTagging: false,
                            label: 'baseball',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0abd20cb-e703-4b1c-a3b1-37cb239bee3d',
                            notForTagging: false,
                            label: 'martial arts',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/733e6c0e-ecb5-6f66-7b7c-9fccb2c20792',
                            notForTagging: false,
                            label: 'volleyball',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7295d662-355b-45e5-b6be-441224a127db',
                            notForTagging: false,
                            label: 'mixed martial arts',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fc4a0c4c-9e6e-2b24-fbd0-2a4ff0af788c',
                            notForTagging: false,
                            label: 'skiing',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9d32dcdb-50df-1e5e-31bb-0203345d28b6',
                            notForTagging: false,
                            label: 'football',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/413c62e3-58bc-c0db-cbb4-9872a86f468e',
                            notForTagging: false,
                            label: 'ice skating',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ed3c1613-8032-4577-8731-dafac3f9b980',
                            notForTagging: false,
                            label: 'boxing',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c3afe7f5-13bb-4eca-b4f9-8bc5483b74c6',
                            notForTagging: false,
                            label: 'roller skating',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1635b994-f757-a9c6-38ac-e1a00cd03ba4',
                            notForTagging: false,
                            label: 'hockey',
                            broaderId:
                                'http://data.disney.com/resources/a23bb9ec-724f-585c-73a0-8cd3198acda6',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/f3cfdb42-8ad3-4761-a9ea-351f186f16ad',
                    notForTagging: false,
                    label: 'spying',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d27471f9-888c-4e99-993f-0c336e27c2e2',
                    notForTagging: false,
                    label: 'stabbing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7d4d2e39-8b1c-37c4-344e-7b8c74971ca0',
                    notForTagging: false,
                    label: 'studying',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/16a17aff-2478-4597-92a1-0afcdf50ec86',
                    notForTagging: false,
                    label: 'sunbathing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9b9bcfa2-3324-4851-953f-666af4b77264',
                    notForTagging: false,
                    label: 'talking on the phone',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/183b6ce3-ac55-499b-b221-d9e96ca87f29',
                            notForTagging: true,
                            label: 'telephone call, unanswered',
                            broaderId:
                                'http://data.disney.com/resources/9b9bcfa2-3324-4851-953f-666af4b77264',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/1e39b7ab-ca77-0f97-a6b2-900b59b15391',
                    notForTagging: false,
                    label: 'teaching',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/77e14e78-9682-4c9a-a086-dac6e54d043a',
                    notForTagging: false,
                    label: 'toasting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d080385d-45cc-4b78-a41b-297e18fb93d0',
                    notForTagging: false,
                    label: 'torture',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b2c0bf53-bd1e-4efe-8043-8b7a291d123a',
                    notForTagging: true,
                    label: 'towing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/03d4e0dc-1df9-4e9e-bb0a-f5413453557f',
                    notForTagging: false,
                    label: 'traveling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b61a2c9c-3f8c-4aa6-baf0-0dfa68a0514c',
                    notForTagging: false,
                    label: 'typing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/284c59a0-3726-4b3c-a12e-e22a385fcb52',
                    notForTagging: false,
                    label: 'using magic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7936d4c4-5d00-43ad-b468-c8874560804a',
                    notForTagging: false,
                    label: 'video recording',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8cbb5003-e610-4298-9c78-00116490b532',
                    notForTagging: true,
                    label: 'volunteering',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/67d8c3ce-5c18-4b45-8ae1-0078c5bf10a1',
                    notForTagging: false,
                    label: 'vomiting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d435a840-05d0-30d5-20dd-7cb25f70cdbf',
                    notForTagging: true,
                    label: 'voting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/02360d16-1632-8691-e203-e82dbb332191',
                    notForTagging: true,
                    label: 'wine tasting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6e437332-c16d-3be5-3226-8427085e8ba9',
                    notForTagging: true,
                    label: 'wrestling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f461b4ee-21e4-4053-83a8-b16620c8ce23',
                    notForTagging: false,
                    label: 'writing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c232fc4d-1158-4d1c-1d5a-4e3ba0659aa6',
                    notForTagging: false,
                    label: 'yawning',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1af9ee8f-1c88-4223-8073-99b39123ed47',
                    notForTagging: false,
                    label: 'yoga',
                    terms: [],
                },
            ],
        },
        {
            label: 'Age Groups',
            type: 'http://data.disney.com/resources/AgeGroups',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/2b758766-5026-4bb2-86cf-c2763bfc250b',
                    notForTagging: false,
                    label: 'adolescent/tween (11-13)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6366b8ef-5ea2-4a12-ad6e-797bacbdffdc',
                    notForTagging: false,
                    label: 'adult',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/193b46f6-8d3c-44d9-8543-011017fe6377',
                    notForTagging: false,
                    label: 'age groups test 4',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e22734e4-89c2-41ce-9ba5-1a115e968910',
                    notForTagging: false,
                    label: 'baby/infant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ccac1fdb-4b31-4f6c-93b8-32cc44ba0006',
                    notForTagging: false,
                    label: 'big kid (6-10)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/54c44928-68cb-4082-a126-89ff5ad1b6b9',
                    notForTagging: false,
                    label: 'indeterminate',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/94a5d17b-4331-4279-8c99-a9c58b138de3',
                    notForTagging: false,
                    label: 'older person (retirees)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8f371402-1e7a-444a-b8c9-d7300bf8211f',
                    notForTagging: false,
                    label: 'teen (14-18)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5c25e08b-377b-45e1-bde7-e2bb2dd7d5cb',
                    notForTagging: false,
                    label: 'young adult (19-25)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c56489fb-7dda-48b5-88e7-7134d013af58',
                    notForTagging: false,
                    label: 'young child (2-5)',
                    terms: [],
                },
            ],
        },
        {
            label: 'Agents',
            type: 'http://data.disney.com/resources/Agents',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/45359248-e524-1f2c-43dd-de762742b0ac',
                    notForTagging: true,
                    label: 'Animals',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/674c491f-cb1a-dd1e-4d89-b9d4786706b9',
                    notForTagging: true,
                    label: 'Fictitious beings',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4a35f735-6e74-16dc-1c18-62dbebd22db4',
                    notForTagging: true,
                    label: 'Groups of people',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5e200b87-7e36-4f69-8ca1-a50aa97912cd',
                    notForTagging: true,
                    label: 'Objects (Agents)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e1309b69-debf-feda-53f6-b8d492623eb1',
                    notForTagging: true,
                    label: 'People',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/173d447a-a14c-6db7-4d19-fa5a66ca678b',
                    notForTagging: true,
                    label: 'People associated with religion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7f3e0cb0-771d-cebb-8339-d65364d3a2a8',
                    notForTagging: true,
                    label: 'People by activity',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/db3b901e-af92-1602-ff1b-023c10efd89f',
                    notForTagging: true,
                    label: 'People by occupation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/aa370e55-ee95-c2c7-8835-52e2f2958191',
                    notForTagging: true,
                    label: 'People by state or condition',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e8331021-57e0-b636-1f0a-a1f3fe5a8d90',
                    notForTagging: true,
                    label: 'People in business',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/483df1d7-e718-680d-5c70-7292f9f7b88e',
                    notForTagging: true,
                    label: 'People in government and public administration',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/253e7248-01db-3435-8101-16a29ba51580',
                    notForTagging: true,
                    label: 'People in medicine and science',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ff37a60a-1b10-a083-2f29-2f9b3d40bb9b',
                    notForTagging: true,
                    label: 'People in security and public safety',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4b384809-931e-3ed2-1384-adb54edefca6',
                    notForTagging: true,
                    label: 'People in sports',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/54304497-5e5a-f1a8-850c-fb13aea266b9',
                    notForTagging: true,
                    label: 'People in the arts and entertainment',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0834a9ca-19f3-15e5-4ee1-d22735d367a7',
                    notForTagging: true,
                    label: 'People in the military',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fb3b3c15-4c35-49a4-4c10-6f0224827c90',
                    notForTagging: true,
                    label: 'People in the service sector',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0dd7b613-d45a-408b-9233-957e15a527b1',
                    notForTagging: false,
                    label: 'activist',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/45cf2e75-deff-49fe-b391-9af69a69c946',
                            notForTagging: false,
                            label: 'civil rights advocate',
                            broaderId:
                                'http://data.disney.com/resources/0dd7b613-d45a-408b-9233-957e15a527b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0c653459-12a7-46f7-87c4-ccf583c2c585',
                            notForTagging: false,
                            label: 'feminist',
                            broaderId:
                                'http://data.disney.com/resources/0dd7b613-d45a-408b-9233-957e15a527b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/43495899-0587-4530-8154-f7055a1d2c75',
                            notForTagging: false,
                            label: 'LGBTQIA+ rights advocate',
                            broaderId:
                                'http://data.disney.com/resources/0dd7b613-d45a-408b-9233-957e15a527b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c907d619-f9ab-4439-af7f-cae6e8d3bef3',
                            notForTagging: false,
                            label: 'animal rights advocate',
                            broaderId:
                                'http://data.disney.com/resources/0dd7b613-d45a-408b-9233-957e15a527b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c8565b40-856f-4f1d-a7a2-c8c9f8db72cb',
                            notForTagging: false,
                            label: 'voting rights advocate',
                            broaderId:
                                'http://data.disney.com/resources/0dd7b613-d45a-408b-9233-957e15a527b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d1e4171b-2833-4ad7-936b-c7ff206bf840',
                            notForTagging: false,
                            label: 'peace advocate',
                            broaderId:
                                'http://data.disney.com/resources/0dd7b613-d45a-408b-9233-957e15a527b1',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/984a3089-4ca9-4a3c-b59b-04df716e9d11',
                    notForTagging: false,
                    label: 'addict',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/96319082-ac68-e4d8-c2f0-9e4c4b34569e',
                    notForTagging: true,
                    label: 'adult',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/726493a7-9ebd-4b7c-bc00-4657b3dfd268',
                    notForTagging: false,
                    label: 'agent with disability',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/05d2f32f-98bd-4a6d-b432-3f883ef70683',
                            notForTagging: false,
                            label: 'agent with mobility disability',
                            broaderId:
                                'http://data.disney.com/resources/726493a7-9ebd-4b7c-bc00-4657b3dfd268',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ffa13f85-b8aa-4acd-b173-7ac7891ca7e0',
                            notForTagging: false,
                            label: 'agent who is blind',
                            broaderId:
                                'http://data.disney.com/resources/726493a7-9ebd-4b7c-bc00-4657b3dfd268',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d5dbb38f-a158-4102-b091-d9156c2999d8',
                            notForTagging: false,
                            label: 'agent who is deaf',
                            broaderId:
                                'http://data.disney.com/resources/726493a7-9ebd-4b7c-bc00-4657b3dfd268',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/dba66711-38c5-4394-b3f1-7dd7c0d7b4f8',
                    notForTagging: false,
                    label: 'agent with mental health disorder',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/94b6ae8f-a980-418d-bbca-6852d8c9a8c6',
                    notForTagging: true,
                    label: 'alligator',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b13654ee-35a3-95ee-6acf-7c3eb8e33bba',
                    notForTagging: false,
                    label: 'android',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                    notForTagging: false,
                    label: 'animal',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/a8803110-21ca-4150-980f-46fd5e9e1a8e',
                            notForTagging: false,
                            label: 'hippopotamus',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7b9a701a-4b29-4b1e-9b18-374cdaf76b51',
                            notForTagging: true,
                            label: 'bees',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1da3d09d-5b4c-494e-831b-1ddb8ed485aa',
                            notForTagging: false,
                            label: 'panda',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1b14d349-4f5c-4909-ab44-f79c8336aa58',
                            notForTagging: false,
                            label: 'fish',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f94b1e12-c02b-47ce-9e22-1e4d5f9108f1',
                            notForTagging: true,
                            label: 'mosquitos',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/902bf830-3479-436f-ae8f-ee682f961966',
                            notForTagging: false,
                            label: 'giraffe',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/537ba59d-2a59-4f4d-9736-a39f40dd4c72',
                            notForTagging: false,
                            label: 'bee',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/00392569-1485-40bd-8f93-f362a8cdc81b',
                            notForTagging: false,
                            label: 'octopus',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d58d5e8d-5f3f-4a78-a962-06874ca555ec',
                            notForTagging: false,
                            label: 'bird',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/36e69896-67d0-4367-b2cd-c905a732dfb7',
                            notForTagging: false,
                            label: 'bear',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e88645ad-e295-4dd2-a5b3-49d9dd29d3b7',
                            notForTagging: false,
                            label: 'hyena',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2031f71c-2e1b-4ddb-87b8-cb82e24dbee9',
                            notForTagging: false,
                            label: 'rabbit',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bcd01f2e-ff37-473c-8bb4-dd2ce52c8f25',
                            notForTagging: false,
                            label: 'squirrel',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c679b5fe-db15-4a54-b552-d6ee19bd72fe',
                            notForTagging: false,
                            label: 'raccoon',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e804bcde-17e2-4f52-b83d-a0364a3b8f8a',
                            notForTagging: false,
                            label: 'coyote',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4e1d7b2c-d797-41bf-930f-9877965b181c',
                            notForTagging: false,
                            label: 'lion',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dd026f00-4140-4d69-b057-d1182b99bcb2',
                            notForTagging: true,
                            label: 'primate',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/394be45b-32e5-4c29-ab7d-2f66dd50b481',
                            notForTagging: false,
                            label: 'reptile',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/183a42db-66c6-a252-d3c1-311d7824da9c',
                            notForTagging: false,
                            label: 'dog',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4fb47568-6895-4c43-af65-6f0904657b40',
                            notForTagging: false,
                            label: 'jellyfish',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/01e41342-af69-400a-ae11-fbc6521c491a',
                            notForTagging: false,
                            label: 'tiger',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b7b6c541-bd8f-4bf6-9933-f6edd8387a08',
                            notForTagging: false,
                            label: 'whale',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c670a85f-750c-42f5-9822-82cbc722919d',
                            notForTagging: true,
                            label: 'bovine',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d91d1745-d7dd-4df8-a07a-0bfee172faea',
                            notForTagging: false,
                            label: 'wild cat',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e2bfa46e-3c03-4154-b250-2a69c363ec28',
                            notForTagging: false,
                            label: 'fox',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/603a7dab-299e-4de1-9ee4-3870e9da3148',
                            notForTagging: false,
                            label: 'elephant',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/792d339c-404d-4759-8d73-067357b7f154',
                            notForTagging: false,
                            label: 'rat',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fe4550eb-0d09-499e-9474-fab1317f1613',
                            notForTagging: false,
                            label: 'cat',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/15bcb205-0142-4fcc-96b8-d9e246dd94b5',
                            notForTagging: false,
                            label: 'deer',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bb8ada77-3a0b-4778-a8d7-fd7f2e5eb97b',
                            notForTagging: false,
                            label: 'dinosaur',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c98a5f93-fa59-40ac-ad4c-34619fc848cd',
                            notForTagging: false,
                            label: 'starfish',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c8f7817a-dd24-4a0c-8b3b-0a89dbab9454',
                            notForTagging: false,
                            label: 'wolf',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/19249508-588a-4a7f-a773-d6c199690f64',
                            notForTagging: false,
                            label: 'goat',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4ef71e08-9d1b-4578-b667-5bc9cff26c92',
                            notForTagging: false,
                            label: 'mouse',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a851a64d-7699-4a47-bd76-1f9640e24bfc',
                            notForTagging: false,
                            label: 'seal',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7aca3878-6505-419a-ba59-1d472716014a',
                            notForTagging: false,
                            label: 'spider',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1434aee0-ed9b-48f0-8727-8c34bc0b2060',
                            notForTagging: false,
                            label: 'snake',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a7ff674d-63ed-4c0a-ba6d-abc9cc4936c8',
                            notForTagging: false,
                            label: 'mosquito',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9c5e0ab4-cb31-4fb4-8752-ec3d57d20491',
                            notForTagging: false,
                            label: 'monkey',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a048ce8a-6f89-4d21-a365-334b0d982052',
                            notForTagging: false,
                            label: 'pet',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/19b9d385-6b8a-4d8e-98a2-83fd171e1e4d',
                            notForTagging: false,
                            label: 'cow',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/43419aa6-7031-4e00-9340-2a129b046aa5',
                            notForTagging: false,
                            label: 'turtle',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a4f505f2-f877-4ab7-be09-abacc1f5d48b',
                            notForTagging: false,
                            label: 'pig',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/70be713a-68e1-4c84-a09f-907cb3e88af0',
                            notForTagging: false,
                            label: 'sheep',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/01ae16cb-30be-4213-a4f5-4838e6723bae',
                            notForTagging: false,
                            label: 'horse',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7518a1a1-8067-4c96-8365-a2e7898f3adb',
                            notForTagging: true,
                            label: 'swine',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d9994265-1482-485a-8fb1-595d9952b0d7',
                            notForTagging: false,
                            label: 'shellfish',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/573b793a-4159-47a1-af43-a325e241046b',
                            notForTagging: false,
                            label: 'crocodile',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f9ce188a-44e8-40f5-9e5d-facbac3dd599',
                            notForTagging: false,
                            label: 'ape',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/70de49d9-1bbb-4964-9606-71fddb0a953d',
                            notForTagging: false,
                            label: 'wild animal',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d3badc47-f23b-4e92-83d6-9119b2b2b6dc',
                            notForTagging: false,
                            label: 'frog',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/87d21286-3896-4eac-9432-345fa79f34f3',
                            notForTagging: false,
                            label: 'insect',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/32974784-7360-450e-bc08-35b287e03feb',
                            notForTagging: false,
                            label: 'shark',
                            broaderId:
                                'http://data.disney.com/resources/78b2cbe2-c5fa-4c81-86ef-c4e36263e244',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/184dad4a-fc50-258d-46e9-43c12a32568d',
                    notForTagging: false,
                    label: 'animal trainer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/01629359-245d-45d0-8277-12b026057707',
                    notForTagging: false,
                    label: 'archaeologist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1132cb30-148a-da98-07f4-1d73b640cba5',
                    notForTagging: false,
                    label: 'artist',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/c3774c56-14ee-42db-9003-cc76f2713387',
                            notForTagging: false,
                            label: 'costume designer',
                            broaderId:
                                'http://data.disney.com/resources/1132cb30-148a-da98-07f4-1d73b640cba5',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410508e-c02c-7d26-9c75-0768b3f8bc8a',
                            notForTagging: false,
                            label: 'writer',
                            broaderId:
                                'http://data.disney.com/resources/1132cb30-148a-da98-07f4-1d73b640cba5',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1135d104-1e95-b9c1-1be8-5160334ea480',
                            notForTagging: false,
                            label: 'animator',
                            broaderId:
                                'http://data.disney.com/resources/1132cb30-148a-da98-07f4-1d73b640cba5',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c03b2d75-52ad-f2b8-6dbe-0fc5e4ecefac',
                            notForTagging: false,
                            label: 'composer',
                            broaderId:
                                'http://data.disney.com/resources/1132cb30-148a-da98-07f4-1d73b640cba5',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/041005c1-a053-64cd-9c1b-aa8195f5b887',
                            notForTagging: false,
                            label: 'editor',
                            broaderId:
                                'http://data.disney.com/resources/1132cb30-148a-da98-07f4-1d73b640cba5',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/263908e9-0dda-b97a-1cb2-2c3e77ae92a1',
                            notForTagging: false,
                            label: 'photographer',
                            broaderId:
                                'http://data.disney.com/resources/1132cb30-148a-da98-07f4-1d73b640cba5',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c9ff7fd2-a608-4714-8996-8b7d496057f7',
                            notForTagging: false,
                            label: 'fashion designer',
                            broaderId:
                                'http://data.disney.com/resources/1132cb30-148a-da98-07f4-1d73b640cba5',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b83d7911-2507-5485-e668-3e6b3a7bf987',
                            notForTagging: false,
                            label: 'lyricist',
                            broaderId:
                                'http://data.disney.com/resources/1132cb30-148a-da98-07f4-1d73b640cba5',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6631ed47-2911-3052-4284-01d014a59dba',
                            notForTagging: false,
                            label: 'architect',
                            broaderId:
                                'http://data.disney.com/resources/1132cb30-148a-da98-07f4-1d73b640cba5',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/04102991-4822-8e25-9c72-f07774cba982',
                    notForTagging: false,
                    label: 'assistant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3634f8ff-0a6f-2bb8-19ae-6c58da6782bc',
                    notForTagging: false,
                    label: 'astronaut',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                    notForTagging: false,
                    label: 'athlete',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/78422907-20eb-14cb-2f48-bf17932081a3',
                            notForTagging: false,
                            label: 'tennis player',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f34919c2-5bac-aa89-2b8a-6f7e37b8b7a9',
                            notForTagging: false,
                            label: 'baseball player',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8ba3b629-9a3e-4334-a6c9-7973fafc3110',
                            notForTagging: false,
                            label: 'ice skater',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/574df04b-5762-825d-3d74-614d55d32c89',
                            notForTagging: true,
                            label: 'automobile racing driver',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a543bca0-18aa-58db-ba88-fd5b8be327be',
                            notForTagging: false,
                            label: 'basketball player',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/98464657-75ac-e343-92a4-73e045438889',
                            notForTagging: true,
                            label: 'goalkeeper',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7c2a8d21-db68-47ff-b764-37ef0d51b42a',
                            notForTagging: false,
                            label: 'cyclist',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4247aed4-41b1-66bb-8a54-a2bf027eabb0',
                            notForTagging: false,
                            label: 'soccer player',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8b6d6f33-8c31-41e8-b582-f36dacb4da4a',
                            notForTagging: false,
                            label: 'diver',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b5460fbb-35da-d262-3a18-f08bc25d009f',
                            notForTagging: true,
                            label: 'ice hockey player',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c04a2d1b-73f8-4043-983f-4fe1926c38eb',
                            notForTagging: false,
                            label: 'boxer',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/19418fc0-e792-43e3-014e-f6c84f2ba3b4',
                            notForTagging: false,
                            label: 'football player',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/28c1d1bc-25b7-4ccd-af66-2dac174d4179',
                            notForTagging: false,
                            label: 'hockey player',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d34f9ea8-0ad0-4169-8057-32802c08bc3d',
                            notForTagging: false,
                            label: 'snowboarder',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8e159738-dd4e-4328-ab0f-47a9bb0f98fe',
                            notForTagging: false,
                            label: 'sky diver',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7d4550e6-975a-9163-5832-afe25f1d9c8f',
                            notForTagging: false,
                            label: 'bullfighter',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a5688e49-b9f3-49fa-8f8e-216318058053',
                            notForTagging: false,
                            label: 'skateboarder',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5ee5ca54-9c49-4f85-bf31-c77198e5a9df',
                            notForTagging: false,
                            label: 'racecar driver',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7aedc58e-6510-448b-b763-a3e36ebf8e73',
                            notForTagging: false,
                            label: 'surfer',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/99643c2c-bb9a-4174-83fb-35525fafb2f6',
                            notForTagging: false,
                            label: 'mountaineer',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/aee74287-626f-47e7-88de-18e5eeaf4b16',
                            notForTagging: false,
                            label: 'cheerleader',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bc5ae75f-123c-4a4d-84dc-ec2a242eea84',
                            notForTagging: false,
                            label: 'martial artist',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/03972edc-a55a-4115-88d8-6f94dd92e885',
                            notForTagging: false,
                            label: 'skier',
                            broaderId:
                                'http://data.disney.com/resources/9437bf84-facc-ae47-af5c-0bf4490808b1',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/a63a894d-bcff-4ffe-b5c8-60f79c1085b7',
                    notForTagging: true,
                    label: 'baby animal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fd0a5267-7378-45e1-bc82-dfa9d8b78821',
                    notForTagging: true,
                    label: 'baby boomer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6a35f683-0604-425a-bbd0-2b8636cde10d',
                    notForTagging: false,
                    label: 'baggage handler',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3c4da195-8f11-256b-7f0e-d3436ed1b39f',
                    notForTagging: false,
                    label: 'bounty hunter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f59d3e5e-4c07-47db-ace1-fe5f22589120',
                    notForTagging: true,
                    label: 'boyfriend',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d0341d5b-23b7-ada4-d8d7-94a28e44d2be',
                    notForTagging: false,
                    label: 'bride',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                    notForTagging: false,
                    label: 'business person',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/c8421b9d-9965-5a74-eea4-fd12c0989dae',
                            notForTagging: false,
                            label: 'supervisor',
                            broaderId:
                                'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cb054e6e-3893-4b7a-893e-b25d9f7802fb',
                            notForTagging: false,
                            label: 'accountant',
                            broaderId:
                                'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/70450fc1-ed3e-4913-bec5-e02268258277',
                            notForTagging: false,
                            label: 'manager',
                            broaderId:
                                'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e74a7057-9937-0b04-fbc6-a668377259ad',
                            notForTagging: false,
                            label: 'auctioneer',
                            broaderId:
                                'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2df21b1b-d384-4742-be12-f74ac2554157',
                            notForTagging: false,
                            label: 'business owner',
                            broaderId:
                                'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e049bcbd-98e0-45f3-bfc4-f446b6f446c0',
                            notForTagging: false,
                            label: 'boss/executive',
                            broaderId:
                                'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a8050db8-b195-471a-ad43-7763284b549f',
                            notForTagging: false,
                            label: 'advisor',
                            broaderId:
                                'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e331d382-16d5-622c-11f0-09a410bbaea9',
                            notForTagging: false,
                            label: 'salesperson',
                            broaderId:
                                'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/82c5808d-8e77-4473-8a5e-4176f9eb8cbd',
                            notForTagging: false,
                            label: 'investor',
                            broaderId:
                                'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1086c4e3-2b3b-4eae-9391-22c876399f3c',
                            notForTagging: false,
                            label: 'banker',
                            broaderId:
                                'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b5ecb84d-5022-4d0d-934d-daab61e38cfe',
                            notForTagging: false,
                            label: 'advertising executive',
                            broaderId:
                                'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1c3a8edd-d678-5625-b3c5-8eb6f647bb89',
                            notForTagging: false,
                            label: 'office worker',
                            broaderId:
                                'http://data.disney.com/resources/d949fe3f-ea35-f7ef-de65-ef0c5a7a42b9',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/6640eaea-6b94-4691-a925-3d059bfebd46',
                    notForTagging: false,
                    label: 'camp counselor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0c9319ac-51ea-4c5f-a188-4c9a82287811',
                    notForTagging: false,
                    label: 'camper',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9f133736-a13e-4567-a46e-0856f59bd0df',
                    notForTagging: false,
                    label: 'captive',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/0129a12c-97ea-4f9e-a5b2-3e450ae22d15',
                            notForTagging: false,
                            label: 'hostage',
                            broaderId:
                                'http://data.disney.com/resources/9f133736-a13e-4567-a46e-0856f59bd0df',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b77345e9-a910-4f47-a596-1f27db12c83c',
                            notForTagging: false,
                            label: 'enslaved person',
                            broaderId:
                                'http://data.disney.com/resources/9f133736-a13e-4567-a46e-0856f59bd0df',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/49d0ceec-b2c1-40f7-8b80-450bfed20ae9',
                            notForTagging: true,
                            label: 'slave',
                            broaderId:
                                'http://data.disney.com/resources/9f133736-a13e-4567-a46e-0856f59bd0df',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/03497317-64fa-bbbf-3840-8456bd3b7889',
                            notForTagging: false,
                            label: 'prisoner',
                            broaderId:
                                'http://data.disney.com/resources/9f133736-a13e-4567-a46e-0856f59bd0df',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/124b7d77-283a-7d28-e3d7-92a1cd570aa4',
                    notForTagging: true,
                    label: 'catcher (baseball)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e53971db-055e-f508-9516-2eb0ebefd9bb',
                    notForTagging: false,
                    label: 'cave dweller',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/79399a23-26ee-6152-126d-8214b075b0ae',
                    notForTagging: true,
                    label: 'child',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/813cf917-ac22-46cc-b109-192fa17e4648',
                    notForTagging: false,
                    label: 'clone',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f6daf416-34b9-466c-97f3-d1d620f3e590',
                    notForTagging: false,
                    label: 'coach',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/78214173-5b20-49e9-ae42-340d04274ab6',
                            notForTagging: false,
                            label: 'personal trainer',
                            broaderId:
                                'http://data.disney.com/resources/f6daf416-34b9-466c-97f3-d1d620f3e590',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/17495e3e-f9ab-b8d8-0d7e-6f7a0902349b',
                    notForTagging: true,
                    label: 'coach (people)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/49522780-e0e8-4587-a015-a2ff0779af2a',
                    notForTagging: false,
                    label: 'collector',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/52841987-0465-4eb4-8b05-5bc584b92625',
                    notForTagging: false,
                    label: 'consumer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9c657adf-d713-4add-9deb-ec7770e52a1f',
                    notForTagging: false,
                    label: 'contestant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4633b7b4-5131-429e-c284-5622f0937496',
                    notForTagging: false,
                    label: 'cowboy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4741e0c1-ac21-7b6e-e400-c5ae7edbaeaa',
                    notForTagging: false,
                    label: 'cowgirl',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8f0ab822-b471-4b38-8a42-79f1a63058da',
                    notForTagging: false,
                    label: 'craftsperson',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/b7ea72ae-3419-48af-b35b-bf8920c7d570',
                            notForTagging: false,
                            label: 'tailor',
                            broaderId:
                                'http://data.disney.com/resources/8f0ab822-b471-4b38-8a42-79f1a63058da',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/773d0907-19cd-e1db-ca32-41280b1bf785',
                            notForTagging: false,
                            label: 'carpenter',
                            broaderId:
                                'http://data.disney.com/resources/8f0ab822-b471-4b38-8a42-79f1a63058da',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c7e2ee75-9310-4770-aad8-e60a92bec338',
                            notForTagging: false,
                            label: 'sewer',
                            broaderId:
                                'http://data.disney.com/resources/8f0ab822-b471-4b38-8a42-79f1a63058da',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d2cddb4e-7d08-44c7-9b5c-d830ce6e24ac',
                            notForTagging: false,
                            label: 'shoemaker',
                            broaderId:
                                'http://data.disney.com/resources/8f0ab822-b471-4b38-8a42-79f1a63058da',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410408e-f0e7-8821-9c75-0768b3f85e8a',
                            notForTagging: false,
                            label: 'apprentice',
                            broaderId:
                                'http://data.disney.com/resources/8f0ab822-b471-4b38-8a42-79f1a63058da',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/d63e35d6-c368-b890-6fff-ea9cc7d9349c',
                    notForTagging: false,
                    label: 'criminal',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/2b67d1fd-d91c-4b63-9727-120c73e100ed',
                            notForTagging: false,
                            label: 'murderer',
                            broaderId:
                                'http://data.disney.com/resources/d63e35d6-c368-b890-6fff-ea9cc7d9349c',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/558e260a-9103-41f7-8164-b2b44b8234d1',
                                    notForTagging: false,
                                    label: 'assassin',
                                    broaderId:
                                        'http://data.disney.com/resources/2b67d1fd-d91c-4b63-9727-120c73e100ed',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/e8fcb191-9146-42f2-adb3-b59985956959',
                                    notForTagging: false,
                                    label: 'serial murderer',
                                    broaderId:
                                        'http://data.disney.com/resources/2b67d1fd-d91c-4b63-9727-120c73e100ed',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/a0aa3152-708a-4971-a999-356c53bc0d47',
                            notForTagging: false,
                            label: 'fugitive from justice',
                            broaderId:
                                'http://data.disney.com/resources/d63e35d6-c368-b890-6fff-ea9cc7d9349c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c7f0b8ef-bd27-459c-96f7-820be76bdbac',
                            notForTagging: false,
                            label: 'terrorist',
                            broaderId:
                                'http://data.disney.com/resources/d63e35d6-c368-b890-6fff-ea9cc7d9349c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/90e20a27-9045-4e6b-b447-08c2da73cd0c',
                            notForTagging: false,
                            label: 'arsonist',
                            broaderId:
                                'http://data.disney.com/resources/d63e35d6-c368-b890-6fff-ea9cc7d9349c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/93907c2f-1ac3-43fb-9c6b-2851858425f2',
                            notForTagging: false,
                            label: 'con artist',
                            broaderId:
                                'http://data.disney.com/resources/d63e35d6-c368-b890-6fff-ea9cc7d9349c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a7309d6d-0610-4ef3-b5ce-64f60727dfb9',
                            notForTagging: false,
                            label: 'thief',
                            broaderId:
                                'http://data.disney.com/resources/d63e35d6-c368-b890-6fff-ea9cc7d9349c',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/285c7239-5e89-4150-9831-ffbc7140d885',
                                    notForTagging: false,
                                    label: 'embezzler',
                                    broaderId:
                                        'http://data.disney.com/resources/a7309d6d-0610-4ef3-b5ce-64f60727dfb9',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/b531444e-e084-4597-8cdb-c46ba6fc63d9',
                            notForTagging: false,
                            label: 'suspect',
                            broaderId:
                                'http://data.disney.com/resources/d63e35d6-c368-b890-6fff-ea9cc7d9349c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/13479f04-fb37-c18f-fbda-90fbf4eb0e81',
                            notForTagging: false,
                            label: 'pirate',
                            broaderId:
                                'http://data.disney.com/resources/d63e35d6-c368-b890-6fff-ea9cc7d9349c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f86cba4e-9355-4f47-8e85-d5f8934e8e11',
                            notForTagging: false,
                            label: 'stalker',
                            broaderId:
                                'http://data.disney.com/resources/d63e35d6-c368-b890-6fff-ea9cc7d9349c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d5836c9e-2d94-4bc7-aa2a-bbef9ad38b3e',
                            notForTagging: false,
                            label: 'crime lord',
                            broaderId:
                                'http://data.disney.com/resources/d63e35d6-c368-b890-6fff-ea9cc7d9349c',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/2d4eee63-012a-398b-151f-69266cd2699c',
                    notForTagging: false,
                    label: 'cyborg',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0c63c3a0-3057-4c3a-bd6a-ca45230b97c5',
                    notForTagging: false,
                    label: 'dead person',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/973e030f-b950-496c-d269-e2c64b1618ab',
                    notForTagging: false,
                    label: 'dwarf',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8234019a-404b-4517-a70f-ac890449d04e',
                    notForTagging: false,
                    label: 'engineer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                    notForTagging: false,
                    label: 'entertainer',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/44334bef-6fe2-5c77-0307-df38614081ae',
                            notForTagging: false,
                            label: 'child actor',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/95cfef2e-0bac-4dac-af7c-a8ad9692d1f6',
                            notForTagging: false,
                            label: 'stunt person',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b1307206-b401-b07d-7527-89ad0af268b8',
                            notForTagging: false,
                            label: 'choreographer',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1f3a13bd-7e13-0a5d-3b51-6fead7ffadaa',
                            notForTagging: false,
                            label: 'director',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2f496792-9996-3791-a46b-38f089ebbba9',
                            notForTagging: true,
                            label: 'disc jockey',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5dbfbd34-c100-42c8-813f-deb54c229bb5',
                            notForTagging: true,
                            label: 'television host',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d23b2aea-7462-c225-5a84-165d1c566c99',
                            notForTagging: false,
                            label: 'musician',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9e3a6760-92c6-3d40-e8d5-0e2f9d26d7b6',
                            notForTagging: false,
                            label: 'producer',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2d02bbf0-7be7-45ff-a096-ff37995d875f',
                            notForTagging: false,
                            label: 'DJ',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4a3f7d41-97dc-49ad-bab9-cb9f9ab314e4',
                            notForTagging: false,
                            label: 'pianist',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/02d3e6a8-c42c-4f6c-815d-c548ff1c3653',
                            notForTagging: false,
                            label: 'musical ensemble',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8b322600-3a3d-dea3-508c-b1654e5bb083',
                            notForTagging: false,
                            label: 'executive producer',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bf2e5fda-6fcb-4a15-86a5-55a827c9d56c',
                            notForTagging: false,
                            label: 'social media star',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9b5757c0-a17b-4220-9ff3-8fd1d04530e9',
                            notForTagging: false,
                            label: 'broadcaster',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/503e74a4-c039-f789-04f0-6b06f52a0cb6',
                            notForTagging: false,
                            label: 'cinematographer',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/27baa6b7-112d-4929-b144-f4c48c899ee2',
                            notForTagging: false,
                            label: 'violinist',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0e97fdc7-9988-4516-a0bc-68ec337a377d',
                            notForTagging: false,
                            label: 'entertainment host',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5e3957be-8780-75e4-d004-84336f0b7e86',
                            notForTagging: false,
                            label: 'celebrity',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b9b10d2e-8106-451b-9b06-43a29617d94c',
                            notForTagging: false,
                            label: 'guitarist',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fe35f164-99ca-54b7-b1b9-bc778ed18bb0',
                            notForTagging: true,
                            label: 'ballerina',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a63de409-90d4-3a0a-45ef-17fc50960a8a',
                            notForTagging: false,
                            label: 'camera operator',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0f3666d3-6257-a2e0-6e86-175765014dac',
                            notForTagging: false,
                            label: 'actor',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ff355dd9-fdcb-f3ad-e1be-513178cb93a0',
                            notForTagging: false,
                            label: 'acrobat',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f54ba375-1a9e-8ca8-970a-55d3554cb588',
                            notForTagging: false,
                            label: 'ringmaster',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2538a7e0-774e-f47e-c65d-029ad90c1d95',
                            notForTagging: false,
                            label: 'singer',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ce35aec4-ca74-ecb7-340d-8553be338b86',
                            notForTagging: false,
                            label: 'conductor',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e33f68a7-8a06-4b34-b827-e4083bae423b',
                            notForTagging: false,
                            label: 'stripper',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c234ac35-393c-ea0d-e07c-321b0fb36bb2',
                            notForTagging: false,
                            label: 'orchestra',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/633a6108-1d74-e981-cdd8-17ea978b9b96',
                            notForTagging: false,
                            label: 'dancer',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fd3cfb33-6c2d-fd82-c820-b0db1628ba8d',
                            notForTagging: false,
                            label: 'ballet dancer',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f62123f3-5222-483f-978f-9bfce0d17b91',
                            notForTagging: false,
                            label: 'comedian',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/04107991-60cc-74f4-9c72-f07774cb9189',
                            notForTagging: false,
                            label: 'animal actor',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/76378220-c111-4272-650c-c6fa048e509f',
                            notForTagging: false,
                            label: 'clown',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5c0beaf8-aab4-46ae-a002-937b67a5a58d',
                            notForTagging: false,
                            label: 'sports announcer',
                            broaderId:
                                'http://data.disney.com/resources/4c425fb0-c4a4-44a4-b6d4-0a2f13bb1ec1',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/e736b272-423c-4879-9bcb-de02f46ce8d7',
                    notForTagging: false,
                    label: 'ex-convict',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7f2603d6-ba29-419a-8f21-77e2af8cf710',
                    notForTagging: false,
                    label: 'ex-partner',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b4820238-f6a4-4ba4-be7b-8c4a101d9a13',
                    notForTagging: false,
                    label: 'explorer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/44354c7f-c96d-4d9c-b270-30c312bccecb',
                    notForTagging: true,
                    label: 'explorer (agent)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                    notForTagging: false,
                    label: 'family member',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/0a3fc210-a464-09f7-ee93-a5dbabee8fb7',
                            notForTagging: false,
                            label: 'cousin',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d42966d5-1e5d-4c2d-9a13-a6d202e89096',
                            notForTagging: false,
                            label: 'stepbrother',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fd309aa2-26ee-822a-e960-4c649c0a30ad',
                            notForTagging: false,
                            label: 'parent',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6313c801-1ebc-4bed-bd2e-5a1d5868aaee',
                            notForTagging: true,
                            label: 'aunt/uncle',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ec693ca8-e52c-4bb2-821d-00d3a756ffb9',
                            notForTagging: false,
                            label: 'nephew',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f5494b5f-bdd2-88f2-4da9-a2e73ca3be9a',
                            notForTagging: false,
                            label: 'stepsister',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e1ade0c7-a8af-413d-9f72-d24b41ca84f1',
                            notForTagging: false,
                            label: 'in-law',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/aabb9b07-0cab-4734-90b4-0faf2d753066',
                            notForTagging: false,
                            label: 'aunt',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9f12d16f-a366-4948-8dd3-fc50fa8c6b10',
                            notForTagging: false,
                            label: 'foster sibling',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c735d1e0-3395-bbe6-7936-96a3647903ba',
                            notForTagging: false,
                            label: 'grandparent',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/396bc68f-97e7-4661-ad0e-fafcf3b101aa',
                            notForTagging: false,
                            label: 'niece',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b723d2ed-fd1b-4115-ae05-6b9c27dea39b',
                            notForTagging: false,
                            label: 'stepfather',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/67d63846-acec-4f52-9462-698c725ce9bd',
                            notForTagging: false,
                            label: 'uncle',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/39335a15-adce-3fc9-0d4d-208a06ed9a85',
                            notForTagging: false,
                            label: 'mother',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d48f5aa0-418e-4be5-b0f4-a99e3c2c31ca',
                            notForTagging: false,
                            label: 'brother',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d13c8d72-75ff-733e-b096-a2b212eaf899',
                            notForTagging: false,
                            label: 'father',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c875f332-491e-4c2d-939e-d4cb4b3aeb35',
                            notForTagging: false,
                            label: 'sibling',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/76d409dd-13f8-418e-88a1-c9c21e0aa354',
                            notForTagging: false,
                            label: 'foster parent',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e63d4ac5-6444-9034-e517-5bb483cd4ca4',
                            notForTagging: false,
                            label: 'daughter',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/caef139e-0cc6-4c82-a5c4-2a3039939bac',
                            notForTagging: false,
                            label: 'twin',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/18455840-7535-4ebd-a7fe-6179f0b4d340',
                            notForTagging: false,
                            label: 'sister',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d6ff72ae-4dce-431b-97b7-dcea5cbbb873',
                            notForTagging: false,
                            label: 'foster child',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/953e8639-1cb2-117c-2d95-d940fc0d6bab',
                            notForTagging: false,
                            label: 'son',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5981d730-e745-40c1-9f3c-a654e1e22c4c',
                            notForTagging: false,
                            label: 'stepmother',
                            broaderId:
                                'http://data.disney.com/resources/2a56d15c-6ea2-4e29-a351-11f83d83014b',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/d47b82b3-121f-4335-b37a-dc10f5431e50',
                    notForTagging: false,
                    label: 'fan',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                    notForTagging: false,
                    label: 'fantasy being',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/c07e3a5e-e92c-4cb2-bf6d-ff462c9c0a04',
                            notForTagging: false,
                            label: 'Santa Claus',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ec6f7d67-e859-4bcc-ae7b-db9e0b4c01d4',
                            notForTagging: false,
                            label: 'time traveler',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7f36a6df-0852-42f8-a0f3-93333a6e8077',
                            notForTagging: false,
                            label: 'troll',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a41ecc07-7c8f-4395-8416-b36071976f54',
                            notForTagging: true,
                            label: 'spirit (being)',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/483c5149-8ee8-7cf5-9708-599b454b2eb3',
                            notForTagging: true,
                            label: 'wizard',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cc4d40f9-6289-f7d9-eb81-ff68855bcaa5',
                            notForTagging: false,
                            label: 'mummy',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410558a-4029-0599-9c75-0768b3f8508a',
                            notForTagging: false,
                            label: 'mermaid',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3a4c56eb-9f0f-acff-c364-f6c96ef40bbe',
                            notForTagging: false,
                            label: 'sprite',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d04374e7-f55e-a116-a41e-91b626abad98',
                            notForTagging: false,
                            label: 'satyr',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/31a9a549-3301-4044-9c99-38f75843d1eb',
                            notForTagging: false,
                            label: 'fantastic beast',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/ec3a24cc-b551-8e55-b6a0-c0609b056498',
                                    notForTagging: false,
                                    label: 'monster',
                                    broaderId:
                                        'http://data.disney.com/resources/31a9a549-3301-4044-9c99-38f75843d1eb',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/404faf90-871f-ba43-0baa-9bc40a04d298',
                                    notForTagging: false,
                                    label: 'griffin',
                                    broaderId:
                                        'http://data.disney.com/resources/31a9a549-3301-4044-9c99-38f75843d1eb',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/964bf9ee-495a-4949-6de9-0192219edf9f',
                                    notForTagging: false,
                                    label: 'unicorn',
                                    broaderId:
                                        'http://data.disney.com/resources/31a9a549-3301-4044-9c99-38f75843d1eb',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/9546c877-08c4-b520-bbca-3584fdf2498f',
                                    notForTagging: false,
                                    label: 'dragon',
                                    broaderId:
                                        'http://data.disney.com/resources/31a9a549-3301-4044-9c99-38f75843d1eb',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/1a45f7b9-2130-3367-6e50-44f93826758c',
                            notForTagging: false,
                            label: 'leprechaun',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/97fcc2b8-abb4-4987-b3f3-6d718871686d',
                            notForTagging: false,
                            label: 'spirit',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/041059a9-2888-bca4-9c16-5127b49c5382',
                                    notForTagging: false,
                                    label: 'demon',
                                    broaderId:
                                        'http://data.disney.com/resources/97fcc2b8-abb4-4987-b3f3-6d718871686d',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/68401d76-100e-7330-37cf-7c825a232998',
                                    notForTagging: false,
                                    label: 'ghoul',
                                    broaderId:
                                        'http://data.disney.com/resources/97fcc2b8-abb4-4987-b3f3-6d718871686d',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/d2053555-23cc-4dc0-9e48-8967ac17cab0',
                                    notForTagging: false,
                                    label: 'devil',
                                    broaderId:
                                        'http://data.disney.com/resources/97fcc2b8-abb4-4987-b3f3-6d718871686d',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/f34ae9dd-6f76-f741-320e-40fb373721aa',
                                    notForTagging: false,
                                    label: 'angel',
                                    broaderId:
                                        'http://data.disney.com/resources/97fcc2b8-abb4-4987-b3f3-6d718871686d',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/fa48f2d9-1998-ad08-c1cf-f26c787d5198',
                                    notForTagging: false,
                                    label: 'cupid',
                                    broaderId:
                                        'http://data.disney.com/resources/97fcc2b8-abb4-4987-b3f3-6d718871686d',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2a36e20a-ccd7-39fd-5711-15594966f3b9',
                                    notForTagging: false,
                                    label: 'ghost',
                                    broaderId:
                                        'http://data.disney.com/resources/97fcc2b8-abb4-4987-b3f3-6d718871686d',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/23e6ee4d-e75f-4551-acef-47595cd8d882',
                                    notForTagging: false,
                                    label: 'deity',
                                    broaderId:
                                        'http://data.disney.com/resources/97fcc2b8-abb4-4987-b3f3-6d718871686d',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/2a44734b-c7e7-2380-44be-2e29493cacad',
                            notForTagging: false,
                            label: 'martian',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d0383c7e-6e69-50f8-a246-110c230ee087',
                            notForTagging: false,
                            label: 'centaur',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8b45a141-4c17-1102-23d1-58e34d2899a9',
                            notForTagging: false,
                            label: 'zombie',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0a38ee32-90e2-5597-b30a-3c07cab6478a',
                            notForTagging: false,
                            label: 'magician',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/144a9b65-0eb4-14fa-5f3f-6838536b5ba6',
                            notForTagging: false,
                            label: 'superhero',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d7396d87-49d8-c227-8aa3-1f99e11cf0a0',
                            notForTagging: false,
                            label: 'sea monster',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/92395f39-2978-c2cf-6a0e-2ff59507d49a',
                            notForTagging: false,
                            label: 'witch',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1867e042-8327-44dd-9ec3-071fa5b110cc',
                            notForTagging: false,
                            label: 'double/doppelgänger',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/773463ec-a205-386c-ca7b-09ec44c0b39f',
                            notForTagging: false,
                            label: 'extraterrestrial being',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ac48ff30-7872-6ec4-fdcc-9f360b1b9caa',
                            notForTagging: false,
                            label: 'werewolf',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/84304059-7cdf-4d9d-8f84-ddbebd89b4b2',
                            notForTagging: false,
                            label: 'imaginary friend',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5133ec11-26c9-24a0-1007-7962a1764597',
                            notForTagging: false,
                            label: 'fairy',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/63b7da99-901e-4878-826d-2b470997b592',
                            notForTagging: false,
                            label: 'shapeshifter',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/294e063d-8234-d0b5-fd23-ce44e0bccbae',
                            notForTagging: false,
                            label: 'vampire',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410db8d-4880-f5f6-9c75-0768b3f80d87',
                            notForTagging: false,
                            label: 'elf',
                            broaderId:
                                'http://data.disney.com/resources/c8a09196-e43a-43d1-85b6-b66cd81844c2',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/6bee7b1a-4c8a-47b7-9b1c-d0105247522f',
                    notForTagging: false,
                    label: 'federal agent',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/deff940b-0325-4cc0-b8c3-9172b1bc3d5b',
                            notForTagging: false,
                            label: 'CIA agent',
                            broaderId:
                                'http://data.disney.com/resources/6bee7b1a-4c8a-47b7-9b1c-d0105247522f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d5203340-1dd5-487e-b3a3-0248aa78965c',
                            notForTagging: false,
                            label: 'ATF agent',
                            broaderId:
                                'http://data.disney.com/resources/6bee7b1a-4c8a-47b7-9b1c-d0105247522f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/06759e44-128e-4e45-b011-136555f304c0',
                            notForTagging: false,
                            label: 'secret service agent',
                            broaderId:
                                'http://data.disney.com/resources/6bee7b1a-4c8a-47b7-9b1c-d0105247522f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b89ee1a7-7a55-4a6b-9c61-1575effe0ed3',
                            notForTagging: false,
                            label: 'DEA agent',
                            broaderId:
                                'http://data.disney.com/resources/6bee7b1a-4c8a-47b7-9b1c-d0105247522f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b940c878-b42d-4237-b8e8-4cf107ae02ff',
                            notForTagging: false,
                            label: 'FBI agent',
                            broaderId:
                                'http://data.disney.com/resources/6bee7b1a-4c8a-47b7-9b1c-d0105247522f',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/7ce57000-d087-4bb4-b62d-3761743f9ba6',
                    notForTagging: false,
                    label: 'fiancé or fiancée',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/573ab7f0-4534-2605-9ed3-a4b6ab6ea88c',
                    notForTagging: false,
                    label: 'fire fighter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/89f469e6-fc9e-440d-abd0-a812eae650d6',
                    notForTagging: false,
                    label: 'flight attendant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/df139c30-d69c-409e-a3bc-3f57dfc1dbcd',
                    notForTagging: false,
                    label: 'forensics investigator',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5c6485aa-bd4e-453c-b1ac-acebc00cbbd9',
                    notForTagging: false,
                    label: 'gambler',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/224a0f47-ea15-bfe5-f11e-b9dd1c93af9c',
                    notForTagging: false,
                    label: 'giant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3334bb23-377e-6ff8-a366-c7d8ccf23bb2',
                    notForTagging: false,
                    label: 'groom',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                    notForTagging: false,
                    label: 'group',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/4f0c47f7-f35b-4791-abf3-85221a956ac8',
                            notForTagging: false,
                            label: 'corporation',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f187f989-3629-48be-9dee-c738edc1a19a',
                            notForTagging: false,
                            label: 'commune',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8f3ccc4c-7f2a-5cc6-e50b-abb1cb138881',
                            notForTagging: false,
                            label: 'queue',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9d70b34a-b9dc-4a0c-9cf8-acd8bf7f44a8',
                            notForTagging: false,
                            label: 'fraternity',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ec45b65f-09d9-9ba2-7903-6c5cf13c2dae',
                            notForTagging: false,
                            label: 'law firm',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1a0a3dc6-2c9d-41a6-873f-ce8cac3bf735',
                            notForTagging: false,
                            label: 'polycule',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e2478216-1ccc-5ebe-0e65-aa69a1e953ad',
                            notForTagging: true,
                            label: 'beatnik',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d3328e07-72a3-cc61-c0b8-c6321137d7be',
                            notForTagging: false,
                            label: 'audience',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3c3ca2fc-6b03-7a6c-f09b-d03a50c72bbf',
                            notForTagging: false,
                            label: 'couple',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fc434962-3b2c-4c6d-a465-c67e86c850a1',
                            notForTagging: true,
                            label: 'club (associations)',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8d8630c4-98fd-4a03-80d9-b1923d927542',
                            notForTagging: false,
                            label: 'sorority',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8d03f8fa-da20-495c-8abc-f27e145f464e',
                            notForTagging: false,
                            label: 'cartel',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/912225e0-8513-4297-9388-d844f4648288',
                            notForTagging: false,
                            label: 'cult',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/94f68ff1-3c26-47b0-ba69-0d5f13af783d',
                            notForTagging: false,
                            label: 'family',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/59ac1fa3-2aee-4b5e-bc21-471428badd30',
                            notForTagging: true,
                            label: 'LGBTQ',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e4be7635-4807-436d-9d47-6946b2c59eb3',
                            notForTagging: false,
                            label: 'labor union',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/58d20d76-73ec-4a31-9381-1facbd79e218',
                            notForTagging: false,
                            label: 'indigenous people',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4a892fba-c30e-436e-840f-37d4445690d4',
                            notForTagging: false,
                            label: 'team',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/352a6f9a-6fc3-4622-a54a-9c5bb5bef5b4',
                            notForTagging: false,
                            label: 'LGBTQIA+',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dc3c55f9-254f-a962-7683-02600223c2a4',
                            notForTagging: false,
                            label: 'army',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bf33c0b6-0619-a9fd-a08b-77e1abe9c997',
                            notForTagging: false,
                            label: 'film crew',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5e5c86af-ccfa-4865-958c-41cb3450a6d0',
                            notForTagging: false,
                            label: 'alternative religious order',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/143f4766-9bde-6a4b-f15e-f8848cf53a98',
                            notForTagging: false,
                            label: 'crowd',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/23de6443-80f0-4b1d-8f43-377d0f512e20',
                            notForTagging: false,
                            label: 'club',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/3146743f-120c-0ce5-08a1-00cab09bffa6',
                                    notForTagging: false,
                                    label: 'country club',
                                    broaderId:
                                        'http://data.disney.com/resources/23de6443-80f0-4b1d-8f43-377d0f512e20',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0bebf22b-65f3-4b48-b103-e782bbe103c3',
                                    notForTagging: false,
                                    label: 'country club',
                                    broaderId:
                                        'http://data.disney.com/resources/23de6443-80f0-4b1d-8f43-377d0f512e20',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/a25e6478-e2f0-4703-aa13-7178e4a09d29',
                            notForTagging: true,
                            label: 'fraternity & sorority',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/13af3e5b-7595-41c7-80d9-6317ed5dd105',
                            notForTagging: true,
                            label: 'grand jury',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b946ad80-eb7d-4e3d-b476-a1777ef02cba',
                            notForTagging: false,
                            label: 'jury',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bb30bb5e-1556-4a9e-a1ef-066fcfef824c',
                            notForTagging: false,
                            label: 'nonprofit organization',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9b57a4c6-1654-40d9-8327-cd236ce0708d',
                            notForTagging: false,
                            label: 'gang',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a44a2e8f-dee1-8d6c-52d9-8945e7b9c183',
                            notForTagging: true,
                            label: 'pit crew',
                            broaderId:
                                'http://data.disney.com/resources/e52a193f-3884-48d2-aa6f-d65571c02542',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/d67a92f2-b143-4b16-9677-a76ea125682a',
                    notForTagging: false,
                    label: 'guard',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e03ac2b5-0154-af2a-3890-93de0d9eba90',
                    notForTagging: false,
                    label: 'guest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c93d9e78-d2ce-4fd3-b978-dfc3653e3afb',
                    notForTagging: false,
                    label: 'guide',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c78d3150-1636-4545-9779-b0355c19271e',
                    notForTagging: true,
                    label: 'guide (person)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4b085d8e-0306-4c7e-9824-ebfd039ed16a',
                    notForTagging: false,
                    label: 'hacker',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                    notForTagging: false,
                    label: 'healthcare provider',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/174f4b3b-f117-e127-b205-52ceed32459f',
                            notForTagging: false,
                            label: 'nurse',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f37cd076-a42f-44b3-bec8-6dc975dde410',
                            notForTagging: false,
                            label: 'midwife',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/32571582-3d90-473b-a7a8-54fb2a3dff6c',
                            notForTagging: false,
                            label: 'hospital administrator',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/34b8f0cc-4bd4-4793-b723-089e0e8f7251',
                            notForTagging: false,
                            label: 'veterinarian',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a049334c-07fa-a5d3-60d3-5ce191e911ba',
                            notForTagging: false,
                            label: 'emergency medical technician',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b04848c2-a81a-cc92-6776-906a7939dbac',
                            notForTagging: false,
                            label: 'dentist',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1d4e2177-0b2c-51f1-3f70-3a247926b3a6',
                            notForTagging: false,
                            label: 'doctor',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f3fcef21-2811-4717-aaf2-ef61589d8053',
                            notForTagging: false,
                            label: 'acupuncturist',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4d398f4d-7cd0-4473-aedf-160b1c07415f',
                            notForTagging: false,
                            label: 'herbal medicine practitioner',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fccadaa4-2b08-4d33-ad7f-4681ee52eb61',
                            notForTagging: false,
                            label: 'pharmacist',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/13d3c37d-341a-4484-b8d8-34fa33d08f1a',
                            notForTagging: true,
                            label: 'psychotherapist',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b1cc51f3-fe11-4070-8d73-8633fcd97435',
                            notForTagging: false,
                            label: 'social worker',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/995460f1-4ed8-42a8-9d81-3ac1a248edb5',
                            notForTagging: false,
                            label: 'mental health professional',
                            broaderId:
                                'http://data.disney.com/resources/4a35f8f1-389c-446f-a7a9-88e91df351bd',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/d63a5f45-d76f-3e45-a0ef-900cec1883b9',
                    notForTagging: true,
                    label: 'hero',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ee37f1a9-e037-4979-bb77-f1e6c7038fc1',
                    notForTagging: false,
                    label: 'historian',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/32a2d63f-4260-43e2-b499-17d13416ccb9',
                    notForTagging: true,
                    label: 'homeless person',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fdcaaace-02ce-47b3-92ce-1576b3413804',
                    notForTagging: false,
                    label: 'homemaker',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d4b9c4c8-2cda-4f17-95a4-ec8e51190902',
                    notForTagging: false,
                    label: 'homeowner',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/daf3cdb3-42e6-47ec-a583-c2975f7d52ed',
                    notForTagging: false,
                    label: 'host',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9b1e7095-1d85-4ba1-9cbc-4bab22d5b3fa',
                    notForTagging: false,
                    label: 'hunter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/78bcfd49-30aa-422e-a2be-79662bbb1f37',
                    notForTagging: false,
                    label: 'illusionist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/545a7c6b-11c8-4948-add0-b18df5886e9c',
                    notForTagging: false,
                    label: 'immigrant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3131db07-8e06-09dc-4ebb-7f954ac999ae',
                    notForTagging: false,
                    label: 'infant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/be3ab2da-351c-eccb-4831-9d9074f4c7ad',
                    notForTagging: false,
                    label: 'judge',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0c0f8dfc-7526-4f62-abc0-b77e6a814ff2',
                    notForTagging: false,
                    label: 'juror',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f6ce3171-07d9-4570-b5d7-f043b2fe99cb',
                    notForTagging: false,
                    label: 'laborer',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/213e3842-1765-e46a-6645-a722e75b12ba',
                            notForTagging: false,
                            label: 'construction worker',
                            broaderId:
                                'http://data.disney.com/resources/f6ce3171-07d9-4570-b5d7-f043b2fe99cb',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1641356a-d8a6-b345-e8fd-4b4ea6ee5f8d',
                            notForTagging: false,
                            label: 'miner',
                            broaderId:
                                'http://data.disney.com/resources/f6ce3171-07d9-4570-b5d7-f043b2fe99cb',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5802c54a-e75b-42ac-ab2b-fa93c81f6165',
                            notForTagging: false,
                            label: 'shepherd',
                            broaderId:
                                'http://data.disney.com/resources/f6ce3171-07d9-4570-b5d7-f043b2fe99cb',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/333de96e-7744-e719-5ea8-3496f51716ab',
                            notForTagging: false,
                            label: 'farmer',
                            broaderId:
                                'http://data.disney.com/resources/f6ce3171-07d9-4570-b5d7-f043b2fe99cb',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/aaa02bba-8bf9-4a8e-952b-3a7ab716dbf5',
                                    notForTagging: false,
                                    label: 'peasant',
                                    broaderId:
                                        'http://data.disney.com/resources/333de96e-7744-e719-5ea8-3496f51716ab',
                                    terms: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3c58a1b-58f3-453f-9c7a-606a12bb2ec7',
                    notForTagging: false,
                    label: 'landlord',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b53625b7-cd84-5a4c-1f19-c8621657929e',
                    notForTagging: false,
                    label: 'lawyer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d93cd1c0-980c-c217-db53-9416fd1604b5',
                    notForTagging: true,
                    label: 'letter carrier',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b8e732f8-509d-481e-9c03-8fcbe0b6b351',
                    notForTagging: false,
                    label: 'librarian',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1047fb8e-7abf-89f3-7aa9-cd2c664dc59e',
                    notForTagging: false,
                    label: 'lifeguard',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bb4482f0-423b-52f0-7f25-924fad3859bb',
                    notForTagging: false,
                    label: 'locomotive engineer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/969680f2-8ca8-42fd-aa18-8c1bb9435906',
                    notForTagging: false,
                    label: 'lover',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/33796fea-ee38-4da7-aeb8-c35c2c2727de',
                    notForTagging: false,
                    label: 'mail carrier',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e8054190-292f-41b4-aac4-1673eb2bb5ed',
                    notForTagging: true,
                    label: 'man',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/487bd879-c9c7-4796-a264-3e59629ea942',
                    notForTagging: false,
                    label: 'military member',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/f43394cc-c3a9-ed58-d226-fbf4cc09989a',
                            notForTagging: false,
                            label: 'military officer',
                            broaderId:
                                'http://data.disney.com/resources/487bd879-c9c7-4796-a264-3e59629ea942',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410c48d-6049-f8bb-9c75-0768b3f81f85',
                            notForTagging: false,
                            label: 'soldier',
                            broaderId:
                                'http://data.disney.com/resources/487bd879-c9c7-4796-a264-3e59629ea942',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/882cc2f1-e65a-413c-a085-f505f9e06e83',
                            notForTagging: false,
                            label: 'general',
                            broaderId:
                                'http://data.disney.com/resources/487bd879-c9c7-4796-a264-3e59629ea942',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b67d35e6-9758-41fe-a7f8-67ac38b8d666',
                            notForTagging: false,
                            label: 'veteran',
                            broaderId:
                                'http://data.disney.com/resources/487bd879-c9c7-4796-a264-3e59629ea942',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/723b5807-e53f-07b1-91e6-e0073fb68e97',
                            notForTagging: false,
                            label: 'admiral',
                            broaderId:
                                'http://data.disney.com/resources/487bd879-c9c7-4796-a264-3e59629ea942',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/8d10483f-0498-472a-a2b4-97e90f299e61',
                    notForTagging: true,
                    label: 'millennial',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/887b6e58-77ef-4ef2-8d3c-32ef9b23fc7c',
                    notForTagging: true,
                    label: 'missing animal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/19c186fb-8601-46c8-ab5d-fef2692a202d',
                    notForTagging: false,
                    label: 'missing person',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/195b136c-478f-405e-b697-407bba58e056',
                    notForTagging: false,
                    label: 'model',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1a3ea449-2e0a-4a42-8b7e-ee3c564d1c40',
                    notForTagging: false,
                    label: 'motorcyclist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/878e76f3-a3d6-4cb4-9dfc-1f0ffcae2499',
                    notForTagging: false,
                    label: 'neighbor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2dddf734-70e2-4506-a9cb-d9a688d9b4a1',
                    notForTagging: false,
                    label: 'neurodiverse agent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d7322843-58cd-8f8f-c2b7-936229fed5b5',
                    notForTagging: false,
                    label: 'news anchor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/16b41159-91e2-48ff-acaa-72639c5fa3ac',
                    notForTagging: false,
                    label: 'object',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/b51cdc0d-4505-48ca-8e47-8ed1b5238fd8',
                            notForTagging: false,
                            label: 'puppet',
                            broaderId:
                                'http://data.disney.com/resources/16b41159-91e2-48ff-acaa-72639c5fa3ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ca301777-92ef-755a-b4a6-24466e3306bb',
                            notForTagging: false,
                            label: 'robot',
                            broaderId:
                                'http://data.disney.com/resources/16b41159-91e2-48ff-acaa-72639c5fa3ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2e7b0bff-4d5a-415c-8630-32b3c50b500d',
                            notForTagging: true,
                            label: 'computer program',
                            broaderId:
                                'http://data.disney.com/resources/16b41159-91e2-48ff-acaa-72639c5fa3ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f5994bfc-783e-425c-914b-42ab62c5106c',
                            notForTagging: false,
                            label: 'artificial intelligence',
                            broaderId:
                                'http://data.disney.com/resources/16b41159-91e2-48ff-acaa-72639c5fa3ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a6155686-4a11-48f1-87e1-0492afcd55cb',
                            notForTagging: false,
                            label: 'automobile',
                            broaderId:
                                'http://data.disney.com/resources/16b41159-91e2-48ff-acaa-72639c5fa3ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a294ae23-762b-4c8b-9ad8-83ad3ca86ec2',
                            notForTagging: true,
                            label: 'drone',
                            broaderId:
                                'http://data.disney.com/resources/16b41159-91e2-48ff-acaa-72639c5fa3ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7a32b117-dfee-6710-1b22-087d13375ca2',
                            notForTagging: false,
                            label: 'snowman',
                            broaderId:
                                'http://data.disney.com/resources/16b41159-91e2-48ff-acaa-72639c5fa3ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d8371b44-3498-bd40-1f65-a7ee5252e4af',
                            notForTagging: false,
                            label: 'toy',
                            broaderId:
                                'http://data.disney.com/resources/16b41159-91e2-48ff-acaa-72639c5fa3ac',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/ebebcd4d-4383-47ce-bafe-8851022c272e',
                    notForTagging: true,
                    label: 'older person',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d1ca9d50-8b63-43fb-a1fe-49713de8f030',
                    notForTagging: false,
                    label: 'orphan',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/553f105e-5f13-bc38-d082-a899a7dcd4af',
                    notForTagging: false,
                    label: 'passenger',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/274bbdf8-999b-4c5e-95e2-fd258c6108af',
                    notForTagging: false,
                    label: 'patient',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/27825679-1fea-4300-9c48-9ba9c25b00e7',
                    notForTagging: false,
                    label: 'pedestrian',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f330b3db-2be8-a2dc-747b-411171435b84',
                    notForTagging: true,
                    label: 'person with disabilities',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                    notForTagging: false,
                    label: 'personal service provider',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/17c00b75-2f42-45a1-8eb0-82e196129937',
                            notForTagging: false,
                            label: 'delivery person',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/490eba0a-ceb4-4a52-a7f2-f7f67a18da71',
                            notForTagging: false,
                            label: 'undertaker',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5649cd6d-eb5f-c2a6-9a36-847b467afaa0',
                            notForTagging: false,
                            label: 'janitor',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/923068b1-b2ef-befd-0eea-eba012d8cb81',
                            notForTagging: true,
                            label: 'maid',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7838df78-acb9-b4b5-95dd-3aebd45b86b6',
                            notForTagging: false,
                            label: 'chauffeur',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9f77e263-a5eb-4875-8688-09c22a917870',
                            notForTagging: false,
                            label: 'babysitter',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ae62f785-8fd3-403a-9718-25b70becc45e',
                            notForTagging: false,
                            label: 'bus driver',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/23da470f-4f4b-41cc-9674-bcc507d05072',
                            notForTagging: false,
                            label: 'interior designer',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5a347d2b-afd9-6e4a-239d-4be8696945b4',
                            notForTagging: false,
                            label: 'beautician',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f33691b7-ef51-2c83-2bf9-c9ec968f4abe',
                            notForTagging: false,
                            label: 'gardener',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a63c0632-b8f1-c054-eb73-0081831961be',
                            notForTagging: false,
                            label: 'driver',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/de44b346-3a88-b72a-67ab-2fda7194468b',
                            notForTagging: false,
                            label: 'wedding planner',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/096d4b9e-9ea3-4b60-8fb4-7ff83ec9935b',
                            notForTagging: false,
                            label: 'taxicab driver',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/64ae23d2-daa1-4cc2-944b-a8a240e1c2ad',
                            notForTagging: false,
                            label: 'servant',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/89724f01-72cd-4d66-ad4d-68e8ffe6c1c9',
                            notForTagging: true,
                            label: 'au pair',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9c73109c-470c-455e-bfa9-1c24999fe4f4',
                            notForTagging: false,
                            label: 'nanny',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e23fd31f-b812-c638-6739-d6e07a3ad9a5',
                            notForTagging: false,
                            label: 'doorman',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2956aade-0af4-4df1-9a00-f8bb34855ea5',
                            notForTagging: true,
                            label: 'People associated with transportation',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ce4fd052-0329-9edd-6ca2-85fc0849a1a7',
                            notForTagging: false,
                            label: 'marriage counselor',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5bb18ccd-8f73-492a-92e3-5ebefd25affe',
                            notForTagging: false,
                            label: 'hairdresser',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5e3628e4-292d-2859-040e-dc457589ab9b',
                            notForTagging: false,
                            label: 'butler',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2a790b04-cefa-41b6-8919-3b80cff61d02',
                            notForTagging: false,
                            label: 'sex worker',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e43ba75c-8c84-d23e-ff03-3115e1aa15a5',
                            notForTagging: false,
                            label: 'barber',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5d4ec2d4-155b-7985-151c-cb82d0adc7b4',
                            notForTagging: false,
                            label: 'repairman',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b2489a4f-e558-804b-184b-1916c6299189',
                            notForTagging: false,
                            label: 'real estate agent',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e84742f6-5acd-a182-54ef-0724d9a5e48e',
                            notForTagging: false,
                            label: 'mechanic',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/137c46a1-0301-4156-a41b-23e112e6ae42',
                            notForTagging: false,
                            label: 'housekeeper',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/843a09d0-c7a6-8b77-1a02-4bf9996d03b8',
                            notForTagging: false,
                            label: 'plumber',
                            broaderId:
                                'http://data.disney.com/resources/be06d422-1ff0-4f7e-aeef-2256f1ef3b26',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/04105ea9-c0c9-b71b-9c16-5127b49c1c87',
                    notForTagging: false,
                    label: 'pilgrim',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fd32c870-4242-a7bb-cacd-80b9cd12a9ac',
                    notForTagging: false,
                    label: 'pilot',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a34dcdc9-ebfd-e7f9-43ba-9dba87b85bb5',
                    notForTagging: true,
                    label: 'pitcher',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d23cbc5f-62d1-9355-6d21-cfd6d7f17baa',
                    notForTagging: false,
                    label: 'police',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/c2363835-8f18-81fb-444a-542a352081a2',
                            notForTagging: false,
                            label: 'traffic control officer',
                            broaderId:
                                'http://data.disney.com/resources/d23cbc5f-62d1-9355-6d21-cfd6d7f17baa',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/49339186-5b0f-acc4-90d1-869e5e312bb7',
                            notForTagging: false,
                            label: 'detective',
                            broaderId:
                                'http://data.disney.com/resources/d23cbc5f-62d1-9355-6d21-cfd6d7f17baa',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c54d4298-bddb-428f-ba0f-3e151c9d35e2',
                            notForTagging: false,
                            label: 'chief of police',
                            broaderId:
                                'http://data.disney.com/resources/d23cbc5f-62d1-9355-6d21-cfd6d7f17baa',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0f472e81-a74e-5031-b98c-414ffbc9058d',
                            notForTagging: false,
                            label: 'sheriff',
                            broaderId:
                                'http://data.disney.com/resources/d23cbc5f-62d1-9355-6d21-cfd6d7f17baa',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/08f3179b-5a09-4432-9a77-57d3a4e3e0c3',
                    notForTagging: false,
                    label: 'politician',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/a5d232bd-e77b-4739-80ba-3024b62aedff',
                            notForTagging: false,
                            label: 'legislator',
                            broaderId:
                                'http://data.disney.com/resources/08f3179b-5a09-4432-9a77-57d3a4e3e0c3',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/14e28b59-5cf2-4ead-8a26-53940238b495',
                            notForTagging: false,
                            label: 'president',
                            broaderId:
                                'http://data.disney.com/resources/08f3179b-5a09-4432-9a77-57d3a4e3e0c3',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c7f134b1-46ac-4a3d-b4b6-92b3875acf95',
                            notForTagging: false,
                            label: 'diplomat',
                            broaderId:
                                'http://data.disney.com/resources/08f3179b-5a09-4432-9a77-57d3a4e3e0c3',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3f4576b8-5194-291c-cbf8-86d6e86fe8a9',
                            notForTagging: false,
                            label: 'vizier',
                            broaderId:
                                'http://data.disney.com/resources/08f3179b-5a09-4432-9a77-57d3a4e3e0c3',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/db172517-0af7-49c8-b08f-e808c030a68b',
                            notForTagging: false,
                            label: 'mayor',
                            broaderId:
                                'http://data.disney.com/resources/08f3179b-5a09-4432-9a77-57d3a4e3e0c3',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f8a71bf3-e168-4f62-a428-e6e5b480db75',
                            notForTagging: false,
                            label: 'governor',
                            broaderId:
                                'http://data.disney.com/resources/08f3179b-5a09-4432-9a77-57d3a4e3e0c3',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/14292710-194d-4beb-94a3-d7dcc56dbabf',
                            notForTagging: false,
                            label: 'bureaucrat',
                            broaderId:
                                'http://data.disney.com/resources/08f3179b-5a09-4432-9a77-57d3a4e3e0c3',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/04109ac3-8895-863a-9c35-2b06c8f7e481',
                    notForTagging: true,
                    label: 'preschooler',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/704e6096-ae3a-4716-83f3-a856c4933ab8',
                    notForTagging: false,
                    label: 'prison guard',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1ecfb3ec-9209-4eaa-a4e4-4681b840c446',
                    notForTagging: false,
                    label: 'psychic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/df93d472-9f3f-4eaa-b384-12a88c3213d8',
                    notForTagging: false,
                    label: 'publicist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b4426a3e-0475-fc8a-7181-4d5a236eec9d',
                    notForTagging: false,
                    label: 'publisher',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2a46e2f8-a7d3-9862-1eac-1a5756790e83',
                    notForTagging: false,
                    label: 'railroad conductor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6b4aaaa0-9edf-919f-0119-82ef9e83d895',
                    notForTagging: false,
                    label: 'ranger',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/db37cf5f-c9c6-6e20-f9e7-b96df7c05eba',
                    notForTagging: false,
                    label: 'referee',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d413a160-b72c-474a-96cd-b7aee7541136',
                    notForTagging: false,
                    label: 'refugee',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cede53a2-e93b-45f8-9a2a-9461eca17d77',
                    notForTagging: false,
                    label: 'religious figure',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/342e26e1-f132-423b-8a20-007aa9d392e0',
                            notForTagging: false,
                            label: 'prophet',
                            broaderId:
                                'http://data.disney.com/resources/cede53a2-e93b-45f8-9a2a-9461eca17d77',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5d3f3b25-79e2-3a53-8af4-062e2688f387',
                            notForTagging: false,
                            label: 'preacher',
                            broaderId:
                                'http://data.disney.com/resources/cede53a2-e93b-45f8-9a2a-9461eca17d77',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/83420d3d-de1a-dda1-3f01-4e7655252399',
                            notForTagging: true,
                            label: 'shaman',
                            broaderId:
                                'http://data.disney.com/resources/cede53a2-e93b-45f8-9a2a-9461eca17d77',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f6337c64-e9fa-2be9-cf99-0afacfd0f08d',
                            notForTagging: false,
                            label: 'priest',
                            broaderId:
                                'http://data.disney.com/resources/cede53a2-e93b-45f8-9a2a-9461eca17d77',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/05ee5181-5457-45d8-890c-be91d4452283',
                            notForTagging: false,
                            label: 'imam',
                            broaderId:
                                'http://data.disney.com/resources/cede53a2-e93b-45f8-9a2a-9461eca17d77',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/16b3cdc0-d24d-43c7-8869-def90a7eaaa8',
                            notForTagging: false,
                            label: "indigenous peoples' religious leader",
                            broaderId:
                                'http://data.disney.com/resources/cede53a2-e93b-45f8-9a2a-9461eca17d77',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6733b9a8-6ba6-c203-d6dd-bf866d71d084',
                            notForTagging: false,
                            label: 'nun',
                            broaderId:
                                'http://data.disney.com/resources/cede53a2-e93b-45f8-9a2a-9461eca17d77',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b03d47a1-9e18-0a48-ca5c-c60c39f1f5ae',
                            notForTagging: false,
                            label: 'monk',
                            broaderId:
                                'http://data.disney.com/resources/cede53a2-e93b-45f8-9a2a-9461eca17d77',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1af1c56a-0971-408d-b859-4961bf3de5ef',
                            notForTagging: false,
                            label: 'rabbi',
                            broaderId:
                                'http://data.disney.com/resources/cede53a2-e93b-45f8-9a2a-9461eca17d77',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/f232609a-0b60-6270-dc93-7820ed6a7fad',
                    notForTagging: false,
                    label: 'reporter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                    notForTagging: false,
                    label: 'restaurant and hospitality service worker',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/253509d0-d19b-1be1-8c99-f33e186c0db9',
                            notForTagging: false,
                            label: 'bartender',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/27cbef5f-9330-4cf1-8148-d3d47a24d154',
                            notForTagging: false,
                            label: 'barista',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/85d82fe2-e266-477f-b2b7-59daefeb9be8',
                            notForTagging: false,
                            label: 'baker',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6745f438-bb05-4d79-8139-bd35d29cf5d4',
                            notForTagging: false,
                            label: 'waitperson',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/95253484-a12e-449c-ad52-7549f497fe6a',
                            notForTagging: false,
                            label: 'sommelier',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fc95f905-ff5d-4f83-a28a-c92e92de50af',
                            notForTagging: false,
                            label: 'vintner',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5b417109-4c86-144f-f08b-56573db55e8c',
                            notForTagging: false,
                            label: 'butcher',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7530a277-195e-3ee4-fc79-80c279abe383',
                            notForTagging: true,
                            label: 'waiter or waitress',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/454d9eef-a93d-f1e0-edf3-479efd669c8d',
                            notForTagging: false,
                            label: "maître d'",
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f731b1fd-94ce-1e9c-7a8b-dcfb0b0634a2',
                            notForTagging: false,
                            label: 'busser',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/773d7e21-1534-44af-9248-701a3d3e1019',
                            notForTagging: true,
                            label: 'bakers',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/66353468-e83b-5dc2-04c7-2605142bb497',
                            notForTagging: false,
                            label: 'cook',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0532d4e4-fae2-43b8-affa-98eed033d930',
                            notForTagging: false,
                            label: 'brewer',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7e383956-de3d-5283-f7df-36a6de93e096',
                            notForTagging: false,
                            label: 'hotel porter',
                            broaderId:
                                'http://data.disney.com/resources/691cd26f-5d77-4afa-ae57-c59865eb5036',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/7310d85f-b19c-4572-b3e7-fe77d3a886a5',
                    notForTagging: false,
                    label: 'retiree',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bd4aa779-b572-4656-8a6f-0dd657f7a573',
                    notForTagging: false,
                    label: 'rich person',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/93344ed5-ae09-447e-a9cf-f1eed2576780',
                            notForTagging: false,
                            label: 'socialite',
                            broaderId:
                                'http://data.disney.com/resources/bd4aa779-b572-4656-8a6f-0dd657f7a573',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8d882e91-c285-4138-a793-a676d857f455',
                            notForTagging: false,
                            label: 'aristocrat',
                            broaderId:
                                'http://data.disney.com/resources/bd4aa779-b572-4656-8a6f-0dd657f7a573',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2ac290c0-29f6-455b-835d-d664cab1b336',
                            notForTagging: false,
                            label: 'heir',
                            broaderId:
                                'http://data.disney.com/resources/bd4aa779-b572-4656-8a6f-0dd657f7a573',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/556b75c0-d1d5-4e8b-8d59-2e2d3a2c06e8',
                    notForTagging: false,
                    label: 'roommate',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4ee2a322-6e47-4ffe-b896-d7afdc2fddf4',
                    notForTagging: false,
                    label: 'ruler',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/15896adf-fd69-4d2e-9ecd-40bdb2d9d2d2',
                            notForTagging: false,
                            label: 'dictator',
                            broaderId:
                                'http://data.disney.com/resources/4ee2a322-6e47-4ffe-b896-d7afdc2fddf4',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f5f0de18-913a-4fae-9033-8efcba7b600d',
                            notForTagging: false,
                            label: 'royalty',
                            broaderId:
                                'http://data.disney.com/resources/4ee2a322-6e47-4ffe-b896-d7afdc2fddf4',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/8a355ae0-57a2-8a89-5a29-350fbda254b8',
                                    notForTagging: false,
                                    label: 'prince',
                                    broaderId:
                                        'http://data.disney.com/resources/f5f0de18-913a-4fae-9033-8efcba7b600d',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/653579ec-48ff-3284-7c65-b2cab77b55b9',
                                    notForTagging: false,
                                    label: 'queen',
                                    broaderId:
                                        'http://data.disney.com/resources/f5f0de18-913a-4fae-9033-8efcba7b600d',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ce3d9b28-abbb-1844-5358-728a44ad5899',
                                    notForTagging: false,
                                    label: 'king',
                                    broaderId:
                                        'http://data.disney.com/resources/f5f0de18-913a-4fae-9033-8efcba7b600d',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/99307d6d-e582-c3fc-f8db-52e943a5ef91',
                                    notForTagging: false,
                                    label: 'princess',
                                    broaderId:
                                        'http://data.disney.com/resources/f5f0de18-913a-4fae-9033-8efcba7b600d',
                                    terms: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/cbfdf911-3fbd-44f4-b291-824a1a898612',
                    notForTagging: false,
                    label: 'runaway',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5d42b9dd-bd37-ff81-cd65-10afbc6de396',
                    notForTagging: false,
                    label: 'sailor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b42c7670-71c6-4bc2-ab6d-41b6e517886f',
                    notForTagging: false,
                    label: 'school principal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0410ca8d-5042-efe4-9c75-0768b3f82086',
                    notForTagging: false,
                    label: 'scientist',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/c93a971b-0c58-42dc-a502-0ca3e12594ef',
                            notForTagging: false,
                            label: 'lab technician',
                            broaderId:
                                'http://data.disney.com/resources/0410ca8d-5042-efe4-9c75-0768b3f82086',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9540cc86-f8cd-9d58-4738-fb97bb4d98a3',
                            notForTagging: false,
                            label: 'coroner',
                            broaderId:
                                'http://data.disney.com/resources/0410ca8d-5042-efe4-9c75-0768b3f82086',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0f3754a4-0bd1-d475-2684-dea2f2abad8d',
                            notForTagging: false,
                            label: 'physicist',
                            broaderId:
                                'http://data.disney.com/resources/0410ca8d-5042-efe4-9c75-0768b3f82086',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b73bba34-63ff-428e-a7a7-8610b5d8de38',
                            notForTagging: false,
                            label: 'inventor',
                            broaderId:
                                'http://data.disney.com/resources/0410ca8d-5042-efe4-9c75-0768b3f82086',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9bd34680-b04d-4667-a8ec-13115157ce81',
                            notForTagging: true,
                            label: 'archeologist',
                            broaderId:
                                'http://data.disney.com/resources/0410ca8d-5042-efe4-9c75-0768b3f82086',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/0d37f8ed-e72d-8444-9e5b-5f7d608a56a7',
                    notForTagging: false,
                    label: 'ship captain',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1e7eb436-b30a-47e8-8edd-7df4acc20931',
                    notForTagging: false,
                    label: 'software developer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f74faf89-8924-8609-ef2d-e47e3a1647ac',
                    notForTagging: false,
                    label: 'spectator',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5e7244ce-7a2a-4d0d-983e-359604e2d218',
                    notForTagging: false,
                    label: 'spouse',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f1334c54-c4f4-cdba-ca3b-960f166b529f',
                    notForTagging: false,
                    label: 'spy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/90c29873-91ff-4534-bb9c-f248a2a0931c',
                    notForTagging: false,
                    label: 'stoner',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4946a2ca-0b6a-bbe0-61a6-f9e41c4d9884',
                    notForTagging: false,
                    label: 'student',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f03f4470-d624-4a5f-94b1-5b7f112e4c93',
                    notForTagging: false,
                    label: 'suitor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a7f0f36f-9793-4a2a-aeb7-90e0670256ea',
                    notForTagging: false,
                    label: 'survivalist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/105d97d2-2168-4bfa-bbd2-e307fae66788',
                    notForTagging: true,
                    label: 'survivor of violence',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/a8a0776c-ba6c-448b-8ef9-f1c2d31710cd',
                            notForTagging: true,
                            label: 'survivor of sexual/gender violence',
                            broaderId:
                                'http://data.disney.com/resources/105d97d2-2168-4bfa-bbd2-e307fae66788',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ece1cbbb-ba89-4c67-a17a-28f52fda12a9',
                            notForTagging: true,
                            label: 'survivor of racial/ethnic violence',
                            broaderId:
                                'http://data.disney.com/resources/105d97d2-2168-4bfa-bbd2-e307fae66788',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/decc0403-da58-418b-9645-b7f9d1f148d5',
                    notForTagging: false,
                    label: 'talent agent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c330b302-a27e-bae2-9b4d-8fb773a5b68f',
                    notForTagging: false,
                    label: 'teacher',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/693c5e8c-ead4-2c7c-d871-9aff6f3011b4',
                    notForTagging: true,
                    label: 'teenager',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/843cfdd8-1de7-706b-0dda-7782539c2fa6',
                    notForTagging: true,
                    label: 'toddler',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/983f8789-2350-cd17-8a32-525bd2868f8a',
                    notForTagging: false,
                    label: 'tourist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/46377c83-fa6b-cd8d-424c-e6d2db286b97',
                    notForTagging: true,
                    label: 'tween',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6745f19d-7f2d-64ec-8fcd-95a996894397',
                    notForTagging: false,
                    label: 'umpire',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9d378724-2573-4a2c-a167-252bfdaec361',
                    notForTagging: false,
                    label: 'unhoused person',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/48e155ae-2886-44f8-acc5-f9ab68c505ea',
                    notForTagging: false,
                    label: 'victim/survivor of violence',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/7cd53607-e824-4001-b31b-173b6ff1bcb2',
                            notForTagging: false,
                            label: 'victim/survivor of racial/ethnic violence',
                            broaderId:
                                'http://data.disney.com/resources/48e155ae-2886-44f8-acc5-f9ab68c505ea',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e2cbceff-5c96-4ad2-8f79-45f43e12b077',
                            notForTagging: false,
                            label: 'victim/survivor of sexual/gender violence',
                            broaderId:
                                'http://data.disney.com/resources/48e155ae-2886-44f8-acc5-f9ab68c505ea',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/41b83e90-17f6-4f01-ab31-4897f41f3e6b',
                    notForTagging: false,
                    label: 'video gamer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bdcaa133-93e3-4f24-8b7f-5f250c81c5b1',
                    notForTagging: false,
                    label: 'vigilante',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4916148f-db44-4229-ac5a-446319f77dce',
                    notForTagging: false,
                    label: 'virgin',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0410749f-f038-f5f5-9cab-60c4a7895688',
                    notForTagging: false,
                    label: 'warrior',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/3936614f-5a5d-d21f-d9d2-0e6086fbce88',
                            notForTagging: false,
                            label: 'knight',
                            broaderId:
                                'http://data.disney.com/resources/0410749f-f038-f5f5-9cab-60c4a7895688',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b24cc08e-e2cf-debd-d888-608d19cfdd8b',
                            notForTagging: false,
                            label: 'archer',
                            broaderId:
                                'http://data.disney.com/resources/0410749f-f038-f5f5-9cab-60c4a7895688',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f045ebce-1724-566d-daf9-81e8f44b7c88',
                            notForTagging: false,
                            label: 'samurai',
                            broaderId:
                                'http://data.disney.com/resources/0410749f-f038-f5f5-9cab-60c4a7895688',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/81e008d7-6388-45c6-a955-20f6c2d71eb0',
                    notForTagging: true,
                    label: 'widow',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7d0d5749-9aac-4d6f-a598-05c4d747a45f',
                    notForTagging: false,
                    label: 'widowed person',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1840f229-6d2b-2be2-3451-04c7e77c8dbf',
                    notForTagging: false,
                    label: 'winner',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bd7acd82-b323-4eb3-bae0-d2de8a80b212',
                    notForTagging: false,
                    label: 'witness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/657b4513-aa12-4768-862a-2bccb8213485',
                    notForTagging: true,
                    label: 'woman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/274a9171-2182-3eee-8688-20cb70d2aba8',
                    notForTagging: false,
                    label: 'zoo keeper',
                    terms: [],
                },
            ],
        },
        {
            label: 'Character Archetypes',
            type: 'http://data.disney.com/resources/CharacterArchetypes',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/aa806a2d-6c59-4017-a4b5-ecf65a4461d6',
                    notForTagging: false,
                    label: 'antagonist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3f026c24-90f8-4fbd-bd1b-dae9a77d7ef3',
                    notForTagging: false,
                    label: 'authority',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b687f941-ef99-40d5-96ae-696cdccc367e',
                    notForTagging: false,
                    label: 'competitor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b9c7af5f-20e9-4aa5-a77e-d65c1dd7b5b9',
                    notForTagging: false,
                    label: 'doubter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/574744ef-a803-617d-38c9-c29be47ac686',
                    notForTagging: false,
                    label: 'explorer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c8e554da-36a3-453a-925c-a7ac573fdd1a',
                    notForTagging: true,
                    label: 'hero',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/80cb5682-6c3c-466d-b69a-afc384005e66',
                    notForTagging: false,
                    label: 'impostor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d7a77a99-02f4-46ee-9951-8e4d5cf59f49',
                    notForTagging: false,
                    label: 'innocent, the',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7c7e7983-cc46-4bc5-ab07-2c711851c280',
                    notForTagging: false,
                    label: 'jester',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5e0fedca-673d-45ed-b3ef-8e3c84a91725',
                    notForTagging: true,
                    label: 'love interest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/62384c4d-e1a9-41b5-8c34-b9d74b8ea7ea',
                    notForTagging: false,
                    label: 'love interest (non-romantic)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/187ae97f-31a3-4147-b3de-1d519b05bb92',
                    notForTagging: false,
                    label: 'love interest (romantic)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b3f59636-4bb0-49cc-af4e-bb493ad6b722',
                    notForTagging: false,
                    label: 'magician',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cafe6be2-79ca-4471-86e6-a01740b5ae35',
                    notForTagging: false,
                    label: 'mentor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6f08e2f8-1198-4c1f-bea5-9ee274886035',
                    notForTagging: false,
                    label: 'messenger',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f136b27e-2d32-45b8-a16f-841f88ac0a55',
                    notForTagging: false,
                    label: 'narrator',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ecc77991-f960-448d-b54d-bf6cacb12765',
                    notForTagging: false,
                    label: 'nurturer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/602e0cbf-7610-4383-a1ba-7f7b77e07fc2',
                    notForTagging: false,
                    label: 'protagonist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a02f1ee0-1d9c-4b36-8ec6-a27c525d361c',
                    notForTagging: false,
                    label: 'rebel',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/19162807-a9ad-445b-8e71-6ebf54292007',
                    notForTagging: false,
                    label: 'shapeshifter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8b9eef77-9ca7-459e-9ec1-7073519f5173',
                    notForTagging: false,
                    label: 'sidekick',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/62084297-eb1b-46eb-97b3-80753fe67d8e',
                            notForTagging: false,
                            label: 'sidekick (antagonist)',
                            broaderId:
                                'http://data.disney.com/resources/8b9eef77-9ca7-459e-9ec1-7073519f5173',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/da32d0be-3743-461f-b1bf-bd834f8e80d4',
                            notForTagging: false,
                            label: 'sidekick (protagonist)',
                            broaderId:
                                'http://data.disney.com/resources/8b9eef77-9ca7-459e-9ec1-7073519f5173',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/de3c193a-74d9-4f36-b8a6-3fddaaea0eef',
                    notForTagging: false,
                    label: 'temptor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/aa337ac4-a63c-51d0-59c2-d71bec68e792',
                    notForTagging: true,
                    label: 'villain',
                    terms: [],
                },
            ],
        },
        {
            label: 'Character Personality Traits',
            type: 'http://data.disney.com/resources/CharacterPersonalityTraits',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/a814e02e-ec3b-4b8f-b3e5-4da2b3658e3d',
                    notForTagging: true,
                    label: 'adventurous',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d633145d-bce6-4d46-8a2c-3b6eddf22fcf',
                    notForTagging: false,
                    label: 'ambitious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3fc246ce-712a-498b-855b-9cb1c596f0b5',
                    notForTagging: true,
                    label: 'apathetic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cac338fc-671c-4471-a30e-f770e5449806',
                    notForTagging: false,
                    label: 'arrogant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d2685b6b-e519-41ce-b80d-de222f6d0f11',
                    notForTagging: true,
                    label: 'awkward',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/936f7bd5-d891-4d1f-8d90-9229ba2cc929',
                    notForTagging: true,
                    label: 'calm',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/afad8644-6922-4f9c-a441-d8fc50722155',
                    notForTagging: true,
                    label: 'charismatic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/10d007c6-0fd7-4b15-9303-76e3e5661d21',
                    notForTagging: true,
                    label: 'chaste',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e1c33d1e-82e7-4787-9df2-24cb5a470861',
                    notForTagging: true,
                    label: 'cold',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7c06e1b7-10e9-437b-8949-ca4a494eefe0',
                    notForTagging: false,
                    label: 'confident',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c144e76d-da68-4760-a64f-33a03f51b99c',
                    notForTagging: false,
                    label: 'conscientious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7c53eb02-9ffb-43fb-9f31-75bc5100a6cb',
                    notForTagging: true,
                    label: 'corrupt',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4bb6e19e-e609-4d41-b3fa-c6827c76ce45',
                    notForTagging: false,
                    label: 'courageous',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0535b460-7c28-435b-adab-04d96852cbe4',
                    notForTagging: true,
                    label: 'crazy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3b77a970-96e2-40bd-9d1a-974e00b9546b',
                    notForTagging: false,
                    label: 'creative',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0bab96d9-63a4-4ac2-9dbb-02dcc8ea20f5',
                    notForTagging: false,
                    label: 'cruel',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7dcd51b5-ebb8-4b6f-9d9f-ce8207ed4a86',
                    notForTagging: false,
                    label: 'deceitful',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4a5a96da-9189-4119-9682-b438af36637b',
                    notForTagging: true,
                    label: 'decisive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d4d06de9-88d5-4a26-985e-cf290d592ab4',
                    notForTagging: true,
                    label: 'dependent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7922f4c0-d651-42d3-8f00-26d4ee106c01',
                    notForTagging: true,
                    label: 'determined',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6cfe3b4a-425c-47cd-97c7-c23d3661651b',
                    notForTagging: false,
                    label: 'easy-going',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/60a1aced-9457-426b-a4ca-7c6788c8337b',
                    notForTagging: false,
                    label: 'egocentric',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/71998fa7-4a54-40e8-9894-0e19d6704f92',
                    notForTagging: false,
                    label: 'enthusiastic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/efbf9d91-d16a-4316-be1c-af8606498739',
                    notForTagging: true,
                    label: 'fearful',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/320e7840-f176-4a99-a03f-6a1eebddab74',
                    notForTagging: true,
                    label: 'friendly',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/474c059e-ea03-444f-965b-9b5d1ebedf9d',
                    notForTagging: false,
                    label: 'funny',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8e7a09c8-5774-4659-8a6b-0c960a030d0a',
                    notForTagging: true,
                    label: 'generous',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cb345a42-c256-41c6-99a4-a25bcd066d68',
                    notForTagging: false,
                    label: 'greedy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/688e409a-73c6-4ce6-981e-c58a81a7c6db',
                    notForTagging: false,
                    label: 'idealistic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/34498e94-97d3-450e-a73d-ae46bf2813fb',
                    notForTagging: false,
                    label: 'independent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/47d0c508-53ea-4115-bd70-88ed74219432',
                    notForTagging: false,
                    label: 'insecure',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/469a2efb-42b6-458d-8a73-5ded6a2eb05d',
                    notForTagging: false,
                    label: 'intelligent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/07183edb-18ad-40ba-b3f3-27e356af7b8e',
                    notForTagging: false,
                    label: 'irreverent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4ccde3fd-ded2-45c0-ad59-0aa383b3ab9d',
                    notForTagging: false,
                    label: 'irritable',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5d6278c7-9d92-493b-912a-566d02702994',
                    notForTagging: true,
                    label: 'jealous',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4fe39d46-2cba-4846-b143-4074a5c2ed72',
                    notForTagging: false,
                    label: 'kind',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d6f69a37-7cd9-4a81-9075-d3885b860534',
                    notForTagging: false,
                    label: 'lazy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a74d157e-ed3e-4f47-a091-d0a48a7b43df',
                    notForTagging: true,
                    label: 'logical',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/21a9132d-7b1c-41ec-9b89-4d9d6cac13a1',
                    notForTagging: false,
                    label: 'loyal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/54b44947-b890-4bb5-9f38-3f3834be4582',
                    notForTagging: true,
                    label: 'manipulative',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/42b58c9c-0027-44d5-8e26-bc1e5f6f378e',
                    notForTagging: true,
                    label: 'modest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1678b154-db19-4920-87d4-71365f472771',
                    notForTagging: false,
                    label: 'naive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3285ae85-72ed-4bee-803d-4ad68b0eb4ca',
                    notForTagging: false,
                    label: 'neurotic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d83075ad-b286-4a16-957b-118b26276d9f',
                    notForTagging: true,
                    label: 'opportunistic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ae9fc9f5-8b45-4230-aae5-8a14949a0901',
                    notForTagging: true,
                    label: 'optimistic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e43c599f-13df-4b09-be11-a5e784535071',
                    notForTagging: true,
                    label: 'passionate',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e2a87823-4b12-4a91-8e25-5fce71c45398',
                    notForTagging: false,
                    label: 'personable',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d0213356-bbeb-4dcf-af46-49467e99cce9',
                    notForTagging: true,
                    label: 'persuasive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/270a5a93-644e-487e-9ace-6a58643aef18',
                    notForTagging: false,
                    label: 'pessimistic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ad434871-9aa5-482c-a316-588e4f367300',
                    notForTagging: true,
                    label: 'pious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d4d8d2bc-cdb3-4c64-8097-f969773c0277',
                    notForTagging: true,
                    label: 'popular',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a3bd07f4-9c5b-435f-a061-bb667e5b9382',
                    notForTagging: false,
                    label: 'promiscuous',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/59f9fb7a-6bbe-433b-ad96-a496b9848014',
                    notForTagging: true,
                    label: 'protective',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3d5c2d3d-ce82-43dc-8e61-2765403429c6',
                    notForTagging: false,
                    label: 'reserved',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0f83bcd0-6814-423c-af04-1b5bd3c4388c',
                    notForTagging: false,
                    label: 'ruthless',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b779749f-c750-4efe-8944-b4d58989f3d8',
                    notForTagging: true,
                    label: 'sarcastic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ee3f32cc-0c42-480e-bc6c-8cb7b7906268',
                    notForTagging: true,
                    label: 'secretive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4cb64024-f517-4365-bee1-9c4a3c58837d',
                    notForTagging: false,
                    label: 'sentimental',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b8fbbc1f-8413-4ad8-bbd8-d199c35e3813',
                    notForTagging: false,
                    label: 'sophisticated',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/382d7061-1692-4154-ba6c-eb843c4e7881',
                    notForTagging: false,
                    label: 'stern',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6173dfb9-86bf-4abc-9835-0fe4f060320e',
                    notForTagging: false,
                    label: 'stubborn',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/11414dfa-5467-46e0-b4d4-61227470cc48',
                    notForTagging: true,
                    label: 'sympathetic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/72957c9a-7350-49e9-a256-64cd59c1bb23',
                    notForTagging: true,
                    label: 'unconventional',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/50fbd05c-42fa-4472-8993-725d568163dc',
                    notForTagging: false,
                    label: 'unintelligent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f62be6fd-1e3d-4c64-83f7-0e37cc4649fc',
                    notForTagging: false,
                    label: 'wise',
                    terms: [],
                },
            ],
        },
        {
            label: 'Cinematic Techniques',
            type: 'http://data.disney.com/resources/CinematicTechniques',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/10400164-1f0e-1a39-aa7b-86a863eec981',
                    notForTagging: false,
                    label: 'interview montage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d8ff02a0-30a6-4814-8385-fa12fd776be8',
                    notForTagging: false,
                    label: 'montage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fecf7884-8c7f-42a6-a770-4696a9a8f54c',
                    notForTagging: false,
                    label: 'parallel editing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/562c01c5-2822-447f-bc05-c72e4968fe07',
                    notForTagging: false,
                    label: 'point of view',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e8119eb3-9869-4683-999f-c9016039c808',
                    notForTagging: false,
                    label: 'split screen',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/61c43062-3c83-4959-8415-770af43cd853',
                    notForTagging: false,
                    label: 'voice-over',
                    terms: [],
                },
            ],
        },
        {
            label: 'Disability',
            type: 'http://data.disney.com/resources/Disability',
            categories: [
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#59780714-40ea-42a3-8d7d-998f1995eba8',
                    notForTagging: false,
                    label: 'cognitive/intellectual disability',
                    terms: [
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#b5e5f263-a78e-4fdc-b62c-f64e01a21ee9',
                            notForTagging: false,
                            label: 'fragile x syndrome',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#59780714-40ea-42a3-8d7d-998f1995eba8',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#2e8ba84e-83fc-41b2-b0c2-b9ca592c0445',
                            notForTagging: false,
                            label: 'Down syndrome',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#59780714-40ea-42a3-8d7d-998f1995eba8',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#e345ed08-7d9c-4dfa-a0f1-cb90466f8105',
                    notForTagging: false,
                    label: 'health conditions/chronic illness',
                    terms: [
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#aa43f799-da8a-4adb-a94a-4a5456c5377a',
                            notForTagging: false,
                            label: 'cancer',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#e345ed08-7d9c-4dfa-a0f1-cb90466f8105',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#df880a13-5cd4-4dc7-8ba7-f0f8b4e860d2',
                            notForTagging: false,
                            label: 'diabetes',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#e345ed08-7d9c-4dfa-a0f1-cb90466f8105',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#1024665b-89ad-461c-8d80-6b32b46b12fb',
                            notForTagging: false,
                            label: 'HIV infection',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#e345ed08-7d9c-4dfa-a0f1-cb90466f8105',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#3791d76c-2c13-46ff-ab9b-574b55b12d00',
                    notForTagging: false,
                    label: 'hearing disability',
                    terms: [
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#77d56ff8-9311-4453-8101-ce0424ee3c7a',
                            notForTagging: false,
                            label: 'auditory processing challenges',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#3791d76c-2c13-46ff-ab9b-574b55b12d00',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#5426cf7d-a950-4f9f-89d5-4877df272eb0',
                            notForTagging: false,
                            label: 'hearing loss',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#3791d76c-2c13-46ff-ab9b-574b55b12d00',
                            terms: [
                                {
                                    id: 'http://example.com/DescriptiveConcepts_TaskCommitted#3dcdacf2-d7f9-4270-ade4-f48e801a2d03',
                                    notForTagging: false,
                                    label: 'deafness',
                                    broaderId:
                                        'http://example.com/DescriptiveConcepts_TaskCommitted#5426cf7d-a950-4f9f-89d5-4877df272eb0',
                                    terms: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#04966e30-5ed2-42b1-a0c5-2b69ff4f7ac8',
                    notForTagging: false,
                    label: 'mental health condition',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/2937c1f3-9eda-4ae1-a89c-80f7b5f0ea56',
                            notForTagging: false,
                            label: 'addiction',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#04966e30-5ed2-42b1-a0c5-2b69ff4f7ac8',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#4961d04e-3fd3-4f49-912f-67f5dcb87be1',
                            notForTagging: false,
                            label: 'major depressive disorder',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#04966e30-5ed2-42b1-a0c5-2b69ff4f7ac8',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#fa570581-a6cd-4ec0-afa2-d07f67736b3f',
                            notForTagging: false,
                            label: 'dissociative disorder',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#04966e30-5ed2-42b1-a0c5-2b69ff4f7ac8',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#cd3df984-fbbb-436f-8d94-68c1184374e1',
                            notForTagging: false,
                            label: 'schizophrenia',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#04966e30-5ed2-42b1-a0c5-2b69ff4f7ac8',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#841f664f-7a8b-44ae-a307-334981af97b1',
                            notForTagging: false,
                            label: 'post-traumatic stress disorder (PTSD/CPPTSD)',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#04966e30-5ed2-42b1-a0c5-2b69ff4f7ac8',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#d6cf2516-4536-4cd7-8150-c8d36d3b1013',
                            notForTagging: false,
                            label: 'anxiety',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#04966e30-5ed2-42b1-a0c5-2b69ff4f7ac8',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#ea883bca-3acd-45c2-bf71-e9a1321bd027',
                            notForTagging: false,
                            label: 'obsessive-compulsive disorder (OCD)',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#04966e30-5ed2-42b1-a0c5-2b69ff4f7ac8',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#2cef87cb-a1c8-407e-a1ca-142574faa9de',
                            notForTagging: false,
                            label: 'borderline personality disorder (BPD)',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#04966e30-5ed2-42b1-a0c5-2b69ff4f7ac8',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#cf162f2f-9065-429c-b60b-a2d46bba1ebe',
                            notForTagging: false,
                            label: 'bipolar disorder',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#04966e30-5ed2-42b1-a0c5-2b69ff4f7ac8',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#9615cb8a-0bdd-4ac7-b388-773aa4d079e2',
                    notForTagging: false,
                    label: 'neurodivergent',
                    terms: [
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#f0350251-dcdb-42a0-a6ff-1c8916dc8ff3',
                            notForTagging: false,
                            label: 'dyslexia',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#9615cb8a-0bdd-4ac7-b388-773aa4d079e2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#fd4c1a6f-8e2b-4a7f-83d4-cb8615182124',
                            notForTagging: false,
                            label: 'ADHD',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#9615cb8a-0bdd-4ac7-b388-773aa4d079e2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#cf7f9f2c-63d8-45b8-b79d-d3fea6ed0014',
                            notForTagging: false,
                            label: 'learning disability',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#9615cb8a-0bdd-4ac7-b388-773aa4d079e2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#90a234a3-8f6d-4ff4-9ca0-0c495cc1b959',
                            notForTagging: false,
                            label: 'dyscalculia',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#9615cb8a-0bdd-4ac7-b388-773aa4d079e2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#f49082a7-7911-49ac-be78-62bbdbd1b3b7',
                            notForTagging: false,
                            label: 'sensory processing challenges',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#9615cb8a-0bdd-4ac7-b388-773aa4d079e2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#a7f27224-cf30-4caf-9460-82a763ec8070',
                            notForTagging: false,
                            label: 'autism spectrum disorder',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#9615cb8a-0bdd-4ac7-b388-773aa4d079e2',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#23c3e3a1-fac6-4e80-89c2-433c96cc670a',
                    notForTagging: false,
                    label: 'neurological disorder',
                    terms: [
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#28fe0bac-fb2e-44b1-90ae-6adc8c799ba7',
                            notForTagging: false,
                            label: "Alzheimer's disease",
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#23c3e3a1-fac6-4e80-89c2-433c96cc670a',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#032f6799-0be7-4294-8ef9-aeb59593dc0f',
                            notForTagging: false,
                            label: 'Tourette syndrome',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#23c3e3a1-fac6-4e80-89c2-433c96cc670a',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#ac98eecf-f7c3-42ab-a6c2-891933568200',
                            notForTagging: false,
                            label: 'epilepsy',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#23c3e3a1-fac6-4e80-89c2-433c96cc670a',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                    notForTagging: false,
                    label: 'physical/structural disability',
                    terms: [
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#c0b2be1d-0873-4bb6-88cf-06c3f3f8f559',
                            notForTagging: false,
                            label: 'debilitating pain',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#66678e1c-d8ec-4ec2-abcb-d4525a439537',
                            notForTagging: false,
                            label: 'chronic fatigue syndrome',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#f2805140-e22a-4c62-8b71-6515d8e8e7c1',
                            notForTagging: false,
                            label: 'muscular dystrophy',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#27567d3f-4fb9-4679-bff4-8c8afaa35bcc',
                            notForTagging: false,
                            label: 'fibromyalgia',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#211bef08-4268-492e-867d-d6f325d6f278',
                            notForTagging: false,
                            label: 'complex regional pain syndrome (CRPS)',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#46c9913d-942a-4545-a2c1-12b3aaf70377',
                            notForTagging: false,
                            label: 'non visible/non-apparent',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#b19f3b3b-6933-4634-b1bd-bf0ff252213d',
                            notForTagging: false,
                            label: 'amputee',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#617d42b2-0fcf-49fc-a649-3cc45ac4dc1d',
                            notForTagging: false,
                            label: 'multiple sclerosis',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#51c1a53c-053a-44ec-9ef5-e81a8eddaf49',
                            notForTagging: false,
                            label: 'mobility conditions requiring the use of a wheelchair, crutches, walker and/or other mobility devices',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#908122ae-910c-4cc5-9baf-d23beda7e91e',
                            notForTagging: false,
                            label: 'limb differences',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#b37ae65f-4304-49b8-bf55-497d084e9511',
                            notForTagging: false,
                            label: 'cerebral palsy',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#d2a9b646-6a30-48f7-a9c7-1b62be37936f',
                            notForTagging: false,
                            label: 'rheumatoid arthritis',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#ca371d1b-7412-4fed-a44a-e94514639d2b',
                            notForTagging: false,
                            label: 'cystic fibrosis',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#a696f8e9-e3d2-47a3-8fdf-1cf29f4d5fae',
                            notForTagging: false,
                            label: 'partial or completely missing limbs',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#23ba3209-903d-4d7c-b725-1ebe29a404ff',
                            notForTagging: false,
                            label: 'short stature, little person',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#e8cc1562-27d9-4419-9da4-72269408e462',
                            notForTagging: false,
                            label: 'facial differences',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#16fd6e42-e38e-469f-9b87-a0c0a5a931a2',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#8ae06650-1a49-480e-be4f-83887430f7be',
                    notForTagging: false,
                    label: 'speech/communication disability',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#0492eb16-6506-4ae5-942a-2ed03e4742db',
                    notForTagging: false,
                    label: 'visual disability',
                    terms: [
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#8b149c64-c2d4-4c54-a043-0a39f9e08004',
                            notForTagging: false,
                            label: 'macular degeneration',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#0492eb16-6506-4ae5-942a-2ed03e4742db',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#6d1f6035-1a9a-41c6-9752-b325de9bee64',
                            notForTagging: false,
                            label: 'glaucoma',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#0492eb16-6506-4ae5-942a-2ed03e4742db',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#fea0ffd8-81d5-4e41-a48e-1939a8cfcb9e',
                            notForTagging: false,
                            label: 'low vision',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#0492eb16-6506-4ae5-942a-2ed03e4742db',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1f4dd1a2-2699-c026-d809-ef84abd0af91',
                            notForTagging: false,
                            label: 'blindness',
                            broaderId:
                                'http://example.com/DescriptiveConcepts_TaskCommitted#0492eb16-6506-4ae5-942a-2ed03e4742db',
                            terms: [],
                        },
                    ],
                },
            ],
        },
        {
            label: 'Disability State',
            type: 'http://data.disney.com/resources/DisabilityState',
            categories: [
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#36cdda7f-127b-4140-863c-52cfb00c9d2b',
                    notForTagging: false,
                    label: 'temporary',
                    terms: [],
                },
            ],
        },
        {
            label: 'Disciplines & Industries',
            type: 'http://data.disney.com/resources/DisciplinesIndustries',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/0793d9d2-bedc-44a4-8bb1-b0d8d128d499',
                    notForTagging: false,
                    label: 'accounting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a0d70d0c-eb5b-4bf9-b762-15bc51fafffb',
                    notForTagging: false,
                    label: 'adult entertainment',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/56103103-0f46-4acf-b54b-ae8047298879',
                    notForTagging: false,
                    label: 'advertising',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ca0b00d5-d8da-4304-9022-c2117d6a98e3',
                    notForTagging: false,
                    label: 'architecture',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4089039a-c6bb-4a86-94b2-39ba85563dd0',
                    notForTagging: false,
                    label: 'armed forces',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8cfe8f9b-b1f4-4a1d-8c55-cf7d4c888fe8',
                    notForTagging: false,
                    label: 'banking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a73f6950-07db-4c0a-97fe-985faa5ce793',
                    notForTagging: false,
                    label: 'broadcasting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2098902c-f690-4b05-9f84-2efa61faf88c',
                    notForTagging: false,
                    label: 'child care',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8f10b3c2-3669-486e-ae30-3cbeef7b1c27',
                    notForTagging: false,
                    label: 'computer programming',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6c318feb-58c6-9715-8736-2e31bdbaef85',
                    notForTagging: false,
                    label: 'construction',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/48ff886f-e59a-4601-83aa-659f70c48d0e',
                    notForTagging: false,
                    label: 'cosmetology',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0133c6f2-3924-4de7-9df7-a06181b96ecd',
                    notForTagging: false,
                    label: 'criminal justice',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ec2abd60-de97-401a-b574-3d1c045194c6',
                    notForTagging: false,
                    label: 'dance',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/01c44eb4-e93d-418e-8213-13251300faeb',
                            notForTagging: false,
                            label: 'ballet',
                            broaderId:
                                'http://data.disney.com/resources/ec2abd60-de97-401a-b574-3d1c045194c6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a1615361-025b-4606-bb8f-2b4c66c52641',
                            notForTagging: false,
                            label: 'db-test',
                            broaderId:
                                'http://data.disney.com/resources/ec2abd60-de97-401a-b574-3d1c045194c6',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/9656bfc2-1c66-4108-8937-c85178741a9c',
                    notForTagging: false,
                    label: 'death care',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/46346458-1877-4c2b-b97e-b2b9ff31b571',
                    notForTagging: false,
                    label: 'dentistry',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/82840dce-8ca9-470c-a252-c960ef8f55bb',
                    notForTagging: false,
                    label: 'detective services',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b8069929-6b91-4295-832d-a21054b1a417',
                    notForTagging: false,
                    label: 'drug trafficking (industry)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/66c72a99-e14a-493b-a81f-d73192910126',
                    notForTagging: false,
                    label: 'dry cleaning and laundry',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2540ec3c-a776-2c5c-2688-2ea495c68aa9',
                    notForTagging: false,
                    label: 'education',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c6103781-e6e5-4460-b957-b5334c51f3a5',
                    notForTagging: false,
                    label: 'farming',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9ea96d1f-8a47-4fe7-8d4f-8d0bece2395a',
                    notForTagging: false,
                    label: 'fashion design',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1725194d-6886-49ad-a549-229ed8d3710e',
                    notForTagging: false,
                    label: 'federal government',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f32c178b-cda4-4456-a987-e39b1d87513b',
                    notForTagging: false,
                    label: 'filmmaking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e4376682-8755-49a2-99c3-65a5624312ce',
                    notForTagging: false,
                    label: 'fine arts',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a83937bb-fa28-88ee-a364-0ad203bd3594',
                    notForTagging: false,
                    label: 'fishing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/60455f56-b41b-d354-5265-e0e7d6bccfbb',
                    notForTagging: false,
                    label: 'gambling (industry)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ff44b4bf-9e14-8a06-5976-89c3c93cf7ad',
                    notForTagging: false,
                    label: 'gardening',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5b0bdfef-8292-438b-947a-cbb5b8f9b9c4',
                    notForTagging: false,
                    label: 'hospitality industry',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1e012622-50e2-4684-a907-51734d3b4000',
                    notForTagging: false,
                    label: 'housekeeping',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e44567b3-d74b-459a-a879-e008dd4350d3',
                    notForTagging: false,
                    label: 'insurance',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e8f712e5-f671-4bab-acae-a0f64a2dc0d7',
                    notForTagging: false,
                    label: 'international relations',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/633384a2-ded9-4405-8f2c-432082f5788f',
                    notForTagging: false,
                    label: 'journalism',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a0846ebc-2fc4-48c6-8a0d-4fc0c36a4f78',
                    notForTagging: false,
                    label: 'law',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/efa80e71-074b-417e-a4b2-cdefb17cb225',
                    notForTagging: false,
                    label: 'management consulting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ef654fe8-d63d-4fb9-81a0-711b550d4169',
                    notForTagging: false,
                    label: 'manufacturing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f85c17a5-dc82-4b68-b806-3756e0c5cb3f',
                    notForTagging: false,
                    label: 'marijuana industry',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5b5513f6-ce01-45d8-9a53-9a4a9b92e589',
                    notForTagging: false,
                    label: 'medicine',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0c42c79a-bb9f-4abc-8fb0-5a3b4b2dca82',
                    notForTagging: false,
                    label: 'mining',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/edc175ef-60e2-4f1e-9aff-ecbb1084f8e2',
                    notForTagging: false,
                    label: 'municipal government',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/db5ca964-c9c8-4d89-a64f-52f735097823',
                    notForTagging: false,
                    label: 'music (industry)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/177d0c1f-68a3-4d50-ab31-efb70d85db14',
                    notForTagging: false,
                    label: 'organized crime (industry)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f003370f-41f6-488b-aece-cbd4563ea312',
                    notForTagging: false,
                    label: 'pet care',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bd4f3aa3-6b30-4f99-84aa-e1a0d1b5dc09',
                    notForTagging: false,
                    label: 'photography (discipline)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/01d393c3-7af1-4835-8293-1ebec31dd112',
                    notForTagging: false,
                    label: 'physical fitness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c49f8442-f347-4314-aa56-0cf98f027df2',
                    notForTagging: false,
                    label: 'politics',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fdfbcd6f-0668-44ed-834c-d6c3e01caa3e',
                    notForTagging: false,
                    label: 'private security',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/33ebaef0-5086-4edf-b47a-5e673628e7b0',
                    notForTagging: false,
                    label: 'prostitution',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5668a190-43e1-4625-a306-2030becb52b6',
                    notForTagging: false,
                    label: 'psychotherapy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dac074aa-b02c-416d-9c9f-c944435dddc0',
                    notForTagging: false,
                    label: 'public relations',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b5d6b244-24fe-4243-9a32-8478384912d0',
                    notForTagging: false,
                    label: 'public safety',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/53c25f87-0c8e-412c-a8ca-3d7b0fd4aa27',
                    notForTagging: false,
                    label: 'publishing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0fa1907a-c65d-4008-a3c6-3805163e4d47',
                    notForTagging: false,
                    label: 'ranching',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1ad12984-343d-43e3-843a-1a7b9882c569',
                    notForTagging: false,
                    label: 'real estate business',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/67834fba-a41f-4938-be13-5ccdf3ca5f85',
                    notForTagging: false,
                    label: 'recreation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f22a5f12-2d5c-4b90-973b-f00d8d538403',
                    notForTagging: false,
                    label: 'religion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/60be0174-6e30-4803-9e05-2cd743031bd8',
                    notForTagging: false,
                    label: 'science (discipline)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/989defdc-66d9-4254-9f6b-c367103fe2da',
                    notForTagging: false,
                    label: 'selling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b43a9b9a-db3c-8945-0029-a5134f02b28e',
                    notForTagging: false,
                    label: 'sports',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a052205f-93bd-4c35-9b08-db5155a8c793',
                    notForTagging: false,
                    label: 'state government',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5f8a6602-612e-4c6c-8a05-5dd55308a2c3',
                    notForTagging: false,
                    label: 'theater',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b397ce40-cc8e-405b-bf5a-c8cbe1fa91c4',
                    notForTagging: false,
                    label: 'transportation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ed7e5fe9-ea42-4488-a613-a3f3b9b173ad',
                    notForTagging: false,
                    label: 'travel',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/05617d02-82de-4914-93a7-386bdd065d6a',
                    notForTagging: false,
                    label: 'veterinary medicine',
                    terms: [],
                },
            ],
        },
        {
            label: 'Events',
            type: 'http://data.disney.com/resources/Events',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/98946de9-3e49-416e-92db-23321aaea476',
                    notForTagging: false,
                    label: 'Heart Attack',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/383ca852-aa0e-a081-a225-0c0a7e7505ab',
                    notForTagging: false,
                    label: 'accident',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/05108121-1ffb-4686-9e72-89a92f23f3ea',
                            notForTagging: true,
                            label: 'transportation accident',
                            broaderId:
                                'http://data.disney.com/resources/383ca852-aa0e-a081-a225-0c0a7e7505ab',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a6fc229a-2f7a-4d6d-9d80-14597ba06326',
                            notForTagging: true,
                            label: 'oil spill',
                            broaderId:
                                'http://data.disney.com/resources/383ca852-aa0e-a081-a225-0c0a7e7505ab',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/37fc98d8-3aad-412f-9914-fea3ab6ceccd',
                            notForTagging: false,
                            label: 'marine accident',
                            broaderId:
                                'http://data.disney.com/resources/383ca852-aa0e-a081-a225-0c0a7e7505ab',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e7297b0a-9951-4475-b4d6-d72008e0e57b',
                            notForTagging: false,
                            label: 'traffic accident',
                            broaderId:
                                'http://data.disney.com/resources/383ca852-aa0e-a081-a225-0c0a7e7505ab',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a0335944-8294-4ed9-a8a9-1018e96261e7',
                            notForTagging: false,
                            label: 'nuclear accident',
                            broaderId:
                                'http://data.disney.com/resources/383ca852-aa0e-a081-a225-0c0a7e7505ab',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6d578105-6d99-4f47-b5d2-b759e9fcc030',
                            notForTagging: false,
                            label: 'food poisoning',
                            broaderId:
                                'http://data.disney.com/resources/383ca852-aa0e-a081-a225-0c0a7e7505ab',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c36e6287-a55e-490c-b60d-6831700b2ff0',
                            notForTagging: false,
                            label: 'chemical spill',
                            broaderId:
                                'http://data.disney.com/resources/383ca852-aa0e-a081-a225-0c0a7e7505ab',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/968540c9-49b3-4676-af52-fa68b2b8372f',
                            notForTagging: false,
                            label: 'construction accident',
                            broaderId:
                                'http://data.disney.com/resources/383ca852-aa0e-a081-a225-0c0a7e7505ab',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/db3f15e2-cfd9-4a94-baea-cd1575c2de45',
                            notForTagging: false,
                            label: 'aircraft accident',
                            broaderId:
                                'http://data.disney.com/resources/383ca852-aa0e-a081-a225-0c0a7e7505ab',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4f71aefb-c59c-4a67-8d39-0e4c6a7ff81b',
                            notForTagging: false,
                            label: 'emergency',
                            broaderId:
                                'http://data.disney.com/resources/383ca852-aa0e-a081-a225-0c0a7e7505ab',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a479a9dd-43ac-4af4-9781-4d575978dad7',
                            notForTagging: false,
                            label: 'structural failure',
                            broaderId:
                                'http://data.disney.com/resources/383ca852-aa0e-a081-a225-0c0a7e7505ab',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/5354fcfe-75cc-446f-8469-9fdeaede0512',
                    notForTagging: false,
                    label: 'adoption',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c2f6dec2-17a6-41f9-9c6d-59a92aba20f9',
                    notForTagging: false,
                    label: 'animal attack',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0410dc8d-78d5-c0d7-9c75-0768b3f84887',
                    notForTagging: false,
                    label: 'anniversary',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/23b1cc1d-a498-4f6c-bd78-18efbb98034b',
                    notForTagging: false,
                    label: 'arresting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2b3c5be8-3cbe-3a95-ca7d-0396235d4bbd',
                    notForTagging: true,
                    label: 'arrival',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4f82098f-bc5b-4cba-a8fc-566b88a53812',
                    notForTagging: false,
                    label: 'auction',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/79371202-6c3b-cf0b-bf3f-840b0e4414ad',
                    notForTagging: false,
                    label: 'audition',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9fc74f0b-b1e5-46c6-b250-d5a46eec943f',
                    notForTagging: true,
                    label: 'auto show',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f32bd82a-dfc4-485d-8b3e-ba313092c90f',
                    notForTagging: false,
                    label: 'autopsy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d0b23f5a-258d-485e-8d56-e737e66a6a96',
                    notForTagging: true,
                    label: 'bankruptcy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6c8b2c0f-4988-4d7d-81b3-ad60e53b1715',
                    notForTagging: false,
                    label: 'banquet',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ed456491-62f3-9b5f-90a6-fcaf67f9b1a8',
                    notForTagging: false,
                    label: 'barbecue',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bf13c570-25be-41c2-9e83-cd7fc4ba29a7',
                    notForTagging: false,
                    label: 'battle',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1a306426-2899-470f-be79-334f8b7f80d7',
                    notForTagging: false,
                    label: 'beauty contest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f7ae7a43-c092-461e-ae7e-2b45f002db28',
                    notForTagging: false,
                    label: 'beheading',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b3815e58-0468-4be8-9859-9e54b68758e0',
                    notForTagging: false,
                    label: 'birth',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/494a1bf0-dc72-5c18-8ad0-e95c42af26b3',
                    notForTagging: false,
                    label: 'birthday',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4dbc5d92-54f1-4ea8-8bc0-9348b53fcf7f',
                    notForTagging: false,
                    label: 'bombing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/944812c4-c08d-4df3-5a05-15ca4f1d7494',
                    notForTagging: false,
                    label: 'bonfire',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9e6b92ee-775c-4b86-ab4e-25171af12a68',
                    notForTagging: true,
                    label: 'boycott',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2398f61a-addb-4f8c-b813-50591dedb62b',
                    notForTagging: false,
                    label: 'breakup',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/91d70dcc-e045-4f81-818b-9c0e9d25e624',
                    notForTagging: false,
                    label: 'campfire',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8c48ffe7-80c5-d4e5-7240-cfda49e510b7',
                    notForTagging: false,
                    label: 'carnival',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/af3909f5-1d5f-4de5-7465-97aad60376ae',
                    notForTagging: false,
                    label: 'celebration',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/354ed40c-3a4c-13eb-eb83-72cd4f6123bf',
                    notForTagging: false,
                    label: 'ceremony',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/473c2855-31c5-4562-bdd2-49e28c82b38c',
                            notForTagging: false,
                            label: 'funeral',
                            broaderId:
                                'http://data.disney.com/resources/354ed40c-3a4c-13eb-eb83-72cd4f6123bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1600a883-df39-4ebf-86a8-3ec3701276cb',
                            notForTagging: false,
                            label: 'bar mitzvah or bat mitzvah',
                            broaderId:
                                'http://data.disney.com/resources/354ed40c-3a4c-13eb-eb83-72cd4f6123bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/600fae2e-0910-4b8a-9112-b591aed71e4a',
                            notForTagging: false,
                            label: 'religious service',
                            broaderId:
                                'http://data.disney.com/resources/354ed40c-3a4c-13eb-eb83-72cd4f6123bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bf4b6087-72c5-2f1b-99a9-9ab6451f0d92',
                            notForTagging: true,
                            label: 'Rose Ceremony',
                            broaderId:
                                'http://data.disney.com/resources/354ed40c-3a4c-13eb-eb83-72cd4f6123bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/99f1870b-ac8e-4fb8-90b4-33cb9c7d75b2',
                            notForTagging: false,
                            label: 'baptism',
                            broaderId:
                                'http://data.disney.com/resources/354ed40c-3a4c-13eb-eb83-72cd4f6123bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5747ec33-0dad-4a26-9b4f-5df402832832',
                            notForTagging: false,
                            label: 'award presentation',
                            broaderId:
                                'http://data.disney.com/resources/354ed40c-3a4c-13eb-eb83-72cd4f6123bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9b158ef5-eca3-4517-837b-ce199f472d37',
                            notForTagging: false,
                            label: 'inauguration',
                            broaderId:
                                'http://data.disney.com/resources/354ed40c-3a4c-13eb-eb83-72cd4f6123bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a14720b4-18fb-649c-46ae-328c47f2a7a1',
                            notForTagging: false,
                            label: 'graduation ceremony',
                            broaderId:
                                'http://data.disney.com/resources/354ed40c-3a4c-13eb-eb83-72cd4f6123bf',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/1f3401cf-9d02-041d-e742-f1ec66772fa0',
                    notForTagging: false,
                    label: 'circus',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/86c628db-14ed-4463-9a29-bfd7513d85b7',
                    notForTagging: false,
                    label: 'commercial',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e653ebea-239d-4356-a714-3a56175afd9c',
                    notForTagging: false,
                    label: 'committing a crime',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5e34e12c-404a-8c96-91df-d1bacbdd4ab7',
                    notForTagging: false,
                    label: 'concert',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/353800f5-1cd4-46ad-9e4f-28f8d26d7d19',
                    notForTagging: false,
                    label: 'confession',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b87273be-f0ca-4429-bba5-812b1a7d67cf',
                    notForTagging: false,
                    label: 'contest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c1a1bb6d-a49e-475b-a454-ab7b4d0e20e2',
                    notForTagging: false,
                    label: "coup d'etat",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5517c612-73dd-4018-9975-93a222d9b51f',
                    notForTagging: false,
                    label: 'cruise',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c20d24f5-bed5-4dc4-a71b-a122aca74f0c',
                    notForTagging: false,
                    label: 'death',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/40ff8007-df0e-4743-9c2f-8527a82eb3d5',
                            notForTagging: false,
                            label: 'accidental death',
                            broaderId:
                                'http://data.disney.com/resources/c20d24f5-bed5-4dc4-a71b-a122aca74f0c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/43d26ea4-1941-4107-ac3e-d9b712747b09',
                            notForTagging: false,
                            label: 'death by natural causes',
                            broaderId:
                                'http://data.disney.com/resources/c20d24f5-bed5-4dc4-a71b-a122aca74f0c',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/77800e8a-bcae-4027-afaf-fc51df087d2b',
                    notForTagging: false,
                    label: 'debate',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0dbf7e3f-9ceb-49fd-a0e6-eb126b2aaf5a',
                    notForTagging: false,
                    label: 'demonstration',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/8218df3e-01fa-4aba-ae54-07c30ca473c0',
                            notForTagging: false,
                            label: 'strike',
                            broaderId:
                                'http://data.disney.com/resources/0dbf7e3f-9ceb-49fd-a0e6-eb126b2aaf5a',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                    notForTagging: false,
                    label: 'disaster',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/25938781-2115-4609-8eed-21dfe2050464',
                            notForTagging: false,
                            label: 'tornado',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8741dc59-b78d-48f5-881c-d7ef1153efd4',
                            notForTagging: false,
                            label: 'wildfire',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e64b9070-c6ed-f706-48bc-70808e904690',
                            notForTagging: false,
                            label: 'landslide',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4989d725-d963-4f19-9713-9a92cef6ca56',
                            notForTagging: false,
                            label: 'fire',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ad47c63f-2c9e-e30e-b201-7b3fb380f7bc',
                            notForTagging: false,
                            label: 'avalanche',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/62242cc7-5c49-4684-9e36-60c682221386',
                            notForTagging: false,
                            label: 'blizzard',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2b4b7fec-cbf7-dc22-0c4d-aeab31cbcb92',
                            notForTagging: false,
                            label: 'volcanic eruption',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e54185d7-279b-6310-da29-c46194816989',
                            notForTagging: false,
                            label: 'flood',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6595ee08-f5e7-4abb-95c5-295c607d5571',
                            notForTagging: false,
                            label: 'earthquake',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8000970b-eb7c-480d-ab8e-71d76f95e59c',
                            notForTagging: false,
                            label: 'tidal wave',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/898cdd39-bf33-4d82-b479-ccd86962c19f',
                            notForTagging: false,
                            label: 'drought',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b2d72050-55e9-4789-9ea7-96dd565908cf',
                            notForTagging: false,
                            label: 'hurricane',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2d4cde08-266f-0527-5bc9-2071b90da28b',
                            notForTagging: false,
                            label: 'explosion',
                            broaderId:
                                'http://data.disney.com/resources/e8430e62-6ec3-2271-7e4a-58f29596c28e',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/53664a75-87c7-47d2-9d18-f507d9e23b10',
                    notForTagging: false,
                    label: 'discovery',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f7fb0e70-bc0b-44c8-bd4a-87ddea42f57e',
                    notForTagging: false,
                    label: 'divorce',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8a6eb0ec-fd0e-4e91-9312-c45c3ef6ebdf',
                    notForTagging: false,
                    label: 'drowning',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b220866b-9d5c-436b-8841-5e2825987e89',
                    notForTagging: false,
                    label: 'electric power failure',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cb4cfb36-cbfe-4227-94ab-1b14067955e2',
                    notForTagging: false,
                    label: 'emergency landing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/456b9670-ea53-4a41-b57f-d97336706ce9',
                    notForTagging: true,
                    label: 'engagement',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0bf74df4-b413-48a1-b143-6bff33575217',
                    notForTagging: false,
                    label: 'epidemic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/458e333f-c85f-4656-a820-c071455ab72c',
                    notForTagging: false,
                    label: 'evacuation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/45982089-bae9-42c2-9c94-681722320ffa',
                    notForTagging: false,
                    label: 'eviction',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/03b4811c-cf86-489a-8785-26c2cfea6221',
                    notForTagging: false,
                    label: 'execution',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fd4693ce-7b94-4987-b5a1-a1850a9c8539',
                    notForTagging: false,
                    label: 'exhibition',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9d43cece-6493-b421-5075-f59e1e1f1fb2',
                    notForTagging: false,
                    label: 'fair',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/84715c42-3a9c-4d4e-815b-de72f3403381',
                            notForTagging: true,
                            label: 'job fair',
                            broaderId:
                                'http://data.disney.com/resources/9d43cece-6493-b421-5075-f59e1e1f1fb2',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/60453c2d-bb87-4229-b356-398f43f621b8',
                    notForTagging: false,
                    label: 'family get-together',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e5dcf46a-b816-4e06-84ba-8a74ff64d076',
                    notForTagging: false,
                    label: 'fashion show',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9947f393-a881-bf5f-af86-e8c66475d3b0',
                    notForTagging: false,
                    label: 'feeling ill',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ed322f93-8304-5ee8-5023-2223c395d190',
                    notForTagging: false,
                    label: 'festival',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/70e2dca4-96cb-4668-9cb2-f3b1ca23af4d',
                            notForTagging: true,
                            label: 'film festival',
                            broaderId:
                                'http://data.disney.com/resources/ed322f93-8304-5ee8-5023-2223c395d190',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/13b3b84e-cb97-41f6-ad50-e015305dbceb',
                    notForTagging: false,
                    label: 'fight',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8ba1ab54-2dcd-493b-bef8-c5d710846f11',
                    notForTagging: false,
                    label: 'first day of school',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3492de8-9c39-8862-f55f-e033df5534a3',
                    notForTagging: false,
                    label: 'flower show',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2cb9fc41-3949-4a28-9b03-b303919002af',
                    notForTagging: false,
                    label: 'garage sale',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9e168a9e-aca8-4f33-8b49-603ed3570584',
                    notForTagging: false,
                    label: 'having a miscarriage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/55b3d1f7-99d0-4d03-aab0-4f6f674867bc',
                    notForTagging: false,
                    label: 'heat wave',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ca3874db-e60c-8080-6fde-e443271da384',
                    notForTagging: false,
                    label: 'holiday',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4f3d90f6-d32a-9473-3ffe-0aaec2cd2cb7',
                    notForTagging: true,
                    label: 'holidays, religious',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/ab3d5bf8-f441-1417-dbf7-59729ddf3096',
                            notForTagging: false,
                            label: 'Hanukkah',
                            broaderId:
                                'http://data.disney.com/resources/4f3d90f6-d32a-9473-3ffe-0aaec2cd2cb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/17ed074b-429d-40d3-905e-208d960a922f',
                            notForTagging: false,
                            label: 'Passover',
                            broaderId:
                                'http://data.disney.com/resources/4f3d90f6-d32a-9473-3ffe-0aaec2cd2cb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a63bbe57-8169-0aa7-b273-5837edb560b5',
                            notForTagging: false,
                            label: 'Christmas',
                            broaderId:
                                'http://data.disney.com/resources/4f3d90f6-d32a-9473-3ffe-0aaec2cd2cb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/983561a4-bd39-e8c5-3fde-52cd2ab67796',
                            notForTagging: false,
                            label: 'Easter',
                            broaderId:
                                'http://data.disney.com/resources/4f3d90f6-d32a-9473-3ffe-0aaec2cd2cb7',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                    notForTagging: true,
                    label: 'holidays, secular and cultural',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/0c387b72-058f-9b55-b193-ee26734400bc',
                            notForTagging: false,
                            label: "Presidents' Day",
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2139c918-950f-8b83-7ac4-e61db0276987',
                            notForTagging: false,
                            label: 'Independence Day',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2635fd90-83b4-2582-18b8-71a1e30697b2',
                            notForTagging: false,
                            label: "Mother's Day",
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8a929cb1-0679-46cd-9faf-e9dde4418177',
                            notForTagging: false,
                            label: 'Election Day',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ea3fcfbe-f7e9-c1d7-699e-32d4936cbe94',
                            notForTagging: false,
                            label: 'Thanksgiving',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f3a1420b-894d-45c7-8e34-1988c6551793',
                            notForTagging: false,
                            label: 'Homecoming',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a3326dc2-e5cd-b228-58e8-a6585843548f',
                            notForTagging: false,
                            label: "Valentine's Day",
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7e3682a9-5e0f-7b89-d7f5-bc85405f2288',
                            notForTagging: false,
                            label: 'Veterans Day',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8f39dd4d-7a04-caa7-4612-414ab51907ad',
                            notForTagging: false,
                            label: "New Year's Eve",
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2a388c1d-d4b8-cdf4-d9b7-452511b4d596',
                            notForTagging: false,
                            label: 'Halloween',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f4b30428-e2be-4aa0-8508-1b23a97aafd7',
                            notForTagging: false,
                            label: 'Mardi Gras',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8a3795e0-fa94-f6b5-64e5-ded09ed78f9b',
                            notForTagging: false,
                            label: 'Martin Luther King Jr. Day',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b331a983-c259-7c63-180c-61e781107397',
                            notForTagging: false,
                            label: 'Memorial Day',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/04109cc3-109a-43c1-9c35-2b06c8f79b88',
                            notForTagging: false,
                            label: "St. Patrick's Day",
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4142bb2d-4726-472e-8dfb-923562394248',
                            notForTagging: false,
                            label: 'Day of the Dead',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f9ecde64-031c-4a65-962b-b63984ca9424',
                            notForTagging: false,
                            label: 'Black Friday',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0e5d4107-8b74-4096-bd34-eb4fe38eb119',
                            notForTagging: false,
                            label: 'Cyber Monday',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/23387e41-b6c3-02e4-a30f-059c5cdef4a7',
                            notForTagging: false,
                            label: "Father's Day",
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/053726f0-9c50-351f-8f08-a07465ea80a8',
                            notForTagging: false,
                            label: 'Labor Day',
                            broaderId:
                                'http://data.disney.com/resources/983b3727-5c72-aa20-610b-9a04e4a00fbd',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/4f9d83a5-43fc-4a7e-8195-5f7ea041edb9',
                    notForTagging: true,
                    label: 'indictment',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/896a20b0-3ab8-4e6a-85e9-4f74244c397e',
                    notForTagging: true,
                    label: 'inheritance',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a2c059e3-7d62-40e2-97ea-2d13b61ba21b',
                    notForTagging: false,
                    label: 'interview',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9fa2b197-fde8-4a90-a334-765f9f58a5b5',
                    notForTagging: false,
                    label: 'intoxication',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e21c0306-1682-4682-b72f-3a25f7e0f7de',
                    notForTagging: false,
                    label: 'losing consciousness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/df2e0f29-c650-46c7-b72d-cc4c08e1036d',
                    notForTagging: false,
                    label: 'marriage proposal',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/1af7d8e0-9d30-4181-979c-bcaf132ec719',
                            notForTagging: true,
                            label: 'marriage proposal acceptance',
                            broaderId:
                                'http://data.disney.com/resources/df2e0f29-c650-46c7-b72d-cc4c08e1036d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a71960b3-d28b-460f-8184-70e3938ddca4',
                            notForTagging: true,
                            label: 'marriage proposal rejection',
                            broaderId:
                                'http://data.disney.com/resources/df2e0f29-c650-46c7-b72d-cc4c08e1036d',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/f0422134-6970-768d-c8e9-37463b993a97',
                    notForTagging: true,
                    label: 'meals',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/ac46e56f-d90f-26ea-c36e-25ba60a775a3',
                            notForTagging: true,
                            label: 'breakfast',
                            broaderId:
                                'http://data.disney.com/resources/f0422134-6970-768d-c8e9-37463b993a97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/344eb55d-15c2-8164-6ce8-6b56c122ecb0',
                            notForTagging: true,
                            label: 'buffet',
                            broaderId:
                                'http://data.disney.com/resources/f0422134-6970-768d-c8e9-37463b993a97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/87413e55-2c35-1b19-7b19-93f618ccccaf',
                            notForTagging: true,
                            label: 'snacks',
                            broaderId:
                                'http://data.disney.com/resources/f0422134-6970-768d-c8e9-37463b993a97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/70fbcc07-01db-4325-9de5-2acba1f7925c',
                            notForTagging: false,
                            label: 'family meal',
                            broaderId:
                                'http://data.disney.com/resources/f0422134-6970-768d-c8e9-37463b993a97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1845e528-fdd9-1c33-7573-e98e0732bca1',
                            notForTagging: true,
                            label: 'dinner',
                            broaderId:
                                'http://data.disney.com/resources/f0422134-6970-768d-c8e9-37463b993a97',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/d34b6892-8f71-d9df-dcca-551efccc8887',
                                    notForTagging: true,
                                    label: 'main course',
                                    broaderId:
                                        'http://data.disney.com/resources/1845e528-fdd9-1c33-7573-e98e0732bca1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2c4920c3-0d19-ca53-9910-37bea5f23e8c',
                                    notForTagging: true,
                                    label: 'appetizer',
                                    broaderId:
                                        'http://data.disney.com/resources/1845e528-fdd9-1c33-7573-e98e0732bca1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a74ac32f-807a-4946-aacd-2c34a6ab9d81',
                                    notForTagging: true,
                                    label: 'side dish',
                                    broaderId:
                                        'http://data.disney.com/resources/1845e528-fdd9-1c33-7573-e98e0732bca1',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/ef4f0930-44fd-7727-bbbb-cd7e3b60ec93',
                            notForTagging: true,
                            label: 'lunch',
                            broaderId:
                                'http://data.disney.com/resources/f0422134-6970-768d-c8e9-37463b993a97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5e419fc2-1ae9-1290-982b-6733c02c70ba',
                            notForTagging: true,
                            label: 'brunch',
                            broaderId:
                                'http://data.disney.com/resources/f0422134-6970-768d-c8e9-37463b993a97',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/014ec208-03d1-4bd6-ae35-ccc2a8cba45a',
                    notForTagging: false,
                    label: 'meeting',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/fa4898cd-1412-4037-8f48-7b6abca66f6f',
                            notForTagging: false,
                            label: 'town hall meeting',
                            broaderId:
                                'http://data.disney.com/resources/014ec208-03d1-4bd6-ae35-ccc2a8cba45a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/90ec93a8-828f-410b-8be0-0dd5cfd817b7',
                            notForTagging: false,
                            label: 'conferences',
                            broaderId:
                                'http://data.disney.com/resources/014ec208-03d1-4bd6-ae35-ccc2a8cba45a',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/2668c57c-7f8f-4c67-ba77-485c320bf3ff',
                    notForTagging: false,
                    label: 'memorial service',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ace56f0e-f74e-40bb-a611-9b9f182632fd',
                    notForTagging: false,
                    label: 'near-death experience',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bae20cff-b1cc-4652-a77e-1579287ef7ef',
                    notForTagging: false,
                    label: 'panic attack',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fb395925-8c31-ac74-9301-6c443be8d2ab',
                    notForTagging: false,
                    label: 'parade',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/74ca51be-6314-4b90-8c1a-fc1ec22297fe',
                            notForTagging: false,
                            label: 'gay pride parade',
                            broaderId:
                                'http://data.disney.com/resources/fb395925-8c31-ac74-9301-6c443be8d2ab',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/b940479d-5f64-4624-b570-8135ae98edc5',
                    notForTagging: false,
                    label: 'parent-teacher conference',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/deb97855-e9bb-4aba-af32-8ab103cf49ae',
                    notForTagging: false,
                    label: 'parole',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/eb391fdd-08f2-fdda-3eaf-303cf3739092',
                    notForTagging: false,
                    label: 'party',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/04103c8e-e81d-4c70-9c75-0768b3f89289',
                            notForTagging: false,
                            label: 'prom',
                            broaderId:
                                'http://data.disney.com/resources/eb391fdd-08f2-fdda-3eaf-303cf3739092',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2fc6ee5b-7e9e-43f5-aafa-5d6d2c237172',
                            notForTagging: false,
                            label: 'ball (party)',
                            broaderId:
                                'http://data.disney.com/resources/eb391fdd-08f2-fdda-3eaf-303cf3739092',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dd2073f5-bfe0-4ec5-8d76-900571dd14b5',
                            notForTagging: true,
                            label: 'sweet sixteen',
                            broaderId:
                                'http://data.disney.com/resources/eb391fdd-08f2-fdda-3eaf-303cf3739092',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5930d3e6-bd9f-320a-862b-445f304e04ae',
                            notForTagging: true,
                            label: 'birthday party',
                            broaderId:
                                'http://data.disney.com/resources/eb391fdd-08f2-fdda-3eaf-303cf3739092',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d994296a-a498-4f0e-961e-d37a25461a53',
                            notForTagging: false,
                            label: 'bachelor or bachelorette party',
                            broaderId:
                                'http://data.disney.com/resources/eb391fdd-08f2-fdda-3eaf-303cf3739092',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8a955af0-b187-4361-bddd-d84949434bef',
                            notForTagging: false,
                            label: 'surprise party',
                            broaderId:
                                'http://data.disney.com/resources/eb391fdd-08f2-fdda-3eaf-303cf3739092',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ffb60396-8d01-4a21-876f-20a4c7f3223b',
                            notForTagging: false,
                            label: 'shower (party)',
                            broaderId:
                                'http://data.disney.com/resources/eb391fdd-08f2-fdda-3eaf-303cf3739092',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/5271696e-7c1c-4dfb-9a5b-ada8bf9a05b6',
                    notForTagging: false,
                    label: 'pep rally',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/224014f4-2c16-420f-7454-a76a1065df89',
                    notForTagging: false,
                    label: 'performance',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/621c54b9-60a6-4ca2-8df2-f593f90e3f2d',
                    notForTagging: false,
                    label: 'pet adoption',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/87407598-2cba-da52-94b7-df3d4c25a7a6',
                    notForTagging: false,
                    label: 'photo shoot',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/203af0fe-48d2-8709-2616-6e5746336cb0',
                    notForTagging: false,
                    label: 'picnic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6d13a60e-d5bc-471f-94d9-c572b6fd9c82',
                    notForTagging: true,
                    label: 'pilgrimage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3927a485-4180-4019-9d1c-73d7a9a79199',
                    notForTagging: false,
                    label: 'political event',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/d4557acf-e69e-4d09-8a53-001a2be63f13',
                            notForTagging: false,
                            label: 'election',
                            broaderId:
                                'http://data.disney.com/resources/3927a485-4180-4019-9d1c-73d7a9a79199',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bcf9282c-0a21-4678-b695-1ffd9b1c024f',
                            notForTagging: false,
                            label: 'political rally',
                            broaderId:
                                'http://data.disney.com/resources/3927a485-4180-4019-9d1c-73d7a9a79199',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/49903fe6-a515-46e7-9252-ca4d12536b08',
                            notForTagging: true,
                            label: 'political campaign',
                            broaderId:
                                'http://data.disney.com/resources/3927a485-4180-4019-9d1c-73d7a9a79199',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/9002bcd0-0c63-4df9-8a7b-1933b4310a32',
                    notForTagging: false,
                    label: 'pregnancy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fe3b17d2-7529-dd2a-a8d6-0ca1afe288b8',
                    notForTagging: true,
                    label: 'premiere',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/82c0f31e-f3aa-4028-8d48-46fd0aa8b3fb',
                    notForTagging: false,
                    label: 'press conference',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/81c38285-98f9-45f5-91b9-a3ac51e33837',
                    notForTagging: false,
                    label: 'prison escape',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e2f553b1-e7f1-4d02-ba1d-7504fdee9c81',
                    notForTagging: false,
                    label: 'product recall',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e44d2b8e-23b6-8b49-3e47-856f9f58e489',
                    notForTagging: false,
                    label: 'race (event)',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/f25eca60-057b-4a7e-a364-f68f36f73180',
                            notForTagging: true,
                            label: 'triathlon',
                            broaderId:
                                'http://data.disney.com/resources/e44d2b8e-23b6-8b49-3e47-856f9f58e489',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8e88b7f5-4f18-4d39-94b9-b00f3abc091f',
                            notForTagging: true,
                            label: 'marathon',
                            broaderId:
                                'http://data.disney.com/resources/e44d2b8e-23b6-8b49-3e47-856f9f58e489',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/fb0ccc13-e080-49d7-af39-19e7d8923de8',
                    notForTagging: false,
                    label: 'rehearsal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/134a478d-13d3-4bff-b741-a3b767610d28',
                    notForTagging: false,
                    label: 'resignation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2f50bc11-9b1a-428e-b38f-280240be49d0',
                    notForTagging: true,
                    label: 'retirement',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3de03691-be7a-426f-b111-19d3dd1b3d40',
                    notForTagging: false,
                    label: 'reunion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/096f5788-1e9a-498a-9d3b-ad16631101e5',
                    notForTagging: true,
                    label: 'revolution',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5e968724-a1d1-45ba-a379-f1f53cdfa136',
                    notForTagging: false,
                    label: 'riot',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6943ee33-7a4c-845b-8f8f-8d44ecb3c98f',
                    notForTagging: false,
                    label: 'rodeo',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/814b0288-8cf0-cef7-6717-fb2699a563b8',
                    notForTagging: false,
                    label: 'science fair',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4497fc7b-fd2b-4fd6-9288-24132d60eaa5',
                    notForTagging: false,
                    label: 'separation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5180ca2c-a0c0-488a-8746-adc089cfc8a0',
                    notForTagging: true,
                    label: 'sex scandal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/db4dcb97-20af-28f3-3e0f-4536f7065995',
                    notForTagging: false,
                    label: 'shooting',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/8ab3964a-76d8-409c-bd72-3925baf6cf06',
                            notForTagging: false,
                            label: 'police shooting',
                            broaderId:
                                'http://data.disney.com/resources/db4dcb97-20af-28f3-3e0f-4536f7065995',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/21a93de9-6621-4b89-ba1d-6be886e09220',
                    notForTagging: false,
                    label: 'speed contest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0ff0c019-9afd-4f9d-9d1f-99c94d12b575',
                    notForTagging: true,
                    label: 'spelling bee',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bb3af577-d621-0cc2-5dd0-6ca762599db8',
                    notForTagging: false,
                    label: 'sporting event',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/04105683-4021-27d5-9d37-d18e8aedf384',
                    notForTagging: false,
                    label: 'storm',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e4aa522f-6b00-4d28-a57a-fd25f3a8fcbc',
                    notForTagging: false,
                    label: 'surgery',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/9fb22bee-b7d3-4509-bff0-627bbf761543',
                            notForTagging: true,
                            label: 'transplantation',
                            broaderId:
                                'http://data.disney.com/resources/e4aa522f-6b00-4d28-a57a-fd25f3a8fcbc',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9aecd563-6f3a-4689-989d-254db561d8d5',
                            notForTagging: true,
                            label: 'plastic surgery',
                            broaderId:
                                'http://data.disney.com/resources/e4aa522f-6b00-4d28-a57a-fd25f3a8fcbc',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/7ecbbc46-4bbd-4f17-9b17-a0a1f08f2305',
                                    notForTagging: true,
                                    label: 'face lift',
                                    broaderId:
                                        'http://data.disney.com/resources/9aecd563-6f3a-4689-989d-254db561d8d5',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/08f8649d-2ed3-4076-94b3-ff184ff0db23',
                            notForTagging: false,
                            label: 'abortion',
                            broaderId:
                                'http://data.disney.com/resources/e4aa522f-6b00-4d28-a57a-fd25f3a8fcbc',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/9b9bcfa2-3324-4851-953f-666af4b77264',
                    notForTagging: false,
                    label: 'talking on the phone',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e637dc6b-bb17-4b5d-8dce-3fc5b72abe36',
                    notForTagging: false,
                    label: 'tournament',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f84ff3e6-88f6-c0b6-9c28-219b5d5c98a1',
                    notForTagging: true,
                    label: 'traffic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e274f940-674d-476b-936c-45224dbc3428',
                    notForTagging: false,
                    label: 'trial',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0410d98d-a063-76f6-9c75-0768b3f88786',
                    notForTagging: false,
                    label: 'vacation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/593eb277-7a95-42c5-bfce-180eb1de3f61',
                    notForTagging: false,
                    label: 'vehicle pursuit',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/44615b3e-aaed-4868-b15f-a8bbac59054f',
                    notForTagging: false,
                    label: 'verdict',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/969c9797-0287-4892-9444-e7bbaa4f0367',
                    notForTagging: true,
                    label: 'vigil',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/db9ae9eb-a9b5-45d6-92ef-24f25425d3ee',
                    notForTagging: true,
                    label: 'virginity, loss of',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6638227d-d49b-ba8b-8b73-efc708b72594',
                    notForTagging: false,
                    label: 'wedding',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/81ef2ef2-8124-43fe-ba0b-849d9d816a4a',
                    notForTagging: false,
                    label: 'wedding abandonment',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4f46529f-5489-80de-8cc8-eac2d088a6bc',
                    notForTagging: true,
                    label: 'wedding vow renewal ceremony',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dabba73e-e1f6-4f25-a160-f56714baac1d',
                    notForTagging: true,
                    label: 'windfall',
                    terms: [],
                },
            ],
        },
        {
            label: 'Gender',
            type: 'http://data.disney.com/resources/Genders',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/5bda59dd-3e77-4b2b-9264-3481150263a7',
                    notForTagging: false,
                    label: 'Female',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/d81ba664-efbd-4a40-849a-a2a8dee37f78',
                            notForTagging: false,
                            label: 'cis female',
                            broaderId:
                                'http://data.disney.com/resources/5bda59dd-3e77-4b2b-9264-3481150263a7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1e83815a-49aa-4c8f-8646-d15364b20c72',
                            notForTagging: false,
                            label: 'intersex female',
                            broaderId:
                                'http://data.disney.com/resources/5bda59dd-3e77-4b2b-9264-3481150263a7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cf5603b7-1054-4083-b8b2-c0f646eff88c',
                            notForTagging: false,
                            label: 'trans female',
                            broaderId:
                                'http://data.disney.com/resources/5bda59dd-3e77-4b2b-9264-3481150263a7',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/8f73a2f8-d2f3-4ab6-ab0b-b9963bc14f5d',
                    notForTagging: false,
                    label: 'Male',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/b0cf2479-b345-4ea8-9b05-8f20b84bf548',
                            notForTagging: false,
                            label: 'intersex male',
                            broaderId:
                                'http://data.disney.com/resources/8f73a2f8-d2f3-4ab6-ab0b-b9963bc14f5d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/81a16823-3034-4c96-b177-7c3ff0ecc3a0',
                            notForTagging: false,
                            label: 'trans male',
                            broaderId:
                                'http://data.disney.com/resources/8f73a2f8-d2f3-4ab6-ab0b-b9963bc14f5d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8c8125c4-024d-4193-ae08-d7b95cd15993',
                            notForTagging: false,
                            label: 'cis male',
                            broaderId:
                                'http://data.disney.com/resources/8f73a2f8-d2f3-4ab6-ab0b-b9963bc14f5d',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/6710c7ed-d6cb-4c03-91ef-e2bccace5b9f',
                    notForTagging: false,
                    label: 'Non-binary',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/e6884306-f32e-4ad5-a3de-e646c5ad84b1',
                            notForTagging: false,
                            label: 'intersex',
                            broaderId:
                                'http://data.disney.com/resources/6710c7ed-d6cb-4c03-91ef-e2bccace5b9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/417208ef-f8f4-40fd-9dd1-3fbc7de667a3',
                            notForTagging: false,
                            label: 'trans nonbinary',
                            broaderId:
                                'http://data.disney.com/resources/6710c7ed-d6cb-4c03-91ef-e2bccace5b9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/99c5e7e0-50bc-4d0f-ae19-4243c41573ae',
                            notForTagging: false,
                            label: 'genderfluid',
                            broaderId:
                                'http://data.disney.com/resources/6710c7ed-d6cb-4c03-91ef-e2bccace5b9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8ce494b8-d86a-47b9-9763-58736ae047ec',
                            notForTagging: false,
                            label: 'pangender',
                            broaderId:
                                'http://data.disney.com/resources/6710c7ed-d6cb-4c03-91ef-e2bccace5b9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0f3494b9-0ab4-4607-ae3d-067a1af89bcc',
                            notForTagging: false,
                            label: 'agender',
                            broaderId:
                                'http://data.disney.com/resources/6710c7ed-d6cb-4c03-91ef-e2bccace5b9f',
                            terms: [],
                        },
                    ],
                },
            ],
        },
        {
            label: 'Generations',
            type: 'http://data.disney.com/resources/Generations',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/49b20ec3-ecfc-4c25-bc7d-0a53fc546456',
                    notForTagging: true,
                    label: '20-somethings',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ebcbd958-7d4c-4c87-bc01-ec5d069ce0c8',
                    notForTagging: true,
                    label: '30s',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/55611225-4667-4a67-a182-48d6e607a66d',
                    notForTagging: true,
                    label: '40s',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e570938c-fbd4-42da-88af-b70f649126c8',
                    notForTagging: true,
                    label: '50s',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ccea9cc6-4602-4f2f-9af0-ee80872094e9',
                    notForTagging: true,
                    label: 'Adults',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a4c7d1e2-5bee-46f9-bbed-2f5e36b6ceb7',
                    notForTagging: true,
                    label: 'Children',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e0530e0f-c3b9-4198-a3df-e219af516030',
                    notForTagging: false,
                    label: 'Generation Z',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/92b8e681-e1d6-4211-b4d7-6d4cac8b5f4d',
                    notForTagging: true,
                    label: 'Retirement',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bbcbf164-91ff-44e9-926b-0a48543ac548',
                    notForTagging: true,
                    label: 'Teens',
                    terms: [],
                },
            ],
        },
        {
            label: 'Generic Locations',
            type: 'http://data.disney.com/resources/GenericLocations',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                    notForTagging: true,
                    label: 'Architectural Elements',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/4b305931-1b11-d26d-d9dc-5bb518aff98a',
                            notForTagging: false,
                            label: 'chimney',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a64eac33-b64f-8552-9ed8-68cef8e6f39c',
                            notForTagging: false,
                            label: 'terrace',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/be416308-8cc1-ab58-09f4-c35b469ad7bb',
                            notForTagging: false,
                            label: 'dome',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1f4a1b1a-bc5b-6c31-b8da-9f9245f719a4',
                            notForTagging: true,
                            label: 'stone wall',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/64d05a7d-e7f9-40fb-a155-dc6f05c4e564',
                            notForTagging: false,
                            label: 'dance floor',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9d49ef45-4b55-598b-393c-e0559a8323ab',
                            notForTagging: false,
                            label: 'marquee',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d705d9f4-110b-495a-835f-042c61cef978',
                            notForTagging: true,
                            label: 'geodesic dome',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/584cebba-c22a-36fe-631b-54941a115dab',
                            notForTagging: true,
                            label: 'railing',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0930ebf2-a0f5-b5d9-9bdf-424af2e4a2a2',
                            notForTagging: false,
                            label: 'roof',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9845d164-bc9c-43e5-54bd-82e883a07085',
                            notForTagging: false,
                            label: 'set',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cb41f3f9-3b82-71f9-16eb-e1cb605d549e',
                            notForTagging: false,
                            label: 'storefront',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0045c558-4b33-dabf-484f-d3fccf26efaa',
                            notForTagging: false,
                            label: 'ledge',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a1495dde-6486-03d5-a08f-4b12cd7111bc',
                            notForTagging: false,
                            label: 'drive-thru window',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/084165f6-77be-a17b-2ae2-3b6a39c21bba',
                            notForTagging: true,
                            label: 'hatch',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7c4c3c40-30c9-4219-3507-d67a8285838b',
                            notForTagging: false,
                            label: 'confessional',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5dc73c38-6498-437a-8e56-f270a9a8a5c2',
                            notForTagging: false,
                            label: 'archway',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5d47deaa-e7f6-5935-d6b8-02bd8ac9a1a3',
                            notForTagging: false,
                            label: 'gate',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/564f4e1f-45a5-19a5-88a3-d94c34d21b91',
                            notForTagging: false,
                            label: 'fireplace',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1933777c-2b04-8640-d69c-ea4213e49e97',
                            notForTagging: false,
                            label: 'window',
                            broaderId:
                                'http://data.disney.com/resources/1438db8e-eca3-6326-fef3-63f8d8da328c',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                    notForTagging: true,
                    label: 'Buildings and Structures',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/ac3b2216-5cd8-1b96-2034-e21bba827c83',
                            notForTagging: false,
                            label: 'convenience store',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/97430d78-0897-4a66-b3df-614f30eecd94',
                            notForTagging: false,
                            label: 'cold storage room',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/31422aa1-b7c4-7d6f-98e4-18efed7c39bf',
                            notForTagging: false,
                            label: 'consulate',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e8259851-4c9a-4e81-997b-3bab66bff497',
                            notForTagging: false,
                            label: 'planetarium',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/395a3112-e2e8-4d8d-b6cd-216f1ab41f0a',
                            notForTagging: false,
                            label: 'ruin',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d0340671-d21a-fde3-b77f-2372678ec8af',
                            notForTagging: true,
                            label: 'Industrial Facilities',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/28456a82-b697-0538-ccff-969d42a71fad',
                                    notForTagging: false,
                                    label: 'windmill',
                                    broaderId:
                                        'http://data.disney.com/resources/d0340671-d21a-fde3-b77f-2372678ec8af',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3342b433-709c-381b-6905-b7ed96e494ba',
                                    notForTagging: false,
                                    label: 'industrial building',
                                    broaderId:
                                        'http://data.disney.com/resources/d0340671-d21a-fde3-b77f-2372678ec8af',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/8ff222ba-0638-48f6-ad1a-097a3724ff2f',
                                    notForTagging: true,
                                    label: 'forge',
                                    broaderId:
                                        'http://data.disney.com/resources/d0340671-d21a-fde3-b77f-2372678ec8af',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/84e82398-b812-4db0-91fe-b442198c8596',
                                    notForTagging: false,
                                    label: 'offshore drilling platform',
                                    broaderId:
                                        'http://data.disney.com/resources/d0340671-d21a-fde3-b77f-2372678ec8af',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/363aa356-f26a-eeb7-dc5a-5d65be11a28c',
                                    notForTagging: false,
                                    label: 'factory',
                                    broaderId:
                                        'http://data.disney.com/resources/d0340671-d21a-fde3-b77f-2372678ec8af',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b24f8396-081f-6b42-d824-6b5042c859b6',
                                    notForTagging: false,
                                    label: 'mine shaft',
                                    broaderId:
                                        'http://data.disney.com/resources/d0340671-d21a-fde3-b77f-2372678ec8af',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/f09f3df5-0e2b-4e15-b630-fbb882739fe0',
                                    notForTagging: false,
                                    label: 'refinery',
                                    broaderId:
                                        'http://data.disney.com/resources/d0340671-d21a-fde3-b77f-2372678ec8af',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/904a73b1-b1c8-e313-dcf0-1482d920a994',
                                    notForTagging: false,
                                    label: 'power plant',
                                    broaderId:
                                        'http://data.disney.com/resources/d0340671-d21a-fde3-b77f-2372678ec8af',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/85431f23-415f-0e37-2c41-65680c9027ae',
                            notForTagging: true,
                            label: 'space simulator',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2e42a759-404b-e533-5717-d8d6f8c55f9d',
                            notForTagging: true,
                            label: 'crematorium',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3b43d912-467e-5dcb-cd34-7eaf6ed66580',
                            notForTagging: false,
                            label: 'bridal shop',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/454a5e38-235a-383a-04ca-58fb1fbe6e9c',
                            notForTagging: true,
                            label: 'ice hockey rink',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b473e2cd-9cc5-408d-b189-956f5cee9aec',
                            notForTagging: false,
                            label: 'community center',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ed30d4d1-a784-fcb1-bb04-a3edc91b1996',
                            notForTagging: false,
                            label: 'warehouse',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7cb34123-dff4-4494-bb6b-f60f8ca6b215',
                            notForTagging: false,
                            label: 'armory',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9f4ae72b-e135-6645-480b-4795a3ddcc8c',
                            notForTagging: true,
                            label: 'boutique',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d4a9efdb-a7ed-43d6-ad74-385591d1067e',
                            notForTagging: true,
                            label: 'building',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e835cb9d-4fb3-9741-12e4-ae09711681a4',
                            notForTagging: false,
                            label: 'grocery store',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/673ea14e-9c88-f7e2-e5b9-d8173cb06d83',
                            notForTagging: true,
                            label: 'diner',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a3f75caf-ae2a-4757-9239-c81ed8d06128',
                            notForTagging: false,
                            label: 'observatory',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fd36554d-a0d1-452c-28e5-63021cf500a4',
                            notForTagging: false,
                            label: 'shed',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bd3bc64a-f8be-1655-c893-e2a4bab85d8e',
                            notForTagging: false,
                            label: 'convention center',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c64f7626-806b-1ba4-302b-cd6f78f6109e',
                            notForTagging: false,
                            label: 'office building',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c349d795-9f73-4da3-8965-e303f40f2d28',
                            notForTagging: false,
                            label: 'space station',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7df774c1-7e20-4bc3-8ed6-5c1deff42b25',
                            notForTagging: false,
                            label: 'scaffolding',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6140627d-c4cc-fedd-01a4-cdcec6c938a6',
                            notForTagging: true,
                            label: 'supermarket',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9233a960-8e9c-19eb-ff92-1c324ffc2bba',
                            notForTagging: false,
                            label: 'museum',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/324f6fef-4076-720b-2399-379b6b576fb5',
                            notForTagging: false,
                            label: 'tombstone',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e0473469-7ee0-ecca-ce93-f39a30beef86',
                            notForTagging: false,
                            label: 'gazebo',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/947cf052-cc10-49af-a361-f8641b0a6bfb',
                            notForTagging: false,
                            label: 'circus tent',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4b4d495a-64aa-90ee-1399-872457ec90bc',
                            notForTagging: false,
                            label: 'clock tower',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7c4bd9a8-1465-5957-7270-ba6711012bae',
                            notForTagging: false,
                            label: 'ranger station',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/194aabfb-8cea-04d4-9dfb-78b64936848e',
                            notForTagging: false,
                            label: 'drugstore',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1d31b531-db8e-f599-609f-0d593521e6a3',
                            notForTagging: false,
                            label: 'department store',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b6447646-03b0-f914-cc69-4b2063b4a19c',
                            notForTagging: false,
                            label: 'food stand',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                            notForTagging: true,
                            label: 'Commercial Buildings',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/d143d6c8-0528-d78d-60d1-6c0b60208bb3',
                                    notForTagging: false,
                                    label: 'massage parlor',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5c45ca89-993b-57ea-92ac-080cb519a680',
                                    notForTagging: false,
                                    label: 'automobile repair shop',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/31462b8f-132b-184e-e406-2045b75c2880',
                                    notForTagging: false,
                                    label: 'car wash',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5b34e43b-d17c-5e95-6fdd-ed1714d7d2b3',
                                    notForTagging: true,
                                    label: 'shop',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b810b2c1-165f-41d8-a3db-91415e7e48bd',
                                    notForTagging: false,
                                    label: 'brothel',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/e84963b3-27eb-c9f8-11d2-a444e39c769d',
                                    notForTagging: false,
                                    label: 'store',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c431c76b-03fa-c850-b92b-d7fad6fc468e',
                                    notForTagging: false,
                                    label: 'bank',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/07354bcb-c613-4350-92aa-7b3c0599a068',
                                    notForTagging: false,
                                    label: 'tattoo parlor',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0e4fc4ce-c683-aaf3-3efd-9e50b95c58a5',
                                    notForTagging: false,
                                    label: 'automobile dealership',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6b4a741c-40b0-ed9f-9855-ada6fdd8fc88',
                                    notForTagging: false,
                                    label: 'auction house',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/8f3db187-9a15-41eb-8376-0d6533b03389',
                                    notForTagging: false,
                                    label: 'beauty shop',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/d94111e5-d6fd-bdd6-b48d-f6b313f2329f',
                                    notForTagging: false,
                                    label: 'barbershop',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1a4645e9-d4f1-058b-2c48-848deaac4d9b',
                                    notForTagging: false,
                                    label: 'travel agency',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2242f501-8de7-7f1c-cb26-a70df25b4d96',
                                    notForTagging: false,
                                    label: 'cleaners',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/734285be-2352-48ec-db13-c6e3495b10b8',
                                    notForTagging: false,
                                    label: 'art gallery',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/e6be2328-f9c4-4a71-af0e-3cc610b1cc7f',
                                    notForTagging: false,
                                    label: 'cybercafe',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1df436e3-04b3-48b4-8ea8-187d9f6e8d7a',
                                    notForTagging: false,
                                    label: 'bookstore',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/bb4da503-7b7a-0ccd-bad3-42808040dbb2',
                                    notForTagging: false,
                                    label: 'bakery',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/424037e1-f0a9-0d56-f0c8-8f4c5fd36ebe',
                                    notForTagging: false,
                                    label: 'funeral home',
                                    broaderId:
                                        'http://data.disney.com/resources/f0363934-453f-a8f6-be18-5e0488520893',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/93433f9d-e17b-ccb7-7bfb-f4c49b73898d',
                            notForTagging: false,
                            label: 'capitol',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/49eea3df-171c-4ee9-9a5c-105816cd3eef',
                            notForTagging: false,
                            label: 'pyramid',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/47305859-18ae-4898-1a68-6980559cf58c',
                            notForTagging: true,
                            label: 'Storage Facilities',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6d60ffca-ba19-48c9-bce1-7333d65c5ee4',
                            notForTagging: true,
                            label: 'black site',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/617f3504-9048-490a-8695-c30991d0e224',
                            notForTagging: false,
                            label: 'embassy',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7436bdc3-910e-61cd-2ded-cb139fb1de97',
                            notForTagging: true,
                            label: 'Professional Buildings',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/ab32cfea-e45c-4042-bb57-494a36230548',
                                    notForTagging: false,
                                    label: 'campaign headquarters',
                                    broaderId:
                                        'http://data.disney.com/resources/7436bdc3-910e-61cd-2ded-cb139fb1de97',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a24bf863-e27a-24e8-9c51-d81b27970a9d',
                                    notForTagging: false,
                                    label: 'dental office',
                                    broaderId:
                                        'http://data.disney.com/resources/7436bdc3-910e-61cd-2ded-cb139fb1de97',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1dc897dd-c0d2-4a3e-a5c4-513bd0bec9bc',
                                    notForTagging: false,
                                    label: 'law office',
                                    broaderId:
                                        'http://data.disney.com/resources/7436bdc3-910e-61cd-2ded-cb139fb1de97',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/52454cba-c689-193e-c3b8-f457fcbf5fae',
                                    notForTagging: false,
                                    label: "therapist's office",
                                    broaderId:
                                        'http://data.disney.com/resources/7436bdc3-910e-61cd-2ded-cb139fb1de97',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a24cd012-63dc-7ee6-bd4c-a3dbd03bf885',
                                    notForTagging: false,
                                    label: 'medical office',
                                    broaderId:
                                        'http://data.disney.com/resources/7436bdc3-910e-61cd-2ded-cb139fb1de97',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2845462f-3360-4410-c3ca-84ce2fcd8189',
                                    notForTagging: false,
                                    label: 'photography studio',
                                    broaderId:
                                        'http://data.disney.com/resources/7436bdc3-910e-61cd-2ded-cb139fb1de97',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/c432e670-234e-215e-8255-bd1d4fb260ba',
                            notForTagging: false,
                            label: 'tent',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0d4b55da-abd7-9ca1-b475-30da99bddaab',
                            notForTagging: false,
                            label: 'municipal building',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/724e3e38-50dc-65a7-e3ec-476642060ead',
                            notForTagging: false,
                            label: 'courthouse',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0a31606c-8200-f734-7fd2-933980545aa8',
                            notForTagging: true,
                            label: 'Religious Buildings',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/0a3eff6e-2b8d-94c2-c4a4-beb09c85a189',
                                    notForTagging: false,
                                    label: 'cathedral',
                                    broaderId:
                                        'http://data.disney.com/resources/0a31606c-8200-f734-7fd2-933980545aa8',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/e93c2999-2ba6-5626-ec6c-0be3ef2314b4',
                                    notForTagging: false,
                                    label: 'church',
                                    broaderId:
                                        'http://data.disney.com/resources/0a31606c-8200-f734-7fd2-933980545aa8',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/844987f4-d373-cd26-0e92-282ff75bc69f',
                                    notForTagging: false,
                                    label: 'monastery',
                                    broaderId:
                                        'http://data.disney.com/resources/0a31606c-8200-f734-7fd2-933980545aa8',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/133a97d3-6ac8-89ff-fbf5-9ee74ccf958b',
                                    notForTagging: false,
                                    label: 'temple',
                                    broaderId:
                                        'http://data.disney.com/resources/0a31606c-8200-f734-7fd2-933980545aa8',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/eae7d1a1-d0f6-4d37-ac4b-c98492150db4',
                                    notForTagging: false,
                                    label: 'convent',
                                    broaderId:
                                        'http://data.disney.com/resources/0a31606c-8200-f734-7fd2-933980545aa8',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c64b6b96-5365-7636-6e61-fbfe3df5d4aa',
                                    notForTagging: false,
                                    label: 'shrine',
                                    broaderId:
                                        'http://data.disney.com/resources/0a31606c-8200-f734-7fd2-933980545aa8',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b6f2229e-3fd6-4384-8023-10f8ecb54136',
                                    notForTagging: false,
                                    label: 'mosque',
                                    broaderId:
                                        'http://data.disney.com/resources/0a31606c-8200-f734-7fd2-933980545aa8',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6d4525f4-9298-feee-8dea-ae20688e5ea7',
                                    notForTagging: false,
                                    label: 'pagoda',
                                    broaderId:
                                        'http://data.disney.com/resources/0a31606c-8200-f734-7fd2-933980545aa8',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/24459599-7950-aca2-6f6a-fc7f1df7998d',
                            notForTagging: true,
                            label: "children's clothing store",
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2f32b3ea-8423-4cca-c069-34c074373a99',
                            notForTagging: true,
                            label: 'Public Accommodations',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/071a3b16-9fba-4efc-9eb5-6c29728b39d3',
                                    notForTagging: false,
                                    label: 'bed-and-breakfast',
                                    broaderId:
                                        'http://data.disney.com/resources/2f32b3ea-8423-4cca-c069-34c074373a99',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/7548ab39-6563-54cf-6145-400b600a28a0',
                                    notForTagging: true,
                                    label: 'inn',
                                    broaderId:
                                        'http://data.disney.com/resources/2f32b3ea-8423-4cca-c069-34c074373a99',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/533c3f1c-840d-524a-ed14-2a7bd3c08ebd',
                                    notForTagging: true,
                                    label: 'resort',
                                    broaderId:
                                        'http://data.disney.com/resources/2f32b3ea-8423-4cca-c069-34c074373a99',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/614f27d9-0f72-f992-0dce-39b954e880a1',
                                            notForTagging: false,
                                            label: 'ski resort',
                                            broaderId:
                                                'http://data.disney.com/resources/533c3f1c-840d-524a-ed14-2a7bd3c08ebd',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/ab4cd491-36a8-3078-6a05-5e6d6c63e09c',
                                            notForTagging: false,
                                            label: 'spa',
                                            broaderId:
                                                'http://data.disney.com/resources/533c3f1c-840d-524a-ed14-2a7bd3c08ebd',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b8478f23-16db-39fe-4d9c-08cee78b7c84',
                                    notForTagging: false,
                                    label: 'wedding chapel',
                                    broaderId:
                                        'http://data.disney.com/resources/2f32b3ea-8423-4cca-c069-34c074373a99',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ee40e87a-2ced-996b-2f07-cc09ce67749e',
                                    notForTagging: true,
                                    label: 'reception hall',
                                    broaderId:
                                        'http://data.disney.com/resources/2f32b3ea-8423-4cca-c069-34c074373a99',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/f43d07b5-04f2-2e01-a913-22fc8b660290',
                                    notForTagging: true,
                                    label: 'motel',
                                    broaderId:
                                        'http://data.disney.com/resources/2f32b3ea-8423-4cca-c069-34c074373a99',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2b307121-ade7-421e-8053-d5b5891fd8ac',
                                    notForTagging: false,
                                    label: 'hotel',
                                    broaderId:
                                        'http://data.disney.com/resources/2f32b3ea-8423-4cca-c069-34c074373a99',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/6931db59-0abb-46e3-7f03-9d1aee22b9bb',
                            notForTagging: true,
                            label: 'Eating and Drinking Buildings',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/113075f7-7e6f-190a-4734-a24ae42967a9',
                                    notForTagging: false,
                                    label: 'bar',
                                    broaderId:
                                        'http://data.disney.com/resources/6931db59-0abb-46e3-7f03-9d1aee22b9bb',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1846481a-e5f0-4daa-80f6-e75ee9fa6699',
                                    notForTagging: false,
                                    label: 'cafeteria',
                                    broaderId:
                                        'http://data.disney.com/resources/6931db59-0abb-46e3-7f03-9d1aee22b9bb',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c84e5512-f8a6-e693-8459-aaff051f9893',
                                    notForTagging: false,
                                    label: 'café',
                                    broaderId:
                                        'http://data.disney.com/resources/6931db59-0abb-46e3-7f03-9d1aee22b9bb',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c54f53c5-52b6-783b-fa3e-97f9ede112a0',
                                    notForTagging: false,
                                    label: 'banquet hall',
                                    broaderId:
                                        'http://data.disney.com/resources/6931db59-0abb-46e3-7f03-9d1aee22b9bb',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3c3562a3-c533-3a02-967d-2f46d7bc868e',
                                    notForTagging: false,
                                    label: 'nightclub',
                                    broaderId:
                                        'http://data.disney.com/resources/6931db59-0abb-46e3-7f03-9d1aee22b9bb',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0732a690-7737-a68e-c708-7331c0fc2aa3',
                                    notForTagging: false,
                                    label: 'restaurant',
                                    broaderId:
                                        'http://data.disney.com/resources/6931db59-0abb-46e3-7f03-9d1aee22b9bb',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/e94440f9-4186-7fcb-411a-33c93fada198',
                                    notForTagging: false,
                                    label: 'coffee house',
                                    broaderId:
                                        'http://data.disney.com/resources/6931db59-0abb-46e3-7f03-9d1aee22b9bb',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/04107991-7020-b69f-9c72-f07774cb5689',
                            notForTagging: false,
                            label: 'high school',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                            notForTagging: true,
                            label: 'Recreation Structures',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/da4455bd-ffc1-11ee-05ac-8ad106e376a3',
                                    notForTagging: false,
                                    label: 'clubhouse',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/e93a574e-ec49-a0a0-d570-965410b2b7a2',
                                    notForTagging: false,
                                    label: 'stadium',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/53464b17-1622-188b-b511-55081bc61592',
                                    notForTagging: false,
                                    label: 'grandstand',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/58414710-2d45-7545-6a98-7a6923e66188',
                                    notForTagging: false,
                                    label: 'roller coaster',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0999efe2-c138-4e34-b774-3a64c87a7703',
                                    notForTagging: false,
                                    label: 'pool hall',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/da45d519-6414-d8e0-b588-89e177032a8e',
                                    notForTagging: false,
                                    label: 'penny arcade',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/964c815a-436f-f484-4124-d832e31bfdbe',
                                    notForTagging: false,
                                    label: 'driving range',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0f46c05d-6409-eb0f-a5f1-27453e5c66b9',
                                    notForTagging: false,
                                    label: 'recreation center',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/9741f22b-ca06-1fe8-f691-67d567c81fa3',
                                    notForTagging: false,
                                    label: 'ski lift',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/9f47ba1b-7131-4a7e-f963-917631198aa9',
                                    notForTagging: false,
                                    label: 'strip club',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/8a40077a-57be-ab69-9b6c-758ae76837aa',
                                    notForTagging: true,
                                    label: 'athletic club',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5a326922-5531-5e2e-19df-7a5313fcc2b3',
                                    notForTagging: false,
                                    label: 'Ferris wheel',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ff4ba2b4-e918-8368-796e-d788f274afa0',
                                    notForTagging: false,
                                    label: 'ice skating rink',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0e4df34a-de15-dc6a-238c-9e5e13ad0da1',
                                    notForTagging: false,
                                    label: 'bowling alley',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/69381918-beaa-4531-b44b-bc45141fd89e',
                                    notForTagging: false,
                                    label: 'shooting gallery',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/7746d498-9f56-e9a0-f7b6-4a2b6ccb9f81',
                                    notForTagging: false,
                                    label: 'basketball court',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5f40119e-0ac7-d47d-7dd1-329df0894595',
                                    notForTagging: false,
                                    label: 'cabaña',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ea42e70d-3c63-1bc9-1653-a79f26a9e797',
                                    notForTagging: false,
                                    label: 'discotheque',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1f3ecc4c-6cdc-4ea5-8c71-a04b94a5c9bd',
                                    notForTagging: false,
                                    label: 'roller-skating rink',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/db4e2c41-de0c-9569-6841-3fbeee293dbb',
                                    notForTagging: false,
                                    label: 'bleachers',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6e4b0ecc-c12a-edd4-ff66-4d941525f587',
                                    notForTagging: false,
                                    label: 'casino',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5c37ad3e-6d61-5581-dc9c-1f0ebd357eb9',
                                    notForTagging: false,
                                    label: 'swimming pool',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/874f2bb2-763c-d8db-09b9-eeef6ea9acbb',
                                    notForTagging: false,
                                    label: 'amusement ride',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6f4d988c-b6df-564a-17b3-f1e837fd2497',
                                    notForTagging: false,
                                    label: 'tree house',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0410298e-8088-aa54-9c75-0768b3f87288',
                                    notForTagging: false,
                                    label: 'gymnasium',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/bf32f811-a48f-715d-cdb1-3bba46c522aa',
                                    notForTagging: true,
                                    label: "child's playhouse",
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2e46bf74-1c00-19ac-83ae-802f7de056ba',
                                    notForTagging: false,
                                    label: 'carousel',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b144f46d-800e-3a43-8ed0-45bb038e61bc',
                                    notForTagging: false,
                                    label: 'boxing ring',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/bb89bb21-21f0-4410-8f4a-022f50bd044f',
                                    notForTagging: true,
                                    label: 'escape room',
                                    broaderId:
                                        'http://data.disney.com/resources/5f399722-1f0d-4222-69e6-c61b8b0ac389',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/3a4fb3a3-b741-ab8d-fe26-49ba2c5799ae',
                            notForTagging: false,
                            label: 'toy store',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                            notForTagging: true,
                            label: 'Transportation Structures',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/5b47ca13-a7b6-de06-960c-4d95fa2efcb1',
                                    notForTagging: false,
                                    label: 'hangar',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0cd18370-53b1-48d7-8dc9-c524a2d4661a',
                                    notForTagging: false,
                                    label: 'launch pad',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a730d7fa-ffb6-0572-7651-454852c023aa',
                                    notForTagging: false,
                                    label: 'tunnel',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ed46b371-c18f-b7c7-a122-20b186928e9c',
                                    notForTagging: false,
                                    label: 'helipad',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3472eeec-3700-4e1e-ac65-358c65a5d70c',
                                    notForTagging: false,
                                    label: 'underpass',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/91491906-b43c-9a6c-c92e-1b89ce031e87',
                                    notForTagging: false,
                                    label: 'subway',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/163262fe-6667-0ed0-7b3c-3bd3d1d68a96',
                                    notForTagging: false,
                                    label: 'railroad station',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/4548ebeb-fbeb-f572-70a7-db87a8ed13a3',
                                    notForTagging: false,
                                    label: 'boathouse',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/68ece8ed-0123-450c-b38e-e6df451f5411',
                                    notForTagging: false,
                                    label: 'tollbooth',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3f31a636-a255-c2c4-90de-2b6ef445d2ab',
                                    notForTagging: false,
                                    label: 'lighthouse',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c7354b78-230e-b6c4-6985-6b9eaf22c5ac',
                                    notForTagging: false,
                                    label: 'pier',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c73582af-5575-9296-5db1-3bd04a1cd1ab',
                                    notForTagging: false,
                                    label: 'dock',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c03a683a-0294-8de7-0e91-15b74c1b5ba6',
                                    notForTagging: false,
                                    label: 'bridge',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0b4121eb-3c6f-4aa7-3d6a-11438212fc99',
                                    notForTagging: false,
                                    label: 'truck stop',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a946ad68-39b5-314e-614c-81139d4b3eac',
                                    notForTagging: false,
                                    label: 'valet parking stand',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/7b384145-8fb7-67de-5e18-14c9d9fed997',
                                    notForTagging: false,
                                    label: 'service station',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5041a34d-d0ba-bb94-083e-1f0f5fd1d286',
                                    notForTagging: false,
                                    label: 'ambulance bay',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/9c48af9a-9ccd-6bf2-0a79-254e807a6ab8',
                                    notForTagging: false,
                                    label: 'marina',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a32eefa2-57a2-4a9f-8603-b758d129e9c2',
                                    notForTagging: false,
                                    label: 'railroad track',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/72457963-e0fe-9403-56c8-6fa71e08e4be',
                                    notForTagging: false,
                                    label: 'bus stop',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/613bc799-25b3-2899-f1d1-23f83c6ef789',
                                            notForTagging: true,
                                            label: 'bus shelter',
                                            broaderId:
                                                'http://data.disney.com/resources/72457963-e0fe-9403-56c8-6fa71e08e4be',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c03a4b84-0ad4-f4f3-87b1-ea0133f8aab6',
                                    notForTagging: false,
                                    label: 'bus station',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/55a4794e-000c-442c-a3ad-30c9451f070f',
                                    notForTagging: false,
                                    label: 'passenger boarding bridge',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5538d479-4c0c-8d2c-06fc-f3f0bf226cb6',
                                    notForTagging: false,
                                    label: 'airport',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/59339667-55a3-f7f0-cedf-5f88b159569c',
                                    notForTagging: true,
                                    label: 'subway station',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/f74df31b-fcf6-4f67-225d-7693d28246ad',
                                    notForTagging: true,
                                    label: 'drawbridge',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6672c121-ab69-412b-ad5b-be616e6e3ba1',
                                    notForTagging: false,
                                    label: 'overpass',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3847bce5-b2d8-4d3d-e307-44bcc88db690',
                                    notForTagging: false,
                                    label: 'loading zone',
                                    broaderId:
                                        'http://data.disney.com/resources/5c3c20b2-6a8e-80e6-25d5-c3cf653027a6',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/4c439159-1d40-0ddc-8b2f-28ef9ad458b5',
                            notForTagging: false,
                            label: 'middle school',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e06d4ce8-5dcb-4ae4-8d87-a2e0d18e6fa6',
                            notForTagging: false,
                            label: 'bunker',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/913b30e4-88e3-ba4e-c958-cf4e0aaabbbf',
                            notForTagging: false,
                            label: 'garage',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/21478e26-5461-1bc9-8a92-c4d3def3ecb2',
                            notForTagging: true,
                            label: 'safe house',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cf46828b-b696-2ca1-01f2-fe867537ddbd',
                            notForTagging: false,
                            label: 'clothing store',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a4f20392-1ac8-4abc-a944-2a0143a45067',
                            notForTagging: false,
                            label: 'elementary school',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/734e86a2-546b-b374-7dd1-3492d64a19bb',
                            notForTagging: true,
                            label: 'paddock',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0a401326-0e39-0dd2-1271-3eaa115f86be',
                            notForTagging: false,
                            label: 'florist shop',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                            notForTagging: true,
                            label: 'Residential Structures',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/76326ff0-89ef-4ba5-13a1-9579e651bfb2',
                                    notForTagging: false,
                                    label: 'kennel',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/833f2481-03e7-9e40-2a92-c55d7769d0b6',
                                    notForTagging: false,
                                    label: 'apartment building',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/613994a1-f057-44fc-8f5c-3f2843576883',
                                    notForTagging: false,
                                    label: 'chalet',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/bd263ede-622d-4e9e-8a2e-773f1ac2e38a',
                                    notForTagging: false,
                                    label: 'retirement home',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c44a6c97-0283-76d4-b584-9558929037b5',
                                    notForTagging: false,
                                    label: 'dormitory',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ac486c54-04db-a784-78e8-02547d41e98d',
                                    notForTagging: true,
                                    label: 'condominium',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/de38b617-a2bb-04e4-e188-06cffd359fa9',
                                    notForTagging: false,
                                    label: 'castle',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1248c258-196b-e69b-5f62-3866559f71ba',
                                    notForTagging: true,
                                    label: 'town house',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a33a574e-195e-1cc5-5cf0-41e1f0f5c882',
                                    notForTagging: false,
                                    label: 'mansion',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ebd96fa3-e582-4f97-82e4-d8dcbc953949',
                                    notForTagging: false,
                                    label: 'cabin (house)',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2715e790-83cb-418d-8756-5b0d160e30bc',
                                    notForTagging: true,
                                    label: 'guesthouse',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/683289f2-dbe3-bbec-fb02-b22a7dadc785',
                                    notForTagging: false,
                                    label: 'mobile home',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/324dcb59-6d48-73a8-f4dc-c7252768c390',
                                    notForTagging: true,
                                    label: 'brownstone',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6b48f984-1055-2ede-2d15-b129e90bf5b7',
                                    notForTagging: false,
                                    label: 'apartment',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3a4b9b06-a662-300c-4b62-b3a612a1da9e',
                                    notForTagging: false,
                                    label: 'cottage',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/384f60ff-5030-9680-e253-321f8bf64f95',
                                    notForTagging: false,
                                    label: 'tipi',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/843f4abc-d9fa-a0f4-7ab2-7d35a2b9cba0',
                                    notForTagging: false,
                                    label: 'house',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2e3f0a0c-4e65-5609-b6b9-08221c1dfaab',
                                    notForTagging: false,
                                    label: 'palace',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0e3f6b31-8954-80af-31ab-f49ba64696b5',
                                    notForTagging: false,
                                    label: 'igloo',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/96d76bc8-ede0-4e2f-821e-c2e38cee5171',
                                    notForTagging: true,
                                    label: "servants' quarters",
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ac4c522c-7a80-bcd6-2ba7-f10ffe1d428b',
                                    notForTagging: true,
                                    label: 'SRO',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/4534741d-b7b4-b2e7-a610-78c5f4760e84',
                                    notForTagging: false,
                                    label: 'hut',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b443a7e9-46e6-1613-cf54-28d6afcc3a96',
                                    notForTagging: true,
                                    label: 'ranch house',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/4f3706b0-3fb5-8dc9-dcb2-bf768dfca687',
                                    notForTagging: false,
                                    label: 'doghouse',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1a476dc3-996e-8ba1-2bf9-557ad679b5bb',
                                    notForTagging: true,
                                    label: 'log cabin',
                                    broaderId:
                                        'http://data.disney.com/resources/e436cb89-c739-31da-8d7a-b4403f3b6fa5',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/a5ef6571-1bca-4567-ad70-a64e9dc018e5',
                            notForTagging: false,
                            label: 'liquor store',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/20433294-b582-5729-c15c-6b4f93d8a3bc',
                            notForTagging: false,
                            label: 'orphanage',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e586e107-e6e6-45a0-b104-6e8016a618d4',
                            notForTagging: false,
                            label: 'office supply store',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2081da16-aa95-4625-807a-4295f2315faf',
                            notForTagging: false,
                            label: 'aquarium',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ec3db815-63ca-f74b-36ea-da8feabc1e95',
                            notForTagging: false,
                            label: 'theater (building)',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/44374246-059b-94c7-2348-a3815b3898a7',
                                    notForTagging: false,
                                    label: 'drive-in theater',
                                    broaderId:
                                        'http://data.disney.com/resources/ec3db815-63ca-f74b-36ea-da8feabc1e95',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/85323ef7-756c-1759-6025-bc36a5f60e82',
                                    notForTagging: false,
                                    label: 'amphitheater',
                                    broaderId:
                                        'http://data.disney.com/resources/ec3db815-63ca-f74b-36ea-da8feabc1e95',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/eb4e056a-8ffa-56bc-5222-a493dfbb3797',
                            notForTagging: true,
                            label: 'tower',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2f46b80f-70f2-9640-7b81-ee65c7d5d8b5',
                            notForTagging: false,
                            label: 'lifeguard tower',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6bb56b9b-536f-4580-a662-754bfe1fb771',
                            notForTagging: true,
                            label: 'automat',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/314aa820-9301-8789-0cde-b999781c3381',
                            notForTagging: false,
                            label: 'archive',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                            notForTagging: true,
                            label: 'Institutional Buildings',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/234bb2e5-9969-3a58-3375-9ab5d1556c9b',
                                    notForTagging: false,
                                    label: 'command post',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ee438d59-7a26-8f21-a1a1-73540ef6ec9d',
                                    notForTagging: false,
                                    label: "women's shelter",
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/8a4f759d-35be-d6dc-a18a-27ab9e8f7c91',
                                    notForTagging: true,
                                    label: 'detention center',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/f43f4207-0b11-e91c-6ccd-694ef23e6d9c',
                                    notForTagging: false,
                                    label: 'jail',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a04d35c9-16ac-847c-24d2-ef97d3d7c1a0',
                                    notForTagging: false,
                                    label: 'security checkpoint',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c6379def-0692-83ea-2a57-4fd95b18afac',
                                    notForTagging: false,
                                    label: 'prison',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2530ef44-6ed1-6ba2-d6a8-b74bc3149788',
                                    notForTagging: false,
                                    label: 'laboratory',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/556bcf96-8d26-4d0a-8e8e-535eebfb660d',
                                    notForTagging: true,
                                    label: 'infirmary',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/7be0043e-4fbd-4247-8a34-2036bc13f527',
                                    notForTagging: true,
                                    label: 'halfway house',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/494e6e63-45ea-5edf-87ae-7be31b079d9e',
                                    notForTagging: false,
                                    label: 'nursing home',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a2fff16f-5f5e-4fa7-856f-700785a94b95',
                                    notForTagging: true,
                                    label: 'military prison',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ab42bae4-d5bf-98a0-8c28-4b7ea716c189',
                                    notForTagging: false,
                                    label: 'psychiatric hospital',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3e43cd6c-1bbb-af42-ba45-655a11b238af',
                                    notForTagging: false,
                                    label: 'college or university ',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5d45f173-4ef2-f545-68b3-4b874962d798',
                                    notForTagging: false,
                                    label: 'school',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/474d3ced-0d17-3f4f-c60d-e73d79efb48d',
                                    notForTagging: true,
                                    label: 'rehabilitation center',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/d93d1b84-9bda-0f9f-2459-99a4861205bf',
                                    notForTagging: false,
                                    label: 'animal shelter',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c148be27-5d1f-2004-621d-882a6fa4d180',
                                    notForTagging: false,
                                    label: 'library',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/604abed9-f2a3-c4a0-335e-75d8d26ae1bc',
                                    notForTagging: false,
                                    label: 'clinic',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/eb407307-0b76-6dad-00f0-cf9c586eeb90',
                                    notForTagging: false,
                                    label: 'morgue',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1c8c77c9-98ae-4761-9aa0-00334fd08e77',
                                    notForTagging: false,
                                    label: 'legal aid clinic',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ee210d10-5e33-4dc5-b4a8-b008ca8e3580',
                                    notForTagging: true,
                                    label: 'group home',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/8f47b130-bee8-411d-4b01-161e58a5c780',
                                    notForTagging: false,
                                    label: 'day care center',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6f4dfb44-1bb6-09e4-b167-03acf10410b6',
                                    notForTagging: false,
                                    label: 'veterinary hospital',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/744652c0-2ade-7176-a69b-e85ffc24ea83',
                                    notForTagging: true,
                                    label: 'research institute',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/613b77b0-2be1-8d3c-fc81-b46f475156be',
                                    notForTagging: false,
                                    label: 'hospital',
                                    broaderId:
                                        'http://data.disney.com/resources/29331180-8e90-47f7-4b98-befffbdf5da6',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/c8ed6172-8511-4dcb-b79c-c9999d9605b0',
                            notForTagging: false,
                            label: 'hardware store',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410518e-e8ba-62a2-9c75-0768b3f8aa8b',
                            notForTagging: true,
                            label: 'Agricultural Structures',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/634841b6-a2e7-657a-afbe-65e6c1e06d87',
                                    notForTagging: false,
                                    label: 'greenhouse',
                                    broaderId:
                                        'http://data.disney.com/resources/0410518e-e8ba-62a2-9c75-0768b3f8aa8b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/4b4f4583-8399-ee8f-2207-ad4414c4389e',
                                    notForTagging: false,
                                    label: 'stable',
                                    broaderId:
                                        'http://data.disney.com/resources/0410518e-e8ba-62a2-9c75-0768b3f8aa8b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/97478e39-55d0-2687-e291-30daa750f0b0',
                                    notForTagging: false,
                                    label: 'apiary',
                                    broaderId:
                                        'http://data.disney.com/resources/0410518e-e8ba-62a2-9c75-0768b3f8aa8b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0410518e-90d9-d5a9-9c75-0768b3f8d48b',
                                    notForTagging: false,
                                    label: 'barn',
                                    broaderId:
                                        'http://data.disney.com/resources/0410518e-e8ba-62a2-9c75-0768b3f8aa8b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/4d41099e-d514-5bbb-c1cb-4ba8d7512cb0',
                                    notForTagging: false,
                                    label: 'beehive',
                                    broaderId:
                                        'http://data.disney.com/resources/0410518e-e8ba-62a2-9c75-0768b3f8aa8b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2a493765-ec8e-da80-9a8b-747eee3e5283',
                                    notForTagging: false,
                                    label: 'stockyard',
                                    broaderId:
                                        'http://data.disney.com/resources/0410518e-e8ba-62a2-9c75-0768b3f8aa8b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/564781be-d225-ef26-b755-4a6187c6829a',
                                    notForTagging: false,
                                    label: 'corral',
                                    broaderId:
                                        'http://data.disney.com/resources/0410518e-e8ba-62a2-9c75-0768b3f8aa8b',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/fb4b2ff6-d225-16d0-2bab-2b3ebdf3a696',
                            notForTagging: false,
                            label: 'monument',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/054571dd-a7d4-62da-936a-5598e05d4eb8',
                            notForTagging: false,
                            label: 'mausoleum',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7545d3ca-39cc-f344-464c-02b2690c6d8d',
                            notForTagging: false,
                            label: 'storage facility',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5e4a487e-490e-9973-aafd-7a29f4a71987',
                            notForTagging: false,
                            label: 'arena',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e806b628-108b-4461-9597-17512d602e3d',
                            notForTagging: false,
                            label: 'watchtower',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0a4a8179-8363-ca77-c477-ebcb0ff4b7a4',
                            notForTagging: false,
                            label: 'fortress',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ad5bd0a8-20e5-4e00-bf52-72e3bcc1c601',
                            notForTagging: false,
                            label: 'pet shop',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f735782a-c27e-dced-a0ee-e49b72846187',
                            notForTagging: true,
                            label: 'Hydraulic Structures',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/866da7f7-a7d5-49fa-8ab0-e0aef4c8a141',
                                    notForTagging: false,
                                    label: 'canal',
                                    broaderId:
                                        'http://data.disney.com/resources/f735782a-c27e-dced-a0ee-e49b72846187',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/764e612c-e5d4-b66c-0bc7-4339891023a5',
                                    notForTagging: false,
                                    label: 'water tower',
                                    broaderId:
                                        'http://data.disney.com/resources/f735782a-c27e-dced-a0ee-e49b72846187',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/f1377d03-945f-247d-3fc0-69fdcc81028c',
                                    notForTagging: false,
                                    label: 'fountain',
                                    broaderId:
                                        'http://data.disney.com/resources/f735782a-c27e-dced-a0ee-e49b72846187',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6447eee6-c5b9-2bd0-c20d-a8dbf2e964be',
                                    notForTagging: false,
                                    label: 'culvert',
                                    broaderId:
                                        'http://data.disney.com/resources/f735782a-c27e-dced-a0ee-e49b72846187',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ca4295bf-dc49-9b14-113e-1a04d8898184',
                                    notForTagging: false,
                                    label: 'dam',
                                    broaderId:
                                        'http://data.disney.com/resources/f735782a-c27e-dced-a0ee-e49b72846187',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/de637230-fdfe-47b2-aa8f-8f5214222550',
                                    notForTagging: false,
                                    label: 'well',
                                    broaderId:
                                        'http://data.disney.com/resources/f735782a-c27e-dced-a0ee-e49b72846187',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ad40f75c-b884-7fe3-d73b-7ce569c6fc8e',
                                    notForTagging: false,
                                    label: 'seawall',
                                    broaderId:
                                        'http://data.disney.com/resources/f735782a-c27e-dced-a0ee-e49b72846187',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/a3364654-a80e-0c40-a215-dc1ae7efa19e',
                            notForTagging: true,
                            label: 'fast food restaurant',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/444ba0ff-cfe7-0b33-70e4-3f20ff730191',
                            notForTagging: false,
                            label: 'bell tower',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3739dff8-b6bc-9800-46d7-65a56512c8ab',
                            notForTagging: true,
                            label: 'Public Buildings',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/38d9d17a-dd7e-4dca-9ec1-c2ba0c214277',
                            notForTagging: true,
                            label: 'cell block',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ed446374-7ca0-cc1d-e0f3-7fefcebcb1b6',
                            notForTagging: false,
                            label: 'police station',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/56482811-2f0b-7696-dcc6-65ab90270682',
                            notForTagging: false,
                            label: 'missile silo',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5bd27b27-be98-465f-aed2-666c55a52ba6',
                            notForTagging: false,
                            label: 'concert hall',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/284191aa-28f1-1e07-c4a8-4f360c0b6293',
                            notForTagging: true,
                            label: 'music hall',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ab490a7c-74a6-c3b0-33c2-f9fb15ae94a9',
                            notForTagging: false,
                            label: 'city hall',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e8379300-1ad9-9f58-728e-836038387e97',
                            notForTagging: false,
                            label: 'post office',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cf554edc-01ce-4f74-bbe3-d6b78a87f54d',
                            notForTagging: true,
                            label: 'fire temple',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/89330d35-b9f2-0a5c-2c9d-4fc13c40469a',
                            notForTagging: false,
                            label: 'fire station',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/283c5761-75d5-b84d-ab11-19e668706f88',
                            notForTagging: false,
                            label: 'skyscraper',
                            broaderId:
                                'http://data.disney.com/resources/de342551-c323-7926-cbb7-5054a803bb9f',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                    notForTagging: true,
                    label: 'Complexes',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/f443091c-bfbc-2d7c-49cd-f4cf1526b28b',
                            notForTagging: true,
                            label: 'compound',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/eb46a51e-75a6-b631-0776-3a34e86848b2',
                            notForTagging: false,
                            label: 'mine',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/18328026-d775-b1f3-0ae9-d9c4a422aaa4',
                            notForTagging: false,
                            label: 'shopping mall',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b945f249-7b26-9963-5b72-10d9a3ed1aa4',
                            notForTagging: false,
                            label: 'military base',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9879f3e0-3a60-462d-a302-0bcdf8826ea3',
                            notForTagging: false,
                            label: 'motion picture studio',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b8419168-3f97-71ef-6c54-06f6fb98ebad',
                            notForTagging: false,
                            label: 'running track',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3642fafb-15a8-8448-4d1e-925a2b2ebeaf',
                            notForTagging: false,
                            label: 'construction site',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5a0a4340-9ba1-4e07-a2dc-11df17fffe70',
                            notForTagging: false,
                            label: 'trailer park',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fb44cef5-92ca-45aa-9685-988545f1fd2b',
                            notForTagging: true,
                            label: 'bazaar',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e9735fd8-c2e5-4c4c-9145-8db72dde09ca',
                            notForTagging: false,
                            label: 'concentration camp',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/794b8709-5299-7832-5370-055cf3e9149f',
                            notForTagging: false,
                            label: 'horse racetrack',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d74c790b-7e37-a53c-7a07-e2085440eaa1',
                            notForTagging: false,
                            label: 'archery range',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/444ab1ce-0834-bf9a-abcb-cf33badc5686',
                            notForTagging: false,
                            label: 'radio station',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/094db626-1926-41b0-63f8-c29839ec30bc',
                            notForTagging: true,
                            label: 'suburban neighborhood',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/974f82de-dca8-e361-b30b-bde0b3459b88',
                            notForTagging: false,
                            label: 'rehearsal studio',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0c7892ae-aab5-4484-8198-e110f00dccc8',
                            notForTagging: false,
                            label: 'obstacle course',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/274aa35e-2e01-bcc1-1331-75964adca3b4',
                            notForTagging: false,
                            label: 'equestrian center',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9d450f87-920b-79a5-d11b-32eba128dfb8',
                            notForTagging: true,
                            label: 'barrio',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ca18914b-4b44-45c9-ac91-8a6c3c9070d4',
                            notForTagging: false,
                            label: "farmers' market",
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ff409491-1a38-1f08-03ab-3088d3219ca2',
                            notForTagging: false,
                            label: 'recording studio',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c64308b4-a47d-bea2-edb8-9d7d5a9d0b93',
                            notForTagging: false,
                            label: 'quarry',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/274ac501-aa5f-4298-0a0c-b83b38e9bc80',
                            notForTagging: true,
                            label: 'automobile racetrack',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/024b4548-8fd8-9c2a-de6a-b0932e3ee686',
                            notForTagging: false,
                            label: 'neighborhood',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/997adf14-1771-4955-ade5-c9b5e7fbfa6d',
                            notForTagging: false,
                            label: 'oil field',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5e3d94a3-5e49-ee74-8c76-e87c61ea5ba9',
                            notForTagging: true,
                            label: 'theme park',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/64496f85-ef23-3c84-5825-1c02254af582',
                            notForTagging: false,
                            label: 'cemetery',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5972180f-e04a-4d0c-b51f-22e6be47c930',
                            notForTagging: false,
                            label: 'marketplace',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5c379def-2b6a-bb7e-eca3-7255e06c73a0',
                            notForTagging: false,
                            label: 'television studio',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dc170cc5-2409-4447-be6a-52e43f7e18ea',
                            notForTagging: false,
                            label: 'battlefield',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ce3ac72a-1535-c1f8-ebc2-9082b38c0198',
                            notForTagging: false,
                            label: 'water park',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/034bace0-11c7-3da0-1ada-32cadfafb2a1',
                            notForTagging: false,
                            label: 'camp',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e330fa77-8619-ea53-d7d5-637395b733ac',
                            notForTagging: false,
                            label: 'zoo',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3f35d501-3780-7b3b-e1eb-b0b28588ecb4',
                            notForTagging: false,
                            label: 'amusement park',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f88f610a-358e-4570-90d7-ee0ef336a653',
                            notForTagging: true,
                            label: 'slum',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a6434cec-e43d-c5ad-545f-5a6cef7d32a2',
                            notForTagging: false,
                            label: 'food court',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3a4d863a-8be9-49c8-a095-7273d0f287ab',
                            notForTagging: true,
                            label: 'Agricultural Complexes',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/9047fbb5-c746-8b74-356c-c34cacd864b6',
                                    notForTagging: false,
                                    label: 'field',
                                    broaderId:
                                        'http://data.disney.com/resources/3a4d863a-8be9-49c8-a095-7273d0f287ab',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/313570cf-da25-e78f-e704-d5a8f62e4c9f',
                                    notForTagging: false,
                                    label: 'ranch',
                                    broaderId:
                                        'http://data.disney.com/resources/3a4d863a-8be9-49c8-a095-7273d0f287ab',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/924a3e0e-456b-f256-7e65-2cf870889da8',
                                    notForTagging: false,
                                    label: 'orchard',
                                    broaderId:
                                        'http://data.disney.com/resources/3a4d863a-8be9-49c8-a095-7273d0f287ab',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/8d36c77f-70fe-4864-9411-80738a91fdf1',
                                    notForTagging: false,
                                    label: 'vineyard',
                                    broaderId:
                                        'http://data.disney.com/resources/3a4d863a-8be9-49c8-a095-7273d0f287ab',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a746162e-e319-f370-4e94-27478a128aa6',
                                    notForTagging: false,
                                    label: 'farm',
                                    broaderId:
                                        'http://data.disney.com/resources/3a4d863a-8be9-49c8-a095-7273d0f287ab',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c344cc34-c312-650f-ac5e-b932fd5d6cb2',
                                    notForTagging: false,
                                    label: 'ant farms',
                                    broaderId:
                                        'http://data.disney.com/resources/3a4d863a-8be9-49c8-a095-7273d0f287ab',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/e2493a3c-e541-46f3-977d-d16159f8de0d',
                            notForTagging: false,
                            label: 'shipyard',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a0940bfb-7119-4f1d-8aae-8e25c62b575d',
                            notForTagging: false,
                            label: 'vehicle racetrack',
                            broaderId:
                                'http://data.disney.com/resources/673fff02-47be-e6f3-be7d-c82da0684f97',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                    notForTagging: true,
                    label: 'Natural Landscapes',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/07eda813-f25a-421d-bf9b-1ead3a3cc691',
                            notForTagging: false,
                            label: 'planet',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dc3f2176-3c64-8bfa-28a1-e50e83ea6591',
                            notForTagging: false,
                            label: 'valley',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c2351522-5440-ef8b-40c5-22b5f21bec9e',
                            notForTagging: false,
                            label: 'ocean',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/993882de-36f9-4582-a826-b62797b7374b',
                            notForTagging: false,
                            label: 'volcano',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0e4bf268-3ad3-c250-6629-c7234f838b87',
                            notForTagging: true,
                            label: 'pond',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3a43a890-f8b8-add6-5628-99e8d8a191bc',
                            notForTagging: false,
                            label: 'lagoon',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a135f84c-fe84-ebd8-3acd-34ee4ed82990',
                            notForTagging: false,
                            label: 'mountain',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/24bac9e2-31fd-42b5-90ee-c04745e40ded',
                            notForTagging: false,
                            label: 'moon',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e1463f0b-a6f0-0c3f-8dd0-e3a5c4fcb3b0',
                            notForTagging: true,
                            label: 'sea',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bc37cb26-03ac-7c8b-dbf1-dd605bb9ff9a',
                            notForTagging: false,
                            label: 'crater',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ae4ad35d-a878-4b58-a0cd-7b7b4087e305',
                            notForTagging: false,
                            label: 'creek',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/523d67e8-5f37-62d3-a674-30059183f9ad',
                            notForTagging: false,
                            label: 'beach',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f04968c7-26b4-9e22-dd9c-4bd25824948a',
                            notForTagging: false,
                            label: 'pasture',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f44783c6-8588-6613-a91e-32b5b75c5e81',
                            notForTagging: false,
                            label: 'comet',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/88ac27c5-926a-4944-9284-a403874f8824',
                            notForTagging: false,
                            label: 'island',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/09252c7e-dd6f-4e1f-adcd-32b9498c0720',
                            notForTagging: false,
                            label: 'tide pool',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6f378e3c-8d09-acc7-5878-03c90d802bb0',
                            notForTagging: false,
                            label: 'forest',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e84b4cde-f1b3-1fb0-ff7f-9f165ad324ac',
                            notForTagging: false,
                            label: 'clearing',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/76781ded-c2d2-4b89-8b42-fcb61f7dab4c',
                            notForTagging: false,
                            label: 'water hole',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/de36649d-d391-b4d0-9fd5-1d855e6ea7b6',
                            notForTagging: true,
                            label: 'Aquatic Landscape',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6a33db61-ed52-d7a7-fa5d-b40902f19098',
                            notForTagging: true,
                            label: 'savanna',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6270ba09-f345-4b31-948f-07754eb48cc9',
                            notForTagging: false,
                            label: 'tundra',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e24490fe-fbd1-58cb-ff5c-2715c65b5b84',
                            notForTagging: false,
                            label: 'river',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3f43e0c8-f63b-d2f5-4351-c1058edc3e98',
                            notForTagging: false,
                            label: 'canyon',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a8a48b6b-af1b-4d6f-ad5e-f10b2734fa24',
                            notForTagging: true,
                            label: 'sea surface',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ffa91f59-e517-49cd-9be2-2607b0a344c6',
                            notForTagging: true,
                            label: 'moor',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2b37b2d6-ae18-9ccc-36cb-81dbb346b68a',
                            notForTagging: false,
                            label: 'meadow',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fc3eefa5-f091-8271-872a-ae2e215ba3ab',
                            notForTagging: false,
                            label: 'jungle',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/17876de1-a384-4beb-bce8-4706dae15f11',
                            notForTagging: false,
                            label: 'sinkhole',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/81400299-fa7f-40b1-8cb1-0f2d24d6f427',
                            notForTagging: false,
                            label: 'sky',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d8c56e8f-f986-45b3-a02c-1d2658d4189b',
                            notForTagging: false,
                            label: 'hot springs',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/45430dac-e30e-a0eb-641e-99bb8810dba8',
                            notForTagging: false,
                            label: 'scenic overlook',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f6453c7e-72fb-b1e9-449a-4e01d5b919a6',
                            notForTagging: false,
                            label: 'plains',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4647287b-24f6-929d-705b-5d1a9f9f68b0',
                            notForTagging: false,
                            label: 'desert',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7a4b6f58-78c0-e2c7-ad5d-d2a9b04987b7',
                            notForTagging: false,
                            label: 'waterfall',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9441e53a-a940-6a09-ecbb-ff1a5b003da8',
                            notForTagging: false,
                            label: 'harbor',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fa429e9b-3b25-4b63-3fb2-06072318d7aa',
                            notForTagging: false,
                            label: 'stream',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f09c6e9b-c984-4409-8615-79f7f174a091',
                            notForTagging: false,
                            label: 'space (astronomy)',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d92d28d4-c12a-4a2b-a17a-5cd958abd7df',
                            notForTagging: false,
                            label: 'swamp',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2c4b61e8-c6ea-4815-af9f-c01f54ff2fba',
                            notForTagging: false,
                            label: 'lake',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b73442f7-0b14-75d3-6a47-a637da1e06ae',
                            notForTagging: false,
                            label: 'cave',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8137158b-13c5-485b-8f5a-990a93cae88e',
                            notForTagging: false,
                            label: 'coastline',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c23b5212-6c7b-5c58-b9f6-1503926cbbaf',
                            notForTagging: false,
                            label: 'reef',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bd42b65d-122e-a5fe-9c83-864ddd083d9d',
                            notForTagging: false,
                            label: 'cliff',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/be39655b-d6ad-1e87-7eb5-2f7861b963b2',
                            notForTagging: true,
                            label: 'Landforms',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/124958a1-3e2e-0634-7894-b91f77a44299',
                            notForTagging: true,
                            label: 'grove',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fd4e5a6e-e6e7-b8b2-f423-d1ed1a91ecbd',
                            notForTagging: false,
                            label: 'hill',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/513e047f-55be-429b-4b91-5445e697a793',
                            notForTagging: false,
                            label: 'dune',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/aa4d9335-373b-acfd-30da-bc1e936da497',
                            notForTagging: false,
                            label: 'riverbank',
                            broaderId:
                                'http://data.disney.com/resources/113ddae3-235e-0933-4ad4-c187defc999d',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/b02eb95f-cdba-4b8b-99b3-2da717fb19b4',
                    notForTagging: true,
                    label: 'Object Locations',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/6ce554ad-606b-4aab-b453-b232db58a4b0',
                            notForTagging: false,
                            label: 'conveyors',
                            broaderId:
                                'http://data.disney.com/resources/b02eb95f-cdba-4b8b-99b3-2da717fb19b4',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/95c4e428-48af-4c55-aedc-e5e9f9e07e16',
                            notForTagging: false,
                            label: 'tram',
                            broaderId:
                                'http://data.disney.com/resources/b02eb95f-cdba-4b8b-99b3-2da717fb19b4',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/173ba6bd-abd4-9957-187d-139e835580a4',
                            notForTagging: false,
                            label: 'briefcase',
                            broaderId:
                                'http://data.disney.com/resources/b02eb95f-cdba-4b8b-99b3-2da717fb19b4',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/594d0074-b7ba-2e7a-e6ae-7a7f75c2ec9d',
                            notForTagging: false,
                            label: 'dumpster',
                            broaderId:
                                'http://data.disney.com/resources/b02eb95f-cdba-4b8b-99b3-2da717fb19b4',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/60423a73-ca04-3bad-1591-be76ca905a8c',
                            notForTagging: false,
                            label: 'aquarium (containers)',
                            broaderId:
                                'http://data.disney.com/resources/b02eb95f-cdba-4b8b-99b3-2da717fb19b4',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/06335257-05c9-88bb-70b0-50a648536db9',
                            notForTagging: false,
                            label: 'chest (furnishing)',
                            broaderId:
                                'http://data.disney.com/resources/b02eb95f-cdba-4b8b-99b3-2da717fb19b4',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                    notForTagging: true,
                    label: 'Open Spaces',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/f85696a6-4a5c-486e-a49e-9c5255b2e74c',
                            notForTagging: false,
                            label: 'botanical garden',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c0441ab5-6594-3a61-4b79-44d1d58d8e85',
                            notForTagging: true,
                            label: 'country road',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4735a4f3-e673-59ab-1e83-133fcbd82c82',
                            notForTagging: false,
                            label: 'garden',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3a4c885d-fb78-ded3-d0dd-1295ba4980b4',
                            notForTagging: false,
                            label: 'baseball field',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4f3aa546-c901-c398-7da1-d226fee51c85',
                            notForTagging: false,
                            label: 'yard',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/80429045-0cbb-00b9-34a6-15d9be8df88d',
                            notForTagging: false,
                            label: 'tennis court',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f6477959-0594-4dda-dfde-dd7e46ce4fb2',
                            notForTagging: false,
                            label: 'tarmac',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/60af678e-815e-40ed-9fc6-bd5161339b0c',
                            notForTagging: true,
                            label: 'wormhole',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4942190e-d332-f55c-2504-91647b99eaa4',
                            notForTagging: false,
                            label: 'quad',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fa4970f8-0259-e777-4039-679083f92dad',
                            notForTagging: false,
                            label: 'plaza',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a9402ad8-2916-2048-3c16-88cf2cc13b8d',
                            notForTagging: false,
                            label: 'boardwalk',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/653d0db0-25f4-06ab-b7ae-011403763aaa',
                            notForTagging: true,
                            label: 'Transportation Spaces',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f044c8d0-cdda-f75b-76a3-85d8df526690',
                            notForTagging: false,
                            label: 'picnic area',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7b4d93cf-0aa6-7eff-8ee1-94ba8bc81d83',
                            notForTagging: false,
                            label: 'dirt road',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e54b8e19-5db1-9352-43dc-031011f68c97',
                            notForTagging: true,
                            label: 'lawn',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bc33613c-d2de-b024-3ff7-6795a521fb85',
                            notForTagging: false,
                            label: 'alley',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c541f523-c9cb-3376-6f9c-269750699f96',
                            notForTagging: false,
                            label: 'hedge maze',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a53e3d24-dc4b-e207-c9a7-3e9865ca0782',
                            notForTagging: false,
                            label: 'golf course',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d14b34fd-93f7-bbcb-2162-509f95f14e90',
                            notForTagging: false,
                            label: 'driveway',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7e369bc7-4b91-bffd-2d1d-57bb78d6f194',
                            notForTagging: false,
                            label: 'road',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6a48838a-37be-4021-d739-d9a0364098b2',
                            notForTagging: false,
                            label: 'sideline',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0230e669-8b68-f765-0c15-1d2c049790b1',
                            notForTagging: false,
                            label: 'highway',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d444a1d0-95cd-bc20-1243-bc38d5c8e1b3',
                            notForTagging: false,
                            label: 'trail',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/304275ee-e498-070e-94d8-de038b63e58e',
                            notForTagging: false,
                            label: 'path',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/93477bcb-f591-9348-afd1-68e02113aa9f',
                            notForTagging: false,
                            label: 'football field',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c86ddf1b-d35e-4358-8a70-0f92b0bd0dfb',
                            notForTagging: false,
                            label: 'fairgrounds',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2b49589c-baae-1a10-0332-f3621d9d9b95',
                            notForTagging: false,
                            label: 'airfield',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7ad18f06-3cd7-4821-89e6-dab4097cb490',
                            notForTagging: false,
                            label: 'playground',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0d27ab79-51d0-47bd-9c7d-688e2248058c',
                            notForTagging: false,
                            label: 'cul-de-sac',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ca4cb660-cd14-b53a-92a9-5d099ca9e2ac',
                            notForTagging: false,
                            label: 'sidewalk',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/423a6407-2ef1-85bd-13be-d2fe0d343f81',
                            notForTagging: false,
                            label: 'park',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c2447227-b72d-6385-b15e-f89e24279e8a',
                            notForTagging: false,
                            label: 'dump',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/744a95dd-51d7-6ee7-50c1-8520cdb5ff93',
                            notForTagging: true,
                            label: 'front yard',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e2478161-b535-e333-649d-5865a842299a',
                            notForTagging: false,
                            label: 'soccer field',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b94678f4-0f85-e994-2c6b-9bae95c82b9d',
                            notForTagging: true,
                            label: 'impound lot',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/714419eb-56bb-a426-154e-4ce855ef47b4',
                            notForTagging: true,
                            label: 'median strip',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9f487e1e-0ae1-ce84-25c7-c8280ea2a9bd',
                            notForTagging: true,
                            label: 'polo field',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/224b0d76-a69f-81e8-582f-c61d2da581bb',
                            notForTagging: false,
                            label: 'junkyard',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4d32a42c-7cdc-2381-0a8e-e2ffc861b799',
                            notForTagging: true,
                            label: 'backyard',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e14e58ce-853f-f4b6-4497-2fadff77c188',
                            notForTagging: true,
                            label: 'parking structure',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e648c400-5d2e-239d-f7b8-13736ca0d590',
                            notForTagging: false,
                            label: 'main street',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/91316448-c591-c812-d12a-d3f9c04944b2',
                            notForTagging: false,
                            label: 'street',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3d487f1a-80f9-041f-0a94-b93829668cab',
                            notForTagging: false,
                            label: 'parking lot',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/23cab348-7713-4897-a968-6d9b3edee15d',
                            notForTagging: true,
                            label: 'walkway',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a34a40ee-99a2-be93-8146-921e5c318dbc',
                            notForTagging: false,
                            label: 'camp site',
                            broaderId:
                                'http://data.disney.com/resources/623a389f-90b5-057d-3bbf-4489774f1e8b',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                    notForTagging: true,
                    label: 'Rooms and Spaces',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/354500cf-3877-3fe1-d288-6e9882ac66be',
                            notForTagging: false,
                            label: 'maternity ward',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c24f82c2-b617-1c01-e8f1-2b04c99ef580',
                            notForTagging: false,
                            label: 'study',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/894a86b3-33de-3554-950c-8e2a22d98a95',
                            notForTagging: false,
                            label: 'deck (sun)',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6e469667-d8d4-d8d3-cf40-58854941c4b8',
                            notForTagging: true,
                            label: 'viewing room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8a46f9aa-0abf-85b5-78a7-deaea1ca1398',
                            notForTagging: false,
                            label: 'alcove',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7270c286-7aa0-4876-84f5-827a90190a13',
                            notForTagging: true,
                            label: 'great hall',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ce44aed8-5d09-3dab-f233-92b36ef06f81',
                            notForTagging: false,
                            label: 'telephone booth',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5a4d8489-e485-6575-fa59-853c7b862f87',
                            notForTagging: false,
                            label: 'classroom',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d840b58c-3532-f329-3009-63b5d9bac193',
                            notForTagging: false,
                            label: "nurses' station",
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ef4be240-dced-2c62-fe4f-9847367b6aba',
                            notForTagging: false,
                            label: "experts' area",
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/10346162-288a-0d1a-39c4-f6138c61aebc',
                            notForTagging: false,
                            label: 'workshop',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f746eb4a-7148-3587-3a13-9ac262115cb8',
                            notForTagging: false,
                            label: 'theater box',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d0487218-d7da-ebfe-7162-1194207c55af',
                            notForTagging: true,
                            label: 'music room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bf329c87-7908-b057-8df3-4fb2735065ad',
                            notForTagging: false,
                            label: 'boardroom',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/504be9b2-10bc-2fe2-7d79-7eb3f6944097',
                            notForTagging: false,
                            label: 'auditorium',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a84f5e8e-05d0-0915-88e7-c887009befb7',
                            notForTagging: false,
                            label: 'newsroom',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9440b941-1263-e8f4-8661-a5a2cc2132b2',
                            notForTagging: false,
                            label: 'cloakroom',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c9409cbd-d29e-7e6f-0509-a2cdb6fed08a',
                            notForTagging: false,
                            label: 'utility room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0d33a5c9-a10d-2d3b-3143-5c048db0c0a6',
                            notForTagging: true,
                            label: 'Circulation Spaces',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/464d0259-f770-e3ba-a9fb-e5310ebcd0b6',
                                    notForTagging: false,
                                    label: 'elevator',
                                    broaderId:
                                        'http://data.disney.com/resources/0d33a5c9-a10d-2d3b-3143-5c048db0c0a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/d643a0f5-a4c4-5e2b-f747-e35a2e478f9e',
                                    notForTagging: false,
                                    label: 'fire escape',
                                    broaderId:
                                        'http://data.disney.com/resources/0d33a5c9-a10d-2d3b-3143-5c048db0c0a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/723d9ca8-62fc-858c-5447-3626ffe1cd98',
                                    notForTagging: false,
                                    label: 'stairs',
                                    broaderId:
                                        'http://data.disney.com/resources/0d33a5c9-a10d-2d3b-3143-5c048db0c0a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b24493a6-efb1-8560-4527-126f4176c2a0',
                                    notForTagging: true,
                                    label: 'stairwell',
                                    broaderId:
                                        'http://data.disney.com/resources/0d33a5c9-a10d-2d3b-3143-5c048db0c0a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/09054a84-33c0-4ead-bb9c-f1f74719b623',
                                    notForTagging: false,
                                    label: 'passageway',
                                    broaderId:
                                        'http://data.disney.com/resources/0d33a5c9-a10d-2d3b-3143-5c048db0c0a6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/904a066b-be6c-fa2f-4fa1-3a26c4ad498f',
                                    notForTagging: false,
                                    label: 'hallway',
                                    broaderId:
                                        'http://data.disney.com/resources/0d33a5c9-a10d-2d3b-3143-5c048db0c0a6',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/41491c6a-acfa-5c92-3bba-10d9dbe0febc',
                            notForTagging: false,
                            label: 'emergency room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1644168b-8ea2-f05f-c789-c61c7e1a9889',
                            notForTagging: true,
                            label: 'galley',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/854b77af-31c4-f5bf-a343-92589670b9aa',
                            notForTagging: false,
                            label: 'booking room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410dd8d-009c-64df-9c75-0768b3f8ab87',
                            notForTagging: false,
                            label: 'sound stage',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c53ae292-3b55-9623-efb1-dc20db8bc385',
                            notForTagging: false,
                            label: 'playroom',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/30461f58-efa0-bdbc-3f65-9e88b4a0a1b3',
                            notForTagging: false,
                            label: 'surgical scrub room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/603b9800-e9e5-6d2b-cf2a-579cf21721b9',
                            notForTagging: true,
                            label: 'meeting room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6820d8a6-b60b-4030-b7af-f09952be6b24',
                            notForTagging: false,
                            label: 'darkroom',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9f35f5dc-908e-2b5c-95af-96e768996a8a',
                            notForTagging: false,
                            label: 'dungeon',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/524be53f-598b-59f1-ce1b-67a8426c6888',
                            notForTagging: true,
                            label: 'green room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/24a3e139-12cf-49d1-91c6-27a36dced066',
                            notForTagging: true,
                            label: 'guest room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2049a759-fd94-f2a6-2b2c-df44e97d69b2',
                            notForTagging: false,
                            label: 'pediatric intensive care unit',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7044c7f0-99a2-1574-31ef-1e63941227b5',
                            notForTagging: false,
                            label: 'tack room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/024a37f0-9f9c-71a7-2bac-4f76f1faa2ad',
                            notForTagging: true,
                            label: 'casting room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f049c4d7-0fa4-71f4-2c42-bfeaba8939b2',
                            notForTagging: false,
                            label: 'elevator lobby',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/154128a0-ecf4-9dc6-b0eb-aa5078b3dc8e',
                            notForTagging: false,
                            label: 'lineup room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/80466742-0852-ef21-641e-de8795be82a0',
                            notForTagging: true,
                            label: 'property room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7d4d3854-beea-e7f4-ee81-7b2a22b78a83',
                            notForTagging: false,
                            label: 'radiology department',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/73419acd-a621-3524-c423-f8f50651a4be',
                            notForTagging: false,
                            label: 'lecture hall',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2873dca7-575d-4465-8993-15a25da20d0c',
                            notForTagging: true,
                            label: 'rotunda',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a315f0d2-faad-4b80-89ae-733365ddd722',
                            notForTagging: false,
                            label: 'crawl space',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e540fd4d-89e3-f77d-bc2d-bf978b23f982',
                            notForTagging: false,
                            label: 'penthouse',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cf463171-39bf-017d-760f-0fea90bcbd98',
                            notForTagging: false,
                            label: 'delivery room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/63459588-fa0a-27eb-3a1b-29f44fd607b8',
                            notForTagging: false,
                            label: 'departure lounge',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/044fe535-cf7a-69d5-19c8-c9e6ce7423a3',
                            notForTagging: false,
                            label: 'ticket booth',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d041e928-61d0-90df-304b-084968df4882',
                            notForTagging: false,
                            label: 'observation room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/08459411-4a5b-b66d-2af6-6de4c0c78bb6',
                            notForTagging: false,
                            label: 'solarium',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/384bed33-0fb9-374e-bb5b-5f2cdae5719d',
                            notForTagging: false,
                            label: 'gun room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f546eb63-6371-5212-f470-b675f8abd684',
                            notForTagging: false,
                            label: 'vacuum chamber',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1d464ada-a2d8-cae7-4d8a-5e85d590bcb6',
                            notForTagging: false,
                            label: 'hideout',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5f3ae450-2d6c-1d19-5c3e-eb7c7b286088',
                            notForTagging: false,
                            label: 'cellar',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ff4b264b-373b-2129-eafc-9094ac75c384',
                            notForTagging: false,
                            label: 'reception area',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1e34c716-ede3-4e3c-d672-07824f7b8abc',
                            notForTagging: false,
                            label: 'storage room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/af4e7bcd-bc8d-c742-1511-cc5c19a3e2b1',
                            notForTagging: false,
                            label: 'intensive care unit',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d540d3fb-66f6-53c6-170e-e1bfb724e2a9',
                            notForTagging: false,
                            label: 'recreation room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/344fac71-993c-a96b-5ad7-a30533bbd38a',
                            notForTagging: false,
                            label: 'stage',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/82366be6-9343-e2b4-52bb-163391f45185',
                            notForTagging: false,
                            label: 'backstage',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/744d0250-c511-52d7-36fc-5ef4368af1ae',
                            notForTagging: false,
                            label: 'squad room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ff2c1a66-f7fa-4515-8576-31893a24390d',
                            notForTagging: true,
                            label: 'broadcasting studio',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a44e85ba-df02-b2d1-016a-bbebe4e6078e',
                            notForTagging: true,
                            label: 'on-call room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410d98d-e8d7-81c0-9c75-0768b3f87886',
                            notForTagging: false,
                            label: 'office',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d74eda9d-039f-87a7-72a3-784b32d4b1a6',
                            notForTagging: true,
                            label: 'isolation room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3e380a3a-5616-1610-6ba8-924d2376eca7',
                            notForTagging: false,
                            label: 'ballroom',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3a3db648-f73d-1906-6ba8-fb74b35c038c',
                            notForTagging: false,
                            label: 'balcony',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/293ae81f-376f-a28f-d4ec-b77a9c3182b0',
                            notForTagging: false,
                            label: 'lounge',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/264dbc49-2a1c-6c62-e1aa-7de13ce97c85',
                            notForTagging: false,
                            label: 'dressing room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/044bb7da-1835-3653-2314-3dd888aaa2b6',
                            notForTagging: false,
                            label: 'recording booth',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7b136ce9-e089-4f3c-a9b2-bc57da7af837',
                            notForTagging: false,
                            label: 'air shaft',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b247268f-03f6-a0eb-7509-ff27ca4c949b',
                            notForTagging: true,
                            label: 'media room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a1456b22-010f-4ceb-bc7f-7f5a27505980',
                            notForTagging: false,
                            label: 'locker room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8c3d08b3-8f5b-dd6e-bc18-10632fa26c8c',
                            notForTagging: false,
                            label: 'bedroom',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/454c3b2a-b69e-2ce3-db6a-714a8c71a097',
                            notForTagging: false,
                            label: 'employee lounge',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0d3d8e2f-2e0b-9da2-e67e-d1b34102d880',
                            notForTagging: false,
                            label: 'courtroom',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2b782ef3-5b1a-44cb-8361-46e758777dfc',
                            notForTagging: false,
                            label: 'showroom',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/913ab5af-30e3-69fc-a844-5573295e6eb2',
                            notForTagging: false,
                            label: 'attic',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4c4a0478-b927-9c28-9176-bb3415a931b9',
                            notForTagging: false,
                            label: 'rest room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9141cd35-4919-f742-b735-c109fd49379b',
                            notForTagging: false,
                            label: 'conference room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c64fecd7-9bac-63d5-5c56-61e76af28da7',
                            notForTagging: true,
                            label: 'suite',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8537556a-d361-87ce-c266-31df7386f2a1',
                            notForTagging: false,
                            label: 'closet',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/604491ce-432b-49ea-7941-0c8679ae4f91',
                            notForTagging: true,
                            label: 'V.I.P. lounge',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/44498b87-2251-6c58-8fd5-ff726dc17999',
                            notForTagging: false,
                            label: 'waiting room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2b31a934-52e3-a502-91a4-30608efb279b',
                            notForTagging: true,
                            label: 'Entrance Spaces',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/e948d41e-ba58-e8f5-8b7e-2c5f956fd9a7',
                                    notForTagging: false,
                                    label: 'loading dock',
                                    broaderId:
                                        'http://data.disney.com/resources/2b31a934-52e3-a502-91a4-30608efb279b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/084bea97-92a7-db87-ca8f-361a9990f798',
                                    notForTagging: false,
                                    label: 'entryway',
                                    broaderId:
                                        'http://data.disney.com/resources/2b31a934-52e3-a502-91a4-30608efb279b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/be464555-cc44-ad0d-40e0-33ede37bf09b',
                                    notForTagging: false,
                                    label: 'red carpet',
                                    broaderId:
                                        'http://data.disney.com/resources/2b31a934-52e3-a502-91a4-30608efb279b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/d93766b6-7843-2519-d972-cf2332585fa4',
                                    notForTagging: false,
                                    label: 'lobby',
                                    broaderId:
                                        'http://data.disney.com/resources/2b31a934-52e3-a502-91a4-30608efb279b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/854402a7-1bcf-ba42-477a-a5f89005e4a1',
                                    notForTagging: true,
                                    label: 'anteroom',
                                    broaderId:
                                        'http://data.disney.com/resources/2b31a934-52e3-a502-91a4-30608efb279b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5f3df1af-8dbe-e6ed-fc9f-c73a73a191af',
                                    notForTagging: false,
                                    label: 'atrium',
                                    broaderId:
                                        'http://data.disney.com/resources/2b31a934-52e3-a502-91a4-30608efb279b',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/0246f06f-0c88-682e-8e00-3524e06cf6b1',
                            notForTagging: true,
                            label: 'reading room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/90487992-8cc2-5096-677a-9a3891b9f887',
                            notForTagging: false,
                            label: 'screening room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/17450877-5c3d-baf4-8e8a-49309ae43a9c',
                            notForTagging: false,
                            label: 'hotel room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ca4317fa-c42b-4c91-b11b-0d16ae6dfdb1',
                            notForTagging: false,
                            label: 'operating room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/30417c01-5c00-fb4b-d5a1-c31757984fb2',
                            notForTagging: false,
                            label: 'dorm room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6947996b-6f72-d549-6819-9a673dca8ba9',
                            notForTagging: false,
                            label: 'recovery room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/62485be8-1ef0-520f-e3c1-a3c7d67e46b7',
                            notForTagging: false,
                            label: 'press box',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e94529b8-4d19-fd5a-74b4-1cb2c8e9c6b0',
                            notForTagging: false,
                            label: 'sauna',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8d4cc183-536e-1f0e-9fe0-103233417a97',
                            notForTagging: false,
                            label: 'throne room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0a4234aa-737c-0172-15a9-b7b6ab134bb7',
                            notForTagging: false,
                            label: 'interrogation room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b23cd589-07a6-ad7b-f3f8-bf9e36a573ac',
                            notForTagging: false,
                            label: 'laundry room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9e36be51-80ea-4030-9788-88ff8893b8a3',
                            notForTagging: true,
                            label: 'hospital ward',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5341eb70-ae21-28b2-0cbe-4b6459cfc8b7',
                            notForTagging: false,
                            label: 'parlor',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dc455e5c-0090-15a6-accf-1f9e2439fa99',
                            notForTagging: false,
                            label: 'coronary care unit',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/601045e9-4a6d-4062-8c8d-b04d46f2d99c',
                            notForTagging: false,
                            label: "mayor's office",
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/56407983-c98d-49aa-23bc-3053d861f8ba',
                            notForTagging: true,
                            label: 'breezeway',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/903a5148-5844-c75c-6243-6e58afe375b0',
                            notForTagging: false,
                            label: 'engine room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a0422fc3-df43-dc78-1651-708e422b88a7',
                            notForTagging: true,
                            label: 'nave',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3a4dc4da-64d8-784a-fad0-e69f1634b2a5',
                            notForTagging: false,
                            label: 'crew quarters',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2d3c1fa8-2b58-c754-ad1a-7b51c6e4039f',
                            notForTagging: false,
                            label: 'living room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fd31d939-c648-e509-d019-5b64abd183a1',
                            notForTagging: false,
                            label: 'patio',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cb4ddc29-0fde-b334-36ec-3bc26afb728f',
                            notForTagging: false,
                            label: 'baggage claim',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4d4f0efc-9eda-e31a-2e2a-44bd4a38b382',
                            notForTagging: false,
                            label: 'kitchen',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dd41ab25-5629-382d-79a1-f839cb83a6bb',
                            notForTagging: true,
                            label: 'shower',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5e4aab2e-ebd7-faad-27d9-0db47fceef8f',
                            notForTagging: false,
                            label: 'crypt',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f23f5ef7-ec1d-731a-dbd2-2b51792b2ab4',
                            notForTagging: false,
                            label: 'dining room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/16404de2-98e8-416c-ada3-62c8d425fbbb',
                            notForTagging: false,
                            label: 'courtyard',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7a02f89d-55c1-456b-bdc2-d26b7d37a53b',
                            notForTagging: true,
                            label: 'sanctuary',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4841cfc0-85a6-d6a3-c145-522cd44b2b87',
                            notForTagging: false,
                            label: 'visiting room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/772f6ddc-7ceb-4637-aafc-2c909a24ec0a',
                            notForTagging: false,
                            label: 'photobooth',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ad4f53bd-4439-d05f-9015-52d499d3fb8a',
                            notForTagging: false,
                            label: 'prison cell',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2a3ca3fb-6394-774d-05c2-4c580ad53ba6',
                            notForTagging: false,
                            label: 'basement',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8f357421-5579-b303-4db1-8fa5d2d3fc9d',
                            notForTagging: false,
                            label: 'gallery',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e94625c4-965e-51d6-edd8-bcc3bbf69693',
                            notForTagging: true,
                            label: 'chapel',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/83469532-1d16-acda-db32-b2dbb9475484',
                            notForTagging: false,
                            label: 'hospital room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/48482ca8-1340-5415-4bab-9429d4251a97',
                            notForTagging: false,
                            label: 'stoop',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/124c76b2-cf5d-f6c0-3584-de74bcd4039e',
                            notForTagging: false,
                            label: "judge's chambers",
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ac31aac4-e860-98c5-f04b-94ee5a808e9c',
                            notForTagging: false,
                            label: 'control room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/45514b9c-281e-452b-8816-2bbc774f803f',
                            notForTagging: true,
                            label: 'war room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f24dbecc-a813-43c6-f2bd-3664477d49b1',
                            notForTagging: true,
                            label: 'wait station',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a440212e-776a-cebd-77cc-d5e72ea18ea2',
                            notForTagging: true,
                            label: 'rooftop',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2b4b8e05-5a60-2ab8-e24a-baca0b55baa9',
                            notForTagging: false,
                            label: 'den',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/da4ad7f5-3f9e-2692-00a0-d10954182e95',
                            notForTagging: false,
                            label: 'vault',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9844f93e-6bba-14c6-7f71-4969150246ab',
                            notForTagging: false,
                            label: 'porch',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9b4eec92-95d5-168b-16d6-9855b837cfa8',
                            notForTagging: false,
                            label: 'tech room',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/374eb609-0426-7b3e-d97e-4fda6cdc72a4',
                            notForTagging: true,
                            label: 'holding cell',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b1471cda-e5db-a5e0-198e-ee63e29b52ae',
                            notForTagging: false,
                            label: 'storm cellar',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/57343f91-75fe-f367-b0f4-cf2302dc1bb9',
                            notForTagging: false,
                            label: 'bathroom',
                            broaderId:
                                'http://data.disney.com/resources/a53110bc-97be-fc1a-70d4-999e71ce488b',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/12386f8a-8605-abe9-b3ca-fd6eb924c880',
                    notForTagging: true,
                    label: 'Settlements',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/c94dd799-36ae-2f36-5654-b59eccf5b687',
                            notForTagging: false,
                            label: 'Indian reservation',
                            broaderId:
                                'http://data.disney.com/resources/12386f8a-8605-abe9-b3ca-fd6eb924c880',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5539d7c5-fa47-2aa3-8c41-7cfb3ea01a96',
                            notForTagging: false,
                            label: 'port',
                            broaderId:
                                'http://data.disney.com/resources/12386f8a-8605-abe9-b3ca-fd6eb924c880',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/480465c5-8f72-4fe7-a5d1-2ba39d403e3d',
                            notForTagging: false,
                            label: 'rural area',
                            broaderId:
                                'http://data.disney.com/resources/12386f8a-8605-abe9-b3ca-fd6eb924c880',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1d3797bc-67f4-5685-d716-42682103278d',
                            notForTagging: false,
                            label: 'town',
                            broaderId:
                                'http://data.disney.com/resources/12386f8a-8605-abe9-b3ca-fd6eb924c880',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c038ff1e-0834-3215-9732-d5bad2cb75b0',
                            notForTagging: false,
                            label: 'village',
                            broaderId:
                                'http://data.disney.com/resources/12386f8a-8605-abe9-b3ca-fd6eb924c880',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/423fce39-e099-d3e1-f601-9c0275497e89',
                            notForTagging: false,
                            label: 'city',
                            broaderId:
                                'http://data.disney.com/resources/12386f8a-8605-abe9-b3ca-fd6eb924c880',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                    notForTagging: true,
                    label: 'Vehicle Locations',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/53964853-dd04-47e4-bb27-0a62c730ac91',
                            notForTagging: true,
                            label: 'surveillance van',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f0437d72-b048-7e8f-3839-e9d6ed58f6ad',
                            notForTagging: false,
                            label: 'bus',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d24b7e7e-53ec-99d9-d58d-638abc45b58d',
                            notForTagging: false,
                            label: 'food truck',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1d48f887-6dec-7a77-9242-a9f0a02ac08c',
                            notForTagging: false,
                            label: 'main cabin',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8904d6b2-5af4-4143-846c-620dfb6c6dab',
                            notForTagging: false,
                            label: 'tractor',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/04105fc7-b89e-c9ef-9c42-8599dcde2c86',
                            notForTagging: false,
                            label: 'submarine',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/394d2b47-3894-13b7-039e-edba6cef51b1',
                            notForTagging: false,
                            label: 'wagon',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/784b1cba-6fa1-e197-0256-7f688d0cdb96',
                            notForTagging: false,
                            label: 'limousine',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cf7a2490-5136-40d4-880b-88a86c4436da',
                            notForTagging: false,
                            label: 'unidentified flying object',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6f395916-2bde-e699-00df-e5937e4f40b5',
                            notForTagging: false,
                            label: 'truck',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/04e9709f-367c-4cef-b4eb-db6fe6f4c38b',
                            notForTagging: false,
                            label: 'space vehicle',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a63040bb-3e8b-51c7-e933-6f9519f208a9',
                            notForTagging: false,
                            label: 'monorail',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6d4369f2-6af1-a16e-7f5f-8d95f1c677b6',
                            notForTagging: false,
                            label: 'ambulance',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cc45b6bb-9303-51af-cc59-5c237c3aed9f',
                            notForTagging: false,
                            label: 'helicopter',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/81476ce8-f3ed-e215-bad8-e624186b36a7',
                            notForTagging: false,
                            label: 'blimp',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/35fcd67b-7988-48ba-b046-f337c9bb8a88',
                            notForTagging: false,
                            label: 'houseboat',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4a89209a-48ca-4cd0-a0ca-f66b684b638e',
                            notForTagging: false,
                            label: 'sleigh',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fffa627a-9b0d-4373-8f3c-7664fce152b7',
                            notForTagging: false,
                            label: 'armored personnel carrier',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ee3c3234-703f-29e1-d6a4-4ffcdc848585',
                            notForTagging: false,
                            label: 'airplane',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1e41b475-fb03-61ff-47fc-160a04b13ba8',
                            notForTagging: false,
                            label: 'police car',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1e3d1228-e43a-e3fb-a1a7-4e47462215a2',
                            notForTagging: false,
                            label: 'boat',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1b49cc1b-c37b-405e-0e67-146a6436e79a',
                            notForTagging: false,
                            label: 'fire engine',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fc3b6f6c-710b-39df-1e30-9c11197750ab',
                            notForTagging: false,
                            label: 'ship',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e347ae18-39ad-470b-2614-93942c08819d',
                            notForTagging: false,
                            label: 'trailer',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dd46bffc-a588-e1c5-8f0d-93a760add598',
                            notForTagging: false,
                            label: 'taxicab',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fdded535-2c77-43c7-9bcf-10ca0f086308',
                            notForTagging: false,
                            label: 'aircraft carrier',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/024396e2-6cb0-974e-0a80-7c41f4882e90',
                            notForTagging: false,
                            label: 'cargo hold',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a465a9e9-5bce-4e54-b62b-0c07fef4a16e',
                            notForTagging: false,
                            label: 'pilothouse',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cd407127-abf8-4e8f-4d1e-cf63de35c2ad',
                            notForTagging: false,
                            label: 'hot air balloon',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2d3ae63d-a149-f7fb-1ad5-f5a075669c82',
                            notForTagging: false,
                            label: 'float',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/584906e5-8d77-36c8-2eeb-579a1aae3ab1',
                            notForTagging: false,
                            label: 'railroad car',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/04107991-88da-be4c-9c72-f07774cb2289',
                            notForTagging: false,
                            label: 'carriage',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/73b95674-7543-4d81-ab07-e8e3ee2e3fbb',
                            notForTagging: false,
                            label: 'time machine',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/993eb266-bb08-f98b-5f75-dc7cbac768bd',
                            notForTagging: false,
                            label: 'automobile',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/11335406-1459-b6c4-c53a-bf4e352a4f9a',
                            notForTagging: false,
                            label: 'van',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2c0bec38-b5e2-40e5-b8d1-a16d9041af44',
                            notForTagging: false,
                            label: 'ferry',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1e42519c-8fd5-92f9-3469-f5192a128991',
                            notForTagging: false,
                            label: 'mail truck',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/68402b67-641b-68e2-6612-01154dc239bc',
                            notForTagging: false,
                            label: 'subway train',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3c442abe-ad8d-6777-67e0-d45a74af19b2',
                            notForTagging: false,
                            label: 'recreational vehicle',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dc4ca254-0e58-8c2d-e2ca-2f1e2a50f781',
                            notForTagging: false,
                            label: 'cockpit',
                            broaderId:
                                'http://data.disney.com/resources/ad2a794f-4a9b-4d11-91d9-31756f2ae561',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/810a154c-e809-408a-86bb-b64800ec343f',
                    notForTagging: false,
                    label: 'archaeological site',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4144ad77-75b6-50fe-ad06-0d6a47970f9d',
                    notForTagging: false,
                    label: 'cityscapes',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b9411874-f9af-ca80-0a09-7c16d682fea2',
                    notForTagging: false,
                    label: 'crash site',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1a4d8de1-5fe6-264c-1658-fade81698c92',
                    notForTagging: false,
                    label: 'crime scene',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/514791c7-a181-1e10-6723-c707d32c4b8a',
                    notForTagging: false,
                    label: 'gallows',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/01b49176-c694-4fb4-8a95-26e9bf322dba',
                    notForTagging: false,
                    label: 'heaven',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2f403ec0-82bf-49e9-f606-1f2254e4e181',
                    notForTagging: false,
                    label: 'hot tub',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/664b659c-5c05-b6a0-851b-29c915a2e295',
                    notForTagging: false,
                    label: 'moat',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/98f43677-044b-4252-a1de-1d861fd50e57',
                    notForTagging: false,
                    label: 'montage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0a768be3-1448-4c93-b48f-d86e994741e4',
                    notForTagging: false,
                    label: 'negative space',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6413261b-da61-457c-b005-d95318bfd786',
                    notForTagging: false,
                    label: 'parallel universe',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/16c4ce9b-9102-4bdf-bc28-537decdd4d54',
                    notForTagging: false,
                    label: 'sewer system',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d52af4ae-5a09-400b-8a20-cbcfcf9f1652',
                    notForTagging: false,
                    label: 'trapping pit',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ea4d8823-1407-f629-6b93-9364ce6b058b',
                    notForTagging: false,
                    label: 'trench',
                    terms: [],
                },
            ],
        },
        {
            label: 'Genres',
            type: 'http://data.disney.com/resources/Genres',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/ea3b750a-8909-6a41-ee63-905a1c2ab982',
                    notForTagging: false,
                    label: 'Western',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/73e25652-5c39-4200-a6f4-ad6bd92f01a7',
                    notForTagging: false,
                    label: 'action-adventure',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/673d9e06-aa2c-ca5e-62ce-4dbf587d078e',
                    notForTagging: true,
                    label: 'animal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/57dcaae3-99cc-4913-a410-45247470f6e4',
                    notForTagging: false,
                    label: 'animals & nature',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7f04aaa0-bead-434f-bf4d-db80b62abfc4',
                    notForTagging: false,
                    label: 'animation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a781ed35-f9f4-4a91-89c0-3dcf7c6fb4d1',
                    notForTagging: false,
                    label: 'anime',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/88377a70-0340-33bb-2eeb-882202acc2ac',
                    notForTagging: false,
                    label: 'anthology',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/959ac266-893e-4baa-bb31-a54cedef934e',
                    notForTagging: false,
                    label: 'b genre',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7f3cc61a-e4a2-0490-d74f-3ff636276e89',
                    notForTagging: false,
                    label: 'biographical',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fc7196d4-e4c4-40db-a7d7-6437b677efd0',
                    notForTagging: false,
                    label: 'bmn genre',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/445f3ff8-1131-4aaf-951d-70c64412672c',
                    notForTagging: false,
                    label: 'buddy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/41f1b19f-fd71-491f-99d6-33c6ada443b4',
                    notForTagging: true,
                    label: 'caper',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/973d06a8-70aa-1389-7b43-15b9384c269c',
                    notForTagging: true,
                    label: "children's",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/453e62ae-7d87-fa3d-3ebd-75f7065283b7',
                    notForTagging: false,
                    label: 'comedy',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/9a56e910-90d1-4336-b544-fab908fe08ee',
                            notForTagging: true,
                            label: 'slapstick (genre)',
                            broaderId:
                                'http://data.disney.com/resources/453e62ae-7d87-fa3d-3ebd-75f7065283b7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/df3e798a-c110-ca2f-8847-f32ae4288f83',
                            notForTagging: true,
                            label: 'situation comedy',
                            broaderId:
                                'http://data.disney.com/resources/453e62ae-7d87-fa3d-3ebd-75f7065283b7',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/f12a3124-5d46-4533-85d6-321b08b8ccc8',
                    notForTagging: false,
                    label: 'coming of age',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/427b0fe9-ac99-4351-8ba5-bf6c056e16d6',
                    notForTagging: false,
                    label: 'concert film',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b1762989-983a-4cef-9c50-4031517b1a0d',
                    notForTagging: true,
                    label: 'cooking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bfb3d7c3-9e25-4c19-974c-32528a63a61d',
                    notForTagging: false,
                    label: 'crime (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/be00b911-1214-4d05-a87d-b520606b9415',
                    notForTagging: false,
                    label: 'dance',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/01b6a1f0-7001-4bb9-aeb9-973a6b59e31e',
                    notForTagging: false,
                    label: 'db-test-genre',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9e6f3fda-57ec-435a-8e2d-ee98b2705493',
                    notForTagging: false,
                    label: 'disaster (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/de322b05-a912-a10e-93f3-19e5341df0bf',
                    notForTagging: false,
                    label: 'documentary',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0e9748c0-8454-4906-8629-0ca0dd108a95',
                    notForTagging: false,
                    label: 'docuseries',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4736a8b7-a13b-74de-237d-c826e55e778a',
                    notForTagging: false,
                    label: 'drama',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3c3ad6b6-1351-bf95-0201-4bd1e81c90b0',
                    notForTagging: true,
                    label: 'educational',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0eaebccc-abee-4c88-b149-9cc844ebd388',
                    notForTagging: false,
                    label: 'family (D+ genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b53e4274-0a31-ed37-daf3-ebfcbafc0dbe',
                    notForTagging: false,
                    label: 'fantasy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9045f541-b7fe-05dc-f7fb-eb39f282439b',
                    notForTagging: false,
                    label: 'film noir',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/af3a421c-6cd5-df55-9840-2cbcc1a28eaa',
                    notForTagging: false,
                    label: 'game show/competition',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f43180ee-5b2d-6e85-2cc0-304ad2b538a8',
                    notForTagging: false,
                    label: 'historical',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b03b159a-9ba9-530a-e724-87534b82efb4',
                    notForTagging: false,
                    label: 'horror',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2633c158-65c2-6953-7404-be76ecd7ab96',
                    notForTagging: true,
                    label: 'instructional',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/68b4075b-7aee-4a1b-b9b5-162171996694',
                    notForTagging: false,
                    label: 'kids',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dd431684-f8ac-42c1-8a15-877d85ddd149',
                    notForTagging: false,
                    label: 'legal (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9328d57d-b941-4b0b-8e86-653a6ad3c2e6',
                    notForTagging: false,
                    label: 'lifestyle',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/323d4a4d-b6d7-1369-3219-d9bc3cba8991',
                    notForTagging: true,
                    label: 'magazine',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e24eb390-ff36-2fe2-6e83-3c08e3c0829a',
                    notForTagging: true,
                    label: 'martial arts',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d9ee5dd7-08ab-4f65-87a4-f21aa54e8359',
                    notForTagging: false,
                    label: 'medical (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d333c079-4300-54be-0d6f-2e25296b899a',
                    notForTagging: true,
                    label: 'military',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ce3674bd-91b5-ab0e-099c-df4e831c62bc',
                    notForTagging: false,
                    label: 'music (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/853fd702-189b-ac3c-4ae1-19514ea9089e',
                    notForTagging: true,
                    label: 'music video',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7c300e5c-6875-5729-529d-3955fe1d72af',
                    notForTagging: false,
                    label: 'musical',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5d3874ac-a94d-f8f3-a2ec-af7e015874a0',
                    notForTagging: false,
                    label: 'mystery',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/533ec9e9-7759-40a9-9839-80f6487073ba',
                    notForTagging: true,
                    label: 'nature',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e93c1b92-aa6c-9ce3-edce-9f5bdb66b285',
                    notForTagging: false,
                    label: 'news',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fd346aea-e1b2-2d0f-8095-71b8b081c6b0',
                    notForTagging: false,
                    label: 'parody (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/013d2ab5-3160-6141-b8b8-3399c1d170a0',
                    notForTagging: false,
                    label: 'police/cop',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f7c515c9-9a9d-46cd-a572-b799fec224f1',
                    notForTagging: true,
                    label: 'political (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4f5c53f2-cf1c-40d2-9001-d0c8ac068c40',
                    notForTagging: false,
                    label: 'procedural',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8b376c79-c689-c54c-8fa9-ac93f4a3d382',
                    notForTagging: true,
                    label: 'public affairs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bc37d71d-9504-77e2-7cd5-6f1e513c0f82',
                    notForTagging: false,
                    label: 'reality',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/aa3be381-fb83-fa5b-6fc1-b57eec07268b',
                    notForTagging: true,
                    label: 'religious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bac206f2-e0c5-4ab3-be14-4eb3280fef1e',
                    notForTagging: true,
                    label: 'road (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d8343820-5ac3-c5fc-c4e5-70e50b1dbca7',
                    notForTagging: false,
                    label: 'romance (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7d67ea14-a80f-43f4-ad6c-094563a43c6c',
                    notForTagging: false,
                    label: 'romantic comedy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/33d0cad6-5c3c-4b72-9271-7621603adc04',
                    notForTagging: true,
                    label: 'satire',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/df3cb718-ea7c-17a8-9565-5ecfdea59c98',
                    notForTagging: false,
                    label: 'science fiction',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ad3b69a6-ffc8-dcdb-1a50-a2c4144d6dad',
                    notForTagging: false,
                    label: 'soap opera/melodrama',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3e14bf1e-6e29-4001-8853-600e662332f9',
                    notForTagging: false,
                    label: 'sports (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/61543603-decd-4348-94b8-97035525357c',
                    notForTagging: false,
                    label: 'spy/espionage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fbd342f6-22f2-45b5-bcc6-a2c01e881685',
                    notForTagging: false,
                    label: 'superhero (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/87340901-ca01-477a-9158-285969b02f67',
                    notForTagging: false,
                    label: 'survival (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7839bd33-9e67-c6d4-9b14-aa39acd61699',
                    notForTagging: false,
                    label: 'talk show',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9035e016-26af-60aa-8541-67fc91047982',
                    notForTagging: false,
                    label: 'thriller',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f334230a-cb59-758f-49ca-928388581bbd',
                    notForTagging: true,
                    label: 'travel (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cb37e9d7-6733-06cb-5a0b-3bbe228f1496',
                    notForTagging: false,
                    label: 'variety',
                    terms: [],
                },
            ],
        },
        {
            label: 'Holidays & Seasons',
            type: 'http://data.disney.com/resources/HolidaysSeasons',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/2f7e3119-5235-47d9-909a-db1f8d72f8e7',
                    notForTagging: false,
                    label: '16 de Septiembre',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1efbf899-e6cc-44cc-bbb7-b0b395323777',
                    notForTagging: false,
                    label: "All Souls' Day",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0d8b10f2-9f4f-427f-9450-ec75df4f1091',
                    notForTagging: false,
                    label: "April Fool's Day",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5dd5a832-170f-4fde-9ea3-4c6f8d972ab3',
                    notForTagging: false,
                    label: 'Ash Wednesday',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/93ebe02a-0aaa-4d14-8b81-ea0afcb744c7',
                    notForTagging: false,
                    label: 'Autumn',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/97972b8d-6754-43da-88b6-7b9a7dface18',
                    notForTagging: false,
                    label: 'Back to School',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6203651b-810e-44a8-8f4b-a25d6067cdd1',
                    notForTagging: false,
                    label: 'Black Friday',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6621c514-f1e5-4e94-ac7d-952f7bad62c9',
                    notForTagging: false,
                    label: 'Carnival',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0e086272-1182-45cb-9917-35800aed8f25',
                    notForTagging: false,
                    label: 'Chinese New Year',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8659f079-64db-41ad-9253-2cef98317c89',
                    notForTagging: false,
                    label: 'Christmas',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f4923726-3225-434e-925c-32055bdc324d',
                    notForTagging: false,
                    label: 'Cyber Monday',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1d1e05a0-7ab7-401e-98f4-50b351e3a448',
                    notForTagging: false,
                    label: 'Day of the Dead',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f6864a25-0be8-4180-9066-94955195432d',
                    notForTagging: false,
                    label: 'Divali',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a4d699f0-9f42-416f-8bd0-c29238365939',
                    notForTagging: false,
                    label: 'Día de los inocentes mexicano',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/826e1112-babe-4820-b7b3-2c4d15d8d8b4',
                    notForTagging: false,
                    label: 'Easter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/83786ede-636a-4292-ac13-1b70d0f01cea',
                    notForTagging: false,
                    label: 'Eid al-Adha',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/eef7dfcb-0c0c-4f0b-871a-84bbca0394c4',
                    notForTagging: false,
                    label: 'Eid al-Fitr',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f1690f1f-6c8f-43a3-a546-d367cf369049',
                    notForTagging: false,
                    label: 'Election Day',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0b8ebe3d-a5f3-4ad6-8fd9-8057980d3de1',
                    notForTagging: false,
                    label: 'Epiphany',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4bf984b4-655d-4823-b3c2-755b60185ffb',
                    notForTagging: false,
                    label: "Father's Day",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1ca1c912-f8a5-4510-9f2f-7b73e047d376',
                    notForTagging: false,
                    label: 'Good Friday',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/839a322e-bdf9-49ea-a274-f4119ee6cc94',
                    notForTagging: false,
                    label: 'Halloween',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/51788201-699e-4593-a42b-2543c92cbe77',
                    notForTagging: false,
                    label: 'Hanukkah',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/43665f00-48db-4351-b429-f80bc81443a2',
                    notForTagging: false,
                    label: 'Independence Day',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7ce42beb-356d-44f0-8ead-777f8e3f029b',
                    notForTagging: false,
                    label: 'Juneteenth',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/07e51798-8695-45c4-b9e0-e319f310d1ef',
                    notForTagging: false,
                    label: 'Kwanzaa',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7c35a2d7-7177-4286-83d8-a2bfbb61cfa4',
                    notForTagging: false,
                    label: 'Labor Day',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ed8e6a89-12fa-4003-90f9-9615f49ec38c',
                    notForTagging: false,
                    label: 'Mardi Gras',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9ae19061-2395-485b-aa79-d410754c1540',
                    notForTagging: false,
                    label: 'Martin Luther King Jr. Day',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7c26972b-e971-4a8f-b33f-c1fe3dab6395',
                    notForTagging: false,
                    label: 'May Day',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cf1759e9-fceb-4668-a730-98fab84f427e',
                    notForTagging: false,
                    label: 'Memorial Day',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b29a1e86-4533-4863-b11d-7d56f3b2afeb',
                    notForTagging: false,
                    label: "Mother's Day",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/36797f26-8a33-49bd-9367-5c6def35c098',
                    notForTagging: false,
                    label: "New Year's Day",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2d4a0a60-e0f1-4b84-9ba0-37bdfb802fe7',
                    notForTagging: false,
                    label: "New Year's Eve",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/63b4c636-ceae-44fc-ae5c-d6e784329589',
                    notForTagging: false,
                    label: 'Palm Sunday',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3d1ffd8c-8314-443b-a6a8-e62fe755c859',
                    notForTagging: false,
                    label: 'Passover',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/19caf85f-bba7-4889-8ede-a3b170831865',
                    notForTagging: false,
                    label: "Presidents' Day",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/597d17ab-f288-4563-b7cb-f70b3a2dfb14',
                    notForTagging: false,
                    label: 'Ramadan',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8f69291d-d680-4a78-a76c-4e3012347233',
                    notForTagging: false,
                    label: 'Rosh Hashanah',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/854fefac-2395-4208-8d8d-342737e25483',
                    notForTagging: false,
                    label: 'Spring',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3d847b5f-0298-4d33-997e-cfb9d4d3eb59',
                    notForTagging: false,
                    label: "St. Patrick's Day",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3bdfc33e-612d-4724-ad69-19ce645ccaff',
                    notForTagging: false,
                    label: 'Summer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/06a2ace0-2391-4516-bad5-d52325e27411',
                    notForTagging: false,
                    label: 'Thanksgiving',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5ffcc244-1160-4e57-a934-7484133d0e49',
                    notForTagging: false,
                    label: "Valentine's Day",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b61ff566-04cf-4630-b938-4d20b84e3c20',
                    notForTagging: false,
                    label: 'Vesak',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/abb367ec-1e12-4681-95fd-e4dbb5c97993',
                    notForTagging: false,
                    label: 'Winter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/80f4aac6-ea3c-4fcd-b1e0-3f5782609370',
                    notForTagging: false,
                    label: 'Yom Kippur',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3a45e6cc-fee1-41b0-afdb-e8730d6e0551',
                    notForTagging: false,
                    label: 'vacaciones de verano (México)',
                    terms: [],
                },
            ],
        },
        {
            label: 'Humor Components',
            type: 'http://data.disney.com/resources/HumorConcepts',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/c59a721b-b769-48ac-9130-cdb622c91ddf',
                    notForTagging: false,
                    label: 'Joke Punchline',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6d1536d5-01fd-48f2-bf59-e67b776262ce',
                    notForTagging: false,
                    label: 'Joke Setup',
                    terms: [],
                },
            ],
        },
        {
            label: 'Humor Subjects',
            type: 'http://data.disney.com/resources/HumorSubjects',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/b60a1b44-d688-475d-adce-64573cfe54d8',
                    notForTagging: false,
                    label: 'aging (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fa37d83d-56f2-45bd-8466-e25caafb0ef2',
                    notForTagging: false,
                    label: 'business (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c8bdac03-05cf-4fbc-bb3d-a0c12d2c3ec9',
                    notForTagging: false,
                    label: 'celebrity (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4be138e6-9325-4163-bb2f-833a759f0e24',
                    notForTagging: false,
                    label: 'character',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/db5f118f-974d-452b-957f-d531761ea7ee',
                    notForTagging: false,
                    label: 'death and dying',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5c8baa82-f363-45a8-9063-50fc72384b1a',
                    notForTagging: false,
                    label: 'education (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/753bac39-1da3-416b-9852-b335dad77518',
                    notForTagging: false,
                    label: 'family (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fbb13a55-71f4-4445-ac4d-82c433fc3c34',
                    notForTagging: false,
                    label: 'fashion (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9d8d96e7-f354-42a7-a481-737a60a84ad9',
                    notForTagging: false,
                    label: 'food',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2c3ccd40-2e73-4989-aef0-81a59815e00a',
                    notForTagging: false,
                    label: 'gender',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8d9d78c8-a128-4b13-ad1d-ac61d85a4c65',
                    notForTagging: false,
                    label: 'geographic locations',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b5cf4278-4628-4209-9377-287170b0684b',
                    notForTagging: false,
                    label: 'health (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bf250305-4b12-4b9f-8161-304045eff575',
                    notForTagging: false,
                    label: 'inanimate objects',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a7370a56-9daa-daf0-3f51-de8f2522d8a3',
                    notForTagging: false,
                    label: 'legal (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3ab26930-e0f4-4ca0-8306-590cf8d3941b',
                    notForTagging: false,
                    label: 'marriage (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e34f5ad3-323a-406e-aaec-cd4d6139844c',
                    notForTagging: false,
                    label: 'media',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/07fb537b-9379-46c7-8be6-bbeee52d5dd9',
                    notForTagging: false,
                    label: 'medical (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f5750dd4-8d25-4868-8136-c75b706a598e',
                    notForTagging: false,
                    label: 'parenting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6446c134-4ea3-40bf-a4b4-31e343b2be0a',
                    notForTagging: false,
                    label: 'physical attractiveness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cfda86ed-8828-4718-9e9e-436292b14409',
                    notForTagging: false,
                    label: 'physical fitness (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8c14ebad-47a2-47fb-9822-db141d22660f',
                    notForTagging: false,
                    label: 'political (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/88cf956e-7327-4d56-8d19-722b50aed2b5',
                    notForTagging: false,
                    label: 'racial and ethnic identity (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c4a4714e-517d-444e-8c8f-c0e6233bed38',
                    notForTagging: false,
                    label: 'religion (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6d2bff2a-4387-47a0-bafb-7573b2008cd2',
                    notForTagging: false,
                    label: 'romance (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/152ea896-bf5f-44fc-b628-5abdd2352dad',
                    notForTagging: false,
                    label: 'sex (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/afeba0fa-5141-4784-97f2-e7cb43789561',
                    notForTagging: false,
                    label: 'social groups and status (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1efb7845-8494-457c-b2c9-778c9c689f10',
                    notForTagging: false,
                    label: 'social issues',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7ff05527-f830-4fe3-9112-5bfc3e4c7a1e',
                    notForTagging: false,
                    label: 'sports (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/edc6bd84-bb9c-4fd4-939e-47868f841bec',
                    notForTagging: false,
                    label: 'technology (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/83f0c91d-39db-4b4e-9d84-9fe87376a072',
                    notForTagging: false,
                    label: 'topical (humor subject)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dcdcf70f-e565-456a-a879-a9a0d5ea07a5',
                    notForTagging: false,
                    label: 'workplace (humor subject)',
                    terms: [],
                },
            ],
        },
        {
            label: 'Humor Types',
            type: 'http://data.disney.com/resources/HumorTypes',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/841bf9a6-f017-41bf-98e9-0495db8e428b',
                    notForTagging: false,
                    label: 'farce',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/95bfdd37-5f6e-49ae-b68d-00c9bd070117',
                    notForTagging: false,
                    label: 'hyperbole',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3f3fe52a-a64e-400d-a826-bcb8c444bdec',
                    notForTagging: false,
                    label: 'insult',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/23b571b8-4262-492f-8afc-7b6632c61b39',
                    notForTagging: false,
                    label: 'irony',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8d25b675-9238-4687-9376-3869be364c09',
                    notForTagging: false,
                    label: 'parody (humor type)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f75c6991-fa32-42f5-80bd-97b1ec169f2d',
                    notForTagging: false,
                    label: 'personality trait',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8cea65ff-1221-41b0-8e68-055b37207c08',
                    notForTagging: false,
                    label: 'repetition',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3026ef12-3ca6-4e53-a0ab-5b7a5eea9f77',
                    notForTagging: false,
                    label: 'self-deprecation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d6001c61-ff72-430b-913e-90d3ea5dd6f5',
                    notForTagging: false,
                    label: 'sight gag',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/33388afc-b6fd-f09e-a115-fadf36d1ed81',
                    notForTagging: false,
                    label: 'slapstick (humor type)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fb65cd56-4da8-4516-b280-460762205229',
                    notForTagging: false,
                    label: 'sophomoric',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1f9129e9-e19d-4d26-8c93-12af73213e22',
                    notForTagging: false,
                    label: 'subversion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b9178de4-efdd-4f92-8a5d-52427fda8208',
                    notForTagging: false,
                    label: 'wordplay',
                    terms: [],
                },
            ],
        },
        {
            label: 'Military Body',
            type: 'http://data.disney.com/resources/MilitaryBody',
            categories: [
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#6f2a4b9b-ce03-44f1-94b7-0fa2723038bf',
                    notForTagging: false,
                    label: 'U.S. Air Force',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#f51b382f-e98a-4378-bcea-aa360675fce2',
                    notForTagging: false,
                    label: 'U.S. Army',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#de11a385-401e-4ccb-9673-2d0caf7765f4',
                    notForTagging: false,
                    label: 'U.S. Coast Guard',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#1a8b26e7-29b7-4943-97fc-1c8eafc65a52',
                    notForTagging: false,
                    label: 'U.S. Marine Corps',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#87e5380f-c235-44f1-8648-f4b276b09fd1',
                    notForTagging: false,
                    label: 'U.S. Military - unspecified',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#ce5cfda3-628e-4f73-bcbf-ba9d3011cf16',
                    notForTagging: false,
                    label: 'U.S. National Guard',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#ca8aaaad-f204-4438-9200-6e87cab1827e',
                    notForTagging: false,
                    label: 'U.S. Navy',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#e183f085-61e9-4bc2-978c-fd749b7ecfa3',
                    notForTagging: false,
                    label: 'U.S. Space Force',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#5f0ca3c0-897c-42a7-b401-17143ea7979b',
                    notForTagging: false,
                    label: 'armed forces - fictional',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#50427aed-6d9b-42d0-b722-ec2f2f5bf4ee',
                    notForTagging: false,
                    label: 'armed forces - non-U.S.',
                    terms: [],
                },
            ],
        },
        {
            label: 'Military Status',
            type: 'http://data.disney.com/resources/MilitaryStatus',
            categories: [
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#9ed7311e-330f-4e6c-9a5b-a24d0aaa99a3',
                    notForTagging: false,
                    label: 'active duty',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#ebbe4dc7-e023-4dd5-ac90-a01ba973479e',
                    notForTagging: false,
                    label: 'veteran',
                    terms: [],
                },
            ],
        },
        {
            label: 'Moods',
            type: 'http://data.disney.com/resources/Moods',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/79bb129e-15f8-4988-a765-27b7d5cb98a8',
                    notForTagging: true,
                    label: 'amazement',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d2c2b10b-1a4f-443b-8332-00af0e753571',
                    notForTagging: false,
                    label: 'bmn mood',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5d587994-8ce2-418d-ad8e-a4228da41be7',
                    notForTagging: false,
                    label: 'calm',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/35ea26f4-f460-415e-9b61-603d4793ee7f',
                    notForTagging: false,
                    label: 'confessional',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b4c4feb0-ff7b-4e2a-8367-7c0aecc38223',
                    notForTagging: true,
                    label: 'confessional',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e4c322c2-a252-45ee-b226-eb7243985b22',
                    notForTagging: false,
                    label: 'confrontational',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/114fd38a-b148-4a3a-a1f8-4d63069017af',
                    notForTagging: false,
                    label: 'contemplative',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/23e93703-288e-41c9-b91e-42ad14e20394',
                    notForTagging: false,
                    label: 'dark',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9f07f7a3-2d21-4768-aaed-1f3964f3735b',
                    notForTagging: true,
                    label: 'discomfiting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3a28f0c-41e2-462c-bb28-036aa8509614',
                    notForTagging: false,
                    label: 'dramatic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c1b8729a-c252-4f2a-92b1-80422354b492',
                    notForTagging: false,
                    label: 'frightening',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b02bf7f6-58f7-4f87-add7-6731159ab941',
                    notForTagging: false,
                    label: 'funny',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f32b9c18-8fe7-4d88-be71-33c412118fcf',
                    notForTagging: false,
                    label: 'gentle',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/da08c04f-d669-4595-a4e6-5dd9c1b74978',
                    notForTagging: false,
                    label: 'heartwarming',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3a9a7bf8-fdea-4cd5-b953-00308698f95c',
                    notForTagging: false,
                    label: 'intimate',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c8444fdc-2a8b-4440-80f4-528b54d84030',
                    notForTagging: false,
                    label: 'matter-of-fact',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8931b2a6-4995-4218-b3c7-f865b4064be9',
                    notForTagging: false,
                    label: 'outrageous',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fa7483ba-dc81-4240-ae57-bd5014163cc6',
                    notForTagging: false,
                    label: 'romantic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/30abc0ff-b261-448e-8212-9e56bf8e7926',
                    notForTagging: false,
                    label: 'sad',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/417ecda5-45ed-41ee-85ee-8d4d77f1d5fb',
                    notForTagging: false,
                    label: 'sexy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/78741e5e-11c2-40f5-a838-7bba9b325afa',
                    notForTagging: false,
                    label: 'sophisticated',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e922fd9c-8e40-4e14-9d30-166dba5a3725',
                    notForTagging: false,
                    label: 'suspenseful',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4d9f7fc4-9562-48d5-afb5-3bdc27947b40',
                    notForTagging: false,
                    label: 'uneasy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f8eb0f4b-4285-47d6-97fa-a38fe09d9914',
                    notForTagging: true,
                    label: 'uplifting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/116875d5-54d2-4278-9046-cd4da745d599',
                    notForTagging: false,
                    label: 'uplifting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ceb62498-e10c-4526-aab5-a5808976b06f',
                    notForTagging: false,
                    label: 'whimsical',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/29ce0066-5d5f-4a36-a431-33f6ab5c46cc',
                    notForTagging: false,
                    label: 'wild',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e48dbce2-049f-41cb-bf08-0eac64e8b7f9',
                    notForTagging: false,
                    label: 'wondrous',
                    terms: [],
                },
            ],
        },
        {
            label: 'Motivations',
            type: 'http://data.disney.com/resources/Motivations',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/a3d485b9-1c13-439b-aeba-6718d9cf0194',
                    notForTagging: false,
                    label: 'achievement',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d7362c9a-44d7-4f97-9c85-af8be3c858aa',
                    notForTagging: false,
                    label: 'approval',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/189762f9-b9ee-4363-8033-ebd3080af2e4',
                    notForTagging: false,
                    label: 'belonging',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0d21e555-5b18-4791-8b49-69f08ea47e87',
                    notForTagging: false,
                    label: 'duty',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f25f28bc-34d0-4049-b61f-c9a82fd1fcd4',
                    notForTagging: false,
                    label: 'employment',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2620b11b-ef86-43fa-967c-2caff939eae9',
                    notForTagging: false,
                    label: 'ethics',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f478e7ce-2702-42bb-be3e-4d309632b945',
                    notForTagging: false,
                    label: 'fame',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/077761a5-b5cf-47df-839e-6fe13fc4ca00',
                    notForTagging: false,
                    label: 'family (motivation)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cee6f94a-ce5c-4a9a-aa05-969843c1105b',
                    notForTagging: false,
                    label: 'freedom',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0c81da2b-3359-4068-9dfd-50a333d3408f',
                    notForTagging: false,
                    label: 'friendship (motivation)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6edf7d8b-0223-4c32-a49e-8a270fdd525a',
                    notForTagging: false,
                    label: 'happiness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/57329a46-7e9c-4acb-bec1-694598819e6b',
                    notForTagging: false,
                    label: 'health (motivation)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cc0c4e8c-ff46-4e72-8f9a-0dbbdf17a4ce',
                    notForTagging: false,
                    label: 'home',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/010c9a4a-8d57-4108-9e57-2d23c5653596',
                    notForTagging: false,
                    label: 'justice',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/120e78e6-9361-45f3-bbe5-5fde87b22052',
                    notForTagging: false,
                    label: 'love',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/887710e7-b9d5-47f2-a131-a1da74b999e4',
                    notForTagging: false,
                    label: 'maturation (motivation)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f78136a4-85fe-479a-a949-f74b4acf06ab',
                    notForTagging: false,
                    label: 'peace',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/783544f5-a25c-49e9-a626-d59184155c17',
                    notForTagging: false,
                    label: 'philanthropy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/aafc2d54-a79d-4d68-9f8a-1c8ca068d5c9',
                    notForTagging: false,
                    label: 'pleasure',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f82cf92d-3b56-4dae-97da-4a210459739d',
                    notForTagging: false,
                    label: 'power',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/183cbe41-b668-4a2e-8d4b-0094c69195b3',
                    notForTagging: false,
                    label: 'redemption',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3e63ddef-1202-4079-8b41-be51d13e3c94',
                    notForTagging: false,
                    label: 'reputation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/58bad8e2-6054-412d-a623-5bdaefd4d3ac',
                    notForTagging: false,
                    label: 'safety',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/225767be-71a5-44c2-8511-e86dbdc5972a',
                    notForTagging: false,
                    label: 'self-expression',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2129feba-c656-4698-9dc7-578bde4e8db5',
                    notForTagging: false,
                    label: 'wealth',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5009a610-d156-4e87-836e-9fe6b35dbe62',
                    notForTagging: false,
                    label: 'wisdom',
                    terms: [],
                },
            ],
        },
        {
            label: 'Narrative Devices',
            type: 'http://data.disney.com/resources/NarrativeDevices',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/d2650c35-08fe-4096-b2c8-a6e2d006ee09',
                    notForTagging: true,
                    label: 'button',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4de82ffc-b156-4c42-a62a-92551142f319',
                    notForTagging: true,
                    label: 'confession shot',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b745e845-c857-40e9-9d53-211583db5dac',
                    notForTagging: true,
                    label: 'dream sequence',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3614a7bd-e372-478d-ada9-159cb3419cfd',
                    notForTagging: true,
                    label: 'flash forward',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/290e1063-e234-473e-99e4-46d46d16e227',
                    notForTagging: true,
                    label: 'flashback',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2039b32c-a2dd-4cc7-a097-91749b3a867d',
                    notForTagging: false,
                    label: 'humor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/af64544c-d008-44c0-8cef-13990eb8eb90',
                    notForTagging: true,
                    label: 'tag',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/60a6a9c6-5c73-45fe-9624-12e05d1a9b5f',
                    notForTagging: true,
                    label: 'teaser',
                    terms: [],
                },
            ],
        },
        {
            label: 'Non Preferred Terms',
            type: 'http://data.disney.com/resources/NonPreferredTerms',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/3bc5aa99-6711-4e82-b02d-7c34bd4e3dd1',
                    notForTagging: false,
                    label: '2-way radio',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/78ea1f15-71a1-4490-aa36-a1f2283cd7a3',
                    notForTagging: false,
                    label: '4th of July',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/417602e0-13a1-49fb-9f9c-e7e7159af18d',
                    notForTagging: false,
                    label: 'AI',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/498d8e0f-c11e-440d-a1dc-0cdea6f5e784',
                    notForTagging: false,
                    label: 'ATV',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b2b660e2-8bc5-4b67-b1ce-06e682463366',
                    notForTagging: false,
                    label: 'American Indians',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7316f87a-2f32-458e-8367-8018a3b6107d',
                    notForTagging: false,
                    label: 'B&B',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7fd820e5-0413-4b55-b034-0c6ae6b0987f',
                    notForTagging: false,
                    label: 'Beanie Babies',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/54ba050c-aad8-41f2-af5c-49bd1dfb64a7',
                    notForTagging: false,
                    label: 'Christmas lights',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2ed556fc-b4c2-485d-8e46-88f50ae217cd',
                    notForTagging: false,
                    label: 'City',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/af2ff214-9bd8-4937-98b5-35d0b18a3149',
                    notForTagging: false,
                    label: 'DJ',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/936bdf3d-4243-4c04-9fef-8d3a4ea86928',
                    notForTagging: false,
                    label: 'DUI',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3cba46e-5ce9-4ca7-a9ab-aae3d20dcd35',
                    notForTagging: false,
                    label: 'DWI',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/432873c0-9875-4a89-9471-f1a35ef73d47',
                    notForTagging: false,
                    label: 'ER',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/66cda3e0-702f-4862-ac1a-723a3fbcb7c7',
                    notForTagging: false,
                    label: 'English rules football',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/df13954d-f43c-42a5-8d10-1d99901981df',
                    notForTagging: false,
                    label: 'Fourth of July',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/77dc015b-b1cc-4896-b45f-0b18fabdf98e',
                    notForTagging: false,
                    label: 'ICU',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/05b88bf5-4831-4947-97f9-0a60abda1192',
                    notForTagging: false,
                    label: 'IPO',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0702f560-726d-4764-ac2c-21107e852459',
                    notForTagging: false,
                    label: 'IPOs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/25aaf69a-ebe1-45a2-9e38-b075389cf234',
                    notForTagging: false,
                    label: 'MC',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/30ff33e2-da87-473e-b0d0-b2269b9e9888',
                    notForTagging: false,
                    label: 'MP',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7074d78f-af83-4062-ba0e-f0fa961e75b4',
                    notForTagging: false,
                    label: 'Native Americans',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/01c7fd07-a054-4ec8-897a-75ca9d8c9e12',
                    notForTagging: false,
                    label: 'OR',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6a6ca102-dd43-40ae-afc8-39fd20f93ecb',
                    notForTagging: false,
                    label: 'Obamacare',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e4e99700-d7f5-46d8-a43f-f737159ed518',
                    notForTagging: false,
                    label: 'PI',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9f077729-8851-45bd-856e-05231eed869f',
                    notForTagging: false,
                    label: 'PICU',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/56de3bab-f82b-4a15-861c-deab1a9594da',
                    notForTagging: false,
                    label: 'PTA meeting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a3a09a4a-1aff-4c6e-a7cb-27cc2af889d2',
                    notForTagging: false,
                    label: 'RV',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/94f7e0f3-b5c7-4528-995f-ca91697bf729',
                    notForTagging: false,
                    label: 'SUV',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bb47b48d-e9f4-481a-a783-7a7af243b359',
                    notForTagging: false,
                    label: "St. Valentine's Day",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d84180b3-c474-421b-b0ff-24d56defc673',
                    notForTagging: false,
                    label: 'TV',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/df0a5be7-b30f-4764-a8b6-3fc4ffb23863',
                    notForTagging: false,
                    label: 'TV host',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/715a1d23-305c-43ca-bd65-b248153ddd3b',
                    notForTagging: false,
                    label: 'Thanksgiving Day',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/901a5dfa-6d57-4bf3-86f8-dc6a8d1e6cba',
                    notForTagging: false,
                    label: 'The Hajj',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/84b5f36e-66de-4191-9f59-8cd4ac451b5b',
                    notForTagging: false,
                    label: 'Tupperware',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/82a1fa49-d62c-4586-9ef6-59026af1ef47',
                    notForTagging: false,
                    label: 'UFO',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/279f9fd5-14a3-4d81-a09c-ae226373a420',
                    notForTagging: false,
                    label: 'USB drives',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/adbcd18a-9714-401e-b560-f0a8664aa604',
                    notForTagging: false,
                    label: "Veteran's Day",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9c9a6878-9506-45b0-80fd-ed3e7146f72f',
                    notForTagging: false,
                    label: 'Wall Street',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/85e85635-f439-43f3-8b50-aa4c5e04d044',
                    notForTagging: false,
                    label: 'abduction',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a78eab3e-dff1-40a5-9668-33232cb02449',
                    notForTagging: false,
                    label: 'abstemious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/da372c28-a708-4f77-91e3-ef59be727b1b',
                    notForTagging: false,
                    label: 'absurdity',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8e5504e8-135f-4c29-95ba-056614c17ab2',
                    notForTagging: false,
                    label: 'abusive relationship',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8fca4ece-7f9f-4e30-8546-0704b2419cb0',
                    notForTagging: false,
                    label: 'acceptance',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/766132d3-c3d4-40a5-8b2f-d73836e51dc0',
                    notForTagging: false,
                    label: 'account executive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fde17ea6-c07f-4a8e-9929-9697ca6b16c0',
                    notForTagging: false,
                    label: 'acid spill',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ca0d0cf0-b0ce-4634-b279-6d7a652f3bc7',
                    notForTagging: false,
                    label: 'actress',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3777c540-e187-43ef-8e1a-8b8f0b73ca35',
                    notForTagging: false,
                    label: 'administrative assistant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4acd9842-f74d-4eee-8468-28f9c30e9453',
                    notForTagging: false,
                    label: 'adolescent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2444d4c8-497c-480d-91a8-a48a2b9b2641',
                    notForTagging: false,
                    label: 'adolescent rebellion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e646c60b-ed11-4c8f-a88d-f6c60e96b268',
                    notForTagging: false,
                    label: 'adultery',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/73716b68-aac8-460a-ab77-db79df76e06c',
                    notForTagging: false,
                    label: 'adventurous',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4081870f-0e6a-4113-a5d3-b781bb11dbf5',
                    notForTagging: false,
                    label: 'advisor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e343f45d-24f9-4cd8-9325-4e6c7a1db95d',
                    notForTagging: false,
                    label: 'aerial photography',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b9bd91bd-2a8e-45a8-af9a-9550313e0a71',
                    notForTagging: false,
                    label: 'aesthetician',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b40fd207-5577-4ecb-b778-57d312b66f40',
                    notForTagging: false,
                    label: 'affair of the heart',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/64a93f76-aaf8-4501-a811-c12f39fb8dde',
                    notForTagging: false,
                    label: 'afraid',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8faa7026-738c-4993-9ee3-3db05eaf99c6',
                    notForTagging: false,
                    label: 'aggressive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ea04a29b-cd38-4cad-9da2-889b45c41002',
                    notForTagging: false,
                    label: 'airlines',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/23cb28bc-6ced-4423-b181-61f8c789d5a9',
                    notForTagging: false,
                    label: 'airship',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fcc8ae61-3684-464f-80cb-d2a89e80a355',
                    notForTagging: false,
                    label: 'aliens',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/af01412e-9e54-42bc-95bf-30a0f66985d0',
                    notForTagging: false,
                    label: 'aloof',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/22fdb506-f0dd-4751-a30a-28b96410d253',
                    notForTagging: false,
                    label: 'alphabet blocks',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8ee09e34-a3c9-4ef9-a3dd-103d6eef500a',
                    notForTagging: false,
                    label: 'altruism',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/30d9d954-6fd2-409a-955d-9b5757453b89',
                    notForTagging: false,
                    label: 'ambition',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/55c89271-c00d-45c8-a625-7ae5b9aacfef',
                    notForTagging: false,
                    label: 'ammo',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d1e540ba-1304-4790-8232-6e72027d3da2',
                    notForTagging: false,
                    label: 'amphitheatre',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/680491d0-7548-4650-9357-aa6a10909cfb',
                    notForTagging: false,
                    label: 'amplifiers',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/08908b30-41a3-49b3-ab42-da3048936fda',
                    notForTagging: false,
                    label: 'amusing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6c854b3d-bcef-4190-9116-ca3535618ebb',
                    notForTagging: false,
                    label: 'analytical',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e2f37792-ca61-457d-86fe-613e210c11ce',
                    notForTagging: false,
                    label: 'anchorman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d288062e-4c1a-44d8-a452-644f6cd0a8e7',
                    notForTagging: false,
                    label: 'animal rescue',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2d617866-23e1-452b-bdb2-d8078b36d7f3',
                    notForTagging: false,
                    label: 'animation cell',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2ab34321-3413-475d-95e6-c7b8eb646679',
                    notForTagging: false,
                    label: 'announcer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/17b08f97-a8af-4ec0-8aa5-7337c8eb48f9',
                    notForTagging: false,
                    label: 'antagonist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/22045652-94e8-47d9-89bf-5f1861a75d4c',
                    notForTagging: false,
                    label: 'antagonistic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b94cfd87-3f59-4bfe-b571-144b371d0423',
                    notForTagging: false,
                    label: 'anti-war',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1ea3faae-a25d-47d3-a664-872cca326509',
                    notForTagging: false,
                    label: 'anxiety attack',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3d19e41e-d3b1-4e43-83b5-d8e6988a2135',
                    notForTagging: false,
                    label: 'anxious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/35a19b19-51e2-4262-8eff-0d8b147aa021',
                    notForTagging: false,
                    label: 'apartment house',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e09f2931-f70b-4855-8701-51e7065d0c9a',
                    notForTagging: false,
                    label: 'apologize',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/879caa83-2623-46c9-b507-44783da27c2d',
                    notForTagging: false,
                    label: 'applause',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/25f2d87f-af44-4559-b85a-41026cb1f408',
                    notForTagging: false,
                    label: 'apply makeup',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7f16ae48-4009-4702-9c01-fbcf68dbc03c',
                    notForTagging: false,
                    label: 'arboretum',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ef24397d-de12-4e23-9d1e-5fa0736285e7',
                    notForTagging: false,
                    label: 'architectural photograph',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b11b1264-3614-4b15-9d8d-4aec70092edf',
                    notForTagging: false,
                    label: 'argumentative',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/09dc5849-4c4d-48b9-9b92-19a834d6be2d',
                    notForTagging: false,
                    label: 'arsenal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7fbdde4c-c032-45d3-9f2b-d2478cd3e171',
                    notForTagging: false,
                    label: 'artificial intelligence',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5ceafce2-2336-4945-b9e3-476b54e4b039',
                    notForTagging: false,
                    label: 'artistic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/38adfa35-9c2c-4a21-b2cd-3779f0d29a75',
                    notForTagging: false,
                    label: 'assaulting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bed975ff-8cd7-46ae-9f73-90d0f7b8179f',
                    notForTagging: false,
                    label: 'assertive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5dce734f-6b02-4b1f-9068-224569df641c',
                    notForTagging: false,
                    label: 'association football',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ad0c69dc-03cc-455b-9f02-b1a0edf56989',
                    notForTagging: false,
                    label: 'asteroid',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3dd90a1-ab2a-481f-abbb-5e8c10f2c6db',
                    notForTagging: false,
                    label: 'attacking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2cb630f0-8700-4167-beea-353011527791',
                    notForTagging: false,
                    label: 'attempted pregnancy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a8fb3f63-5647-4dec-996c-c5d3836392a2',
                    notForTagging: false,
                    label: 'audience reveal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a8f736b5-dbfd-439d-bdd4-00b0d85281e3',
                    notForTagging: false,
                    label: 'audio gag',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/20d34d19-a7f9-4ed0-be01-61fd655129e4',
                    notForTagging: false,
                    label: 'author',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/20d511c3-c448-4459-9d2e-10f6592777c6',
                    notForTagging: false,
                    label: 'automaton',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fe41225f-b9dd-4279-8b71-e289681c7ba6',
                    notForTagging: false,
                    label: 'automobile show',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/305ba352-803a-49c7-99d8-a066cc5f85a0',
                    notForTagging: false,
                    label: 'automobile theft',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/976cbced-0008-4227-b375-09a8a4bd2bd0',
                    notForTagging: false,
                    label: 'autonomy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b50bf8bc-db0f-4419-97ed-ff544a9a5738',
                    notForTagging: false,
                    label: 'awards ceremony',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d4cea0e8-9b3c-4c40-a38d-4e831c09308e',
                    notForTagging: false,
                    label: 'babies',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b5ae8aa6-356f-474c-b217-f4ae9170e34b',
                    notForTagging: false,
                    label: 'baby',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4a7980c1-321a-4a28-beed-78fa8e524e7f',
                    notForTagging: false,
                    label: 'baby shower',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b1f3fb2c-92c6-41a6-90c6-06e0fecc9422',
                    notForTagging: false,
                    label: 'bad guy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/064b15dd-3f8a-4adb-86c6-b5bd6793b462',
                    notForTagging: false,
                    label: 'baggage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8cfdb929-b3fc-4cd7-9cf8-5fbe3961a180',
                    notForTagging: false,
                    label: 'band',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fcee7817-b900-4843-87e9-4ee956766739',
                    notForTagging: false,
                    label: 'barfing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cf512b1f-e816-4588-a4df-9b703ef06b15',
                    notForTagging: false,
                    label: 'baron',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d118c80f-fce7-475c-82b4-760c59844a88',
                    notForTagging: false,
                    label: 'bazooka',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/eba01b61-6e7c-4b65-ab95-d24dce671890',
                    notForTagging: false,
                    label: 'bear cubs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/366f8284-371f-4357-aa31-535b150289ee',
                    notForTagging: false,
                    label: 'beating',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/09452171-ef5a-47c8-b628-a092a1d2a9f2',
                    notForTagging: false,
                    label: 'beauty pageant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9c44d799-e6c9-4eba-9d1c-cd8c0a424464',
                    notForTagging: false,
                    label: 'beauty partlor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f3495a8b-b138-4cd4-9ced-c08b5adc0637',
                    notForTagging: false,
                    label: 'bed linens',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7902c791-179a-4fca-b556-79c8415a8f84',
                    notForTagging: false,
                    label: 'bedbugs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7589dc38-915e-4fab-989c-85947abc06c3',
                    notForTagging: false,
                    label: 'beetles',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ac730347-1d97-488a-9bc8-8731f6050e83',
                    notForTagging: false,
                    label: 'being mad',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/876d9fe4-0a71-44b5-84e6-5659f40885a9',
                    notForTagging: false,
                    label: 'being revived',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5b039fa6-dc82-4185-be50-4681513c68d8',
                    notForTagging: false,
                    label: 'bellboy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/96153143-85f9-47d2-a60c-55dec9bef181',
                    notForTagging: false,
                    label: 'bellhop',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/47e9f0e3-3477-43e8-a038-d6b25e6c9b82',
                    notForTagging: false,
                    label: 'benefits',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ab55f452-9000-4bee-a691-c2f50dbd6ed8',
                    notForTagging: false,
                    label: 'bequest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6d86888a-4b27-404a-8a8a-bcdc10e47ff2',
                    notForTagging: false,
                    label: 'besmirch',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7b9d66dd-2229-481d-9c5e-9b0dcdc8408c',
                    notForTagging: false,
                    label: 'bicycle crash',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f53b7e96-58e0-433c-9cec-c214e9289289',
                    notForTagging: false,
                    label: 'bicycle rider',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/551a9bd5-4a7e-486b-8809-742b4005161c',
                    notForTagging: false,
                    label: 'bicycling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ee456e56-350f-4858-b08f-1ea7d6da8443',
                    notForTagging: false,
                    label: 'bike',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4ee757f0-09f5-4bdb-971b-8b97980a2880',
                    notForTagging: false,
                    label: 'bike riding',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f60166a2-809f-46ed-8717-9ee3ed7a3ebd',
                    notForTagging: false,
                    label: 'biker gang',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3b9693ad-3fb1-41c3-a322-fcdf028f492c',
                    notForTagging: false,
                    label: 'biking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bb09e0ad-f845-4423-bcbd-717b3d39810a',
                    notForTagging: false,
                    label: 'bills',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0a391852-a833-4410-9514-6088b31823c2',
                    notForTagging: false,
                    label: 'birth parents',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5409f0bb-a4b6-4887-b8a0-a202b9801f82',
                    notForTagging: false,
                    label: 'bison',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/00324395-bc6e-4d2f-a9f3-f48b083dced4',
                    notForTagging: false,
                    label: 'black comedy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bfef0f2f-4c97-4e15-b545-5ed536d52a76',
                    notForTagging: false,
                    label: 'blackmail',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/aeb859d6-6cf7-411b-afd0-f1c51a671b0a',
                    notForTagging: false,
                    label: 'blackout',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/080c9dcc-598a-4fc8-bf2c-5803a57f4514',
                    notForTagging: false,
                    label: 'blow dryer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ec335c08-ca04-44be-9290-f857f756364e',
                    notForTagging: false,
                    label: 'blue collar worker',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f7253c68-4cd0-4b98-9de8-1d198fcccf3c',
                    notForTagging: false,
                    label: 'boat accident',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/76b6da69-376d-43d5-b888-902f2938b9eb',
                    notForTagging: false,
                    label: 'bomb squad',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/43d54f83-cd65-4a90-b672-2676c4a32e91',
                    notForTagging: false,
                    label: 'book of spells',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c7a280b7-b105-4364-be23-c3a1533cab23',
                    notForTagging: false,
                    label: 'bookcase',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/78bca4ec-149f-40a2-9d21-9cbd9eb1fb98',
                    notForTagging: false,
                    label: 'boss',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/176f78d5-a0de-4b28-8248-43ee7644f2c6',
                    notForTagging: false,
                    label: 'bosses',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/38645046-348c-4e60-8bf3-1218606518bd',
                    notForTagging: false,
                    label: 'bowing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7ea5e96d-2dc4-4c1d-81b3-5928fbcdaadc',
                    notForTagging: false,
                    label: 'boyfriends',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d63a7663-0e4f-4cb9-a1be-78a9e6747d6a',
                    notForTagging: false,
                    label: 'brand',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0ee064b5-8269-4af6-a38a-9bd1858d52eb',
                    notForTagging: false,
                    label: 'branding iron',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/660d3475-6f88-4535-be4f-93c7ba3af0a5',
                    notForTagging: false,
                    label: 'brave',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/20f180f3-1537-4354-afa9-df17e4a51378',
                    notForTagging: false,
                    label: 'break room',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3e5565bb-31ad-4556-9927-f663d556212d',
                    notForTagging: false,
                    label: 'break-in',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3b4a1c5a-21ba-44ae-844f-3b6943a7ec94',
                    notForTagging: false,
                    label: 'bricklayer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/088ef6a5-fe5e-462c-a0bf-573a17f6f028',
                    notForTagging: false,
                    label: 'bridal shower',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/15a45535-06c0-4a21-a0f1-75810c8c75c0',
                    notForTagging: false,
                    label: 'bridegrooms',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1b93d75e-5b4b-4f34-ad93-3f7e1c190155',
                    notForTagging: false,
                    label: 'brig',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6dd06f6e-a99a-4a7e-bd3f-69f128b9f895',
                    notForTagging: false,
                    label: 'broadcast booth',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/20e4fb49-cc5e-43cc-ba8e-31f47ea1fc25',
                    notForTagging: false,
                    label: 'buffalo',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f95d6c84-00b9-4b02-9425-099c16cef30e',
                    notForTagging: false,
                    label: 'bugs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f50437a4-e3cd-486e-944b-f570348eb73c',
                    notForTagging: false,
                    label: 'building blocks',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/44077644-0a79-4f11-9ef6-4a68c0912643',
                    notForTagging: false,
                    label: 'building collapse',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1de74f7c-fc9c-4dcc-829a-c7c2c50a08fe',
                    notForTagging: false,
                    label: 'bullpen (work area)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e1fa703a-b322-47d3-a847-7f3a4a8dc43b',
                    notForTagging: false,
                    label: 'bunks',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ec1bc4f6-ecf0-46b1-ab13-1cd5a1a3adfc',
                    notForTagging: false,
                    label: 'burglary',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5cdb45c1-88f8-4dfd-9bd5-13f7bf2905fc',
                    notForTagging: false,
                    label: 'buried treasure',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/047f9591-0a39-4bfc-b93e-e3a25fd148b6',
                    notForTagging: false,
                    label: 'bus accident',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/024c2fbd-84ed-4dd9-b44f-2eb2ead60f83',
                    notForTagging: false,
                    label: 'bus crash',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f78a82b1-fe93-416c-baef-f173c33bcdc4',
                    notForTagging: false,
                    label: 'bus girl',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/93279d23-8337-4563-9cbc-2547f4dc321f',
                    notForTagging: false,
                    label: 'bus person',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/97f20ba7-b5d4-4beb-a289-034c2faebeed',
                    notForTagging: false,
                    label: 'bus shelter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7c7e98e0-04cc-42dc-af40-9c2aa3640fc9',
                    notForTagging: false,
                    label: 'bus terminal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/95c3666b-8754-4522-950c-571e914d3d37',
                    notForTagging: false,
                    label: 'business owner',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/71ec2951-a66c-4337-a498-43859d212ce6',
                    notForTagging: false,
                    label: 'business woman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/012fa682-829d-4731-8a83-ee7308b8f8f9',
                    notForTagging: false,
                    label: 'businessman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2940cd52-8db9-4298-be35-a117438ab404',
                    notForTagging: false,
                    label: 'buzzed driving',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/183e96fc-d125-4f8c-8e07-47f43be9ebc5',
                    notForTagging: false,
                    label: 'cab driver',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0c9e37c6-223c-4bd3-a0fc-f595f93b9d16',
                    notForTagging: false,
                    label: 'cabana',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/88a38328-5ffb-480c-9ea2-ec8f32581913',
                    notForTagging: false,
                    label: 'calculating',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ddcc37b5-2f4c-41d4-b140-7092b8bb3131',
                    notForTagging: false,
                    label: 'calm',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2f3ba6a3-54bf-423e-a8f8-d1762c5c29c4',
                    notForTagging: false,
                    label: 'camcorder',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ddb99e14-ff1a-467c-88e6-b0801c47900c',
                    notForTagging: false,
                    label: 'campaign rally',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9f1b25e4-6fbd-402c-b09f-31b3238c1024',
                    notForTagging: false,
                    label: 'car',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/918a2c1f-4136-4999-9c54-95805936df6b',
                    notForTagging: false,
                    label: 'car accident',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8c4502d0-0ccf-42a1-8e10-7e3e0c52a062',
                    notForTagging: false,
                    label: 'car chase',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e8a55c4b-ebdf-4ec2-889a-b5635037f400',
                    notForTagging: false,
                    label: 'car crash',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f8f502a3-040e-4135-8dd5-ce8a2e7ca7e7',
                    notForTagging: false,
                    label: 'car fire',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cc8cb88a-fd60-4ac7-8882-f03d4d21b296',
                    notForTagging: false,
                    label: 'car show',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b491c287-8030-4a4d-95ba-980842de9f25',
                    notForTagging: false,
                    label: 'carbonated beverages',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4545eba4-3280-4d4e-965d-6869b48e37e8',
                    notForTagging: false,
                    label: 'cardiac care unit',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d56316e7-c99b-4e55-a472-ac4fde6d2b51',
                    notForTagging: false,
                    label: 'cardiologist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/60aa49ab-0eef-4319-8494-b96d20d7dd2a',
                    notForTagging: false,
                    label: 'career expo',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d76a402a-6852-4d76-956b-5be9b603ffd2',
                    notForTagging: false,
                    label: 'career fair',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/64e28e59-89d8-4b1c-a683-ddeac5cbcebf',
                    notForTagging: false,
                    label: 'caregiver',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/af92106c-b9d3-421b-a6a0-30a0bf1ef464',
                    notForTagging: false,
                    label: 'caressing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/997dc697-0a75-415b-87c8-ab862f881feb',
                    notForTagging: false,
                    label: 'caroling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/381b7280-ea19-4df3-a8c7-c65c573aded4',
                    notForTagging: false,
                    label: 'cast out',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/03f514a2-cd55-4ac1-a622-60677e00d54d',
                    notForTagging: false,
                    label: 'cattle',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8909a084-39f3-48d1-a742-70640d6b422f',
                    notForTagging: false,
                    label: 'caveman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e0345d76-4e08-4db2-944e-569ec3094d2d',
                    notForTagging: false,
                    label: 'cel art',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dfa7cee6-b6bb-47fa-915c-f49e5560ae94',
                    notForTagging: false,
                    label: 'cell phone',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/71bd4c5e-7069-48e5-9d28-d82b174def17',
                    notForTagging: false,
                    label: 'cellphone',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/928f3a3f-0b0a-409f-add1-2a11bd18672d',
                    notForTagging: false,
                    label: 'cenote',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e1896826-7d61-4a64-bae1-e916640dafe9',
                    notForTagging: false,
                    label: 'chalkboard',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1bb37abf-4c7f-4d19-a0d9-3070e5431514',
                    notForTagging: false,
                    label: 'champion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/523b9762-35d4-4d29-b775-7de2aa4e4e43',
                    notForTagging: false,
                    label: 'character reveal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/54bc1454-7391-4fc5-99e2-86726b06dc12',
                    notForTagging: false,
                    label: 'charismatic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dca1b5ae-cdd5-4ac6-a069-f1ad1306239b',
                    notForTagging: false,
                    label: 'charitable',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0be00b8c-c1b4-4ff0-99dc-470acc124e36',
                    notForTagging: false,
                    label: 'charitable organization',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ff1e80f1-5e14-4b9d-bb72-553244451ecf',
                    notForTagging: false,
                    label: 'charities',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5f75f7d0-82e3-4c05-bba4-b2b1141a5ec1',
                    notForTagging: false,
                    label: 'charity',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d529cb97-5452-442f-9cf2-cdbe993051be',
                    notForTagging: false,
                    label: 'charming',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/52d31d52-7e7c-4260-b805-57e1fb1b1627',
                    notForTagging: false,
                    label: 'chaste',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a9d6bf1d-e69b-482d-9e35-4016f4fcad0b',
                    notForTagging: false,
                    label: 'chastise',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/323ed9f8-2cc3-441e-baaa-3b1a26a02981',
                    notForTagging: false,
                    label: 'cheating',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2c0493f8-4bab-443a-85bb-19fe6e199d40',
                    notForTagging: false,
                    label: 'check-up',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/632bec8f-def0-491c-8dfb-eee07fec3e87',
                    notForTagging: false,
                    label: 'cheeky',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5a08f3f6-95c2-4024-8393-8c2762047565',
                    notForTagging: false,
                    label: 'cheerleaders',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/63d99173-4a58-41bc-a4e5-7c234f336955',
                    notForTagging: false,
                    label: 'cheetahs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3aad7921-310e-46af-aee6-3badccf7b8c2',
                    notForTagging: false,
                    label: 'chef',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dee90c49-dce1-4b09-8181-11e3463491da',
                    notForTagging: false,
                    label: 'chemical leak',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/04bb9ea1-89bb-4447-a7e9-38d490ac3ce6',
                    notForTagging: false,
                    label: 'chief',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/be187e8d-ce04-44d4-a75a-620888e3b84a',
                    notForTagging: false,
                    label: 'child',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/22a904b6-9f36-4ed2-9af6-b2bd39797c38',
                    notForTagging: false,
                    label: "children's",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1836f127-cb96-4074-ba9d-06b1d6157f4f',
                    notForTagging: false,
                    label: 'chips',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/79edbf0e-0aab-4295-ae9a-d13d6712cdee',
                    notForTagging: false,
                    label: 'choir',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e4de17af-3401-4deb-8642-af96d1ac72c0',
                    notForTagging: false,
                    label: 'cigar',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/65aff30b-15a8-4c0d-b1dc-fe40a96cff4f',
                    notForTagging: false,
                    label: 'cigarette',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4b06aefe-372f-487c-ad71-3a45702a5fab',
                    notForTagging: false,
                    label: 'civil servant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0c0bfb3e-c64d-4215-bd3d-506eeb51ea6d',
                    notForTagging: false,
                    label: 'clairvoyant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1cd946e1-78a9-4ad2-bd39-05a3efea385c',
                    notForTagging: false,
                    label: 'class conflict',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c70b2ae6-c7fc-4268-b02c-20428f708575',
                    notForTagging: false,
                    label: 'class warfare',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e488cc55-a189-44a0-b43c-eca70fa6ace6',
                    notForTagging: false,
                    label: 'clerical staff',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6b202ead-2d08-4cfe-8fa2-dabebcd03231',
                    notForTagging: false,
                    label: 'clever',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b4f7b6f5-eced-4c79-ae2c-84b2a85b24de',
                    notForTagging: false,
                    label: 'clownfish',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5cb17fc1-c4b6-49fb-88bc-e1037188382f',
                    notForTagging: false,
                    label: 'clumsy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5f0b8010-5ade-4dd8-b5a9-101e3c3ee794',
                    notForTagging: false,
                    label: 'coat hook',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4c9b7550-99b9-48ae-a38d-ed9b62ff5cb6',
                    notForTagging: false,
                    label: 'coatroom',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8dcacf05-f346-4fd6-9841-f82c5fe4fdb8',
                    notForTagging: false,
                    label: 'coda',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b040e8d1-bf51-487d-9be5-118d252a200e',
                    notForTagging: false,
                    label: 'coins',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f79b9147-aa71-481c-86b3-0337dba5f252',
                    notForTagging: false,
                    label: 'cold',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3d8b7067-5d41-46ce-a7b9-8c53ea4f6e5d',
                    notForTagging: false,
                    label: 'coliseum',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dd766308-6853-44f4-ada0-fdb8db64bce0',
                    notForTagging: false,
                    label: 'collegial',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b6edc290-9546-4773-b0f7-c42ec7e67ac8',
                    notForTagging: false,
                    label: 'colosseum',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b3b72054-9513-44cd-9ef0-f155618df356',
                    notForTagging: false,
                    label: 'coma',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/46d3760a-2533-471e-9696-f712b003c3d9',
                    notForTagging: false,
                    label: 'combat',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fff7c702-f1bf-42ae-b9a2-49ff6c8178e8',
                    notForTagging: false,
                    label: 'coming out of coma',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e2c92b7d-6688-4208-87ea-8c6cee78ca32',
                    notForTagging: false,
                    label: 'commencement',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e013547d-0662-4277-94ac-916908d50d12',
                    notForTagging: false,
                    label: 'community service',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7ab4dd52-484a-4528-b714-cca00cc1f86e',
                    notForTagging: false,
                    label: 'compassionate',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/655b2f6e-d91d-4921-ad27-e0de9d97b0ab',
                    notForTagging: false,
                    label: 'competing organizations',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4023134b-a5d1-4e4d-8e74-0c7ac47ed082',
                    notForTagging: false,
                    label: 'competition',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c1a46836-6394-42d5-a584-73a120fd205c',
                    notForTagging: false,
                    label: 'computer programmer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/70b0a3a5-09bb-423c-a56d-0dcc5719bc24',
                    notForTagging: false,
                    label: 'computer viruses',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2fdbfc63-59eb-4b60-9049-a2e3b6ca85e3',
                    notForTagging: false,
                    label: 'comraderie',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d1558185-4625-456c-ab03-c6aec5c042d4',
                    notForTagging: false,
                    label: 'conceited',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3101e81f-42d0-4d58-ac4b-ea449526b210',
                    notForTagging: false,
                    label: 'concrete blocks',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2770142b-6e84-47e5-be0e-44ab112f4b4a',
                    notForTagging: false,
                    label: 'condo',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5ed13e1f-af9a-4317-b021-1f9daaedca83',
                    notForTagging: false,
                    label: 'congressman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/40152e4d-8944-4937-87db-ffae74b20152',
                    notForTagging: false,
                    label: 'congresswoman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9512e1bc-e3fb-4ac5-b62b-60dd2bc9b02a',
                    notForTagging: false,
                    label: 'conjuring',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cf58e58b-d63c-49a0-bb6c-01c3cf39de1e',
                    notForTagging: false,
                    label: 'contentment',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/82241b2e-411c-4e30-a0c1-7870d7a636b3',
                    notForTagging: false,
                    label: 'contract killing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a32d84a0-2049-4e25-8ff0-8e2b2e809c98',
                    notForTagging: false,
                    label: 'conventions',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e3ca313d-3bc1-4f31-a2ba-8fd1204d1876',
                    notForTagging: false,
                    label: 'conveyor belt',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9b9d2f6e-6fa9-4d9a-9e99-647851077446',
                    notForTagging: false,
                    label: 'convict',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f68f8c82-0473-4f16-a0ac-8c459eed6681',
                    notForTagging: false,
                    label: 'convincing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/057a8d35-9ded-45a0-834b-61fa2965ef91',
                    notForTagging: false,
                    label: 'cookout',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4788333e-bc12-4aea-a50b-1f252cba8349',
                    notForTagging: false,
                    label: 'cool',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/28c84127-9349-4013-84b1-9ca798bee6f8',
                    notForTagging: false,
                    label: 'cops',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fa42f36a-405a-473e-b2c3-1a4d391b9154',
                    notForTagging: false,
                    label: 'copy room',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6f9fa8aa-f29e-4666-a0a4-4cf507f7b77c',
                    notForTagging: false,
                    label: 'corpse',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9f15e76b-c414-4a50-a826-92df46cc3285',
                    notForTagging: false,
                    label: 'corrections officer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8a9107f2-4bfe-49e3-b8fd-a4741b9c5535',
                    notForTagging: false,
                    label: 'correspondent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/883ae009-482c-4214-88f8-23d3753e5b9f',
                    notForTagging: false,
                    label: 'corridor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e42c62d5-ddd7-44c3-9e82-52c11f396f1c',
                    notForTagging: false,
                    label: 'corrupt',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/deb099a1-c246-4322-ad9f-cb2201adae46',
                    notForTagging: false,
                    label: 'corruption',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b0136675-4a4b-4b68-bcb6-cdebbf68d332',
                    notForTagging: false,
                    label: 'cosmetic surgery',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d9dab2d5-d36e-46f8-842b-044007e023e3',
                    notForTagging: false,
                    label: 'costume designer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1ec481d2-bda7-4704-adfe-8fcb98864331',
                    notForTagging: false,
                    label: 'couch',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bc819779-284a-400c-9e96-36d3ec4886f8',
                    notForTagging: false,
                    label: 'count',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/05ef3646-510a-4229-95af-8dbfccd6400d',
                    notForTagging: false,
                    label: 'countryside',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/99a36e76-2053-401e-a361-ecdca474bfa7',
                    notForTagging: false,
                    label: 'coup',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b5c8c578-b5ce-4613-b919-7d07d948dc55',
                    notForTagging: false,
                    label: 'courtroom',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/361a6578-7405-4624-b34f-a408bc5fff3c',
                    notForTagging: false,
                    label: 'courtship',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b6a35de6-0465-4785-bae0-caae935246c7',
                    notForTagging: false,
                    label: 'coverup',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b870b364-c134-4f06-804e-81c162e51bb2',
                    notForTagging: false,
                    label: 'cowardly',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/82b9e888-8a53-4836-bdc9-1f0dff2f63f5',
                    notForTagging: false,
                    label: 'crash',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0010158b-ba92-4b23-971c-dc2c1b8e08f2',
                    notForTagging: false,
                    label: 'crazy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2e0f3d9c-1ca7-4bd2-b167-eda53f9b41b2',
                    notForTagging: false,
                    label: 'creativity',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/20bda5c8-2ae3-47d1-abb3-9d87e3499dce',
                    notForTagging: false,
                    label: 'credit cookie',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f4a74da9-7b0a-46c2-a235-36ab682db30d',
                    notForTagging: false,
                    label: 'creeper',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/17efdbd1-ca0e-495e-9755-fd9f7bb3e88a',
                    notForTagging: false,
                    label: 'crematories',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3dafe467-1fe4-4949-bb34-dd9d1fa0e3e0',
                    notForTagging: false,
                    label: 'crime aboard buses',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/99b93e12-8871-41ef-afc0-2a1f8eab70d2',
                    notForTagging: false,
                    label: 'crisis shelter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6fe7d47b-c98f-4679-9285-91f3ea183c6e',
                    notForTagging: false,
                    label: 'crosscutting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/577e07ba-e84e-4578-a47c-152b75d01a38',
                    notForTagging: false,
                    label: 'crossword puzzle',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b37d5e9d-5ac9-4bf2-a3e3-ba155d86fa45',
                    notForTagging: false,
                    label: 'cubs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/13d724df-1ee2-4988-bd12-dd1171772600',
                    notForTagging: false,
                    label: 'cuddling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5a192e29-6e22-4e9a-9cbf-f09240055524',
                    notForTagging: false,
                    label: 'customer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9732e03b-4574-4b01-8dd3-9846bd7c4575',
                    notForTagging: false,
                    label: 'cyberattack',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ced91312-ec2a-4df1-b93a-3b85317ff4d9',
                    notForTagging: false,
                    label: 'cyberbullying',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/22446796-f32f-4d0e-aac4-6ef5ae97fee8',
                    notForTagging: false,
                    label: 'cynic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8676cfcf-1808-440c-a404-f78c6e5799c0',
                    notForTagging: false,
                    label: 'cynical',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3216a90-4ecc-4153-b83d-8fb08f0d9512',
                    notForTagging: false,
                    label: 'dad',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6c062ea8-0f94-4f13-ac38-15482871777d',
                    notForTagging: false,
                    label: 'dance studio',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/502588ff-f145-4e42-96a4-084ce530155a',
                    notForTagging: false,
                    label: 'dark comedy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b2e3a4c4-8902-432f-b91b-b93cf3ecc54e',
                    notForTagging: false,
                    label: 'data breach',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9a476816-dd04-4afa-8e0f-3e9ceebeafbe',
                    notForTagging: false,
                    label: 'dead body',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/53b79663-de3d-4275-8371-d2bfa105bfa9',
                    notForTagging: false,
                    label: 'decisive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/422188bd-7e34-460e-8a0d-bf0aaf896cd3',
                    notForTagging: false,
                    label: 'defame',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/698a8fc4-2cbd-47f7-94d4-4b3aa8576422',
                    notForTagging: false,
                    label: 'dependent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cb063b86-4933-4eeb-90b6-0ba6a4f1c73f',
                    notForTagging: false,
                    label: 'deputy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b1e9c92e-ecf5-426c-848d-df7d2944a7d6',
                    notForTagging: false,
                    label: 'despair',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1031567e-a02d-490f-b892-3a73de64c539',
                    notForTagging: false,
                    label: 'detective (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4505441a-3a62-49a5-89fe-7cca67f39a18',
                    notForTagging: false,
                    label: 'deterioration',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4a85357a-1bc8-4741-8c29-fb7026f3f44e',
                    notForTagging: false,
                    label: 'determined',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bad28ea8-efbd-4dce-b94e-27461a156904',
                    notForTagging: false,
                    label: 'devious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2bc417dc-806c-4252-bed1-a8b82de6da05',
                    notForTagging: false,
                    label: 'devout',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/20bed052-5466-42f3-8419-86ef3f7c0d96',
                    notForTagging: false,
                    label: 'diligent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3a4671b3-2ddb-4e28-9590-f82c01c3d313',
                    notForTagging: false,
                    label: 'directions',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/718f6c9f-882e-49ec-9d8b-ae40a7a962c6',
                    notForTagging: false,
                    label: 'director of photography',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1388a87c-0cd0-4f18-b104-cfb3468e5772',
                    notForTagging: false,
                    label: 'dirigible',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/eea3012c-ea89-45f5-bba8-6f6fadfd9185',
                    notForTagging: false,
                    label: 'dis',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dc739272-feb6-4bf4-83de-39ba0d076b46',
                    notForTagging: false,
                    label: 'disabled person',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b3301524-bb29-4a64-a89e-7fdf5d8f6c21',
                    notForTagging: false,
                    label: 'disabled, the',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3c408b9f-768c-41f6-a8d2-7f8fcd49602c',
                    notForTagging: false,
                    label: 'disco',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/da6fdb2a-7c11-41ab-a684-e506063531e8',
                    notForTagging: false,
                    label: 'disco ball',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/676365f4-732f-45cf-944c-6f920e2874ad',
                    notForTagging: false,
                    label: 'disease',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0608eda5-d505-4b93-b5bc-5ac07f38dc50',
                    notForTagging: false,
                    label: 'dishonest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/abca6778-d211-4f6f-93bb-9bd2a8efa8f0',
                    notForTagging: false,
                    label: 'disingenuous',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bb71ecf1-b1bd-4369-8d09-9357d72b5344',
                    notForTagging: false,
                    label: 'diviner',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/436ac691-8ede-4e82-b05a-2f850aeb2c9a',
                    notForTagging: false,
                    label: "doctor's office",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cd973d02-33a2-4661-88af-80f43c5792e8',
                    notForTagging: false,
                    label: 'dog bed',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a35f0bbd-04e8-4417-8fdc-600f1791fb5a',
                    notForTagging: false,
                    label: 'domestic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0726f570-9a16-4ef6-8b6a-9ab2916d5c2c',
                    notForTagging: false,
                    label: 'donation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c74c3c0c-3ea6-4a76-9f98-5bec2a2bfaca',
                    notForTagging: false,
                    label: 'donuts',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/439b54f2-27d4-4445-9bc6-8363c596988e',
                    notForTagging: false,
                    label: 'doomsday machine',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c1b6b083-7438-4adb-bb04-caebf32124ad',
                    notForTagging: false,
                    label: 'dorm',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/83b90ad5-770f-4de0-a8ee-30ab849da029',
                    notForTagging: false,
                    label: 'down',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/72f94dee-7a77-4df4-891c-4ac45dba27d2',
                    notForTagging: false,
                    label: 'drapery',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9964d325-0488-432c-bb1d-00223ad7e05a',
                    notForTagging: false,
                    label: 'drapes',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9d59aecb-8617-468c-b60e-858436487451',
                    notForTagging: false,
                    label: 'drinking alcohol',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d0f349f3-2d39-441a-baa8-5eab3693cbcc',
                    notForTagging: false,
                    label: 'drinking and driving',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8a45f75e-c5d9-4f1d-8534-7eb70f8ac6cd',
                    notForTagging: false,
                    label: 'driving under the influence',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3758c5a0-e301-4593-aaf2-0c0b78e8f629',
                    notForTagging: false,
                    label: 'driving while intoxicated',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/96008d70-0b2a-4b5f-becb-0455b296036d',
                    notForTagging: false,
                    label: 'drones',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/25599bff-efdc-4e03-b3e5-91afcff27b09',
                    notForTagging: false,
                    label: 'drug dealing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/654a0c33-0020-42ec-9909-dfb33f560501',
                    notForTagging: false,
                    label: 'drugged driving',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7765b031-2212-4c90-a995-296e82b98242',
                    notForTagging: false,
                    label: 'drunk dirivng',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c39ab54c-0d5a-41cc-93d9-33bcc83934d8',
                    notForTagging: false,
                    label: 'drunkeness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d0a4dfb6-bfd7-45fb-9e19-fe3e375bf2db',
                    notForTagging: false,
                    label: 'dry cleaners',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8610a84c-1ca7-4aa8-b1b1-884399621adb',
                    notForTagging: false,
                    label: 'dry erase board',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4c593ece-447b-4626-aeaa-6dc249cc8c0c',
                    notForTagging: false,
                    label: 'e-cigarettes',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b1722a2e-a750-4a24-858c-c335079975ad',
                    notForTagging: false,
                    label: 'e. coli',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1f1945d9-9476-4f9d-82c0-4f87aceecf48',
                    notForTagging: false,
                    label: 'earbud',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/11b8d23c-3e78-4310-a6ff-056f35357dd5',
                    notForTagging: false,
                    label: 'eating out',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9b6aef78-e82b-4982-95ab-bc3ec97d30c3',
                    notForTagging: false,
                    label: 'ebullient',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/66be3432-d534-457b-a692-f673ab1e9272',
                    notForTagging: false,
                    label: 'effusive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6e53cb4d-db72-4a14-bbb3-e82ade459ad2',
                    notForTagging: false,
                    label: 'elderly persons',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9942179c-b852-48c9-82d7-75165e18fae4',
                    notForTagging: false,
                    label: 'elderly, the',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f7e7f21a-3ced-437a-a7dd-2241ca2ce2bb',
                    notForTagging: false,
                    label: 'embracing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/38587914-4482-473e-98b1-04b27d941967',
                    notForTagging: false,
                    label: 'embroidery',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9193fd5a-4f52-492e-8799-6bab93f47eb0',
                    notForTagging: false,
                    label: 'emcee',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cecb63f7-c4e8-45cf-bec9-79cde90a899a',
                    notForTagging: false,
                    label: 'emotional',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ade18c4c-3407-4a2f-b9a6-7f7f0477d6fa',
                    notForTagging: false,
                    label: 'empathetic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/05ee293c-ae1a-48d5-99c8-2386d317a04e',
                    notForTagging: false,
                    label: 'emperor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6f1a4e1f-9ee4-4213-a7d4-f04b02f40861',
                    notForTagging: false,
                    label: 'enlightenment',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/06760959-bdac-46ff-a033-98aa734224f7',
                    notForTagging: false,
                    label: 'enthusiastic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/520e8871-3dd9-4721-bf79-2ad3b869f6cf',
                    notForTagging: false,
                    label: 'entrance',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/de8ea651-1d30-4bb7-a1a5-eee54b04b215',
                    notForTagging: false,
                    label: 'entrepreneur',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f3c69a50-e7bc-47cb-9b6d-b5fa2ddf62cd',
                    notForTagging: false,
                    label: 'envious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b0cc0118-1978-4d4c-84dc-8c78430666d5',
                    notForTagging: false,
                    label: 'escaped prisoner',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/43712187-7bf0-40bf-bc38-387802e9e953',
                    notForTagging: false,
                    label: 'esthetician',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3441194a-6355-4e33-bee7-788936635540',
                    notForTagging: false,
                    label: 'eureka moment',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e5f46e78-066c-4afd-9ac0-8bdad5bdefdb',
                    notForTagging: false,
                    label: 'even-tempered',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/03077604-1219-4c8f-af18-02b4212df399',
                    notForTagging: false,
                    label: 'evidence room',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7d042264-2d64-4755-8492-4c5e2c2be9d9',
                    notForTagging: false,
                    label: 'evil',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f51cc09a-30f9-4dad-99a8-b28ae205e96c',
                    notForTagging: false,
                    label: 'exaggeration',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c23bdfba-c15c-4cef-947e-0958cf62f5cd',
                    notForTagging: false,
                    label: 'excitement',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2e92522d-f1dc-497d-90c0-1fcea32679e7',
                    notForTagging: false,
                    label: 'exercise bicycle',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4b803ab3-3cac-4ad7-8900-fa8c6d78d1fc',
                    notForTagging: false,
                    label: 'exercycle',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/98e228ac-a9d3-4df9-ba1e-19895179a85c',
                    notForTagging: false,
                    label: 'exhaustion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/25a44b84-2024-489e-8dac-0dcb48df86ab',
                    notForTagging: false,
                    label: 'extraterrestrial life',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f1bac680-0bd1-4d89-891d-5d2be09cb000',
                    notForTagging: false,
                    label: 'exuberant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e601ab4a-3ff8-4ffd-8039-5075beaa2f7d',
                    notForTagging: false,
                    label: 'eye exam',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/26cbe166-e8a8-40d6-9fbe-8893fc149ba4',
                    notForTagging: false,
                    label: 'fabric',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/741a5fb4-929b-4743-b9bf-44ca5040aa39',
                    notForTagging: false,
                    label: 'fainting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/480dcf07-cb70-4655-936a-a3d8a33e53fb',
                    notForTagging: false,
                    label: 'fairness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3ae5caa-099c-46b8-a12c-5e507e324b24',
                    notForTagging: false,
                    label: 'faithful',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6e223bab-41e1-4c69-90ca-2cfb88b898de',
                    notForTagging: false,
                    label: 'fakes',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f6cb9233-f85d-4483-9b85-2746811a3b18',
                    notForTagging: false,
                    label: 'false accusation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/93ea623e-f235-43f9-af92-674854ea6571',
                    notForTagging: false,
                    label: 'family violence',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/29063bc7-73cb-4863-a0f1-eb018fd996a4',
                    notForTagging: false,
                    label: 'fearful',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1e903a87-5809-4508-9df4-97b4686d6225',
                    notForTagging: false,
                    label: 'fearless',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/76bb890e-7ef1-465d-bfc6-e1f9f1533374',
                    notForTagging: false,
                    label: 'feast',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/76263960-f0b0-44b2-b6e9-9a5c1858bd0f',
                    notForTagging: false,
                    label: 'ferry accident',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8e1362ed-1e73-4851-8b9b-10bb8458d4d7',
                    notForTagging: false,
                    label: 'ferry disaster',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d2a0828d-165d-4ed1-aa2f-6d9741418602',
                    notForTagging: false,
                    label: 'fiddle',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/de80b3ef-4f69-45e9-9e86-56f1af30a4f4',
                    notForTagging: false,
                    label: 'field glasses',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7e9fd90f-dacc-41db-8c37-af83cebe8d12',
                    notForTagging: false,
                    label: 'filial',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/df8deb22-732f-4a65-a7ba-f8471ba69706',
                    notForTagging: false,
                    label: 'film editor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f35e7ba3-b29f-4cb0-8f5f-c7a5eba2f62a',
                    notForTagging: false,
                    label: 'finance',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b4c26f57-4941-4a41-997a-8f4416ab23b7',
                    notForTagging: false,
                    label: 'financier',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c88af57f-a029-4980-8497-7a97f2b72ceb',
                    notForTagging: false,
                    label: 'finding money',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0bb6fdf4-034f-4d5a-8bc6-e797a7c8cf38',
                    notForTagging: false,
                    label: 'fire department',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9465efe9-36f9-4084-894e-06e04fea801c',
                    notForTagging: false,
                    label: 'fire house',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6e58b09a-ab2c-4a8b-b154-1f26ab04da6b',
                    notForTagging: false,
                    label: 'firefighter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f3bcbf04-5404-4b61-97f2-7baefe356b68',
                    notForTagging: false,
                    label: 'fireman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e0c4cb8b-3874-452f-aa6a-9031c7c78fd9',
                    notForTagging: false,
                    label: 'firemen',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/647fc881-9bf3-46f2-9e65-3ca293b8eee8',
                    notForTagging: false,
                    label: 'fireplug',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e91115f5-c23a-4d2f-939e-5c6c464bdf01',
                    notForTagging: false,
                    label: 'fish tank',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0f00e6a7-f7fe-40d2-80a0-34fde5f1c732',
                    notForTagging: false,
                    label: 'fitness studio',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a4fe2e83-10e5-4506-8258-c4ec0bdd2bd2',
                    notForTagging: false,
                    label: 'fixing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/363b251e-0c15-4b18-88b2-74a21bb83892',
                    notForTagging: false,
                    label: 'flash drives',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ea0a8e81-627c-4a9f-801e-123e0b454557',
                    notForTagging: false,
                    label: 'flea market',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/18dd4ff2-7215-4741-bd2c-2d51c83a6689',
                    notForTagging: false,
                    label: 'flight belt',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e481589a-089a-457b-9c3d-22f1e979c774',
                    notForTagging: false,
                    label: 'flip-flopper',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3d5ec1bb-6d58-49a6-9b68-b74892432ffd',
                    notForTagging: false,
                    label: 'flipping the bird',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3954c58a-c8bc-449c-83b4-1f45a45feee6',
                    notForTagging: false,
                    label: 'flower shop',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c64319de-29fe-43bd-90ab-c50826de16d0',
                    notForTagging: false,
                    label: 'flying carpet',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/56539609-419f-461c-9e66-a2163f9f98a0',
                    notForTagging: false,
                    label: 'following in footsteps',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c0a98375-e0e0-44b2-9e77-acc2a6560444',
                    notForTagging: false,
                    label: 'foodborne diseases',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/97887399-29e2-4fa7-a6b8-ee6a84b629d3',
                    notForTagging: false,
                    label: 'football pitch',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fcbe7a50-88cc-4094-8455-fec38ed73981',
                    notForTagging: false,
                    label: 'forest fire',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0148acb2-1081-4140-acc4-2f4b46d79132',
                    notForTagging: false,
                    label: 'forgeries',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/373eb91b-ff1a-465e-86d2-221061ffd119',
                    notForTagging: false,
                    label: 'forgiveness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/72448e50-e001-4ea4-a9db-1ca57be71a41',
                    notForTagging: false,
                    label: 'formal ball',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8cfe979a-43ec-47bb-ba65-406899362f35',
                    notForTagging: false,
                    label: 'formals',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/defff365-602d-4367-9cbe-289bdcece9ad',
                    notForTagging: false,
                    label: 'fortune teller',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/68b5ae9e-06fe-4693-8257-f52135b6c86f',
                    notForTagging: false,
                    label: 'foster home',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/521a5637-fe1c-43c6-a818-3c560a3bcc90',
                    notForTagging: false,
                    label: 'found money',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/12b906c7-1780-41d2-9942-cd35441c71c8',
                    notForTagging: false,
                    label: 'foyer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5d9a8113-c051-47fe-8b93-5b7b40408b93',
                    notForTagging: false,
                    label: 'frame',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c38f88ef-56ef-4445-83b3-68b3287d4aa3',
                    notForTagging: false,
                    label: 'fraternal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4a20257f-c48c-4612-aee3-41b8d031c0f3',
                    notForTagging: false,
                    label: 'frats',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/afa44052-030c-47c8-97b1-7712fc4009e9',
                    notForTagging: false,
                    label: 'free climbing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7f6a06af-d564-426a-bab2-0a4e2ced545f',
                    notForTagging: false,
                    label: 'freeway',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2537e0dc-5bae-4c71-b18b-0187a68a7878',
                    notForTagging: false,
                    label: 'frequent fliers',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ac915cec-6246-4447-bf1f-c6cb66d56e7d',
                    notForTagging: false,
                    label: 'friendly',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5161e47e-0125-4e2a-8550-afaa86b133eb',
                    notForTagging: false,
                    label: 'frightened',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2d9637a4-9a0f-4b67-b8be-8ae0af2a483d',
                    notForTagging: false,
                    label: 'front door',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e27f1a34-3ead-4e45-b10b-1870aaa883fe',
                    notForTagging: false,
                    label: 'fuel spill',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/21cec3c3-a1fd-4de9-b635-081ab04dea07',
                    notForTagging: false,
                    label: 'fugitive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4c06f328-6e16-4fb8-a7cb-e273ec92f3c9',
                    notForTagging: false,
                    label: 'fun',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/df8bcfc4-a3b9-48ef-b32e-8f9bd6a294fa',
                    notForTagging: false,
                    label: 'gagging',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c616127b-bda9-4674-8b2b-a51fd4ae5ad1',
                    notForTagging: false,
                    label: 'garbage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/55423a4b-285f-4555-8d72-1b971227b736',
                    notForTagging: false,
                    label: 'gas',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b9e75884-8621-4dd5-ad96-1df447be8c7a',
                    notForTagging: false,
                    label: 'gas leak',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bee53d81-6153-4882-8b4a-8ebab33edad6',
                    notForTagging: false,
                    label: 'gas station',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e3a28e43-e259-422e-959f-137f86b862af',
                    notForTagging: false,
                    label: 'gay marriage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e9ac18cc-98cd-4a42-99b1-6b0706962df5',
                    notForTagging: false,
                    label: 'gazelle',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/aa43c762-74f6-47bb-8290-7951113114ce',
                    notForTagging: false,
                    label: 'geeky',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a7f1dded-f104-4bb5-a76a-049355ecb07a',
                    notForTagging: false,
                    label: 'generation gap',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f3314e97-fac8-4c73-8429-a1c11893028f',
                    notForTagging: false,
                    label: 'generous',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8051c1c0-f79c-4e9c-bbc3-225269902905',
                    notForTagging: false,
                    label: 'getting CPR',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a81c9fe3-aca4-49a9-951b-6aae3afb45d8',
                    notForTagging: false,
                    label: 'getting cold feet',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bb8bf877-8ab1-4159-9bfe-da8834d8f936',
                    notForTagging: false,
                    label: 'getting dressed',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e99ea0a4-bed2-4bc9-8dd4-489336b10eb5',
                    notForTagging: false,
                    label: 'getting made-up',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/99ac46a1-530a-44b5-916a-fe58b18393e4',
                    notForTagging: false,
                    label: 'getting pregnant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b2745d5d-cfad-42e8-bc8b-4fbaa5d13149',
                    notForTagging: false,
                    label: 'giggling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/61fd9284-acb9-485a-bce8-dcdafd1dd135',
                    notForTagging: false,
                    label: 'girlfriends',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6b3d4cb2-8d31-44c7-b167-0cfd9a09bff0',
                    notForTagging: false,
                    label: 'giving',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5d7a075a-d956-4428-813c-db997cfc6715',
                    notForTagging: false,
                    label: 'giving five',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/36fd418c-8215-4130-bac9-77010d4e7e99',
                    notForTagging: false,
                    label: 'giving the finger',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/99be05b6-2ca1-4ad3-ac6c-9492be34ac05',
                    notForTagging: false,
                    label: 'glitter ball',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8678c025-8d16-4772-b780-3e05759e284b',
                    notForTagging: false,
                    label: 'glowing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5d1e9592-e6fb-4df4-a2bb-0d6364614b1c',
                    notForTagging: false,
                    label: 'goddesses',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ab8d5b03-3b4c-4eb7-8e2d-edcfc0f6e7c7',
                    notForTagging: false,
                    label: 'gods',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/92009f33-090e-498e-9edd-1347f0821823',
                    notForTagging: false,
                    label: 'going to movies',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d4425a98-81ee-491c-89da-d753ccd55dbb',
                    notForTagging: false,
                    label: 'going to restaurant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e27aa92f-592f-4fdd-94ff-95bfe2d6d6a2',
                    notForTagging: false,
                    label: 'gold-digger',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6bc1355b-7478-454d-a866-fb2c7ff78b4c',
                    notForTagging: false,
                    label: 'golddigger',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5b6c7e5b-c53c-46f6-b1ab-e127378e1ed1',
                    notForTagging: false,
                    label: 'golden fleece',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f202441f-117a-468d-9f63-231ba9bc1c41',
                    notForTagging: false,
                    label: 'good',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c8e5d678-0a83-4ec1-9c5b-ec114018d95c',
                    notForTagging: false,
                    label: 'governess',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/22fa4743-2b1d-4570-96f1-e174b9332701',
                    notForTagging: false,
                    label: 'graduations',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/370ed3dc-905c-49ab-a2ea-ae9f1d9031a1',
                    notForTagging: false,
                    label: 'graveyard',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5ba4e4de-e623-4933-b457-506be6f63273',
                    notForTagging: false,
                    label: 'grouchy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/980cb883-e7f2-4140-b49b-443b629bf070',
                    notForTagging: false,
                    label: 'growth',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1cb1641b-d8ef-4b3c-abe8-6cec66ad115e',
                    notForTagging: false,
                    label: 'guarded',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/aa93240d-cd49-4934-80ff-86619e11c625',
                    notForTagging: false,
                    label: 'guardian',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a0a6988a-dd66-41d9-b699-0c589e0d4d60',
                    notForTagging: false,
                    label: 'gullible',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/135f3f19-972a-4ff0-be6b-706dbe789b69',
                    notForTagging: false,
                    label: 'gun',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8f1d4a3b-8222-4ad3-9d6d-3a0551191d75',
                    notForTagging: false,
                    label: 'gym',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f80aea22-20a3-4dac-8296-b9b409df6019',
                    notForTagging: false,
                    label: 'hair curler',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a56a3a9e-c55c-44e0-87c6-3cee8e2107ad',
                    notForTagging: false,
                    label: 'hair styling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b5d87631-964f-43e8-a9d3-72a3c5265dad',
                    notForTagging: false,
                    label: 'hand mirror',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/83772295-ad72-4b9e-bbb8-3db8bd4f7945',
                    notForTagging: false,
                    label: 'handicapped',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/17186291-556b-4029-987a-42c8e54fa1ea',
                    notForTagging: false,
                    label: 'handicraft',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1c52bf38-7f74-483d-ba54-ca60fd7f8289',
                    notForTagging: false,
                    label: 'happily married',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4363a40d-bd48-4735-ae7d-4b775c4bc91e',
                    notForTagging: false,
                    label: 'hardworking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fa459e20-3dd7-4a74-b202-7a9c79ec1d47',
                    notForTagging: false,
                    label: 'haughty',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f1e40cef-f866-43f3-9f98-1c1785d070b2',
                    notForTagging: false,
                    label: 'health resort',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8c1aaa2d-825a-45f8-918d-276a184ebe4e',
                    notForTagging: false,
                    label: 'heart surgeon',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/28c9410c-adf8-4bd7-a586-3858199597e3',
                    notForTagging: false,
                    label: 'helicopter crash',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9eaaf71b-8814-44be-98dd-fcd809a63094',
                    notForTagging: false,
                    label: 'henchman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1268f33f-cbca-40e0-8026-24c4c68cfd6a',
                    notForTagging: false,
                    label: 'hidden identity',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/eae829fe-168d-4127-b3db-4f7f8576d888',
                    notForTagging: false,
                    label: 'high speed chase',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/29d28532-4e82-4579-8ea6-b0a0a540ee60',
                    notForTagging: false,
                    label: 'high-strung',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3ed75ca-664a-4f0c-9cbe-4e3f946a4ed4',
                    notForTagging: false,
                    label: 'hobbies',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b233e83e-dfab-4650-a50d-60f9a2786657',
                    notForTagging: false,
                    label: 'hockey fan',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2143055b-231f-49bb-a9fb-76f484489378',
                    notForTagging: false,
                    label: 'hogs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/83db2c78-13e7-400e-8191-990c53acae9b',
                    notForTagging: false,
                    label: 'home decoration',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3adddc7-7c1e-4842-860a-4ec431631332',
                    notForTagging: false,
                    label: 'home health aide',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/32d48375-b7e3-420e-8f38-13a6222466f7',
                    notForTagging: false,
                    label: 'homefront',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cc46db00-69d6-4dfc-b45d-2e1c527ddbcf',
                    notForTagging: false,
                    label: 'homicide',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8e099853-83ec-418a-8a2b-4045d93d066d',
                    notForTagging: false,
                    label: 'honor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/81b308ce-9c1f-4b56-b5f6-3fb4cc774ac8',
                    notForTagging: false,
                    label: 'hopping',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/122b0d60-a079-4ab3-af36-901384f28f47',
                    notForTagging: false,
                    label: 'horse races',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dd37c175-84f2-44df-931d-e9e7df3e1e73',
                    notForTagging: false,
                    label: 'hostile',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/496c7dfe-5d46-4d9f-b88a-e36d8ad387d7',
                    notForTagging: false,
                    label: 'house plant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a905f7bf-da46-4038-9d4e-d5633b8c09f1',
                    notForTagging: false,
                    label: 'houseguest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0d61a954-580c-4dd8-b2ef-b85e6cae23df',
                    notForTagging: false,
                    label: 'housekeeper',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7734876b-e270-4436-a76f-35cf417377cc',
                    notForTagging: false,
                    label: 'housewife',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c4e54a54-84ec-4417-8937-403a1661f8b5',
                    notForTagging: false,
                    label: 'human remains',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d30ae249-0520-4bfc-8c2d-cdd3e552d0d6',
                    notForTagging: false,
                    label: 'human smuggling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/26c21e41-59f5-41ff-85d1-eb5111f9fe8e',
                    notForTagging: false,
                    label: 'humble',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/76ee82b0-48ca-4e0f-adec-2fbe9eb6194a',
                    notForTagging: false,
                    label: 'humorous',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ad5bc6f5-6841-4880-b55d-8f0e92e9a31a',
                    notForTagging: false,
                    label: 'ice hockey',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0ac62d70-ef02-4957-9bb7-e2c173461f88',
                    notForTagging: false,
                    label: 'ice rink',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a28984c2-3f3f-41d7-b8b1-2704c6f6426e',
                    notForTagging: false,
                    label: 'idiosyncratic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4a868091-6711-4260-adf9-38bd6d227f00',
                    notForTagging: false,
                    label: 'igniter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/29d1bf71-b9a3-40ae-b3e8-106426f95a70',
                    notForTagging: false,
                    label: 'ill-tempered',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0164fffd-9030-41df-b2b7-494b4edf0ed3',
                    notForTagging: false,
                    label: 'imaginative',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5261dbd3-119d-44da-b34c-33d0888c40c3',
                    notForTagging: false,
                    label: 'immigrant smuggling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7780d80b-a7e8-4f47-b537-dc627f82a705',
                    notForTagging: false,
                    label: 'immoral',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/557556f7-74fc-4f6d-8e1e-51d2fbba4b36',
                    notForTagging: false,
                    label: 'impassive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2e57a8a8-b9c2-48c9-8e9c-587c15a48287',
                    notForTagging: false,
                    label: 'impertinent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c08273a6-722e-4b85-8f1a-16d5439690c1',
                    notForTagging: false,
                    label: 'imposter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2888751b-a3f6-4768-9235-d4323c072873',
                    notForTagging: false,
                    label: 'impressionable',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5e96dada-3f63-4237-9ae3-8ab8937d80a2',
                    notForTagging: false,
                    label: 'in-line skating',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/17c19953-6f34-4d2b-afbf-0cb3a0f05277',
                    notForTagging: false,
                    label: 'incident',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/250725e7-273a-4c34-b375-6995dbea3dd2',
                    notForTagging: false,
                    label: 'income',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f666ed64-bdad-4930-8829-e13e3276000e',
                    notForTagging: false,
                    label: 'indecisive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0416288b-8125-4757-841f-38e054c346e9',
                    notForTagging: false,
                    label: 'independence',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6ad64f23-6bc7-4a49-a6fe-bc099f65ed45',
                    notForTagging: false,
                    label: 'individualistic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ed996e6e-5fe4-4197-a962-079f3e7531d9',
                    notForTagging: false,
                    label: 'industrious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/241c6e7b-3cf5-469b-ab42-a13c053d1fdb',
                    notForTagging: false,
                    label: 'inebriation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2302545b-1dc4-4919-9ead-16806d5186fe',
                    notForTagging: false,
                    label: 'information bearer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8b1505c5-f18e-49d8-9995-f536f5e1becd',
                    notForTagging: false,
                    label: 'inline skates',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7c115c44-1f7f-4416-810b-2f75694ce6a5',
                    notForTagging: false,
                    label: 'inmate',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7e76678c-19b9-43df-b8a9-ef7497903bb4',
                    notForTagging: false,
                    label: 'insane',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a7fde2f5-da63-4956-bfc9-aaa6a952e545',
                    notForTagging: false,
                    label: 'insecure',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/36de1113-1ea7-41aa-b8a3-6e4bed72af3d',
                    notForTagging: false,
                    label: 'inspirational',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f3e73370-768f-4697-a3a6-9836978fab80',
                    notForTagging: false,
                    label: 'internet cafe',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a8ce4b72-d925-4a5f-9220-df81cc1b3cb0',
                    notForTagging: false,
                    label: 'internment camp',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fea10e45-6d34-4fda-a959-d37f2f898da8',
                    notForTagging: false,
                    label: 'interview room',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/aad5de54-f967-44c0-befd-26882ea9aca9',
                    notForTagging: false,
                    label: 'invasion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/54e3a32c-5034-4c1c-80a7-47d438eec330',
                    notForTagging: false,
                    label: 'inventive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d4fcb028-0227-4958-8609-d7806c65991f',
                    notForTagging: false,
                    label: 'jaguars',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/920044e1-fe9e-46f8-a808-c96186ff5335',
                    notForTagging: false,
                    label: 'jet',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f803afe4-8cc9-4f82-83e4-8ac6eb76156a',
                    notForTagging: false,
                    label: 'jet belt',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/379269e2-03d1-4d8f-81f7-5a229a7a851c',
                    notForTagging: false,
                    label: 'jet bridge',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ed531e83-6122-4831-acd6-cd77f5427694',
                    notForTagging: false,
                    label: 'jet fighter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0f74bf1a-9f42-42a8-b130-02a7e40d3c47',
                    notForTagging: false,
                    label: 'jetbelt',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3e0dbe22-3761-4fae-b164-ac288c8013b4',
                    notForTagging: false,
                    label: 'jetpack',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7e13f0e8-1a00-44b2-99ad-e53e21b82db5',
                    notForTagging: false,
                    label: 'jetway',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9e876d01-e260-4679-863f-aa22ec61ec0a',
                    notForTagging: false,
                    label: 'jewel',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/22bee511-4ddf-4555-ae71-2c1a6817dffc',
                    notForTagging: false,
                    label: 'jigsaw puzzle',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6d77e02b-09f4-4341-b350-a86b5f09acf4',
                    notForTagging: false,
                    label: 'jobs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/12f18843-54a7-4cac-847b-c15f7425f927',
                    notForTagging: false,
                    label: 'jogging',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/06989a32-3849-4d9f-b0c2-13aecef6fe87',
                    notForTagging: false,
                    label: 'joker',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/99ace20d-03d1-4c55-adc1-8d348fd269c1',
                    notForTagging: false,
                    label: 'journalist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a2163674-9d80-4658-8e8a-3c269966d9bb',
                    notForTagging: false,
                    label: 'joy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f88c2032-94dd-4c7e-973c-51f1a3551c7f',
                    notForTagging: false,
                    label: 'jump',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/58b9a5c9-e0ac-40c9-897a-e5fd7867e40c',
                    notForTagging: false,
                    label: 'juuling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fcdd64c2-7b5a-43cc-905d-db72bf462328',
                    notForTagging: false,
                    label: 'juvenile',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4508e9c3-7f95-40d5-922c-7ee444361e1c',
                    notForTagging: false,
                    label: 'juvenile detention center',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9818cf1b-2447-4aa8-a796-7f08bd8f0490',
                    notForTagging: false,
                    label: 'juvenile hall',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9d387b3c-facd-48d8-9273-080ec0251ce5',
                    notForTagging: false,
                    label: 'keying',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a0338320-f7ad-4e28-a28e-4d4347ed8d30',
                    notForTagging: false,
                    label: 'kids',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fadb1c75-af63-4da6-a337-43b2f6fd6d97',
                    notForTagging: false,
                    label: 'killing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/075b1ac6-970a-4468-8510-09eae3d2eaf8',
                    notForTagging: false,
                    label: 'kinship',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/acf60647-e125-4544-84c5-b0b00cc2e0bc',
                    notForTagging: false,
                    label: 'kittens',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/80f12eab-5a66-4c7b-8fb3-387ad89ffc59',
                    notForTagging: false,
                    label: 'knives',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9b8bb428-49e7-4b2c-badc-40c8f9ea7721',
                    notForTagging: false,
                    label: 'knowledge',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d27463f2-e29b-4a08-a58a-9f707759bea1',
                    notForTagging: false,
                    label: 'lab',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a5463a80-ec32-4532-b2fd-bc78eb485bec',
                    notForTagging: false,
                    label: 'lace',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4e94b347-2b5a-44a5-bdf9-c3991f568279',
                    notForTagging: false,
                    label: 'laid back',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5f9564f6-6a34-4550-90a3-815f50d885cb',
                    notForTagging: false,
                    label: 'lair',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/90e5a930-765c-435d-b34c-3dc84376ba8b',
                    notForTagging: false,
                    label: 'lambs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/08df2963-f8cc-4776-97cc-b5aaa2fb700d',
                    notForTagging: false,
                    label: 'landmark',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1e246c1f-008b-4873-bc01-310429f031d7',
                    notForTagging: false,
                    label: 'landmine',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c98cfcb7-9ede-443e-8421-321aebe42bf0',
                    notForTagging: false,
                    label: 'laughter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7ddc4f6b-b52d-45c8-8ac3-edd2f8f4d17d',
                    notForTagging: false,
                    label: 'laundries',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dae157a2-757b-4a0c-9038-894e3d6e6ff1',
                    notForTagging: false,
                    label: 'law enforcement',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/97d51216-3153-4754-b430-9cb0b32009ff',
                    notForTagging: false,
                    label: 'left at the altar',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0908c9aa-dec5-4547-94d0-a37495621800',
                    notForTagging: false,
                    label: 'leopards',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/eb8d3cf0-15c3-48a1-b457-7b2a23562906',
                    notForTagging: false,
                    label: 'liberty',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cd6ad5b1-a049-46d1-a58a-16a967e722c5',
                    notForTagging: false,
                    label: 'lifeguard station',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/016142f3-d858-4082-a8c1-472c568ea028',
                    notForTagging: false,
                    label: 'light-hearted',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/75c9c74c-20f1-4360-9958-20d5f02978c1',
                    notForTagging: false,
                    label: 'line of succession',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2e5eb90f-58c1-42c9-b444-b7547ccc08c8',
                    notForTagging: false,
                    label: 'lions',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e4e685e3-7727-4e75-87fc-3de99269b748',
                    notForTagging: false,
                    label: 'liquor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a50e09f6-c8e1-490b-8e8d-6183d123fb28',
                    notForTagging: false,
                    label: 'local government',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0f86072f-856e-4692-84a9-37ccd9a569c5',
                    notForTagging: false,
                    label: 'lockout',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e50ae25a-9a9a-49ee-94c5-daa9ade97654',
                    notForTagging: false,
                    label: 'logical',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/41b8bd32-92ea-47ca-8da7-4ad22c59bec7',
                    notForTagging: false,
                    label: 'lost pet',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/64aa7a3c-208f-4be2-940a-79e2485c70cd',
                    notForTagging: false,
                    label: 'lotto',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4d178b22-ff35-4577-b640-487eba3450da',
                    notForTagging: false,
                    label: 'love potion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dc03ae00-07b6-43c8-b3d9-8988f4b23e2a',
                    notForTagging: false,
                    label: 'lunar eclipse',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/58a1ac4c-13e7-4cda-887e-f3c09134b997',
                    notForTagging: false,
                    label: 'magic potion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/402c3ea1-82ed-4164-8aa5-32993b0ad9cc',
                    notForTagging: false,
                    label: 'mailman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/459b766d-0a41-4d49-b9fe-ae581ed4de51',
                    notForTagging: false,
                    label: 'makeup',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a42fa52c-23fb-4a9c-b472-c7173a5c907a',
                    notForTagging: false,
                    label: 'making out',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d80c556f-3e6b-4c15-9593-b83af06e3ccb',
                    notForTagging: false,
                    label: 'mall',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0c4fe26c-ff90-4e58-909d-5985ba0815ad',
                    notForTagging: false,
                    label: 'malware',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a416e2b8-107d-40f1-bbc8-d2b5bb9f399c',
                    notForTagging: false,
                    label: 'manhunt',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5363f591-afe4-4c61-a51a-95eacbaea96d',
                    notForTagging: false,
                    label: 'manicurist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2e771081-6c23-4b59-baac-ed99cb7c841e',
                    notForTagging: false,
                    label: 'manipulative',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b5ad4338-2fe1-4c4e-b62f-83022973992e',
                    notForTagging: false,
                    label: 'marijuana cultivation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/41dd0a3a-6f38-4c25-9c15-a43229f4a894',
                    notForTagging: false,
                    label: 'marionette',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/753b8cd5-1f83-4875-bcb6-552109852b39',
                    notForTagging: false,
                    label: 'masturbating',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ca7eaa83-15ce-4d2e-ad98-6b996e2460ad',
                    notForTagging: false,
                    label: 'masturbation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a9a08321-39ee-4a65-b475-021f76b5ee1e',
                    notForTagging: false,
                    label: 'matador',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1b71517d-cba9-48f4-8544-da3b9fafaf08',
                    notForTagging: false,
                    label: 'mechanical toy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/16e0a307-7535-45f4-8edc-bfab9ad56f9d',
                    notForTagging: false,
                    label: 'medical examiner',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b79ba122-eb09-40a8-91a9-1bdc25fb0a43',
                    notForTagging: false,
                    label: 'medical specialist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e3d4e472-4daf-418c-b8a8-f5b6545e5007',
                    notForTagging: false,
                    label: 'medical transplant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2fc18a2f-caa2-4149-b7fd-cf2a44c5e7a5',
                    notForTagging: false,
                    label: 'medium',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/34856f22-b5c6-4f47-9077-5a1a24a5481c',
                    notForTagging: false,
                    label: 'melodrama',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3d66b61b-da3a-407e-9ae2-59d33eec695c',
                    notForTagging: false,
                    label: 'mental asylum',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ad1e6926-bc72-4c6d-b28a-30aebd71ad85',
                    notForTagging: false,
                    label: 'mental institution',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a86d2148-17bf-4532-a3cd-f4b2345e40ec',
                    notForTagging: false,
                    label: 'mentoring',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f0e1e1b9-bc89-4486-8714-c8f7ea54192c',
                    notForTagging: false,
                    label: 'merry-go-round',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4b720788-a14f-43f2-925e-de1df8882c98',
                    notForTagging: false,
                    label: 'meter maid',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/746139fc-deb9-4959-a786-2f876b6a88a3',
                    notForTagging: false,
                    label: 'methodical',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c14fb335-474f-44f5-8a34-0c4b4d65d87e',
                    notForTagging: false,
                    label: 'military',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8b047f85-39ff-4144-9549-3d39e333df54',
                    notForTagging: false,
                    label: 'military campaign',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/eac89db2-9d23-4ea7-89b4-fe2dfe5e589a',
                    notForTagging: false,
                    label: 'mirrored disco ball',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0249c7a3-4e9f-4b6a-9dd7-c7f4ff0e028b',
                    notForTagging: false,
                    label: 'misdirection',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/88978983-024c-45d4-b403-f2b9cc095ee1',
                    notForTagging: false,
                    label: 'missing children',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/eb5f6323-d43c-490c-8efa-1b66a5368308',
                    notForTagging: false,
                    label: 'missing dog',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ab49efdb-7040-4369-931a-3884fd7c5b85',
                    notForTagging: false,
                    label: 'missing girl',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b9784141-f6c7-4518-962e-aaa1f7222118',
                    notForTagging: false,
                    label: 'missing man',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c7221304-1788-49b5-a240-09e67e8c3a8d',
                    notForTagging: false,
                    label: 'missing woman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/37ca09e2-0181-4d88-9e28-b6901caa6ee4',
                    notForTagging: false,
                    label: 'mixed drink',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a76130dd-5a0e-4a21-b651-6bb734778b0d',
                    notForTagging: false,
                    label: 'mob',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7833dd97-02ee-4c8f-b157-3cb4e5544347',
                    notForTagging: false,
                    label: 'mobile phone',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a39c6b2a-22fb-4345-a3d2-64acd62404f1',
                    notForTagging: false,
                    label: 'mockery',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ec1f30bb-a95f-4616-908f-d470edd818a6',
                    notForTagging: false,
                    label: 'modest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/38518679-bbed-4d6a-a7c7-d886afdcfa17',
                    notForTagging: false,
                    label: 'mom',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a7e785e5-f182-41fd-98ce-0fc31e988dfd',
                    notForTagging: false,
                    label: 'monarch',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e698803f-63e5-4550-a7c2-30744aa714ff',
                    notForTagging: false,
                    label: 'moose',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b29b860e-bf3e-4bcd-9cf8-5198ead19160',
                    notForTagging: false,
                    label: 'moral',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b677182e-7f57-4b8c-a617-8bb85438c0d4',
                    notForTagging: false,
                    label: 'morality',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9e82cf79-988e-457f-a324-20fb6c01d00a',
                    notForTagging: false,
                    label: 'mortuary',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fee6506d-5cd9-441e-944b-49b41aa70a7e',
                    notForTagging: false,
                    label: 'most wanted',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/de341197-23f8-4aeb-8635-6b32409909d2',
                    notForTagging: false,
                    label: 'motorcycle accident',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b87f8359-06a2-4824-abcf-b59dfdedd541',
                    notForTagging: false,
                    label: 'mouse trap',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6666f5a6-5f62-4d69-b4dd-f655276eb231',
                    notForTagging: false,
                    label: 'movie camera',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7c9e108a-458f-47a8-8bfe-a455bb101d3b',
                    notForTagging: false,
                    label: 'mudslide',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/959ed313-db91-48f2-8c24-1f857e4fdb5e',
                    notForTagging: false,
                    label: 'mugging',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5c6036ac-4d76-4424-bbda-fb1fa1df8247',
                    notForTagging: false,
                    label: 'myocardial infarction',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/22a65bb1-ce9f-4904-a62e-5b76805e0bb2',
                    notForTagging: false,
                    label: 'naif',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/18bde1a3-842f-4346-bd8d-07945e69f9c8',
                    notForTagging: false,
                    label: 'naked',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/97827ff5-b8a5-4e5e-b412-b793a2698454',
                    notForTagging: false,
                    label: 'nanny',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c309e6f1-3818-4ab1-b07c-5e06011c171a',
                    notForTagging: false,
                    label: 'narcissistic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/60333063-4190-41e6-8d70-47f18a282eaf',
                    notForTagging: false,
                    label: 'necking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3895d228-5611-446e-ba65-a403b6e5f825',
                    notForTagging: false,
                    label: 'needy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/68c7c7df-c25f-486d-a882-fbfd3b0ebc9b',
                    notForTagging: false,
                    label: 'negativistic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c9c0f18f-f77c-4ef2-b662-cda9cbb040f7',
                    notForTagging: false,
                    label: 'nerdy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e7b54127-db57-48ef-a32d-92b3840078e5',
                    notForTagging: false,
                    label: 'nervous',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cc4bb21d-f480-4215-ab7b-3cee162d913f',
                    notForTagging: false,
                    label: 'newborn',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/edd0416a-970c-4828-ac2b-e4107bf4e046',
                    notForTagging: false,
                    label: 'night views',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6fb13031-ea58-46f2-b236-1737cdd2612f',
                    notForTagging: false,
                    label: 'non sequitur',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6458fbdc-d0c1-4b95-9e19-f6f3b7180d9d',
                    notForTagging: false,
                    label: 'non-profit',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b26fde0d-0cea-495c-a997-6f3412bb7ce2',
                    notForTagging: false,
                    label: 'nonprofit',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/65eff5bb-3bd4-40b0-93de-0eea1f2a42ce',
                    notForTagging: false,
                    label: 'nude',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e98c45db-7cc7-4c99-ac02-6d1790cbf4fd',
                    notForTagging: false,
                    label: 'nymph',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a6836622-a659-41c4-87b6-f62dc4f53db6',
                    notForTagging: false,
                    label: 'obstinate',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2fb97a66-95c6-4be7-9dc9-fe594fbc778a',
                    notForTagging: false,
                    label: 'officer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/beb4f1cf-dda8-45f2-a392-4b85af300ca8',
                    notForTagging: false,
                    label: 'oil drilling and exploration',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c47e6e3a-608e-46ff-91ee-51cb53168d84',
                    notForTagging: false,
                    label: 'oil refinery',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4de68255-e2d1-4924-9b7a-77902e422229',
                    notForTagging: false,
                    label: 'open-air theater',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4e01981b-c5e5-47c2-a8a7-818e13d02b11',
                    notForTagging: false,
                    label: 'opening',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2b3d7f36-9d95-47e9-b2f0-7e16670af125',
                    notForTagging: false,
                    label: 'opera glasses',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/39ad4d70-f2dd-42d9-88b7-670ec583016f',
                    notForTagging: false,
                    label: 'opportunist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0c6ce8e8-9831-40a3-a6c5-ee3eae2de927',
                    notForTagging: false,
                    label: 'opportunistic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2d6dac62-4c13-4fb0-82cb-307065b6d65c',
                    notForTagging: false,
                    label: 'opposer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f92a8afa-e4b3-4080-8361-327bdaabef9c',
                    notForTagging: false,
                    label: 'optimistic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bcc2820c-8b94-4a80-b918-1f3cbb388f4d',
                    notForTagging: false,
                    label: 'ornaments',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/262ed291-2413-4864-8f48-6b677ef7c304',
                    notForTagging: false,
                    label: 'ottoman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/28e4aa80-7315-4989-a4b2-48858e060424',
                    notForTagging: false,
                    label: 'out-of-body experience',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ff5440f1-be9d-4dd3-82ef-e7ee0340151a',
                    notForTagging: false,
                    label: 'outer space',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ad8a2963-88ce-48d0-a4f5-bbf78f5fdd10',
                    notForTagging: false,
                    label: 'outgoing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8a3b773e-7d84-4c6c-a7ad-70207b6feb7b',
                    notForTagging: false,
                    label: 'outsider',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/82c32b09-912a-4405-acdd-5e00fe956f77',
                    notForTagging: false,
                    label: 'overseer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b4a442cd-e0a1-4f92-9490-0fa17159865e',
                    notForTagging: false,
                    label: 'overstatement',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ab1cb5df-a9b6-46ef-bfea-a5ac5063526b',
                    notForTagging: false,
                    label: 'package delivery person',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c472c7bc-c8af-40bd-81f4-b09d351ee1a7',
                    notForTagging: false,
                    label: 'palm reading',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3197c9c3-0112-48a9-ad00-236012d471cb',
                    notForTagging: false,
                    label: 'paper cut-out',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c804b19e-6af1-4b62-942f-bd6305a2a5ef',
                    notForTagging: false,
                    label: 'paper money',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/33417f46-b3ce-4273-8987-f50a93762967',
                    notForTagging: false,
                    label: 'paramedic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3d12d17d-f000-4e91-8d9d-ed55592e0fb5',
                    notForTagging: false,
                    label: 'paranoid',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/08ad415d-6794-4990-8a23-2e3534e6a76c',
                    notForTagging: false,
                    label: 'parental',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/67a9b766-a47c-4c7d-b282-c3d044ab8a4f',
                    notForTagging: false,
                    label: 'parental favoritism',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3dc08b7c-ca61-4d75-b82a-c338654af585',
                    notForTagging: false,
                    label: 'passionate',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6b990598-9fc6-4256-9fd1-b48bb37d046c',
                    notForTagging: false,
                    label: 'patriotic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c427c13c-eaec-4586-b63e-5a6904749879',
                    notForTagging: false,
                    label: 'patron',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ae2cf2d2-4529-4332-85f6-644484932397',
                    notForTagging: false,
                    label: 'peeking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9b0de93c-f83c-49f6-b990-7ba949a59d32',
                    notForTagging: false,
                    label: 'peer approval',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b169eb04-d69d-4fd7-bfbc-0d08907e8abc',
                    notForTagging: false,
                    label: 'perky',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0ee18901-2f86-4b4c-8ab3-51d96d4f0f57',
                    notForTagging: false,
                    label: 'pessimist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/23b227d9-d80b-429f-b8e0-962320fcc37a',
                    notForTagging: false,
                    label: 'petrol',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8b1ffcf8-596a-468e-9eda-5ce44e061e8f',
                    notForTagging: false,
                    label: 'petsitting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a2773208-c6c2-4167-9b8f-531e2ba2ecc7',
                    notForTagging: false,
                    label: 'phone call',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d85a49fc-d339-4c9f-b1e4-d0857eea24d3',
                    notForTagging: false,
                    label: 'phonograph',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/556fc8f3-a4fe-413d-8bfd-2f9ade5d22df',
                    notForTagging: false,
                    label: 'photo frame',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8b109274-d627-47c8-b737-cd1524d03ab7',
                    notForTagging: false,
                    label: 'physical humor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/80aa759c-a7c2-425c-9545-b68a7a3570cf',
                    notForTagging: false,
                    label: 'pigs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/174ccc78-7706-4231-862a-f7e4e8ae7d7f',
                    notForTagging: false,
                    label: 'pious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/40352625-786b-421f-a5ea-15407137d436',
                    notForTagging: false,
                    label: 'pistol',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/31fa5dfb-52de-43e5-9715-f5cff3594767',
                    notForTagging: false,
                    label: 'plane',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7315ba12-0415-4809-9a91-ef066769becc',
                    notForTagging: false,
                    label: 'plane accident',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2ce430e2-1c28-4258-9592-669d17eb774a',
                    notForTagging: false,
                    label: 'plane crash',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/320fda8b-e8db-4696-ac4c-c64f8e818c24',
                    notForTagging: false,
                    label: 'playhouse',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dd6049bb-9a67-43b5-9fb5-9cf55a2bf1d1',
                    notForTagging: false,
                    label: 'pleading',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c1118897-f743-4526-9a0d-ada3989134ba',
                    notForTagging: false,
                    label: 'plush toy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4cc9f481-cc2b-415a-8f4b-88cca41b86df',
                    notForTagging: false,
                    label: 'podcaster',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/74aeeb03-ff85-42d9-b252-27378df6230b',
                    notForTagging: false,
                    label: 'poker',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a1e6e477-4caf-4c1c-931c-08afa8c27544',
                    notForTagging: false,
                    label: 'police officers',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/108958df-9985-4704-819d-c6821a51553e',
                    notForTagging: false,
                    label: 'police pursuit',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/79333b89-3687-44ed-a130-a03600f08632',
                    notForTagging: false,
                    label: 'police woman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d6d56be2-3fc6-484d-b6de-bd1d3d30e658',
                    notForTagging: false,
                    label: 'policeman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2909f34a-6df7-40fe-960c-8454f98ef594',
                    notForTagging: false,
                    label: 'polished',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/56ced6eb-d1b4-43e0-8c33-2dde641e4218',
                    notForTagging: false,
                    label: 'pompous',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c059c118-d50d-4498-8716-1753ed8fa4cd',
                    notForTagging: false,
                    label: 'pool deck',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c1c4b256-b49f-4480-8610-6234e90c3ec3',
                    notForTagging: false,
                    label: 'popular',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fcef1f0b-df2e-40ec-9f52-c317ece4a33f',
                    notForTagging: false,
                    label: 'pornography',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/efbbc017-7953-4c50-8e3b-209fede8b4a4',
                    notForTagging: false,
                    label: 'port-a-potty',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7f5e6b1c-6070-4a0a-9322-c204a077ef1e',
                    notForTagging: false,
                    label: 'portable toilet',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7577905b-cc68-4925-9239-b940a951408f',
                    notForTagging: false,
                    label: 'ports of call',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/af12ed23-2235-49af-bde4-b674ecad0d93',
                    notForTagging: false,
                    label: 'positive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d7c86829-019c-42c6-b11f-8177f74ecb73',
                    notForTagging: false,
                    label: 'postal worker',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/91d9fa3e-2dc0-44d8-a761-1e059e39fab9',
                    notForTagging: false,
                    label: 'pot farming',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f39f771f-8477-4b8c-acdf-ac3d48b46db3',
                    notForTagging: false,
                    label: 'potted plant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/23e4e959-4fba-48d5-ad13-a6ff530b9297',
                    notForTagging: false,
                    label: 'power boat',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a773d364-2a10-418c-8c1e-8d0955a7dae8',
                    notForTagging: false,
                    label: 'power outage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/45a1e884-4f09-43ba-98e5-c5382bf0151e',
                    notForTagging: false,
                    label: 'pranks',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0211d4bb-38d2-4bbb-a1f6-3bf69a19702c',
                    notForTagging: false,
                    label: 'prankster',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/31693701-971f-4787-8306-700df807cf9f',
                    notForTagging: false,
                    label: 'pre-schoolers',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6e79715b-0de7-4f8b-aded-e102ea549502',
                    notForTagging: false,
                    label: 'predictability',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/42eb5698-dc02-4c5a-9ea0-f09f4acc8a10',
                    notForTagging: false,
                    label: 'pregnant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8f003ee8-a66d-4268-a77b-4917ff72ced1',
                    notForTagging: false,
                    label: 'present',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/08b0dee3-02ec-4d68-9122-edb0d5916ea9',
                    notForTagging: false,
                    label: 'presenter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5e2bb0e4-2756-4cff-be4f-d97e8e3b0b81',
                    notForTagging: false,
                    label: 'presidential inauguration',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c645ae5e-9833-4841-9f02-a6c4c3b38201',
                    notForTagging: false,
                    label: 'preteens',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/550cc1ae-2080-4215-bd87-52b05b3c85b3',
                    notForTagging: false,
                    label: 'pretender',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/892f452b-bbf2-4415-8146-ad1e2c83fd17',
                    notForTagging: false,
                    label: 'principal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0698b077-0de1-4eb8-bcb6-e03c96ed7c9f',
                    notForTagging: false,
                    label: 'private investigation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b0cad765-c98b-4713-a48e-ba1daf85367a',
                    notForTagging: false,
                    label: 'private investigator',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a3f8f6f2-8d04-4b70-b381-136a2f33fa7a',
                    notForTagging: false,
                    label: 'production crew',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/887c7861-befc-4304-a13d-25e45d7fb206',
                    notForTagging: false,
                    label: 'professor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/167b2fbb-7e38-4036-92dc-89ce8def821d',
                    notForTagging: false,
                    label: 'protagonist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/09c3eb25-d996-41a6-aae2-de9a056356c1',
                    notForTagging: false,
                    label: 'protective',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/849aba3c-de19-4ed1-b157-07afd8bac3ff',
                    notForTagging: false,
                    label: 'protest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d2b9503e-7b95-484d-bc20-9c941c861570',
                    notForTagging: false,
                    label: 'psychiatrist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/47c23824-4346-41bc-b687-ac71af71dc12',
                    notForTagging: false,
                    label: 'psychologist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/63f7f305-cb27-4683-84af-913a62e8f185',
                    notForTagging: false,
                    label: 'psychotic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1d7bab17-9dce-4206-be1b-1d2602f5b9eb',
                    notForTagging: false,
                    label: 'ptomaine poisoning',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4369c698-dd6c-444c-b8c3-cffe119aaa36',
                    notForTagging: false,
                    label: 'pub',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/95e4eea1-15ef-4391-9e62-2c22172eb8f1',
                    notForTagging: false,
                    label: 'public servant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7effc87d-83bd-4e04-bcb4-a715085c2958',
                    notForTagging: false,
                    label: 'puns',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/759659bf-1b11-46da-94c4-4106cbba6425',
                    notForTagging: false,
                    label: 'puppies',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a4b462b4-b464-4a8f-9d38-f819190d34b3',
                    notForTagging: false,
                    label: 'purposeful',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/59179cfd-a125-4b5e-89fa-f90d5fa521d3',
                    notForTagging: false,
                    label: 'put-down',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/730a44b9-fc15-4cb9-bc23-1960f0ecf007',
                    notForTagging: false,
                    label: 'quarreling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a4e1becc-7ab8-40d6-a0ef-d34396225458',
                    notForTagging: false,
                    label: 'questioning',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/243c0e43-bb7d-410f-b0fe-8682a5deaebf',
                    notForTagging: false,
                    label: 'quitting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7ddae32a-adad-40dd-b4cd-799e0a9d8e9b',
                    notForTagging: false,
                    label: 'racetrack',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/91c2dec7-0abe-429a-b5c9-a66b62410da5',
                    notForTagging: false,
                    label: 'raidation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b1d4d343-705f-4126-b1f2-036e3fe1e67a',
                    notForTagging: false,
                    label: 'rail station',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7b2b7bb8-a10a-4897-bfc6-ea54641d9a24',
                    notForTagging: false,
                    label: 'rams',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c9d102f9-07bc-40c3-9a29-28140a98a9f3',
                    notForTagging: false,
                    label: 'ransom',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d70993f7-7d81-42ba-93fc-15ad187a7a09',
                    notForTagging: false,
                    label: 'raping',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5ac794ea-bd4d-4a6d-a133-e988185d31a9',
                    notForTagging: false,
                    label: 'rapper',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6d22d615-ad81-4664-a569-788ad1135ab4',
                    notForTagging: false,
                    label: 'rational',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e3809a0d-0756-4bdf-b9a8-d9fdc094dacd',
                    notForTagging: false,
                    label: 'reaction shot',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/103115ee-e210-457f-91ee-763e44e3b0c9',
                    notForTagging: false,
                    label: 'realtor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6fbda0fd-1997-4092-9388-efc343b8fa86',
                    notForTagging: false,
                    label: 'rebirth',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1fffe6ea-e594-4098-b8db-f796820d0016',
                    notForTagging: false,
                    label: 'rec center',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c091b813-d841-4eeb-96cd-b581ec7afebb',
                    notForTagging: false,
                    label: 'rec room',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b495aed8-0d54-496c-83f0-ab3e328f69b0',
                    notForTagging: false,
                    label: 'reconstructive surgery',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2700f4d1-add5-415a-a596-658f1b3fa9a1',
                    notForTagging: false,
                    label: 'record player',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/94c14f20-996c-4963-bc72-725853b67735',
                    notForTagging: false,
                    label: 'recurrence',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fc430ba9-6974-42ea-9a3c-36b081ae3bf5',
                    notForTagging: false,
                    label: 'refined',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7db889c4-5c2f-4b81-aa52-9850c1d8712a',
                    notForTagging: false,
                    label: 'reflective',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9cfc70fe-b609-4b67-8d2f-158da4d7e384',
                    notForTagging: false,
                    label: 'reformed lothario',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/81caf573-5a89-4be5-baec-7b5cdba144df',
                    notForTagging: false,
                    label: 'regression',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dcaa8ddc-937e-4296-a33f-0cdd6529a4b3',
                    notForTagging: false,
                    label: 'relaxed',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/28b6c19e-c0a2-486a-a4f2-5dcaad2c1da3',
                    notForTagging: false,
                    label: 'religious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c69590b7-526d-46a3-a101-f3efe7b5455c',
                    notForTagging: false,
                    label: 'religious ceremony',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ccb34764-15c8-4ddf-9071-b6ab68fbc0bf',
                    notForTagging: false,
                    label: 'relocation camp',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fe85a373-ea6b-409d-9f8b-2c3a2eab0912',
                    notForTagging: false,
                    label: 'renown',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/05dbcf52-586c-4563-bcbd-469f3504834c',
                    notForTagging: false,
                    label: 'reprimand',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/551f59ac-51ef-4734-a8f4-483a72e59868',
                    notForTagging: false,
                    label: 'residence hall',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d4e450c8-4a44-426f-8314-d41559e28161',
                    notForTagging: false,
                    label: 'resolute',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ff1724c8-a5f3-4ded-854a-f2fea819ace3',
                    notForTagging: false,
                    label: 'responsible',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5a30dd75-fffe-44f8-a3fd-1e3584bf58b4',
                    notForTagging: false,
                    label: 'restaurateur',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1f341753-4676-4c2d-a861-c48307a9c85b',
                    notForTagging: false,
                    label: 'restroom',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d3cd1bf1-4b83-4111-84a2-1aac727289dc',
                    notForTagging: false,
                    label: 'retail trade',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5b0819fd-5ed0-4ce4-9ad4-6e231a08164b',
                    notForTagging: false,
                    label: 'retired people',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/554f7669-a826-486d-bd27-35939ace5e54',
                    notForTagging: false,
                    label: 'revolver',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/69b5ba33-29b4-4567-829c-12134b4c85f5',
                    notForTagging: false,
                    label: 'reward',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/65d83239-b3ee-4107-9672-59f82b2c1198',
                    notForTagging: false,
                    label: 'riches',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2385de1b-b054-4e34-b6e8-aca013103f6b',
                    notForTagging: false,
                    label: 'ride sharing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/32ed95c1-0c6f-4c80-abf0-84a83edcfbf4',
                    notForTagging: false,
                    label: 'rigid',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/464a1161-dc73-45a6-90c0-685d2601bef6',
                    notForTagging: false,
                    label: 'rite',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0bc449e5-f1f6-4196-b435-11cbf672f6cc',
                    notForTagging: false,
                    label: 'ritual',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9d3942b9-4aee-4b6d-9f30-d0a1685ccf21',
                    notForTagging: false,
                    label: 'rival',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d0baf6a9-b47e-4e91-839c-7b43365c8aab',
                    notForTagging: false,
                    label: 'roadblock',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/351bbf30-e780-4416-9e52-76ddba7fb912',
                    notForTagging: false,
                    label: 'roadside attraction',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5ba7bc48-6388-4132-8098-dcfe4a2ae8ff',
                    notForTagging: false,
                    label: 'rock climbing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b8a4343e-305a-4cbe-8b87-8dd52012ae96',
                    notForTagging: false,
                    label: 'roller blading',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/354204a7-d1af-41bf-8bea-0ee217bea9cd',
                    notForTagging: false,
                    label: 'rollerblades',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ff53d2f4-e88b-42c4-bbb5-ff62d9f2df2a',
                    notForTagging: false,
                    label: 'rolling eyes',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c978f9a7-a42b-47e0-88b1-8d33a29c53fb',
                    notForTagging: false,
                    label: 'romance',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e6b3e648-788e-4a0c-af49-26b09c3a3dfb',
                    notForTagging: false,
                    label: 'royalty',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2e652244-b157-40d4-ba09-dcfa69a9e849',
                    notForTagging: false,
                    label: 'ruler',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/98c59480-3ac2-472c-9ff1-c2a74abee4f4',
                    notForTagging: false,
                    label: 'rummage sale',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8db1e88b-bfbf-46d5-affd-f2348a3a3872',
                    notForTagging: false,
                    label: 'runaway',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/52461157-25e7-4cd9-8178-fb002ff1d979',
                    notForTagging: false,
                    label: 'runway',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2d5269d3-cd07-4da6-bec1-0186c1d8e92a',
                    notForTagging: false,
                    label: 'sack',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/876910bb-0728-4cc5-9ae8-7d853b26ac0b',
                    notForTagging: false,
                    label: 'sagacious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e875925a-1d88-47d2-a897-226fb589eb0d',
                    notForTagging: false,
                    label: 'sage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5aac57d4-1320-42f9-ac0f-866d238987d8',
                    notForTagging: false,
                    label: 'sales',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7759538e-f7e5-4ee9-8a60-f91017794331',
                    notForTagging: false,
                    label: 'salmonella',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d57ab250-9ea6-44ef-8640-a54afd3b2dbc',
                    notForTagging: false,
                    label: 'salon',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5225dc6f-a18e-41fc-893f-d44bc72f0dac',
                    notForTagging: false,
                    label: 'salsa',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1cc0df42-2311-4531-bd8e-be3e2aec5173',
                    notForTagging: false,
                    label: 'salutation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6ceebdbe-e9c2-4b23-8cea-0ec6cb6e52c9',
                    notForTagging: false,
                    label: 'sarcasm',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/44ed7667-ccbd-4e7f-85a6-6bd9401c9494',
                    notForTagging: false,
                    label: 'sarcastic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d18f2e01-7a12-4a6f-9670-e35776c7ba51',
                    notForTagging: false,
                    label: 'saying hello',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bf59440a-050a-45db-aa34-53eb8c95dd1d',
                    notForTagging: false,
                    label: 'scams',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c6ad6c57-ad10-4811-b61f-56ac2d54b7ed',
                    notForTagging: false,
                    label: 'scandal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4da1bfdd-bad3-4b63-be67-4ed82831ed1a',
                    notForTagging: false,
                    label: 'scatalogical',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3b245a8-6612-4192-a938-3036ed36302c',
                    notForTagging: false,
                    label: 'scheming',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e994ca6e-5dec-40f2-aa97-716aec216ff2',
                    notForTagging: false,
                    label: 'sci-fi',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c0663aee-6177-47ba-9e55-48e89c9be748',
                    notForTagging: false,
                    label: 'scooter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e641494f-c7de-4dfa-98c9-f35b6131a82f',
                    notForTagging: false,
                    label: 'scouts',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1c350932-0f29-4a33-b121-46a506da56ce',
                    notForTagging: false,
                    label: 'screen test',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2b17e5f1-6faa-48ba-bf6c-d1ce56888f70',
                    notForTagging: false,
                    label: 'screwball comedy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f04a5831-fad2-45e1-a4ba-9301a1d1dcb0',
                    notForTagging: false,
                    label: 'scuba mask',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2b132b1c-0080-44be-b326-833e0079819b',
                    notForTagging: false,
                    label: 'secretary',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/deee112f-7324-41b6-9935-085f9eb678cc',
                    notForTagging: false,
                    label: 'seducer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/85bdd2ec-0810-4f3f-ade6-f5015c2166b6',
                    notForTagging: false,
                    label: 'seductress',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/88c6c46d-a173-480f-bd36-9fb354398062',
                    notForTagging: false,
                    label: 'seeking approval',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c2046dcb-586b-4f1a-b07c-69715ed30a8a',
                    notForTagging: false,
                    label: 'seer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8263c851-332d-4a12-81ef-ee2b86e1d8f8',
                    notForTagging: false,
                    label: 'self-centered',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d9d90107-0d6d-4c24-8266-6b60a9ee2356',
                    notForTagging: false,
                    label: 'self-conscious',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4d396eb5-b614-43fe-a3fd-1bf1dc1496eb',
                    notForTagging: false,
                    label: 'self-indulgent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ae1e60d1-af66-448b-ae2e-9eda288f9ff1',
                    notForTagging: false,
                    label: 'self-sufficient',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7d8a4536-2db8-434d-a8b6-749ff2b27a33',
                    notForTagging: false,
                    label: 'senior citizens',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4675fabc-0244-4018-9ad3-3c5cc14c4fca',
                    notForTagging: false,
                    label: 'seniors',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3aed5ebc-5519-4fe3-b0fd-392fd29aeda3',
                    notForTagging: false,
                    label: 'sentences (criminal)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f674159e-82e1-4cd9-97f3-5be61c7a775e',
                    notForTagging: false,
                    label: 'serene',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/adcd046d-d00e-4ffd-bb53-702672e50582',
                    notForTagging: false,
                    label: 'serpent',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3c0a925-0e6a-4a8f-b342-053d48a9d37b',
                    notForTagging: false,
                    label: 'service animal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6ad97d73-82bd-4809-963b-61150ce161b5',
                    notForTagging: false,
                    label: 'severe',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bc310e2e-3abf-494b-be47-f47148dc4e09',
                    notForTagging: false,
                    label: 'sewage pipe',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bf36f3e3-b8f9-4f3b-8ba2-11478aae0544',
                    notForTagging: false,
                    label: 'sexual intercourse',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9f930942-a313-4e79-9061-5444ac64526e',
                    notForTagging: false,
                    label: 'shack',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a084dcc0-8284-4e6a-8cb6-e58ce4ffaf32',
                    notForTagging: false,
                    label: 'shaking hands',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8d8f5430-fd95-4bbb-85c9-f938e458b19a',
                    notForTagging: false,
                    label: 'shantytown',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/01ef80a9-0f84-49e7-8d73-34fc16fdcfcf',
                    notForTagging: false,
                    label: 'sheeting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5b17ac54-9d1b-4539-a32b-3dbd666fcd0b',
                    notForTagging: false,
                    label: 'sheets',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/55063339-a6c8-429e-b76b-d8f8f4a229e1',
                    notForTagging: false,
                    label: 'sheriff station',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a40fc2c6-372b-41b2-83f5-caaf40974a46',
                    notForTagging: false,
                    label: "ship's bridge",
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1efb45bd-dab2-4a18-8853-91d600649e0b',
                    notForTagging: false,
                    label: 'shooting star',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f8a2ff1b-e912-4c01-ba02-82122f080361',
                    notForTagging: false,
                    label: 'show runner',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/143322d7-8ffd-4322-a464-c82ebb70acc9',
                    notForTagging: false,
                    label: 'showering',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a7345889-4b63-436c-852f-dd36986b860a',
                    notForTagging: false,
                    label: 'shrews',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2947c80c-5d09-42f3-a1be-440a1052d23e',
                    notForTagging: false,
                    label: 'shushing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a6f9f3b3-46e5-4bbf-825f-bf9d378292fb',
                    notForTagging: false,
                    label: 'shy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2f488d75-8b77-4177-8321-ddc5631d71a7',
                    notForTagging: false,
                    label: 'simple-minded',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c6a49f72-31a1-480e-b068-5179733644c5',
                    notForTagging: false,
                    label: 'sisterly',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fad6792e-212e-4d53-bccb-3de08467b993',
                    notForTagging: false,
                    label: 'sitcom',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1a11cf4e-b598-4915-b9fa-bc803407ed12',
                    notForTagging: false,
                    label: 'skeptic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8654558d-bbb1-4090-91e4-7541d9886c4c',
                    notForTagging: false,
                    label: 'sketch',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/92bec631-3726-4c79-aff1-75409159c795',
                    notForTagging: false,
                    label: 'skillet',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f64b0c46-5f7b-4d63-afb8-7720ee311c3e',
                    notForTagging: false,
                    label: 'skull and crossbones',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/08c3f858-266f-4423-a8d4-57bd0bee3e50',
                    notForTagging: false,
                    label: 'slasher',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/861003f7-0455-48a9-87e6-9a38f47cb558',
                    notForTagging: false,
                    label: 'slashing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4d0a024c-826b-42da-92c6-987daf864a99',
                    notForTagging: false,
                    label: 'sloth',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c8fc878b-4c6e-4426-9f34-ac2abde7fa08',
                    notForTagging: false,
                    label: 'slow-witted',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0b9064ef-7572-42db-ae55-0ca5ac4e7603',
                    notForTagging: false,
                    label: 'smart',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/95181d4f-bf41-44b8-a776-d3751a67f2bb',
                    notForTagging: false,
                    label: 'smithy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/38c10917-a472-490e-ab70-304f7281469b',
                    notForTagging: false,
                    label: 'smooching',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f9de577f-b43a-44c4-8f7f-3e5ca708f637',
                    notForTagging: false,
                    label: 'snowdome',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/553f2e98-d0cc-4864-9a4a-246c9058f6ef',
                    notForTagging: false,
                    label: 'snuff',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ed698e8a-0d60-4fdc-889e-28e1d6b159c5',
                    notForTagging: false,
                    label: 'snuggling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fc8ee8f7-5b40-4b51-8e8c-8434d4fbd1b4',
                    notForTagging: false,
                    label: 'sober',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1254053d-cf17-4b51-8524-48e7e4eb431e',
                    notForTagging: false,
                    label: 'sociable',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f9dff152-c7a9-431e-a812-6296fb65f4d9',
                    notForTagging: false,
                    label: 'social recognition',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/44c10413-42bb-4a62-9c30-a4983d76190a',
                    notForTagging: false,
                    label: 'soda',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/13ebb0ce-8343-43ad-b0a4-62b8f8ab0a9a',
                    notForTagging: false,
                    label: 'software development',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e3543e35-6185-4079-8f7b-0f84a5f55a4c',
                    notForTagging: false,
                    label: 'solar eclipse',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d17e2c3a-51c2-4687-ac16-12f57f462a5a',
                    notForTagging: false,
                    label: 'songwriter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/199cd4b3-a8ee-4b6b-a6d8-7fbe646d3bf2',
                    notForTagging: false,
                    label: 'sorcerer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ccbdb664-328e-4eed-9900-14f3149c2a6a',
                    notForTagging: false,
                    label: 'sorcery',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cfac96c8-666d-4c23-a532-71c77bfdbd42',
                    notForTagging: false,
                    label: 'sorrow',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2af6004f-735e-4a21-b61d-ca3f5e85119f',
                    notForTagging: false,
                    label: 'souk',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/299e78bb-8ba3-4ebb-a6da-62f6c863fc0f',
                    notForTagging: false,
                    label: 'spacecraft',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/db75a920-c0b8-42bc-b0d8-d6a0870862a7',
                    notForTagging: false,
                    label: 'spaceman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/21627d47-44bc-4a42-819a-822ac23c44d9',
                    notForTagging: false,
                    label: 'spaceship',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3235a3c2-6223-4308-a1aa-7805c9a0f766',
                    notForTagging: false,
                    label: 'speakers',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3cf0f40a-ac6d-45fe-98fb-0a96e4170cab',
                    notForTagging: false,
                    label: 'spectacles',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f4942c08-ac89-4c64-8d56-3cad35527bad',
                    notForTagging: false,
                    label: 'spells',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9066f80d-0691-4ad9-83e2-9778619bb108',
                    notForTagging: false,
                    label: 'spiders',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/efc64412-0898-4529-9bbd-bb81f77f53c8',
                    notForTagging: false,
                    label: 'spineless',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f2a21120-a94e-4c13-a7c8-dccf5336f293',
                    notForTagging: false,
                    label: 'spoof',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/00ae4513-9086-4580-9d90-00ba6b1c3165',
                    notForTagging: false,
                    label: 'spooning',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/297033fc-08c8-4471-b421-f1d50a88b3bd',
                    notForTagging: false,
                    label: 'sport',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3eef6544-2e72-4179-9686-21a51b57c371',
                    notForTagging: false,
                    label: 'spousal abuse',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2b0a2f3c-500a-457f-98e9-2c6c7a5e99fb',
                    notForTagging: false,
                    label: 'spurned lover',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/250e63d3-31c9-4f99-9d2f-c4e36d83ca8b',
                    notForTagging: false,
                    label: 'spy (genre)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3c804593-8b17-423d-be22-c70afb82274e',
                    notForTagging: false,
                    label: 'staff lounge',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bee8add8-8417-4807-9550-30e2bcefdff3',
                    notForTagging: false,
                    label: 'stage scenery',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8ade8244-362a-4ac1-842b-6cf32c04f5d7',
                    notForTagging: false,
                    label: 'staircase',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4dac00cd-7ac0-4f96-b3e6-f57335e6a1d8',
                    notForTagging: false,
                    label: 'stairway',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5c1be3ac-e7bf-477c-bfac-a361a4afab31',
                    notForTagging: false,
                    label: 'standoff',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/36875883-f883-4261-aeeb-5e9da45ec791',
                    notForTagging: false,
                    label: 'state trooper',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a288e701-ef2b-4015-b51a-32ef52c99dbb',
                    notForTagging: false,
                    label: 'statue',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b038ce44-06a8-4abf-8d1d-aab68746ea64',
                    notForTagging: false,
                    label: 'statue desecration',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6a5b69a6-e3d8-4f91-a268-d918b02752f8',
                    notForTagging: false,
                    label: 'stealing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c5656d56-a3a9-4bec-a985-bd74e12abb88',
                    notForTagging: false,
                    label: 'stepladder',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/95cda78e-75bc-47d1-92b5-80917b0f1602',
                    notForTagging: false,
                    label: 'stereo receivers',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1ad739da-8f99-4b24-88d4-075e6a4852d7',
                    notForTagging: false,
                    label: 'stereo speakers',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3fde08aa-52a1-4711-b85a-02b637a6d614',
                    notForTagging: false,
                    label: 'stinger',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2dd34e74-82bc-47b9-910e-81e4d10f8d6d',
                    notForTagging: false,
                    label: 'stingray',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/93c3c433-7cc7-44c9-85ff-10c850f84933',
                    notForTagging: false,
                    label: 'stingy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ee1c18cf-98cf-421e-84fa-08b1eed7f870',
                    notForTagging: false,
                    label: 'stock exchange',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a3dba4f0-8a5e-43d4-b43b-006b673bf29f',
                    notForTagging: false,
                    label: 'stop signs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/37ad7438-0f9b-4853-afb8-c8eec1bf0f17',
                    notForTagging: false,
                    label: 'strangling',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5ecafe66-db15-45ab-9977-8186a13fb3b0',
                    notForTagging: false,
                    label: 'strategic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6359526f-9b59-4b0f-8c70-06769ca05fc8',
                    notForTagging: false,
                    label: 'street lamp',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ece3cb12-b42a-41a9-af68-76a8f8f468f1',
                    notForTagging: false,
                    label: 'street racing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4538a467-6df8-4ee3-b6bb-ece9bbc7c295',
                    notForTagging: false,
                    label: 'street signs',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/19c5e064-e937-431d-ac36-9c6cb98e4d64',
                    notForTagging: false,
                    label: 'streetlamp',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d3d8230a-eb50-4144-ad1e-2d08ffad76b0',
                    notForTagging: false,
                    label: 'streetlight',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3e29a4b-0d12-4cbf-a0a4-d3f8e3fc39df',
                    notForTagging: false,
                    label: 'strict',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/95f30d25-f4a6-4cb9-8f83-1a545a359afd',
                    notForTagging: false,
                    label: 'studio lot',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3761a42e-60f0-42da-9f3e-c5db34ebdffa',
                    notForTagging: false,
                    label: 'stuffed animal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bcd50d7d-db9b-4857-9bee-8a39bd80dc88',
                    notForTagging: false,
                    label: 'stupid',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/de588b2a-b9be-4574-bb39-fb9f47cb47de',
                    notForTagging: false,
                    label: 'subway crime',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dcc80dc3-3a47-459a-95fb-ad60746b53b1',
                    notForTagging: false,
                    label: 'sun deck',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/59446406-d6b0-4c1d-984f-6f1d3e00f961',
                    notForTagging: false,
                    label: 'suntan',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4bc4b9d4-f4c6-4dfe-a1eb-9c0b98aac0b8',
                    notForTagging: false,
                    label: 'supportive',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/73e18488-5479-4d46-a5fb-e8f0538f2b3a',
                    notForTagging: false,
                    label: 'surgery prep room',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/84de8395-741c-4eac-b444-a463c324844d',
                    notForTagging: false,
                    label: 'suspense',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9c33c7e1-1fbc-4f7a-9af4-d8fc62b139ac',
                    notForTagging: false,
                    label: 'swap meet',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/67a1d8c1-b98f-4426-bc2b-66552ddbfc9c',
                    notForTagging: false,
                    label: 'sweepstakes',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/edf65c9b-e124-482e-9c51-3500fcde0114',
                    notForTagging: false,
                    label: 'sword fighting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2737c82b-02be-452f-900c-38527593534a',
                    notForTagging: false,
                    label: 'swordfighting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6178c0a5-cb5e-46b1-b677-e9f9a488f370',
                    notForTagging: false,
                    label: 'symbiotic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/59bb70e8-c67c-4d55-96a2-005ce44aacb3',
                    notForTagging: false,
                    label: 'sympathetic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7c70b6c8-121e-40d5-81ac-8b07d953b160',
                    notForTagging: false,
                    label: 'tag sales',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8822f147-ef8c-4729-b034-27c9fa8cbe95',
                    notForTagging: false,
                    label: 'talking on the phone',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e5ab5a02-f425-4841-899c-e2a85cd38bf7',
                    notForTagging: false,
                    label: 'tanning',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/545f4085-1d68-40e5-b3a8-71d8a598868e',
                    notForTagging: false,
                    label: 'tavern',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3f5c2951-e719-4aaa-82a2-8ca444900b91',
                    notForTagging: false,
                    label: 'taxi driver',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1424ad70-b1a1-410c-9360-9e65ae01c0f2',
                    notForTagging: false,
                    label: 'teen',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/77e5a063-c92a-4099-84ba-d5f26ac72220',
                    notForTagging: false,
                    label: 'teens',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bd2bbc7d-ed08-4973-a003-97a36abb9561',
                    notForTagging: false,
                    label: 'teeter totter',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ab4509a1-24d1-414c-bdf3-e82c11759664',
                    notForTagging: false,
                    label: 'television industry',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/df3f4f5d-7d80-42fb-8b09-14314f4c8ea4',
                    notForTagging: false,
                    label: 'temptress',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/78316773-6834-4f81-96f1-618e09707958',
                    notForTagging: false,
                    label: 'terror',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d407bb1b-36d0-4e41-972e-8b4275e80010',
                    notForTagging: false,
                    label: 'text messaging',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3d38bf85-d47d-4c25-b8bf-9f3f7749ce35',
                    notForTagging: false,
                    label: 'textile',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/09369445-b9a2-4297-8c33-634a625866eb',
                    notForTagging: false,
                    label: 'theft',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8bd63dc7-fc70-4408-a80b-84ac2d690d8b',
                    notForTagging: false,
                    label: 'therapist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c1cfa064-032c-4f2b-87eb-aa2316634f6b',
                    notForTagging: false,
                    label: 'therapy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/21a7065a-7681-42aa-8c9a-6df34824d3ac',
                    notForTagging: false,
                    label: 'thoughtful',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/03af5a38-9665-4c78-be2c-2f8e69719bba',
                    notForTagging: false,
                    label: 'threaten',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/82253c50-90c6-4a0d-9f2f-2c92f4e6f785',
                    notForTagging: false,
                    label: 'throwing up',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bb2e55ee-707b-4fb0-9638-4df0234c2e41',
                    notForTagging: false,
                    label: 'thumb drives',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/da94c3da-018b-49aa-8015-a828868878cb',
                    notForTagging: false,
                    label: 'thumbs down',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dfa68a6a-a716-41df-ae8d-4cc5e4fecc7e',
                    notForTagging: false,
                    label: 'thunderbolts',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1b4b941b-020e-403f-8846-561fe7f19c51',
                    notForTagging: false,
                    label: 'ticks',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/14ae52a7-2886-41aa-a0dd-c78e04e16f0c',
                    notForTagging: false,
                    label: 'tigers',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/561fa228-a99b-49e6-8821-a1f144ec9f0f',
                    notForTagging: false,
                    label: 'timid',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3c817d6e-9d34-47bf-b0ba-c5b89f5ceff4',
                    notForTagging: false,
                    label: 'tin soldier',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/98526eda-c5ed-4064-8f7b-1145be8c350a',
                    notForTagging: false,
                    label: 'tiredness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e5ed4e39-835b-4c59-814f-169dcb912b97',
                    notForTagging: false,
                    label: 'toad',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ed9d1d7f-cd75-45ea-82d7-c0b8fa5c0ec7',
                    notForTagging: false,
                    label: 'toreador',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8db3e109-03ae-4321-b4a2-3df0c6c9756d',
                    notForTagging: false,
                    label: 'torturing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ae6d167c-533b-4afa-b4ed-094a44ba587c',
                    notForTagging: false,
                    label: 'tour guides',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3542ae57-449e-4fd9-a850-d44ac4d5c14f',
                    notForTagging: false,
                    label: 'tourism',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/91aecb23-d186-44c2-9c69-2713bd85a2d0',
                    notForTagging: false,
                    label: 'tourist attraction',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a631d403-7cc3-4c06-b3e7-f46097839430',
                    notForTagging: false,
                    label: 'town square',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/95b3ec6a-d815-4921-a815-657030e43a2e',
                    notForTagging: false,
                    label: 'toy chest',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/42b7bc37-782a-4559-ac7b-b40d68d412cf',
                    notForTagging: false,
                    label: 'trade show',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/45403e6e-8423-45fb-82fb-058419efd6bb',
                    notForTagging: false,
                    label: 'traffic cop',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8a5a7f6d-f3ac-4d36-a439-b63f4c4c3ba3',
                    notForTagging: false,
                    label: 'traffic police',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/15356ecb-ac17-4822-bee9-d5e0ffadf485',
                    notForTagging: false,
                    label: 'traffic signal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f71085f8-4689-49f1-b649-126463f15077',
                    notForTagging: false,
                    label: 'trailer camp',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9f6181be-d2e5-4f35-87c2-e2eb917f754e',
                    notForTagging: false,
                    label: 'train car',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/73d9272a-51be-4de9-b97f-87755b84c06e',
                    notForTagging: false,
                    label: 'train station',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f8c0da67-b1a3-4c72-9bf9-48652e5fbab7',
                    notForTagging: false,
                    label: 'trash',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d52121e3-0c29-4f65-9fe1-6966f7542cf5',
                    notForTagging: false,
                    label: 'treating',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a8a0c8d5-5293-42bf-832b-9162b96b8f0c',
                    notForTagging: false,
                    label: 'triton',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9cbd136b-d888-4b4d-84d3-a6e0eb9c290b',
                    notForTagging: false,
                    label: 'trolley accident',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/53140a39-d906-4100-8d4a-4c47089cba8e',
                    notForTagging: false,
                    label: 'troop transport',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c40bc1af-062e-44ab-986e-840f8dcf90f2',
                    notForTagging: false,
                    label: 'troops',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/13d06056-30a9-48de-8df5-9a7961e9b10d',
                    notForTagging: false,
                    label: 'truck crash',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/850f1b10-fd96-4aae-b90f-ba79ab89a644',
                    notForTagging: false,
                    label: 'truck fire',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9ef258ad-8e9d-45c4-ac26-11e9c0fba0fd',
                    notForTagging: false,
                    label: 'trustworthy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/67cc8527-eb99-4cb7-8344-109bab1d2a0c',
                    notForTagging: false,
                    label: 'tryout',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e02fbc5e-b34c-4367-9f19-ae61b4efc136',
                    notForTagging: false,
                    label: 'tsunami',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2a764c2a-adf3-48a1-a53d-e4810439ef45',
                    notForTagging: false,
                    label: 'two-way radio',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ec506c64-91e5-4b19-a95e-a3f5aeab57a2',
                    notForTagging: false,
                    label: 'typewriting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3dc8f6b8-aa7d-41ab-b5b9-2c911ba2ee7f',
                    notForTagging: false,
                    label: 'uncool',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/eda61c80-c49c-49d1-a48c-a143731f6479',
                    notForTagging: false,
                    label: 'understanding',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4c8ac4ab-403d-40d5-8b5b-33f83c88dbeb',
                    notForTagging: false,
                    label: 'unfriendly',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ab9dfd4a-1d85-4384-a392-e73a9349a6b3',
                    notForTagging: false,
                    label: 'unhappiness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/09da429f-8d66-4289-97f7-55cf829b4c88',
                    notForTagging: false,
                    label: 'unhinged',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/889049ed-6a19-40af-9c57-b6fa26fbed2d',
                    notForTagging: false,
                    label: 'union',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9db76339-d9a8-4cb9-b14c-74d5a791bb70',
                    notForTagging: false,
                    label: 'unknown identity',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ffde3247-76a0-4470-b464-4b67c918dbdf',
                    notForTagging: false,
                    label: 'unsophisticated',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8057836b-9c8d-44f8-a3d9-9adf7ceb387b',
                    notForTagging: false,
                    label: 'use of force',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/26b0106c-a90e-4693-9f42-57aafd52eccf',
                    notForTagging: false,
                    label: 'validation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ac367504-3b81-44cc-b3d7-baf70b03894f',
                    notForTagging: false,
                    label: 'ventilation shaft',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d067e49c-0901-4bcf-aa43-78b521d36a0c',
                    notForTagging: false,
                    label: 'video log',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c699ff2b-30ad-4b61-ae85-4892d33f5457',
                    notForTagging: false,
                    label: 'visitor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/275e5029-6efd-4351-aeb3-163361af22fb',
                    notForTagging: false,
                    label: 'vlog',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/26a08df5-dae2-437b-b39d-c5611ffea48a',
                    notForTagging: false,
                    label: 'voluntarism',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/828011c4-6cdd-4272-86ce-ce5636ad8feb',
                    notForTagging: false,
                    label: 'wake',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/20a5a0ae-ade8-4f7d-a847-9f84a6f024fa',
                    notForTagging: false,
                    label: 'walk-in refrigerator',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/47f9a5c1-4432-4a9d-80ef-84cf25b3941d',
                    notForTagging: false,
                    label: 'wall climbing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/979d9646-881f-4b33-85dd-42d6c4faf972',
                    notForTagging: false,
                    label: 'warlock',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/15157456-1d6f-4116-a844-71c244f11745',
                    notForTagging: false,
                    label: 'warthog',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9802d695-c233-4461-a55b-93bed0aa37f1',
                    notForTagging: false,
                    label: 'washing',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6283e7b8-3c31-4c0d-8677-6c2eda474b8f',
                    notForTagging: false,
                    label: 'watch tower',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a06547ba-8c28-491a-ac2c-9f0c73502e12',
                    notForTagging: false,
                    label: 'watching TV',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7bd376b6-8903-41ce-8d1e-f56f0dd1787a',
                    notForTagging: false,
                    label: 'water spirits',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0e818d87-0e7a-42cc-9e1a-9a26867241e2',
                    notForTagging: false,
                    label: 'water sports',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/891c6aa9-7015-46e7-ac8e-dc5e16108f47',
                    notForTagging: false,
                    label: 'wayward child',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/012c3840-82b6-49f2-95c5-6843dabe85b6',
                    notForTagging: false,
                    label: 'weak-willed',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3c826de3-a9a2-44e0-b69d-52e4dc7de0b3',
                    notForTagging: false,
                    label: 'weariness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ce72dfe4-796f-4792-9b92-6239c5b86d50',
                    notForTagging: false,
                    label: 'weeping',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a8c09c0d-81aa-4e65-ba0d-69cefce782f0',
                    notForTagging: false,
                    label: 'well-being',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8dcabaa2-b98d-46a3-87d7-570b3b0ea47c',
                    notForTagging: false,
                    label: 'white space',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0c13df5a-36a9-4475-9c5b-a42a7dec382d',
                    notForTagging: false,
                    label: 'whorehouse',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/afbdff7a-0767-49bb-a316-4aa4a670b425',
                    notForTagging: false,
                    label: 'widower',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9f9edea7-0280-4601-b737-b8f056828520',
                    notForTagging: false,
                    label: 'wild boars',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b95618ba-e084-4825-9993-2704c69806e7',
                    notForTagging: false,
                    label: 'wild card',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/90918425-e322-49e9-9afd-099da4dd1f2a',
                    notForTagging: false,
                    label: 'window washer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cd475632-a3e0-4f2f-81ed-f5f59b2251c4',
                    notForTagging: false,
                    label: 'wise man',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1763fda5-0e4e-4255-ae35-ae4398590401',
                    notForTagging: false,
                    label: 'witchcraft',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/909f363e-c4fc-4203-98d1-e0dad2e13c6b',
                    notForTagging: false,
                    label: 'witty',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d6b872ff-2797-4653-8744-f076f862c472',
                    notForTagging: false,
                    label: 'wizardry',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3416c2bb-3e12-4938-a439-6a624b4267ec',
                    notForTagging: false,
                    label: 'wolfman',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/891b0238-f6c9-4d18-9b7b-dc3b5a8e5c69',
                    notForTagging: false,
                    label: 'woods',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1222a243-b334-4d94-b541-d41ccadef1ab',
                    notForTagging: false,
                    label: 'work stoppage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0026d89c-8faa-4203-bec4-16761265d37e',
                    notForTagging: false,
                    label: 'worker',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6d27a630-852d-4186-93b3-e9a1984f3b02',
                    notForTagging: false,
                    label: 'wounded',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/93847ef8-91a4-4414-a8e5-50d306ba3617',
                    notForTagging: false,
                    label: 'yaks',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8366ad31-b38f-4996-a841-7cee0bfb8422',
                    notForTagging: false,
                    label: 'yard sale',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ed4fcb31-a872-433c-af58-6f37423235aa',
                    notForTagging: false,
                    label: 'young animals',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6668e818-85e8-4d88-bb3c-ebddbdef3730',
                    notForTagging: false,
                    label: 'yurt',
                    terms: [],
                },
            ],
        },
        {
            label: 'Objects & Materials',
            type: 'http://data.disney.com/resources/ObjectsMaterials',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                    notForTagging: true,
                    label: 'Clothing',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/694d7066-8136-b80a-9ee8-4888ed36e098',
                            notForTagging: false,
                            label: 'sneakers',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a4457b99-bafb-001b-3605-1099f7be17b8',
                            notForTagging: false,
                            label: 'bathrobe',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/394b249e-60ea-bff3-8362-7b42cfc0e2bc',
                            notForTagging: false,
                            label: 'clothing',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3d4933d2-c280-a3a3-6564-78dbdf260188',
                            notForTagging: false,
                            label: 'overalls',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3e3a152b-4d1d-bcff-1b93-21a595cdf981',
                            notForTagging: false,
                            label: 'skirt',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/f6418330-85ac-1827-67b9-8feb99a347a4',
                                    notForTagging: false,
                                    label: 'kilt',
                                    broaderId:
                                        'http://data.disney.com/resources/3e3a152b-4d1d-bcff-1b93-21a595cdf981',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/00472a18-5b98-b115-1c73-5bb372bb96ad',
                                    notForTagging: false,
                                    label: 'hula skirt',
                                    broaderId:
                                        'http://data.disney.com/resources/3e3a152b-4d1d-bcff-1b93-21a595cdf981',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3342f91d-005a-9f06-e5ce-e44a68c77080',
                                    notForTagging: false,
                                    label: 'tutu',
                                    broaderId:
                                        'http://data.disney.com/resources/3e3a152b-4d1d-bcff-1b93-21a595cdf981',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                            notForTagging: true,
                            label: 'Clothing Accessories',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/1836497c-1ea0-58b6-9394-883024f6b89e',
                                    notForTagging: false,
                                    label: 'headgear',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/76302c09-f1f8-782f-a21a-aaac51fe0580',
                                            notForTagging: false,
                                            label: 'cap',
                                            broaderId:
                                                'http://data.disney.com/resources/1836497c-1ea0-58b6-9394-883024f6b89e',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/d24cf866-f9a0-6e1b-7c66-76a225bd2ba1',
                                                    notForTagging: false,
                                                    label: 'fez',
                                                    broaderId:
                                                        'http://data.disney.com/resources/76302c09-f1f8-782f-a21a-aaac51fe0580',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/34469b2d-c98c-4e11-8f6f-6e2c76698d92',
                                                    notForTagging: false,
                                                    label: 'beret',
                                                    broaderId:
                                                        'http://data.disney.com/resources/76302c09-f1f8-782f-a21a-aaac51fe0580',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/2e4f0dfa-fba0-3525-f333-d3eda0bb368c',
                                                    notForTagging: false,
                                                    label: 'beanie',
                                                    broaderId:
                                                        'http://data.disney.com/resources/76302c09-f1f8-782f-a21a-aaac51fe0580',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/084e9464-c65e-4b4d-9d86-558b6377a7a2',
                                                    notForTagging: false,
                                                    label: 'baseball cap',
                                                    broaderId:
                                                        'http://data.disney.com/resources/76302c09-f1f8-782f-a21a-aaac51fe0580',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/9a4cdb4a-f7ba-628a-82a5-168f854400b2',
                                                    notForTagging: false,
                                                    label: 'mortarboard',
                                                    broaderId:
                                                        'http://data.disney.com/resources/76302c09-f1f8-782f-a21a-aaac51fe0580',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/dd4b1926-7c08-f2f4-cfdb-5e5716f6d0ac',
                                            notForTagging: false,
                                            label: 'turban',
                                            broaderId:
                                                'http://data.disney.com/resources/1836497c-1ea0-58b6-9394-883024f6b89e',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/5b4953fa-b7c0-e514-4134-9f6a4cdb1ba2',
                                            notForTagging: false,
                                            label: 'veil',
                                            broaderId:
                                                'http://data.disney.com/resources/1836497c-1ea0-58b6-9394-883024f6b89e',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/96358d34-eba1-5b0d-5ec1-0bc90230d6bd',
                                            notForTagging: false,
                                            label: 'hat',
                                            broaderId:
                                                'http://data.disney.com/resources/1836497c-1ea0-58b6-9394-883024f6b89e',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/0d4f48a5-0028-2602-4879-7eb27c4d68b5',
                                                    notForTagging: false,
                                                    label: "sorcerer's hat",
                                                    broaderId:
                                                        'http://data.disney.com/resources/96358d34-eba1-5b0d-5ec1-0bc90230d6bd',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/383f7f07-def9-4879-c29b-c8650c6f31b0',
                                                    notForTagging: false,
                                                    label: 'Mickey Mouse ear hat',
                                                    broaderId:
                                                        'http://data.disney.com/resources/96358d34-eba1-5b0d-5ec1-0bc90230d6bd',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/ea39a898-6eef-8b41-eb01-8e423a5df0bb',
                                                    notForTagging: false,
                                                    label: 'cowboy hat',
                                                    broaderId:
                                                        'http://data.disney.com/resources/96358d34-eba1-5b0d-5ec1-0bc90230d6bd',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/2232e41c-55d0-7f58-23cc-33459c3cc190',
                                                    notForTagging: false,
                                                    label: 'sombrero',
                                                    broaderId:
                                                        'http://data.disney.com/resources/96358d34-eba1-5b0d-5ec1-0bc90230d6bd',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/c53bc529-af9c-8be1-9665-d82856431bb4',
                                                    notForTagging: false,
                                                    label: 'top hat',
                                                    broaderId:
                                                        'http://data.disney.com/resources/96358d34-eba1-5b0d-5ec1-0bc90230d6bd',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/923a603b-48b3-9744-0771-f6e6fe56f6b7',
                                            notForTagging: false,
                                            label: 'helmet',
                                            broaderId:
                                                'http://data.disney.com/resources/1836497c-1ea0-58b6-9394-883024f6b89e',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/d247c71b-709d-8cf2-3170-08f7fe63c2a1',
                                                    notForTagging: false,
                                                    label: 'fire hat',
                                                    broaderId:
                                                        'http://data.disney.com/resources/923a603b-48b3-9744-0771-f6e6fe56f6b7',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/243446d7-7ef5-7aa0-0deb-bb399d3441bf',
                                                    notForTagging: false,
                                                    label: 'hard hat',
                                                    broaderId:
                                                        'http://data.disney.com/resources/923a603b-48b3-9744-0771-f6e6fe56f6b7',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a746c06b-af01-22a4-5e79-4abbcad036b6',
                                    notForTagging: false,
                                    label: 'neckwear',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/354bfc98-ba41-1a78-2109-7ea3aed4208f',
                                            notForTagging: false,
                                            label: 'necktie',
                                            broaderId:
                                                'http://data.disney.com/resources/a746c06b-af01-22a4-5e79-4abbcad036b6',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/cb483b9f-d058-48a4-a629-c7d4c8d39b94',
                                            notForTagging: false,
                                            label: 'bow tie',
                                            broaderId:
                                                'http://data.disney.com/resources/a746c06b-af01-22a4-5e79-4abbcad036b6',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/25465b01-bb64-b19f-165b-53a07ecb7bb6',
                                            notForTagging: false,
                                            label: 'boa',
                                            broaderId:
                                                'http://data.disney.com/resources/a746c06b-af01-22a4-5e79-4abbcad036b6',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1840a7a0-05fa-5f8f-cb3e-5b697b869e81',
                                    notForTagging: false,
                                    label: 'folding fan',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/4446afbb-640c-9972-5dff-4cd6f8538188',
                                    notForTagging: false,
                                    label: 'hosiery',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/1232ed70-27fd-9db0-80e3-ec23bb39d6a9',
                                            notForTagging: false,
                                            label: 'socks',
                                            broaderId:
                                                'http://data.disney.com/resources/4446afbb-640c-9972-5dff-4cd6f8538188',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/963f7989-3f0f-c3ea-674f-a2d2a577f094',
                                    notForTagging: false,
                                    label: 'accessory',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a937890f-9cb0-ddbc-f306-a9cf10452f85',
                                    notForTagging: false,
                                    label: 'scarf',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a023a44e-0c98-4fce-99b9-819d1c0b8d1b',
                                    notForTagging: false,
                                    label: 'bag (costume accessory)',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/604598e3-bdaa-ed72-b740-bc4b926dfc92',
                                            notForTagging: false,
                                            label: 'purse',
                                            broaderId:
                                                'http://data.disney.com/resources/a023a44e-0c98-4fce-99b9-819d1c0b8d1b',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/a737f119-592d-7ee6-3cc5-8945c6805681',
                                            notForTagging: false,
                                            label: 'shoulder bag',
                                            broaderId:
                                                'http://data.disney.com/resources/a023a44e-0c98-4fce-99b9-819d1c0b8d1b',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/423a7893-7815-55dc-58db-b148d34b3a80',
                                            notForTagging: false,
                                            label: 'backpack',
                                            broaderId:
                                                'http://data.disney.com/resources/a023a44e-0c98-4fce-99b9-819d1c0b8d1b',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a0b1b3bc-34c3-4c95-8729-2199947b680e',
                                    notForTagging: false,
                                    label: 'mask',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/47359554-285e-cf98-179e-55a25a709eb1',
                                    notForTagging: false,
                                    label: 'holster',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/36479099-d141-86e1-035f-b29d439e8495',
                                    notForTagging: false,
                                    label: 'handwear',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/324f46eb-6022-0138-df10-2471f2e1969f',
                                            notForTagging: false,
                                            label: 'mitten',
                                            broaderId:
                                                'http://data.disney.com/resources/36479099-d141-86e1-035f-b29d439e8495',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/ed435986-e873-5e1e-1782-b6b1c0cd0d9a',
                                            notForTagging: false,
                                            label: 'glove',
                                            broaderId:
                                                'http://data.disney.com/resources/36479099-d141-86e1-035f-b29d439e8495',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/344d678e-17ad-94a9-c609-7953b2e0b89a',
                                            notForTagging: false,
                                            label: 'muff',
                                            broaderId:
                                                'http://data.disney.com/resources/36479099-d141-86e1-035f-b29d439e8495',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/f835c856-2f22-7459-bcb7-0a16c7c634a1',
                                    notForTagging: false,
                                    label: 'jewelry',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/e23b619d-795a-80e0-47b9-2c903e7e1087',
                                            notForTagging: false,
                                            label: 'crown',
                                            broaderId:
                                                'http://data.disney.com/resources/f835c856-2f22-7459-bcb7-0a16c7c634a1',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/0b3e5aa5-a8a4-784b-29d2-ab074738a582',
                                            notForTagging: false,
                                            label: 'bracelet',
                                            broaderId:
                                                'http://data.disney.com/resources/f835c856-2f22-7459-bcb7-0a16c7c634a1',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/6e3f2598-aaac-5f84-d1fc-4ecd6247d293',
                                            notForTagging: false,
                                            label: 'earring',
                                            broaderId:
                                                'http://data.disney.com/resources/f835c856-2f22-7459-bcb7-0a16c7c634a1',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/aff893a0-137a-46da-bc4f-3573b430801f',
                                            notForTagging: false,
                                            label: 'watch',
                                            broaderId:
                                                'http://data.disney.com/resources/f835c856-2f22-7459-bcb7-0a16c7c634a1',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/8531f3ba-c2a6-4678-0f21-934698affa98',
                                            notForTagging: false,
                                            label: 'pin',
                                            broaderId:
                                                'http://data.disney.com/resources/f835c856-2f22-7459-bcb7-0a16c7c634a1',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/b23ad0cf-9a83-92e3-44c2-5c4116d0ebbe',
                                            notForTagging: false,
                                            label: 'necklace',
                                            broaderId:
                                                'http://data.disney.com/resources/f835c856-2f22-7459-bcb7-0a16c7c634a1',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/5e4372a3-af0c-1d68-9cff-9579eade9a9c',
                                                    notForTagging: false,
                                                    label: 'locket',
                                                    broaderId:
                                                        'http://data.disney.com/resources/b23ad0cf-9a83-92e3-44c2-5c4116d0ebbe',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/a534e231-f40f-0710-33a3-c4afceee59b7',
                                            notForTagging: false,
                                            label: 'ring',
                                            broaderId:
                                                'http://data.disney.com/resources/f835c856-2f22-7459-bcb7-0a16c7c634a1',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0410c98d-b0b2-1eaf-9c75-0768b3f8f285',
                                    notForTagging: false,
                                    label: 'footwear',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/3d417dee-794c-bd58-3bc1-c0d4739f179c',
                                            notForTagging: false,
                                            label: 'clogs',
                                            broaderId:
                                                'http://data.disney.com/resources/0410c98d-b0b2-1eaf-9c75-0768b3f8f285',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/844d3f87-341d-c145-fbce-23c547784680',
                                            notForTagging: false,
                                            label: 'boots',
                                            broaderId:
                                                'http://data.disney.com/resources/0410c98d-b0b2-1eaf-9c75-0768b3f8f285',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/a944ffef-ee32-55dc-dc26-b83f16bfbca9',
                                            notForTagging: false,
                                            label: 'galoshes',
                                            broaderId:
                                                'http://data.disney.com/resources/0410c98d-b0b2-1eaf-9c75-0768b3f8f285',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/f440616d-cb5d-3d44-f003-9a82c6c0f083',
                                            notForTagging: false,
                                            label: 'sandals',
                                            broaderId:
                                                'http://data.disney.com/resources/0410c98d-b0b2-1eaf-9c75-0768b3f8f285',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/6430286b-a5b0-e641-1fa1-8acab0efe8bc',
                                            notForTagging: false,
                                            label: 'shoes',
                                            broaderId:
                                                'http://data.disney.com/resources/0410c98d-b0b2-1eaf-9c75-0768b3f8f285',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/d8443ce5-6ed3-777f-6848-fbab0278a7bf',
                                            notForTagging: false,
                                            label: 'hip boots',
                                            broaderId:
                                                'http://data.disney.com/resources/0410c98d-b0b2-1eaf-9c75-0768b3f8f285',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/574be80e-5169-68e5-8f85-8f59f35bfdac',
                                    notForTagging: false,
                                    label: 'bandanna',
                                    broaderId:
                                        'http://data.disney.com/resources/4e3d9ed0-aa66-bd5e-dcf0-c15b3c282da3',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/fb38c3b7-715b-32e6-536e-5b46a899e3ae',
                            notForTagging: false,
                            label: 'dress',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/b64774eb-76bc-ca1e-7cd0-f86864ef0488',
                                    notForTagging: false,
                                    label: 'wedding dress',
                                    broaderId:
                                        'http://data.disney.com/resources/fb38c3b7-715b-32e6-536e-5b46a899e3ae',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/a54ad080-91e5-1f76-267a-221a01fbd697',
                            notForTagging: false,
                            label: 'moccasins',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7e3e73ed-1281-4364-483c-9355cd2a7f9e',
                            notForTagging: false,
                            label: 'armor',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b337522c-5287-d280-0199-999e4a09d2aa',
                            notForTagging: false,
                            label: 'jacket',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a14ee70a-c076-bba9-9dd2-e3bd21f49eb3',
                            notForTagging: false,
                            label: 'ballet slippers',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/28433367-97a3-8b0a-c2f6-6fcaab977fbc',
                            notForTagging: false,
                            label: 'kimono',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ee407a03-eebf-f4b8-6772-b9ed8f58bd84',
                            notForTagging: false,
                            label: 'shorts',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/c04f64b8-a6f9-b5c8-88d5-2e42b6f78a82',
                                    notForTagging: false,
                                    label: 'lederhosen',
                                    broaderId:
                                        'http://data.disney.com/resources/ee407a03-eebf-f4b8-6772-b9ed8f58bd84',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/5d30536e-9d94-d87d-ed1b-0fad9ee58cae',
                            notForTagging: false,
                            label: 'costume',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/594c67a2-e44c-3309-8bba-e4a9f5ad1d9f',
                            notForTagging: false,
                            label: 'bib',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/404a9938-0be4-0896-2220-3f2a0eafb993',
                            notForTagging: false,
                            label: 'chaps',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0c3e2c30-3be2-545e-21f3-c95edab03aa3',
                            notForTagging: false,
                            label: 'bathing suit',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/0c47abc4-adcd-64be-681f-a08861e91597',
                                    notForTagging: false,
                                    label: 'bikini',
                                    broaderId:
                                        'http://data.disney.com/resources/0c3e2c30-3be2-545e-21f3-c95edab03aa3',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/bf40e830-71c1-0edd-5bce-1df044a2f1a6',
                            notForTagging: false,
                            label: 'uniform',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/dd39d212-6e1b-76ce-ca74-342e36118b82',
                                    notForTagging: false,
                                    label: 'military uniform',
                                    broaderId:
                                        'http://data.disney.com/resources/bf40e830-71c1-0edd-5bce-1df044a2f1a6',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/4c4be524-24e6-bdd5-5f98-48530da0818d',
                            notForTagging: false,
                            label: 'trousers',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f742f679-25ed-1ac3-d0ec-b47f310c3d8b',
                            notForTagging: false,
                            label: 'cape',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/40340483-5168-d369-a8ec-c3a7629c2780',
                            notForTagging: false,
                            label: 'shirt',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/8c4c3e6d-2ad7-f849-f52a-26f11f0db18e',
                                    notForTagging: false,
                                    label: 'sweat shirt',
                                    broaderId:
                                        'http://data.disney.com/resources/40340483-5168-d369-a8ec-c3a7629c2780',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b84ae540-061e-684b-4cab-d16ea13e798d',
                                    notForTagging: false,
                                    label: 'T-shirt',
                                    broaderId:
                                        'http://data.disney.com/resources/40340483-5168-d369-a8ec-c3a7629c2780',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3f339b0e-89f2-f9f5-acae-c14a8a053995',
                                    notForTagging: false,
                                    label: 'tank top',
                                    broaderId:
                                        'http://data.disney.com/resources/40340483-5168-d369-a8ec-c3a7629c2780',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/3b3c1168-c96e-cb38-049d-f99a695e65a0',
                            notForTagging: false,
                            label: 'coat',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/2740656e-e235-ff73-b6eb-b9ad0dd2dbad',
                                    notForTagging: false,
                                    label: 'raincoat',
                                    broaderId:
                                        'http://data.disney.com/resources/3b3c1168-c96e-cb38-049d-f99a695e65a0',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/ed42f6bc-ef0f-50d7-e663-f86c58058f91',
                            notForTagging: false,
                            label: 'sweater',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/504d3a45-1152-f210-4a8b-c40838b1ddb3',
                            notForTagging: false,
                            label: 'suit',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/5c458205-7a04-3fd7-ef64-5b05b7c32da3',
                                    notForTagging: false,
                                    label: 'space suit',
                                    broaderId:
                                        'http://data.disney.com/resources/504d3a45-1152-f210-4a8b-c40838b1ddb3',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/7b4a8180-f556-3baf-99bb-b1907772eab7',
                                    notForTagging: false,
                                    label: 'wetsuit',
                                    broaderId:
                                        'http://data.disney.com/resources/504d3a45-1152-f210-4a8b-c40838b1ddb3',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/80441c75-afdd-1895-034d-1b69fc42df98',
                                    notForTagging: false,
                                    label: 'tuxedo',
                                    broaderId:
                                        'http://data.disney.com/resources/504d3a45-1152-f210-4a8b-c40838b1ddb3',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1a414a0f-c76b-0ba9-6ad6-85b6c5b9ea91',
                                    notForTagging: false,
                                    label: 'snowsuit',
                                    broaderId:
                                        'http://data.disney.com/resources/504d3a45-1152-f210-4a8b-c40838b1ddb3',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/494b225f-df81-6a9c-c5b3-04653991d8ae',
                            notForTagging: false,
                            label: 'apron',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f14e9336-f86d-1447-107d-a0c095362481',
                            notForTagging: false,
                            label: 'underwear',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/244c7b7a-e5af-7bcc-dfa6-175b01e1ddb6',
                                    notForTagging: false,
                                    label: 'lingerie',
                                    broaderId:
                                        'http://data.disney.com/resources/f14e9336-f86d-1447-107d-a0c095362481',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/e1403290-2ebd-0828-34c6-8377c399f189',
                            notForTagging: false,
                            label: 'vest',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/354efa46-4c10-d8fb-2d7b-2c9f7bbf7091',
                            notForTagging: false,
                            label: 'pajamas',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8646153d-99c9-20cd-4102-ab2f2476fda1',
                            notForTagging: false,
                            label: 'poncho',
                            broaderId:
                                'http://data.disney.com/resources/5d3408c1-54c9-0d37-04ce-ad9cb058b08a',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                    notForTagging: true,
                    label: 'Containers',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/c8df9991-b7c3-4f78-9f1a-a1b326978e2b',
                            notForTagging: false,
                            label: 'box',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7d4ba790-a848-4bfe-492c-14e5bb15f0be',
                            notForTagging: false,
                            label: 'beaker',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6e375aa0-0283-4e25-e202-ec1720882997',
                            notForTagging: false,
                            label: 'teapot',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6898c027-a22f-489a-8651-75ca662f647d',
                            notForTagging: false,
                            label: 'crate',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f440c135-51cf-0a07-d5e5-9ba524f89890',
                            notForTagging: false,
                            label: 'mailbox',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4672aec6-2621-49ca-8ef8-3cd0883f0222',
                            notForTagging: false,
                            label: 'cup',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5f474899-edf5-1cf0-68cd-afbcb999ffa8',
                            notForTagging: false,
                            label: 'fishbowl',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8c3e328d-280e-c20b-4eb1-2c20ff8d77b4',
                            notForTagging: false,
                            label: 'bucket',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2894f3f0-4f01-4552-8fd2-7502fae35249',
                            notForTagging: false,
                            label: 'basket',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c75f3c7d-6aa8-4f14-9efd-b795ae68bf13',
                            notForTagging: false,
                            label: 'briefcase',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/384695c5-3064-879c-6128-75b8b2cd4aa8',
                            notForTagging: false,
                            label: 'shipping container',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/82366da9-21bd-54e3-1c63-827f1a863d86',
                            notForTagging: false,
                            label: 'vase',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a1609b79-80eb-4428-a950-a249422c9ab6',
                            notForTagging: false,
                            label: 'tray',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8f3214f6-a2c2-aaea-acf5-ff8dc8aa07b4',
                            notForTagging: false,
                            label: 'picnic basket',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/40bb20df-9126-4f16-a5fb-9cf0e1a65b29',
                            notForTagging: false,
                            label: 'jar',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a434d71e-72dd-9394-2806-76c2005d55a1',
                            notForTagging: false,
                            label: 'urn',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3f314d2f-f92e-03dd-61d1-9209dd75dca7',
                            notForTagging: false,
                            label: 'coffeepot',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a74b186c-ae5f-146d-fa85-69c4c2274bae',
                            notForTagging: false,
                            label: 'kettle',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/954836ed-6ab9-83b6-514b-11400c6dbb85',
                            notForTagging: false,
                            label: 'locker',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bc40843e-ff05-1f9b-729b-a395496dc8bb',
                            notForTagging: false,
                            label: 'watering can',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b44faef8-446c-090c-2e62-e861b4662185',
                            notForTagging: false,
                            label: 'bag',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/953e1ef5-cea4-fb9e-1395-fa9aaf390882',
                            notForTagging: false,
                            label: 'toolbox',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/773029f0-eeff-1f87-095d-663fbb42cca0',
                            notForTagging: false,
                            label: 'luggage',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f64257a5-b521-ed02-872d-2e5ec247e6ba',
                            notForTagging: false,
                            label: 'vial',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/05babbd2-d274-4de2-941d-bdcf44e98e8c',
                            notForTagging: false,
                            label: 'aquarium (containers)',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5d404ddf-3029-f943-1ada-07ae60727d94',
                            notForTagging: false,
                            label: 'safe',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5029a467-d616-4162-b20a-22063842de95',
                            notForTagging: false,
                            label: 'trash can',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f8f74803-7912-4fc4-abb4-35b251455d45',
                            notForTagging: false,
                            label: 'canister',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/520c9b14-1892-4cea-8bf9-70b79cc74af4',
                            notForTagging: false,
                            label: 'can',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/434374bb-9d12-1682-0580-9a0c43806b99',
                            notForTagging: false,
                            label: 'coffin',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a64a0f4f-ca2a-6636-fb06-e1d35c62ed9a',
                            notForTagging: false,
                            label: 'birdcage',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/06392d04-350e-b957-491a-af58bc93ca80',
                            notForTagging: false,
                            label: 'cage',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2f3126ce-37b3-bbcf-04e2-71845ea5c8b4',
                            notForTagging: false,
                            label: 'lunchbox',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e1fcfabf-344e-440f-b4a0-22708bf0d42d',
                            notForTagging: false,
                            label: 'jewelry box',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/95f3e4af-ad4f-4a2d-9c3c-542310b63ca3',
                            notForTagging: false,
                            label: 'bottle',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/81ddaa6f-62ad-4bdf-91fd-dd937eb9cf92',
                            notForTagging: false,
                            label: 'dish',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/85f30ab2-8998-46b0-885a-cef941a13662',
                            notForTagging: false,
                            label: 'plastic container',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/81377bb4-f5f4-0d92-afc2-02ac95bb38a5',
                            notForTagging: false,
                            label: 'barrel',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a26245ea-ee98-43d7-b755-818d45e6c1d4',
                            notForTagging: false,
                            label: 'dumpster',
                            broaderId:
                                'http://data.disney.com/resources/ce362546-ad65-1247-f84c-d43ce6f83e80',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                    notForTagging: true,
                    label: 'Equipment',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/f73b24f7-2fbf-1af2-40c9-f21274bfa285',
                            notForTagging: false,
                            label: 'net',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/62996339-c746-471b-8779-b8ee7ae7a5c5',
                            notForTagging: false,
                            label: 'medical equipment',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3f1acee3-df1f-4c19-a565-027818bd40ed',
                            notForTagging: false,
                            label: 'concrete mixer',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6e4b6007-e529-455a-cf8c-7ee5f262eab7',
                            notForTagging: false,
                            label: 'mousetrap',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3c4a467b-26f2-aed8-5897-3700c1a162b6',
                            notForTagging: false,
                            label: 'fire extinguisher',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7373d190-7db2-4a24-8dda-f6204b7c30dd',
                            notForTagging: false,
                            label: 'restraining equipment',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/0a472ea4-0e32-dc7f-36a5-8abfa58628b5',
                                    notForTagging: false,
                                    label: 'shackle',
                                    broaderId:
                                        'http://data.disney.com/resources/7373d190-7db2-4a24-8dda-f6204b7c30dd',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ba42abf4-6ed1-1be9-5e96-fcaa53a981a9',
                                    notForTagging: false,
                                    label: 'handcuff',
                                    broaderId:
                                        'http://data.disney.com/resources/7373d190-7db2-4a24-8dda-f6204b7c30dd',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/a24544c2-f22d-8b9e-7c1c-87c6cea8698d',
                            notForTagging: false,
                            label: 'wrecking ball',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f6390307-c0a6-59f9-ca57-e5508d711baa',
                            notForTagging: false,
                            label: 'machine',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b83bfccd-81f0-0ba6-e7b9-6d25f972899e',
                            notForTagging: false,
                            label: 'microscope',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7e45fae1-face-84f5-de7d-b3afde41c59a',
                            notForTagging: false,
                            label: 'paper clips',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/af4b9f5d-d54f-4d70-b541-c0a03a5a3f9f',
                            notForTagging: false,
                            label: 'speedometer',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                            notForTagging: false,
                            label: 'electronic equipment',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/1c438e3c-4190-c28c-e2e6-2187256ce4a1',
                                    notForTagging: false,
                                    label: 'walkie-talkie',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/aab3ae9b-01c5-4008-9212-29ee9c7ecea6',
                                    notForTagging: false,
                                    label: 'electric switches',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/203e1fb9-b244-21d1-7d00-5d3ebc7c49a4',
                                    notForTagging: false,
                                    label: 'videocassette recorder',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/e2345c48-916e-0f33-e5b8-339f754ac79e',
                                    notForTagging: false,
                                    label: 'camera',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1e326701-53d3-e01d-a574-28a3afcdab9c',
                                    notForTagging: false,
                                    label: 'radios',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/253794e5-41ca-9012-4a26-559436d86898',
                                    notForTagging: false,
                                    label: 'television',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/df2a690c-bf7b-4054-8279-83de800e419c',
                                    notForTagging: false,
                                    label: 'control board',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/d130fa68-8261-b180-3f18-e6ec4987c4af',
                                    notForTagging: false,
                                    label: 'video camera',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/88727995-cf70-4a05-b6f5-a69d156d0967',
                                    notForTagging: false,
                                    label: 'transmitter-receiver',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ae36c12a-76a6-df8f-a005-5fb54c58fb99',
                                    notForTagging: false,
                                    label: 'computer',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/3802b2a6-7fda-4520-9ca8-72e3eb61401a',
                                            notForTagging: false,
                                            label: 'storage device',
                                            broaderId:
                                                'http://data.disney.com/resources/ae36c12a-76a6-df8f-a005-5fb54c58fb99',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/96a7d974-9e94-41bd-ac5d-c2a334b94878',
                                            notForTagging: false,
                                            label: 'circuit board',
                                            broaderId:
                                                'http://data.disney.com/resources/ae36c12a-76a6-df8f-a005-5fb54c58fb99',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/903ab881-955b-aa1d-5295-f4b2cb486a95',
                                    notForTagging: false,
                                    label: 'headphone',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/aa62955d-1840-4dc4-a373-6810d919c680',
                                    notForTagging: false,
                                    label: 'electronic surveillance equipment',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3c345560-2b2f-ce2e-d58d-67e40cf63282',
                                    notForTagging: false,
                                    label: 'projector',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/04102791-a8b7-c92d-9c72-f07774cbbc81',
                                    notForTagging: false,
                                    label: 'motion picture camera',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/78815be8-155f-4c42-bde3-be1205bb2f12',
                                    notForTagging: false,
                                    label: 'audio equipment',
                                    broaderId:
                                        'http://data.disney.com/resources/264e4e27-78a8-f0f8-314e-fbf31772a6b1',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/a448b961-f468-866e-3660-1a7809745c9e',
                                            notForTagging: false,
                                            label: 'turntable',
                                            broaderId:
                                                'http://data.disney.com/resources/78815be8-155f-4c42-bde3-be1205bb2f12',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/41e954ec-3a56-446a-b3c0-a33b55d59a2d',
                                            notForTagging: false,
                                            label: 'loudspeaker',
                                            broaderId:
                                                'http://data.disney.com/resources/78815be8-155f-4c42-bde3-be1205bb2f12',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/463e2496-a991-e568-fef9-2573d4510299',
                                            notForTagging: false,
                                            label: 'microphone',
                                            broaderId:
                                                'http://data.disney.com/resources/78815be8-155f-4c42-bde3-be1205bb2f12',
                                            terms: [],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/9763fbfa-a53c-4451-90e3-274f7325c8d7',
                            notForTagging: false,
                            label: 'automobile driving simulator',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c83454e8-b8d9-e262-d41b-122d36be01a2',
                            notForTagging: false,
                            label: 'knife',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ab4b3da5-699a-c040-09f1-e88cc7cb7099',
                            notForTagging: false,
                            label: 'polygraph',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/da43335c-a012-4075-4a3e-83880c4e5d9c',
                            notForTagging: false,
                            label: 'lawn mower',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1b448925-2368-8560-4fee-19348a3390bd',
                            notForTagging: false,
                            label: 'tractor',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5e3dedd9-d4ae-5492-b77a-cd157da8f780',
                            notForTagging: true,
                            label: 'Drafting, Drawing and Writing Equipment',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/37baf8a8-5d60-4f43-8b93-8e3ba972ce72',
                                    notForTagging: false,
                                    label: 'pen',
                                    broaderId:
                                        'http://data.disney.com/resources/5e3dedd9-d4ae-5492-b77a-cd157da8f780',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0635c83a-6b71-b4e3-9be3-02d351446e84',
                                    notForTagging: false,
                                    label: 'typewriter',
                                    broaderId:
                                        'http://data.disney.com/resources/5e3dedd9-d4ae-5492-b77a-cd157da8f780',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/e237568d-8c13-4680-a0b5-c8870cdb04a8',
                                    notForTagging: false,
                                    label: 'whiteboard',
                                    broaderId:
                                        'http://data.disney.com/resources/5e3dedd9-d4ae-5492-b77a-cd157da8f780',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c56464b0-9b5d-4c24-8e11-5088674a108b',
                                    notForTagging: false,
                                    label: 'blackboard',
                                    broaderId:
                                        'http://data.disney.com/resources/5e3dedd9-d4ae-5492-b77a-cd157da8f780',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/93467a5d-deda-e3e9-2f74-a865a4eba2a7',
                            notForTagging: false,
                            label: 'backhoe',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/963105d1-a27c-8ded-2b0b-43766ebed28f',
                            notForTagging: false,
                            label: 'playground equipment',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/a135b411-3547-b545-e947-de6f649a8283',
                                    notForTagging: false,
                                    label: 'seesaw',
                                    broaderId:
                                        'http://data.disney.com/resources/963105d1-a27c-8ded-2b0b-43766ebed28f',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/cf3396cb-daaa-8aae-65d4-092884802491',
                                    notForTagging: false,
                                    label: 'slide',
                                    broaderId:
                                        'http://data.disney.com/resources/963105d1-a27c-8ded-2b0b-43766ebed28f',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/eb38252f-8896-a3e2-8d6b-c066c00625ba',
                                    notForTagging: false,
                                    label: 'swing',
                                    broaderId:
                                        'http://data.disney.com/resources/963105d1-a27c-8ded-2b0b-43766ebed28f',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/044b4d6f-7a2e-ddbc-bc50-503c26008496',
                                    notForTagging: false,
                                    label: 'sandbox',
                                    broaderId:
                                        'http://data.disney.com/resources/963105d1-a27c-8ded-2b0b-43766ebed28f',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410c58d-b02e-1be0-9c75-0768b3f87c85',
                            notForTagging: false,
                            label: 'moviola',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                            notForTagging: true,
                            label: 'Equipment for Personal Use',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/da3a288d-bd74-3c80-4625-405b6b3e8faf',
                                    notForTagging: false,
                                    label: 'umbrella',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/9f471c91-4dfb-b9b6-e829-9347cfa0ae91',
                                    notForTagging: false,
                                    label: 'eyeglasses',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c837c8f6-18d9-6509-bad7-2aae6b3e10ba',
                                    notForTagging: false,
                                    label: 'hair dryer',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1f41684d-bc2e-afea-37be-4fa3bc268c8e',
                                    notForTagging: false,
                                    label: 'blindfold',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/4f3825f9-b56c-d8e5-2186-d59fcba2f9b6',
                                    notForTagging: false,
                                    label: 'sunglasses',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5e37350c-b795-1cf0-6cbd-889e21a09da1',
                                    notForTagging: false,
                                    label: 'crutches',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/344154e8-74c6-51aa-c89a-4fe5884bf4ac',
                                    notForTagging: false,
                                    label: 'bandage',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a54e70c8-08b2-8101-433d-ec184f36c8be',
                                    notForTagging: false,
                                    label: 'thermometer',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/7a3cb510-4b58-388b-1873-818a068b6b90',
                                    notForTagging: false,
                                    label: 'binoculars',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/db3bfb2e-025f-86b8-4405-c7e28d0d5cad',
                                    notForTagging: false,
                                    label: 'cosmetics',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/1e42781a-a23b-c12b-552f-d0cda286ca8d',
                                            notForTagging: false,
                                            label: 'lipstick',
                                            broaderId:
                                                'http://data.disney.com/resources/db3bfb2e-025f-86b8-4405-c7e28d0d5cad',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c5a0b6da-afa2-4b5c-9c42-ad21c2cf4ad7',
                                    notForTagging: false,
                                    label: 'razor',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/613bc69d-63e8-e0e0-f918-9d4001eaa883',
                                    notForTagging: false,
                                    label: 'staff (walking stick)',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/3336e2f4-0f9d-22ca-14c0-b67397bf8a87',
                                            notForTagging: false,
                                            label: 'cane (walking stick)',
                                            broaderId:
                                                'http://data.disney.com/resources/613bc69d-63e8-e0e0-f918-9d4001eaa883',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/aa46256a-2554-158b-c3ad-6175ddb10488',
                                    notForTagging: false,
                                    label: 'curling iron',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3541e7a7-25ec-d4fd-3c58-46738b87ed82',
                                    notForTagging: false,
                                    label: 'goggles',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/73468e56-cebc-8095-3eb9-4ac6ed79a1a6',
                                    notForTagging: false,
                                    label: 'gas mask',
                                    broaderId:
                                        'http://data.disney.com/resources/803f7e03-7835-b4f0-ff27-f1edc110f188',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/9d3a512d-1faf-a2e0-d870-6e941abb1684',
                            notForTagging: false,
                            label: 'chains',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1a408e4b-065f-ab0b-15bc-63342a666a87',
                            notForTagging: false,
                            label: 'crane (equipment)',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/54c446bb-805e-4f8e-85cf-e74e1c2b2522',
                            notForTagging: false,
                            label: 'spinning wheel',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a9481427-da92-7519-787e-2b995d17c09c',
                            notForTagging: false,
                            label: 'cash register',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/486e8f23-87ae-4399-8d6c-0c0d2ec6d8e7',
                            notForTagging: false,
                            label: 'battery',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/80bdea8f-7caa-4a58-b336-62f4357bdbca',
                            notForTagging: false,
                            label: 'telephone',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/c8be46eb-434c-47fd-8bae-eaa51339c615',
                                    notForTagging: false,
                                    label: 'cellular telephone',
                                    broaderId:
                                        'http://data.disney.com/resources/80bdea8f-7caa-4a58-b336-62f4357bdbca',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/562e9555-679d-49e7-b4c3-621c6e91c25f',
                            notForTagging: false,
                            label: 'fan',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/16b37780-ba93-4025-9fa3-2ddd34d9fbba',
                            notForTagging: false,
                            label: 'keys (hardware)',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9445fe87-1632-0f1a-f32e-9ae30f1d958b',
                            notForTagging: false,
                            label: 'sawhorse',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5b4035cf-e2c4-d895-13ee-a2f27141c594',
                            notForTagging: false,
                            label: 'blowtorch',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3845c4a6-54d2-9060-653a-d2d972fa118d',
                            notForTagging: false,
                            label: 'periscope',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8c405a2a-0125-b783-23a1-eda7d34e1dbc',
                            notForTagging: false,
                            label: 'chain saw',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0b301fbe-f827-fc61-c57a-ba8fc0ac2a90',
                            notForTagging: true,
                            label: 'Appliances',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/0631b71d-7b31-298b-6310-e1d1984c3f86',
                                    notForTagging: false,
                                    label: 'microwave oven',
                                    broaderId:
                                        'http://data.disney.com/resources/0b301fbe-f827-fc61-c57a-ba8fc0ac2a90',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b436daab-8aa9-3944-b035-8dc189940bac',
                                    notForTagging: false,
                                    label: 'vacuum cleaner',
                                    broaderId:
                                        'http://data.disney.com/resources/0b301fbe-f827-fc61-c57a-ba8fc0ac2a90',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/374433c6-61a0-79b0-f537-7a6408fc28a0',
                                    notForTagging: false,
                                    label: 'washing machine',
                                    broaderId:
                                        'http://data.disney.com/resources/0b301fbe-f827-fc61-c57a-ba8fc0ac2a90',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/fc3b07ab-5798-aab2-e25d-9c94d599f785',
                                    notForTagging: false,
                                    label: 'air conditioner',
                                    broaderId:
                                        'http://data.disney.com/resources/0b301fbe-f827-fc61-c57a-ba8fc0ac2a90',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/9d38119c-b9c4-8332-c3c9-1d2232618ba0',
                                    notForTagging: false,
                                    label: 'refrigerator',
                                    broaderId:
                                        'http://data.disney.com/resources/0b301fbe-f827-fc61-c57a-ba8fc0ac2a90',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/3f373c37-2819-9adc-2864-a5c4e2207280',
                            notForTagging: false,
                            label: 'stethoscope',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/274fab31-81bd-5e7f-ec2d-92894efae5b6',
                            notForTagging: true,
                            label: 'Culinary Equipment',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/ac4f553c-279a-b30c-ad74-8b1161ca7d81',
                                    notForTagging: false,
                                    label: 'cookware',
                                    broaderId:
                                        'http://data.disney.com/resources/274fab31-81bd-5e7f-ec2d-92894efae5b6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b24ca68c-033d-cd4f-14d3-6c681f6626af',
                                    notForTagging: false,
                                    label: 'flatware',
                                    broaderId:
                                        'http://data.disney.com/resources/274fab31-81bd-5e7f-ec2d-92894efae5b6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3ed9cfe8-b519-4f18-9548-7b602bdcfaa5',
                                    notForTagging: false,
                                    label: 'frying pan',
                                    broaderId:
                                        'http://data.disney.com/resources/274fab31-81bd-5e7f-ec2d-92894efae5b6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/fd3b7baf-e8c1-416d-b335-99825ff15aa9',
                                    notForTagging: false,
                                    label: 'stove',
                                    broaderId:
                                        'http://data.disney.com/resources/274fab31-81bd-5e7f-ec2d-92894efae5b6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0f453dca-0a03-4419-181f-30f171d99fa3',
                                    notForTagging: false,
                                    label: 'barbecue grill',
                                    broaderId:
                                        'http://data.disney.com/resources/274fab31-81bd-5e7f-ec2d-92894efae5b6',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/3f3aa291-268b-766a-2587-54d80dc4cd91',
                            notForTagging: false,
                            label: 'telescope',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5cf33ef3-1b52-4196-af62-029c7d59dadc',
                            notForTagging: false,
                            label: 'gauge',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                            notForTagging: true,
                            label: 'Tools and Components',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/13330ec3-39e8-84a9-8761-1cd9e09146a9',
                                    notForTagging: false,
                                    label: 'broom',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0809ba7d-917d-42f5-9160-6b69c05ca19e',
                                    notForTagging: false,
                                    label: 'mop',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/444b281e-09ba-7695-5e85-e88ecc944cb3',
                                    notForTagging: false,
                                    label: 'paintbrush',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/324cec21-a689-a857-85f1-6ed95869ab9f',
                                    notForTagging: false,
                                    label: 'file (tool)',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/203af6b6-d370-b199-ff64-b374b0c4cd8a',
                                    notForTagging: false,
                                    label: 'plunger',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/7d4fdea1-f44e-eb16-c1a1-4e48d2ce02b0',
                                    notForTagging: false,
                                    label: 'gavel',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/44406dcf-f3fb-0181-a468-044958149c88',
                                    notForTagging: false,
                                    label: 'rake',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6d47eb7e-c541-e39a-dc1d-fde0532e1d8e',
                                    notForTagging: false,
                                    label: 'hatchet',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/8134b968-8b88-4734-0e2b-75a25420e1b2',
                                    notForTagging: false,
                                    label: 'jackhammer',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/14308c22-4b06-8489-701a-54b8affadcbe',
                                    notForTagging: false,
                                    label: 'drill',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/fa4faff7-20e5-7e85-59b8-ba69ee9bcabd',
                                    notForTagging: false,
                                    label: 'ax',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6d4aefbf-3608-9ece-2267-a8c519420b9e',
                                    notForTagging: false,
                                    label: 'cattle brand',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/594ff7f6-1432-9e06-8a66-9a96411e1ab9',
                                    notForTagging: false,
                                    label: 'hose',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b0a2b9c4-2156-4d3b-a0e8-11331eabb65c',
                                    notForTagging: false,
                                    label: 'nail',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/aa335db0-7132-ec61-bf81-03d35b20f286',
                                    notForTagging: false,
                                    label: 'pliers',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/38335eb4-d390-26db-05ab-89a3f1dba3b0',
                                    notForTagging: false,
                                    label: 'hammer',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b33a42a1-737b-5e5e-961d-994103bc0fa9',
                                    notForTagging: false,
                                    label: 'shovel',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2c4d461e-0ca8-c831-797f-1040b0c8919e',
                                    notForTagging: false,
                                    label: 'hoe',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/104736f9-9ebe-bb98-964e-24f2d48373aa',
                                    notForTagging: false,
                                    label: 'saw',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/30805e77-237c-448c-b75d-e2533e0d62ee',
                                    notForTagging: false,
                                    label: 'nut and bolt',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/fc485dc6-72aa-de40-1ada-4852ab49bb85',
                                    notForTagging: false,
                                    label: 'pocket knife',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/04107691-d05d-eb6b-9c72-f07774cbe487',
                                    notForTagging: false,
                                    label: 'screwdriver',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/47403346-c4b4-2220-051c-f60fd0fca39c',
                                    notForTagging: false,
                                    label: 'pitchfork',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/893a843c-8768-edf6-bb4e-b43cdd404988',
                                    notForTagging: false,
                                    label: 'wrench',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5389afe8-0be0-48de-8533-4999ba8684c3',
                                    notForTagging: false,
                                    label: 'hook',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/65327d18-a12f-5774-35c7-f7474a9dd8bd',
                                    notForTagging: false,
                                    label: 'scissors',
                                    broaderId:
                                        'http://data.disney.com/resources/2b318f0c-226b-26df-f381-9ec2328cb4b5',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/514791c7-a181-1e10-6723-c707d32c4b8a',
                            notForTagging: false,
                            label: 'gallows',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5a465359-b7aa-7d83-573e-3259001493ad',
                            notForTagging: false,
                            label: 'bulldozer',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2cc7c5f3-5798-4f1c-8570-12e3d3b6a500',
                            notForTagging: false,
                            label: 'scaffolding',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a8439f64-a6eb-b21a-b2b6-3f8c79260e9f',
                            notForTagging: false,
                            label: 'magnet',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                            notForTagging: true,
                            label: 'Sports and Athletic Equipment',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/32302970-f6a6-b27b-4ca1-1fcd0a34ed8c',
                                    notForTagging: false,
                                    label: 'volleyball',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0b44ffd2-b26c-d784-46d2-f4f760b147a8',
                                    notForTagging: false,
                                    label: 'Frisbee',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b53aa3aa-c6e0-61c1-8d94-efac58f0c8bb',
                                    notForTagging: false,
                                    label: 'skateboard',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/aa45eb56-e8d3-96a1-601d-ecdecb71679d',
                                    notForTagging: false,
                                    label: 'golf club (equipment)',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6a42bb89-0b3f-d82b-6079-baff074281aa',
                                    notForTagging: false,
                                    label: 'snow skis',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a638785a-4377-2b23-a7f8-9bb624e01d86',
                                    notForTagging: false,
                                    label: 'football',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3e407d84-3b92-984c-ce28-94a8c36568ac',
                                    notForTagging: false,
                                    label: 'hockey stick',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/881ee1d6-2475-4a35-a3ec-2dc29887b716',
                                    notForTagging: false,
                                    label: 'baseball bat',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/12381532-67cf-8f56-060a-10d395c1e3aa',
                                    notForTagging: false,
                                    label: 'snowboard',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5b49178c-1d5f-b0b1-f80d-3d44f2c4a8a2',
                                    notForTagging: false,
                                    label: 'ice skates',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/e1373d23-c5d2-dcdf-3761-4cf8beb22a8b',
                                    notForTagging: false,
                                    label: 'surfboard',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/873ecefd-8ee9-bc42-db00-ac1c9aa88abb',
                                    notForTagging: false,
                                    label: 'basketball',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2b4dc7ee-8c48-2093-aa5e-acb9af633aab',
                                    notForTagging: false,
                                    label: 'roller skates',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0410298e-d081-f6c3-9c75-0768b3f8f288',
                                    notForTagging: false,
                                    label: 'stationary bicycle',
                                    broaderId:
                                        'http://data.disney.com/resources/023a80c2-30d3-5bad-af25-4986dc847282',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/48334306-cc97-2633-01aa-3f8a1daaad84',
                            notForTagging: false,
                            label: 'lasso',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410ca8d-286e-a6ac-9c75-0768b3f80986',
                            notForTagging: false,
                            label: 'life preserver',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/aa405c8f-3728-d36d-ab44-bc8625e760b1',
                            notForTagging: false,
                            label: 'gasoline pump',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c640b69d-6e18-fb08-b5d9-fc3511a48c8f',
                            notForTagging: false,
                            label: 'animal trap',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2644de4f-5906-4970-7c16-6b7f8499cba1',
                            notForTagging: false,
                            label: 'ladder',
                            broaderId:
                                'http://data.disney.com/resources/f933b3f1-9e41-b6b6-31ae-7f39db7d42b8',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                    notForTagging: false,
                    label: 'Food',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/ed3d49da-16b7-b60a-351c-04064cb4c98e',
                            notForTagging: false,
                            label: 'vegetable',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/655ab1a3-5ad2-4978-90e8-cefc0a03f146',
                                    notForTagging: false,
                                    label: 'pumpkin',
                                    broaderId:
                                        'http://data.disney.com/resources/ed3d49da-16b7-b60a-351c-04064cb4c98e',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/9662a6d9-f850-4421-91e9-177fe14be7b4',
                            notForTagging: false,
                            label: 'apple',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2dfefb7b-3319-4a09-b901-3c0ab60f502b',
                            notForTagging: false,
                            label: 'baked good',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/e83f29dd-308a-594a-820d-abcd80eaf19f',
                                    notForTagging: false,
                                    label: 'bread',
                                    broaderId:
                                        'http://data.disney.com/resources/2dfefb7b-3319-4a09-b901-3c0ab60f502b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/38360798-cb97-3670-1963-6f688cb7e189',
                                    notForTagging: false,
                                    label: 'pie',
                                    broaderId:
                                        'http://data.disney.com/resources/2dfefb7b-3319-4a09-b901-3c0ab60f502b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/f34d66c8-8bd0-45d3-b011-4343d55d295e',
                                    notForTagging: false,
                                    label: 'cake',
                                    broaderId:
                                        'http://data.disney.com/resources/2dfefb7b-3319-4a09-b901-3c0ab60f502b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/f0323da3-191d-745c-c9fb-5135658f08bc',
                                    notForTagging: false,
                                    label: 'birthday cake',
                                    broaderId:
                                        'http://data.disney.com/resources/2dfefb7b-3319-4a09-b901-3c0ab60f502b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3c418175-b474-5f47-bd0d-e830b36ff987',
                                    notForTagging: false,
                                    label: 'cookies',
                                    broaderId:
                                        'http://data.disney.com/resources/2dfefb7b-3319-4a09-b901-3c0ab60f502b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/88c5b780-8e94-4de4-b371-8727f5b6321d',
                                    notForTagging: false,
                                    label: 'doughnuts',
                                    broaderId:
                                        'http://data.disney.com/resources/2dfefb7b-3319-4a09-b901-3c0ab60f502b',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/e2af1427-f37e-4c7b-8bc5-2474883168e5',
                            notForTagging: false,
                            label: 'snack food',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3c389705-dfde-9e06-2c0b-c22424e48988',
                            notForTagging: false,
                            label: 'sandwich',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/493d2926-d253-aa99-5828-e9b8faec1ead',
                                    notForTagging: false,
                                    label: 'hamburger',
                                    broaderId:
                                        'http://data.disney.com/resources/3c389705-dfde-9e06-2c0b-c22424e48988',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/d531ee4a-d61a-ab7b-3a0c-e67b81eb28b4',
                            notForTagging: false,
                            label: 'strawberry',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f24db8bd-39e5-a6cc-8624-cbfd68801089',
                            notForTagging: false,
                            label: 'seafood',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2930160f-c32f-cefc-cc05-4e993003cda9',
                            notForTagging: false,
                            label: 'fruit',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2c45d8a8-1f9a-99f7-b9d1-550a791afc8f',
                            notForTagging: false,
                            label: 'grain food',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b547a240-72a3-4a72-24a2-ed7572fe7286',
                            notForTagging: false,
                            label: 'salad',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/423bd6eb-0de8-da86-46d0-84cd2b57f6b8',
                            notForTagging: false,
                            label: 'french fries',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/95347bd5-495b-de95-aeab-d2f87c28218d',
                            notForTagging: false,
                            label: 'pizza',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/29475ec5-5d1b-1658-15cc-573073805984',
                            notForTagging: false,
                            label: 'chocolate',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6d36fc92-5c10-c20b-91a5-3f2dde578685',
                            notForTagging: false,
                            label: 'pasta',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9c38e55e-3da3-49eb-eaa6-d5e80c9f6496',
                            notForTagging: false,
                            label: 'beverage',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/ee4cf5bc-3d9b-98ab-c834-5b9f3c91c8a7',
                                    notForTagging: false,
                                    label: 'tea',
                                    broaderId:
                                        'http://data.disney.com/resources/9c38e55e-3da3-49eb-eaa6-d5e80c9f6496',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b73269ff-6ed9-8c11-f998-7af784e64e8d',
                                    notForTagging: false,
                                    label: 'alcoholic beverage',
                                    broaderId:
                                        'http://data.disney.com/resources/9c38e55e-3da3-49eb-eaa6-d5e80c9f6496',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/c048e435-71ba-987d-e08f-2c028d435cb7',
                                            notForTagging: false,
                                            label: 'cocktail',
                                            broaderId:
                                                'http://data.disney.com/resources/b73269ff-6ed9-8c11-f998-7af784e64e8d',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/745f6259-279f-42c4-9725-45f7934a6c05',
                                            notForTagging: false,
                                            label: 'potion',
                                            broaderId:
                                                'http://data.disney.com/resources/b73269ff-6ed9-8c11-f998-7af784e64e8d',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/a747c259-973a-db17-e0b0-91dcfc5a889d',
                                            notForTagging: false,
                                            label: 'beer',
                                            broaderId:
                                                'http://data.disney.com/resources/b73269ff-6ed9-8c11-f998-7af784e64e8d',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/cb43c89c-2232-8012-7616-22d60ada1184',
                                            notForTagging: false,
                                            label: 'wine',
                                            broaderId:
                                                'http://data.disney.com/resources/b73269ff-6ed9-8c11-f998-7af784e64e8d',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/dd23f3c2-766e-46d6-8dde-a106cd92586d',
                                    notForTagging: false,
                                    label: 'soft drink',
                                    broaderId:
                                        'http://data.disney.com/resources/9c38e55e-3da3-49eb-eaa6-d5e80c9f6496',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/d04e478d-2e0d-2de4-e5eb-f14cfb38c5ba',
                                    notForTagging: false,
                                    label: 'milk',
                                    broaderId:
                                        'http://data.disney.com/resources/9c38e55e-3da3-49eb-eaa6-d5e80c9f6496',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/94420cd8-695a-1986-64a6-e8010b8d94bf',
                                    notForTagging: false,
                                    label: 'coffee',
                                    broaderId:
                                        'http://data.disney.com/resources/9c38e55e-3da3-49eb-eaa6-d5e80c9f6496',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/e54c51da-61d8-1549-d77e-508f9c8a90bc',
                                    notForTagging: false,
                                    label: 'fruit juice',
                                    broaderId:
                                        'http://data.disney.com/resources/9c38e55e-3da3-49eb-eaa6-d5e80c9f6496',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/d8333fef-8dfd-9446-be42-e2d1ef3d479e',
                            notForTagging: false,
                            label: 'dairy products',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ff3a13a8-9081-8a2d-9d2d-ed13dc381bb5',
                            notForTagging: false,
                            label: 'pineapple',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5d4de077-7a92-05d1-dfb3-586a8bcb47bb',
                            notForTagging: false,
                            label: 'nut',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0a46dcf6-e71e-001a-b78b-197c61c305b4',
                            notForTagging: false,
                            label: 'meat',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8fc18ea0-862b-4f26-b15c-e99725ce1aa4',
                            notForTagging: false,
                            label: 'condiment',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e031ace9-418c-bb39-9162-62ed45145b9e',
                            notForTagging: false,
                            label: 'soup',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7042fbd3-a0e5-bb61-46a2-a527b2b63dbd',
                            notForTagging: false,
                            label: 'candy',
                            broaderId:
                                'http://data.disney.com/resources/30366160-ea29-d4b7-c96d-7ff8ce3cb89f',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                    notForTagging: true,
                    label: 'Furnishing',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/a035f9f3-9853-dd05-109e-fa1893c4898e',
                            notForTagging: false,
                            label: 'medicine cabinet',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a33cd7a9-383c-eb2a-3782-cdeb97e23ba5',
                            notForTagging: false,
                            label: 'cabinet',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410288e-784f-10ae-9c75-0768b3f81e88',
                            notForTagging: false,
                            label: 'music stand',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1dd3200e-f32a-4e2c-ac78-e162626bbead',
                            notForTagging: false,
                            label: 'bedspread',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/424c3494-c9a4-f218-60b8-863b94254da7',
                            notForTagging: false,
                            label: 'sofa',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/10436b2a-054f-778b-3b9c-ea9629e4a398',
                            notForTagging: false,
                            label: 'blanket',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7e321449-e880-dfa7-a478-14572bfb2cae',
                            notForTagging: false,
                            label: 'hammock',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1d3552a4-eba7-49eb-d8df-4e8fc95996af',
                            notForTagging: false,
                            label: 'chandelier',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8d374099-fc96-8dca-5755-90ac38e3b1bb',
                            notForTagging: false,
                            label: 'rug',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/0fa55e04-8543-463b-a407-c1429dbd5ec4',
                                    notForTagging: false,
                                    label: 'magic carpet',
                                    broaderId:
                                        'http://data.disney.com/resources/8d374099-fc96-8dca-5755-90ac38e3b1bb',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/54374223-3bdd-d151-3cfc-208a540bbb96',
                            notForTagging: false,
                            label: 'candle',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c3371de3-23c8-2ca7-8e2b-285a675295a8',
                            notForTagging: true,
                            label: 'Lighting Devices',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410faa5-60a4-1334-9c16-5127b49ca688',
                            notForTagging: false,
                            label: 'menorah',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f8357a4c-174b-d96f-d0b1-f2fd36c746b0',
                            notForTagging: false,
                            label: 'lamp',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/db4143fc-3adb-5d7d-3a36-dda2dcc8669f',
                            notForTagging: false,
                            label: 'searchlight',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1b4e1d05-cbd5-20c9-14ba-16d6e2767a9d',
                            notForTagging: false,
                            label: 'lava lamp',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4332ad4e-59be-df29-a7ea-49c6d91e9c97',
                            notForTagging: false,
                            label: 'desk',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/723d34a3-b8a6-42cc-90c9-0844feef1e4c',
                            notForTagging: false,
                            label: 'display stand',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/333a02d9-4715-6daa-536b-f459dd228db3',
                            notForTagging: false,
                            label: 'lantern',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cc4dcac8-5e6c-73c2-8fa4-db035d89a781',
                            notForTagging: false,
                            label: 'filing cabinet',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/df8fa558-34ef-4204-8e49-f9d5211a363b',
                            notForTagging: false,
                            label: 'chest (furnishing)',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7431d876-f1f8-5d16-4258-ea4205d8a1ac',
                            notForTagging: false,
                            label: 'flashlight',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5c4f9fe1-e2fe-ec88-afd0-89108980d790',
                            notForTagging: false,
                            label: 'mirror',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/ea1ba867-7b9e-4ceb-a0dc-e08fdba6b610',
                                    notForTagging: false,
                                    label: 'magic mirror',
                                    broaderId:
                                        'http://data.disney.com/resources/5c4f9fe1-e2fe-ec88-afd0-89108980d790',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/d037df4f-7d29-ce1b-45e1-ecb1be1ce294',
                            notForTagging: false,
                            label: 'picture frame',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9f375494-dcc8-33c1-a753-b04e0b73f0b7',
                            notForTagging: false,
                            label: 'tapestry',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0a35435f-55f8-639a-8de9-195a6f34889a',
                            notForTagging: false,
                            label: 'bench',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ae36aa6f-0ffa-5e8f-a240-9ce9e1746f87',
                            notForTagging: false,
                            label: 'clock',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6d3fcf2f-d018-6169-0ba7-c3ab27ec8bbe',
                            notForTagging: false,
                            label: 'candelabra',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/613a7906-d362-f767-f5f0-9f930f0b2e93',
                            notForTagging: false,
                            label: 'chair',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/ee43e6d5-106f-c5b8-72d5-471dfb9e76aa',
                                    notForTagging: false,
                                    label: 'highchair',
                                    broaderId:
                                        'http://data.disney.com/resources/613a7906-d362-f767-f5f0-9f930f0b2e93',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0410408e-80cd-d234-9c75-0768b3f86c8a',
                                    notForTagging: false,
                                    label: 'rocking chair',
                                    broaderId:
                                        'http://data.disney.com/resources/613a7906-d362-f767-f5f0-9f930f0b2e93',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/cf4b19b2-1a1a-1582-2014-450fbeab1cb4',
                                    notForTagging: false,
                                    label: 'wheelchair',
                                    broaderId:
                                        'http://data.disney.com/resources/613a7906-d362-f767-f5f0-9f930f0b2e93',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/4b349b9a-f847-fe18-c3a3-b1de8d9138aa',
                                    notForTagging: false,
                                    label: 'throne',
                                    broaderId:
                                        'http://data.disney.com/resources/613a7906-d362-f767-f5f0-9f930f0b2e93',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0410408e-a858-8c36-9c75-0768b3f8758a',
                                    notForTagging: false,
                                    label: 'armchair',
                                    broaderId:
                                        'http://data.disney.com/resources/613a7906-d362-f767-f5f0-9f930f0b2e93',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/c5489974-f5fc-eb84-b12f-b316b4308c99',
                            notForTagging: false,
                            label: 'tablecloth',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e93299a4-a9dc-fe27-8a70-38dacbae01b4',
                            notForTagging: false,
                            label: 'dresser',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b9471a22-815f-e562-7a81-b8543a727194',
                            notForTagging: false,
                            label: 'lectern',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410288e-c0b4-0bc4-9c75-0768b3f83988',
                            notForTagging: false,
                            label: 'light bulb',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5b4d45a0-3409-7a9d-fd36-07ef297ea5bb',
                            notForTagging: false,
                            label: 'folding screen',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/041082a1-9800-1129-9cab-60c4a7899c88',
                            notForTagging: false,
                            label: 'alarm clock',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b341c899-ce02-7ba9-d88e-c18408b8da9f',
                            notForTagging: false,
                            label: 'bulletin board',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/97351002-83b7-10a8-7cfc-8799bc75a29e',
                            notForTagging: false,
                            label: 'curtain',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/e747768f-e848-0d58-7d6d-cb26474a2882',
                                    notForTagging: false,
                                    label: 'shower curtain',
                                    broaderId:
                                        'http://data.disney.com/resources/97351002-83b7-10a8-7cfc-8799bc75a29e',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/6e4343b5-3b5a-4b72-02ce-4f06deef07ac',
                            notForTagging: false,
                            label: 'spotlight',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b73a6179-f649-e646-a640-b4a7a03051a2',
                            notForTagging: false,
                            label: 'bookshelf',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4d3999a7-4c99-84f1-925b-02d66463b0b0',
                            notForTagging: false,
                            label: 'quilt',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fa13e063-8a51-4601-9408-17c8664ebea3',
                            notForTagging: false,
                            label: 'towel',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c647abaf-1090-6d67-b581-6c9b1de77db3',
                            notForTagging: false,
                            label: 'holiday decorations',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/03440ad6-e9a4-af3a-054e-343c750da5a9',
                            notForTagging: false,
                            label: 'mirrorball',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2e44c845-fb3f-6364-202a-cbd452f80497',
                            notForTagging: false,
                            label: 'doormat',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d6e3ee4e-8b6e-4dd9-9234-31e7a16e0cc6',
                            notForTagging: false,
                            label: 'table',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/a64c06d4-e3b8-7e66-03f6-8c26a3895986',
                                    notForTagging: false,
                                    label: 'night table',
                                    broaderId:
                                        'http://data.disney.com/resources/d6e3ee4e-8b6e-4dd9-9234-31e7a16e0cc6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/554fb1ae-a143-661f-9268-d8dd0d202f96',
                                    notForTagging: false,
                                    label: 'picnic table',
                                    broaderId:
                                        'http://data.disney.com/resources/d6e3ee4e-8b6e-4dd9-9234-31e7a16e0cc6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a7430a75-fc1d-b7d6-ff9f-6d1ef6faf3a8',
                                    notForTagging: false,
                                    label: 'workbench',
                                    broaderId:
                                        'http://data.disney.com/resources/d6e3ee4e-8b6e-4dd9-9234-31e7a16e0cc6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0410c98d-90b7-cc2b-9c75-0768b3f8a185',
                                    notForTagging: false,
                                    label: 'drawing table',
                                    broaderId:
                                        'http://data.disney.com/resources/d6e3ee4e-8b6e-4dd9-9234-31e7a16e0cc6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3e430938-b377-8d0a-d847-bf1f3044a29e',
                                    notForTagging: false,
                                    label: 'dining table',
                                    broaderId:
                                        'http://data.disney.com/resources/d6e3ee4e-8b6e-4dd9-9234-31e7a16e0cc6',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/583e2533-4d57-1199-a1d5-5b59df20118a',
                            notForTagging: false,
                            label: 'bed',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/ba4d9683-6a53-e5f1-2a01-e0b3f78764b3',
                                    notForTagging: false,
                                    label: 'crib',
                                    broaderId:
                                        'http://data.disney.com/resources/583e2533-4d57-1199-a1d5-5b59df20118a',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/024a09a5-bfb1-12cc-2663-8cb2564560af',
                                    notForTagging: false,
                                    label: 'bunk bed',
                                    broaderId:
                                        'http://data.disney.com/resources/583e2533-4d57-1199-a1d5-5b59df20118a',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5a4dcf9e-145a-5892-c14b-759162d47684',
                                    notForTagging: false,
                                    label: 'cradle',
                                    broaderId:
                                        'http://data.disney.com/resources/583e2533-4d57-1199-a1d5-5b59df20118a',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/6343cecc-e198-38e6-e2fc-cb74166212bd',
                            notForTagging: false,
                            label: 'footstool',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ce3da153-5c53-7173-1d06-d426a664cdba',
                            notForTagging: false,
                            label: 'stool',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/0410518e-c09c-a870-9c75-0768b3f87f8b',
                                    notForTagging: false,
                                    label: 'barstool',
                                    broaderId:
                                        'http://data.disney.com/resources/ce3da153-5c53-7173-1d06-d426a664cdba',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/9739cf1b-6ad8-4f29-9c81-911c228488df',
                            notForTagging: false,
                            label: 'bedsheet',
                            broaderId:
                                'http://data.disney.com/resources/67305753-c15f-b18d-e10b-6c7b376a47ac',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                    notForTagging: true,
                    label: 'Materials',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/7b4d7252-d8c9-e4ef-0149-de6c4b1fa3b8',
                            notForTagging: false,
                            label: 'wrought-iron',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2049065d-02b3-3a48-5420-a304ec9e9999',
                            notForTagging: false,
                            label: 'glue',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f2445b1c-98ea-9f01-3e75-3a3d2b92fdb9',
                            notForTagging: false,
                            label: 'gasoline',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/27a7de91-770b-45aa-8670-5c5d4a66f82b',
                            notForTagging: false,
                            label: 'metal',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/0410b08d-50ff-45e6-9c75-0768b3f86e83',
                                    notForTagging: false,
                                    label: 'gold',
                                    broaderId:
                                        'http://data.disney.com/resources/27a7de91-770b-45aa-8670-5c5d4a66f82b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/8693841b-a301-4d46-aee8-61e0c3dbc9f4',
                                    notForTagging: false,
                                    label: 'iron',
                                    broaderId:
                                        'http://data.disney.com/resources/27a7de91-770b-45aa-8670-5c5d4a66f82b',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/047958c1-5193-4df6-a2f3-c6c54a3e4219',
                            notForTagging: false,
                            label: 'paper',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fbe03a02-bd44-4e6e-ac4c-b1f575187527',
                            notForTagging: false,
                            label: 'refuse',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/4ddfc49c-9aed-4f79-ad8d-3fb4bbf79c27',
                                    notForTagging: false,
                                    label: 'hazardous waste',
                                    broaderId:
                                        'http://data.disney.com/resources/fbe03a02-bd44-4e6e-ac4c-b1f575187527',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/683c694d-8eef-0943-f5cb-ec38b483a2b1',
                            notForTagging: false,
                            label: 'shell',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/32979f88-e4fe-4ac2-96ab-38e65360fb73',
                                    notForTagging: false,
                                    label: 'conch (shell)',
                                    broaderId:
                                        'http://data.disney.com/resources/683c694d-8eef-0943-f5cb-ec38b483a2b1',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/75306b81-cf98-ef42-9e99-74f05468f89b',
                            notForTagging: false,
                            label: 'feather',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/da3084c2-ab89-4790-854b-77201ef353a9',
                            notForTagging: false,
                            label: 'drug',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5625dea7-4e23-43e8-a630-e879e79bfa67',
                            notForTagging: false,
                            label: 'fur',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fc3629d0-bad5-123d-ca5d-975f6cb9d3b6',
                            notForTagging: false,
                            label: 'rope',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/db03bcdf-2429-487b-8023-f7a048ec7420',
                            notForTagging: false,
                            label: 'wood',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6f3b5a4e-df9b-5fe8-c1a4-ce40de1d86bf',
                            notForTagging: false,
                            label: 'dynamite',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ff4831a0-24ab-632e-a679-4829c18a8e9f',
                            notForTagging: false,
                            label: 'bone',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6b364bec-0c2a-4301-9943-1d7241c01c6c',
                            notForTagging: false,
                            label: 'cloth',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8d3fa068-33e7-1b4a-55a1-61115f239691',
                            notForTagging: false,
                            label: 'dirt',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a034cc39-cace-37c0-88ea-67b6890ed58c',
                            notForTagging: false,
                            label: 'wire',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/8e357a8a-f238-f6f9-1dad-307271de36b3',
                                    notForTagging: false,
                                    label: 'barbed wire',
                                    broaderId:
                                        'http://data.disney.com/resources/a034cc39-cace-37c0-88ea-67b6890ed58c',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/4344d8c3-f7e1-4078-a261-b6c9a75183eb',
                            notForTagging: false,
                            label: 'salt',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b63f0b33-5f25-3fdd-9d3c-0e2dd2cece83',
                            notForTagging: false,
                            label: 'rock',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/8449f591-a4e7-4d47-29d0-260cd37886b8',
                                    notForTagging: false,
                                    label: 'boulder',
                                    broaderId:
                                        'http://data.disney.com/resources/b63f0b33-5f25-3fdd-9d3c-0e2dd2cece83',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/824a7fa8-0f00-90d6-0217-1df67921fcbc',
                                    notForTagging: false,
                                    label: 'cobblestone',
                                    broaderId:
                                        'http://data.disney.com/resources/b63f0b33-5f25-3fdd-9d3c-0e2dd2cece83',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ce3911a4-8ef9-9ccb-5651-87d0ad073ca3',
                                    notForTagging: false,
                                    label: 'stone',
                                    broaderId:
                                        'http://data.disney.com/resources/b63f0b33-5f25-3fdd-9d3c-0e2dd2cece83',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/134e3791-e0ad-f73d-8810-753958b0aa9f',
                            notForTagging: false,
                            label: 'cinder block',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/437d50e0-8c20-4db4-9270-56d7b3da7634',
                            notForTagging: false,
                            label: 'concrete',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d14e213b-0ec5-7c17-20f9-e40998daee98',
                            notForTagging: false,
                            label: 'brick',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/96327418-5d06-5116-6b32-b1bc1b782e8a',
                            notForTagging: false,
                            label: 'building material',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/850bcc68-f612-4aec-832f-51483c5ac0db',
                            notForTagging: false,
                            label: 'string',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0c44ea59-f1ef-9bbe-3562-42b15270a595',
                            notForTagging: false,
                            label: 'sand',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6445c71a-72b7-3284-f659-1b7873c89ea7',
                            notForTagging: false,
                            label: 'poison',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/204059d8-4fc6-389d-6372-9decf9abef80',
                            notForTagging: false,
                            label: 'coal',
                            broaderId:
                                'http://data.disney.com/resources/e2396250-363c-c870-8dee-91af8b34a1bf',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                    notForTagging: true,
                    label: 'Musical Instruments',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/bf4fb579-cd5b-e23e-5245-a9e0e369b882',
                            notForTagging: false,
                            label: 'harmonica',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fa44f6b0-bbce-f0c7-2b9f-eb1d9d122599',
                            notForTagging: false,
                            label: 'cymbal',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3d43c583-e810-eb9c-87f4-9c693b3bd4af',
                            notForTagging: false,
                            label: 'clarinet',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/644c793e-3860-11ab-b610-b1fa3520e19d',
                            notForTagging: false,
                            label: 'cowbell',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f743b473-1635-4e3e-8cbb-04b5ad4b7baa',
                            notForTagging: false,
                            label: 'trumpet',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f54e4dd5-d9b2-540b-3440-f1aa24fa9e89',
                            notForTagging: false,
                            label: 'trombone',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e1441f6b-a9fb-127a-99ee-581b4e871396',
                            notForTagging: false,
                            label: 'accordion',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/78445f3f-62e1-7139-56b1-ccfa2884138d',
                            notForTagging: false,
                            label: 'conga',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0a44f115-906b-cd0d-a566-aa749b99a380',
                            notForTagging: false,
                            label: 'drum',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d229d8c2-4ec1-4ea4-bc30-49e68b0fd717',
                            notForTagging: false,
                            label: 'wind instrument',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7a461b49-37fd-ae77-b6cd-66ea8c636b99',
                            notForTagging: false,
                            label: 'saxophone',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/861af20e-593b-42a8-99bc-4d3efbcd43b0',
                            notForTagging: false,
                            label: 'brass instrument',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0c3bb0ec-8d1f-fca4-5a2e-0ccd5cffb9bb',
                            notForTagging: false,
                            label: 'guitar',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/4b40f595-534a-6f91-9152-d3fdd5d429ad',
                                    notForTagging: false,
                                    label: 'electric guitar',
                                    broaderId:
                                        'http://data.disney.com/resources/0c3bb0ec-8d1f-fca4-5a2e-0ccd5cffb9bb',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/22475467-e39f-5621-4ccb-73307e3d9893',
                            notForTagging: false,
                            label: 'bell',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/214c2505-840c-401f-4d8b-b0dee2c4caa8',
                            notForTagging: false,
                            label: 'banjo',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410db8d-0812-e8e9-9c75-0768b3f80587',
                            notForTagging: false,
                            label: 'violin',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a5396b2b-8b2e-c941-4dd0-d4441bd6da94',
                            notForTagging: false,
                            label: 'piano',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f9441a9e-603b-a312-4df5-f67e48fd4992',
                            notForTagging: false,
                            label: 'bongo',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/26424ca7-c49c-0f0b-ca0a-357a9440b292',
                            notForTagging: false,
                            label: 'whistle',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5c63d251-e908-44b7-af3d-7654a6303b24',
                            notForTagging: false,
                            label: 'percussion instrument',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3dd961e1-5dc9-4d7d-ba5c-05126a1bb14c',
                            notForTagging: false,
                            label: 'stringed instrument',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ac4bbc62-cafd-df82-6d24-7a30f0107598',
                            notForTagging: false,
                            label: 'harp',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5f4b1f6b-0411-eef0-64cd-4da680a0019a',
                            notForTagging: false,
                            label: 'organ',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8041aa19-4fd3-8efb-51a0-71d79026a69a',
                            notForTagging: false,
                            label: 'flute',
                            broaderId:
                                'http://data.disney.com/resources/c54c8204-3529-7316-3703-d4ef32243184',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                    notForTagging: true,
                    label: 'Natural Phenomena',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/7c335991-8608-96fd-a0eb-b24e35c69994',
                            notForTagging: false,
                            label: 'rainbow',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9aec5678-30ac-44b6-af7e-063f97e131a7',
                            notForTagging: false,
                            label: 'comet',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6c3c456e-ced0-77c2-5702-46ba817eb78c',
                            notForTagging: false,
                            label: 'lightning',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/71448615-e529-281f-977b-b46f9f263aa1',
                            notForTagging: false,
                            label: 'ice',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cd4dcbd1-2458-7327-deba-e1fa0836ba97',
                            notForTagging: false,
                            label: 'meteor',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/66418d82-0a64-ebec-30b9-12fbcb16588e',
                            notForTagging: false,
                            label: 'tornado',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/24344c35-7571-ce5c-6a19-267cf533c591',
                            notForTagging: false,
                            label: 'cloud',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e73975e6-1f76-d017-e461-95df885c6790',
                            notForTagging: false,
                            label: 'snow',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5e6f9367-20e3-4abd-9723-ac23f3133c23',
                            notForTagging: false,
                            label: 'fire (object)',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a9362b4f-52e9-b81a-ffff-2883065f0faf',
                            notForTagging: false,
                            label: 'rain',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/453f16d3-c666-9ced-31a3-59961ffaa788',
                            notForTagging: false,
                            label: 'wave',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a21c4f2b-4a20-4ada-8bb5-f327a9316848',
                            notForTagging: false,
                            label: 'star (celestial body)',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6ffa8590-f1cd-465f-8203-b3ce6f3ce7f9',
                            notForTagging: false,
                            label: 'eclipse',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1f37e7f9-9989-8967-d0e6-9772ef6b5a98',
                            notForTagging: false,
                            label: 'aurora',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4e399006-206e-23f2-4e51-cf9b5d37f293',
                            notForTagging: false,
                            label: 'water',
                            broaderId:
                                'http://data.disney.com/resources/683ce809-305c-c080-f606-7df94d5ffb85',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                    notForTagging: true,
                    label: 'Objects',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/64864c6b-56fa-4d95-b743-6f98f5d41495',
                            notForTagging: false,
                            label: 'fire hydrant',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2e3f0951-b3b0-a8a3-a581-5cd9ddd0d69b',
                            notForTagging: false,
                            label: 'banner',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ed48653b-a255-b3a9-cf46-b90c198c2cbe',
                            notForTagging: false,
                            label: 'sticks',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6249e534-a146-6512-b088-ebe076bd7b9a',
                            notForTagging: false,
                            label: 'medal',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6b14a38a-1a43-421f-8606-13e4870359be',
                            notForTagging: false,
                            label: 'ant farm',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0ec027a3-0672-49a6-b7db-a05350175cce',
                            notForTagging: false,
                            label: 'scrolls',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1548337f-ad71-4590-7c9f-b34ebc1e0f97',
                            notForTagging: false,
                            label: 'Jolly Roger',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e5438b76-e242-b521-373a-e61a387fc180',
                            notForTagging: false,
                            label: 'baby rattle',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9ec5c893-fbc1-4668-840b-78de3c023d6f',
                            notForTagging: false,
                            label: 'street light',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2d8b37e1-dfb4-4395-ad6a-8cabf6d275f8',
                            notForTagging: false,
                            label: 'book',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/f73d3c71-77a4-4df3-a722-e438ee784f54',
                                    notForTagging: false,
                                    label: 'songbook',
                                    broaderId:
                                        'http://data.disney.com/resources/2d8b37e1-dfb4-4395-ad6a-8cabf6d275f8',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b2ed49d2-e46d-491f-a3f9-c8c6cd88f6c0',
                                    notForTagging: false,
                                    label: 'sacred text',
                                    broaderId:
                                        'http://data.disney.com/resources/2d8b37e1-dfb4-4395-ad6a-8cabf6d275f8',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/499201ac-f05b-4c1f-836b-529a923a23dc',
                                    notForTagging: false,
                                    label: 'ritual',
                                    broaderId:
                                        'http://data.disney.com/resources/2d8b37e1-dfb4-4395-ad6a-8cabf6d275f8',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/e9e08fb4-e1c7-466f-86aa-36f8e82bf293',
                            notForTagging: false,
                            label: 'figurine',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/67cb64f6-87ad-491e-bacb-e375b4e57e9c',
                            notForTagging: false,
                            label: 'doors',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410c98d-b8de-6881-9c75-0768b3f8cf85',
                            notForTagging: false,
                            label: 'memorabilia',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a84ccb2b-5206-162f-14b6-456eeafcf5bc',
                            notForTagging: false,
                            label: 'ribbon (material)',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ff4e4be6-70b3-8fea-b007-b27aa689a2ba',
                            notForTagging: false,
                            label: 'science project',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/78452797-7e81-fdc3-6380-aff9a72948a5',
                            notForTagging: false,
                            label: 'poker chip',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6d416cff-99a0-c509-5d9f-17268fc835b9',
                            notForTagging: false,
                            label: 'warning sign',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a4488578-161c-0df7-4367-fc4e1888c5ad',
                            notForTagging: false,
                            label: 'snowball',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1ff57814-a94a-4dee-949e-a8d580af2c17',
                            notForTagging: false,
                            label: 'signature',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/16421123-bda7-66f5-4d27-da4f0d69b7a8',
                            notForTagging: false,
                            label: 'dice',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ac414fbc-71d2-b66d-6ab6-9d2d5aa598a1',
                            notForTagging: false,
                            label: 'snowglobe',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d233dfd9-5147-4afd-bdfe-786c745d94cf',
                            notForTagging: false,
                            label: 'amulet',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410ef9f-380e-2e6b-9cab-60c4a789488d',
                            notForTagging: false,
                            label: 'audio-animatronic figure',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/94d154fb-7223-4546-82a2-4de1852215ae',
                            notForTagging: false,
                            label: 'recipe',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1e421ef9-6b3e-325b-41a6-b1b56c8aa68b',
                            notForTagging: false,
                            label: 'hunting trophy',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4c428d3c-fa92-367b-1b73-61b96e9c5fb7',
                            notForTagging: false,
                            label: 'rocking horse',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/103fc9ec-7a02-40d9-b7bf-4f8e8e2d7603',
                            notForTagging: false,
                            label: 'advertisement',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/934f64a1-f722-c4c5-bcd2-2a4a99b43199',
                            notForTagging: false,
                            label: 'paper airplane',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/42302438-af8e-5c32-0907-aa4174df24b3',
                            notForTagging: false,
                            label: 'puppet',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3a9350bb-2bb9-4564-9fbf-82679cb5f41d',
                            notForTagging: false,
                            label: 'computer program',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6346f193-32c5-ee0a-1ee1-bc94aee9979a',
                            notForTagging: false,
                            label: 'traffic light',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9c9a3dd9-134a-4a29-8ef9-52e8b50ced2c',
                            notForTagging: false,
                            label: 'contracts',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f740d50e-e320-b6ac-876e-9dfd682eda93',
                            notForTagging: false,
                            label: 'dollhouse',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/203fae40-1bac-4da8-3ca7-d459a02810ac',
                            notForTagging: false,
                            label: 'gift',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/644ad4cd-d832-47f7-bf43-ff868adf16f5',
                            notForTagging: false,
                            label: 'counterfeit',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4d459337-f432-9418-9281-b6fc4acb6ca4',
                            notForTagging: false,
                            label: 'crystal ball',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/63f92d88-2d42-4031-a5b2-9b102806a3e2',
                            notForTagging: false,
                            label: 'magazine',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/631989bd-b2c9-4611-bc0b-8547dd5d1aec',
                            notForTagging: false,
                            label: 'newspaper',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2134e111-1281-ce10-3b6b-25145a65b7ab',
                            notForTagging: false,
                            label: 'balloon',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410fda5-6820-4be8-9c16-5127b49ce083',
                            notForTagging: false,
                            label: 'gem',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/174fdbc6-3f6a-04c9-5a9b-72140a997782',
                            notForTagging: false,
                            label: 'dreidel',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e46a98ad-ac37-42df-8a78-fb5adbe3ad0c',
                            notForTagging: false,
                            label: 'rack',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/23453c5d-d9a9-a162-06cd-edd2095d0388',
                            notForTagging: false,
                            label: 'pinata',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/903f0a46-03a8-6f4b-14aa-b62ea79ff89d',
                            notForTagging: false,
                            label: 'flag',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7e476de1-7782-d96e-027e-6ee0fd73e7a6',
                            notForTagging: false,
                            label: 'chain link fencing',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ec4a4b87-e907-cee2-8481-ba8daededd89',
                            notForTagging: false,
                            label: 'traffic sign',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c04c3703-6937-96dc-b7c3-72151d44fca2',
                            notForTagging: false,
                            label: 'puzzle',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1a3abcc7-7b12-5ee9-16d2-ae893c3011b6',
                            notForTagging: false,
                            label: 'doll',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/4949e55c-9927-4779-c718-2ac71422578f',
                                    notForTagging: false,
                                    label: 'toy soldier',
                                    broaderId:
                                        'http://data.disney.com/resources/1a3abcc7-7b12-5ee9-16d2-ae893c3011b6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/364896d1-a42c-20bb-a517-69d06e3c04ba',
                                    notForTagging: false,
                                    label: 'voodoo doll',
                                    broaderId:
                                        'http://data.disney.com/resources/1a3abcc7-7b12-5ee9-16d2-ae893c3011b6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/314d1a71-31fa-7afe-62fa-c859d2878196',
                                    notForTagging: false,
                                    label: 'action figure',
                                    broaderId:
                                        'http://data.disney.com/resources/1a3abcc7-7b12-5ee9-16d2-ae893c3011b6',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/ce3986ae-935d-89d5-e9f1-1381366f52af',
                            notForTagging: false,
                            label: 'badge',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f934bb4f-1fa1-6529-1292-552863e08eb5',
                            notForTagging: false,
                            label: 'Olympic flame',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6815d713-7495-4cfa-bfbe-afdd0fd54ff3',
                            notForTagging: false,
                            label: 'talisman',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2c479619-20f8-0e3b-c914-f9d4444a0a85',
                            notForTagging: false,
                            label: 'garden gnome',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8a3c120b-8e35-869f-45a9-7a62c0556d9f',
                            notForTagging: false,
                            label: 'ball (object)',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/34fc70b9-3278-40d0-9e5f-8c9bc50063a5',
                            notForTagging: false,
                            label: 'tags',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4241717d-bed6-7901-7bb7-b4831048589b',
                            notForTagging: false,
                            label: 'hobbyhorse',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d9421505-4be0-25e1-6270-39620484ddac',
                            notForTagging: false,
                            label: 'money',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ba76e57f-7582-47f5-ad64-2178be051130',
                            notForTagging: false,
                            label: 'decoration',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e64662e3-a921-f5d5-b05e-b665d9ad9ca6',
                            notForTagging: false,
                            label: 'scepter',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/df30fdfb-c801-a885-3547-710de3d7c589',
                            notForTagging: false,
                            label: 'award',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/b1ea29db-908a-4b20-ab09-e072039015b6',
                                    notForTagging: false,
                                    label: 'trophy',
                                    broaderId:
                                        'http://data.disney.com/resources/df30fdfb-c801-a885-3547-710de3d7c589',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/041005a0-90a5-8c43-9cab-60c4a789cd8f',
                            notForTagging: false,
                            label: 'jack-o-lantern',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/794e2803-bb80-e88a-6621-7fa714defc9e',
                            notForTagging: false,
                            label: 'kite',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7a19379d-4530-4915-81d7-8348b08cb33a',
                            notForTagging: false,
                            label: 'disguise',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                            notForTagging: false,
                            label: 'toy',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/f73559f4-3727-0e4a-b356-f6b06ee455af',
                                    notForTagging: false,
                                    label: 'pogo stick',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a441adc0-bb22-b756-4d14-dac97cf0ac99',
                                    notForTagging: false,
                                    label: 'Tinkertoy',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ba476a48-2ab1-4d9e-5d38-1749a34b5eb3',
                                    notForTagging: false,
                                    label: 'Spirograph',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1e4bf97c-9403-e518-3d6b-f320634712be',
                                    notForTagging: false,
                                    label: 'windup toy',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/4e333ac2-cb45-d55b-9897-5088ce899aae',
                                    notForTagging: false,
                                    label: 'soft toy',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/244ff7c7-fb5e-0f69-4bab-bc9252d894b2',
                                            notForTagging: false,
                                            label: 'teddy bear',
                                            broaderId:
                                                'http://data.disney.com/resources/4e333ac2-cb45-d55b-9897-5088ce899aae',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/de4b8eb7-08e4-7831-18a3-69358ef4d289',
                                    notForTagging: false,
                                    label: 'push-pull toy',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/8231f57b-fb3b-c8a3-39cb-83da85759f92',
                                    notForTagging: false,
                                    label: 'block (toy)',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/d73828b9-3adf-e927-7dcc-aa0baa4c67b6',
                                    notForTagging: false,
                                    label: 'yo-yo',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/cc4ab904-7bd0-1908-7cf9-c79919d5e898',
                                    notForTagging: false,
                                    label: 'jump rope',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6e4b9db9-b597-baa5-7ccb-3e8f28c54bb9',
                                    notForTagging: false,
                                    label: 'water pistol',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1036d9bb-e2b9-353f-6787-3940f797de93',
                                    notForTagging: false,
                                    label: 'Hula-Hoop',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a84004e3-c244-b6e4-393f-4ec24414f48f',
                                    notForTagging: false,
                                    label: 'Etch A Sketch',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/1935362b-2d6c-effc-8b4a-b87eaa7780b5',
                                    notForTagging: false,
                                    label: 'jack-in-the-box',
                                    broaderId:
                                        'http://data.disney.com/resources/a29e26dd-c07f-4d60-b4ca-710cf465b982',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/ef3720fb-5fc1-081f-b41c-28c2e9ce5190',
                            notForTagging: false,
                            label: 'prop',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/be6c48a0-c70d-4111-a639-09796dd582ab',
                            notForTagging: false,
                            label: 'invention',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b73d5f2d-8842-a465-ce8a-eceb4bf5e68c',
                            notForTagging: false,
                            label: 'artifacts',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/64092e86-d11f-4f8b-b08f-6cc8936e2338',
                            notForTagging: false,
                            label: 'billboard',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/114ddb34-468e-3f53-56ca-4f526ca4fd8d',
                            notForTagging: false,
                            label: 'confetti',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cc4a5406-3b9b-f3fb-f969-787a0ab334b3',
                            notForTagging: false,
                            label: 'magic wand',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0535b5a7-432e-58a2-b41b-9629f59b58b2',
                            notForTagging: false,
                            label: 'treasure trove',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e2152bd0-dcb5-4efd-8431-40fa6aaa192c',
                            notForTagging: false,
                            label: 'instructions',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8ec143b4-a8d1-495b-89f8-fc32c1efafda',
                            notForTagging: false,
                            label: 'memorials',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/33366b35-ea44-2e0d-32fe-e154eb189b90',
                            notForTagging: false,
                            label: 'tobacco products',
                            broaderId:
                                'http://data.disney.com/resources/0d33e4f5-0dc7-fb06-e422-2dd3189d4bb7',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/9b4409e5-c084-b72c-4767-36c127365aac',
                    notForTagging: true,
                    label: 'Plants',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/b846101b-a60c-97bf-7091-b2152e09d28c',
                            notForTagging: false,
                            label: 'grass',
                            broaderId:
                                'http://data.disney.com/resources/9b4409e5-c084-b72c-4767-36c127365aac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8cbc9c4b-0ff1-40ac-894e-516c040ae23d',
                            notForTagging: false,
                            label: 'houseplant',
                            broaderId:
                                'http://data.disney.com/resources/9b4409e5-c084-b72c-4767-36c127365aac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/258f7a1b-5ae1-4c9b-9726-0061deaadc94',
                            notForTagging: false,
                            label: 'seed',
                            broaderId:
                                'http://data.disney.com/resources/9b4409e5-c084-b72c-4767-36c127365aac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5b4fb077-1104-15b1-0db4-e5ad09bce6a3',
                            notForTagging: false,
                            label: 'flower',
                            broaderId:
                                'http://data.disney.com/resources/9b4409e5-c084-b72c-4767-36c127365aac',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/72c81807-9ae0-400c-a1ce-ab84a398cbd6',
                                    notForTagging: false,
                                    label: 'rose',
                                    broaderId:
                                        'http://data.disney.com/resources/5b4fb077-1104-15b1-0db4-e5ad09bce6a3',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/fe3d415f-74e9-116f-5d05-6033679c9a84',
                            notForTagging: false,
                            label: 'cactus',
                            broaderId:
                                'http://data.disney.com/resources/9b4409e5-c084-b72c-4767-36c127365aac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/794fd3e6-43c0-6a3c-0fcc-fbbad8718d9d',
                            notForTagging: false,
                            label: 'bush or shrub',
                            broaderId:
                                'http://data.disney.com/resources/9b4409e5-c084-b72c-4767-36c127365aac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/689e2ae7-e06f-4960-87fc-910412219686',
                            notForTagging: false,
                            label: 'vine',
                            broaderId:
                                'http://data.disney.com/resources/9b4409e5-c084-b72c-4767-36c127365aac',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ed3a80cb-f837-2caa-1f93-b0b99ed86580',
                            notForTagging: false,
                            label: 'tree',
                            broaderId:
                                'http://data.disney.com/resources/9b4409e5-c084-b72c-4767-36c127365aac',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/4fd2c084-4401-4046-a640-661894deb0d9',
                                    notForTagging: false,
                                    label: 'dead tree',
                                    broaderId:
                                        'http://data.disney.com/resources/ed3a80cb-f837-2caa-1f93-b0b99ed86580',
                                    terms: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/32328106-f1bc-8dc7-deb5-6b265611ac9c',
                    notForTagging: true,
                    label: 'System Components',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/e049bdd2-2e28-ecc4-a8cc-779fcdcdc69a',
                            notForTagging: false,
                            label: 'elevator shaft',
                            broaderId:
                                'http://data.disney.com/resources/32328106-f1bc-8dc7-deb5-6b265611ac9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6a4a3500-ddc9-9a98-b94d-f397f503e2a4',
                            notForTagging: false,
                            label: 'pipeline',
                            broaderId:
                                'http://data.disney.com/resources/32328106-f1bc-8dc7-deb5-6b265611ac9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/16c4ce9b-9102-4bdf-bc28-537decdd4d54',
                            notForTagging: false,
                            label: 'sewer system',
                            broaderId:
                                'http://data.disney.com/resources/32328106-f1bc-8dc7-deb5-6b265611ac9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/041047a5-18b4-96ec-9c16-5127b49c108b',
                            notForTagging: true,
                            label: 'Plumbing Fixtures',
                            broaderId:
                                'http://data.disney.com/resources/32328106-f1bc-8dc7-deb5-6b265611ac9c',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/3b1054d8-9de7-4380-8693-18fd8805a17c',
                                    notForTagging: false,
                                    label: 'bathtub',
                                    broaderId:
                                        'http://data.disney.com/resources/041047a5-18b4-96ec-9c16-5127b49c108b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/fbbdcad8-8d71-48ce-8b58-b2ab2d4d22d3',
                                    notForTagging: false,
                                    label: 'chemical toilet',
                                    broaderId:
                                        'http://data.disney.com/resources/041047a5-18b4-96ec-9c16-5127b49c108b',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2f403ec0-82bf-49e9-f606-1f2254e4e181',
                                    notForTagging: false,
                                    label: 'hot tub',
                                    broaderId:
                                        'http://data.disney.com/resources/041047a5-18b4-96ec-9c16-5127b49c108b',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/ff43aace-c46c-84f8-850d-86de07191b98',
                            notForTagging: false,
                            label: 'air duct',
                            broaderId:
                                'http://data.disney.com/resources/32328106-f1bc-8dc7-deb5-6b265611ac9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d3aac4c1-7fb9-498e-9337-9b444ea58a33',
                            notForTagging: false,
                            label: 'elevator',
                            broaderId:
                                'http://data.disney.com/resources/32328106-f1bc-8dc7-deb5-6b265611ac9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e81540a2-a9d1-4a13-9d77-2a184e3b2939',
                            notForTagging: false,
                            label: 'pneumatic tube',
                            broaderId:
                                'http://data.disney.com/resources/32328106-f1bc-8dc7-deb5-6b265611ac9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a9ac45db-e42f-4f9b-9baa-f8dce0e124f6',
                            notForTagging: false,
                            label: 'air shaft',
                            broaderId:
                                'http://data.disney.com/resources/32328106-f1bc-8dc7-deb5-6b265611ac9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/04103b9f-c096-f263-9c1c-a9ae04b5f987',
                            notForTagging: false,
                            label: 'manhole',
                            broaderId:
                                'http://data.disney.com/resources/32328106-f1bc-8dc7-deb5-6b265611ac9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b640d2da-4085-1c46-ca29-dbb251324488',
                            notForTagging: false,
                            label: 'escalator',
                            broaderId:
                                'http://data.disney.com/resources/32328106-f1bc-8dc7-deb5-6b265611ac9c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/55a8cf02-c5f8-4c2e-9cdd-393a87c1de10',
                            notForTagging: false,
                            label: 'conveyor',
                            broaderId:
                                'http://data.disney.com/resources/32328106-f1bc-8dc7-deb5-6b265611ac9c',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                    notForTagging: true,
                    label: 'Vehicles',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/854842ba-b212-fb38-e9b8-f24807881690',
                            notForTagging: false,
                            label: 'rocket',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2ccdcbca-3527-457a-b97c-ade0a7ee6d12',
                            notForTagging: false,
                            label: 'boat',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9d36e13f-68e3-0d57-5e3f-207ba7bff988',
                            notForTagging: false,
                            label: 'jeep',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a1425b21-2709-538e-3cb7-ab53c8c39a9d',
                            notForTagging: false,
                            label: 'sport utility vehicle',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8f4d25e2-c81e-5a7b-8621-d252444de19c',
                            notForTagging: false,
                            label: 'motor scooter',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6f4d9300-32da-5024-2352-30b8e64fac87',
                            notForTagging: false,
                            label: 'canoe',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dfba5e85-6070-4a26-8472-d7863dbe7a54',
                            notForTagging: false,
                            label: 'space vehicle',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/655d579d-cbab-40c2-ba6e-fc72d5823ec0',
                            notForTagging: false,
                            label: 'houseboat',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c45a1d46-d985-4fb2-91a9-b34cadc999ca',
                            notForTagging: false,
                            label: 'sled',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4042f4a2-18e6-aa27-062f-b484eb142ca7',
                            notForTagging: false,
                            label: 'motorboat',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c903d13f-1930-4b5d-9a89-664587f5ebff',
                            notForTagging: false,
                            label: 'police car',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a9330419-4bec-e53e-2708-958db564ea93',
                            notForTagging: false,
                            label: 'tank (military vehicle)',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/02efb28d-99c3-46d7-8f26-0782f06a910b',
                            notForTagging: false,
                            label: 'truck',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/704886f4-b83a-f685-5754-ae2ce78a6b93',
                            notForTagging: false,
                            label: 'kayak',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/16238e70-17ad-4069-9059-c9ffd2fcb06b',
                            notForTagging: false,
                            label: 'submarine',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d320e264-29ab-4330-8ece-043b32727f6b',
                            notForTagging: false,
                            label: 'fire engine',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6962ebc9-fc62-461d-a628-062ff7251408',
                            notForTagging: false,
                            label: 'ship',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/353e6054-7249-4e0b-a3aa-55999f3545c9',
                            notForTagging: false,
                            label: 'aircraft carrier',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/68b766b0-7026-4a9a-8816-9bdc00dc05f0',
                            notForTagging: false,
                            label: 'tram',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/64909fb4-bc2d-430c-9e1c-a73cd922d2a8',
                            notForTagging: false,
                            label: 'drone aircraft',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/df4a376a-8cf2-c43f-32d3-1c13ee1515a3',
                            notForTagging: false,
                            label: 'hearse',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b5bfb9e6-b3bb-43cf-8a1f-40e3c9193cc3',
                            notForTagging: false,
                            label: 'carriage',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/73f3fc46-f23c-4dea-8910-803652fb131e',
                            notForTagging: false,
                            label: 'ferry',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/272e88ec-5238-4651-9bb9-68fff2dbf118',
                            notForTagging: false,
                            label: 'food truck',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5509f298-a76e-4204-b076-b9339401110a',
                            notForTagging: false,
                            label: 'time machine',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1758c15a-041f-4f0c-92b6-3b5aa62b4d40',
                            notForTagging: false,
                            label: 'pilothouse',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9c49f452-daf4-4d86-a1ed-d02becf10050',
                            notForTagging: false,
                            label: 'cart',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d2e9fa9f-4db4-4391-8a17-bd928e77d94d',
                            notForTagging: false,
                            label: 'engine room',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2e005d77-6ae4-419f-85f1-38f3d6eb8853',
                            notForTagging: false,
                            label: 'baggage cart',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/474b695c-2538-2916-38d5-8809999413a1',
                            notForTagging: false,
                            label: 'pickup truck',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ebf90ddc-a126-477b-8088-c5d0dc4c5b43',
                            notForTagging: false,
                            label: 'cargo hold',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/67473909-244d-899f-1644-b08b3f1b4686',
                            notForTagging: false,
                            label: 'cabin (vehicle component)',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1b4b2d14-ff7b-791e-d8ad-56b1479498a2',
                            notForTagging: false,
                            label: 'gondola',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a0e78650-fbc9-4026-aaa3-4451a9a8a33a',
                            notForTagging: false,
                            label: 'airplane wing',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/584241bf-f0ff-fa69-c781-455ec55db796',
                            notForTagging: false,
                            label: 'deck (ship)',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fbee908e-7548-494d-bb01-734589c2dcdc',
                            notForTagging: false,
                            label: 'airplane',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/15387105-6b54-3a41-12af-23a1a555bd88',
                                    notForTagging: false,
                                    label: 'bomber',
                                    broaderId:
                                        'http://data.disney.com/resources/fbee908e-7548-494d-bb01-734589c2dcdc',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/22027158-3d9e-4816-ae5b-6c676c692503',
                                    notForTagging: false,
                                    label: 'fighter plane',
                                    broaderId:
                                        'http://data.disney.com/resources/fbee908e-7548-494d-bb01-734589c2dcdc',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/fe41c3af-4fc3-d3ca-6913-82ed0772b090',
                                    notForTagging: true,
                                    label: 'seaplane',
                                    broaderId:
                                        'http://data.disney.com/resources/fbee908e-7548-494d-bb01-734589c2dcdc',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/d4fa0a02-a439-4bdc-a7e4-9296a5a99768',
                            notForTagging: false,
                            label: 'railroad car',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/45422faa-b3e7-32b9-fa2a-4824f9238ba7',
                            notForTagging: false,
                            label: 'fuselage',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e8512e2d-0b6f-4926-a235-0c4eb284e4ea',
                            notForTagging: false,
                            label: 'helicopter',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/08333a84-4f8c-91d1-8e45-1154ce66028b',
                            notForTagging: false,
                            label: 'train',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/61311c67-3b25-7472-1c0b-304f24bafa87',
                            notForTagging: false,
                            label: 'cruise ship',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0e6e436e-b1d8-4f5d-8ef8-ea6614d4475c',
                            notForTagging: false,
                            label: 'limousine',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/74a4b57a-60a1-4af5-9f05-f7b5fa3c5887',
                            notForTagging: false,
                            label: 'trailer',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5d37bba7-be97-9aa8-43d9-d68dd4765aaa',
                            notForTagging: false,
                            label: 'bicycle',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6a422005-d8c6-edbc-890c-8a000606929a',
                            notForTagging: false,
                            label: 'convoy',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b2c0b0e7-af53-476b-a327-dbe4d655c293',
                            notForTagging: false,
                            label: 'recreational vehicle',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b163a448-80da-441e-9fa0-84ec6a77f8ff',
                            notForTagging: false,
                            label: 'go-karts',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a913aeeb-81a6-47d5-9ed9-6463b6424a23',
                            notForTagging: false,
                            label: 'wagon',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/015c7de8-3a5b-4720-9ffa-55d31343b729',
                            notForTagging: false,
                            label: 'main cabin',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/12f24127-6f98-49c4-8ee9-fade0b5b39ca',
                            notForTagging: false,
                            label: 'sleigh',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f838bb6a-85d1-9429-dc3e-f92627d79289',
                            notForTagging: true,
                            label: 'Air and Space Vehicles',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/43431bb3-2e23-472c-a573-5ed06c7d2e50',
                                    notForTagging: false,
                                    label: 'hot air balloon',
                                    broaderId:
                                        'http://data.disney.com/resources/f838bb6a-85d1-9429-dc3e-f92627d79289',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/993d0648-a745-8807-a12c-b07f2f490199',
                            notForTagging: false,
                            label: 'raft',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e370406b-6708-4051-89c5-6d25daf7bf2a',
                            notForTagging: false,
                            label: 'monorail',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4c351e22-415d-4a1a-aff0-e65c48ce1bcd',
                            notForTagging: false,
                            label: 'blimp',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/42bc1ec2-2c19-4da3-920e-2a004ddec165',
                            notForTagging: false,
                            label: 'tire',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9e279068-0610-4641-beee-809e9596eb9b',
                            notForTagging: false,
                            label: 'armored personnel carrier',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1a292765-7590-4d9d-b94a-5b030dc02531',
                            notForTagging: false,
                            label: 'unidentified flying object',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cc4d961c-8753-5a88-8498-bc14dc6a6e81',
                            notForTagging: false,
                            label: 'convertible',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9745c641-f519-9f10-7f7c-953e0ac9ebb1',
                            notForTagging: false,
                            label: 'golf cart',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4a5b322f-e88b-43fa-96bb-ecd142e74582',
                            notForTagging: false,
                            label: 'subway train',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5ed8b0f3-5df5-4de2-bac7-9dc28eb06678',
                            notForTagging: false,
                            label: 'personal propulsion unit',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a995e35e-212a-43b2-9633-2be84074fac7',
                            notForTagging: false,
                            label: 'cockpit',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3f4d8c1c-3860-7f64-9010-73f93c4a5db1',
                            notForTagging: false,
                            label: 'yacht',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0b3ded88-59dd-ef89-55f3-6cb3c7bbd9bb',
                            notForTagging: true,
                            label: 'Land Vehicles',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b9b6bfe6-70f3-4503-9c08-c6721b270750',
                            notForTagging: false,
                            label: 'automobile',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/89393d6c-30ab-f2d9-c028-aceeff8e65b6',
                            notForTagging: false,
                            label: 'motorcycle',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3fc04e9d-edfe-401a-b0ad-3df80b9f0838',
                            notForTagging: false,
                            label: 'float',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/781e44fd-63c0-45e1-bd9d-31ce375f1e43',
                            notForTagging: false,
                            label: 'ambulance',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/da30dc22-b27a-7a1c-551f-06e980c6aca8',
                            notForTagging: true,
                            label: 'Vehicle Components',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1291b14c-e435-4244-99bf-9f716dc9b59b',
                            notForTagging: false,
                            label: 'van',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b431b61d-c498-0b46-59a0-7fedbf94edb8',
                            notForTagging: true,
                            label: 'Water Vehicles',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6b4360ad-1f16-4bfe-b200-5feaec153cb1',
                            notForTagging: false,
                            label: 'all terrain vehicle',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/18987b27-1796-43a2-81e7-e37c3bdcfa14',
                            notForTagging: false,
                            label: 'bus',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/10fa34ab-24a3-4386-976e-6316ac7a2afb',
                                    notForTagging: false,
                                    label: 'school bus',
                                    broaderId:
                                        'http://data.disney.com/resources/18987b27-1796-43a2-81e7-e37c3bdcfa14',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/3c4d3d6f-2ef5-1223-3f68-2a8ddd425083',
                            notForTagging: false,
                            label: 'travel trailer',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c1b5d9ff-8238-4505-bafc-33a2ad84b4c2',
                            notForTagging: false,
                            label: 'taxicab',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dd467c16-80b6-d04e-d7c9-10fbec1a288e',
                            notForTagging: false,
                            label: 'rickshaw',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ba0d7a13-c0b6-4c32-a3a2-1944f9d53dcc',
                            notForTagging: false,
                            label: 'mail truck',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e0a59a01-eb07-4383-8a56-db4d5727c1de',
                            notForTagging: false,
                            label: 'tow truck',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ad4cb0f6-fdf0-45ed-4ecd-b89110a8a0b0',
                            notForTagging: false,
                            label: 'sailboat',
                            broaderId:
                                'http://data.disney.com/resources/8538ddb9-7d35-3bf0-5706-7e81dd9ac598',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                    notForTagging: true,
                    label: 'Visual Works',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/974548bc-6c48-99bb-defa-1f5ee04a869e',
                            notForTagging: false,
                            label: 'graffiti',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e5c9f442-07ab-4279-80ae-9a14b8659153',
                            notForTagging: false,
                            label: 'picture',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/e5116d96-9527-4925-85dd-13a2c52acff4',
                                    notForTagging: false,
                                    label: 'cut-paper work',
                                    broaderId:
                                        'http://data.disney.com/resources/e5c9f442-07ab-4279-80ae-9a14b8659153',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/4d36e327-af65-c490-d258-a485558e0d83',
                                    notForTagging: false,
                                    label: 'illustration',
                                    broaderId:
                                        'http://data.disney.com/resources/e5c9f442-07ab-4279-80ae-9a14b8659153',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/134c4adc-1b0c-9c9b-5761-478c163de998',
                                    notForTagging: false,
                                    label: 'painting (visual work)',
                                    broaderId:
                                        'http://data.disney.com/resources/e5c9f442-07ab-4279-80ae-9a14b8659153',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a43e3f6a-c03e-bc11-e066-45499fc48798',
                                    notForTagging: false,
                                    label: 'photograph',
                                    broaderId:
                                        'http://data.disney.com/resources/e5c9f442-07ab-4279-80ae-9a14b8659153',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0c440d4c-b988-9ac1-523b-4c21c59f1289',
                                    notForTagging: false,
                                    label: 'caricature',
                                    broaderId:
                                        'http://data.disney.com/resources/e5c9f442-07ab-4279-80ae-9a14b8659153',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b232825c-cc7f-74c7-4548-3803be9aeba3',
                                    notForTagging: false,
                                    label: 'drawing',
                                    broaderId:
                                        'http://data.disney.com/resources/e5c9f442-07ab-4279-80ae-9a14b8659153',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/1233580b-fff6-b381-995a-dc208690c6b7',
                            notForTagging: false,
                            label: 'mural',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/04108a91-7097-7cab-9c72-f07774cb678b',
                            notForTagging: false,
                            label: 'animation cel',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/923d0838-688c-316a-cb24-cac6a467b781',
                            notForTagging: false,
                            label: 'sculpture',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8037f5df-4562-8b10-3f7b-a52939386ab6',
                            notForTagging: false,
                            label: 'stained glass',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/13455973-b813-f45a-c838-df8dee71d89a',
                            notForTagging: false,
                            label: 'video blog',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/72e033dd-fb4c-431a-ac4a-253b694f543e',
                            notForTagging: false,
                            label: 'map',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f040ba0e-efcc-608f-97d1-ec590ee004b5',
                            notForTagging: false,
                            label: 'needlework',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/29385744-986f-8bb4-69e3-de75477c9abb',
                            notForTagging: false,
                            label: 'tattoo',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410c68d-909b-7d44-9c75-0768b3f88485',
                            notForTagging: false,
                            label: 'motion picture',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1c01a7ac-6ef5-44b2-bebe-3ba709dabe07',
                            notForTagging: false,
                            label: 'sign',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2040f296-c178-e606-ed00-6b3a4d99ee88',
                            notForTagging: false,
                            label: 'model',
                            broaderId:
                                'http://data.disney.com/resources/413c3cc2-298c-70e7-e606-0ea548324aaf',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                    notForTagging: true,
                    label: 'Weapons and Ammunition',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/c195cdce-e054-45b5-ba23-37fd4ffb0c97',
                            notForTagging: false,
                            label: 'land mine',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9331efbf-f0d6-d019-35b0-6db602b3828a',
                            notForTagging: false,
                            label: 'shield',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8b3eae64-6c00-283b-1389-599607f82189',
                            notForTagging: false,
                            label: 'detonator',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0410408e-20ad-a267-9c75-0768b3f88b8a',
                            notForTagging: false,
                            label: 'artillery',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1e44bcee-0b1a-c105-0710-91bd73647394',
                            notForTagging: false,
                            label: 'laser weapons',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7e6d276f-5cea-4c4a-a6a8-73b1b93d0c96',
                            notForTagging: false,
                            label: 'rocket launcher',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2030fe60-f4fb-2f42-90e6-eedff1d0bb95',
                            notForTagging: false,
                            label: 'spear',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a33a4206-c399-1dcd-3979-7d3c325afaa0',
                            notForTagging: false,
                            label: 'ammunition',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/25090e49-51e6-4f5a-bd98-a96d4fcc1beb',
                            notForTagging: false,
                            label: 'fireworks',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/d447fcc1-c8b5-24fe-787a-e6de0da181a4',
                                    notForTagging: false,
                                    label: 'firecracker',
                                    broaderId:
                                        'http://data.disney.com/resources/25090e49-51e6-4f5a-bd98-a96d4fcc1beb',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/4c45e46d-bd6e-4742-9330-4655357bc72d',
                            notForTagging: false,
                            label: 'assault rifle',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5d4d55f1-a6d7-be0c-61c8-058a631f148e',
                            notForTagging: false,
                            label: 'torpedo',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7747fed5-ac2b-5987-d6ba-c346be8939a5',
                            notForTagging: false,
                            label: 'catapult',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7046906b-f181-e4d5-0f8b-795d9a098ca3',
                            notForTagging: false,
                            label: 'dagger',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6b44be9a-98e7-16a3-9d74-13a5e17d0c85',
                            notForTagging: false,
                            label: 'battering ram',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5b3bce65-bed0-3b92-14ce-562fc935baab',
                            notForTagging: false,
                            label: 'sword',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cd3eabd8-6e3e-5eaf-3b0a-69fecbdca7a9',
                            notForTagging: false,
                            label: 'bomb',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7a373244-aa53-ef2e-ca42-690d9f48bf9d',
                            notForTagging: false,
                            label: 'bullet',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f742f2af-1cb6-4c4d-62b9-052241f5ae8b',
                            notForTagging: false,
                            label: 'missile',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8a3b5486-f59b-64f7-2a80-e19671f0d69e',
                            notForTagging: false,
                            label: 'arrow',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e43643b6-9474-2f39-dc3e-be49b1e4f992',
                            notForTagging: false,
                            label: 'firearm',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/433cb9fa-8166-a461-1a77-6685f112ec98',
                            notForTagging: false,
                            label: 'weapons',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/58074db6-af19-4f44-aa28-b7b1c86c164f',
                            notForTagging: false,
                            label: 'doomsday device',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4c402d98-48e0-ed53-ed75-c0e0cca79893',
                            notForTagging: false,
                            label: 'grenade',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a232f166-53a5-ef3b-1d96-3f3b32ea2ca4',
                            notForTagging: false,
                            label: 'rifle',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cb3beb21-f555-78a3-c040-df113db5c6b0',
                            notForTagging: false,
                            label: 'handgun',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d04de142-bcfb-f0da-f9b8-295e99f787bb',
                            notForTagging: false,
                            label: 'cannon',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/96479f37-2d07-36c1-ab49-5fed0406469b',
                            notForTagging: false,
                            label: 'cannonball',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b2486c6e-f979-6ef9-731d-cb8f81a79cbc',
                            notForTagging: false,
                            label: 'shotgun',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/813bb2b8-91b6-3a98-5a92-8770a41838ba',
                            notForTagging: false,
                            label: 'bow',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/614503c4-ec54-a196-8848-b58b2312068c',
                            notForTagging: false,
                            label: 'machine gun',
                            broaderId:
                                'http://data.disney.com/resources/b039752a-cff6-a01d-e817-3dba852fe488',
                            terms: [],
                        },
                    ],
                },
            ],
        },
        {
            label: 'Place of Origin',
            type: 'http://data.disney.com/resources/PlaceOfOrigin',
            categories: [
                {
                    category: 'http://cv.ap.org/id/661812607d5b100481f1c076b8e3055c',
                    notForTagging: false,
                    label: 'Africa',
                    terms: [],
                },
                {
                    category: 'http://cv.ap.org/id/661812607d5b100481f3c076b8e3055c',
                    notForTagging: false,
                    label: 'Asia',
                    terms: [],
                },
                {
                    category: 'http://cv.ap.org/id/661850e07d5b100481f4c076b8e3055c',
                    notForTagging: false,
                    label: 'Europe',
                    terms: [],
                },
                {
                    category: 'http://cv.ap.org/id/661850e07d5b100481f5c076b8e3055c',
                    notForTagging: false,
                    label: 'Latin America and Caribbean',
                    terms: [],
                },
                {
                    category: 'http://cv.ap.org/id/661850e07d5b100481f7c076b8e3055c',
                    notForTagging: false,
                    label: 'North America',
                    terms: [],
                },
                {
                    category: 'http://cv.ap.org/id/661850e07d5b100481f3c076b8e3055c',
                    notForTagging: false,
                    label: 'Oceania',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/90fadde7-ef60-490b-88ce-f9fcbf09844b',
                    notForTagging: false,
                    label: 'SWANA',
                    terms: [],
                },
            ],
        },
        {
            label: 'Plot Devices',
            type: 'http://data.disney.com/resources/PlotDevices',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/b3fa4548-5a2d-472f-8351-84e89cd7f211',
                    notForTagging: false,
                    label: 'cliffhanger',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f9600b10-0a22-4b75-b778-6e66df0b5025',
                    notForTagging: false,
                    label: 'reveal, audience',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/831ebb5e-6ee9-4b31-9477-9d14ed6798ca',
                    notForTagging: false,
                    label: 'reveal, character',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ee23a305-b978-44b2-a80e-b47b2038ca9c',
                    notForTagging: false,
                    label: 'reversal',
                    terms: [],
                },
            ],
        },
        {
            label: 'Plot Points',
            type: 'http://data.disney.com/resources/PlotPoints',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/c446612f-7213-4e13-a34d-b5e4e8e8df9c',
                    notForTagging: false,
                    label: 'Acceptance',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2dd2a09e-bb73-4538-9a01-c376fb6cc940',
                    notForTagging: false,
                    label: 'Adrift',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8423e91c-a542-4960-b9ea-4887f7adb4f2',
                    notForTagging: false,
                    label: 'Approach',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/67e12e4a-8a15-4846-8436-8bd00bba4f25',
                    notForTagging: false,
                    label: 'Call, The',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7122a6ab-d664-4ada-88b2-a6634171ea5e',
                    notForTagging: false,
                    label: 'Meeting the Mentor',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d73c6bb5-43d4-484d-aa88-0231c5991c81',
                    notForTagging: false,
                    label: 'On the Road',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a2734945-d61c-4bde-9753-26de717ea715',
                    notForTagging: false,
                    label: 'Ordeal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/dda35b43-1482-470a-aeae-3290302bc973',
                    notForTagging: false,
                    label: 'Ordinary World',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a70267c7-c499-4e29-b586-37b3b966591b',
                    notForTagging: false,
                    label: 'Refusing the Call',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/05773130-6bd7-4aa4-b67e-c091b508a793',
                    notForTagging: false,
                    label: 'Resolution',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/81d07331-1c3b-4ef1-a987-86a4dd55e627',
                    notForTagging: false,
                    label: 'Setup',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d41c9ba8-35e7-452f-800a-066edd5f21e5',
                    notForTagging: false,
                    label: 'Things are Looking Up',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/818a40dd-ac20-4b3c-88e1-2a61e92449fa',
                    notForTagging: false,
                    label: 'Twist',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4eacaa7e-4929-4427-8450-d92d72e5ceaa',
                    notForTagging: false,
                    label: 'Unconscious Choice',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e71bb971-2101-450d-a2d8-3209a88085eb',
                    notForTagging: false,
                    label: 'Worst of the Worst',
                    terms: [],
                },
            ],
        },
        {
            label: 'Race & Ethnicity',
            type: 'http://data.disney.com/resources/RaceEthnicity',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/15715f64-8d5d-4508-9a9f-dea345a4f8dc',
                    notForTagging: false,
                    label: 'Asian',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/93f7b449-b510-4a7d-8a5c-0e9b571fac38',
                            notForTagging: false,
                            label: 'Central Asian',
                            broaderId:
                                'http://data.disney.com/resources/15715f64-8d5d-4508-9a9f-dea345a4f8dc',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/76ed474e-c994-46a7-b531-58e6983ec1ea',
                            notForTagging: false,
                            label: 'Southeast Asian',
                            broaderId:
                                'http://data.disney.com/resources/15715f64-8d5d-4508-9a9f-dea345a4f8dc',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dc1627fb-6c77-43f5-a6d9-7cce90115891',
                            notForTagging: false,
                            label: 'East Asian',
                            broaderId:
                                'http://data.disney.com/resources/15715f64-8d5d-4508-9a9f-dea345a4f8dc',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1538b92e-31c9-4675-836c-3c8214ac4579',
                            notForTagging: false,
                            label: 'South Asian',
                            broaderId:
                                'http://data.disney.com/resources/15715f64-8d5d-4508-9a9f-dea345a4f8dc',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/e63e7e28-1fd5-42b3-8708-f49477a0051a',
                    notForTagging: false,
                    label: 'Black',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a12ea551-2835-448d-95e5-ea7220d0eb44',
                    notForTagging: false,
                    label: 'Latina/o/e',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/72f60794-e443-418d-8d0e-b63b543d8682',
                    notForTagging: false,
                    label: 'Native Peoples',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5244ead8-50b4-411b-ae2d-61099c622aba',
                    notForTagging: false,
                    label: 'Southwest Asian and North African',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/725dcf50-4f5d-4764-b913-462f8a33b90d',
                    notForTagging: false,
                    label: 'White',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/55fcb79a-5b37-4b5f-ae6e-e814747263d9',
                    notForTagging: false,
                    label: 'person of color, unspecified',
                    terms: [],
                },
            ],
        },
        {
            label: 'Relationship Types',
            type: 'http://data.disney.com/resources/RelationshipTypes',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/14ea5bfe-c0b0-4c6b-89e5-742e2ea547a0',
                    notForTagging: false,
                    label: 'adversarial',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0321d04b-b1cb-4df7-a427-81771586bc88',
                    notForTagging: false,
                    label: 'cooperative',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c54e65df-1f99-4245-9b94-e5cce70088a9',
                    notForTagging: false,
                    label: 'familial',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a36e5cfc-5eb0-44fa-bce8-5997cc5184cb',
                    notForTagging: false,
                    label: 'friends',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9aee2503-e068-4286-bae6-022c838dc1b3',
                    notForTagging: false,
                    label: 'marital',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/71a8c3e3-c280-4105-84e5-19c6d238877c',
                    notForTagging: false,
                    label: 'mentorship',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7df26ad0-5be7-4953-8370-987cca7b91bb',
                    notForTagging: false,
                    label: 'parent-child',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/eddeffa1-2539-4acb-9746-19ff2bf65113',
                    notForTagging: false,
                    label: 'professional',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/65202d82-7e9d-4d5b-a5e7-eff8159892f6',
                    notForTagging: false,
                    label: 'romantic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1a5c8fa5-683d-4470-afba-4cb21f1e6be1',
                    notForTagging: false,
                    label: 'sibling',
                    terms: [],
                },
            ],
        },
        {
            label: 'Religion',
            type: 'http://data.disney.com/resources/Religion',
            categories: [
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#18bc6d09-2f81-4951-a86f-0db06a4ab93f',
                    notForTagging: false,
                    label: 'Atheist',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#4a076d59-17a6-477a-a5cf-391b0774bd9a',
                    notForTagging: false,
                    label: 'Buddhist',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#313e5e95-057c-407e-a5f4-8b867d745ad8',
                    notForTagging: false,
                    label: 'Christian',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#d37a2d32-9cdf-44bf-9ac7-5ebb3c0ddf5d',
                    notForTagging: false,
                    label: 'Hindu',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#0bd2f9f6-cfd0-43d5-84c9-fb34e6e65edb',
                    notForTagging: false,
                    label: 'Jain',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#6998e835-4911-4176-834c-fbc664b2c88e',
                    notForTagging: false,
                    label: 'Jewish',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#e34d723b-41ad-47ed-b7e1-71ff23e739c0',
                    notForTagging: false,
                    label: 'Muslim',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#a8bff2f7-0a61-41a2-a9de-0d1177564ce4',
                    notForTagging: false,
                    label: 'Sikh',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#df73fc4f-d943-4302-980f-3fb9cf900c57',
                    notForTagging: false,
                    label: 'Wiccan',
                    terms: [],
                },
                {
                    category:
                        'http://example.com/DescriptiveConcepts_TaskCommitted#9e912797-2859-4cc1-b0a8-e573b555d50e',
                    notForTagging: false,
                    label: 'folk or traditional religionist',
                    terms: [],
                },
            ],
        },
        {
            label: 'Scopes and Scales',
            type: 'http://data.disney.com/resources/ScopesScales',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/b4f5810f-ea34-4fa4-8a6f-76a784436585',
                    notForTagging: false,
                    label: 'confined',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/08e1a9a5-f4e1-4408-8830-f207f347a653',
                    notForTagging: false,
                    label: 'epic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5f77e625-ea24-451b-99d9-9bec91224822',
                    notForTagging: false,
                    label: 'grand',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8f886a08-7c62-477b-9bb9-6e2341991d23',
                    notForTagging: false,
                    label: 'normal',
                    terms: [],
                },
            ],
        },
        {
            label: 'Sections',
            type: 'http://data.disney.com/resources/Sections',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/46833f48-0f86-4a13-9d90-6c660258ed6e',
                    notForTagging: false,
                    label: 'act',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6e910a8d-481f-4551-8530-403370565b57',
                    notForTagging: false,
                    label: 'recap',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/538c7124-1c0d-4eb4-ac82-08d3b701631e',
                    notForTagging: false,
                    label: 'scene',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6fceafeb-3f99-4c5f-9db7-36f06f43fb3d',
                    notForTagging: false,
                    label: 'song clip',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4458c2d7-5b84-40e7-b27a-50fc72f9887a',
                    notForTagging: false,
                    label: 'subscene',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/46a1319f-e391-4b04-870e-2456c05349c7',
                    notForTagging: false,
                    label: 'title sequence',
                    terms: [],
                },
            ],
        },
        {
            label: 'Setting Types',
            type: 'http://data.disney.com/resources/SettingTypes',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/16987cc4-312c-4dc5-94a0-fad1c4209ab6',
                    notForTagging: false,
                    label: 'academic setting',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/2ef9dd59-42be-44f0-b2f7-b1ef87500305',
                            notForTagging: false,
                            label: 'elementary school',
                            broaderId:
                                'http://data.disney.com/resources/16987cc4-312c-4dc5-94a0-fad1c4209ab6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/aafed4d6-e7f3-4e52-8aec-4b6e51edd366',
                            notForTagging: false,
                            label: 'high school',
                            broaderId:
                                'http://data.disney.com/resources/16987cc4-312c-4dc5-94a0-fad1c4209ab6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/86f31b3b-39a4-44b0-b8d3-9a974ae2e64a',
                            notForTagging: false,
                            label: 'college',
                            broaderId:
                                'http://data.disney.com/resources/16987cc4-312c-4dc5-94a0-fad1c4209ab6',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/075c0ce6-473a-478c-ba4d-7a1738f53ab2',
                            notForTagging: false,
                            label: 'middle school',
                            broaderId:
                                'http://data.disney.com/resources/16987cc4-312c-4dc5-94a0-fad1c4209ab6',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/beffffbd-b21e-429b-9bb6-86c99f654c56',
                    notForTagging: false,
                    label: 'aquatic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/444855f9-565e-d0ad-5189-23459707fbbe',
                    notForTagging: false,
                    label: 'beach',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/86f31b3b-39a4-44b0-b8d3-9a974ae2e64a',
                    notForTagging: false,
                    label: 'college',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/65235db3-826d-4da6-8b74-fb4adf57d5f6',
                    notForTagging: false,
                    label: 'desert',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2ef9dd59-42be-44f0-b2f7-b1ef87500305',
                    notForTagging: false,
                    label: 'elementary school',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/aafed4d6-e7f3-4e52-8aec-4b6e51edd366',
                    notForTagging: false,
                    label: 'high school',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0c06dda3-3f4b-41e2-9c80-36922ef1ecdf',
                    notForTagging: false,
                    label: 'island',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ff58a4ce-f4b8-427c-9e29-871650496710',
                    notForTagging: false,
                    label: 'jungle',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/41f50da3-6a9e-4b64-81af-f1d3d523e8c5',
                    notForTagging: false,
                    label: 'kingdom',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/075c0ce6-473a-478c-ba4d-7a1738f53ab2',
                    notForTagging: false,
                    label: 'middle school',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ef80db8d-24a1-4f22-9cb2-7e828d4950b5',
                    notForTagging: false,
                    label: 'outer space',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/384f4514-6225-b10d-0600-de925fa825be',
                    notForTagging: false,
                    label: 'rural',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/01340275-74f7-442a-ad3e-ea2af43fb494',
                    notForTagging: false,
                    label: 'sky',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9444e627-2bd1-c4f9-2679-7f234b22d7b4',
                    notForTagging: false,
                    label: 'suburban',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c3f3ef1e-ec92-42ab-82a3-b4d148b1b235',
                    notForTagging: false,
                    label: 'town',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c64bdff7-b044-f3a5-bc09-10aa88e611ac',
                    notForTagging: false,
                    label: 'urban',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c44af55f-35d5-6af3-c6c4-34259794e299',
                    notForTagging: false,
                    label: 'wilderness',
                    terms: [],
                },
            ],
        },
        {
            label: 'Sexual Orientation',
            type: 'http://data.disney.com/resources/SexualOrientation',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/12280c63-9965-4fda-9a44-d3725a6c3e68',
                    notForTagging: false,
                    label: 'asexual',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7c0dd7b3-da0e-4618-bca7-3a74c8994f82',
                    notForTagging: false,
                    label: 'bisexual',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/efe532c6-e37b-42b2-b881-8486c6cc9eab',
                    notForTagging: false,
                    label: 'demisexual',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8ada2c49-0164-4f2f-a784-54f045e4bc41',
                    notForTagging: false,
                    label: 'different gender',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/9c51ecf4-a52c-4710-9b81-b62a245dd96a',
                            notForTagging: false,
                            label: 'heterosexual',
                            broaderId:
                                'http://data.disney.com/resources/8ada2c49-0164-4f2f-a784-54f045e4bc41',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/ab59b83c-305e-49c3-a182-28aebbd37824',
                    notForTagging: false,
                    label: 'pansexual',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cdffd81b-e4b3-49eb-8705-5024f8cc74d5',
                    notForTagging: false,
                    label: 'queer',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6a537708-7d8d-4d33-a28e-e78b8ca628dc',
                    notForTagging: false,
                    label: 'same gender',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/3e0ad784-a990-40e4-9b8e-4c278e964812',
                            notForTagging: false,
                            label: 'gay man',
                            broaderId:
                                'http://data.disney.com/resources/6a537708-7d8d-4d33-a28e-e78b8ca628dc',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8361c98e-4239-4ffc-b0c8-02ebfe17b808',
                            notForTagging: false,
                            label: 'lesbian',
                            broaderId:
                                'http://data.disney.com/resources/6a537708-7d8d-4d33-a28e-e78b8ca628dc',
                            terms: [],
                        },
                    ],
                },
            ],
        },
        {
            label: 'Source Material',
            type: 'http://data.disney.com/resources/SourceMaterials',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/59118822-ddfd-4cbb-bb7d-71f82839795b',
                    notForTagging: false,
                    label: 'adaptation',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d2cb31ce-9cad-4081-9d99-f0e30a68f687',
                    notForTagging: false,
                    label: 'alternate edit',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c88e9630-7e57-43fe-b307-604d2c1d6583',
                    notForTagging: false,
                    label: 'as suggested by',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fb2907dd-b138-4aa4-9da5-f448329d73f3',
                    notForTagging: false,
                    label: 'cross-over',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/202cbb1c-aba1-4932-b742-4c8936cef379',
                    notForTagging: false,
                    label: 'original idea',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e83c3911-a69b-4c4c-8ef2-62015f4a55a2',
                    notForTagging: false,
                    label: 'real life',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/2ba65492-a2a1-462f-98d5-623ff8a28112',
                    notForTagging: false,
                    label: 'reboot',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/eada2fb5-3772-45c3-b82d-f87c07124328',
                    notForTagging: false,
                    label: 'remake',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5419ab69-5d24-485e-a324-d8a1075a53e2',
                    notForTagging: false,
                    label: 'revival',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9a3c04c1-e531-4e53-9c5d-91b5bccc916e',
                    notForTagging: false,
                    label: 'sequel',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/0782479b-e935-420e-af55-ceb4d0bcc986',
                            notForTagging: false,
                            label: 'prequel',
                            broaderId:
                                'http://data.disney.com/resources/9a3c04c1-e531-4e53-9c5d-91b5bccc916e',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/faca7450-fbdc-4a6f-8ac6-1deb9b88c773',
                    notForTagging: false,
                    label: 'spin-off',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d27644d4-7681-40a3-bc65-806ca191fbde',
                    notForTagging: false,
                    label: 'true story',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/87ce317f-f452-4c5c-b918-ff068a50e12f',
                    notForTagging: false,
                    label: 'work about another work',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/2c53042b-1082-4369-bb15-c8656894a48d',
                            notForTagging: false,
                            label: 'supplemental',
                            broaderId:
                                'http://data.disney.com/resources/87ce317f-f452-4c5c-b918-ff068a50e12f',
                            terms: [],
                        },
                    ],
                },
            ],
        },
        {
            label: 'States and Conditions',
            type: 'http://data.disney.com/resources/StatesConditions',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/ea45eab3-176a-4ea9-1d4f-8b9d7524b09d',
                    notForTagging: false,
                    label: 'anger',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1f4dd1a2-2699-c026-d809-ef84abd0af91',
                    notForTagging: false,
                    label: 'blindness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/25403418-55c7-0929-cfdd-42100d8543bc',
                    notForTagging: false,
                    label: 'boredom',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/244b270e-d5f5-1b0e-ceeb-f3edf6d9b4a1',
                    notForTagging: false,
                    label: 'embarrassment',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/724d5bbc-2117-0126-5677-09548ed7e389',
                    notForTagging: false,
                    label: 'excitement',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a7ad5606-644c-4820-b622-295c099f1957',
                    notForTagging: false,
                    label: 'fatigue',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8d4f9239-e2dc-d858-a3bd-af4ecf379ba9',
                    notForTagging: false,
                    label: 'fear',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9947f393-a881-bf5f-af86-e8c66475d3b0',
                    notForTagging: false,
                    label: 'feeling ill',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/72c68ccf-2ee1-437f-a5a3-a607a382526b',
                    notForTagging: false,
                    label: 'happiness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/939ebb3c-ce44-48c1-a486-104fb4627cf1',
                    notForTagging: false,
                    label: 'injury',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9fa2b197-fde8-4a90-a334-765f9f58a5b5',
                    notForTagging: false,
                    label: 'intoxication',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/e21c0306-1682-4682-b72f-3a25f7e0f7de',
                    notForTagging: false,
                    label: 'losing consciousness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a6fa5f0e-ef94-4cdc-8da8-38e09183cdba',
                    notForTagging: false,
                    label: 'love',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/de4e2b75-d644-e151-b069-cfe9782f98a9',
                    notForTagging: false,
                    label: 'luminescence',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/a240167f-0792-876e-e4dd-96e856e6a5ab',
                    notForTagging: false,
                    label: 'nudity',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/014a145b-d659-b758-1491-2599a69677a4',
                    notForTagging: false,
                    label: 'patriotism',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/70e8feaf-0bff-4e0d-9ff9-83c763ebc253',
                    notForTagging: false,
                    label: 'pregnant',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1241808b-0d8a-40ad-f388-524a2259339d',
                    notForTagging: false,
                    label: 'sadness',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/01447ca6-055a-ca4b-7a12-0e5baee9a192',
                    notForTagging: false,
                    label: 'surprise',
                    terms: [],
                },
            ],
        },
        {
            label: 'Story Attributes',
            type: 'http://data.disney.com/resources/StoryAttributes',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/a7991f92-7801-47d4-aafc-2b903400ae2c',
                    notForTagging: true,
                    label: 'Transparency',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/fa1d90ef-2977-4e6b-be7c-f328d8a5057f',
                            notForTagging: false,
                            label: 'obvious',
                            broaderId:
                                'http://data.disney.com/resources/a7991f92-7801-47d4-aafc-2b903400ae2c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/915ecc20-692d-447c-8f28-7dcdd4f92dad',
                            notForTagging: false,
                            label: 'unclear',
                            broaderId:
                                'http://data.disney.com/resources/a7991f92-7801-47d4-aafc-2b903400ae2c',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1f6dbb72-c082-44e0-9485-93db59019980',
                            notForTagging: false,
                            label: 'unrevealed',
                            broaderId:
                                'http://data.disney.com/resources/a7991f92-7801-47d4-aafc-2b903400ae2c',
                            terms: [],
                        },
                    ],
                },
            ],
        },
        {
            label: 'Story Elements',
            type: 'http://data.disney.com/resources/StoryElements',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                    notForTagging: true,
                    label: 'self',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/b82601dd-e8a3-458c-9324-ec5e4b2e40bd',
                            notForTagging: false,
                            label: 'teen rebellion',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0b3abb59-6bdd-4650-ac54-48f084055303',
                            notForTagging: false,
                            label: 'level up',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3ad6514c-3504-4e1b-bc1d-1b6141a32cd6',
                            notForTagging: false,
                            label: 'personal crisis',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/c4dcf18e-7661-4fdf-b94e-479ef4e23774',
                                    notForTagging: false,
                                    label: 'descent into madness',
                                    broaderId:
                                        'http://data.disney.com/resources/3ad6514c-3504-4e1b-bc1d-1b6141a32cd6',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/863b9039-fb01-49f6-ab2e-d880f34b7b19',
                            notForTagging: true,
                            label: 'false pregnancy',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cc8e17c2-5e64-45e1-b21f-bfbdd14b3dd5',
                            notForTagging: false,
                            label: 'comeback',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0298645e-a97c-453e-8caf-dc642993ed45',
                            notForTagging: false,
                            label: 'switched at birth',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/514644af-c352-47c1-be25-fd396f559adb',
                            notForTagging: false,
                            label: 'role reversal',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3243951b-5469-ee8f-fd96-62d46d1b98a3',
                            notForTagging: false,
                            label: 'health issue',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3d18784d-db51-4103-9522-7a24763ef13c',
                            notForTagging: false,
                            label: 'performance-enhancing drugs',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://example.com/DescriptiveConcepts_TaskCommitted#19073ef0-18d5-4cdb-b58a-595ba3f2703e',
                            notForTagging: false,
                            label: 'perception',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ee3a7dd0-3b8e-48df-9c75-8d30e17f73f7',
                            notForTagging: false,
                            label: 'search for meaning',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4f6726c2-beea-4c74-a42f-7f34e1575bce',
                            notForTagging: false,
                            label: 'facing fear',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/846c8e42-7e86-4e7f-b9c8-e34d1b5274d3',
                            notForTagging: false,
                            label: 'uncertain paternity',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4c0e44a5-93b6-444c-adb6-765eff905624',
                            notForTagging: false,
                            label: 'leaving home',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b00af25f-3ebd-4d14-b55c-b7f07e30e94e',
                            notForTagging: false,
                            label: 'troubled past',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dda38288-2300-4105-8e7f-63ca914c3584',
                            notForTagging: false,
                            label: 'learning self-reliance',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ecfea36a-8ea7-4fd0-a355-61545f0bcb39',
                            notForTagging: false,
                            label: 'being yourself',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/43735446-3180-4c1f-943d-360f7d0e7399',
                            notForTagging: false,
                            label: 'metamorphosis',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b006442d-d806-44fd-bcbc-7f4d5dc2be65',
                            notForTagging: false,
                            label: 'mental health',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/03d14283-d6cc-42df-8f50-067a50e8d163',
                            notForTagging: false,
                            label: 'disability',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e5993dbc-e938-428a-a2f9-0939c1e87a63',
                            notForTagging: false,
                            label: 'returning home',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f027c05d-8a0d-41e5-93e8-11c78c668e1f',
                            notForTagging: true,
                            label: 'planned pregnancy',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d3251c43-b159-4e75-8874-1aabfc84d6af',
                            notForTagging: false,
                            label: 'multiple personalities',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6f7f9a87-85a3-481f-a1ba-dae9553b1a69',
                            notForTagging: false,
                            label: 'maturation',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/530af3e3-62e4-41a2-a3cf-407e79291213',
                            notForTagging: false,
                            label: 'sexual orientation and gender identity',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0c16c6e4-5a73-4d70-afba-373ed663b08b',
                            notForTagging: false,
                            label: 'recovery',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/af17592e-374b-4f1d-9d24-245b0afbcf3c',
                            notForTagging: false,
                            label: 'medical condition',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fa950b9f-0c33-44c9-ab70-d36cc7938333',
                            notForTagging: false,
                            label: 'self-actualization',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/097afe29-9007-4d15-b0d5-3168f558d7e7',
                                    notForTagging: false,
                                    label: 'following a dream',
                                    broaderId:
                                        'http://data.disney.com/resources/fa950b9f-0c33-44c9-ab70-d36cc7938333',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/f35b27b2-f982-4e20-8d24-6d577d1c2a48',
                                    notForTagging: false,
                                    label: 'living it up',
                                    broaderId:
                                        'http://data.disney.com/resources/fa950b9f-0c33-44c9-ab70-d36cc7938333',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/bb531a45-5601-407d-8077-59109ba23478',
                            notForTagging: false,
                            label: 'coming out',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8135ab51-da8c-414a-8fbe-3b1259d5585a',
                            notForTagging: false,
                            label: 'losing innocence',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d53224d0-94c7-4523-a432-daf6b73d8d80',
                            notForTagging: true,
                            label: 'switching sides: good-to-bad',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8b98a79b-c74e-4989-b240-c8c4b3d89810',
                            notForTagging: false,
                            label: 'wish fulfillment',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/f9a7c4c3-41db-478c-bb8e-f503cde6742e',
                            notForTagging: false,
                            label: 'out-of-character behavior',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6e072f6f-950f-4578-bbee-c7f5972ef765',
                            notForTagging: false,
                            label: 'body swapping',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9181f9a7-a973-4103-9b70-77e171ed6a55',
                            notForTagging: false,
                            label: 'mistaken identity',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/dd30c653-b79c-43ab-be13-271b14a90939',
                            notForTagging: true,
                            label: 'hidden pregnancy',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8f432973-ce11-392c-1b35-2e2e52d0749c',
                            notForTagging: false,
                            label: 'pregnancy issue',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/fcfe8823-0bc7-44ae-9b54-b60064926098',
                                    notForTagging: false,
                                    label: 'unintended pregnancy',
                                    broaderId:
                                        'http://data.disney.com/resources/8f432973-ce11-392c-1b35-2e2e52d0749c',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/19073ef0-18d5-4cdb-b58a-595ba3f2703e',
                            notForTagging: false,
                            label: 'perception',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/8f51c1bd-ed1e-43b5-922a-29a7761b6939',
                                    notForTagging: false,
                                    label: 'invisibility',
                                    broaderId:
                                        'http://data.disney.com/resources/19073ef0-18d5-4cdb-b58a-595ba3f2703e',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5a3f40f6-3996-465a-8f0b-b7ec17823686',
                                    notForTagging: false,
                                    label: 'presumed dead',
                                    broaderId:
                                        'http://data.disney.com/resources/19073ef0-18d5-4cdb-b58a-595ba3f2703e',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/91a39b6d-ba3c-482c-80c7-da7db048c8ee',
                                    notForTagging: false,
                                    label: 'identity',
                                    broaderId:
                                        'http://data.disney.com/resources/19073ef0-18d5-4cdb-b58a-595ba3f2703e',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/4b9db24c-979e-4453-8b0d-052c1b19077a',
                                            notForTagging: false,
                                            label: 'reinvention',
                                            broaderId:
                                                'http://data.disney.com/resources/91a39b6d-ba3c-482c-80c7-da7db048c8ee',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b4861855-5c75-4a01-9535-23b9ca18a89a',
                                    notForTagging: false,
                                    label: 'doppelganger',
                                    broaderId:
                                        'http://data.disney.com/resources/19073ef0-18d5-4cdb-b58a-595ba3f2703e',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/57fd9d9d-f84a-4af5-9562-55ad323b3462',
                            notForTagging: false,
                            label: 'pregnancy loss',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5dc04bf3-6fd7-49aa-b760-a43dbaedc86b',
                            notForTagging: false,
                            label: 'surrogacy',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b1021413-c61f-4acf-ba6e-c9d1a8a638d0',
                            notForTagging: false,
                            label: 'self-destructive behavior',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7024ee02-f69b-4470-80e4-28b060ed93d5',
                            notForTagging: false,
                            label: 'aging issue',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/d84660bd-e755-4ee6-a1c2-d33677f8a78b',
                            notForTagging: false,
                            label: 'coming of age',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/29456c62-a45b-4ec6-906c-3081995a6d88',
                            notForTagging: false,
                            label: 'sexual awakening',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/712ad54a-9b52-492f-893e-f5c68fcccf72',
                                    notForTagging: false,
                                    label: 'first kiss',
                                    broaderId:
                                        'http://data.disney.com/resources/29456c62-a45b-4ec6-906c-3081995a6d88',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c0bed7d2-6151-4e26-85b8-c3d0c9da949c',
                                    notForTagging: false,
                                    label: 'losing virginity',
                                    broaderId:
                                        'http://data.disney.com/resources/29456c62-a45b-4ec6-906c-3081995a6d88',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a1bedd2b-f0ad-4671-bd6b-6f772d9d92dd',
                                    notForTagging: false,
                                    label: 'sexual quest',
                                    broaderId:
                                        'http://data.disney.com/resources/29456c62-a45b-4ec6-906c-3081995a6d88',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/988d67c8-3170-4ad6-9246-44b65608f8f5',
                                    notForTagging: false,
                                    label: 'late bloomer',
                                    broaderId:
                                        'http://data.disney.com/resources/29456c62-a45b-4ec6-906c-3081995a6d88',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/b69949d0-fe8c-47c4-9e75-3c5215961c54',
                            notForTagging: false,
                            label: 'secret identity',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/917a6505-1ee4-4e1e-94ec-10c830da558c',
                            notForTagging: true,
                            label: 'conception',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/de2e708e-637b-4f2a-a443-6da4f5477d65',
                            notForTagging: false,
                            label: 'hubris',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/164b2d20-0c6d-4fdf-893e-7df1259a8c44',
                            notForTagging: false,
                            label: 'switching sides',
                            broaderId:
                                'http://data.disney.com/resources/85d41056-72e4-47cc-82cc-dcad0525e54b',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                    notForTagging: true,
                    label: 'situation',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/0012df58-8e95-46c7-8794-fd1f5e0dfd76',
                            notForTagging: false,
                            label: 'stuck in a game',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bf47b70e-e01c-4858-9efb-35ba4e1e9d13',
                            notForTagging: false,
                            label: 'survival (story elements)',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4aba7655-20ac-4aa9-a0ee-c52439404cec',
                            notForTagging: false,
                            label: 'against the odds',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8371d349-103e-4a1e-846e-3107c32c559b',
                            notForTagging: false,
                            label: 'tough choice',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2e8d0264-a672-478f-ba11-438055f706a0',
                            notForTagging: false,
                            label: 'extraordinary environment',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fd4d7415-0bef-433f-a7a2-0aef6b258f63',
                            notForTagging: false,
                            label: 'protagonist vs. beast',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e3f6d89a-314f-41ff-b22e-5215394297aa',
                            notForTagging: false,
                            label: 'exploration',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/fc4d29e9-f6e7-4ed9-a9b7-ce560ae64f9b',
                                    notForTagging: false,
                                    label: 'space travel',
                                    broaderId:
                                        'http://data.disney.com/resources/e3f6d89a-314f-41ff-b22e-5215394297aa',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/26015ebe-a23d-44ed-bf46-e56eb3b47df7',
                            notForTagging: false,
                            label: 'supernatural phenomena',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/48c785bc-3b5c-45c5-aac3-9d29dd7bcea5',
                            notForTagging: false,
                            label: 'protagonist vs. machine',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/9573143e-5ace-4142-81ec-6f814f23ad20',
                            notForTagging: false,
                            label: 'showdown',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/203f6329-b372-4661-87a4-7df5a95e6373',
                            notForTagging: false,
                            label: 'journey home',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ec8c9807-b8c2-417c-b890-1fb301dae1ce',
                            notForTagging: false,
                            label: 'alien contact',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/05568cf1-52d8-4b6e-89db-07dceb38f5be',
                            notForTagging: false,
                            label: 'saving the world',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8923867a-c045-401e-b7e7-ce86652bbcef',
                            notForTagging: false,
                            label: 'on a mission',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/e9bd59d9-da95-42f9-932e-ea3d37d2242f',
                                    notForTagging: false,
                                    label: 'personal investigation',
                                    broaderId:
                                        'http://data.disney.com/resources/8923867a-c045-401e-b7e7-ce86652bbcef',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/0fcedea2-846e-4c0d-bbea-d29fbcd3e9b2',
                                    notForTagging: false,
                                    label: 'covert operation',
                                    broaderId:
                                        'http://data.disney.com/resources/8923867a-c045-401e-b7e7-ce86652bbcef',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/320ba884-682c-4395-a3f9-9836ab4fa8c6',
                                    notForTagging: false,
                                    label: 'holy grail',
                                    broaderId:
                                        'http://data.disney.com/resources/8923867a-c045-401e-b7e7-ce86652bbcef',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/10c506d7-47ef-4e10-ac4a-e7945a8bcffe',
                                    notForTagging: false,
                                    label: 'heroic mission',
                                    broaderId:
                                        'http://data.disney.com/resources/8923867a-c045-401e-b7e7-ce86652bbcef',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/07311507-0571-492e-9176-2d19720b1f65',
                            notForTagging: false,
                            label: 'self-sacrifice',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/5939fd3f-7c71-4f12-a629-70d17f5a5724',
                            notForTagging: false,
                            label: 'tragedy',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3be1d2a2-9a7d-43d0-8a65-2447bdfbfc25',
                            notForTagging: false,
                            label: 'rescue mission',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/03919112-467f-4771-8fa8-6b00532e522c',
                            notForTagging: false,
                            label: 'voyage and return',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/eb6012ee-6d05-4e06-9e4a-0d300d83441e',
                            notForTagging: false,
                            label: 'disease outbreak',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7410331a-75d9-470b-8557-2ecbff1efc30',
                            notForTagging: false,
                            label: 'human vs. nature',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8aa8e965-142a-4107-9364-4b2e6bea3c06',
                            notForTagging: false,
                            label: 'saving the environment',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8f4cfdbe-7eb7-44f5-8651-faa871607010',
                            notForTagging: false,
                            label: 'price of success',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/b57ef7b2-5f90-4b15-8fb8-91569bea4f6a',
                                    notForTagging: false,
                                    label: 'deal with the devil',
                                    broaderId:
                                        'http://data.disney.com/resources/8f4cfdbe-7eb7-44f5-8651-faa871607010',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/457b315b-9877-4d57-915b-cb64219e16ec',
                            notForTagging: false,
                            label: 'dystopia',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/f77fcda1-d0b5-4e60-8cf9-1eaa0ad67a27',
                                    notForTagging: false,
                                    label: 'false utopia',
                                    broaderId:
                                        'http://data.disney.com/resources/457b315b-9877-4d57-915b-cb64219e16ec',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5a2118a8-dd54-4aad-a815-64bb9cc0329b',
                                    notForTagging: false,
                                    label: 'post apocalypse',
                                    broaderId:
                                        'http://data.disney.com/resources/457b315b-9877-4d57-915b-cb64219e16ec',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/021589aa-a005-42bb-a2ed-d7e5c2ebd838',
                                            notForTagging: false,
                                            label: 'zombie apocalypse',
                                            broaderId:
                                                'http://data.disney.com/resources/5a2118a8-dd54-4aad-a815-64bb9cc0329b',
                                            terms: [],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/e2fc98a3-9191-4a2f-a1c9-7c59426a5e19',
                            notForTagging: false,
                            label: 'virtual reality',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/0fdb000b-9240-4de5-b552-f65ec34a4917',
                            notForTagging: false,
                            label: 'financial hardship',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/32a2816a-7c7c-4411-9024-382544266440',
                            notForTagging: false,
                            label: 'horrifying situation',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/53b232e4-e188-44fc-a9cb-85be21e351d6',
                                    notForTagging: false,
                                    label: 'single elimination',
                                    broaderId:
                                        'http://data.disney.com/resources/32a2816a-7c7c-4411-9024-382544266440',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6d6963ad-6442-4fce-aae7-c45db6783424',
                                    notForTagging: false,
                                    label: 'body possession',
                                    broaderId:
                                        'http://data.disney.com/resources/32a2816a-7c7c-4411-9024-382544266440',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/9af115c4-a45c-41db-915d-2e8b5a32df21',
                                            notForTagging: false,
                                            label: 'mind control',
                                            broaderId:
                                                'http://data.disney.com/resources/6d6963ad-6442-4fce-aae7-c45db6783424',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/5d03ef41-0ce0-4b5c-b07a-aa394e7487d8',
                                    notForTagging: false,
                                    label: 'haunting',
                                    broaderId:
                                        'http://data.disney.com/resources/32a2816a-7c7c-4411-9024-382544266440',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/4cbb0d5c-b976-4bde-8910-1f5ed8b15743',
                            notForTagging: false,
                            label: 'reincarnation',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/baf1f121-e50d-41fa-b448-d309fd0197b0',
                            notForTagging: false,
                            label: 'lost worlds discovered',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7e7c32bb-8ef0-4696-9594-a80813995d8c',
                            notForTagging: false,
                            label: 'resurrection',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3a592499-6840-49f8-b737-a6677a1fa395',
                            notForTagging: false,
                            label: 'journey',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/87cefe3e-8749-43f5-9ab8-93e6e2c688ff',
                            notForTagging: false,
                            label: 'reversal of fortune',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/2f2cd166-a8e7-499b-b7e6-8a5d31915bcc',
                                    notForTagging: false,
                                    label: 'fall from grace',
                                    broaderId:
                                        'http://data.disney.com/resources/87cefe3e-8749-43f5-9ab8-93e6e2c688ff',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/cb45d81f-137f-4349-8e4e-2b510782f274',
                                    notForTagging: false,
                                    label: 'cursed',
                                    broaderId:
                                        'http://data.disney.com/resources/87cefe3e-8749-43f5-9ab8-93e6e2c688ff',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/2d40b05a-9f02-4009-8503-49f94be8a38f',
                                    notForTagging: false,
                                    label: 'riches to rags',
                                    broaderId:
                                        'http://data.disney.com/resources/87cefe3e-8749-43f5-9ab8-93e6e2c688ff',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/d05cf783-bbf5-4e5e-bd4b-ae5d8f0bc764',
                                    notForTagging: false,
                                    label: 'loss of anonymity',
                                    broaderId:
                                        'http://data.disney.com/resources/87cefe3e-8749-43f5-9ab8-93e6e2c688ff',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a9855c4f-5be1-4964-bc9f-178d1c26aeb1',
                                    notForTagging: false,
                                    label: 'rags to riches',
                                    broaderId:
                                        'http://data.disney.com/resources/87cefe3e-8749-43f5-9ab8-93e6e2c688ff',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/4a570644-99c2-431f-89f8-a099616c739a',
                            notForTagging: false,
                            label: 'playing god',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/fd95f922-aecc-47f8-b7af-eec80784fb98',
                            notForTagging: false,
                            label: 'bereavement',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c2c73743-782b-46bd-ac46-32d945fe1ba0',
                            notForTagging: false,
                            label: 'multiverse',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/752046c3-4e9d-4868-8b0c-883cb59a9ec7',
                            notForTagging: false,
                            label: 'time anomaly',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/1f26a25e-d940-49f6-81cd-a74d916d5012',
                                    notForTagging: false,
                                    label: 'time travel',
                                    broaderId:
                                        'http://data.disney.com/resources/752046c3-4e9d-4868-8b0c-883cb59a9ec7',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/dd2f091f-555c-4243-a135-92d5493c0a7e',
                                    notForTagging: false,
                                    label: 'suspended animation',
                                    broaderId:
                                        'http://data.disney.com/resources/752046c3-4e9d-4868-8b0c-883cb59a9ec7',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/d379b72b-f573-488e-9099-0c65a54f712c',
                                    notForTagging: false,
                                    label: 'time loop',
                                    broaderId:
                                        'http://data.disney.com/resources/752046c3-4e9d-4868-8b0c-883cb59a9ec7',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/8c2d0f84-1bc2-4b2a-90f6-c6f174ddd8f4',
                            notForTagging: false,
                            label: 'deception for survival',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/69a7f9e0-7628-4f9c-817d-2a927b06ec14',
                            notForTagging: false,
                            label: 'war experience',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/b6fda3b8-12d1-410f-bc60-0ac34e5bc42d',
                                    notForTagging: false,
                                    label: 'front lines',
                                    broaderId:
                                        'http://data.disney.com/resources/69a7f9e0-7628-4f9c-817d-2a927b06ec14',
                                    terms: [],
                                },
                                {
                                    id: 'http://example.com/DescriptiveConcepts_TaskCommitted#b6fda3b8-12d1-410f-bc60-0ac34e5bc42d',
                                    notForTagging: false,
                                    label: 'front lines',
                                    broaderId:
                                        'http://data.disney.com/resources/69a7f9e0-7628-4f9c-817d-2a927b06ec14',
                                    terms: [],
                                },
                                {
                                    id: 'http://example.com/DescriptiveConcepts_TaskCommitted#ac98b27e-f722-42f9-9b9d-8751b6096454',
                                    notForTagging: false,
                                    label: 'home front',
                                    broaderId:
                                        'http://data.disney.com/resources/69a7f9e0-7628-4f9c-817d-2a927b06ec14',
                                    terms: [],
                                },
                                {
                                    id: 'http://example.com/DescriptiveConcepts_TaskCommitted#90d2ac1b-9ecc-4735-b361-6fd2421999b1',
                                    notForTagging: false,
                                    label: 'consequences of war',
                                    broaderId:
                                        'http://data.disney.com/resources/69a7f9e0-7628-4f9c-817d-2a927b06ec14',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ac98b27e-f722-42f9-9b9d-8751b6096454',
                                    notForTagging: false,
                                    label: 'home front',
                                    broaderId:
                                        'http://data.disney.com/resources/69a7f9e0-7628-4f9c-817d-2a927b06ec14',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/90d2ac1b-9ecc-4735-b361-6fd2421999b1',
                                    notForTagging: false,
                                    label: 'consequences of war',
                                    broaderId:
                                        'http://data.disney.com/resources/69a7f9e0-7628-4f9c-817d-2a927b06ec14',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/e45fb066-87e6-49a8-8daf-2fade60be984',
                            notForTagging: false,
                            label: 'race against time',
                            broaderId:
                                'http://data.disney.com/resources/311f09f8-9e16-4c0d-8b77-d2a6d6be9a51',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                    notForTagging: true,
                    label: 'social dynamics',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/4445c438-d684-4d3c-a7d5-6e882c4e2900',
                            notForTagging: false,
                            label: 'unexpected parenthood',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2d944783-8d31-482c-8f5d-37b829a67492',
                            notForTagging: false,
                            label: 'everyone has a secret',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/752f9f57-0a42-454a-ac44-945aef4d6cb6',
                            notForTagging: false,
                            label: 'deadbeat parent',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/161e5130-8bbe-469d-a241-ffcb45210dfe',
                            notForTagging: false,
                            label: 'workplace issue',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/5fdba27d-b1fd-4c5f-ad50-6ae12cc74007',
                                    notForTagging: false,
                                    label: 'lost job',
                                    broaderId:
                                        'http://data.disney.com/resources/161e5130-8bbe-469d-a241-ffcb45210dfe',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6d4f826f-caf5-4a54-9c3e-739539448cdd',
                                    notForTagging: false,
                                    label: 'coming out of retirement',
                                    broaderId:
                                        'http://data.disney.com/resources/161e5130-8bbe-469d-a241-ffcb45210dfe',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/e6fd35bf-eefb-4ba6-bfd9-7a8401cba802',
                                    notForTagging: false,
                                    label: 'predatory business',
                                    broaderId:
                                        'http://data.disney.com/resources/161e5130-8bbe-469d-a241-ffcb45210dfe',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/f065f50a-cbbf-48f0-8ac1-943e2ad633ca',
                                    notForTagging: false,
                                    label: 'new job',
                                    broaderId:
                                        'http://data.disney.com/resources/161e5130-8bbe-469d-a241-ffcb45210dfe',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3147b71d-9b53-45fb-b63f-1ca876ce131f',
                                    notForTagging: false,
                                    label: 'work-life imbalance',
                                    broaderId:
                                        'http://data.disney.com/resources/161e5130-8bbe-469d-a241-ffcb45210dfe',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/dbb5362e-21c4-410a-82fd-6e50e7490848',
                                    notForTagging: false,
                                    label: 'startup',
                                    broaderId:
                                        'http://data.disney.com/resources/161e5130-8bbe-469d-a241-ffcb45210dfe',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/897d129f-0a1b-491b-8d5a-8eb5a7a6df10',
                                    notForTagging: false,
                                    label: 'one last job',
                                    broaderId:
                                        'http://data.disney.com/resources/161e5130-8bbe-469d-a241-ffcb45210dfe',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/cc7e8506-b371-48dd-917a-cedfe31d02b2',
                                    notForTagging: false,
                                    label: 'failing business',
                                    broaderId:
                                        'http://data.disney.com/resources/161e5130-8bbe-469d-a241-ffcb45210dfe',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/b1cb70d2-36a3-427c-9510-8ae2aa868fb5',
                                    notForTagging: false,
                                    label: 'job dissatisfaction',
                                    broaderId:
                                        'http://data.disney.com/resources/161e5130-8bbe-469d-a241-ffcb45210dfe',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/5d170e6a-eab3-4b8d-99fe-fb9ef1de0d0a',
                            notForTagging: true,
                            label: 'topical issue',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/7ac13a2c-4dee-4a27-acb8-0787fe2e3718',
                            notForTagging: false,
                            label: 'shared identity',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/a5b11f02-afcc-4f99-bde0-1f9e3d8287fc',
                                    notForTagging: false,
                                    label: 'racial and ethnic identity (story elements)',
                                    broaderId:
                                        'http://data.disney.com/resources/7ac13a2c-4dee-4a27-acb8-0787fe2e3718',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/e69816a9-9753-4fae-8b8c-f1ac5ce1b938',
                            notForTagging: true,
                            label: 'revolution',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/8bc73ad0-cd5a-4ef0-8a9e-bcc6e06a9bc9',
                            notForTagging: true,
                            label: 'new kid',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3e67798e-5f27-4e5a-a52f-ac5469c7c3d7',
                            notForTagging: false,
                            label: 'star-crossed lovers',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/747f8da9-f9ed-436d-9a9e-164489af6940',
                            notForTagging: false,
                            label: 'interpersonal relationship',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/60ad8ac9-60e9-43b1-869f-a2ec329edef0',
                                    notForTagging: false,
                                    label: 'hostile interaction',
                                    broaderId:
                                        'http://data.disney.com/resources/747f8da9-f9ed-436d-9a9e-164489af6940',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/66dc9a68-11a6-4da6-848d-e77811e45fc8',
                                            notForTagging: false,
                                            label: 'bullying (story elements)',
                                            broaderId:
                                                'http://data.disney.com/resources/60ad8ac9-60e9-43b1-869f-a2ec329edef0',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/889a357d-2ee8-4a78-9517-0fc81cd54d65',
                                            notForTagging: false,
                                            label: 'revenge',
                                            broaderId:
                                                'http://data.disney.com/resources/60ad8ac9-60e9-43b1-869f-a2ec329edef0',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/3c7ade93-f091-44d3-8ea2-f95c620cd242',
                                            notForTagging: false,
                                            label: 'toxic relationship',
                                            broaderId:
                                                'http://data.disney.com/resources/60ad8ac9-60e9-43b1-869f-a2ec329edef0',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/e629b9c7-5a3a-400f-8a15-3742d17a2707',
                                            notForTagging: false,
                                            label: 'betrayal',
                                            broaderId:
                                                'http://data.disney.com/resources/60ad8ac9-60e9-43b1-869f-a2ec329edef0',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                    notForTagging: false,
                                    label: 'love story',
                                    broaderId:
                                        'http://data.disney.com/resources/747f8da9-f9ed-436d-9a9e-164489af6940',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/337656a5-89ab-4de3-8251-273f0d14a723',
                                            notForTagging: false,
                                            label: 'polyamory',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/cfef9fb5-4090-4391-b530-b19d638f7baa',
                                            notForTagging: false,
                                            label: 'romantic rivalry',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/f362df96-532f-43ab-9d96-d2cd9442517c',
                                                    notForTagging: false,
                                                    label: 'love triangle',
                                                    broaderId:
                                                        'http://data.disney.com/resources/cfef9fb5-4090-4391-b530-b19d638f7baa',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/520234f9-a7d5-4638-a0d8-32958c57c666',
                                                    notForTagging: false,
                                                    label: 'full dance card',
                                                    broaderId:
                                                        'http://data.disney.com/resources/cfef9fb5-4090-4391-b530-b19d638f7baa',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/6c323d69-505a-4643-81b7-73487a502b61',
                                            notForTagging: false,
                                            label: 'cold feet',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/bc1e9844-267d-4148-976e-216a8ebe5b01',
                                            notForTagging: false,
                                            label: 'long distance relationship',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/20cb41d3-c40d-4239-a01e-f76e82ee6cee',
                                            notForTagging: false,
                                            label: 'fake relationship',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/e355eb24-8162-4458-9b4e-789b92987cd8',
                                            notForTagging: false,
                                            label: 'unresolved sexual tension',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/dc6a1954-f530-40b5-b88e-252ffc580364',
                                            notForTagging: false,
                                            label: 'new love',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/8129180e-f873-40ba-b716-010cdbca1659',
                                                    notForTagging: false,
                                                    label: 'forced proximity romance',
                                                    broaderId:
                                                        'http://data.disney.com/resources/dc6a1954-f530-40b5-b88e-252ffc580364',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/c6266af6-dba1-41e0-9956-53c6afcdbda4',
                                                    notForTagging: false,
                                                    label: 'friends-to-lovers',
                                                    broaderId:
                                                        'http://data.disney.com/resources/dc6a1954-f530-40b5-b88e-252ffc580364',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/c652ed9f-8f45-44b2-8f2d-97af33a39710',
                                                    notForTagging: false,
                                                    label: 'love at first sight',
                                                    broaderId:
                                                        'http://data.disney.com/resources/dc6a1954-f530-40b5-b88e-252ffc580364',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/78e076c2-e8ef-4482-a96a-c641610cb259',
                                                    notForTagging: false,
                                                    label: 'first love',
                                                    broaderId:
                                                        'http://data.disney.com/resources/dc6a1954-f530-40b5-b88e-252ffc580364',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/186b5e14-6613-495f-98ee-0b23b10d1945',
                                                    notForTagging: false,
                                                    label: 'enemies-to-lovers',
                                                    broaderId:
                                                        'http://data.disney.com/resources/dc6a1954-f530-40b5-b88e-252ffc580364',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/5a4033c3-1e45-4625-b948-a82c365068c4',
                                            notForTagging: false,
                                            label: 'relationship sabotage',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/d28b51e8-9062-4cf4-bf3e-2accbf5b4743',
                                            notForTagging: false,
                                            label: 'obsessive love',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/33cc25c4-b156-43ee-a961-505047c24a9d',
                                            notForTagging: false,
                                            label: 'forbidden love',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/16b86214-bbef-48bf-9c3f-f5a9157f78fd',
                                            notForTagging: false,
                                            label: 'sworn single settles down',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/4561e2e7-fc80-4c2b-b034-9e77a4ed904c',
                                            notForTagging: false,
                                            label: 'unconventional attraction',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/0843aafe-3877-489e-922b-9c455d017e27',
                                                    notForTagging: false,
                                                    label: 'age difference',
                                                    broaderId:
                                                        'http://data.disney.com/resources/4561e2e7-fc80-4c2b-b034-9e77a4ed904c',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/88f0f3cd-4449-48a1-a1ff-75b287fadfb1',
                                                    notForTagging: false,
                                                    label: 'cross-cultural romance',
                                                    broaderId:
                                                        'http://data.disney.com/resources/4561e2e7-fc80-4c2b-b034-9e77a4ed904c',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/903a4e18-338a-48af-9611-d8c99eae4911',
                                                    notForTagging: false,
                                                    label: 'unequal pairing',
                                                    broaderId:
                                                        'http://data.disney.com/resources/4561e2e7-fc80-4c2b-b034-9e77a4ed904c',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/1e9938c7-dfee-437b-a30f-f6a027484794',
                                            notForTagging: false,
                                            label: 'lonely heart',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/2dcbb8b0-fa40-45cb-8eee-c97c32587da2',
                                                    notForTagging: false,
                                                    label: 'the one that got away',
                                                    broaderId:
                                                        'http://data.disney.com/resources/1e9938c7-dfee-437b-a30f-f6a027484794',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/bd19db8c-2373-450e-aeb0-d680b8b92a1c',
                                                    notForTagging: false,
                                                    label: 'unrequited love',
                                                    broaderId:
                                                        'http://data.disney.com/resources/1e9938c7-dfee-437b-a30f-f6a027484794',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/11b00b7e-46ce-44aa-beb6-b9741c0a3879',
                                            notForTagging: true,
                                            label: 'deterioration',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/87b1e01d-f0bd-43b4-8479-795e8fc9ef98',
                                            notForTagging: false,
                                            label: 'rekindled romance',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/705581b7-d081-4686-91bd-d668d171bfc3',
                                            notForTagging: false,
                                            label: 'broken heart',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/589953a7-5c8c-462b-95d7-14973be90178',
                                                    notForTagging: false,
                                                    label: 'scorned lover',
                                                    broaderId:
                                                        'http://data.disney.com/resources/705581b7-d081-4686-91bd-d668d171bfc3',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/5618aa7c-d3a3-4122-a75a-70b41fc30a09',
                                                    notForTagging: false,
                                                    label: 'on the rebound',
                                                    broaderId:
                                                        'http://data.disney.com/resources/705581b7-d081-4686-91bd-d668d171bfc3',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/452aa4b3-8a99-409e-817f-a29951cf8271',
                                                    notForTagging: false,
                                                    label: 'breakup',
                                                    broaderId:
                                                        'http://data.disney.com/resources/705581b7-d081-4686-91bd-d668d171bfc3',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/3f83f3aa-bbac-493c-bb85-152a90cb7821',
                                            notForTagging: false,
                                            label: 'matchmaking',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/3f2e8832-eae4-48e5-9257-90362124ccae',
                                            notForTagging: false,
                                            label: 'missed connection',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/c56b05b1-c043-47ea-a541-a9796a46804e',
                                                    notForTagging: false,
                                                    label: "we'll always have Paris",
                                                    broaderId:
                                                        'http://data.disney.com/resources/3f2e8832-eae4-48e5-9257-90362124ccae',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/1f80b80b-edf4-438f-a949-44b6ee02fc64',
                                            notForTagging: true,
                                            label: 'happily involved',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/c70bfc10-75b0-4b68-a74d-72c8c9f660b7',
                                            notForTagging: false,
                                            label: 'one-night-stand',
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/d79ac578-4043-4821-a8cc-fd412ad864c7',
                                                    notForTagging: false,
                                                    label: 'anonymous sex',
                                                    broaderId:
                                                        'http://data.disney.com/resources/c70bfc10-75b0-4b68-a74d-72c8c9f660b7',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/0ebb7bc4-d02d-42b9-a081-fa1dad88d669',
                                            notForTagging: false,
                                            label: "lovers' quarrel",
                                            broaderId:
                                                'http://data.disney.com/resources/6718b7fc-76ad-4949-82ec-46d9aecc587a',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/02c1439a-4036-4fb0-b367-f65b6335b9bc',
                                    notForTagging: false,
                                    label: 'personal bond',
                                    broaderId:
                                        'http://data.disney.com/resources/747f8da9-f9ed-436d-9a9e-164489af6940',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/f8c71a90-dbb0-409c-aa58-59174a03d694',
                                            notForTagging: false,
                                            label: 'next big step',
                                            broaderId:
                                                'http://data.disney.com/resources/02c1439a-4036-4fb0-b367-f65b6335b9bc',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/27260e3c-c940-4be1-a129-c0057d06cd2e',
                                            notForTagging: false,
                                            label: 'friendship issue',
                                            broaderId:
                                                'http://data.disney.com/resources/02c1439a-4036-4fb0-b367-f65b6335b9bc',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/cd6a3435-5a24-4c40-bf7f-9371dfb0c83c',
                                                    notForTagging: false,
                                                    label: 'forbidden friendship',
                                                    broaderId:
                                                        'http://data.disney.com/resources/27260e3c-c940-4be1-a129-c0057d06cd2e',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/f9419a55-bbee-46c9-8064-ac00f006811d',
                                                    notForTagging: false,
                                                    label: 'frenemies',
                                                    broaderId:
                                                        'http://data.disney.com/resources/27260e3c-c940-4be1-a129-c0057d06cd2e',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/39818364-2a86-4eb0-b63d-c97ab0857a7c',
                                                    notForTagging: false,
                                                    label: 'buddies',
                                                    broaderId:
                                                        'http://data.disney.com/resources/27260e3c-c940-4be1-a129-c0057d06cd2e',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/66c50eb3-b996-4450-bcea-6867c2ab9632',
                                            notForTagging: false,
                                            label: 'making amends',
                                            broaderId:
                                                'http://data.disney.com/resources/02c1439a-4036-4fb0-b367-f65b6335b9bc',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/983a6c91-17bb-4bb1-b19c-02c8c6f2bb33',
                                            notForTagging: false,
                                            label: 'mentoring',
                                            broaderId:
                                                'http://data.disney.com/resources/02c1439a-4036-4fb0-b367-f65b6335b9bc',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/ee221e73-4392-4703-9a0e-de6b2c9fcf83',
                                            notForTagging: true,
                                            label: 'strange bedfellows',
                                            broaderId:
                                                'http://data.disney.com/resources/02c1439a-4036-4fb0-b367-f65b6335b9bc',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/13e7950f-d4de-44ec-8847-5e46a8897d54',
                                            notForTagging: false,
                                            label: 'helping others',
                                            broaderId:
                                                'http://data.disney.com/resources/02c1439a-4036-4fb0-b367-f65b6335b9bc',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/5e0495dc-e26f-4997-813e-17c5396a6e42',
                                            notForTagging: false,
                                            label: 'human-animal bond',
                                            broaderId:
                                                'http://data.disney.com/resources/02c1439a-4036-4fb0-b367-f65b6335b9bc',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/29259c91-0138-416f-b83e-29a3c4a3823a',
                                            notForTagging: false,
                                            label: 'unlikely allies',
                                            broaderId:
                                                'http://data.disney.com/resources/02c1439a-4036-4fb0-b367-f65b6335b9bc',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/1c45cd92-03bc-4781-934b-97cb80f06027',
                                            notForTagging: false,
                                            label: 'banding together',
                                            broaderId:
                                                'http://data.disney.com/resources/02c1439a-4036-4fb0-b367-f65b6335b9bc',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/9f38a727-8ac2-a72f-bd45-4144098bef99',
                                    notForTagging: false,
                                    label: 'familial relationship',
                                    broaderId:
                                        'http://data.disney.com/resources/747f8da9-f9ed-436d-9a9e-164489af6940',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/fc625025-6159-4f94-9545-04f7f2691fc1',
                                            notForTagging: false,
                                            label: 'family estrangement',
                                            broaderId:
                                                'http://data.disney.com/resources/9f38a727-8ac2-a72f-bd45-4144098bef99',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/5e903ccd-5291-47e3-8a4c-274c7093715a',
                                                    notForTagging: false,
                                                    label: 'dissolution',
                                                    broaderId:
                                                        'http://data.disney.com/resources/fc625025-6159-4f94-9545-04f7f2691fc1',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/4db659f0-f4c2-45e2-a5cc-3f2ff3ee72be',
                                            notForTagging: false,
                                            label: 'seeking family',
                                            broaderId:
                                                'http://data.disney.com/resources/9f38a727-8ac2-a72f-bd45-4144098bef99',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/a5b59d8d-2b78-4801-a7a0-177b54625718',
                                            notForTagging: false,
                                            label: 'chosen family',
                                            broaderId:
                                                'http://data.disney.com/resources/9f38a727-8ac2-a72f-bd45-4144098bef99',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/b1d24b31-86df-4221-93d8-f1448b24be04',
                                            notForTagging: false,
                                            label: 'married life',
                                            broaderId:
                                                'http://data.disney.com/resources/9f38a727-8ac2-a72f-bd45-4144098bef99',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/273ed495-73b0-4708-ae03-9594f5eddcc2',
                                                    notForTagging: false,
                                                    label: 'newlyweds',
                                                    broaderId:
                                                        'http://data.disney.com/resources/b1d24b31-86df-4221-93d8-f1448b24be04',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/ebdda163-53d8-4f0b-979e-c98415a03737',
                                                    notForTagging: false,
                                                    label: 'marriage of convenience',
                                                    broaderId:
                                                        'http://data.disney.com/resources/b1d24b31-86df-4221-93d8-f1448b24be04',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/b3431884-4ce9-45e2-8466-d2d64086913e',
                                                    notForTagging: false,
                                                    label: 'arranged marriage',
                                                    broaderId:
                                                        'http://data.disney.com/resources/b1d24b31-86df-4221-93d8-f1448b24be04',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/436acaf5-4572-45e9-8e7f-d3a60e7192b8',
                                            notForTagging: false,
                                            label: 'family business',
                                            broaderId:
                                                'http://data.disney.com/resources/9f38a727-8ac2-a72f-bd45-4144098bef99',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/e5608b5f-9963-4451-b8e3-a08777a79186',
                                            notForTagging: true,
                                            label: 'same-sex marriage',
                                            broaderId:
                                                'http://data.disney.com/resources/9f38a727-8ac2-a72f-bd45-4144098bef99',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/b3d533cd-da5d-44b8-9da6-b8c7615008bd',
                                            notForTagging: false,
                                            label: "partner's family",
                                            broaderId:
                                                'http://data.disney.com/resources/9f38a727-8ac2-a72f-bd45-4144098bef99',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/d52ae5df-e05f-4aea-bebb-8aa9f2b824cb',
                                            notForTagging: false,
                                            label: 'familial approval',
                                            broaderId:
                                                'http://data.disney.com/resources/9f38a727-8ac2-a72f-bd45-4144098bef99',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/829b72bc-0c7b-4f80-8f04-08ab34e5a560',
                                                    notForTagging: false,
                                                    label: 'child approval',
                                                    broaderId:
                                                        'http://data.disney.com/resources/d52ae5df-e05f-4aea-bebb-8aa9f2b824cb',
                                                    terms: [],
                                                },
                                                {
                                                    id: 'http://data.disney.com/resources/6e426a98-a9a0-405c-95ed-0c71378f7fe0',
                                                    notForTagging: false,
                                                    label: 'parental approval',
                                                    broaderId:
                                                        'http://data.disney.com/resources/d52ae5df-e05f-4aea-bebb-8aa9f2b824cb',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/819a013e-618e-449f-a957-538283de286a',
                                            notForTagging: false,
                                            label: 'sibling rivalry',
                                            broaderId:
                                                'http://data.disney.com/resources/9f38a727-8ac2-a72f-bd45-4144098bef99',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/6c3e4b0f-c67e-4150-8531-e3068f313e16',
                                            notForTagging: false,
                                            label: 'parent-child relationship',
                                            broaderId:
                                                'http://data.disney.com/resources/9f38a727-8ac2-a72f-bd45-4144098bef99',
                                            terms: [
                                                {
                                                    id: 'http://data.disney.com/resources/90bbbbdb-6ff5-4c49-8f39-4b619657ebcf',
                                                    notForTagging: false,
                                                    label: 'single parenting',
                                                    broaderId:
                                                        'http://data.disney.com/resources/6c3e4b0f-c67e-4150-8531-e3068f313e16',
                                                    terms: [],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/0606c21d-d184-4b70-b337-d2df2f13fe68',
                            notForTagging: false,
                            label: 'embarrassing parent',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/ed65d9bb-1f06-4728-9aa5-96613a03c572',
                            notForTagging: false,
                            label: 'adult living with parent',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/bd3f93cc-98d0-48d8-9800-0cd1e39387c7',
                            notForTagging: false,
                            label: 'abandoned child',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/1565d0f1-d88f-46ad-9d30-8a454dbdf3de',
                            notForTagging: false,
                            label: 'deception',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/1722f9ff-d069-4b82-9c45-fb45910f6e6b',
                                    notForTagging: false,
                                    label: 'faked death',
                                    broaderId:
                                        'http://data.disney.com/resources/1565d0f1-d88f-46ad-9d30-8a454dbdf3de',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/c9ad15d1-c244-436f-8c97-7dbb2aae72cd',
                                    notForTagging: false,
                                    label: 'cover-up',
                                    broaderId:
                                        'http://data.disney.com/resources/1565d0f1-d88f-46ad-9d30-8a454dbdf3de',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/711db1c9-07fb-4ad6-8ce8-77208be29dac',
                                    notForTagging: false,
                                    label: 'hidden past',
                                    broaderId:
                                        'http://data.disney.com/resources/1565d0f1-d88f-46ad-9d30-8a454dbdf3de',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/1b47915a-d68b-4de6-a51c-d8f7721cbd94',
                            notForTagging: false,
                            label: 'controlling parent',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e2524fe3-f13d-4d7c-8951-446b9ec27797',
                            notForTagging: true,
                            label: 'manipulative behavior',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/75abc1d0-6298-49aa-8b02-4591e8f28f98',
                            notForTagging: false,
                            label: 'parentification',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e51b039d-77e3-49d5-9a81-466c3fe1872c',
                            notForTagging: false,
                            label: 'power struggle',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/67e1194d-98b1-4808-8274-312ed90a7d21',
                                    notForTagging: false,
                                    label: 'palace intrigue',
                                    broaderId:
                                        'http://data.disney.com/resources/e51b039d-77e3-49d5-9a81-466c3fe1872c',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/07807a7b-7d79-4a97-af7a-1b28496c433b',
                                    notForTagging: false,
                                    label: 'rivalry',
                                    broaderId:
                                        'http://data.disney.com/resources/e51b039d-77e3-49d5-9a81-466c3fe1872c',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/1784d257-7410-4f2a-a1b4-d323f1308709',
                                            notForTagging: false,
                                            label: 'battle of the sexes',
                                            broaderId:
                                                'http://data.disney.com/resources/07807a7b-7d79-4a97-af7a-1b28496c433b',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/4c1dbf08-8803-4cce-b7d7-c65ef706fa83',
                                    notForTagging: false,
                                    label: 'competition',
                                    broaderId:
                                        'http://data.disney.com/resources/e51b039d-77e3-49d5-9a81-466c3fe1872c',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/d374530a-6994-4b44-ba90-d698ecaed348',
                            notForTagging: false,
                            label: 'office romance',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/46c5d54d-320a-4e6f-b83a-b55b075b5b8e',
                            notForTagging: false,
                            label: 'social status',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cd94a548-b9cc-4d65-b5fd-ff148246f5d5',
                            notForTagging: false,
                            label: 'exclusive social group',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/a5133e78-1a88-49e5-876b-9e4932b07c33',
                            notForTagging: false,
                            label: 'paradigm shift',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/2b401fda-16de-4a64-aa1f-9a7f31c75391',
                            notForTagging: false,
                            label: 'incest',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/b4bb22f1-64dd-43a5-915f-0b841d1abeec',
                            notForTagging: false,
                            label: 'infidelity (story elements)',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/6e143f18-a40c-4235-87dd-8bfee1b00ef0',
                            notForTagging: true,
                            label: 'misleading behavior',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/3b312b30-9b36-519b-d3e6-8d27d5f88cb0',
                            notForTagging: false,
                            label: 'criminal activity',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/4b7344c3-1943-4940-ae1e-5ae5cefc4396',
                                    notForTagging: false,
                                    label: 'murder (story elements)',
                                    broaderId:
                                        'http://data.disney.com/resources/3b312b30-9b36-519b-d3e6-8d27d5f88cb0',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/3818129c-ccad-49cc-b63d-0442de13bf54',
                                    notForTagging: false,
                                    label: 'organized crime operation',
                                    broaderId:
                                        'http://data.disney.com/resources/3b312b30-9b36-519b-d3e6-8d27d5f88cb0',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/d1b26fd8-1162-4a36-a56f-b856b3d64f38',
                                            notForTagging: false,
                                            label: 'dirty cops',
                                            broaderId:
                                                'http://data.disney.com/resources/3818129c-ccad-49cc-b63d-0442de13bf54',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/b1442f00-61dd-4fe7-8629-4c12e6709ef8',
                                            notForTagging: false,
                                            label: 'drug trade',
                                            broaderId:
                                                'http://data.disney.com/resources/3818129c-ccad-49cc-b63d-0442de13bf54',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/7c827cf4-b8fc-4903-8edc-61140b8bed6a',
                                            notForTagging: false,
                                            label: 'human trafficking (story elements)',
                                            broaderId:
                                                'http://data.disney.com/resources/3818129c-ccad-49cc-b63d-0442de13bf54',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a8fc3373-609e-40c0-83bc-b47aca73172c',
                                    notForTagging: false,
                                    label: 'extortion (story elements)',
                                    broaderId:
                                        'http://data.disney.com/resources/3b312b30-9b36-519b-d3e6-8d27d5f88cb0',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/02d0681f-597c-4540-8d72-8684edeeaa65',
                                    notForTagging: false,
                                    label: 'abuse (story elements)',
                                    broaderId:
                                        'http://data.disney.com/resources/3b312b30-9b36-519b-d3e6-8d27d5f88cb0',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/1050f092-909a-4fbb-96a5-2b29344dade2',
                                            notForTagging: false,
                                            label: 'elder abuse (story elements)',
                                            broaderId:
                                                'http://data.disney.com/resources/02d0681f-597c-4540-8d72-8684edeeaa65',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/43665691-80f4-4674-936b-08110b0ae2f9',
                                            notForTagging: false,
                                            label: 'child abuse (story elements)',
                                            broaderId:
                                                'http://data.disney.com/resources/02d0681f-597c-4540-8d72-8684edeeaa65',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/a9eabf8b-3f83-4313-a67e-6cb6ece6dc83',
                                            notForTagging: false,
                                            label: 'partner abuse',
                                            broaderId:
                                                'http://data.disney.com/resources/02d0681f-597c-4540-8d72-8684edeeaa65',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/016e08cd-4d31-41a0-948e-d124f3447117',
                                    notForTagging: true,
                                    label: 'organized crime',
                                    broaderId:
                                        'http://data.disney.com/resources/3b312b30-9b36-519b-d3e6-8d27d5f88cb0',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/156bde57-3510-4a68-8ba2-e265971dc159',
                                    notForTagging: false,
                                    label: 'terrorism (story elements)',
                                    broaderId:
                                        'http://data.disney.com/resources/3b312b30-9b36-519b-d3e6-8d27d5f88cb0',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/4d899642-a1b3-492a-b36b-de81e9fcf52e',
                                    notForTagging: false,
                                    label: 'stalking (story elements)',
                                    broaderId:
                                        'http://data.disney.com/resources/3b312b30-9b36-519b-d3e6-8d27d5f88cb0',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/aa31df07-5069-4cb7-8140-9a5bc244f455',
                                    notForTagging: false,
                                    label: 'caper (story elements)',
                                    broaderId:
                                        'http://data.disney.com/resources/3b312b30-9b36-519b-d3e6-8d27d5f88cb0',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/a2dfd202-6f17-4442-99ca-172d685a0bff',
                                            notForTagging: false,
                                            label: 'con',
                                            broaderId:
                                                'http://data.disney.com/resources/aa31df07-5069-4cb7-8140-9a5bc244f455',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/fd2a0a40-d878-4344-af6b-da111a3f3489',
                                    notForTagging: false,
                                    label: 'criminal misadventure',
                                    broaderId:
                                        'http://data.disney.com/resources/3b312b30-9b36-519b-d3e6-8d27d5f88cb0',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/17150f3f-3802-47a9-baa8-d960cb9d37cf',
                                            notForTagging: false,
                                            label: 'witnessing a crime',
                                            broaderId:
                                                'http://data.disney.com/resources/fd2a0a40-d878-4344-af6b-da111a3f3489',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/dacb7f82-77bf-41c5-8066-ac8d3278edb0',
                                    notForTagging: false,
                                    label: 'sexual and gender violence',
                                    broaderId:
                                        'http://data.disney.com/resources/3b312b30-9b36-519b-d3e6-8d27d5f88cb0',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/994ac072-a6c9-2793-01c3-12130167c8ab',
                                    notForTagging: false,
                                    label: 'kidnapping',
                                    broaderId:
                                        'http://data.disney.com/resources/3b312b30-9b36-519b-d3e6-8d27d5f88cb0',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/7c7f6851-837e-4a10-9609-c7d4fc54b2d3',
                            notForTagging: false,
                            label: 'fighting crime',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/8428571e-5bb5-4ba4-a361-421ca4d53dea',
                                    notForTagging: false,
                                    label: 'solving a crime',
                                    broaderId:
                                        'http://data.disney.com/resources/7c7f6851-837e-4a10-9609-c7d4fc54b2d3',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/749e3751-ef63-4bdd-a1d8-56e0a6259c88',
                                            notForTagging: false,
                                            label: 'elusive killer',
                                            broaderId:
                                                'http://data.disney.com/resources/8428571e-5bb5-4ba4-a361-421ca4d53dea',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/695de568-ff00-44f9-b6f4-29246bb5cbff',
                                            notForTagging: false,
                                            label: 'cold case',
                                            broaderId:
                                                'http://data.disney.com/resources/8428571e-5bb5-4ba4-a361-421ca4d53dea',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/f351407a-37a2-485b-8a28-56f978e6ec0d',
                                            notForTagging: false,
                                            label: 'questionable means',
                                            broaderId:
                                                'http://data.disney.com/resources/8428571e-5bb5-4ba4-a361-421ca4d53dea',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/a020c927-a94e-4834-84ca-8adf1d024476',
                                    notForTagging: false,
                                    label: 'fight for justice',
                                    broaderId:
                                        'http://data.disney.com/resources/7c7f6851-837e-4a10-9609-c7d4fc54b2d3',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/b4726ab6-6037-4735-b3d5-0354d031b7f9',
                                            notForTagging: false,
                                            label: 'innocent person',
                                            broaderId:
                                                'http://data.disney.com/resources/a020c927-a94e-4834-84ca-8adf1d024476',
                                            terms: [],
                                        },
                                        {
                                            id: 'http://data.disney.com/resources/e95b9b4a-5035-49d8-a0bd-507953da7900',
                                            notForTagging: false,
                                            label: 'vigilante justice',
                                            broaderId:
                                                'http://data.disney.com/resources/a020c927-a94e-4834-84ca-8adf1d024476',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/ca972517-1b1a-47d5-bea0-3755e4bedd71',
                                    notForTagging: false,
                                    label: 'whistleblower',
                                    broaderId:
                                        'http://data.disney.com/resources/7c7f6851-837e-4a10-9609-c7d4fc54b2d3',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/6b453e17-f8e1-4aa3-b844-a3ac076e77d0',
                                    notForTagging: false,
                                    label: 'confidential informant',
                                    broaderId:
                                        'http://data.disney.com/resources/7c7f6851-837e-4a10-9609-c7d4fc54b2d3',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/e81edf2a-7a49-41ae-aac8-0f013f0a2c53',
                            notForTagging: false,
                            label: 'fish-out-of-water',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/c0946c7f-b278-44bf-bc03-2624df69f705',
                            notForTagging: false,
                            label: 'exiled',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/03d07125-cf49-41f6-817d-6c14b3b29ff4',
                            notForTagging: false,
                            label: 'class conflict',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/34ecd674-8be5-4fca-b916-0311e66b6cb2',
                            notForTagging: false,
                            label: 'adopted child',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/e93d3c69-c7e8-0c21-12e8-68658278b49a',
                            notForTagging: false,
                            label: 'political issue',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/34c8d7c7-d92a-48de-be7e-eceba31f0739',
                            notForTagging: false,
                            label: 'toxic atmosphere',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/30445ab3-cd89-4727-847b-5de4ae8d1e83',
                            notForTagging: false,
                            label: 'discrimination issue',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/4c96a779-0af6-4cba-9160-a5f0c965e7d6',
                            notForTagging: false,
                            label: 'school issue',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [
                                {
                                    id: 'http://data.disney.com/resources/13cb54e4-857a-4533-bea7-9656d948f71b',
                                    notForTagging: false,
                                    label: 'school event',
                                    broaderId:
                                        'http://data.disney.com/resources/4c96a779-0af6-4cba-9160-a5f0c965e7d6',
                                    terms: [
                                        {
                                            id: 'http://data.disney.com/resources/c314b623-3e76-47b2-aa75-3a8e45d31caa',
                                            notForTagging: false,
                                            label: 'school dance',
                                            broaderId:
                                                'http://data.disney.com/resources/13cb54e4-857a-4533-bea7-9656d948f71b',
                                            terms: [],
                                        },
                                    ],
                                },
                                {
                                    id: 'http://data.disney.com/resources/deff39b5-1299-4d45-9957-91e26ccde1e0',
                                    notForTagging: false,
                                    label: 'academic dishonesty',
                                    broaderId:
                                        'http://data.disney.com/resources/4c96a779-0af6-4cba-9160-a5f0c965e7d6',
                                    terms: [],
                                },
                                {
                                    id: 'http://data.disney.com/resources/76aec9dc-9d1d-495b-8b12-012bb572d895',
                                    notForTagging: false,
                                    label: 'school organization',
                                    broaderId:
                                        'http://data.disney.com/resources/4c96a779-0af6-4cba-9160-a5f0c965e7d6',
                                    terms: [],
                                },
                            ],
                        },
                        {
                            id: 'http://data.disney.com/resources/0105b900-f5a3-42e1-bac6-1b0c9932d57e',
                            notForTagging: false,
                            label: 'intergenerational conflict',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/55f6f3f2-eda3-431f-9dbf-9979b6eeb70a',
                            notForTagging: false,
                            label: 'no adults',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/cd825164-4552-47e8-b49d-a857d649f22a',
                            notForTagging: false,
                            label: 'fitting in',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/299ae9bc-930d-4ebe-a894-c7a4be16449c',
                            notForTagging: false,
                            label: 'secretly in love',
                            broaderId:
                                'http://data.disney.com/resources/082b13af-6f4b-48f0-b9b7-391017422416',
                            terms: [],
                        },
                    ],
                },
            ],
        },
        {
            label: 'Storyline Components',
            type: 'http://data.disney.com/resources/StorylineComponents',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/15b0ddcf-7f2e-4e77-9f14-2170d462197d',
                    notForTagging: false,
                    label: 'alternative plot',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d80c12e7-7758-4dc9-aa4f-fc603fc79123',
                    notForTagging: false,
                    label: 'main plot',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/6e8d98cd-3eae-411b-b0ff-d0abcf0081d0',
                    notForTagging: false,
                    label: 'secondary plot',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/444b961b-faf1-4f7b-a132-30dbfe8a2d08',
                    notForTagging: false,
                    label: 'stinger',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/da46a356-60b0-44c7-93f0-9e036be66881',
                    notForTagging: false,
                    label: 'subplot',
                    terms: [],
                },
            ],
        },
        {
            label: 'Subjects',
            type: 'http://data.disney.com/resources/Subjects',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/916eebc5-032e-497a-af0e-63c4c07f7aba',
                    notForTagging: false,
                    label: 'aging',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8b3fa1ce-9842-9dc7-8c5c-db806197679b',
                    notForTagging: false,
                    label: 'arts & crafts',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3de90f6e-caba-4f06-b638-59591824d6d1',
                    notForTagging: false,
                    label: 'automobiles',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/424887d6-a739-cbd3-0dab-2826726a1991',
                    notForTagging: false,
                    label: 'beauty',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/244c434f-8610-7f35-bf2b-5ba8b5f920bb',
                    notForTagging: false,
                    label: 'business',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/66e58430-5178-48c2-8595-6ecf6247197d',
                    notForTagging: false,
                    label: 'crime',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f0943e6d-3215-4ce1-bfd4-acdd62c0a153',
                    notForTagging: false,
                    label: 'disaster',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1e7c8685-3369-4638-90db-a5503660bd40',
                    notForTagging: false,
                    label: 'educational',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/7a3d70a4-f7ed-4bfb-88b7-dd5dd7149b29',
                    notForTagging: false,
                    label: 'entertainment',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/71175ecc-c6bc-4c6d-a237-6a2961a3aebe',
                    notForTagging: false,
                    label: 'environment',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/57b0c56f-6372-4456-94e5-c0e493361d5f',
                    notForTagging: false,
                    label: 'family',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d945f9aa-7776-2835-1580-e9a0d851bbac',
                    notForTagging: false,
                    label: 'fashion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/101aa88a-167b-4729-930b-bac779627cd0',
                    notForTagging: false,
                    label: 'food & cooking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4c6f47cb-dce2-4ae2-bfa0-2ccc016daf98',
                    notForTagging: false,
                    label: 'health',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d9e6f5f9-5604-4a4e-b3c6-5caa53834608',
                    notForTagging: false,
                    label: 'history',
                    terms: [
                        {
                            id: 'http://data.disney.com/resources/e6ba1461-765c-4ef7-88e9-e887d87fc143',
                            notForTagging: false,
                            label: 'slavery',
                            broaderId:
                                'http://data.disney.com/resources/d9e6f5f9-5604-4a4e-b3c6-5caa53834608',
                            terms: [],
                        },
                        {
                            id: 'http://data.disney.com/resources/25eaab22-5746-4939-81fc-706f225be84b',
                            notForTagging: false,
                            label: 'slavery',
                            broaderId:
                                'http://data.disney.com/resources/d9e6f5f9-5604-4a4e-b3c6-5caa53834608',
                            terms: [],
                        },
                    ],
                },
                {
                    category:
                        'http://data.disney.com/resources/b7d21a5a-fee3-4695-abaa-45036b9fb09f',
                    notForTagging: true,
                    label: 'holiday',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/50bbcd90-13a7-4681-81d2-ddf56aec330e',
                    notForTagging: false,
                    label: 'home improvement',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/c95a23af-f9a1-4a34-85a5-f3382c85a8f3',
                    notForTagging: false,
                    label: 'legal',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/37bb8857-a097-46c8-9c25-a6eadd8dbdcf',
                    notForTagging: false,
                    label: 'marriage',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/59cd17a4-87bb-48f9-9c88-016025d7dffc',
                    notForTagging: false,
                    label: 'medical',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/913d75f4-9747-4bce-980c-eaab3256c1ff',
                    notForTagging: false,
                    label: 'parenting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f1787568-0806-4854-bfec-b2f351a4e312',
                    notForTagging: false,
                    label: 'people, society, and culture',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/96f11d41-a2b9-4093-8c5c-945ab8bfa6ec',
                    notForTagging: false,
                    label: 'pets',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4d88c2ad-2c45-452a-9228-6664b51c84c2',
                    notForTagging: false,
                    label: 'politics',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ae813949-0f34-4131-a70d-7876bc5c3d48',
                    notForTagging: false,
                    label: 'pregnancy',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b42df1ab-79e8-4f48-a342-cc7541aacb16',
                    notForTagging: false,
                    label: 'religion/spirituality',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b83b00de-98f0-4ab5-bf05-e8f6fea2b95a',
                    notForTagging: false,
                    label: 'science',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ecfad7fc-ae87-4a01-b7eb-de3a7d0e9ea5',
                    notForTagging: true,
                    label: 'science/technology',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/380de78d-5889-4ada-ac98-d86515cbf38f',
                    notForTagging: false,
                    label: 'sports',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/964bd5af-8dcd-4794-b033-8991e9f9124a',
                    notForTagging: false,
                    label: 'supernatural',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8c5a3175-6e69-4847-94ae-99f41be1faa4',
                    notForTagging: false,
                    label: 'technology',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b734f093-d960-47f1-a4a6-d1ba90815785',
                    notForTagging: false,
                    label: 'travel',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4f39d213-67a9-ea62-85b9-7dd014619691',
                    notForTagging: false,
                    label: 'war',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/952185f7-cb9b-4f45-843e-143ff727c177',
                    notForTagging: false,
                    label: 'wedding',
                    terms: [],
                },
            ],
        },
        {
            label: 'Themes Topics',
            type: 'http://data.disney.com/resources/ThemesTopics',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/8532ae17-e7f3-c5a7-915b-7e7c417a71b6',
                    notForTagging: true,
                    label: 'art',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d63ea442-5ebb-4193-aa97-8499d73e864d',
                    notForTagging: false,
                    label: 'automobiles',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/424887d6-a739-cbd3-0dab-2826726a1991',
                    notForTagging: false,
                    label: 'beauty',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/244c434f-8610-7f35-bf2b-5ba8b5f920bb',
                    notForTagging: false,
                    label: 'business',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0ee6e484-4f1a-4565-8d3b-e2bf21aa7dc8',
                    notForTagging: false,
                    label: 'celebrity',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8228a92a-cbc6-4141-8d6c-c69016f8ca24',
                    notForTagging: false,
                    label: 'child abuse',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/39d08b3c-5fd6-498b-8096-0703f1a77c83',
                    notForTagging: false,
                    label: 'discrimination',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f69cdcb2-4805-4689-b7d5-eb1527ab2901',
                    notForTagging: false,
                    label: 'drug trafficking',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d945f9aa-7776-2835-1580-e9a0d851bbac',
                    notForTagging: false,
                    label: 'fashion',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/58ce8a50-6cca-4514-a23a-fa7c74ffd5bd',
                    notForTagging: false,
                    label: 'friendship',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4c6f47cb-dce2-4ae2-bfa0-2ccc016daf98',
                    notForTagging: false,
                    label: 'health',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b7d21a5a-fee3-4695-abaa-45036b9fb09f',
                    notForTagging: true,
                    label: 'holiday',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/50bbcd90-13a7-4681-81d2-ddf56aec330e',
                    notForTagging: false,
                    label: 'home improvement',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/913d75f4-9747-4bce-980c-eaab3256c1ff',
                    notForTagging: false,
                    label: 'parenting',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1d5a74cb-5eeb-404c-9f19-58f9d1984711',
                    notForTagging: false,
                    label: 'real estate',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ca9a514b-1fa9-4b25-b06f-22f556942f31',
                    notForTagging: false,
                    label: 'schools',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f434e215-cf9b-4029-ac59-3c78bcf45528',
                    notForTagging: false,
                    label: 'science',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/0c2eee5c-5aeb-4746-aa21-fa20efe06aeb',
                    notForTagging: false,
                    label: 'sex (theme)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d2498574-ff99-a383-390f-a8a3e94013b7',
                    notForTagging: false,
                    label: 'social media',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/794dc8b5-241d-79f6-4283-2306bb969a82',
                    notForTagging: false,
                    label: 'spirituality',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/34411a42-aa94-1f0d-9340-3866127e4099',
                    notForTagging: false,
                    label: 'technology',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ca334a2b-bc01-5eb6-f062-bcd67342c4b3',
                    notForTagging: false,
                    label: 'weather',
                    terms: [],
                },
            ],
        },
        {
            label: 'Time Era',
            type: 'http://data.disney.com/resources/TimeEra',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/139f47a5-b26d-4e2a-bf84-4c421490045e',
                    notForTagging: false,
                    label: '19th Century - ca. 1789-1860',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/bfd54ee7-3168-4cd1-9659-87f696c65854',
                    notForTagging: false,
                    label: '19th Century - ca. 1861-1865',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8359e5da-a579-44c8-9b13-4b50dcb1ef11',
                    notForTagging: false,
                    label: '19th Century - ca. 1866-1890',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/115863e6-7410-4ccb-9f37-b2c2bbffc075',
                    notForTagging: false,
                    label: '20th Century - 1946-1959',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/8f632851-66ab-42be-9db9-b1f9bc994a5b',
                    notForTagging: false,
                    label: '20th Century - 1960-1966',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5aa972f5-bc39-4cc0-a26a-84211c9bbc90',
                    notForTagging: false,
                    label: '20th Century - 1967-1974',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/9482ca00-583e-4fc4-8da2-4912aab3bea0',
                    notForTagging: false,
                    label: '20th Century - 1981-1989',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b04b4d2d-97e0-4aca-9050-c6c0b304263e',
                    notForTagging: false,
                    label: '20th Century - WWI',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d275d57f-cd09-4f77-8365-5f5f54118c9a',
                    notForTagging: false,
                    label: '20th Century - WWII',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/32b8691d-bc99-476f-adeb-8f4825a6908c',
                    notForTagging: false,
                    label: '20th Century - ca. 1919-1929',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/fa22a631-5639-413b-8c98-95ff68feb242',
                    notForTagging: false,
                    label: '20th Century - ca. 1929-1939',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/3e8be54c-84ca-4607-bd11-e5040064f077',
                    notForTagging: false,
                    label: '20th Century - ca. 1975-1980',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/94e3a6cc-bb72-44ea-a0bf-fbd09121b4e5',
                    notForTagging: false,
                    label: '20th Century - ca. 1990-1999',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b96fb4fa-be54-46ef-999d-5eb16df93ae6',
                    notForTagging: false,
                    label: 'Ancient Civilization (ca. 776 BCE - 476 CE)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f33bd305-8267-43e1-89cf-2e3f325eceb0',
                    notForTagging: false,
                    label: 'Colonialism (ca. 1607-1788)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/cebd8f3c-f227-4390-989f-4a75e466af61',
                    notForTagging: false,
                    label: 'Contemporary (21st Century) (2000-)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/af0f4743-d748-487d-8d77-b49facbdc853',
                    notForTagging: false,
                    label: 'Dark Ages (ca. 477-1300)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/879b0cd5-e7af-4838-8858-e692f14abcf2',
                    notForTagging: false,
                    label: 'Elizabethan Era (1558-1606)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/996beb0b-e214-4e6d-aa25-8b79d5455c0b',
                    notForTagging: false,
                    label: 'Future, The',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/670cd8bf-996c-4cbd-b58b-49d587a3bd6c',
                    notForTagging: false,
                    label: 'Past (Unspecified)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/69163891-5bf8-4911-b2b1-88197bd2691c',
                    notForTagging: false,
                    label: 'Pre-Historic',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/5f68c515-0e6f-43da-a28c-829cb5cfe5ee',
                    notForTagging: false,
                    label: 'Renaissance (ca. 1300-1557)',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/13aba0a6-323c-4608-af82-3f24203ba019',
                    notForTagging: false,
                    label: 'Turn of the (20th) Century (ca. 1890-1913)',
                    terms: [],
                },
            ],
        },
        {
            label: 'Times of Day',
            type: 'http://data.disney.com/resources/TimesOfDay',
            categories: [
                {
                    category:
                        'http://data.disney.com/resources/e21abfed-0528-439b-b05d-86148aad2b96',
                    notForTagging: false,
                    label: 'afternoon',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/4eb25aba-18df-4065-a4ca-80db6f5d9345',
                    notForTagging: false,
                    label: 'dawn',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/d24040ec-2126-4647-b619-d51504a55c4d',
                    notForTagging: false,
                    label: 'day',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/b5eefd5f-7e18-4a11-89e1-be24726aa84c',
                    notForTagging: false,
                    label: 'dusk',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/1f409391-77cc-46a4-b15f-1580a67b6143',
                    notForTagging: false,
                    label: 'morning',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/379368a1-2b03-4244-9efd-721dd942c88d',
                    notForTagging: false,
                    label: 'night',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/f2bedfd4-275d-4021-9c25-cbf11264ffc1',
                    notForTagging: false,
                    label: 'sunrise',
                    terms: [],
                },
                {
                    category:
                        'http://data.disney.com/resources/ca5a8102-025a-46ee-b5fb-eeb14be563e0',
                    notForTagging: false,
                    label: 'sunset',
                    terms: [],
                },
            ],
        },
    ],
    count: 44,
}
