import React, { useCallback, useMemo } from 'react'
import { CellProps, Column, FilterProps } from 'react-table'
import ReactTooltip from 'react-tooltip'
import copy from 'copy-to-clipboard'
import { ValidationError } from 'yup'

import { CastMember } from 'codecs/CastMember'
import { flags } from 'shared/flags'

import { usePortrayalsContext } from './portrayalRowsContext'
import { Pill, PillsWrapper } from '../Table/TablePills'
import { OpenModalButton } from '../Icons/OpenModalIcon/OpenModalIcon'
import { EditIconButton } from '../Icons/EditIcon'
import { TrashBinButton } from '../Icons/TrashBinIcon'
import { CloseIconButton } from '../Icons/CloseIcon/CloseIcon'
import { CheckIconButton } from '../Icons/CheckIcon/CheckIcon'
import { CheckboxFilter } from '../Table'
import {
    hasAttr,
    extractResIdHash,
    notifyDataNotChangedWarning,
    getMessageFromValidationError,
    notifyInlineValidationError,
    isRowInEdit,
    isInlineEdit,
    buildCastMemberFromFormState,
} from './utils'
import { PortrayalTypeSelect } from './PortrayalTypeSelect'
import { PortrayalNameInput } from './PortrayalNameInput'

import {
    PortrayalDetailsViewMode,
    PortrayalDetailsViewStage,
    PortrayalsDetailsModalState,
} from './PortrayalDetailsModal'

import styled from 'shared/theme'
import { CopyIconButton } from '../Icons/CopyIcon/CopyIcon'
import { PortrayalRoleSelect } from './PortrayalRoleSelect'
import { PortrayalFormSchema } from './validationSchemas'
import { InlineEditPortrayedBy } from './PortrayedBySearch'
import { InlineEditPortrays } from './PortraysSearch'
import {
    NONE_FIELD_LABEL,
    UNDEFINED_FIELD_LABEL,
    portrayalsPermanentFilterColDef,
} from './constants'

const CellContent = styled.div`
    min-width: 170px;
`

type PortrayalsCellProps = CellProps<CastMember> & {
    formControlsEnabled: boolean
    hasLibReadRole: boolean
    hasLibWriteRole: boolean
    isPortrayalsEditingAllowed: boolean
    hasWIPData: boolean
    rowProps: {
        onUpdateCharacter(updatedCharacter: CastMember, index: number): void
    }
    onPortrayalModalChange(state: PortrayalsDetailsModalState): void
    onUnlinkPortrayal(cm: CastMember): void
}

export const PortrayalNameCell: React.FC<PortrayalsCellProps> = (props: PortrayalsCellProps) => {
    const {
        row: { original: c },
        hasLibWriteRole,
    } = props

    const { portrayalInEdit } = usePortrayalsContext()

    const isCellRowInEdit = useMemo(() => {
        return isRowInEdit(c, portrayalInEdit)
    }, [c, portrayalInEdit])

    return (
        <CellContent>
            {isCellRowInEdit ? (
                <PortrayalNameInput disabled={!hasLibWriteRole} />
            ) : (
                <span>{`${c.portrayal?.portrayalName}`}</span>
            )}
        </CellContent>
    )
}

export const PortrayalTypeCell: React.FC<PortrayalsCellProps> = (props: PortrayalsCellProps) => {
    const {
        row: { original: c },
        hasLibWriteRole,
    } = props

    const { portrayalInEdit } = usePortrayalsContext()

    const isCellRowInEdit = useMemo(() => {
        return isRowInEdit(c, portrayalInEdit)
    }, [c, portrayalInEdit])

    return (
        <CellContent>
            {isCellRowInEdit ? (
                <PortrayalTypeSelect disabled={!hasLibWriteRole} />
            ) : (
                <span>{`${c.portrayal.portrayalType?.label}`}</span>
            )}
        </CellContent>
    )
}

const ResIdTooltip: React.FC<{ id: string; charIdHash: string; characterId: string }> = ({
    id,
    charIdHash,
    characterId,
}) => {
    return (
        <ReactTooltip
            id={id}
            getContent={() => (
                <div>
                    <div style={{ fontWeight: 'bold' }}>Concept URI:</div>
                    <div>
                        {characterId}{' '}
                        <CopyIconButton
                            title="Copy to clipboard"
                            style={{ verticalAlign: 'middle' }}
                            onClick={() => copy(characterId)}
                        />
                    </div>
                    <div style={{ fontWeight: 'bold' }}>GUID:</div>
                    <div>
                        {charIdHash}{' '}
                        <CopyIconButton
                            title="Copy to clipboard"
                            style={{ verticalAlign: 'middle' }}
                            onClick={() => copy(charIdHash)}
                        />
                    </div>
                </div>
            )}
            effect="solid"
            delayHide={500}
            delayShow={2000}
            delayUpdate={500}
            place={'right'}
            border={true}
            type={'light'}
        />
    )
}

export const PortraysCell: React.FC<PortrayalsCellProps> = (props: PortrayalsCellProps) => {
    const {
        row: { original: c },
        hasLibReadRole,
        hasLibWriteRole,
        onPortrayalModalChange,
    } = props

    const { portrayalInEdit } = usePortrayalsContext()

    const isCellRowInEdit = useMemo(() => {
        return isRowInEdit(c, portrayalInEdit)
    }, [c, portrayalInEdit])

    // Currenlty only one item expected
    const portraysData = useMemo(() => {
        const portraysItem = c.character
        const resourceHash = extractResIdHash(portraysItem.characterId)
        return {
            portraysId: portraysItem.characterId,
            portraysLabel: portraysItem.characterName,
            mdmId: portraysItem.mdmId,
            resHash: resourceHash,
        }
    }, [c])

    const handleOpenPortrayalsRelationships = useCallback(() => {
        onPortrayalModalChange({
            open: true,
            portrayal: c,
            mode: PortrayalDetailsViewMode.Relationships,
            stage: PortrayalDetailsViewStage.None,
        })
    }, [c, onPortrayalModalChange])

    return (
        <CellContent>
            {isCellRowInEdit ? (
                <InlineEditPortrays disabled={!hasLibWriteRole} />
            ) : hasLibReadRole ? (
                <>
                    <span
                        data-for={c.portrayal.portrayalId}
                        data-tip={portraysData.portraysId}
                        style={{ cursor: 'pointer' }}
                    >
                        {`${portraysData.portraysLabel}`}{' '}
                        <OpenModalButton
                            label="Open Modal"
                            style={{ verticalAlign: 'middle', marginLeft: '1rem' }}
                            onClick={handleOpenPortrayalsRelationships}
                        />
                    </span>
                    <ResIdTooltip
                        id={c.portrayal.portrayalId}
                        charIdHash={portraysData.resHash}
                        characterId={portraysData.portraysId}
                    />
                </>
            ) : (
                <span>{`${c.character.characterName}`} </span>
            )}
        </CellContent>
    )
}

export const PortrayedByCell: React.FC<PortrayalsCellProps> = (props: PortrayalsCellProps) => {
    const {
        row: { original: c },
        hasLibWriteRole,
    } = props

    const { portrayalInEdit } = usePortrayalsContext()

    const isCellRowInEdit = useMemo(() => {
        return isRowInEdit(c, portrayalInEdit)
    }, [c, portrayalInEdit])

    return (
        <CellContent>
            {isCellRowInEdit ? (
                <InlineEditPortrayedBy disabled={!hasLibWriteRole} />
            ) : (
                <span>{`${c.__meta__?.portrayedBy || NONE_FIELD_LABEL}`}</span>
            )}
        </CellContent>
    )
}

export const CharacterMDMIdCell: React.FC<PortrayalsCellProps> = (props: PortrayalsCellProps) => {
    const {
        row: { original: c },
    } = props

    const { portrayalInEdit } = usePortrayalsContext()

    const isCellRowInEdit = useMemo(() => {
        return isRowInEdit(c, portrayalInEdit)
    }, [c, portrayalInEdit])

    // TODO: Handle merge of the portrayals data. Pick the correct mdm id
    //? In theory mdmid should be the same for both portrayals of twins

    return isCellRowInEdit ? (
        <PillsWrapper style={{ display: 'inline' }}>
            <Pill>{`${c.character?.mdmId || UNDEFINED_FIELD_LABEL}`}</Pill>
        </PillsWrapper>
    ) : (
        <span>{`${c.character?.mdmId || UNDEFINED_FIELD_LABEL}`}</span>
    )
}

export const RoleCell: React.FC<PortrayalsCellProps> = (props: PortrayalsCellProps) => {
    const {
        row: { original: c },
        hasLibWriteRole,
    } = props

    const { portrayalInEdit } = usePortrayalsContext()

    const isCellRowInEdit = useMemo(() => {
        return isRowInEdit(c, portrayalInEdit)
    }, [c, portrayalInEdit])

    return (
        <CellContent>
            {isCellRowInEdit ? (
                <PortrayalRoleSelect disabled={!hasLibWriteRole} />
            ) : (
                <span>{`${c.role?.roleLabel}`}</span>
            )}
        </CellContent>
    )
}

export const ControlsCell: React.FC<PortrayalsCellProps> = (props: PortrayalsCellProps) => {
    const {
        row: { original: c },
        hasLibReadRole,
        hasLibWriteRole,
        isPortrayalsEditingAllowed,
        hasWIPData,
        // rowProps: { onUpdateCharacter },
        onPortrayalModalChange,
        onUnlinkPortrayal,
    } = props

    // TODO: Disable inline editing before QA
    const { isSaving, portrayalInEdit, startEditing, setCurrentPortrayal, updatePortrayal } =
        usePortrayalsContext()
    const isEdit = useMemo(() => {
        return isInlineEdit(portrayalInEdit)
    }, [portrayalInEdit])
    const isCellRowInEdit = useMemo(() => {
        return isRowInEdit(c, portrayalInEdit)
    }, [c, portrayalInEdit])

    const startInlineEditing = useCallback(() => {
        startEditing(c.portrayal.portrayalId)
    }, [startEditing, c])

    const cancelEditing = useCallback(() => {
        setCurrentPortrayal(null)
    }, [setCurrentPortrayal])

    const saveData = useCallback(() => {
        if (!portrayalInEdit) return

        // Check if it works properly with this back and forth transformations
        const cmInEdit = buildCastMemberFromFormState(portrayalInEdit, c.attributes)

        const hasChanges = !notifyDataNotChangedWarning(cmInEdit, c, false)

        if (hasChanges) {
            PortrayalFormSchema.validate(portrayalInEdit)
                .then(() => {
                    updatePortrayal(c.portrayal.portrayalId as string, c)
                })
                .catch((err: ValidationError) => {
                    const message = getMessageFromValidationError(err)
                    notifyInlineValidationError(message)
                })
        }
    }, [c, portrayalInEdit, updatePortrayal])

    const handleOpenPortrayalsDetails = useCallback(() => {
        onPortrayalModalChange({
            open: true,
            portrayal: c,
            mode: PortrayalDetailsViewMode.ReadWrite,
            stage: PortrayalDetailsViewStage.None,
        })
    }, [c, onPortrayalModalChange])

    const handlePortrayalUnlink = useCallback(() => {
        onUnlinkPortrayal(c)
    }, [c, onUnlinkPortrayal])

    if (isEdit && !isCellRowInEdit) {
        return null
    }

    if (isSaving) {
        return null
    }

    return (
        <div style={{ minWidth: '100px' }}>
            {isCellRowInEdit ? (
                <div style={{ textAlign: 'right' }}>
                    <CloseIconButton
                        label="Cancel"
                        onClick={cancelEditing}
                        style={{ verticalAlign: 'middle' }}
                    />
                    <CheckIconButton
                        label="Save"
                        onClick={saveData}
                        style={{ verticalAlign: 'middle', marginLeft: '1rem' }}
                    />
                </div>
            ) : (
                <div style={{ textAlign: 'right' }}>
                    {hasLibWriteRole && flags.portrayalsInlineEditing && (
                        <EditIconButton
                            label="Edit"
                            onClick={startInlineEditing}
                            style={{ verticalAlign: 'middle' }}
                        />
                    )}
                    {hasLibReadRole && (
                        <OpenModalButton
                            label="Open Modal"
                            style={{ verticalAlign: 'middle', marginLeft: '1rem' }}
                            onClick={handleOpenPortrayalsDetails}
                        />
                    )}
                    {hasLibWriteRole && isPortrayalsEditingAllowed && (
                        <TrashBinButton
                            label="Unlink Portrayal"
                            style={{ verticalAlign: 'middle', marginLeft: '1rem' }}
                            onClick={handlePortrayalUnlink}
                            isDisabled={hasWIPData}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

const PORTRAYALS_CELLS_CFG = {
    PORTRAYAL_NAME: {
        Header: 'Portrayal Name',
        accessor: (c: CastMember) => c.portrayal?.portrayalName,
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    PORTRAYAL_TYPE: {
        Header: 'Portrayal Type',
        accessor: (c: CastMember) => c.portrayal?.portrayalType?.label,
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    PORTRAYS: {
        Header: 'Portrays',
        accessor: (c: CastMember) => c.character.characterName,
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    MDMID: {
        Header: 'MDM ID',
        accessor: (c: CastMember) => c.character.mdmId,
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    PORTRAYED_BY: {
        Header: 'Portrayed By',
        // TODO: Make sure that data is correct for twins
        accessor: (c: CastMember) => c.__meta__?.portrayedBy || NONE_FIELD_LABEL,
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    ROLE: {
        Header: 'Role',
        accessor: (c: CastMember) => c.role?.roleLabel,
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    HAS_ATTRIBUTES: {
        Header: 'Attributes',
        accessor: (c: CastMember): string => {
            const hasData = hasAttr(c)
            return hasData ? 'Yes' : 'No'
        },
        Filter: (props: FilterProps<CastMember>) => <CheckboxFilter {...props} hasSearch={false} />,
        filter: 'checkbox',
    },
}

export const editableColumnsDef: Column<CastMember>[] = [
    {
        ...PORTRAYALS_CELLS_CFG.PORTRAYAL_NAME,
        Cell: PortrayalNameCell,
    },
    {
        ...PORTRAYALS_CELLS_CFG.PORTRAYAL_TYPE,
        Cell: PortrayalTypeCell,
    },
    {
        ...PORTRAYALS_CELLS_CFG.PORTRAYS,
        Cell: PortraysCell,
    },
    {
        ...PORTRAYALS_CELLS_CFG.MDMID,
        Cell: CharacterMDMIdCell,
    },
    {
        ...PORTRAYALS_CELLS_CFG.PORTRAYED_BY,
        Cell: PortrayedByCell,
    },
    {
        ...PORTRAYALS_CELLS_CFG.ROLE,
        Cell: RoleCell,
    },
    PORTRAYALS_CELLS_CFG.HAS_ATTRIBUTES,
    portrayalsPermanentFilterColDef,
]

export const controlsCelDef: Column<CastMember> = {
    Header: '',
    id: 'controls',
    Cell: ControlsCell,
}

export const staticColumnsDef: Column<CastMember>[] = [
    {
        ...PORTRAYALS_CELLS_CFG.PORTRAYAL_NAME,
    },
    {
        ...PORTRAYALS_CELLS_CFG.PORTRAYAL_TYPE,
    },
    {
        ...PORTRAYALS_CELLS_CFG.PORTRAYS,
    },
    {
        ...PORTRAYALS_CELLS_CFG.MDMID,
    },
    {
        ...PORTRAYALS_CELLS_CFG.PORTRAYED_BY,
    },
    {
        ...PORTRAYALS_CELLS_CFG.ROLE,
    },
    PORTRAYALS_CELLS_CFG.HAS_ATTRIBUTES,
    portrayalsPermanentFilterColDef,
]
