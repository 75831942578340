import { orderBy } from 'lodash'
import * as s from 'shared/diff/schema'
import { DEIAttribute, CastMember } from '../../codecs/CastMember'

interface IComboAttrValue {
    attributeLabel: string
    attributeId: string
}
interface IComboAttrInterface {
    primary?: IComboAttrValue
    secondary?: IComboAttrValue
}

const ComboAttrDiffSchema = s.schema({
    primary: s.value<string | undefined>(),
    secondary: s.value<string | undefined>(),
})

export type DiffableComboAttribute = s.TypeOf<typeof ComboAttrDiffSchema>

export const AttributesDiffSchema = s.schema({
    [DEIAttribute.Genders]: s.array(s.value<string>()),
    [DEIAttribute.RaceOrEthnicity]: s.array(s.value<string>()),
    [DEIAttribute.SexualOrientation]: s.array(s.value<string>()),
    [DEIAttribute.AgeGroups]: s.array(s.value<string>()),
    [DEIAttribute.PlaceOfOrigin]: s.array(s.value<string>()),
    [DEIAttribute.Religion]: s.array(s.value<string>()),
    [DEIAttribute.Disability]: s.array(s.value<DiffableComboAttribute>()),
    [DEIAttribute.MilitaryBody]: s.array(s.value<DiffableComboAttribute>()),
})

export const CharacterDiffSchema = s.schema({
    character: s.ignore<{ characterId: string; characterName: string; mdmId?: string }>(),
    characterId: s.ignore<string>(),
    portrayalId: s.ignore<string>(),
    talent: s.ignore<{
        talentId?: string
        talentName?: string
    }>(),
    portrayal: s.ignore<{
        portrayalId?: string
        portrayalType?: {
            uri: string
            label: string
        } | null
        portrayalName?: string
    }>(),
    role: s.ignore<{ roleId?: string | undefined; roleLabel?: string | undefined }>(),
    __meta__: s.ignore<{ portrayedBy: string } | undefined>(),
    attributes: AttributesDiffSchema,
})

export type DiffableAttributes = s.TypeOf<typeof AttributesDiffSchema>

const toDiffableMultiAttr = (
    attributes: { [key: string]: { attributeLabel: string }[] },
    key: DEIAttribute,
) => {
    return orderBy((attributes[key] || []).map(a => a.attributeLabel))
}

const toDiffableComboAttr = (
    attributes: { [key: string]: IComboAttrInterface[] },
    key: DEIAttribute,
): DiffableComboAttribute[] => {
    return (attributes[key] || []).map(a => {
        const res = {} as DiffableComboAttribute
        if (a.primary) {
            res.primary = a.primary.attributeLabel
        }
        if (a.secondary) {
            res.secondary = a.secondary.attributeLabel
        }
        return res
    })
}

export const toDiffableAttributes = (attributes: { [key: string]: any }): DiffableAttributes => {
    return {
        [DEIAttribute.Genders]: toDiffableMultiAttr(attributes, DEIAttribute.Genders),
        [DEIAttribute.RaceOrEthnicity]: toDiffableMultiAttr(
            attributes,
            DEIAttribute.RaceOrEthnicity,
        ),
        [DEIAttribute.SexualOrientation]: toDiffableMultiAttr(
            attributes,
            DEIAttribute.SexualOrientation,
        ),
        [DEIAttribute.AgeGroups]: toDiffableMultiAttr(attributes, DEIAttribute.AgeGroups),
        [DEIAttribute.PlaceOfOrigin]: toDiffableMultiAttr(attributes, DEIAttribute.PlaceOfOrigin),
        [DEIAttribute.Religion]: toDiffableMultiAttr(attributes, DEIAttribute.Religion),
        // ? what with the types?
        [DEIAttribute.Disability]: toDiffableComboAttr(attributes, DEIAttribute.Disability) as any,
        [DEIAttribute.MilitaryBody]: toDiffableComboAttr(
            attributes,
            DEIAttribute.MilitaryBody,
        ) as any,
    }
}

export interface DiffableCharacter extends s.TypeOf<typeof CharacterDiffSchema> {}

export const toDiffableCharacter = (castMember: CastMember): DiffableCharacter => {
    return {
        characterId: castMember.character.characterId,
        portrayalId: castMember.portrayal.portrayalId,
        character: { ...castMember.character },
        talent: { ...castMember.talent },
        role: { ...castMember.role },
        portrayal: { ...castMember.portrayal },
        __meta__: castMember.__meta__,
        attributes: toDiffableAttributes(castMember.attributes || {}),
    }
}
