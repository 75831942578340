import React from 'react'

import styled from 'shared/theme'
import { Formik, Form } from 'formik'
import CheckboxInput from 'shared/components/CheckboxRadioInput/Checkbox'
import Button from 'shared/components/Button'
import { WIPDataType } from 'model/WIP'
import TextualButton from 'shared/components/TextualButton'
import Container from 'shared/components/Container'
import {
    AlertDialog,
    AlertDialogLabel,
    AlertDialogButtons,
    AlertDialogDescription,
} from 'shared/components/AlertModal'
import WIPInfo from 'shared/resource/WIPInfo'
import { useLockedWIPs, useResourceMachine } from 'shared/resource/ResourceMachineProvider'
import { WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA } from '@genome-web-forms/server'

export const StopEditingButton = styled(TextualButton)`
    color: ${props => props.theme.colors.white};
    margin-right: 1.5rem;
`

export const workflowTasksTypeLabels: { [x in WIPDataType]: string } = {
    [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA]: 'Descriptors',
    storylines: 'Storylines',
    relationships: 'Relationships',
    characters: 'Portrayals',
}

export const ReleaseWIPsControl: React.FC<{ disabled: boolean }> = ({ disabled, children }) => {
    const [showModal, setShowModal] = React.useState(false)
    return (
        <>
            <StopEditingButton disabled={disabled} type="button" onClick={() => setShowModal(true)}>
                {children}
            </StopEditingButton>
            {showModal && <ReleaseWIPsModal closeModal={() => setShowModal(false)} />}
        </>
    )
}

export const ReleaseWIPsModal: React.FC<{ closeModal: () => void }> = ({ closeModal }) => {
    const [state, send] = useResourceMachine()
    const cancelRef = React.createRef<HTMLButtonElement>()

    const onSubmit = ({ selection }: { selection: WIPDataType[] }): void => {
        send({ type: 'RELEASE', selection })
        closeModal()
    }

    const isProcessing = state.matches('releasing')

    const availableTypes = useLockedWIPs().map(wip => wip.dataType)

    const initialValues: {
        selection: WIPDataType[]
    } = {
        selection: [],
    }

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues, onSubmit }}>
                {({ values, setValues }) => (
                    <Form>
                        <AlertDialogLabel>Which tabs do you wish to unlock?</AlertDialogLabel>
                        <AlertDialogDescription>Select the tabs to edit:</AlertDialogDescription>
                        <Container mt={3} mb="2" flexDirection="column">
                            {state.context.wips
                                .map(ref => ref.state.context.dataType)
                                .map(dataType => (
                                    <Container key={dataType} alignItems="center">
                                        <CheckboxInput
                                            name="selection"
                                            value={dataType}
                                            checked={values?.selection.includes(dataType)}
                                            disabled={
                                                isProcessing || !availableTypes.includes(dataType)
                                            }
                                        ></CheckboxInput>
                                        <span>{workflowTasksTypeLabels[dataType]}:</span>
                                        <WIPInfo dataType={dataType} />
                                    </Container>
                                ))}

                            <div style={{ display: 'flex' }}>
                                <Button
                                    variant="outline"
                                    type="button"
                                    size="small"
                                    disabled={isProcessing}
                                    onClick={() =>
                                        setValues({
                                            selection: [...availableTypes],
                                        })
                                    }
                                >
                                    Select All
                                </Button>
                            </div>
                        </Container>

                        <AlertDialogButtons>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={values.selection.length < 1 || isProcessing}
                                isLoading={isProcessing}
                            >
                                Release
                            </Button>
                            <TextualButton
                                type="button"
                                onClick={closeModal}
                                ref={cancelRef}
                                disabled={isProcessing}
                            >
                                Cancel
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}
export default ReleaseWIPsControl
