import React, { useCallback, useState } from 'react'
import { ValueType } from 'react-select'
import { AsyncProps } from 'react-select/async'
import { debounce } from 'lodash'

import { MyIDUser } from '@genome-web-forms/common/auth'

import { useUser } from 'auth/Auth'
import { MemoAsyncSelect } from 'shared/components/Select'

import { searchPortrayals } from 'api/portrayals'
import { abortableLoadOptions, formatPortrayalsFoundOptions as formatOptions } from './utils'
import { DEFAULT_DEBOUNCE_DELAY } from './constants'
import { FormattedPortrayal } from './types'

import styled from 'shared/theme'

const getOptionLabel = (o: FormattedPortrayal) => o.label
const getOptionValue = (o: FormattedPortrayal) => o.portrayalId

const fetchData = async (
    user: MyIDUser,
    text = '',
    abortCtrl: AbortController,
): Promise<FormattedPortrayal[]> => {
    if (text === '') {
        return []
    }
    return searchPortrayals(text, user, abortCtrl).then(data => {
        return formatOptions(data)
    })
}

const loadOptions = (
    user: MyIDUser,
    text: string,
    abortCtrl: AbortController,
    callback: (opts: FormattedPortrayal[]) => any,
) => {
    fetchData(user, text, abortCtrl).then(resp => callback(resp))
}

const debouncedLoadOptions = debounce(loadOptions, DEFAULT_DEBOUNCE_DELAY)

const PortrayalSearch: React.FC<{
    onChange: (portrayal: ValueType<FormattedPortrayal>) => void
    value: FormattedPortrayal | null
}> = ({ value, onChange }) => {
    const user = useUser()

    const [prevCancelTokenSourceObj] = useState({
        ctrl: null,
    })

    const loadOptions = useCallback<AsyncProps<FormattedPortrayal>['loadOptions']>(
        (inputValue, callback) =>
            abortableLoadOptions(
                user,
                inputValue,
                prevCancelTokenSourceObj,
                callback,
                debouncedLoadOptions,
            ),
        [user, prevCancelTokenSourceObj],
    )

    return (
        <MemoAsyncSelect
            isMulti={false}
            value={value}
            loadOptions={loadOptions}
            placeholder={'Search...'}
            cacheOptions={false}
            onChange={onChange}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            menuPlacement="auto"
            isClearable={true}
        />
    )
}

export default styled(PortrayalSearch)`
    display: flex;
    flex-basis: 180px;
    flex-direction: column;
`
