import React from 'react'

import styled from 'shared/theme'
import {
    StoryRoleAttribute,
    STORYROLE_TYPE_TRAITS,
    STORYROLE_TYPE_CHARACTER_ARCHETYPE,
    STORYROLE_TYPE_MOTIVATIONS,
} from 'codecs/storyline/StoryRole'
import StoryroleAttribute from './StoryroleAttribute'

const GridItem = styled.div`
    border-top: 1px solid ${props => props.theme.colors.border};
    border-left: 1px solid ${props => props.theme.colors.border};
    padding: 0.5rem 1rem 1rem;
`
type Props = {
    storyRoleAttributes: StoryRoleAttribute[]
    storyRoleIndex: number
    push: (item: StoryRoleAttribute) => void
    remove: (index: number) => void
}
const StoryroleAttributes: React.FC<Props> = ({
    storyRoleAttributes,
    storyRoleIndex,
    push,
    remove,
}): React.ReactElement => {
    return (
        <>
            <GridItem>
                <StoryroleAttribute
                    label="Character Archetypes"
                    storyRoleAttributes={storyRoleAttributes}
                    storyroleType={STORYROLE_TYPE_CHARACTER_ARCHETYPE}
                    storyRoleIndex={storyRoleIndex}
                    push={push}
                    remove={remove}
                />
            </GridItem>

            <GridItem>
                <StoryroleAttribute
                    label="Motivations"
                    storyRoleAttributes={storyRoleAttributes}
                    storyRoleIndex={storyRoleIndex}
                    storyroleType={STORYROLE_TYPE_MOTIVATIONS}
                    push={push}
                    remove={remove}
                />
            </GridItem>

            <GridItem>
                <StoryroleAttribute
                    label="Traits"
                    storyRoleAttributes={storyRoleAttributes}
                    storyRoleIndex={storyRoleIndex}
                    storyroleType={STORYROLE_TYPE_TRAITS}
                    push={push}
                    remove={remove}
                />
            </GridItem>
        </>
    )
}

export default StoryroleAttributes
