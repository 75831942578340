import React from 'react'
import * as t from 'io-ts'
import { useQuery, UseQueryResult } from 'react-query'
import * as cwm from '@genome-web-forms/common/model/CWMClassType'
import uniq from 'lodash/uniq'
import flatMap from 'lodash/flatMap'
import { pipe } from 'fp-ts/lib/function'
import invariant from 'tiny-invariant'
import {
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
} from '@genome-web-forms/server'
import { useUser } from 'auth/Auth'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { request } from '@genome-web-forms/common/api'
import { authGWF } from 'api/auth'
import config from 'shared/config'

export const TaskTypeCodec = t.keyof({
    [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA]: null,
    [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS]: null,
    [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS]: null,
})
export type TaskType = t.TypeOf<typeof TaskTypeCodec>

//////////////////////////////////////////////////////

export type TaskTypeOption = { value: TaskType; label: string }

export const SourceTypeOptionPayloadCodec = t.type({
    prefLabel: t.string,
    uri: t.string,
})

export interface SourceTypeOption {
    label: string
    value: string
    uri: string // At the moment not used
}

export const TASK_TYPE_OPTIONS_ALL: TaskTypeOption[] = [
    {
        value: WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
        label: 'Descriptors',
    },
    {
        value: WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
        label: 'Storylines / Relationships',
    },
    {
        value: WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS,
        label: 'Portrayals',
    },
]

export const getTaskLabel = (task: TaskType): string => {
    const option = TASK_TYPE_OPTIONS_ALL.find(o => o.value === task)
    invariant(option, `Did not find option for TaskType "${task}"`)
    return option.label
}

// TODO: Remove 'exclude' when adding season workflow support
export const TASK_TYPES_PER_CWM_BASE_TYPE: Record<cwm.CWMBaseClass, TaskType[]> = {
    feature: [
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS,
    ],
    episode: [
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS,
    ],
    series: [
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS,
    ],
    season: [
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS,
    ],
}

export const getTaskOptionsForSelectedTitles = (
    selectedTitles: { cwmClassType: string }[],
): TaskTypeOption[] => {
    const tasksCwmBaseClasses = pipe(
        selectedTitles.map(t => cwm.getCWMBaseClass(t) as cwm.CWMBaseClass),
        uniq,
    )
    const taskTypes: TaskType[] = pipe(
        flatMap(tasksCwmBaseClasses, c => TASK_TYPES_PER_CWM_BASE_TYPE[c]),
        uniq,
    )

    return TASK_TYPE_OPTIONS_ALL.filter(o => taskTypes.includes(o.value))
}

export const getValidTaskTypesPredicate =
    (title: { cwmClassType: string }): ((task: TaskType) => boolean) =>
    task =>
        getAvailableTaskTypesForTitle(title).includes(task)

export const getAvailableTaskTypesForTitle = (title: { cwmClassType: string }): TaskType[] => {
    return TASK_TYPES_PER_CWM_BASE_TYPE[cwm.getCWMBaseClass(title) as cwm.CWMBaseClass]
}

export const useTaskOptionsForSelectedTitles = (
    selectedTitles: { cwmClassType: string }[],
): TaskTypeOption[] => {
    return React.useMemo(() => {
        return getTaskOptionsForSelectedTitles(selectedTitles)
    }, [selectedTitles])
}

export const fetchSources = (user: MyIDUser): Promise<SourceTypeOption[]> => {
    return request(
        t.array(SourceTypeOptionPayloadCodec),
        authGWF(user, {
            url: `${config.urlGWFOntology}/creative-work/workflow-sources`,
        }),
    ).then(data => {
        const mappedOptions = data.map(o => {
            return {
                label: o.prefLabel,
                value: o.prefLabel,
                uri: o.uri,
            }
        })
        mappedOptions.sort(function (a, b) {
            if (a.label < b.label) {
                return -1
            }
            if (a.label > b.label) {
                return 1
            }
            return 0
        })
        return mappedOptions
    })
}

export const useSourceOptionsForSelectedTitles = (): UseQueryResult<SourceTypeOption[]> => {
    const user = useUser()
    return useQuery(['workflow-sources'], () => fetchSources(user), {})
}
