import React from 'react'

import ErrorMessage from 'shared/components/ErrorMessage'
import FormikSelect from '../Select/FormikSelect'
import { getRelationshipTypeOptions } from './utils'

type RelationshipTypeSelectProps = {
    name: string
    disabled: boolean
}

const options = getRelationshipTypeOptions()

export const RelationshipTypeSelect: React.FC<RelationshipTypeSelectProps> = ({
    name,
    disabled,
}) => {
    return (
        <>
            <FormikSelect name={name} options={options} isDisabled={disabled} />

            <ErrorMessage name={name} />
        </>
    )
}
