import { Form, Formik } from 'formik'
import { cloneDeep, isArray, noop, omit, uniqBy } from 'lodash'
import React, { CSSProperties, useEffect } from 'react'
import CellLabel from 'shared/components/CellLabel'
import Container from 'shared/components/Container'
import FormikSelect from 'shared/components/Select/FormikSelect'
import {
    ALL_AVAILABLE_FACETS,
    UngroupedFacetOption,
    FacetSelect,
    SupportedFacet,
    SupportedFacetGroup,
    SUPPORTED_FACETS_OPTIONS,
    useFacetOptions,
} from 'shared/search/useFacetOptions'
import { SearchParams } from 'shared/search/useSearch'
import styled from 'shared/theme'
import { ReactComponent as DeleteIcon } from 'shared/components/Icons/trash-can-icon.svg'
import { components } from 'react-select'
import GroupSelect from 'shared/components/GroupSelect'
import {
    TitleSearchGroupedDateOptions,
    WorkflowTitleSearchGroupedDateOptions,
} from 'model/search/SearchOptions'
import { deepSearchItems } from 'shared/util/deepSearch'
import LocationsSelect from 'shared/components/Metadata/LocationsSelect'
import { Location } from 'codecs/Location'
import { SearchInput } from 'shared/components/search'

export const CATEGORY_SEPARATOR = '---'
export const TYPE_SEPARATOR = '#'

// TODO: Remove when fetched from service
export const DEFAULT_AVAILABLE_FACETS: FacetSelect[] = [
    {
        label: 'Type',
        facet: 'www_w3_org_1999_02_22-rdf-syntax-ns.type',
        componentType: 'single-select',
        locked: true,
        isMulti: false,
        width: '250px',
    },
    {
        label: 'Genres',
        facet: 'genres',
        componentType: 'grouped-select',
        categoriesIncluded: ['genre', 'genre other'],
        locked: true,
        isMulti: true,
        width: '250px',
    },
    {
        label: 'Descriptors',
        facet: 'descriptors',
        componentType: 'grouped-select',
        categoriesExcluded: ['genre', 'genre other'],
        locked: true,
        isMulti: true,
        width: '250px',
    },
    {
        label: 'Date',
        facet: 'dates',
        componentType: 'grouped-date-range',
        locked: true,
        isMulti: true,
        width: '250px',
    },
    {
        label: 'RADAR Product ID',
        placeholder: 'RADAR Product ID',
        facet: 'data_disney_com_cpm.productId',
        componentType: 'text-input',
        locked: true,
        isMulti: false,
        width: '250px',
    },
]

const StyledForm = styled(Form)`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    > *:not(:last-child) {
        padding-right: 1rem;
    }
`

const StyledIconButton = styled.div.attrs({
    className: 'styled-icon-button',
})`
    stroke: ${props => props.theme.colors.black};
    fill: transparent;
    cursor: pointer;
    svg {
        color: ${props => props.theme.colors.gray};
    }
`

export const FormGroup = styled.div`
    position: relative;
    &:hover {
        .form-group-options {
            visibility: visible;
        }
    }
    .form-group-options {
        visibility: hidden;
    }
`

export const FormGroupOptions = styled.div.attrs({
    className: 'form-group-options',
})`
    position: absolute;
    top: 0;
    right: 0;
    svg {
        width: 1em;
    }
`

export const FormSubGroup = styled.div`
    display: flex;
    align-items: center;

    > *:first-child {
        flex: 1;
    }
    > *:not(:first-child) {
        margin-left: 0.5em;
    }

    svg {
        width: 2rem;
    }
`
const GroupHeading = ({ hasSelectedOption, ...props }: any) => {
    const selectedStyle = {
        backgroundColor: 'red',
        color: 'black',
    }
    const style = { ...(hasSelectedOption && selectedStyle) }
    return <components.GroupHeading {...props} style={style} />
}

const Group = (props: any) => {
    const hasSelectedOption = props.children.some((opt: any) => opt.props.isSelected)
    const onClick = () => {
        props.selectProps.onChange(props.options)
    }
    return (
        <components.Group
            {...props}
            headingProps={{ ...props.headingProps, hasSelectedOption, onClick }}
        />
    )
}

const StyledEBSFacet = styled.div`
    min-width: 100px;
`

type EBSFacetProps = {
    style: CSSProperties
    facetSelect: FacetSelect
    values: any
    onValueChange: (
        supportedFacet: SupportedFacet | SupportedFacetGroup,
        values: string | string[],
    ) => void
    onRemove: (facet: SupportedFacet | SupportedFacetGroup) => void
    onSubmit: () => void
    isWorkflowSearch: boolean
}

type EBSFacetWithIncludedGroupsProps = EBSFacetProps & {
    groupsIncluded?: string[]
}

type EBSFacetWithExcludedGroupsProps = EBSFacetProps & {
    groupsExcluded?: string[]
}

const EBSFacet = (props: EBSFacetWithIncludedGroupsProps | EBSFacetWithExcludedGroupsProps) => {
    const { style, facetSelect, onRemove } = props

    const { label, facet, locked } = facetSelect

    return (
        <StyledEBSFacet style={style}>
            <FormGroup key={`eas-select-${facet}`}>
                <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                    {label}
                </CellLabel>
                <FormGroupOptions>
                    {!locked && (
                        <StyledIconButton title="Remove facet" onClick={() => onRemove(facet)}>
                            <DeleteIcon />
                        </StyledIconButton>
                    )}
                </FormGroupOptions>
                <EBSFacetDropdown {...props} />
            </FormGroup>
        </StyledEBSFacet>
    )
}

export const EBSFacetDropdownContainer = styled.div`
    .loading {
        display: flex;
        align-items: center;
        .loader {
            float: right;
        }
    }
`

const EBSFacetDropdown = (props: EBSFacetProps) => {
    const { values, isWorkflowSearch, facetSelect, onValueChange, onSubmit } = props
    const {
        facet,
        label: facetLabel,
        componentType,
        categoriesIncluded,
        categoriesExcluded,
    } = facetSelect
    const { isLoading, options: unfilteredOptions } = useFacetOptions({ facet })

    // Uses root level property 'label' as group. Consider parametrizing if filter using different property is needed
    const options = React.useMemo(() => {
        if (categoriesIncluded && !categoriesExcluded)
            return unfilteredOptions?.filter(o => categoriesIncluded.includes(o.label))
        if (!categoriesIncluded && categoriesExcluded)
            return unfilteredOptions?.filter(o => !categoriesExcluded.includes(o.label))
        return unfilteredOptions
    }, [unfilteredOptions, categoriesIncluded, categoriesExcluded])

    const _value = React.useMemo(() => {
        const facetValue = values?.[facet]
        if (!facetValue) return null
        if (componentType === 'single-select') {
            if (options) {
                const [selectedType] = options
                    .reduce(
                        (prev, curr: any) => [...curr?.options, ...prev],
                        [] as UngroupedFacetOption[],
                    )
                    .filter((option: UngroupedFacetOption) => option.value === values[facet][0])

                return selectedType
            }
            return
        }
        if (componentType === 'grouped-date-range') return facetValue

        const allValues = deepSearchItems(options, 'labelValue', (_: any, v: any) => {
            if (isArray(facetValue)) return facetValue.includes(v)
            return v === facetValue
        })
        return uniqBy(allValues, 'value')
    }, [values, facet, options, componentType])

    const _formikProps = {
        id: `eas-select-${facetLabel}`,
        name: `eas-select-${facetLabel}`,
        options,
        value: _value as any,
        menuPortalTarget: document.body,
        isClearable: true,
        isSearchable: true,
        isMulti: facetSelect.isMulti,
        components: { Group, GroupHeading },
        onChange: (v: any) => {
            const values: string | string[] = isArray(v)
                ? v.map(({ value }) => value)
                : [(v as any)?.value]
            onValueChange(facet, values)
        },
    }

    const _groupedSelectProps = {
        id: `eas-select-${facetLabel}`,
        name: `${facetLabel}`,
        closeMenuOnSelect: false,
        options: options?.filter(({ label }: { label: string }) => label !== 'generation'),
        value: _value as any,
        isMulti: facetSelect.isMulti,
        // TODO: make this a prop if needs to allow multiple date intervals into grouped select
        isSingleInterval: true,
        onChange: (v: any) => {
            const values: string | string[] =
                componentType === 'grouped-date-range'
                    ? isArray(v)
                        ? v.map(i => i?.value)
                        : (v as any)?.value
                    : isArray(v)
                    ? v.map(i => {
                          // Workaround to have type in value
                          return `${i?.value}${i?.type ? `${CATEGORY_SEPARATOR}${i.type}` : ''}`
                      })
                    : (v as any)?.value
            onValueChange(facet, values)
        },
        isLoading,
        placeholder: isLoading ? 'Loading...' : 'Select...',
    }

    const _locationSelectProps = {
        id: `eas-select-${facetLabel}`,
        name: `${facetLabel}`,
        options,
        value: _value as any,
        menuPortalTarget: document.body,
        isClearable: true,
        isSearchable: true,
        isMulti: facetSelect.isMulti,
        components: { Group, GroupHeading },
        onChange: (location: Location[] | null) => {
            const locationIds = location?.map(({ locationId }) => locationId) ?? null
            onValueChange(facet, locationIds ?? [])
        },
    }

    const _inputTextProps = {
        id: `eas-select-${facet}`,
        name: `${facetLabel}`,
        placeholder: facetSelect.placeholder ?? '',
        value: values?.[facet] || '',
        onKeyPress: (e: any) => {
            if (!/[0-9]/.test(e.key)) {
                e.preventDefault()
            }
            if (e.key === 'Enter') {
                e.preventDefault()
                onSubmit()
            }
        },
        onChange: (v: any) => {
            onValueChange(facet, v.target.value)
        },
    }

    let component
    switch (componentType) {
        case 'single-select':
        case 'multiple-select':
            component = <FormikSelect {..._formikProps} />
            break
        case 'grouped-select':
            component = <GroupSelect {..._groupedSelectProps} type={componentType} />
            break
        case 'grouped-date-range':
            component = (
                <GroupSelect
                    {..._groupedSelectProps}
                    type={componentType}
                    options={
                        isWorkflowSearch
                            ? WorkflowTitleSearchGroupedDateOptions
                            : TitleSearchGroupedDateOptions
                    }
                />
            )
            break

        case 'location-select':
            component = <LocationsSelect {..._locationSelectProps} formControlsEnabled={true} />
            break
        case 'text-input':
            component = <SearchInput {..._inputTextProps} />
            break
        default:
            throw Error('Unsupported select component for extended basic search!')
    }

    return <EBSFacetDropdownContainer>{component}</EBSFacetDropdownContainer>
}

type EBSFacetSelectorProps = {
    facets: FacetSelect[]
    onAdd: (facet: SupportedFacet | undefined) => void
}

const StyledEBSFacetSelector = styled.div`
    min-width: 250px;
`

const EBSFacetSelector: React.FC<EBSFacetSelectorProps> = props => {
    const { facets, onAdd } = props

    const options = React.useMemo(
        () => SUPPORTED_FACETS_OPTIONS.filter(sf => !facets.map(f => f.facet).includes(sf.value)),
        [facets],
    )

    if (!options.length) return null

    return (
        <StyledEBSFacetSelector>
            <FormGroup key="eas-select-new">
                <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                    Select new facet
                </CellLabel>
                <FormSubGroup key="eas-select-new">
                    <FormikSelect
                        id="eas-facet-selector"
                        options={options}
                        name="new-facet"
                        placeholder="Select facet"
                        noOptionsMessage={() => 'No more options available'}
                        // Make this "null" to reset selected value right after onChange is executed
                        value={null}
                        menuPortalTarget={document.body}
                        onChange={(({ value }: any) => onAdd(value)) as any}
                    />
                </FormSubGroup>
            </FormGroup>
        </StyledEBSFacetSelector>
    )
}

type ExtendedBasicSearchProps = {
    initialValues: any
    isWorkflowSearch: boolean
    onFacetsChange: (params: SearchParams) => void
    onSubmit: () => void
}

const ExtendedBasicSearch: React.FC<ExtendedBasicSearchProps> = (props): React.ReactElement => {
    const formikRef = React.useRef<any>()
    const { initialValues, isWorkflowSearch, onFacetsChange, onSubmit } = props

    const [params, setParams] = React.useState<SearchParams>(initialValues)
    const [facetsSelect, setFacetsSelect] = React.useState<FacetSelect[]>(DEFAULT_AVAILABLE_FACETS)

    useEffect(() => {
        setParams(initialValues)
        // The following lines merge default facets with facets containing values
        const facetValueKeys = initialValues ? Object.keys(initialValues) : []
        const facetsWithValues = ALL_AVAILABLE_FACETS.filter(availableFacet => {
            return facetValueKeys.includes(availableFacet.label)
        })
        const uniqueFacets = [...DEFAULT_AVAILABLE_FACETS, ...facetsWithValues].filter(
            (v, i, arr) => i === arr.findIndex(j => j.label === v.label),
        )
        setFacetsSelect(uniqueFacets)
    }, [initialValues])

    const handleFacetValueChange = (
        facet: SupportedFacet | SupportedFacetGroup,
        values: string | string[],
    ) => {
        const updatedFacets = Object.assign({}, params, { [facet]: values })
        const facetValue = updatedFacets[facet]
        const facetCleared =
            facetValue === '' || facetValue?.length === 0 || facetValue?.[0] === undefined
        if (facetCleared) {
            delete updatedFacets[facet]
        }
        setParams(updatedFacets)
        onFacetsChange(updatedFacets)
    }

    const handleAddFacet = React.useCallback(
        (facet: SupportedFacet | undefined) => {
            if (!facet) return
            const _facets: FacetSelect[] = cloneDeep(facetsSelect)
            const defaultFacetSelectConfig = ALL_AVAILABLE_FACETS.find(
                cfg => cfg.facet === facet,
            ) as FacetSelect
            const _newFacet: FacetSelect = defaultFacetSelectConfig
            setFacetsSelect([..._facets, _newFacet])
        },
        [facetsSelect],
    )

    const handleRemoveFacet = React.useCallback(
        (removedFacet: SupportedFacet | SupportedFacetGroup | undefined) => {
            if (!removedFacet) return
            const _facets: FacetSelect[] = cloneDeep(facetsSelect).filter(
                f => f.facet !== removedFacet,
            )
            const _params = omit(params, [removedFacet]) as SearchParams
            setFacetsSelect(_facets)
            setParams(_params)
            onFacetsChange(_params)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [facetsSelect, onFacetsChange],
    )

    return (
        <Formik initialValues={{}} innerRef={formikRef} enableReinitialize onSubmit={noop}>
            <Container pl={0} pr={0} pt={2} pb={2}>
                <StyledForm>
                    {facetsSelect.map(facetSelect => (
                        <EBSFacet
                            isWorkflowSearch={isWorkflowSearch}
                            key={facetSelect.facet}
                            values={params}
                            facetSelect={facetSelect}
                            onValueChange={handleFacetValueChange}
                            onSubmit={onSubmit}
                            onRemove={handleRemoveFacet}
                            style={{ width: facetSelect.width }}
                        />
                    ))}
                    <EBSFacetSelector {...{ facets: facetsSelect, onAdd: handleAddFacet }} />
                </StyledForm>
            </Container>
        </Formik>
    )
}

export default ExtendedBasicSearch
