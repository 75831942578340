import {
    AlertDialog as BaseAlertDialog,
    AlertDialogLabel as BaseAlertLabel,
    AlertDialogDescription as BaseAlertDescription,
} from '@reach/alert-dialog'
import '@reach/dialog/styles.css'

import styled from 'shared/theme'

export const AlertDialog = styled(BaseAlertDialog)`
    border: 1px solid ${props => props.theme.colors.gray};
    box-shadow: 0 0.25rem 0.5rem 0 rgba(75, 93, 128, 0.3);
    border-radius: 0.5rem;
    line-height: 2.5rem;
    text-align: center;
    &[data-reach-dialog-content] {
        padding: 3.5rem;
        min-width: 39.75rem;
        max-width: 60rem;
    }
`
export const AlertDialogLabel = styled(BaseAlertLabel)`
    font-size: 1.75rem;
    line-height: 1.5rem;
    color: ${props => props.theme.colors.textPrimary};
`
export const AlertDialogDescription = styled(BaseAlertDescription)`
    font-size: 0.9375rem;
    line-height: 1.6rem;
    padding-top: 1rem;
    color: ${props => props.theme.colors.textSecondary};
`
export const AlertDialogButtons = styled.div`
    display: grid;
    grid-auto-columns: max-content;
    justify-content: center;
    grid-row-gap: 1.25rem;
    padding-top: 2.5rem;
`
