import * as t from 'io-ts'
import { UseQueryResult, useQuery } from 'react-query'

import { request } from '@genome-web-forms/common/api'
import { useUser } from 'auth/Auth'

import { MyIDUser } from '@genome-web-forms/common/auth'
import { authGWF } from 'api/auth'
import config from 'shared/config'

export type PortrayalType = {
    label: string
    uri: string
}

export const PortrayalTypeCodec = t.type({
    label: t.string,
    uri: t.string,
})

const fetchPortrayalTypes = (user: MyIDUser): Promise<PortrayalType[]> => {
    const url = `${config.urlGWFOntology}/portrayal-type`
    return request(
        t.array(PortrayalTypeCodec),
        authGWF(user, {
            url: url,
            method: 'GET',
        }),
    )
}

export const usePortrayalTypes = (): UseQueryResult<PortrayalType[]> => {
    const user = useUser()
    return useQuery<PortrayalType[]>(
        'portrayal-type',
        () => {
            return fetchPortrayalTypes(user)
        },
        {
            cacheTime: Infinity,
            staleTime: Infinity,
        },
    )
}
