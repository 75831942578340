import React, { useMemo, useState } from 'react'
import { QueryObserver, QueryObserverResult, useQuery, UseQueryResult } from 'react-query'
import * as t from 'io-ts'

import { request } from '@genome-web-forms/common/api'
import { is404Error } from '@genome-web-forms/common/error'

import { MetadataDescription, MetadataDescriptionCodec } from 'model/MetadataDescription'
import config from 'shared/config'
import { queryClient } from 'shared/queryClient'
import { useResourceMachineSelector } from 'shared/resource/ResourceMachineProvider'
import { useUser } from 'auth/Auth'

import { ResourceRequest, ResourceType } from './fetch/fetchResource'
import { authGWF } from './auth'

export const makeMetadataDescriptionKey = (config: { resourceId: string }): Array<string> => {
    return ['metadata-description', config.resourceId]
}

type UseCharacterMetadataDescriptionReturn = {
    isLoading: boolean
    isSaving: boolean
    isFetchError: boolean
    notes: MetadataDescription
    updateCharacterNotes: (notes: string) => void
    refetchCharacterNotes: () => Promise<QueryObserverResult<MetadataDescription, unknown>>
}

export const useCharacterMetadataDescription = (
    characterId: string,
): UseCharacterMetadataDescriptionReturn => {
    const user = useUser()

    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [notes, setNotes] = useState<MetadataDescription>({ content: '', lockVersion: 0 })

    const { resourceType, productId } = useResourceMachineSelector<
        any,
        { resourceId: string; resourceType: ResourceType; productId: string }
    >(
        React.useCallback(
            state => ({
                resourceId: state.context.resourceId,
                resourceType: state.context.resourceType,
                productId: state.context.resource.static.productId,
            }),
            [],
        ),
    )

    const resourceIdCombined = useMemo(() => {
        const insertAfter = '/resources/'
        return characterId.replace(insertAfter, `${insertAfter}${productId}/`)
    }, [characterId, productId])

    const {
        isLoading,
        isError: isFetchError,
        refetch: refetchCharacterNotes,
    }: UseQueryResult<MetadataDescription> = useQuery(
        ['character-notes', characterId],
        () => fetchMetadataDescription({ user, resourceId: resourceIdCombined, resourceType }),
        {
            useErrorBoundary: false,
            onSuccess: data => {
                if (!!data) setNotes(data)
            },
        },
    )

    const updateCharacterNotes = React.useCallback(
        async (updatedNotesContent: string) => {
            setIsSaving(true)
            const updatedNotes: MetadataDescription = {
                lockVersion: notes.lockVersion,
                content: updatedNotesContent,
            }
            try {
                await upsertMetadataDescription({
                    user,
                    resourceId: resourceIdCombined,
                    metadataDescription: updatedNotes,
                    resourceType,
                })
                setNotes({ ...updatedNotes, lockVersion: updatedNotes.lockVersion + 1 })
            } finally {
                setIsSaving(false)
            }
        },
        [resourceType, notes, user, resourceIdCombined],
    )

    return { isLoading, isSaving, isFetchError, notes, updateCharacterNotes, refetchCharacterNotes }
}

export const fetchMetadataDescription = async ({
    user,
    resourceId,
    resourceType,
}: ResourceRequest): Promise<MetadataDescription | null> => {
    try {
        return await request(
            MetadataDescriptionCodec,
            authGWF(user, {
                dataProp: 'metadataDescription',
                url: `${config.urlGWF}/creativework/${resourceType}/${encodeURIComponent(
                    resourceId,
                )}/metadata-description`,
            }),
        )
    } catch (e) {
        if (is404Error(e)) {
            return null
        }
        throw e
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createMetadataDescriptionObserver = (config: ResourceRequest) => {
    const queryKey = makeMetadataDescriptionKey(config)
    const queryFn = () => fetchMetadataDescription(config)

    return new QueryObserver(queryClient, {
        queryKey,
        queryFn,
        refetchOnWindowFocus: 'always',
    })
}

export const upsertMetadataDescription = async ({
    user,
    resourceId,
    resourceType,
    metadataDescription,
}: ResourceRequest & {
    metadataDescription: MetadataDescription
}): Promise<MetadataDescription> => {
    let res = await request(
        t.type({ metadataDescription: MetadataDescriptionCodec }),
        authGWF(user, {
            method: 'POST',
            url: `${config.urlGWF}/creativework/${resourceType}/${encodeURIComponent(
                resourceId,
            )}/metadata-description`,
            data: { metadataDescription },
        }),
    )

    return res.metadataDescription
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createMetadataDescriptionMutation = (
    config: ResourceRequest & { metadataDescription: MetadataDescription },
) => {
    const queryKey = makeMetadataDescriptionKey(config)
    const mutationFn = () => upsertMetadataDescription(config)

    return queryClient.getMutationCache().build(queryClient, {
        mutationFn,
        onSuccess: data => {
            queryClient.setQueryData(queryKey, data)
        },
    })
}
