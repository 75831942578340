import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { OptionTypeBase } from 'react-select'

import { CastMember, Character, Portrayal, Talent } from 'codecs/CastMember'
import { NewPortrayalPayload, PortrayalToUpdate } from 'api/portrayals'
import {
    CharacterRelationship,
    relationshipTypeResouceBase,
    RELATIONSHIPS_LABELS_MAP,
    EMPTY_FIELD_LABEL,
    PortrayalType,
    UNDEFINED_FIELD_LABEL,
    NONE_OPTION,
} from './constants'
import { PortrayalFound, TitleAssociation } from 'model/characters/Portrayal'
import { notification } from 'shared/notification'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { ValidationError } from 'yup'
import { FormattedPortrayal, PortrayalFormInProgressState, ResouceData, SearchType } from './types'

export type PortraysItem = {
    portraysId: string
    portraysLabel: string
    mdmId?: string | undefined
}

export type PortrayedByItem = {
    portrayedById: string
    portrayedByLabel: string
}

export const hasAttr = (character: CastMember): boolean => {
    const attributes = character?.attributes || {}
    const attrIds = Object.keys(attributes || {})
    if (!attrIds.length) return false
    const hasData = attrIds.some(attrId => {
        const attr = attributes[attrId]
        return !isEmpty(attr)
    })
    return hasData
}

export const isRowInEdit = (
    char: CastMember,
    charInEdit: PortrayalFormInProgressState | null,
): boolean => {
    return !!charInEdit && charInEdit.portrayal.portrayalId === char.portrayal.portrayalId
}

export const isInlineEdit = (formState: PortrayalFormInProgressState | null): boolean => {
    return !!formState
}

export const generatePortrayalName = (
    portrays: ({ label: string; uri: string } | null)[],
    portrayedBy: ({ label: string; uri: string } | null)[],
): string => {
    const portraysLabels = portrays.map(p => p?.label || null).join('/')
    const portrayedByLabels = portrayedBy.map(p => p?.label || null).join('/')
    return `${portraysLabels} (${portrayedByLabels})`
}

export const preparePortrayalToUpdateFromFormStatePayload = (
    formState: PortrayalFormInProgressState,
    resourceId: string,
    associations: TitleAssociation[],
    isAssociation: boolean,
): PortrayalToUpdate => {
    let dataToUpdate = {
        portrayalID: formState.portrayal.portrayalId as string,
        portrayalName: formState.portrayal.portrayalName as string,
        portrays: [...formState.portrays.map(p => p?.uri || '')],
        portrayedBy: [
            ...formState.portrayedBy
                .filter(pb => pb?.uri !== NONE_OPTION.uri)
                .map(pb => pb?.uri || ''),
        ],
        hasScope: formState.role?.roleId as string,
        portrayalType: formState.portrayal.portrayalType?.uri as string,
        associations: [resourceId],
    }

    const associationsUris = associations.map(a => a.titleUri)

    if (isAssociation) {
        // We should prevent the scenario when a portrayals is already linked to the title
        const otherAssociations = associations
            .filter(a => a.titleUri !== resourceId)
            .map(a => a.titleUri)
        dataToUpdate.associations = [resourceId, ...otherAssociations]
    } else {
        dataToUpdate.associations = associationsUris
    }

    return dataToUpdate
}

export const prepareNewPortrayalFromFormStatePayload = (
    formState: PortrayalFormInProgressState,
    resourceId: string,
): NewPortrayalPayload => {
    let req: NewPortrayalPayload = {
        portrayalName: formState.portrayal.portrayalName as string,
        portrays: [...formState.portrays.map(p => p?.uri || '')],
        portrayedBy: [
            ...formState.portrayedBy
                .filter(pb => pb?.uri !== NONE_OPTION.uri)
                .map(pb => pb?.uri || ''),
        ],
        hasScope: formState.role?.roleId as string,
        portrayalType: formState.portrayal.portrayalType?.uri as string,
        associations: [],
    }

    req.associations = [resourceId]

    return req
}

export const updateResouceData = (
    resource: ResouceData,
    portrayal: CastMember,
    isNew: boolean,
    isUnlink: boolean,
): ResouceData => {
    const portrayalId = portrayal.portrayal.portrayalId

    if (isNew) {
        resource.characters = [portrayal, ...resource.characters]
        return resource
    }

    if (isUnlink) {
        resource.characters = resource.characters.filter(
            p => p.portrayal.portrayalId !== portrayal.portrayal.portrayalId,
        )
        return resource
    }

    resource.characters = resource.characters.map(p => {
        if (p.portrayal.portrayalId === portrayalId) {
            return { ...portrayal, attributes: p.attributes }
        }
        return p
    })

    return resource
}

export const extractResIdHash = (resId: string): string => {
    return resId.replace('http://data.disney.com/resources/', '')
}

export const extractRelationshipTypeValue = (fullRelationshipId: string): string => {
    return fullRelationshipId.replace(`${relationshipTypeResouceBase}#`, '')
}

export const getRelationshipTypeLabelByValue = (relationshipType: string): string => {
    const type = extractRelationshipTypeValue(relationshipType) as CharacterRelationship
    return RELATIONSHIPS_LABELS_MAP[type] || 'Unknown'
}

export const creatRelationshipRecoureId = (relationship: CharacterRelationship): string => {
    return `${relationshipTypeResouceBase}#${relationship}`
}

export const CHAR_RELATIONSHIPS_OPTS = [
    {
        value: creatRelationshipRecoureId(CharacterRelationship.HasOlder),
        label: RELATIONSHIPS_LABELS_MAP[CharacterRelationship.HasOlder],
    },
    {
        value: creatRelationshipRecoureId(CharacterRelationship.IsOlderOf),
        label: RELATIONSHIPS_LABELS_MAP[CharacterRelationship.IsOlderOf],
    },
    {
        value: creatRelationshipRecoureId(CharacterRelationship.HasYounger),
        label: RELATIONSHIPS_LABELS_MAP[CharacterRelationship.HasYounger],
    },
    {
        value: creatRelationshipRecoureId(CharacterRelationship.IsYoungerOf),
        label: RELATIONSHIPS_LABELS_MAP[CharacterRelationship.IsYoungerOf],
    },
    {
        value: creatRelationshipRecoureId(CharacterRelationship.HasIdentity),
        label: RELATIONSHIPS_LABELS_MAP[CharacterRelationship.HasIdentity],
    },
    {
        value: creatRelationshipRecoureId(CharacterRelationship.IsIdentityOf),
        label: RELATIONSHIPS_LABELS_MAP[CharacterRelationship.IsIdentityOf],
    },
    {
        value: creatRelationshipRecoureId(CharacterRelationship.Succeeds),
        label: RELATIONSHIPS_LABELS_MAP[CharacterRelationship.Succeeds],
    },
    {
        value: creatRelationshipRecoureId(CharacterRelationship.SucceededBy),
        label: RELATIONSHIPS_LABELS_MAP[CharacterRelationship.SucceededBy],
    },
]

export const getRelationshipTypeOptions = (): typeof CHAR_RELATIONSHIPS_OPTS => {
    return CHAR_RELATIONSHIPS_OPTS
}

export const notifyNoAssociationError = (
    associations: TitleAssociation[],
    resourceId: string,
    isAssociation: boolean,
): boolean => {
    const hasAssociationWithTheTitle = !!associations.find(a => a.titleUri === resourceId)

    if (!hasAssociationWithTheTitle && !isAssociation) {
        notification.error('Current portrayal has no association to the title')
        return true
    }

    return false
}

export const notifyHasAssociationError = (
    associations: TitleAssociation[],
    resourceId: string,
    isAssociation: boolean,
): boolean => {
    const hasAssociationWithTheTitle = !!associations.find(a => a.titleUri === resourceId)

    if (isAssociation && hasAssociationWithTheTitle) {
        notification.error('Current portrayal is already linked with the title')
        return true
    }

    return false
}

export const notifyDataNotChangedWarning = (
    oldCm: CastMember | null,
    newCm: CastMember,
    isAssociation: boolean,
): boolean => {
    if (isAssociation) {
        return false
    }
    const dataNotChanged = isEqual(oldCm, newCm)

    if (dataNotChanged) {
        notification.warning('Nothing to save')
        return true
    }

    return false
}

export const notifyPortrayalsDuplicates = (portrayals: PortrayalFound[]): boolean => {
    if (portrayals.length > 1) {
        notification.warning('Current portrayal has duplicates')
        return true
    }

    return false
}

export const notifyInlineValidationError = (message: string): void => {
    notification.warning(message)
}

export const abortableLoadOptions = <T extends OptionTypeBase>(
    user: MyIDUser,
    inputValue: string,
    abortCtrlObject: { ctrl: AbortController | null },
    callback: (opts: T[]) => any,
    debouncedLoadOptions: (
        user: MyIDUser,
        val: string,
        ctrl: AbortController,
        callback: (opts: T[]) => any,
    ) => any,
): void => {
    if (abortCtrlObject.ctrl) {
        abortCtrlObject.ctrl.abort()
    }
    const newAbortCtrl = new AbortController()
    // Mutation is used here
    abortCtrlObject.ctrl = newAbortCtrl
    debouncedLoadOptions(user, String(inputValue).trim(), newAbortCtrl, callback)
}

export const getMessageFromValidationError = (err: ValidationError): string => {
    // TODO: adjust validation according to the new multiple fields support
    switch (err.path) {
        case 'portrayal.portrayalName':
            return 'Portrayal name is required'
        case 'portrayal.portrayalType':
            return 'Portrayal type is required'
        case 'character':
            return 'Portrayal character (Portrays) is required'
        case 'talent':
            return 'Portrayal talent (Portrayed By) is required'
        case 'role':
            return 'Portrayal role is required'
        default: {
            return 'Fields must be not empty'
        }
    }
}

export const getPortraysSearchTypeFromPortrayalType = (portrayalType: string): SearchType => {
    switch (portrayalType) {
        case PortrayalType.OfCharacter:
        case PortrayalType.ByTwins:
        case PortrayalType.OfMetaCharacter:
        case PortrayalType.OfPerson:
            return SearchType.Character
        default:
            return SearchType.Character
    }
}

export const getPortrayedBySearchTypeFromPortrayalType = (portrayalType: string): SearchType => {
    switch (portrayalType) {
        case PortrayalType.OfCharacter:
        case PortrayalType.ByTwins:
        case PortrayalType.OfPerson:
            return SearchType.Talent
        case PortrayalType.OfMetaCharacter:
            return SearchType.Character
        default:
            return SearchType.Talent
    }
}

export const getMdmIdLabel = (
    value: { label: string; uri: string; mdmId?: string | undefined } | null,
): string => {
    return value ? (!!value.mdmId ? value.mdmId : EMPTY_FIELD_LABEL) : UNDEFINED_FIELD_LABEL
}

export const buildFormStateFromPortrayalFound = (
    portrayalSelectVal: FormattedPortrayal,
): PortrayalFormInProgressState => {
    const { portrayal: selectedPortrayal } = portrayalSelectVal
    const portrayedBy = selectedPortrayal.portrayedBy.length
        ? selectedPortrayal.portrayedBy.map(p => {
              return {
                  uri: p.id,
                  label: p.label || `${p.lastName}`,
                  mdmId: p.mdmID || EMPTY_FIELD_LABEL,
              }
          })
        : [NONE_OPTION]
    return {
        portrays: [
            ...selectedPortrayal.portrays.map(p => {
                return {
                    uri: p.id,
                    label: p.label || `${p.lastName}`,
                    // TODO:  Something that should be packaged on the level of array item. Ask backend
                    mdmId: p.mdmID || EMPTY_FIELD_LABEL,
                }
            }),
        ],
        portrayedBy: portrayedBy,
        role: selectedPortrayal.scope
            ? { roleId: selectedPortrayal.scope, roleLabel: selectedPortrayal.scopeName }
            : null,
        portrayal: {
            portrayalId: selectedPortrayal.genomeId,
            portrayalName: selectedPortrayal.portrayalLabel,
            portrayalType: {
                uri: selectedPortrayal.portrayalType,
                label: selectedPortrayal.portrayalTypeLabel,
            },
        },
    }
}

export const buildFormStateForNewPortrayal = (): PortrayalFormInProgressState => {
    return {
        portrays: [null],
        portrayedBy: [null],
        role: null,
        portrayal: {
            portrayalId: undefined,
            portrayalType: null,
        },
    }
}

export const buildCastMemberFromFormState = (
    formState: PortrayalFormInProgressState,
    attributes: { [key: string]: any } | undefined = undefined,
): CastMember => {
    const charOrNull = formState.portrays[0] || null // Should be exact one item

    let character: Character = {
        characterId: '',
        characterName: '',
        mdmId: '',
    }

    if (charOrNull) {
        character.characterId = charOrNull.uri
        character.characterName = charOrNull.label
        character.mdmId = charOrNull.mdmId
    }

    const talentOrNull = formState.portrayedBy[0] // can be 2 items for twins

    let talent: Talent = {
        talentId: '',
        talentName: '',
    }

    if (talentOrNull) {
        // since there are possible 2 values for twins, we shouldn't rely on this id
        // we should load existing data by portrayalUri
        talent.talentId = talentOrNull.uri
        talent.talentName = talentOrNull.label
    }

    const result: CastMember = {
        role: formState.role || null,
        portrayal: formState.portrayal as Portrayal,
        character: character,
        talent: talent,
        __meta__: {
            portrayedBy: formState.portrayedBy.map(p => p?.label || '').join('/'),
        },
    }

    if (!!attributes) {
        result.attributes = attributes
    }

    return result
}

export const buildMergedPortrayalsData = (characters: CastMember[]): CastMember[] => {
    const result: CastMember[] = []
    const portrayalIdsMap: { [key: string]: boolean } = {}
    characters.forEach((c, idx) => {
        if (c.portrayal.portrayalType?.uri === PortrayalType.ByTwins) {
            if (portrayalIdsMap[c.portrayal.portrayalId]) {
                return
            }
            // It is expected to have twins portrayals with matching characterIds and portrayalIds
            portrayalIdsMap[c.portrayal.portrayalId] = true

            const twinFound = characters.find((ch, i) => {
                return ch.portrayal.portrayalId === c.portrayal.portrayalId && i !== idx
            })

            portrayalIdsMap[c.portrayal.portrayalId] = true

            if (twinFound && !!c.talent) {
                // It is not expected to have empty talent or twin
                const portrayedByJoined = `${c.talent.talentName}/${twinFound.talent?.talentName}`
                result.push({
                    ...c,
                    __meta__: {
                        portrayedBy: portrayedByJoined,
                    },
                })
                result.push({
                    ...twinFound,
                    __meta__: {
                        portrayedBy: portrayedByJoined,
                    },
                })
            } else {
                //? Do we need that broken data?
                result.push({
                    ...c,
                    __meta__: {
                        portrayedBy: c.talent?.talentName || '',
                    },
                })
                console.error('Missing pair for:', c)
            }
        } else {
            result.push({ ...c, __meta__: { portrayedBy: c.talent?.talentName || '' } })
        }
    })

    return result
}

export const buildFilteredMergedPortrayals = (cms: CastMember[]): CastMember[] => {
    const rowsMap: { [portrayalId: string]: boolean } = {}
    return cms.filter(r => {
        const { portrayal } = r
        if (portrayal.portrayalType?.uri === PortrayalType.ByTwins) {
            if (rowsMap[portrayal.portrayalId]) {
                return false
            } else {
                rowsMap[portrayal.portrayalId] = true
                return true
            }
        }

        return true
    })
}

export const formatPortrayalsFoundOptions = (rowOpts: PortrayalFound[]): FormattedPortrayal[] => {
    return rowOpts.map(rowOpt => {
        return {
            label: rowOpt.portrayalLabel,
            portrayalId: rowOpt.genomeId,
            portrayal: rowOpt,
        }
    })
}
