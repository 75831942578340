import React, { MouseEventHandler, useState } from 'react'
import { Formik } from 'formik'
import Container from 'shared/components/Container'
import { useInterpreter, useSelector } from './CreateAssignmentProvider'
import { useTaskOptionsForSelectedTitles } from 'model/workflow/TaskType'
import { createAssignmentConfigurationSchema } from 'model/workflow/AssignmentConfiguration'
import CreateAssignmentsStepper from './CreateAssignmentsStepper'
import Button from 'shared/components/Button'
import { useIsXStateTransitionAvailable } from 'xstate-helpers/react/useIsXStateTransitionAvailable'
import SelectedTitlesTable from './AssignmentsConfiguration/SelectedTitlesTable'
import GlobalConfigurationFormNode from './AssignmentsConfiguration/GlobalConfigurationFormNode'
import { intersection } from 'lodash'
import { AlertDialog, AlertDialogButtons, AlertDialogLabel } from 'shared/components/AlertModal'
import TextualButton from 'shared/components/TextualButton'
import WorkflowTaskName from 'shared/components/Workflow/WorkflowTaskName'
import { AlertDialogDescription } from '@reach/alert-dialog'
import { Selection } from './createAssignment.machine'
import NoWrap from 'shared/components/NoWrap'
import styled from 'shared/theme'

type TitleDuplicateTasks = { title: string; resourceId: string; duplicates: string[] }

type TitleDuplicatesByConfig = {
    globalDuplicates: TitleDuplicateTasks[]
    selectedDuplicates: TitleDuplicateTasks[]
    totalDuplicates: number
}

const AssignmentsConfigurationNode: React.FC = () => {
    const service = useInterpreter()
    const selection: Selection[] = useSelector(
        React.useCallback(state => state.context.selection, []),
    )

    const selectedTitles = selection.map(s => s.title)

    const taskTypeOptions = useTaskOptionsForSelectedTitles(selectedTitles)

    const globalConfiguration = useSelector(
        React.useCallback(state => state.context.globalConfiguration, []),
    )

    const backButtonAvailable = useIsXStateTransitionAvailable(service, 'RETURN_TO_SELECTION')
    const forwardButtonAvailable = useIsXStateTransitionAvailable(service, 'START_CREATION')

    const [showAlreadyPublishedTitleWarning, setShowAlreadyPublishedTitleWarning] =
        useState<boolean>()
    const [showDuplicateWarning, setShowDuplicateWarning] = useState<boolean>()

    const [duplicatesByConfig, setDuplicatesByConfig] = useState<TitleDuplicatesByConfig>()

    const getDuplicateWorkflowTasks = React.useCallback((): TitleDuplicatesByConfig => {
        const globalDuplicates: TitleDuplicateTasks[] = []
        const selectedDuplicates: TitleDuplicateTasks[] = []
        let totalDuplicates = 0

        selection.forEach(s => {
            const selectedTitleTasks: string[] = s.title.workflows
                ? s.title.workflows.map(w => w.workflowConfig.task)
                : []
            const globalConfigDups = intersection(selectedTitleTasks, globalConfiguration.tasks)
            const selectedConfigDups =
                s.editingConfiguration &&
                intersection(selectedTitleTasks, s.editingConfiguration.tasks)

            const titleParams = {
                title: s.title.resourceTitle,
                resourceId: s.title.resourceId,
            }

            if (globalConfigDups.length > 0) {
                globalDuplicates.push({ ...titleParams, duplicates: globalConfigDups })
                totalDuplicates += globalConfigDups.length
            }
            if (selectedConfigDups && selectedConfigDups.length > 0) {
                selectedDuplicates.push({ ...titleParams, duplicates: selectedConfigDups })
                totalDuplicates += selectedConfigDups?.length ?? 0
            }
        })

        return { globalDuplicates, selectedDuplicates, totalDuplicates }
    }, [globalConfiguration, selection])

    const totalTasks = React.useMemo(() => {
        if (!selection) return 0

        return selection.reduce(
            (total: number, curr) => (curr.mergedConfiguration.tasks?.length ?? 0) + total,
            0,
        )
    }, [selection])

    const alreadyPublishedTitles = React.useMemo((): Selection[] => {
        return selection.filter(
            ({ title: { hasDescriptors, hasStorylines, hasRelationships } }) => {
                return [hasDescriptors, hasStorylines, hasRelationships].some(h => h === true)
            },
        )
    }, [selection])

    const handleSubmit = React.useCallback(
        () => {
            if (checkDuplicates()) return
            if (checkAlreadyPublished()) return

            service.send({ type: 'START_CREATION' })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selection],
    )

    const checkDuplicates = (ignoreDuplicates = false) => {
        if (ignoreDuplicates) {
            service.send({ type: 'START_CREATION' })
            return
        }

        const duplicatesByConfig = getDuplicateWorkflowTasks()
        setDuplicatesByConfig(duplicatesByConfig)
        const showDuplicateWarning =
            duplicatesByConfig.globalDuplicates.length > 0 ||
            duplicatesByConfig.selectedDuplicates.length > 0

        if (showDuplicateWarning) {
            setShowDuplicateWarning(showDuplicateWarning)
            return true
        } else return false
    }

    const checkAlreadyPublished = (ignoreAleadyCreated = false) => {
        if (ignoreAleadyCreated) {
            service.send({ type: 'START_CREATION' })
            return
        }

        const showAlreadyPublishedTitleWarning = alreadyPublishedTitles.length > 0
        if (showAlreadyPublishedTitleWarning) {
            setShowAlreadyPublishedTitleWarning(showAlreadyPublishedTitleWarning)
            return true
        } else return false
    }

    return (
        <Formik
            enableReinitialize
            initialValues={globalConfiguration}
            onSubmit={handleSubmit}
            validationSchema={createAssignmentConfigurationSchema(selectedTitles)}
        >
            {({ submitForm }) => (
                <>
                    <CreateAssignmentsStepper activeStep={2}>
                        <Container mt={2}>
                            <Container flex="1">
                                <Button
                                    id="assignments-configuration-back"
                                    type="button"
                                    size="large"
                                    onClick={() => service.send({ type: 'RETURN_TO_SELECTION' })}
                                    disabled={!backButtonAvailable}
                                >
                                    Back
                                </Button>
                            </Container>

                            <Container>
                                <Button
                                    id="assignments-configuration-create"
                                    type="button"
                                    size="large"
                                    onClick={submitForm}
                                    disabled={!forwardButtonAvailable}
                                >
                                    Create
                                </Button>
                            </Container>
                        </Container>
                    </CreateAssignmentsStepper>

                    <GlobalConfigurationFormNode taskTypeOptions={taskTypeOptions} />

                    <Container flexDirection="column" style={{ margin: '40px' }}>
                        <SelectedTitlesTable {...{ selection }} />
                    </Container>

                    {showAlreadyPublishedTitleWarning && (
                        <AlreadyPublishedTitlesModal
                            closeModal={() => setShowAlreadyPublishedTitleWarning(false)}
                            selection={alreadyPublishedTitles}
                            confirmModal={() => checkAlreadyPublished(true)}
                        />
                    )}

                    {showDuplicateWarning && (
                        <DuplicatesWarningModal
                            closeModal={() => setShowDuplicateWarning(false)}
                            titleDuplicatesByConfig={duplicatesByConfig}
                            confirmModal={() => checkDuplicates(true)}
                            totalTasks={totalTasks}
                        />
                    )}
                </>
            )}
        </Formik>
    )
}

// Already published modal components
const AlreadyPublishedTitlesModalList: React.FC<{
    selection: Selection[]
}> = ({ selection }) => {
    if (!selection.length) return null
    return (
        <div id={`title-published-list`}>
            <ul>
                {selection.map(
                    ({
                        title: {
                            resourceId,
                            resourceTitle,
                            hasDescriptors,
                            hasStorylines,
                            hasRelationships,
                        },
                    }) => {
                        const dataPublishedLabels = [
                            { label: 'Descriptors', value: hasDescriptors },
                            { label: 'Storylines', value: hasStorylines },
                            { label: 'relationships', value: hasRelationships },
                        ]
                            .filter(l => !!l.value)
                            .map(l => l.label)

                        const dataPublishedSummary =
                            dataPublishedLabels.slice(0, -1).join(', ') +
                            (dataPublishedLabels.length > 1 ? ' and ' : '') +
                            dataPublishedLabels.slice(-1)

                        return (
                            <li key={`title-duplicate-global-${resourceId}`}>
                                <NoWrap>
                                    {resourceTitle} have already published{' '}
                                    <em>{dataPublishedSummary}</em>
                                </NoWrap>
                            </li>
                        )
                    },
                )}
            </ul>
        </div>
    )
}

const AlreadyPublishedTitlesModal: React.FC<{
    selection: Selection[]
    closeModal: MouseEventHandler<HTMLButtonElement>
    confirmModal: MouseEventHandler<HTMLButtonElement>
}> = ({ selection, closeModal, confirmModal }) => {
    const cancelRef = React.createRef<HTMLButtonElement>()

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <AlertDialogLabel>Titles with published data</AlertDialogLabel>

            <AlertDialogDescription>
                <p>The following titles have published data already</p>
                <AlreadyPublishedTitlesModalList selection={selection} />
            </AlertDialogDescription>

            <AlertDialogButtons>
                <Button variant="primary" type="submit" onClick={confirmModal}>
                    Continue anyway
                </Button>
                <TextualButton type="button" onClick={closeModal} ref={cancelRef}>
                    Cancel
                </TextualButton>
            </AlertDialogButtons>
        </AlertDialog>
    )
}

const ListWrapper = styled.div`
    text-align: left;
`

// Duplicate tasks modal components
const DuplicateWarningModalList: React.FC<{
    type: 'global' | 'selected title'
    tasks: TitleDuplicateTasks[]
}> = ({ type, tasks }) => {
    if (!tasks.length) return null
    return (
        <div id={`title-duplicate-${type}-list`}>
            <ul>
                {tasks.map(({ title, resourceId, duplicates }) => (
                    <li key={`title-duplicate-global-${resourceId}`}>
                        <ListWrapper>
                            <span>{title}</span> -
                            {duplicates.map(d => (
                                <span key={`title-duplicate-global-${resourceId}-${d}`}>
                                    {' '}
                                    <b>
                                        <WorkflowTaskName task={d} />
                                    </b>
                                    {` in ${type} configuration`}
                                </span>
                            ))}
                        </ListWrapper>
                    </li>
                ))}
            </ul>
        </div>
    )
}

const DuplicatesWarningModal: React.FC<{
    titleDuplicatesByConfig: TitleDuplicatesByConfig | undefined
    closeModal: MouseEventHandler<HTMLButtonElement>
    confirmModal: MouseEventHandler<HTMLButtonElement>
    totalTasks: number
}> = ({ titleDuplicatesByConfig, closeModal, confirmModal, totalTasks }) => {
    const cancelRef = React.createRef<HTMLButtonElement>()

    if (!titleDuplicatesByConfig) return null

    const { globalDuplicates, selectedDuplicates, totalDuplicates } = titleDuplicatesByConfig

    const showContinue = totalDuplicates < totalTasks

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <AlertDialogLabel>Duplicate tasks</AlertDialogLabel>

            <AlertDialogDescription>
                <p>The following tasks will not be created because they already exist:</p>
                <DuplicateWarningModalList tasks={globalDuplicates} type="global" />
                <DuplicateWarningModalList tasks={selectedDuplicates} type="selected title" />
            </AlertDialogDescription>

            <AlertDialogButtons>
                {showContinue && (
                    <Button variant="primary" type="button" onClick={confirmModal}>
                        Continue
                    </Button>
                )}
                <TextualButton type="button" onClick={closeModal} ref={cancelRef}>
                    Cancel
                </TextualButton>
            </AlertDialogButtons>
        </AlertDialog>
    )
}

export default AssignmentsConfigurationNode
