import {
    flatten,
    isArray,
    isEmpty,
    mapValues,
    isNil,
    pickBy,
    transform,
    isObject,
    keyBy,
    values,
    omitBy,
} from 'lodash'
import { SearchParams } from 'shared/search/useSearch'
import {
    SupportedFacet,
    SupportedFacetGroup,
    SupportedQueryParamFacet,
} from 'shared/search/useFacetOptions'
import { GroupedDateOptions } from 'model/search/SearchOptions'
import { CATEGORY_SEPARATOR, TYPE_SEPARATOR } from 'pages/Search/ExtendedBasicSearch'
import { zonedTimeToUtc } from 'date-fns-tz'

export const DESCRIPTORS_FACET_KEY = 'data_disney_com_storyContent'

const URL_REGEXP =
    // eslint-disable-next-line
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

type ESOperator = 'must' | 'must_not' | 'should' | 'term' | 'filter' | 'range'
type ESType = 'match' | 'match_phrase' | 'term' | 'range' | 'query_param_date'

type ESRegExpValue = {
    value: string
    flags: 'ALL'
    case_insensitive: boolean
    max_determinized_states?: number
    rewrite?: string
}
type ESTerm = { [type in ESType]?: { [x: string]: string | ESRegExpValue } }
type ESQueryFragment = { [operator in ESOperator]?: ESTerm[] | ESQueryFragment }

const ES_TYPES_BY_FACET: { [facet in SupportedFacet | SupportedFacetGroup]?: ESType } = {
    'data_disney_com_cpm.titleNameDisambiguated': 'match',
    'data_disney_com_cpm.productId': 'match',
    'www_w3_org_1999_02_22-rdf-syntax-ns.type': 'match_phrase',
    [DESCRIPTORS_FACET_KEY]: 'match_phrase',
    'data_disney_com_creativeWork.setIn': 'match_phrase',
    dates: 'range',
    descriptors: 'match_phrase',
    genres: 'match_phrase',
}

const SUPPORTED_HIGHLIGHT_FACETS: (SupportedFacet | SupportedFacetGroup)[] = [
    'data_disney_com_cpm.titleNameDisambiguated',
]

const ES_QUERY_PARAM_FACET: SupportedQueryParamFacet[] = ['dueDate']

export type QueryParams = { [x: string]: any }

export type ESQuery = {
    query: {
        bool: ESQueryFragment
    }
    sort: [{ _score: 'asc' | 'desc' }, { _id: 'asc' | 'desc' }]
    highlight: any
}

function cleanEmpty(obj: any) {
    function internalClean(el: any) {
        return transform(el, function (result, value, key) {
            let isCollection = isObject(value)
            let cleaned = isCollection ? internalClean(value) : value

            if (isCollection && isEmpty(cleaned)) return

            isArray(result) ? result.push(cleaned) : ((result as any)[key] = cleaned)
        })
    }

    // This is made to avoid handling serializable objects as regular objects during cleaning (i.e. Date)
    const parsedObj = JSON.parse(JSON.stringify(obj))

    return isObject(parsedObj) ? internalClean(parsedObj) : parsedObj
}

function isValidUrl(input: string): boolean {
    return URL_REGEXP.test(input)
}

function queryParamsToTerms(searchParams: Partial<SearchParams>): ESQueryFragment {
    const matchTerms = pickBy(
        searchParams,
        (_, key) => ES_TYPES_BY_FACET[key as SupportedFacet] === 'match',
    )
    const matchPhraseTerms = pickBy(
        searchParams,
        (_, key) => ES_TYPES_BY_FACET[key as SupportedFacet] === 'match_phrase',
    )

    // TODO: Wrap the following lines in a separate function
    const rangeTerms = pickBy(
        searchParams,
        (_, key) => ES_TYPES_BY_FACET[key as SupportedFacet] === 'range',
    )

    const flattenedTermValues = flatten(values(rangeTerms))
    const flattenedTermsByKey = keyBy(flattenedTermValues, 'key')
    const termValues = mapValues(flattenedTermsByKey, 'value')

    const genreTerms = searchParams?.['genres'] ?? []
    const descriptorsTerms = searchParams?.['descriptors'] ?? []
    const allDescriptors = [...genreTerms, ...descriptorsTerms]

    // Set subcategoru as part of
    // TODO: make this more generic
    if (!isEmpty(allDescriptors)) {
        matchPhraseTerms[DESCRIPTORS_FACET_KEY] = allDescriptors.forEach((t: string) => {
            const [id, type] = t.split(CATEGORY_SEPARATOR)
            const [, category] = type.split(TYPE_SEPARATOR)
            matchPhraseTerms[`${DESCRIPTORS_FACET_KEY}.${category}`] = id
        })
        delete matchPhraseTerms[DESCRIPTORS_FACET_KEY]
    }
    delete matchPhraseTerms?.['genres']
    delete matchPhraseTerms?.['descriptors']

    const descriptorRegexp = new RegExp(`^${DESCRIPTORS_FACET_KEY}`, 'gi')

    const secondaryMustQueries = flatten(
        Object.keys(matchPhraseTerms)
            .map(key => ({ key, value: matchPhraseTerms[key] }))
            .map(({ key, value }) => {
                if (value === undefined) return []
                const values = isArray(value) ? value : [value]
                // If resource id or locations url is not valid and is descriptor, interpret as empty
                const isEmptyDescriptor = descriptorRegexp.test(key) && !values.every(isValidUrl)

                return isEmptyDescriptor
                    ? { bool: { must_not: values.map(v => ({ exists: { field: key } })) } }
                    : { bool: { should: values.map(v => ({ match_phrase: { [key]: v } })) } }
            }) as any,
    )

    // Filter out query params related dates and map it as range query
    const rangeFilters = Object.keys(termValues)
        .filter((k: any) => !ES_QUERY_PARAM_FACET.includes(k))
        .map(key => ({
            range: {
                [key]: {
                    gte: termValues[key]?.['from']
                        ? zonedTimeToUtc(new Date(termValues[key]?.['from']), 'UTC')
                        : undefined,
                    lt: termValues[key]?.['to']
                        ? zonedTimeToUtc(new Date(termValues[key]?.['to']), 'UTC')
                        : undefined,
                },
            },
        }))

    const secondaryMust = secondaryMustQueries.length
        ? [
              {
                  bool: {
                      must: secondaryMustQueries,
                  },
              },
          ]
        : []

    const filterQueries = !isEmpty(rangeFilters)
        ? {
              filter: {
                  bool: {
                      should: rangeFilters,
                  },
              },
          }
        : {}

    return cleanEmpty({
        must: [
            {
                bool: {
                    should: [
                        ...(Object.keys(matchTerms)
                            .map(key => ({ key, value: matchTerms[key] }))
                            .filter(({ value }) => !(isNil(value) || isEmpty(value)))
                            .map(({ key, value }) => ({ match: { [key]: value } })) as any),
                    ],
                },
            },
            // This line forces only titles content to be fetched
            { exists: { field: 'data_disney_com_cpm.productId' } },
            ...secondaryMust,
        ],
        ...(filterQueries as any),
    })
}

function queryParamsToHighlightFields(searchParams: Partial<SearchParams>): any {
    const highligthedSearchParams = pickBy(searchParams, (_, key) =>
        SUPPORTED_HIGHLIGHT_FACETS.includes(key as any),
    )
    return mapValues(highligthedSearchParams, () => ({ force_source: true }))
}

function getFacetsQueryParams(searchParams: Partial<SearchParams> | undefined): QueryParams {
    if (!searchParams) return {}
    const queryParamsFacets: GroupedDateOptions[] = searchParams['dates'] ?? []
    const queryParamsByFacet = queryParamsFacets
        .filter(({ key }) => ES_QUERY_PARAM_FACET.includes(key as SupportedQueryParamFacet))
        .map(({ key, value }: any, k: number) => {
            return {
                [`${key}From`]: value.from,
                [`${key}To`]: value.to,
            }
        })

    return { ...queryParamsByFacet.pop() }
}

/**
 * Function that gets state saved enabled query string from SearchParams.
 * @param searchParams
 * @returns
 */
export function queryParamsToStateTerms(searchParams: SearchParams | undefined): string {
    if (!searchParams) return ''
    const paramsWithoutRange = omitBy(
        searchParams,
        (_, key) =>
            (ES_TYPES_BY_FACET as any)[key as SupportedFacet] ===
            'data_disney_com_cpm.earliestReleaseDate',
    ) as SearchParams
    return JSON.stringify(paramsWithoutRange)
}

export function queryParamsToESQuery(
    searchParams: Partial<SearchParams> | undefined,
    isWorkflowSearch = false,
): [ESQuery | null, QueryParams | null] {
    if (!searchParams) return [null, null]
    const esQuery: ESQuery = {
        query: {
            bool: queryParamsToTerms(searchParams),
        },
        sort: [{ _score: 'desc' }, { _id: 'asc' }],
        highlight: {
            fields: queryParamsToHighlightFields(searchParams),
        },
    }
    const queryParams = isWorkflowSearch ? getFacetsQueryParams(searchParams) : {}

    return [esQuery, queryParams]
}
