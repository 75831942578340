import * as t from 'io-ts'

import { request } from '@genome-web-forms/common/api'
import { MyIDUser } from '@genome-web-forms/common/auth'

import { authGWF } from 'api/auth'

import {
    CharacterCommentCodec,
    CharacterComment,
    CharacterCommentsCountsCodec,
    CharactersCommentsCounts,
} from 'model/characters/CharacterComment'

import config from 'shared/config'

export const fetchAllCommentsCounts = (
    ids: string[],
    user: MyIDUser,
): Promise<CharactersCommentsCounts> => {
    return request(
        CharacterCommentsCountsCodec,
        authGWF(user, {
            url: `${config.urlGWF}/notes/character/metadata`,
            method: 'POST',
            data: { characterIds: ids },
        }),
    )
}

export const fetchCommentsForCharacter = (
    portrayalId: string,
    user: MyIDUser,
): Promise<{ notes: CharacterComment[] }> => {
    return request(
        t.type({
            notes: t.array(CharacterCommentCodec),
        }),
        authGWF(user, {
            url: `${config.urlGWF}/notes/character/${encodeURIComponent(portrayalId)}`,
            method: 'GET',
        }),
    )
}

export const createComment = (
    content: string,
    portrayalId: string,
    user: MyIDUser,
): Promise<CharacterComment> => {
    return request(
        CharacterCommentCodec,
        authGWF(user, {
            url: `${config.urlGWF}/notes/character/${encodeURIComponent(portrayalId)}`,
            method: 'POST',
            data: { content },
        }),
    )
}

export const updateComment = (
    content: string,
    portrayalId: string,
    commentId: string,
    user: MyIDUser,
): Promise<CharacterComment> => {
    return request(
        CharacterCommentCodec,
        authGWF(user, {
            url: `${config.urlGWF}/notes/character/${encodeURIComponent(portrayalId)}/${commentId}`,
            method: 'PUT',
            data: { content },
        }),
    )
}

export const deleteComment = (
    portrayalId: string,
    commentId: string,
    user: MyIDUser,
): Promise<unknown> => {
    return request(
        t.unknown,
        authGWF(user, {
            url: `${config.urlGWF}/notes/character/${encodeURIComponent(portrayalId)}/${commentId}`,
            method: 'DELETE',
        }),
    )
}
