import React, { memo, useMemo } from 'react'
import styled from 'shared/theme'
import { uniqueId, get } from 'lodash'
import { useField, useFormikContext } from 'formik'

import { AttributeValue, Attribute } from 'codecs/Attribute'
import { AttributeMeta } from 'model/metadata/RecordedAttributes'

import { usePureFn } from 'shared/hooks/usePureFn'
import { safeFormikName } from 'shared/util/formikSafe'
import { FormikTRSSelect, ImplementerFormikTRSSelectProps } from 'shared/components/TRS/TRSSelect'
import UnavailableText from 'shared/components/UnavailableText'
import { useTRSSimpleFind } from 'shared/hooks/useTRS'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import { Pill } from 'shared/components/Table/TablePills'

const ComboAttrsGrid = styled.div<{ isEditing: boolean }>`
    display: grid;
    grid-template-columns: ${props => (props.isEditing ? '1fr 1fr 26px' : '1fr 1fr')};
    column-gap: 0.5rem;
    align-items: flex-start;
    margin-bottom: 0.5rem;
`

export const RemoveAttrGroupBtn = styled.button.attrs({
    type: 'button',
})`
    width: 26px;
    height: 26px;
    margin-top: 2rem;
    border: 0;
    background: transparent;
    color: ${props => props.theme.colors.textPrimary};
    cursor: pointer;
`

export const CustomErrorMessage = styled.span`
    display: block;
    color: ${props => props.theme.colors.danger};
    margin-top: 0.75rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
`

interface ICharAttr {
    attributeId: string
    attributeLabel: string
}

interface IAttrGroup {
    primary?: ICharAttr
    secondary?: ICharAttr
}

function createAttrGroupRow(): IAttrGroup {
    return {}
}

type CharAttrSelectProps = {
    attributeMeta: AttributeMeta
    formControlsEnabled: boolean
    isMulti: boolean
    name: string // Formik namespace
} & ImplementerFormikTRSSelectProps<AttributeValue>

const CharAttrSelect: React.FC<CharAttrSelectProps> = (props): React.ReactElement => {
    const { attributeMeta, formControlsEnabled, isMulti, name } = props
    const { type: attrId, placeholder } = attributeMeta
    const { terms } = useTRSSimpleFind(attrId, false)
    const [{ value }] = useField<Attribute>(props.name)
    const { errors } = useFormikContext()

    const { hasError, error } = useMemo(() => {
        const error = get(errors, props.name)
        return { hasError: !!error, error: error || null }
    }, [errors, props.name])

    const getOptionLabel = usePureFn((option, term) => (term ? term.label : option.attributeLabel))
    const getOptionValue = usePureFn(option => option.attributeId)
    const termToOption = usePureFn(term => ({
        attributeId: term.id,
        attributeLabel: term.label,
        broaderId: term.broaderId,
    }))

    if (!formControlsEnabled && !value) {
        return <UnavailableText>Unavailable</UnavailableText>
    }

    if (!formControlsEnabled) {
        return (
            <Pill
                style={{
                    marginTop: '8px',
                }}
                title={value.attributeLabel}
            >
                {value.attributeLabel}
            </Pill>
        )
    }

    return (
        <>
            <FormikTRSSelect<AttributeValue>
                name={name}
                isMulti={isMulti}
                terms={terms}
                termToOption={termToOption}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                isDisabled={!formControlsEnabled}
                placeholder={placeholder}
                menuPlacement={'auto'}
                isClearable={false}
            />
            {hasError && <CustomErrorMessage>{error}</CustomErrorMessage>}
        </>
    )
}

type CompoundAttributeProps = {
    compoundAttrsMetadata: {
        PRIMARY: AttributeMeta
        SECONDARY: AttributeMeta
    }
    attributes: { [x: string]: IAttrGroup[] } | undefined
    formControlsEnabled: boolean
    isMulti: boolean
    push: (item: IAttrGroup) => void
    remove: (index: number) => void
}

type TComboAttrType = 'primary' | 'secondary'

const getSafeNameSpaceForComboAttr = (parentId: string, type: TComboAttrType, idx: number) => {
    return `attributes.${safeFormikName(parentId)}[${String(idx)}].${type}`
}

export const CompoundAttribute: React.FC<CompoundAttributeProps> = ({
    compoundAttrsMetadata,
    attributes,
    formControlsEnabled,
    isMulti,
    push,
    remove,
}): React.ReactElement | null => {
    const { PRIMARY, SECONDARY } = compoundAttrsMetadata
    const { type: attrId, label: primaryLabel } = PRIMARY
    const { label: secondaryLabel } = SECONDARY

    const attrs = attributes || {}
    const safeId = safeFormikName(attrId)
    const attrGroups = attrs[safeId] ? attrs[safeId] : []

    return (
        <>
            <div>
                {attrGroups.map((_, idx) => (
                    <div key={uniqueId()}>
                        <ComboAttrsGrid isEditing={formControlsEnabled}>
                            <div>
                                <Text size="5" weight="bold" as="h5">
                                    {primaryLabel}
                                </Text>
                                <CharAttrSelect
                                    attributeMeta={PRIMARY}
                                    formControlsEnabled={formControlsEnabled}
                                    name={getSafeNameSpaceForComboAttr(attrId, 'primary', idx)}
                                    isMulti={isMulti}
                                />
                            </div>
                            <div>
                                <Text size="5" weight="bold" as="h5">
                                    {secondaryLabel}
                                </Text>
                                <CharAttrSelect
                                    attributeMeta={SECONDARY}
                                    formControlsEnabled={formControlsEnabled}
                                    name={getSafeNameSpaceForComboAttr(attrId, 'secondary', idx)}
                                    isMulti={isMulti}
                                />
                            </div>
                            {formControlsEnabled && (
                                <RemoveAttrGroupBtn
                                    onClick={() => {
                                        remove(idx)
                                    }}
                                >
                                    &#10005;
                                </RemoveAttrGroupBtn>
                            )}
                        </ComboAttrsGrid>
                    </div>
                ))}
                {formControlsEnabled && (
                    <div style={{ paddingLeft: '2rem' }}>
                        <Button
                            onClick={() => {
                                push(createAttrGroupRow())
                            }}
                            variant="outline"
                            size="small"
                        >
                            Add {primaryLabel}
                        </Button>
                    </div>
                )}
            </div>
        </>
    )
}

export const MemoizedCompoundAttribute = memo(CompoundAttribute)
