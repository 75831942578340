import { CastMember } from 'codecs/CastMember'
import * as s from 'shared/diff/schema'
import { DiffableCharacter } from 'model/characters/Characters'
import { PortrayalFound } from 'model/characters/Portrayal'

export enum SearchType {
    Character = 'character',
    Talent = 'talent',
}

export type FormattedPortrayal = {
    label: string
    portrayalId: string
    portrayal: PortrayalFound
}

export type ResouceData = { characters: CastMember[] }

export type ArrField = { uri: string; label: string; mdmId?: string | undefined }

export type PortrayalFormInProgressState = {
    role?: {
        roleId: string
        roleLabel: string
    } | null
    portrayal: {
        portrayalId?: string
        portrayalName?: string
        portrayalType?: {
            uri: string
            label: string
        } | null
    }
    portrays: (ArrField | null)[]
    portrayedBy: (ArrField | null)[]
}

export type CharactersDiffOps = s.ArrayDiffOps<DiffableCharacter>
