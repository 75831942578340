import React, { useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { ValueType } from 'react-select'

import { MemoSelect } from 'shared/components/Select'
import ErrorMessage from 'shared/components/ErrorMessage'
import { PortrayalType, usePortrayalTypes } from './usePortrayalTypes'
import { usePortrayalsContext } from './portrayalRowsContext'
import FormikSelect from '../Select/FormikSelect'
import { PortrayalType as PortrayalTypeEnum } from './constants'
import { PortrayalFormInProgressState } from './types'

type PortrayalTypeSelectProps = {
    disabled: boolean
}

const getOptionValue = (opt: PortrayalType) => {
    return opt.uri
}

const getOptionLabel = (opt: PortrayalType) => {
    return opt.label
}

export const PortrayalTypeSelect: React.FC<PortrayalTypeSelectProps> = ({ disabled }) => {
    const { portrayalInEdit, setCurrentPortrayal } = usePortrayalsContext()

    const handleOnChange = useCallback(
        (opt: ValueType<PortrayalType>) => {
            // TODO: Make the same logic to reset input values
            const selectedOpt = opt as PortrayalType | null

            !!portrayalInEdit &&
                setCurrentPortrayal({
                    ...portrayalInEdit,
                    portrayal: {
                        ...portrayalInEdit.portrayal,
                        portrayalType: selectedOpt,
                    },
                })
        },
        [portrayalInEdit, setCurrentPortrayal],
    )

    const value = useMemo(() => {
        const portrayalType = portrayalInEdit?.portrayal?.portrayalType || null
        return portrayalType
    }, [portrayalInEdit])

    return <PortrayalTypeSelectCommon onChange={handleOnChange} value={value} disabled={disabled} />
}

export const FormikPortrayalTypeSelect: React.FC<{
    disabled: boolean
}> = ({ disabled }) => {
    const name = 'portrayal.portrayalType'
    const { values, resetForm } = useFormikContext<PortrayalFormInProgressState>()
    const { data, isFetching } = usePortrayalTypes()

    const options = useMemo(() => {
        return data || []
    }, [data])

    const handleChange = useCallback(
        (value: ValueType<PortrayalType>) => {
            // TODO: filter missing fields or reset them to null for portrays and portrayedBy
            const val = value as PortrayalType | null
            // Based on type
            let portrays = [null]
            let portrayedBy = [null]

            if (val && val.uri === PortrayalTypeEnum.ByTwins) {
                portrayedBy = [null, null]
            }

            resetForm({
                values: {
                    ...values,
                    portrayedBy: portrayedBy,
                    portrays: portrays,
                    portrayal: {
                        ...values.portrayal,
                        portrayalType: val,
                    },
                },
            })

            // onFormReinitialize({
            //     ...values,
            //     portrayedBy: portrayedBy,
            //     portrays: portrays,
            //     portrayal: {
            //         ...values.portrayal,
            //         portrayalType: val,
            //     },
            // })
        },
        [values, resetForm],
    )

    return (
        <>
            <FormikSelect
                name={name}
                isDisabled={disabled}
                isLoading={isFetching}
                options={options}
                getOptionValue={getOptionValue}
                onChange={handleChange}
                isClearable={false}
            />

            <ErrorMessage name={name} />
        </>
    )
}

export const PortrayalTypeSelectCommon: React.FC<{
    onChange: (portrayal: ValueType<PortrayalType>) => void
    value: {
        uri: string
        label: string
    } | null
    disabled: boolean
}> = ({ onChange, value, disabled }) => {
    const { data, isFetching } = usePortrayalTypes()

    const options = useMemo(() => {
        return data || []
    }, [data])

    return (
        <MemoSelect<PortrayalType>
            hideSelectedOptions={false}
            options={options}
            onChange={onChange}
            value={value}
            isLoading={isFetching}
            isMulti={false}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            isDisabled={disabled}
            isClearable={false}
        />
    )
}
