import React from 'react'
import dateFormat from 'date-fns/format'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import CellLabel from 'shared/components/CellLabel'
import FormikSelect from 'shared/components/Select/FormikSelect'
import DatePicker from 'shared/components/DatePicker'
import { WorkflowPriorityOptions } from 'model/workflow/WorkflowPriority'
import { AssignmentConfiguration } from 'model/workflow/AssignmentConfiguration'
import ErrorMessage from 'shared/components/ErrorMessage'
import { useSourceOptionsForSelectedTitles } from 'model/workflow/TaskType'

export type WorkflowUpdates = Partial<
    Pick<AssignmentConfiguration, 'priority' | 'deadlineAt' | 'reviewRequired' | 'source'>
>

const ReviewRequiredOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
]

export const GridLayout = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1.5fr 1fr;
    grid-column-gap: 0.5rem;
    padding: 1rem;
`

const FormWrapper = styled.div<{ hasErrors?: boolean }>`
    background-color: ${props =>
        props.hasErrors ? props.theme.colors.backgroundError : props.theme.colors.activeField};
    position: relative;
    box-sizing: border-box;
    width: 100%;
`

const EditWorkflowForm: React.FC<any> = () => {
    const { isLoading: isSourceTypeOptionsLoading, data: sourceTypeOptions = [] } =
        useSourceOptionsForSelectedTitles()

    const { values, setFieldValue } = useFormikContext<WorkflowUpdates>()

    return (
        <FormWrapper>
            <GridLayout>
                <div>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Priority
                    </CellLabel>
                    <FormikSelect
                        name="priority"
                        isClearable={true}
                        options={WorkflowPriorityOptions}
                        value={WorkflowPriorityOptions.find(po => po.value === values.priority)}
                        onChange={
                            ((value: { value: string } | null) =>
                                setFieldValue('priority', value ? value.value : null)) as any
                        }
                    />
                    <ErrorMessage name="priority" />
                </div>

                <div>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Due Date
                    </CellLabel>
                    <DatePicker
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select..."
                        selected={values.deadlineAt ? new Date(values.deadlineAt) : undefined}
                        onChange={date =>
                            setFieldValue(
                                'deadlineAt',
                                date ? dateFormat(date as Date, 'MMMM d, yyyy') : undefined,
                            )
                        }
                    />
                    <ErrorMessage name="deadlineAt" />
                </div>

                <div>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Source
                    </CellLabel>

                    <FormikSelect
                        name="source"
                        isClearable={true}
                        options={sourceTypeOptions}
                        placeholder="Select Source"
                        isLoading={isSourceTypeOptionsLoading}
                        value={sourceTypeOptions.find(so => so.value === values.source)}
                        onChange={
                            ((value: { value: string } | null) =>
                                setFieldValue('source', value ? value.value : null)) as any
                        }
                    />
                    <ErrorMessage name="source" />
                </div>

                <div>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Needs review{' '}
                    </CellLabel>
                    <FormikSelect
                        placeholder="No changes"
                        name="reviewRequired"
                        isClearable={true}
                        options={ReviewRequiredOptions}
                        value={ReviewRequiredOptions.find(po => po.value === values.reviewRequired)}
                        onChange={
                            ((value: { value: string } | null) =>
                                setFieldValue('reviewRequired', value ? value.value : null)) as any
                        }
                    />
                    <ErrorMessage name="priority" />
                    <ErrorMessage name="reviewRequired" />
                </div>
            </GridLayout>
        </FormWrapper>
    )
}

export default EditWorkflowForm
