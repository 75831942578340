import React, { useEffect, useState } from 'react'
import capitalize from 'lodash/capitalize'
import { getTaskLabel } from 'model/workflow/TaskType'
import { CellProps, Column } from 'react-table'
import Table, { RowComponentDefault } from 'shared/components/Table'
import Text from 'shared/components/Text'
import { Selection } from '../createAssignment.machine'
import { TableRow, TableCell } from 'shared/components/Table'
import { useInterpreter, useSelector } from '../CreateAssignmentProvider'
import AssignmentTableRowForm from './AssignmentTableRowForm'
import MediumDate from 'shared/components/MediumDate'
import { useFullNameMyIDUser } from 'api/workflow/partialMyIDUsers'

type ExpandableSelection = Selection & {
    expanded: boolean
}

type SelectedTitlesTableProps = {
    selection: Selection[]
}

const SelectedTitlesTable: React.FC<SelectedTitlesTableProps> = ({ selection }) => {
    const service = useInterpreter()

    const globalConfiguration = useSelector(
        React.useCallback(state => state.context.globalConfiguration, []),
    )

    const [expandableSelection, setExpandableSelection] = useState<ExpandableSelection[]>(() =>
        selection.map(s => ({ ...s, expanded: false })),
    )

    useEffect(() => {
        setExpandableSelection(
            selection.map((s, index) => ({ ...s, expanded: expandableSelection[index].expanded })),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection])

    const columns = React.useMemo<Column<ExpandableSelection>[]>(
        () => [
            {
                accessor: s => s.title.resourceTitle,
                Header: 'Title',
                width: 400,
            },
            {
                Header: 'Type',
                accessor: s => s.title.cwmClassTypeLabel,
                width: 1,
                Cell: ({ row: { original } }: CellProps<Selection>) => (
                    <span style={{ whiteSpace: 'nowrap' }}>{original.title.cwmClassTypeLabel}</span>
                ),
            },
            {
                id: 'tasks',
                Header: 'Tasks',
                width: 150,
                accessor: s => s.mergedConfiguration.tasks,
                Cell: ({ row: { original }, value: mergedConfigTasks }: CellProps<Selection>) => {
                    const editConfigTasks =
                        original.editingConfiguration && original.editingConfiguration?.tasks
                            ? original.editingConfiguration?.tasks
                            : []
                    const allConfigTasks = Array.from(
                        new Set(editConfigTasks.concat(mergedConfigTasks)).values(),
                    )

                    if (mergedConfigTasks.length === 0 && editConfigTasks.length === 0) {
                        return <Text variant="danger">No tasks selected</Text>
                    }

                    return (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {allConfigTasks.map(getTaskLabel).join(', ')}
                        </span>
                    )
                },
            },
            {
                id: 'assignee',
                Header: 'Assignee',
                width: 1,
                accessor: s => s.mergedConfiguration.assignee,
                Cell: ({ row: { original } }: CellProps<Selection>) => {
                    const createdByFullName = useFullNameMyIDUser(
                        original?.mergedConfiguration.assignee,
                    )
                    return <span style={{ whiteSpace: 'nowrap' }}>{createdByFullName}</span>
                },
            },
            {
                Header: 'Priority',
                width: 100,
                accessor: s => s.mergedConfiguration.priority,
                Cell: ({
                    row: { original },
                    value: mergedConfigPriority,
                }: CellProps<Selection>) => {
                    const editingConfigPriority =
                        original.editingConfiguration && original.editingConfiguration.priority
                    const priority = editingConfigPriority || mergedConfigPriority
                    return <>{capitalize(priority ?? '')}</>
                },
            },
            {
                id: 'deadlineAt',
                Header: 'Due Date',
                width: 1,
                accessor: s => s.mergedConfiguration.deadlineAt,
                Cell: ({
                    row: { original },
                    value: mergedConfigDeadlineAt,
                }: CellProps<Selection>) => {
                    const editingConfigDeadlineAt =
                        original.editingConfiguration && original.editingConfiguration.deadlineAt
                    const deadlineAt = editingConfigDeadlineAt || mergedConfigDeadlineAt

                    return (
                        <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                            {deadlineAt ? <MediumDate date={new Date(deadlineAt)} /> : null}
                        </Text>
                    )
                },
            },
            {
                id: 'source',
                Header: 'Source',
                accessor: s => s.mergedConfiguration.source,
                width: 1,
                Cell: ({
                    row: {
                        original: { editingConfiguration, mergedConfiguration },
                    },
                }: CellProps<Selection, string | undefined>) => {
                    if (editingConfiguration !== false && editingConfiguration.source)
                        return editingConfiguration.source
                    return mergedConfiguration.source ?? null
                },
            },
            {
                id: 'reviewRequired',
                Header: 'Needs Review',
                width: 1,
                accessor: s => s.mergedConfiguration.reviewRequired,
                Cell: ({
                    row: {
                        original: { editingConfiguration, mergedConfiguration },
                    },
                }: CellProps<Selection, string | undefined>) =>
                    mergedConfiguration.reviewRequired === true ||
                    (editingConfiguration !== false ? editingConfiguration.reviewRequired : false)
                        ? 'Yes'
                        : 'No',
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [expandableSelection],
    )

    const handleToggle = React.useCallback(
        (selectionIndex: number, expanded: boolean) => {
            const updatedSelection = [...expandableSelection]
            updatedSelection[selectionIndex].expanded = !expanded
            setExpandableSelection(updatedSelection)
        },
        [expandableSelection],
    )

    return (
        <>
            <Text as="h1">Selected Titles</Text>
            <Table<ExpandableSelection>
                {...{
                    columns,
                    scrollable: true,
                    data: expandableSelection,
                    onRowClick: ({ row, index }) => {
                        if (!row.original.editingConfiguration) {
                            service.send({
                                type: 'TITLE_CONFIGURATION_EDIT_START',
                                title: row.original.title,
                            })
                        }
                        handleToggle(index, row.original.expanded)
                    },
                    RowComponent: props => {
                        const selection = props.row.original

                        if (selection.expanded) {
                            return (
                                <React.Fragment key={props.row.getRowProps().key}>
                                    <RowComponentDefault {...props} />
                                    <TableRow key={'edit-' + props.row.getRowProps().key}>
                                        <TableCell
                                            {...{
                                                colSpan: props.allColumns.length,
                                                style: { padding: 0 },
                                                removeTopBorderRadius: true,
                                                alignWithPreviousRow: true,
                                            }}
                                        >
                                            <AssignmentTableRowForm
                                                {...{
                                                    title: selection.title,
                                                    editingConfiguration:
                                                        selection.editingConfiguration,
                                                    globalConfiguration,
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        }
                        return <RowComponentDefault {...props} />
                    },
                }}
            />
        </>
    )
}
export default SelectedTitlesTable
