import React from 'react'
import * as s from 'shared/diff/schema'
import { Link, LinkProps } from 'react-router-dom'
import styled, { css, theme } from 'shared/theme'
import { Pill } from 'shared/components/Table/TablePills'
import { rgba } from 'polished'
import Container from 'shared/components/Container'
import { ColumnInstance } from 'react-table'
import { keyframes } from 'styled-components'
import { useScrollToSelf } from 'shared/hooks/useScrollToSelf'

interface ScrollableTableProps {
    maxHeight?: string | undefined
}

export const ScrollableTable = styled.div<ScrollableTableProps>`
    overflow-y: auto;
    max-height: ${props => props.maxHeight};
    margin: 24px 0;

    & thead th {
        position: sticky;
        top: 0;
        z-index: 0;
    }

    & th {
        background: #f5f7fc;
    }
`

export const TableEl = styled.table`
    border-collapse: separate;
    border-spacing: 0 4px;
    width: auto !important;
    min-width: 100% !important;
    table-layout: fixed;
`
export const Tbody = styled.tbody``
export const Thead = styled.thead`
    white-space: nowrap;
`

const RowBase = styled.tr`
    display: table-row !important;
`
export const HeaderContentWrap = styled(Container)<{
    isSorted: boolean
    isSortedDesc: boolean | undefined
}>`
    color: ${props => (props.isSorted ? '#1f7cff' : '')};
    position: relative;
    align-items: center;

    & > div > span::after {
        border-top: ${props =>
            props.isSorted && props.isSortedDesc ? '0.3125rem solid #1f7cff' : ''};
    }

    & > div > span::before {
        border-bottom: ${props =>
            props.isSorted && !props.isSortedDesc ? '0.3125rem solid #1f7cff' : ''};
    }
`
const HIGHLIGHT_DURATION_MS = 2000
const keyFramesNewRowHighlight = keyframes`
  0% {
    background-color: ${theme.colors.white};
  }
  50% {
    background-color: ${theme.colors.activeField};
  }
  100% {
    background: ${theme.colors.white};
  }
`

type TableRowStyleProps = {
    isEditing?: boolean
    isDisabled?: boolean
    hasHover?: boolean
    highlight?: boolean
    clickable?: boolean
    hasErrors?: boolean
    diffType?: s.DiffOpType
}
const TableRowStyled = styled(RowBase)<TableRowStyleProps>`
    background-color: ${props =>
        props.hasErrors
            ? props.theme.colors.backgroundError
            : props.diffType === 'add'
            ? props.theme.colors.backgroundDiffAdd
            : props.diffType === 'remove'
            ? props.theme.colors.backgroundDiffRemove
            : props.isEditing
            ? props.theme.colors.activeField
            : props.isDisabled
            ? props.theme.colors.backgroundPrimary
            : props.theme.colors.white};

    ${props =>
        props.isDisabled &&
        `
        td { color: ${props.theme.colors.gray}; };
        button { background-color: ${props.theme.colors.border}; cursor: not-allowed}
    `}

    ${props =>
        props.isEditing && `box-shadow: 0px 4px 8px 0px ${rgba(props.theme.colors.border, 0.3)};`}

    ${props =>
        props.hasHover &&
        (props.hasErrors
            ? css`
                  &:hover {
                      background-color: ${props.theme.colors.backgroundErrorHighlight};
                  }
                  &:hover ${Pill} {
                      background-color: ${props.theme.colors.border};
                  }
              `
            : css`
                  &:hover {
                      background-color: ${props.theme.colors.activeField};
                  }
                  &:hover ${Pill} {
                      background-color: ${props.theme.colors.border};
                  }
              `)}
    ${props =>
        props.highlight &&
        css`
            animation-name: ${keyFramesNewRowHighlight};
            animation-duration: ${HIGHLIGHT_DURATION_MS}ms;
            animation-timing-function: ease;
        `}
    ${props =>
        props.clickable &&
        !props.isDisabled &&
        css`
            cursor: pointer;
        `}
`

export function TableRow(
    props: React.PropsWithoutRef<JSX.IntrinsicElements['tr']> &
        TableRowStyleProps & {
            scrollToSelf?: boolean
        },
): ReturnType<typeof TableRowStyled> {
    const { scrollToSelf, ...restProps } = props
    const ref = React.useRef<HTMLTableRowElement>(null)
    useScrollToSelf(ref, !!scrollToSelf)

    return <TableRowStyled {...restProps} ref={ref} />
}

export const HeaderRow = styled(RowBase)`
    background-color: transparent;
`

type TableCellProps = {
    removeBottomBorderRadius?: boolean
    removeTopBorderRadius?: boolean
    removePadding?: boolean
    alignWithPreviousRow?: boolean
    secondAlignWithPreviousRow?: boolean
}

export const TableCell = styled.td<TableCellProps>`
    display: table-cell !important;
    width: auto !important;
    flex: none !important;
    vertical-align: top;
    font-size: 0.9375rem;
    line-height: 2rem;
    border: 1px solid ${props => props.theme.colors.border};
    padding: ${props => (props.removePadding ? '0' : '0.5rem 1rem')};
    &:not(:last-child) {
        border-right: 0;
    }
    &:not(:first-child) {
        border-left: 0;
    }
    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    ${props =>
        props.alignWithPreviousRow &&
        // Accounts for:
        // border-spacing(4px) + cell border-width (1px)
        // beacuase of duplicating borders without +1px
        `top: -5px;
        position: relative;
        `}
    ${props =>
        props.secondAlignWithPreviousRow &&
        `top: -10px;
        position: relative;
        `}
    && {
        ${props =>
            props.removeBottomBorderRadius &&
            `border-bottom-right-radius: 0; border-bottom-left-radius: 0;`}
    }
    && {
        ${props =>
            props.removeTopBorderRadius && `border-top-right-radius: 0; border-top-left-radius: 0;`}
    }
`

export const TableLinkCell = styled(Link)<LinkProps>`
    display: block;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 2rem;
    color: ${props => props.theme.colors.textPrimary};
`

export const Header = styled.th<{ center?: boolean }>`
    color: ${props => props.theme.colors.textSecondary};
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 0 1rem 0.25rem;
    font-weight: 600;
    vertical-align: top;
    text-align: left;
    ${props => props.center && 'text-align: center'};
`
export const HeaderAlignComponent = styled.div`
    display: flex;
    align-items: center;
`

export const TableSortIndicator = styled.span<{
    column: ColumnInstance<any>
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    &:before,
    &:after {
        width: 0;
        height: 0;
        content: '';
        border-left: 0.25rem solid transparent;
        border-right: 0.25rem solid transparent;
    }
    &:before {
        margin-bottom: 0.125rem;
        border-bottom: 0.3125rem solid
            ${props =>
                props.column.isSorted && props.column.isSortedDesc
                    ? 'transparent'
                    : props.theme.colors.textSecondary};
    }
    &:after {
        border-top: 0.3125rem solid
            ${props =>
                props.column.isSorted && !props.column.isSortedDesc
                    ? 'transparent'
                    : props.theme.colors.textSecondary};
    }
`
