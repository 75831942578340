import * as t from 'io-ts'

export const TalentCodec = t.intersection([
    t.type({
        talentId: t.string,
        talentName: t.string,
    }),
    t.partial({
        scopeId: t.string,
        scope: t.string,
    }),
])
export type Talent = t.TypeOf<typeof TalentCodec>
