import React from 'react'
import { ReactComponent as CheckIcon } from 'shared/components/Icons/check-icon.svg'
import styled from 'shared/theme'
import { useButton } from '@react-aria/button'

export default CheckIcon

type CheckIconButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, 'children'>
const CheckconButtonElement = styled(CheckIcon)<CheckIconButtonProps>`
    cursor: pointer;
    color: ${props => props.theme.colors.textSecondary};
`

export function CheckIconButton(props: CheckIconButtonProps): JSX.Element {
    const ref = React.useRef<HTMLButtonElement>()
    const { buttonProps } = useButton({ elementType: 'svg' }, ref as any)
    return <CheckconButtonElement {...buttonProps} {...(props as any)} />
}
