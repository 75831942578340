import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString'

export const CharacterCommentCodec = t.type({
    noteId: t.string,
    characterId: t.string, // Used portrayalId
    authorId: t.string,
    authorName: t.string,
    content: t.string,
    createdAt: DateFromISOString,
    updatedAt: DateFromISOString,
    isActive: t.boolean,
})

export interface CharacterComment extends t.TypeOf<typeof CharacterCommentCodec> {}

export const CharacterCommentsCountsCodec = t.type({
    metadata: t.type({
        notesCount: t.record(t.string, t.number),
    }),
})

export interface CharactersCommentsCounts extends t.TypeOf<typeof CharacterCommentsCountsCodec> {}
