import * as t from 'io-ts'
import { BooleanFromString } from 'io-ts-types/lib/BooleanFromString'

import nullable from 'codecs/util/nullable'
import { DateFromXDS } from 'codecs/util/DateFromXDS'
import { fromNullable } from 'codecs/util/fromNullable'

import { MetadataCodec } from 'model/metadata/Metadata'
import { ConceptCodec } from 'codecs/Concept'

import { FeatureStorylineCodec } from 'codecs/storyline/FeatureStoryline'
import { RelationshipCodec } from 'codecs/Relationship'

import * as cwm from '@genome-web-forms/common/model/CWMClassType'
import { CastMemberCodec } from 'codecs/CastMember'

const FeatureCodecBase = t.type({
    featureId: t.string,
    featureTitle: t.string,
    cwmClassType: t.keyof(cwm.CWM_FEATURE_CLASSES),
    cwmClassTypeLabel: t.string,
    static: t.type({
        productionCompany: fromNullable(t.array(t.string), []),
        concepts: fromNullable(t.record(t.string, t.array(ConceptCodec)), {}),
        hasDescription: nullable(t.string),
        earliestReleaseDate: nullable(DateFromXDS),
        originalPremiereDate: nullable(DateFromXDS),
        displayTitle: nullable(t.string),
        brandedTitle: nullable(t.string),
        aka: fromNullable(t.array(t.string), []),
        fka: fromNullable(t.array(t.string), []),
        titleNameDisambiguated: nullable(t.string),
        titleNameStandardized: nullable(t.string),
        groupId: nullable(t.string),
        _unverified_radarGroupNumber: nullable(t.string),
        productId: nullable(t.string),
        productNumber: nullable(t.string),
        eidrL1: nullable(t.string),
        hasMDMID: nullable(t.string),

        creativeFormat: nullable(t.string),
        brandTitle: nullable(t.string),
        synopsisMedium: nullable(t.string),

        _unverified_rating: nullable(t.string),
        _unverified_runtime: nullable(t.string),

        disneyPlusSelected: fromNullable(BooleanFromString, false),
        DisneyPlusOriginal: fromNullable(BooleanFromString, false),

        starSelected: fromNullable(BooleanFromString, false),
    }),
    metadata: MetadataCodec,
    storylines: fromNullable(t.array(FeatureStorylineCodec), []),
    relationships: fromNullable(t.array(RelationshipCodec), []),
    characters: fromNullable(t.array(CastMemberCodec), []),
})

export interface Feature extends t.TypeOf<typeof FeatureCodecBase> {}
export const FeatureCodec: t.Type<Feature> = cwm
    .CWMTypeLabelCodec(cwm.CWM_CLASS_TYPE_FEATURE_FILM)
    .pipe(FeatureCodecBase, FeatureCodecBase.name) as t.Type<Feature>

export const FeatureJSONViewOmittedValues = [
    'creativeWorkType',
    'creativeWorkTypeLabel',
    'cwmClassType',
    'static.type',
    'static.typeLabel',
    'static.parentClass',
    'static.parentClassLabel',
]
