import React from 'react'
import { ReactComponent as CloseIcon } from 'shared/components/Icons/close-icon.svg'
import styled from 'shared/theme'
import { useButton } from '@react-aria/button'

export default CloseIcon

type CloseIconButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, 'children'>
const CloseIconButtonElement = styled(CloseIcon)<CloseIconButtonProps>`
    cursor: pointer;
    color: ${props => props.theme.colors.textSecondary};
`

export function CloseIconButton(props: CloseIconButtonProps): JSX.Element {
    const ref = React.useRef<HTMLButtonElement>()
    const { buttonProps } = useButton({ elementType: 'svg' }, ref as any)
    return <CloseIconButtonElement {...buttonProps} {...(props as any)} />
}
