import React, { useMemo } from 'react'
import { Form, Formik, useFormikContext } from 'formik'
import { set, format } from 'date-fns'

import { usePartialMyIDUsersQuery } from 'api/workflow/partialMyIDUsers'
import { AssignmentConfiguration } from 'model/workflow/AssignmentConfiguration'
import { AssignmentTitle } from 'model/workflow/CreateWorkflowRequest'
import {
    getTaskLabel,
    getValidTaskTypesPredicate,
    useSourceOptionsForSelectedTitles,
    useTaskOptionsForSelectedTitles,
} from 'model/workflow/TaskType'
import { WorkflowPriorityOptions } from 'model/workflow/WorkflowPriority'
import CellLabel from 'shared/components/CellLabel'
import CheckboxInput from 'shared/components/CheckboxRadioInput/Checkbox'
import DatePicker from 'shared/components/DatePicker'
import ErrorMessage from 'shared/components/ErrorMessage'
import FormikSelect from 'shared/components/Select/FormikSelect'
import Autosave from 'shared/form/Autosave'
import styled from 'shared/theme'
import { useInterpreter } from '../CreateAssignmentProvider'
import AssigneeSelect, {
    getAsigneeOptions,
    getTasksOptions,
    AssigneeTooltip,
    ASSIGNEE_TOOLTIP_TEXT,
} from './AssigneeSelect'

export const GridLayout = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 0.75fr 0.75fr 1.5fr 0.5fr 0.5fr;
    grid-column-gap: 0.5rem;
    padding: 0 1rem;
`
const FormWrapper = styled.div<{ hasErrors?: boolean }>`
    background-color: ${props =>
        props.hasErrors ? props.theme.colors.backgroundError : props.theme.colors.activeField};
    position: relative;
    box-sizing: border-box;
    width: 100%;
`

type AssignmentTableRowFormProps = {
    title: AssignmentTitle
    editingConfiguration: Partial<AssignmentConfiguration>
    globalConfiguration: AssignmentConfiguration
}

const AssignmentTableRow: React.FC<AssignmentTableRowFormProps> = ({
    title,
    globalConfiguration,
}) => {
    const service = useInterpreter()
    const taskTypeOptions = useTaskOptionsForSelectedTitles([title])
    const { isLoading: isSourceTypeOptionsLoading, data: sourceTypeOptions = [] } =
        useSourceOptionsForSelectedTitles()
    const { values, setFieldValue } = useFormikContext<AssignmentConfiguration>()

    const partialUsersQuery = usePartialMyIDUsersQuery()

    const assigneeOptions = useMemo(() => {
        return getAsigneeOptions(partialUsersQuery.data, values.tasks)
    }, [partialUsersQuery.data, values.tasks])

    const tasksOpts = useMemo(() => {
        return getTasksOptions(taskTypeOptions, assigneeOptions, values.assignee)
    }, [taskTypeOptions, assigneeOptions, values.assignee])

    return (
        <Form>
            <Autosave<Partial<AssignmentConfiguration>>
                onChange={editingConfiguration => {
                    service.send({
                        type: 'TITLE_CONFIGURATION_EDIT_UPDATE',
                        title,
                        editingConfiguration,
                    })
                }}
            />
            <FormWrapper>
                <GridLayout>
                    <div>
                        <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                            Tasks
                            <AssigneeTooltip
                                text={ASSIGNEE_TOOLTIP_TEXT}
                                id="table-row-tasks-selection-tooltip"
                            />
                        </CellLabel>
                        <FormikSelect
                            name="tasks"
                            isMulti
                            options={tasksOpts}
                            placeholder={
                                globalConfiguration.tasks
                                    .filter(getValidTaskTypesPredicate(title))
                                    .map(getTaskLabel)
                                    .join(', ') || 'Select Tasks'
                            }
                            value={taskTypeOptions.filter(tto =>
                                (values.tasks || []).includes(tto.value),
                            )}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            onChange={
                                ((value: { value: string }[]) => {
                                    setFieldValue('tasks', value ? value.map(v => v.value) : [])
                                }) as any
                            }
                        />
                    </div>

                    <div>
                        <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                            Assignee
                        </CellLabel>
                        <AssigneeSelect
                            name="assignee"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPlacement="bottom"
                            placeholder={globalConfiguration.assignee}
                            options={assigneeOptions}
                            isLoading={partialUsersQuery.isLoading}
                        />
                    </div>

                    <div>
                        <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                            Priority
                        </CellLabel>
                        <FormikSelect
                            name="priority"
                            isClearable={true}
                            options={WorkflowPriorityOptions}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            value={WorkflowPriorityOptions.find(po => po.value === values.priority)}
                            onChange={
                                ((value: { value: string } | null) =>
                                    setFieldValue('priority', value ? value.value : null)) as any
                            }
                            placeholder={
                                WorkflowPriorityOptions.find(
                                    po => po.value === globalConfiguration.priority,
                                )?.label ?? 'Select Source'
                            }
                        />
                    </div>

                    <div>
                        <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                            Due Date
                        </CellLabel>
                        <DatePicker
                            dateFormat="MMMM d, yyyy"
                            placeholderText={
                                globalConfiguration.deadlineAt
                                    ? format(
                                          new Date(globalConfiguration.deadlineAt),
                                          'MMMM d, yyyy',
                                      )
                                    : undefined
                            }
                            selected={values.deadlineAt ? new Date(values.deadlineAt) : undefined}
                            onChange={date => {
                                setFieldValue(
                                    'deadlineAt',
                                    date
                                        ? set(date as Date, {
                                              hours: 0,
                                              minutes: 0,
                                              seconds: 0,
                                          })
                                        : undefined,
                                )
                            }}
                        />
                    </div>

                    <div>
                        <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                            Source
                        </CellLabel>
                        <FormikSelect
                            name="source"
                            options={sourceTypeOptions}
                            isClearable={true}
                            value={sourceTypeOptions.find(so => so.value === values.source)}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isLoading={isSourceTypeOptionsLoading}
                            onChange={
                                ((value: { value: string } | null) =>
                                    setFieldValue('source', value ? value.value : null)) as any
                            }
                            placeholder={
                                sourceTypeOptions.find(
                                    po => po.value === globalConfiguration.source,
                                )?.label ?? 'Select Source'
                            }
                        />
                        <ErrorMessage name="source" />
                    </div>

                    <div>
                        <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                            Needs review{' '}
                        </CellLabel>
                        <CheckboxInput
                            name="reviewRequired"
                            checked={!!values.reviewRequired}
                        ></CheckboxInput>
                        <ErrorMessage name="reviewRequired" />
                    </div>
                </GridLayout>
            </FormWrapper>
        </Form>
    )
}

const AssignmentTableRowForm: React.FC<AssignmentTableRowFormProps> = ({
    title,
    editingConfiguration,
    globalConfiguration,
}) => {
    const service = useInterpreter()
    const _editingConfiguration = useMemo(() => editingConfiguration || {}, [editingConfiguration])

    return (
        <Formik
            initialValues={_editingConfiguration}
            enableReinitialize
            onSubmit={configuration => {
                service.send({
                    type: 'TITLE_CONFIGURATION_EDIT_CONFIRM',
                    title,
                    configuration,
                })
            }}
        >
            <AssignmentTableRow
                title={title}
                editingConfiguration={editingConfiguration}
                globalConfiguration={globalConfiguration}
            />
        </Formik>
    )
}
export default AssignmentTableRowForm
