import React, { useMemo } from 'react'
import { useFormikContext, FieldArray } from 'formik'
import { v4 } from 'uuid'

import { FormikTalentSearchAsTalent, InlineTalentSearchAsTalent } from './TalentSearch'
import { FormikCharacterSearchAsTalent, InlineCharacterSearchAsTalent } from './CharacterSearch'
import { PortrayalFormInProgressState, SearchType } from './types'
import { getPortrayedBySearchTypeFromPortrayalType } from './utils'
import { usePortrayalsContext } from './portrayalRowsContext'
import { MdmSearchModeCtrl } from './MdmIDSearchModeCheckbox'
import Text from 'shared/components/Text'
import { MdmIdGrid } from './Layout'

export const FormikPortrayedBy: React.FC<{
    disabled: boolean
    onSearchModeChange: (value: boolean) => void
    isMdmSearchModeOn: boolean
}> = ({ disabled, isMdmSearchModeOn, onSearchModeChange }) => {
    const { values } = useFormikContext<PortrayalFormInProgressState>()

    const searchType = useMemo(() => {
        return getPortrayedBySearchTypeFromPortrayalType(values.portrayal.portrayalType?.uri || '')
    }, [values.portrayal.portrayalType])

    return (
        <>
            <MdmIdGrid disabled={disabled}>
                <Text size="5" weight="bold" as="h5">
                    Portrayed By
                </Text>
                <Text size="5" weight="bold" as="h5">
                    MDM ID
                </Text>
            </MdmIdGrid>
            <FieldArray name="portrayedBy">
                {() => (
                    <>
                        {values.portrayedBy.map((_, idx) => {
                            const name = `portrayedBy[${idx}]`
                            return (
                                <div
                                    key={v4()}
                                    style={{
                                        marginBottom:
                                            idx === values.portrayedBy.length - 1
                                                ? 'auto'
                                                : '0.5rem',
                                    }}
                                >
                                    {searchType === SearchType.Talent ? (
                                        <FormikTalentSearchAsTalent
                                            disabled={disabled}
                                            name={name}
                                            index={idx}
                                            isMdmMode={isMdmSearchModeOn}
                                        />
                                    ) : (
                                        <FormikCharacterSearchAsTalent
                                            disabled={disabled}
                                            name={name}
                                            index={idx}
                                            isMdmMode={isMdmSearchModeOn}
                                        />
                                    )}
                                </div>
                            )
                        })}
                    </>
                )}
            </FieldArray>
            {!disabled && !!values.portrays.length && (
                <MdmSearchModeCtrl
                    searchType={searchType}
                    isMdmIDOn={isMdmSearchModeOn}
                    onChange={onSearchModeChange}
                />
            )}
        </>
    )
}

export const InlineEditPortrayedBy: React.FC<{
    disabled: boolean
}> = ({ disabled }) => {
    const { portrayalInEdit } = usePortrayalsContext()

    const searchType = useMemo(() => {
        return getPortrayedBySearchTypeFromPortrayalType(
            portrayalInEdit?.portrayal.portrayalType?.uri || '',
        )
    }, [portrayalInEdit])

    const selectList = useMemo(() => {
        return portrayalInEdit ? portrayalInEdit.portrayedBy : []
    }, [portrayalInEdit])

    return (
        <>
            {selectList.map((_, idx) => {
                return (
                    <div
                        key={v4()}
                        style={{
                            marginBottom: idx === selectList.length - 1 ? 'auto' : '1rem',
                        }}
                    >
                        {searchType === SearchType.Talent ? (
                            <InlineTalentSearchAsTalent disabled={disabled} index={idx} />
                        ) : (
                            <InlineCharacterSearchAsTalent disabled={disabled} index={idx} />
                        )}
                    </div>
                )
            })}
        </>
    )
}
