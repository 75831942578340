import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Formik, Form, FieldArray } from 'formik'
import styled from 'styled-components'
import * as y from 'yup'
import { v4 } from 'uuid'
import { isEqual } from 'lodash'
import copy from 'copy-to-clipboard'

import { CastMember } from 'codecs/CastMember'
import { useUser } from 'auth/Auth'

import {
    CharRelationshipToSave,
    deleteRelationshipList,
    saveCharacterRelationships,
} from 'api/portrayals'

import { notification } from 'shared/notification'
import Loader from 'shared/components/Loader/index'
import Text from 'shared/components/Text'
import { CloseIconButton } from 'shared/components/Icons/CloseIcon/CloseIcon'
import Button from 'shared/components/Button'
import { CopyIconButton } from '../Icons/CopyIcon/CopyIcon'

import {
    extractResIdHash,
    extractRelationshipTypeValue,
    getRelationshipTypeLabelByValue,
} from './utils'
import { useCharacterRelationships } from './useCharacterRelationships'
import { ErrorText } from './ErrorText'
import { RelationshipTypeSelect } from './RelationshipTypeSelect'
import { EntityTypePill } from './EntityTypePill'
import { FormikRelCharacterSearch } from './CharacterSearch'
import { MdmSearchModeCtrl } from './MdmIDSearchModeCheckbox'
import { SearchType } from './types'

const RelationshipsGrid = styled.div`
    display: grid;
    grid-template-columns: 40% 50% 60px;
    grid-column-gap: 1rem;
`

type CharacterRelationshipsProps = {
    portrayal: CastMember
    areControlsDisabled: boolean
}

type RelationshipItem = {
    character: {
        label: string
        uri: string
    }
    relationshipType: {
        value: string
        label: string
    }
    id: string
}

const characterSchema = y
    .object({
        label: y.string(),
        uri: y.string(),
    })
    .required('This field is required')
    .nullable()

const relationshipTypeSchema = y
    .object({
        value: y.string(),
        label: y.string(),
    })
    .required('This field is required')
    .nullable()

const RowSchema = y
    .object({
        character: characterSchema,
        relationshipType: relationshipTypeSchema,
    })
    .defined()

const validationSchema = y.object({
    relationships: y.array().of(RowSchema).optional(),
})

type FormState = {
    relationships: RelationshipItem[]
}

export const CharacterRelationships: React.FC<CharacterRelationshipsProps> = ({
    portrayal,
    areControlsDisabled,
}) => {
    // TODO: Remove getter by index when it is clear that there are multiple characters/portrays
    // Assuming that there can be only one portrays
    const { characterId, characterName, mdmId } = portrayal.character

    const user = useUser()
    const { isFetching, isError, data } = useCharacterRelationships(characterId)

    const guid = useMemo(() => {
        return extractResIdHash(characterId)
    }, [characterId])

    const [isSaving, setIsSaving] = useState(false)
    const [isSavingError, setIsSavingError] = useState(false)

    const [initialFormState, setInitialFormState] = useState<null | FormState>(null)
    const [submittedRelationships, setSubmittedRelationships] = useState<null | FormState>(null)

    const [isMdmSearchOn, setIsMdmSearchOn] = useState(false)

    const handleSearchModeChange = useCallback(
        (value: boolean) => {
            setIsMdmSearchOn(value)
        },
        [setIsMdmSearchOn],
    )

    useEffect(() => {
        const originalData = data ? data.relationships : []
        const mappedList: RelationshipItem[] = originalData.map(r => {
            return {
                id: v4(),
                character: {
                    uri: r.targetCharacter.uri,
                    label: r.targetCharacter.prefLabel,
                },
                relationshipType: {
                    value: r.type,
                    label: getRelationshipTypeLabelByValue(r.type),
                },
            }
        })
        setInitialFormState({
            relationships: mappedList,
        })
        setSubmittedRelationships({
            relationships: mappedList,
        })
    }, [data, setInitialFormState])

    const handleSubmit = useCallback(
        (e: FormState) => {
            if (
                isEqual(
                    e.relationships,
                    !!submittedRelationships ? submittedRelationships.relationships : [],
                )
            ) {
                notification.warning('Nothing to save')
            } else {
                const relationshipsToSave: CharRelationshipToSave[] = e.relationships.map(e => {
                    return {
                        characterId: characterId,
                        type: extractRelationshipTypeValue(e.relationshipType.value),
                        targetCharacterId: e.character.uri,
                    }
                })
                setIsSaving(true)
                setIsSavingError(false)
                if (!!relationshipsToSave.length) {
                    saveCharacterRelationships(relationshipsToSave, user)
                        .then(() => {
                            setSubmittedRelationships(e)
                        })
                        .catch(() => {
                            setIsSavingError(true)
                        })
                        .finally(() => {
                            setIsSaving(false)
                        })
                } else {
                    deleteRelationshipList(characterId, user)
                        .then(() => {
                            setSubmittedRelationships(e)
                        })
                        .catch(() => {
                            setIsSavingError(true)
                        })
                        .finally(() => {
                            setIsSaving(false)
                        })
                }
            }
        },
        [
            characterId,
            submittedRelationships,
            user,
            setIsSaving,
            setIsSavingError,
            setSubmittedRelationships,
        ],
    )

    return (
        <div style={{ textAlign: 'left' }}>
            <h3>{characterName}</h3>
            <EntityTypePill name="character" />
            <span></span>
            <Text size="5" weight="bold" as="h5">
                MDM ID
            </Text>
            <div>{mdmId || 'Not Available'}</div>
            <Text size="5" weight="bold" as="h5">
                URI
            </Text>
            <div>
                {characterId}{' '}
                <CopyIconButton
                    title="Copy to clipboard"
                    style={{ verticalAlign: 'middle' }}
                    onClick={() => copy(characterId)}
                />
            </div>
            <Text size="5" weight="bold" as="h5">
                GUID
            </Text>
            <div>
                {guid}{' '}
                <CopyIconButton
                    title="Copy to clipboard"
                    style={{ verticalAlign: 'middle' }}
                    onClick={() => copy(guid)}
                />
            </div>

            {!isFetching && initialFormState && (
                <>
                    <Formik<FormState>
                        initialValues={initialFormState}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                    >
                        {({ values, isValid }) => (
                            <Form>
                                <div style={{ marginBottom: '2rem' }}>
                                    <FieldArray name="relationships">
                                        {({ push, remove }) => (
                                            <>
                                                {!areControlsDisabled && (
                                                    <div>
                                                        <Button
                                                            variant="primary"
                                                            size="small"
                                                            onClick={() => {
                                                                push({
                                                                    character: null,
                                                                    relationshipType: null,
                                                                    id: v4(),
                                                                })
                                                            }}
                                                            disabled={isSaving}
                                                        >
                                                            <span>+</span>
                                                            <span style={{ paddingLeft: '5px' }}>
                                                                Add a relationship
                                                            </span>
                                                        </Button>
                                                    </div>
                                                )}

                                                {!values.relationships.length && (
                                                    <div>
                                                        No relationships found for this characters
                                                    </div>
                                                )}

                                                {!!values.relationships.length && (
                                                    <RelationshipsGrid>
                                                        <Text size="5" weight="bold" as="h5">
                                                            Relationship Type
                                                        </Text>
                                                        <Text size="5" weight="bold" as="h5">
                                                            Character
                                                        </Text>
                                                        <div></div>
                                                    </RelationshipsGrid>
                                                )}

                                                {values.relationships.map((r, index) => {
                                                    return (
                                                        <RelationshipsGrid
                                                            key={r.id}
                                                            style={{ marginBottom: '0.5rem' }}
                                                        >
                                                            <div>
                                                                <RelationshipTypeSelect
                                                                    name={`relationships[${index}].relationshipType`}
                                                                    disabled={areControlsDisabled}
                                                                />
                                                            </div>
                                                            <div>
                                                                <FormikRelCharacterSearch
                                                                    name={`relationships[${index}].character`}
                                                                    disabled={areControlsDisabled}
                                                                    isMdmMode={isMdmSearchOn}
                                                                />
                                                            </div>
                                                            <div>
                                                                {!areControlsDisabled && (
                                                                    <CloseIconButton
                                                                        label="Cancel"
                                                                        onClick={() => {
                                                                            remove(index)
                                                                        }}
                                                                        style={{
                                                                            verticalAlign: 'middle',
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </RelationshipsGrid>
                                                    )
                                                })}
                                            </>
                                        )}
                                    </FieldArray>

                                    {!areControlsDisabled && !!values.relationships.length && (
                                        <MdmSearchModeCtrl
                                            isMdmIDOn={isMdmSearchOn}
                                            onChange={handleSearchModeChange}
                                            searchType={SearchType.Character}
                                        />
                                    )}

                                    {isError && (
                                        <ErrorText>
                                            Error has occurred while loading relationships.
                                        </ErrorText>
                                    )}

                                    {isSavingError && (
                                        <ErrorText>
                                            Error has occurred while saving relationship.
                                        </ErrorText>
                                    )}
                                </div>
                                {(!!data?.character.aka.length || !!data?.character.fka.length) && (
                                    <>
                                        <Text size="5" weight="bold" as="h5">
                                            Alternative labels
                                        </Text>
                                        {data.character.aka.map(f => {
                                            return (
                                                <RelationshipsGrid key={f.uri}>
                                                    <div>AKA</div>
                                                    <div>{f.prefLabel}</div>
                                                </RelationshipsGrid>
                                            )
                                        })}
                                        {data.character.fka.map(f => {
                                            return (
                                                <RelationshipsGrid key={f.uri}>
                                                    <div>FKA</div>
                                                    <div>{f.prefLabel}</div>
                                                </RelationshipsGrid>
                                            )
                                        })}
                                    </>
                                )}

                                {!areControlsDisabled && (
                                    <div style={{ textAlign: 'right' }}>
                                        <Button
                                            variant="outline"
                                            size="small"
                                            style={{ marginLeft: 'auto' }}
                                            type="submit"
                                            disabled={isSaving || !isValid}
                                            isLoading={isSaving}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                </>
            )}

            {isFetching && (
                <div style={{ textAlign: 'center' }}>
                    <span style={{ width: '50px', height: '50px', display: 'inline-block' }}>
                        <Loader size="ssmallmedium" />
                    </span>
                </div>
            )}
        </div>
    )
}
