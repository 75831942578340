import React, { useCallback } from 'react'
import { FormikProps, useFormikContext } from 'formik'
import ErrorMessage from 'shared/components/ErrorMessage'
import { transparentize } from 'polished'
import { ValueType } from 'react-select'

import styled from 'shared/theme'
import TextualButton from 'shared/components/TextualButton'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Container from 'shared/components/Container'
import { AlertDialog, AlertDialogLabel, AlertDialogButtons } from 'shared/components/AlertModal'
import Loader from 'shared/components/Loader'

import FormikSelect from '../Select/FormikSelect'
import { Association } from '../../../model/Associations'
import { useAssociationsContext } from './AssociationsProvider'

import { usePureFn } from 'shared/hooks/usePureFn'
import AssociationTaxonomySelect from './AssociationTaxonomySelect'
import { TOP_CONCEPT_LIST } from './constants'
import { AssocMultiple, AssocSingle, NewAssociation, TopConceptOption } from './types'

export const TableLayout = styled.div<{ hasErrors?: boolean }>`
    padding: 0.5rem 1rem 1rem;
    display: grid;
    column-gap: 1rem;
    grid-template-columns: minmax(150px, 280px) minmax(700px, auto) minmax(0, 280px);
    align-items: start;

    ${props => props.hasErrors && `background-color: ${props.theme.colors.backgroundError};`}
`
export const LoaderContainer = styled.div`
    padding-bottom: 12px;
`

const StyledContainer = styled.div`
    background-color: ${props => props.theme.colors.activeField};
    box-shadow: 0 4px 8px 0 ${props => transparentize('0.7', props.theme.colors.textSecondary)};
    border: 1px solid ${props => props.theme.colors.gray};
    border-radius: 5px;
    margin-bottom: 0.5rem;
    position: relative;
    width: 100%;
`

const ButtonsContainer = styled(Container).attrs({
    justifyContent: 'flex-end',
    p: '2',
})`
    border-top: 1px solid ${props => props.theme.colors.border};
`

const AssociationFormNode: React.FC<
    FormikProps<AssocMultiple | AssocSingle | NewAssociation> & {
        onTopCategoryChange: (value: ValueType<TopConceptOption>) => void
    }
> = ({ submitForm, onTopCategoryChange }): React.ReactElement => {
    const [confirm, showConfirmModal] = React.useState(false)
    const [selectedConcept, setSelectedConcept] = React.useState(null)

    const { values, isValid, setFieldValue } = useFormikContext<any>()
    const { associationsList, isAssociationInEditMode, association, isSaving, cancelEdit } =
        useAssociationsContext()

    React.useEffect(() => {
        if (!values.type) {
            return
        }

        if (selectedConcept !== values.type.typeURI) {
            setFieldValue('associations', undefined)
        }

        setSelectedConcept(values.type.typeURI)
    }, [values.type, selectedConcept, setFieldValue])

    const isOptionDisabled = useCallback(
        o => !!associationsList.find(a => a.type.typeURI === o.typeURI),
        [associationsList],
    )
    const getOptionValue = usePureFn(o => o.typeURI)

    return (
        <StyledContainer>
            <TableLayout>
                <div>
                    <Text size="5" weight="bold" as="h5">
                        Type
                    </Text>
                    <FormikSelect
                        name="type"
                        isOptionDisabled={isOptionDisabled}
                        getOptionValue={getOptionValue}
                        options={TOP_CONCEPT_LIST}
                        onChange={onTopCategoryChange}
                    />
                    <ErrorMessage name="type" />
                </div>

                {values.type && (
                    <AssociationTaxonomySelect
                        type={values.type.value}
                        isMulti={!values.type.isSingle}
                    />
                )}
            </TableLayout>

            <ButtonsContainer>
                {isAssociationInEditMode && association && (
                    <>
                        <Button
                            variant="danger"
                            disabled={isSaving}
                            onClick={() => showConfirmModal(true)}
                        >
                            Delete
                        </Button>
                        {confirm && (
                            <DeleteAssociationAlert
                                association={association}
                                showConfirmModal={showConfirmModal}
                            />
                        )}
                    </>
                )}
                <TextualButton weight="bold" mr="1" mx={2} onClick={cancelEdit}>
                    Cancel
                </TextualButton>

                <Button
                    disabled={!isValid || isSaving}
                    isLoading={isSaving}
                    onClick={() =>
                        // hack for proper formik states
                        Promise.resolve().then(() => submitForm())
                    }
                >
                    Save
                </Button>
            </ButtonsContainer>
        </StyledContainer>
    )
}
export default AssociationFormNode

type DeleteAssociationAlertProps = {
    association: Association
    showConfirmModal: React.Dispatch<React.SetStateAction<boolean>>
}

const DeleteAssociationAlert: React.FC<DeleteAssociationAlertProps> = ({
    association,
    showConfirmModal,
}) => {
    const cancelRef = React.createRef<HTMLButtonElement>()
    const { isSaving, updateAssociation } = useAssociationsContext()

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <AlertDialogLabel>Delete this association?</AlertDialogLabel>
            <AlertDialogButtons>
                {isSaving ? (
                    <LoaderContainer>
                        <Loader center size="smedium" />
                    </LoaderContainer>
                ) : (
                    <>
                        <Button
                            variant="danger"
                            onClick={() =>
                                updateAssociation(association, true /* true === delete */)
                            }
                        >
                            Delete
                        </Button>
                        <TextualButton
                            onClick={() => showConfirmModal(false)}
                            ref={cancelRef}
                            variant="primary"
                        >
                            Cancel
                        </TextualButton>
                    </>
                )}
            </AlertDialogButtons>
        </AlertDialog>
    )
}
