import * as t from 'io-ts'
import { nullable } from 'codecs/util/nullable'

import { fromNullable } from 'codecs/util/fromNullable'

/////////////////////////////////////////////
// DE&I
/////////////////////////////////////////////
export enum DEIAttribute {
    Genders = 'http://data.disney.com/resources/Genders',
    RaceOrEthnicity = 'http://data.disney.com/resources/RaceEthnicity',
    SexualOrientation = 'http://data.disney.com/resources/SexualOrientation',
    PlaceOfOrigin = 'http://data.disney.com/resources/PlaceOfOrigin',
    AgeGroups = 'http://data.disney.com/resources/AgeGroups',
    Disability = 'http://data.disney.com/resources/Disability',
    DisabilityState = 'http://data.disney.com/resources/DisabilityState',
    MilitaryBody = 'http://data.disney.com/resources/MilitaryBody',
    MilitaryStatus = 'http://data.disney.com/resources/MilitaryStatus',
    Religion = 'http://data.disney.com/resources/Religion',
}

// const DEnIAttributeCodec = t.intersection([
//     AttributeCodec,
//     t.record(t.string, t.boolean)
// ])
const DEnIAttributeCodec = t.any

const DEnAAttributesCodec = t.record(t.string, DEnIAttributeCodec)
export type DEnAAttributes = t.TypeOf<typeof DEnAAttributesCodec>

const DEnICharacterAttributesCodec = t.type({
    attributes: fromNullable(DEnAAttributesCodec, {}),
})
export type DEnICharacterAttributes = t.TypeOf<typeof DEnICharacterAttributesCodec>

/////////////////////////////////////////////
// Cast Member
/////////////////////////////////////////////
export const TalentCodec = t.type({
    talentId: t.string,
    talentName: t.string,
})

export type Talent = t.TypeOf<typeof TalentCodec>

export const CharacterCodec = t.type({
    characterId: t.string,
    characterName: t.string,
    mdmId: t.union([t.string, t.undefined]),
})

export type Character = t.TypeOf<typeof CharacterCodec>

export const RoleCodec = t.type({
    roleId: t.string,
    roleLabel: t.string,
})

export type Role = t.TypeOf<typeof RoleCodec>

export const PortrayalTypeCodec = t.type({
    uri: t.string,
    label: t.string,
})

export type PortrayalType = t.TypeOf<typeof PortrayalTypeCodec>

export const PortrayalCodec = t.type({
    portrayalId: t.string,
    portrayalType: t.union([PortrayalTypeCodec, t.undefined, t.null]),
    portrayalName: t.union([t.string, t.undefined]),
})

export type Portrayal = t.TypeOf<typeof PortrayalCodec>

export const MetaCodec = t.type({
    portrayedBy: t.string,
})

export const CastMemberCodec = t.intersection([
    t.type({
        talent: nullable(TalentCodec),
        role: nullable(RoleCodec),
        character: CharacterCodec,
        portrayal: PortrayalCodec,
        __meta__: t.union([MetaCodec, t.undefined]),
    }),
    t.partial(DEnICharacterAttributesCodec.props),
])

export type CastMember = t.TypeOf<typeof CastMemberCodec>

export const CastMemberWithNotesCodec = t.intersection([
    CastMemberCodec,
    t.type({
        notes: t.string,
    }),
])

export type CastMemberWithNotes = t.TypeOf<typeof CastMemberWithNotesCodec>
