import React, { useCallback, useMemo } from 'react'
import { useField } from 'formik'
import { ValueType } from 'react-select'

import { MemoSelect } from 'shared/components/Select'
import ErrorMessage from 'shared/components/ErrorMessage'
import { usePortrayalsContext } from './portrayalRowsContext'
import FormikSelect from '../Select/FormikSelect'
import { usePortrayalRoles } from './userPortrayalRoles'

type PortrayalRoleSelectProps = {
    disabled: boolean
}

type FormattedOption = {
    roleId: string
    roleLabel: string
}

const getOptionValue = (opt: FormattedOption) => {
    return opt.roleId
}

const getOptionLabel = (opt: FormattedOption) => {
    return opt.roleLabel
}

export const PortrayalRoleSelect: React.FC<PortrayalRoleSelectProps> = ({ disabled }) => {
    const { portrayalInEdit, setCurrentPortrayal } = usePortrayalsContext()

    const { data, isFetching } = usePortrayalRoles()

    const options: FormattedOption[] = useMemo(() => {
        const fetchedData = data || []

        return fetchedData.map(o => {
            return {
                roleId: o.scopeUri,
                roleLabel: o.scopeLabel,
            }
        })
    }, [data])

    const handleOnChange = useCallback(
        (opt: ValueType<FormattedOption>) => {
            const selectedOpt = opt as FormattedOption | null

            !!portrayalInEdit &&
                setCurrentPortrayal({
                    ...portrayalInEdit,
                    role: selectedOpt,
                })
        },
        [portrayalInEdit, setCurrentPortrayal],
    )

    const value = useMemo(() => {
        const role = portrayalInEdit?.role || null
        return role
    }, [portrayalInEdit])

    return (
        <MemoSelect<FormattedOption>
            hideSelectedOptions={false}
            options={options}
            onChange={handleOnChange}
            value={value}
            isLoading={isFetching}
            isMulti={false}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            isDisabled={disabled}
            isClearable={false}
        />
    )
}

export const FormikPortrayalRoleSelect: React.FC<PortrayalRoleSelectProps> = ({ disabled }) => {
    const name = 'role'
    const { data, isFetching } = usePortrayalRoles()

    const options = useMemo(() => {
        const fetchedData = data || []

        return fetchedData.map(o => {
            return {
                roleId: o.scopeUri,
                roleLabel: o.scopeLabel,
            }
        })
    }, [data])

    const [{ value }] = useField(name)

    return (
        <>
            <FormikSelect
                value={value}
                name={name}
                isDisabled={disabled}
                isLoading={isFetching}
                options={options}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                isClearable={false}
            />

            <ErrorMessage name={name} />
        </>
    )
}
