import * as y from 'yup'
import * as t from 'io-ts'

export const StorylineAttributeCodec = t.intersection([
    t.type({
        id: t.string,
        label: t.string,
    }),
    t.partial({
        broaderId: t.string,
    }),
])
export type StorylineAttribute = t.TypeOf<typeof StorylineAttributeCodec>

/////////////////////////////////////////////
// YUP VALIDATION
/////////////////////////////////////////////

export const StorylineAttributeFormSchema = y
    .object({
        id: y.string().required(),
        label: y.string().required(),
    })
    .defined()
