import * as cwm from '@genome-web-forms/common/model/CWMClassType'
import { featureLink, seriesLink, episodeLink, seasonLink } from 'routing/routes'
import {
    SearchResult,
    FeatureResultCodec,
    SeriesResultCodec,
    EpisodeResultCodec,
    SeasonOfSeriesResultCodec,
    ESResultCodec,
} from 'model/search/SearchResult'
import { assertNever } from 'shared/util/assertNever'

interface ResourceIdRouteRedirect {
    resourceId: string
    cwmClassType: string
}

const routeUsingGenomeObject = (searchResult: SearchResult): string => {
    const { genomeObject: obj } = searchResult

    if (EpisodeResultCodec.is(obj)) {
        return episodeLink(obj)
    }
    if (FeatureResultCodec.is(obj)) {
        return featureLink(obj)
    }
    if (SeriesResultCodec.is(obj)) {
        return seriesLink(obj)
    }
    if (SeasonOfSeriesResultCodec.is(obj)) {
        return seasonLink(obj)
    }
    if (ESResultCodec.is(obj)) {
        return routeToUsingESResult(searchResult)
    }

    assertNever(obj, `Non-exhaustive conditional detected for ${JSON.stringify(obj)}`)
}

const routeToUsingResourceId = ({ resourceId, cwmClassType }: ResourceIdRouteRedirect): string => {
    switch (true) {
        case cwm.isEpisode(cwmClassType): {
            return episodeLink({ episodeId: resourceId })
        }
        case cwm.isFeature(cwmClassType): {
            return featureLink({ featureId: resourceId })
        }
        case cwm.isSeries(cwmClassType): {
            return seriesLink({ seriesId: resourceId })
        }
        case cwm.isSeason(cwmClassType): {
            return seasonLink({ seasonId: resourceId })
        }
    }
    throw new Error(`Cannot generate link for CWM Class Type "${cwmClassType}"`)
}

const routeToUsingESResult = (obj: any): string => {
    const {
        id,
        genomeObject: { cwmClassType },
    } = obj
    if (cwm.isEpisode(cwmClassType)) {
        return episodeLink({ episodeId: id })
    }
    if (cwm.isFeature(cwmClassType)) {
        return featureLink({ featureId: id })
    }
    if (cwm.isSeries(cwmClassType)) {
        return seriesLink({ seriesId: id })
    }
    if (cwm.isSeason(cwmClassType)) {
        return seasonLink({ seasonId: id })
    }

    throw new Error(`Cannot generate route link for CWM ES Class Type "${cwmClassType}"`)
}

export const routeTo = (obj: SearchResult | ResourceIdRouteRedirect): string => {
    if ((obj as SearchResult).genomeObject) return routeUsingGenomeObject(obj as SearchResult)
    else return routeToUsingResourceId(obj as ResourceIdRouteRedirect)
}
