import React from 'react'

import UnavailableText from 'shared/components/UnavailableText'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'

import { Genre, OtherGenre } from 'model/metadata/Genre'

import { FormikSelect } from 'shared/components/Select/FormikSelect'
import { usePureFn } from 'shared/hooks/usePureFn'
import { TaxonomyTerm } from 'codecs/TRS/Taxonomy'
import { UNIQUE_NA_ID, useOtherGenres } from 'shared/hooks/useTRS'
import { useField } from 'formik'

export const NA_OTHER_GENRE_TERM: OtherGenre = {
    // TODO: fill this value
    otherGenreId: UNIQUE_NA_ID,
    otherGenreLabel: 'N/A',
    suggestionScore: null,
    notForTagging: false,
}

type OtherGenresSelectProps = {
    name: string
    formControlsEnabled: boolean
}
export const OtherGenresSelect: React.FC<OtherGenresSelectProps> = ({
    name,
    formControlsEnabled,
}): React.ReactElement => {
    const [{ value }] = useField<Genre['otherGenres']>(name)

    const { loaded, data: options } = useOtherGenres()

    const getOptionValue = usePureFn((genre: OtherGenre) => genre.otherGenreId)

    const optionsWithNA = React.useMemo(() => [...options, NA_OTHER_GENRE_TERM], [options])

    const getOptionLabel = usePureFn((genre: OtherGenre, term?: TaxonomyTerm) =>
        term ? term.label : genre.otherGenreLabel,
    )

    function render(): React.ReactElement {
        if (!formControlsEnabled && !value.length) {
            return <UnavailableText>Unavailable</UnavailableText>
        }

        return (
            <FormikSelect<OtherGenre>
                {...{
                    name,
                    isMulti: true,
                    options: optionsWithNA,
                    isLoading: !loaded,
                    getOptionValue,
                    getOptionLabel,
                    isDisabled: !formControlsEnabled,
                    placeholder: 'Other Genres',
                }}
            />
        )
    }

    return (
        <Container mb="1" flexDirection="column">
            <Text size="5" weight="bold" as="h5">
                Other Genres
            </Text>

            {render()}
        </Container>
    )
}

export default OtherGenresSelect
