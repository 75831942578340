import React from 'react'
import { AuthContext } from 'auth/Auth'
import { RoleName } from '@genome-web-forms/common/auth'

export const useHasRole = (role: string): boolean => {
    const { user } = React.useContext(AuthContext)
    const roles = user.roles || []
    const roleNames = roles.map(r => r.name)
    return React.useMemo(() => roleNames.includes(role), [roleNames, role])
}

export const useHasPortrayalsEditingRoles = (): boolean => {
    const hasDEIRole = useHasRole(RoleName.DEI)
    const hasLibReadRole = useHasRole(RoleName.LIBRARY_READ)
    const hasLibWriteRole = useHasRole(RoleName.LIBRARY_WRITE)
    return hasDEIRole || (hasLibReadRole && hasLibWriteRole)
}

export const useHasDEIRole = (): boolean => {
    const hasDEIRole = useHasRole(RoleName.DEI)
    return hasDEIRole
}

export const useHasLibReadRole = (): boolean => {
    return useHasRole(RoleName.LIBRARY_READ)
}

export const useHasLibWriteRole = (): boolean => {
    return useHasRole(RoleName.LIBRARY_WRITE)
}
