import * as Yup from 'yup'

export const createDescriptorsReportTypes: { [x: string]: string } = {
    NON_SERIAL_GENRES: 'Non-Serial Genres',
    NON_SERIAL_KEYWORDS: 'Non-Serial Descriptors',
    NON_SERIAL_LOCATIONS: 'Non-Serial Locations',
    SERIAL_GENRES: 'Serial Genres',
    SERIAL_KEYWORDS: 'Serial Descriptors',
    SERIAL_LOCATIONS: 'Serial Locations',
    TITLES_BY_RELATIONSHIPS: 'Titles  by Relationship',
    TITLES_BY_STORYLINES: 'Titles by Storyline',
    TITLES_BY_STORY_ROLE: 'Titles by Storyrole',
    WIP_FEATURE_PUBLISH_STATUS_STORYLINE: 'WIP Storylines',
}

export const DEIdescriptorsReportTypes: { [x: string]: string } = {
    NON_SERIAL_CHARACTERS: 'Non-Serial Characters',
    SERIAL_CHARACTERS: 'Serial Characters',
    SERIAL_CHARACTERS_BY_SEASON: 'Serial Characters by Season',
    SERIAL_CHARACTERS_BY_EPISODE: 'Serial Characters by Episode',
}

export interface CreateDescriptorsReportModel {
    selectedDate: Date | undefined
    checkedDescriptors: string[]
}

export const CreateDescriptorsReportValidationSchema = Yup.object().shape({
    selectedDate: Yup.date().required('Date is required'),
    checkedDescriptors: Yup.array().min(1).required('Descriptors type is required'),
})

export interface FetchCreateDescriptorsReportParams {
    date: Date
    fileName: string
}
