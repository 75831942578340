import * as t from 'io-ts'

import { fromNullable } from 'codecs/util/fromNullable'
import { LockCodec, Lock } from '@genome-web-forms/common/lock'
import nullable from 'codecs/util/nullable'

import * as cwm from '@genome-web-forms/common/model/CWMClassType'
import { Workflow } from '@genome-web-forms/server'
import { SupportedFacet } from 'shared/search/useFacetOptions'

export interface LockStatuses {
    metadata: Lock | null
    storylines: Lock | null
    relationships: Lock | null
}
const LockStatusesCodec: t.Type<LockStatuses> = t.type({
    metadata: nullable(LockCodec),
    storylines: nullable(LockCodec),
    relationships: nullable(LockCodec),
})

export const FeatureResultCodec = t.type({
    highlight: t.string,
    cwmClassType: t.keyof(cwm.CWM_FEATURE_CLASSES),
    cwmClassTypeLabel: t.string,
    productId: nullable(t.string),
    lockStatuses: nullable(LockStatusesCodec),

    hasDescriptors: nullable(t.boolean),
    hasRelationships: nullable(t.boolean),
    hasStorylines: nullable(t.boolean),

    publishedDate: nullable(t.string),
    isPublished: nullable(t.boolean),
    featureId: t.string,
    featureTitle: t.string,

    seasonNumber: nullable(t.string),
    episodeNumber: nullable(t.string),

    titleNameDisambiguated: t.string,
    releaseDate: nullable(t.string),
    earliestReleaseDate: nullable(t.string),
    hasDisplayGenre: nullable(t.string),
    genreDisplay: nullable(t.array(t.string)),
    hasAttribute: nullable(t.string),
    metadata: nullable(LockCodec),
    storylines: nullable(LockCodec),
    relationships: nullable(LockCodec),
})
export interface FeatureResult extends t.TypeOf<typeof FeatureResultCodec> {}

export const EpisodeResultCodec = t.type({
    highlight: t.string,
    cwmClassType: t.keyof(cwm.CWM_EPISODE_CLASSES),
    cwmClassTypeLabel: t.string,
    productId: nullable(t.string),
    lockStatuses: nullable(LockStatusesCodec),
    publishedDate: nullable(t.string),
    isPublished: nullable(t.boolean),

    hasDescriptors: nullable(t.boolean),
    hasRelationships: nullable(t.boolean),
    hasStorylines: nullable(t.boolean),

    episodeId: t.string,
    episodeTitle: t.string,
    seriesId: nullable(t.string),
    seriesTitle: nullable(t.string),

    seasonNumber: nullable(t.string),
    episodeNumber: nullable(t.string),

    titleNameDisambiguated: t.string,
    releaseDate: nullable(t.string),
    earliestReleaseDate: nullable(t.string),
    hasDisplayGenre: nullable(t.string),
    genreDisplay: nullable(t.array(t.string)),
    hasAttribute: nullable(t.string),
    metadata: nullable(LockCodec),
    storylines: nullable(LockCodec),
    relationships: nullable(LockCodec),
})
export interface EpisodeResult extends t.TypeOf<typeof EpisodeResultCodec> {}

export const SeriesResultCodec = t.type({
    highlight: t.string,
    cwmClassType: t.keyof(cwm.CWM_SERIES_CLASSES),
    cwmClassTypeLabel: t.string,
    productId: nullable(t.string),
    lockStatuses: nullable(LockStatusesCodec),
    publishedDate: nullable(t.string),
    isPublished: nullable(t.boolean),

    hasDescriptors: nullable(t.boolean),
    hasRelationships: nullable(t.boolean),
    hasStorylines: nullable(t.boolean),

    seriesId: t.string,
    seriesTitle: t.string,

    seasonNumber: nullable(t.string),
    episodeNumber: nullable(t.string),

    titleNameDisambiguated: t.string,
    releaseDate: nullable(t.string),
    earliestReleaseDate: nullable(t.string),
    hasDisplayGenre: nullable(t.string),
    genreDisplay: nullable(t.array(t.string)),
    hasAttribute: nullable(t.string),
    metadata: nullable(LockCodec),
    storylines: nullable(LockCodec),
    relationships: nullable(LockCodec),
})
export interface SeriesResult extends t.TypeOf<typeof SeriesResultCodec> {}

export const SeasonOfSeriesResultCodec = t.type({
    seasonId: t.string,
    highlight: t.string,
    cwmClassType: t.string,
    cwmClassTypeLabel: t.string,
    lockStatuses: nullable(LockStatusesCodec),
    productId: nullable(t.string),
    publishedDate: nullable(t.string),
    isPublished: nullable(t.boolean),

    hasDescriptors: nullable(t.boolean),
    hasRelationships: nullable(t.boolean),
    hasStorylines: nullable(t.boolean),

    displayType: nullable(t.string),
    seasonTitle: nullable(t.string),
    lock: nullable(t.string),
    status: nullable(t.string),

    seasonNumber: nullable(t.string),
    episodeNumber: nullable(t.string),

    titleNameDisambiguated: t.string,
    releaseDate: nullable(t.string),
    earliestReleaseDate: nullable(t.string),
    hasDisplayGenre: nullable(t.string),
    genreDisplay: nullable(t.array(t.string)),
    hasAttribute: nullable(t.string),
    metadata: nullable(LockCodec),
    storylines: nullable(LockCodec),
    relationships: nullable(LockCodec),
})

export interface SeasonOfSeriesResult extends t.TypeOf<typeof SeasonOfSeriesResultCodec> {}

// ES results
export const ESResultCodec = t.any

export interface ESResult extends t.TypeOf<typeof ESResultCodec> {}

export interface SearchResult {
    id: string
    genomeObject: FeatureResult | SeriesResult | EpisodeResult | SeasonOfSeriesResult | ESResult
    highlights?: { [key in SupportedFacet]: string }
    workflows: Array<Workflow> | false
}

export const SearchResultCodec: t.Type<SearchResult> = t.intersection([
    t.type({
        id: t.string,
        genomeObject: t.union([
            cwm
                .CWMTypeLabelCodec()
                .pipe(FeatureResultCodec, FeatureResultCodec.name) as t.Type<FeatureResult>,
            cwm
                .CWMTypeLabelCodec()
                .pipe(EpisodeResultCodec, EpisodeResultCodec.name) as t.Type<EpisodeResult>,
            cwm
                .CWMTypeLabelCodec()
                .pipe(SeriesResultCodec, SeriesResultCodec.name) as t.Type<SeriesResult>,
            cwm
                .CWMTypeLabelCodec()
                .pipe(
                    SeasonOfSeriesResultCodec,
                    SeasonOfSeriesResultCodec.name,
                ) as t.Type<SeasonOfSeriesResult>,
            t.any,
        ]),
        workflows: fromNullable(t.boolean, false) as any, // hack to initialize to false
    }),
    t.partial({
        highlights: t.any,
    }),
])

export const SearchResultPayloadCodec = t.type({
    totalHits: t.number,
    results: fromNullable(t.array(SearchResultCodec), []),
})

export type SearchResultPayload = t.TypeOf<typeof SearchResultPayloadCodec>

export function getTitle(obj: SearchResult['genomeObject']): string {
    if (EpisodeResultCodec.is(obj))
        return obj.seriesTitle ? `${obj.episodeTitle} (${obj.seriesTitle})` : obj.episodeTitle

    if (FeatureResultCodec.is(obj)) return obj.featureTitle
    if (SeriesResultCodec.is(obj)) return obj.seriesTitle
    if (SeasonOfSeriesResultCodec.is(obj)) return `${obj.seasonTitle} (${obj.displayType})`

    return getESResultTitle(obj)
}

export function getResourceId(searchResult: SearchResult): string {
    const { genomeObject: obj } = searchResult
    if (EpisodeResultCodec.is(obj)) return obj.episodeId
    if (FeatureResultCodec.is(obj)) return obj.featureId
    if (SeriesResultCodec.is(obj)) return obj.seriesId
    if (SeasonOfSeriesResultCodec.is(obj)) return `${obj.seasonId}`

    return getESResourceId(obj)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getESResultTitle(obj: any): string {
    return obj.titleNameDisambiguated
}

export function getESResourceId(searchResult: SearchResult): string {
    return searchResult.id
}
