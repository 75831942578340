import React from 'react'

import { FormikTRSSelect, ImplementerFormikTRSSelectProps } from 'shared/components/TRS/TRSSelect'
import { useTRSSimpleFind, TRS_STORY_ARCHETYPES } from 'shared/hooks/useTRS'
import { StorylineAttribute } from 'codecs/storyline/StorylineAttribute'
import { usePureFn } from 'shared/hooks/usePureFn'
import { TaxonomyTermWithBroaderId } from './Metadata/StaticAttributeSelect'
import { TaxonomyTerm } from 'codecs/TRS/Taxonomy'

export type StorylineArchetypeSelectProps = ImplementerFormikTRSSelectProps<StorylineAttribute>
export const StorylineArchetypeSelect: React.FC<StorylineArchetypeSelectProps> =
    (): React.ReactElement => {
        const { terms } = useTRSSimpleFind(TRS_STORY_ARCHETYPES, true)

        const termToOption = React.useCallback(
            (term: TaxonomyTermWithBroaderId): StorylineAttribute => ({
                id: term.id,
                label: term.label,
                broaderId: term.broaderId,
            }),
            [],
        )
        const getOptionValue = usePureFn((attribute: StorylineAttribute) => attribute.id)
        const getOptionLabel = usePureFn((attribute: StorylineAttribute, term?: TaxonomyTerm) =>
            term ? term.label : attribute.label,
        )

        return (
            <FormikTRSSelect<StorylineAttribute>
                name="storylineArchetypes"
                placeholder="Select storyline elements"
                terms={terms}
                termToOption={termToOption}
                isMulti={true}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
            />
        )
    }

export default StorylineArchetypeSelect
