import React, { useCallback, useState } from 'react'
import { Form, Formik } from 'formik'

import { TitleAssociation } from 'model/characters/Portrayal'

import Text from 'shared/components/Text'
import ErrorMessage from 'shared/components/ErrorMessage'

import { FormikPortrayalTypeSelect } from './PortrayalTypeSelect'
import { FormikPortrayedBy } from './PortrayedBySearch'
import { FormikPortrays } from './PortraysSearch'
import { FormikInput } from '../Input'
import { PortrayalDetailsViewMode, PortrayalDetailsViewStage } from './PortrayalDetailsModal'
import Button from '../Button'
import { ErrorText } from './ErrorText'

import { FormikPortrayalRoleSelect } from './PortrayalRoleSelect'
import { PortrayalFormSchema } from './validationSchemas'
import { UNKNOWN_FIELD_LABEL } from './constants'
import { PortrayalFormInProgressState } from './types'

import styled from 'shared/theme'

export type ArrField = { uri: string; label: string; mdmId?: string | undefined }

const AssocList = styled.ul`
    color: ${props => props.theme.colors.textSecondary};
    list-style: none;
    line-height: 1.5rem;
    text-decoration: underline;
`

export const PortrayalForm: React.FC<{
    associations: TitleAssociation[]
    initialValues: PortrayalFormInProgressState
    isLoadingMeta: boolean
    isLoadingMetaError: boolean
    isSaving: boolean
    isSavingError: boolean
    areControlsDisabled: boolean
    mode: PortrayalDetailsViewMode
    stage: PortrayalDetailsViewStage

    onBack: () => void
    onEditingModeChange: (enabled: boolean) => void
    onModalClose: () => void
    onSubmit: (portrayal: PortrayalFormInProgressState) => void
    loadMetadata: (portrayalId: string) => void
    isPortraysMdmSearchOn: boolean
    onPortraysSearchModesChange: (value: boolean) => void
    isPortrayedByMdmSearchOn: boolean
    onPortrayedBySearchModeChange: (value: boolean) => void
}> = ({
    associations,
    areControlsDisabled,
    initialValues,
    isLoadingMeta,
    isLoadingMetaError,
    isSaving,
    mode,
    stage,
    onBack,
    onEditingModeChange,
    onSubmit,
    loadMetadata,
    isPortraysMdmSearchOn,
    onPortraysSearchModesChange,
    isPortrayedByMdmSearchOn,
    onPortrayedBySearchModeChange,
}) => {
    const [formBeforeSubmit, setFormBeforeSubmit] = useState<PortrayalFormInProgressState>({
        ...initialValues,
    })

    const handleSubmit = useCallback(
        (form: PortrayalFormInProgressState) => {
            setFormBeforeSubmit(form)
            onSubmit(form)
        },
        [setFormBeforeSubmit, onSubmit],
    )

    return (
        <Formik<PortrayalFormInProgressState>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={PortrayalFormSchema}
            validateOnMount={true}
            enableReinitialize={true}
        >
            {({ values, resetForm }) => (
                <Form>
                    <div>
                        {/* <pre>{JSON.stringify(values)}</pre> */}
                        <Text size="5" weight="bold" as="h5">
                            Portrayal Type
                        </Text>
                        <FormikPortrayalTypeSelect disabled={areControlsDisabled} />

                        <FormikPortrays
                            disabled={areControlsDisabled}
                            isMdmSearchModeOn={isPortraysMdmSearchOn}
                            onSearchModeChange={onPortraysSearchModesChange}
                        />

                        <FormikPortrayedBy
                            disabled={areControlsDisabled}
                            isMdmSearchModeOn={isPortrayedByMdmSearchOn}
                            onSearchModeChange={onPortrayedBySearchModeChange}
                        />

                        <Text size="5" weight="bold" as="h5">
                            Role (has scope)
                        </Text>
                        <FormikPortrayalRoleSelect disabled={areControlsDisabled} />

                        <Text size="5" weight="bold" as="h5">
                            Portrayal Name
                        </Text>
                        <div style={{ marginBottom: '1rem' }}>
                            {!areControlsDisabled ? (
                                <div>
                                    <FormikInput
                                        fastValidate
                                        name="portrayal.portrayalName"
                                        placeholder="Portrayal Name"
                                        style={{ width: '100%' }}
                                    />
                                    <ErrorMessage name="portrayal.portrayalName" />
                                </div>
                            ) : (
                                <div>{values.portrayal.portrayalName}</div>
                            )}
                        </div>

                        <PortrayalMetadataLoading
                            isLoadingMeta={isLoadingMeta}
                            isLoadingMetaError={isLoadingMetaError}
                            portrayalId={values.portrayal.portrayalId}
                            loadMetadata={loadMetadata}
                        />

                        {!isLoadingMeta && !!associations.length && (
                            <>
                                <Text size="5" weight="bold" as="h5">
                                    Is Associated With
                                </Text>
                                <AssocList>
                                    {associations.map(a => {
                                        return (
                                            <li key={a.titleUri}>
                                                {a.titleName || UNKNOWN_FIELD_LABEL}
                                            </li>
                                        )
                                    })}
                                </AssocList>
                            </>
                        )}
                    </div>

                    <div>
                        <div style={{ textAlign: 'right' }}>
                            {mode === PortrayalDetailsViewMode.ReadWrite ? (
                                <>
                                    {!areControlsDisabled && (
                                        <>
                                            <Button
                                                variant="primary"
                                                size="small"
                                                onClick={() => {
                                                    resetForm({ values: formBeforeSubmit })
                                                    onEditingModeChange(false)
                                                }}
                                                disabled={isSaving}
                                                style={{ marginLeft: 'auto' }}
                                            >
                                                Cancel Editing
                                            </Button>
                                            <Button
                                                variant="outline"
                                                size="small"
                                                style={{ marginLeft: 'auto' }}
                                                type="submit"
                                                disabled={
                                                    isSaving || isLoadingMeta || isLoadingMetaError
                                                }
                                                isLoading={isSaving}
                                            >
                                                Save
                                            </Button>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Button
                                    variant="primary"
                                    size="small"
                                    onClick={onBack}
                                    style={{ marginLeft: 'auto' }}
                                    disabled={isSaving}
                                    isLoading={isSaving}
                                >
                                    Back
                                </Button>
                            )}

                            {stage === PortrayalDetailsViewStage.Associate && (
                                <Button
                                    variant="outline"
                                    size="small"
                                    style={{ marginLeft: 'auto' }}
                                    type="submit"
                                    disabled={isSaving || isLoadingMeta || isLoadingMetaError}
                                    isLoading={isSaving}
                                >
                                    Associate
                                </Button>
                            )}

                            {stage === PortrayalDetailsViewStage.CreateNew && (
                                <Button
                                    variant="outline"
                                    size="small"
                                    style={{ marginLeft: 'auto' }}
                                    type="submit"
                                    disabled={isSaving}
                                    isLoading={isSaving}
                                >
                                    Save
                                </Button>
                            )}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export const PortrayalMetadataLoading: React.FC<{
    isLoadingMeta: boolean
    isLoadingMetaError: boolean
    portrayalId: string | undefined
    loadMetadata(portrayalId: string): void
}> = ({ isLoadingMeta, isLoadingMetaError, portrayalId, loadMetadata }) => {
    return (
        <>
            {isLoadingMetaError && (
                <>
                    <ErrorText style={{ marginBottom: '1rem' }}>
                        Failed to load portrayals metadata
                    </ErrorText>
                    <Button
                        style={{ margin: 0 }}
                        variant="primary"
                        size="small"
                        onClick={() => {
                            if (portrayalId) {
                                loadMetadata(portrayalId)
                            }
                        }}
                    >
                        Retry
                    </Button>
                </>
            )}

            {isLoadingMeta && (
                <div style={{ textAlign: 'center' }}>
                    <span>Loading metadata...</span>
                </div>
            )}
        </>
    )
}
