import React, { useMemo, useCallback } from 'react'
import Container from 'shared/components/Container'
import { range } from 'lodash'
import styled from 'shared/theme'

const PaginationButtonsContainer = styled(Container)`
    position: sticky;
    bottom: 15px;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 3px;
    width: fit-content;
    padding: 0.5rem 1.5rem;
    margin: 1rem auto 0;
`
const TablePaginationButton = styled.button`
    cursor: pointer;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 2px;
    margin: 0 0.15rem;
    padding: 0.45rem;
`
const TablePaginationNumber = styled.button<{ selected: boolean; isDots: boolean }>`
    display: block;
    cursor: ${props => (props.isDots ? 'unset' : 'pointer')};
    background-color: ${props => (props.selected ? props.theme.colors.border : 'transparent')};
    border: 1px solid ${props => (props.selected ? props.theme.colors.border : 'transparent')};
    border-radius: 50%;
    height: 2.4rem;
    width: 2.4rem;
    margin: 0 0.15rem;
    padding-bottom: 3px;
`

export type PaginationOptions = {
    currentPage: number
    totalPages: number
}

type TablePaginationProps = {
    canPreviousPage: boolean
    canNextPage: boolean
    pageCount: number
    gotoPage: (pageIndex: number) => void
    nextPage: () => void
    previousPage: () => void
    pageIndex: number
}
const TablePagination = ({
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    pageIndex,
}: TablePaginationProps): React.ReactElement | null => {
    const gotoPageCB = useCallback(page => gotoPage(page), [gotoPage])
    const previousPageCB = useCallback(() => previousPage(), [previousPage])
    const nextPageCB = useCallback(() => nextPage(), [nextPage])

    const paginationRange: ('...' | number)[] = useMemo(() => {
        const SIBLING_COUNT = 1
        const maxPaginationNumbers = SIBLING_COUNT + 5

        if (maxPaginationNumbers >= pageCount) return range(0, pageCount)

        const leftSiblingIdx = Math.max(pageIndex - SIBLING_COUNT, 0)
        const rightSiblingIdx = Math.min(pageIndex + SIBLING_COUNT, pageCount)
        const showLeftDots = leftSiblingIdx > 2
        const showRightDots = rightSiblingIdx < pageCount - 1

        const firstPageIdx = 0

        if (!showLeftDots && showRightDots) {
            const leftItemCount = 3 + 2 * SIBLING_COUNT
            const leftRange = range(0, leftItemCount)
            return [...leftRange, '...', pageCount - 1]
        }

        if (showLeftDots && !showRightDots) {
            const rightItemCount = 3 + 2 * SIBLING_COUNT
            const rightRange = range(pageCount - rightItemCount, pageCount)
            return [firstPageIdx, '...', ...rightRange]
        }

        if (showLeftDots && showRightDots) {
            const middleRange = range(leftSiblingIdx, rightSiblingIdx)
            return [firstPageIdx, '...', ...middleRange, '...', pageCount - 1]
        }

        return range(0, pageCount)
    }, [pageCount, pageIndex])

    return pageCount ? (
        <PaginationButtonsContainer justifyContent={'center'} alignItems={'center'} mt={1} mb={2}>
            <TablePaginationButton onClick={() => gotoPageCB(0)} disabled={!canPreviousPage}>
                {'<<'}
            </TablePaginationButton>
            <TablePaginationButton onClick={() => previousPageCB()} disabled={!canPreviousPage}>
                {'<'}
            </TablePaginationButton>
            {paginationRange.map(page => (
                <TablePaginationNumber
                    key={`btn-num-${page}`}
                    onClick={() => {
                        if (typeof page === 'string') return
                        gotoPageCB(page)
                    }}
                    selected={pageIndex === page}
                    isDots={typeof page === 'string'}
                >
                    {page === '...' ? page : page + 1}
                </TablePaginationNumber>
            ))}
            <TablePaginationButton onClick={() => nextPageCB()} disabled={!canNextPage}>
                {'>'}
            </TablePaginationButton>
            <TablePaginationButton
                onClick={() => gotoPageCB(pageCount - 1)}
                disabled={!canNextPage}
            >
                {'>>'}
            </TablePaginationButton>
        </PaginationButtonsContainer>
    ) : null
}

export default TablePagination
