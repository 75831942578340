import {
    PERMISSION_DEBUG,
    PERMISSION_PUBLISH,
    PERMISSION_READ,
    PERMISSION_WRITE,
    PERMISSION_APPROVE,
    UserPermission,
} from '@genome-web-forms/common/auth'
import { WorkflowAssignmentTableType } from 'api/workflow/workflow'
import { AuthContext } from 'auth/Auth'
import React from 'react'
import { hasPermission } from '../UserHasPermission'

type WorkflowSearchTab = {
    id: string
    label: string
    type: WorkflowAssignmentTableType
    permissions: UserPermission[]
}

const availableTabs: WorkflowSearchTab[] = [
    {
        id: 'tab-0',
        label: 'My Workflows',
        type: 'SELF',
        permissions: [PERMISSION_READ],
    },
    {
        id: 'tab-1',
        label: 'Available for Tagging',
        type: 'TAGGING',
        permissions: [PERMISSION_READ, PERMISSION_WRITE],
    },
    {
        id: 'tab-2',
        label: 'Available for QA',
        type: 'QA',
        permissions: [PERMISSION_READ, PERMISSION_WRITE, PERMISSION_PUBLISH],
    },
    {
        id: 'tab-3',
        label: 'Available for Approval',
        type: 'APPROVAL',
        permissions: [PERMISSION_READ, PERMISSION_APPROVE],
    },
    // {
    //     id: 'tab-4',
    //     label: 'Ready for Delivery',
    //     type: 'DELIVERY',
    //     permissions: [PERMISSION_READ, PERMISSION_WRITE, PERMISSION_PUBLISH],
    // },
    { id: 'tab-5', label: 'All Workflows', type: 'ALL', permissions: [PERMISSION_DEBUG] },
]

export const useWorkflowAvailableRoleTabs = (): WorkflowSearchTab[] => {
    const { user } = React.useContext(AuthContext)

    return React.useMemo(
        () =>
            availableTabs.filter(({ permissions }) =>
                permissions.every(permission => hasPermission(user, permission)),
            ),
        [user],
    )
}
