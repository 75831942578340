import React, { createRef } from 'react'
import { CastMember } from 'codecs/CastMember'

import { AlertDialogButtons, AlertDialog, AlertDialogLabel } from 'shared/components/AlertModal'
import TextualButton from 'shared/components/TextualButton'
import Button from 'shared/components/Button'
import { usePortrayalsContext } from './portrayalRowsContext'

export const UnlinkPortrayalConfirmationModal: React.FC<{
    portrayal: CastMember
    isOpen: boolean
    onCancel: () => void
}> = ({ isOpen, portrayal, onCancel }) => {
    const { unlinkPortrayal } = usePortrayalsContext()

    const cancelRef = createRef<HTMLButtonElement>()

    const handleConfirmationClick = React.useCallback(() => {
        unlinkPortrayal(portrayal.portrayal.portrayalId, portrayal)
        onCancel()
    }, [onCancel, unlinkPortrayal, portrayal])

    return (
        <AlertDialog isOpen={isOpen} onDismiss={onCancel} leastDestructiveRef={cancelRef}>
            <AlertDialogLabel>
                Are you sure you want to unlink "{portrayal.portrayal.portrayalName}"?
            </AlertDialogLabel>

            <AlertDialogButtons>
                <Button variant="primary" onClick={handleConfirmationClick}>
                    Yes
                </Button>
                <TextualButton type="button" onClick={onCancel}>
                    Cancel
                </TextualButton>
            </AlertDialogButtons>
        </AlertDialog>
    )
}
