import {
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS,
} from '@genome-web-forms/server'

// NTD: Use for workflow tasks only (locks have three values instead)
export const workflowTasksTypeLabels: { [x: string]: string } = {
    [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA]: 'Descriptors',
    [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS]: 'Storylines / Relationships',
    [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS]: 'Portrayals',
}

export default workflowTasksTypeLabels
