import React from 'react'
import uniqueId from 'lodash/uniqueId'

import { useField } from 'formik'

import { Props, Content, Label, Checkbox, TextWrapper, Wrapper } from './common'
import { ReactComponent as Icon } from './icon.svg'

type CheckboxProps = Props & {
    id?: string
    value?: string
    checked?: boolean
}
const CheckboxInput: React.FC<CheckboxProps> = ({ children, id, checked, ...rest }) => {
    const [field, meta] = useField({ ...rest, type: 'checkbox' })
    const [_id] = React.useState(() => id || uniqueId('checkbox-'))

    return (
        <Wrapper>
            <Content error={!!meta.error}>
                <Checkbox type="checkbox" id={_id} {...field} {...rest} checked={checked} />
                <Label htmlFor={_id} error={!!meta.error}>
                    <Icon />{' '}
                </Label>
                <label htmlFor={_id}>
                    <TextWrapper>{children}</TextWrapper>
                </label>
            </Content>
        </Wrapper>
    )
}

export default CheckboxInput
