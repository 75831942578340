import React, { useState, useCallback, useMemo, ChangeEvent } from 'react'
import { TableInstance } from 'react-table'
import { Formik } from 'formik'

import cloneDeep from 'lodash/cloneDeep'
import noop from 'lodash/noop'

import { CastMember } from 'codecs/CastMember'

import Container from 'shared/components/Container'
import SearchInput from 'shared/components/SearchInput'
import Table from 'shared/components/Table'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import Text from 'shared/components/Text'
import {
    getWIPData,
    useFormControlsEnabled,
    usePreviewModeDiff,
    useViewMode,
    useWIPCharactersActor,
} from 'shared/resource/ResourceMachineProvider'
import { useHasDEIRole, useHasLibReadRole, useHasLibWriteRole } from 'shared/hooks/useHasRole'

import Button from 'shared/components/Button'
import CharactersRow from 'pages/Feature/Characters/CharactersRow'
import { CastTableDiff } from './Characters/CastDiff'
import { CharacterCommentsModal } from './Characters/CharacterCommentsModal'
import { CharacterCommentsProvider } from './Characters/characterCommentsContext'
import { PortrayalsProvider, usePortrayalsContext } from './Characters/portrayalRowsContext'
import { controlsCelDef, editableColumnsDef, staticColumnsDef } from './Characters/PortrayalCells'
import { isInlineEdit } from './Characters/utils'
import {
    PortrayalDetailsModal,
    PortrayalDetailsViewMode,
    PortrayalsDetailsModalState,
    PortrayalDetailsViewStage,
} from './Characters/PortrayalDetailsModal'
import SaveIndicator from './SaveIndicator'
import { UnlinkPortrayalConfirmationModal } from './Characters/UnlinkPortrayalConfirmationModal'
import { PortrayalType } from './Characters/constants'
import { useIsPortrayalsEditingAllowed, useHasWIPData } from 'shared/resource/WIPInfo'
import { ReactComponent as Warning } from 'shared/components/Icons/warning-icon.svg'
import styled from 'shared/theme'

const initialTableState = {
    sortBy: [{ id: 'Portrayed By' }, { id: 'Portrays' }],
    hiddenColumns: ['portrayalFilter'],
    filters: [{ id: 'portrayalFilter', value: null }],
}

type CastProps = {
    label?: string
    data: CastMember[]
}

type CastTableProps = CastProps & {
    searchText: string
    canReadWriteAttributes: boolean
    hasLibReadRole: boolean
    hasLibWriteRole: boolean
    isPortrayalsEditingAllowed: boolean
    hasWIPData: boolean
    onPortrayalModalChange: (state: PortrayalsDetailsModalState) => void
}

// Should not use characters wip actors inside the component
// Because most of actors state checks relies on existance of an actor
const CastTable: React.FC<CastTableProps> = ({
    data = [],
    label = 'Cast',
    searchText,
    canReadWriteAttributes,
    hasLibReadRole,
    hasLibWriteRole,
    isPortrayalsEditingAllowed,
    hasWIPData,
    onPortrayalModalChange,
}) => {
    const columns = useMemo(() => {
        if (hasLibReadRole) {
            return [...editableColumnsDef, controlsCelDef]
        } else {
            return [...staticColumnsDef]
        }
    }, [hasLibReadRole])

    return (
        <Table<CastMember>
            {...{
                data,
                columns: columns,
                initialState: initialTableState,
                searchText,
                canReadWriteAttributes,
                hasLibReadRole,
                hasLibWriteRole,
                isPortrayalsEditingAllowed,
                hasWIPData,
                RowComponent: CharactersRow,
                onPortrayalModalChange,
            }}
            hasRowHover={true}
            emptyMessage={`No ${label.toLocaleLowerCase()} added yet`}
        />
    )
}

type EditableCastTableProps = CastProps & {
    searchText: string
    canReadWriteAttributes: boolean
    hasLibReadRole: boolean
    hasLibWriteRole: boolean
    isPortrayalsEditingAllowed: boolean
    hasWIPData: boolean
    onTableInstInit(tbl: TableInstance<any>): void
    onAllRowsExpandedChange(areAllRowsExpanded: boolean): void
    onCommentsBtnClick(castMember: CastMember): void
    onPortrayalModalChange: (state: PortrayalsDetailsModalState) => void
    onUnlinkPortrayal: (castMember: CastMember) => void
}

const EditableCastTable: React.FC<EditableCastTableProps> = ({
    data: characters = [],
    label = 'Cast',
    searchText,
    canReadWriteAttributes,
    hasLibReadRole,
    hasLibWriteRole,
    isPortrayalsEditingAllowed,
    hasWIPData,
    onTableInstInit,
    onAllRowsExpandedChange,
    onCommentsBtnClick,
    onPortrayalModalChange,
    onUnlinkPortrayal,
}) => {
    const [wipState, send] = useWIPCharactersActor<CastMember[]>()
    const wip = getWIPData(useViewMode(), wipState)
    const formControlsEnabled = useFormControlsEnabled('characters')
    const { isSaving } = usePortrayalsContext()

    const [data, setData] = React.useState<CastMember[]>([])

    // Table component has a max of 10 updates then it triggers warning.
    // Attributes is where data is updating constantly
    React.useEffect(() => {
        setData(wip || characters)
    }, [wip, characters])

    const handleUpdateCharacter = React.useCallback(
        (updatedCharacter: CastMember, index: number) => {
            const updatedCharacters: CastMember[] = cloneDeep(data)
            updatedCharacters[index] = updatedCharacter
            if (updatedCharacter.portrayal.portrayalType?.uri === PortrayalType.ByTwins) {
                const twinPortrayal = updatedCharacters.find(
                    (p, idx) =>
                        p.portrayal.portrayalId === updatedCharacter.portrayal.portrayalId &&
                        idx !== index,
                )
                if (twinPortrayal) {
                    const idx = updatedCharacters.indexOf(twinPortrayal)
                    updatedCharacters[idx] = cloneDeep({
                        ...twinPortrayal,
                        attributes: updatedCharacter.attributes,
                    })
                }
            }
            send({ type: 'UPDATE', data: updatedCharacters })
        },
        [data, send],
    )

    const columns = useMemo(() => {
        if (hasLibReadRole) {
            return [...editableColumnsDef, controlsCelDef]
        } else {
            return [...staticColumnsDef]
        }
    }, [hasLibReadRole])

    return (
        <>
            <Table<CastMember>
                {...{
                    data,
                    columns,
                    initialState: initialTableState,
                    searchText,
                    onCommentsBtnClick,
                    canReadWriteAttributes,
                    rowProps: { onUpdateCharacter: handleUpdateCharacter },
                    RowComponent: CharactersRow,
                    formControlsEnabled,
                    hasLibReadRole,
                    hasLibWriteRole,
                    isPortrayalsEditingAllowed,
                    hasWIPData,
                    onPortrayalModalChange,
                    onUnlinkPortrayal,
                }}
                hasRowHover={true}
                emptyMessage={`No ${label.toLocaleLowerCase()} added yet`}
                onTableInstInit={onTableInstInit}
                onAllRowsExpandedChange={onAllRowsExpandedChange}
            />
            {isSaving && <SaveIndicator />}
        </>
    )
}

type TableControlsProps = {
    areAllRowsExpanded: boolean
    hasDEIRole: boolean
    hasLibWriteRole: boolean
    label: string
    searchText: string
    isPortrayalEditingAllowed: boolean
    onSearchTextChange(e: ChangeEvent<HTMLInputElement>): void
    onExpandToggleClick(): void
    onPortrayalModalChange(state: PortrayalsDetailsModalState): void
}

const TableControls: React.FC<TableControlsProps> = ({
    areAllRowsExpanded,
    hasDEIRole,
    label,
    searchText,
    hasLibWriteRole,
    isPortrayalEditingAllowed,
    onSearchTextChange,
    onExpandToggleClick,
    onPortrayalModalChange,
}: TableControlsProps) => {
    const { portrayalInEdit, isSaving, setCurrentPortrayal } = usePortrayalsContext()
    const hasInlineEdit = isInlineEdit(portrayalInEdit)
    const handleCancelClick = useCallback(() => {
        setCurrentPortrayal(null)
    }, [setCurrentPortrayal])

    const handleAddPortrayalBtnClick = useCallback(() => {
        onPortrayalModalChange({
            open: true,
            portrayal: null,
            mode: PortrayalDetailsViewMode.Adding,
            stage: PortrayalDetailsViewStage.Search,
        })
    }, [onPortrayalModalChange])

    return (
        <Container alignItems="center" style={{ justifyContent: 'space-between' }}>
            <TableHeaderContent>
                <Text as="h3" size="2" my="2">
                    {label}
                </Text>
                <SearchInput value={searchText} onChange={onSearchTextChange} />
                {hasDEIRole && (
                    <Button variant="outline" size="small" onClick={onExpandToggleClick}>
                        {areAllRowsExpanded ? 'Collapse Rows' : 'Expand Rows'}
                    </Button>
                )}
                {hasLibWriteRole && !hasInlineEdit && isPortrayalEditingAllowed && (
                    <Button
                        variant="primary"
                        size="small"
                        onClick={handleAddPortrayalBtnClick}
                        style={{ marginLeft: 'auto' }}
                        disabled={isSaving}
                    >
                        <span>+</span>
                        <span style={{ paddingLeft: '5px' }}>Add a Portrayal</span>
                    </Button>
                )}
                {hasLibWriteRole && hasInlineEdit && isPortrayalEditingAllowed && (
                    <Button
                        size="small"
                        type="button"
                        variant="outline"
                        onClick={handleCancelClick}
                        style={{ marginLeft: 'auto' }}
                    >
                        Cancel Editing
                    </Button>
                )}
            </TableHeaderContent>
        </Container>
    )
}

export const StyledInfo = styled(Warning)`
    fill: ${props => props.theme.colors.primary};
    cursor: default;
    max-width: 1.25rem;
    vertical-align: middle;
`

const Cast: React.FC<CastProps> = ({ data: characters = [], label = 'Cast' }) => {
    const [searchText, setSearchText] = React.useState('')
    const hasDEIRole = useHasDEIRole()
    const hasLibReadRole = useHasLibReadRole()
    const hasLibWriteRole = useHasLibWriteRole()
    const previewModeDiff = usePreviewModeDiff()
    const [tableInst, setTableInst] = useState<TableInstance | null>(null)
    const [areAllRowsExpanded, setAreAllRowsExpanded] = useState<boolean>(false)
    const isPortrayalsEditingAllowed = useIsPortrayalsEditingAllowed()
    const { hasData: hasWIPData, isLoading: isWIPLoading } = useHasWIPData()

    const [characterCommentsModal, setCharacterCommentsModal] = useState<{
        open: boolean
        castMember: CastMember | null
    }>({
        open: false,
        castMember: null,
    })

    const [portrayalDetailsModal, setPortrayalDetailsModal] = useState<PortrayalsDetailsModalState>(
        {
            open: false,
            portrayal: null,
            mode: PortrayalDetailsViewMode.None,
            stage: PortrayalDetailsViewStage.None,
        },
    )

    const handlePortrayalsDetailsModalChange = useCallback(
        (state: PortrayalsDetailsModalState) => {
            setPortrayalDetailsModal(state)
        },
        [setPortrayalDetailsModal],
    )

    const handleSearchTextChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value)
        },
        [setSearchText],
    )

    const handleAllRowsExpandedChange = useCallback(
        (areAllRowsExpanded: boolean) => {
            setAreAllRowsExpanded(areAllRowsExpanded)
        },
        [setAreAllRowsExpanded],
    )

    const handleTableInstSet = useCallback(
        (tbl: TableInstance) => {
            setTableInst(tbl)
        },
        [setTableInst],
    )

    const handleExpandCollapseClick = useCallback(() => {
        tableInst?.toggleAllRowsExpanded(!areAllRowsExpanded)
    }, [areAllRowsExpanded, tableInst])

    const handleCharactersCommentsModalOpen = useCallback(
        (castMember: CastMember) => {
            setCharacterCommentsModal({
                open: true,
                castMember,
            })
        },
        [setCharacterCommentsModal],
    )

    const handleCharactersCommentsModalClose = useCallback(() => {
        setCharacterCommentsModal({
            open: false,
            castMember: null,
        })
    }, [setCharacterCommentsModal])

    const [unlinkConfirmationPortrayalModal, setUnlinkConfirmationPortrayalModal] = useState<{
        open: boolean
        portrayal: CastMember | null
    }>({
        open: false,
        portrayal: null,
    })

    const handleUnlinkModalOpen = useCallback(
        (portrayal: CastMember) => {
            setUnlinkConfirmationPortrayalModal({
                open: true,
                portrayal,
            })
        },
        [setUnlinkConfirmationPortrayalModal],
    )

    const handleUnlinkModalClose = useCallback(() => {
        setUnlinkConfirmationPortrayalModal({
            open: false,
            portrayal: null,
        })
    }, [setUnlinkConfirmationPortrayalModal])

    return (
        <PortrayalsProvider characters={characters} canEditCharacters={hasDEIRole}>
            <CharacterCommentsProvider characters={characters} canEditCharacters={hasDEIRole}>
                <TableControls
                    areAllRowsExpanded={areAllRowsExpanded}
                    hasDEIRole={hasDEIRole}
                    hasLibWriteRole={hasLibWriteRole}
                    label={label}
                    searchText={searchText}
                    isPortrayalEditingAllowed={isPortrayalsEditingAllowed}
                    onExpandToggleClick={handleExpandCollapseClick}
                    onSearchTextChange={handleSearchTextChange}
                    onPortrayalModalChange={handlePortrayalsDetailsModalChange}
                />

                {!isPortrayalsEditingAllowed && !previewModeDiff && (
                    <p>
                        <StyledInfo />
                        Portrayal editing is not available when the tab is locked by another user or
                        is in the Character Attributes edit mode.
                    </p>
                )}

                {isPortrayalsEditingAllowed && !isWIPLoading && hasWIPData && !previewModeDiff && (
                    <p>
                        <StyledInfo />
                        Portrayal deletion is not available when the title has WIP data associated.
                    </p>
                )}

                <Formik<any> initialValues={{}} onSubmit={noop}>
                    {() =>
                        previewModeDiff ? (
                            <CastTableDiff
                                searchText={searchText}
                                onTableInstInit={handleTableInstSet}
                                onAllRowsExpandedChange={handleAllRowsExpandedChange}
                            />
                        ) : hasDEIRole || hasLibWriteRole ? (
                            <EditableCastTable
                                data={characters}
                                searchText={searchText}
                                canReadWriteAttributes={hasDEIRole}
                                hasLibReadRole={hasLibReadRole}
                                hasLibWriteRole={hasLibWriteRole}
                                isPortrayalsEditingAllowed={isPortrayalsEditingAllowed}
                                hasWIPData={hasWIPData || isWIPLoading}
                                onTableInstInit={handleTableInstSet}
                                onAllRowsExpandedChange={handleAllRowsExpandedChange}
                                onCommentsBtnClick={handleCharactersCommentsModalOpen}
                                onPortrayalModalChange={handlePortrayalsDetailsModalChange}
                                onUnlinkPortrayal={handleUnlinkModalOpen}
                            />
                        ) : (
                            <CastTable
                                data={characters}
                                searchText={searchText}
                                canReadWriteAttributes={hasDEIRole}
                                hasLibReadRole={hasLibReadRole}
                                hasLibWriteRole={hasLibWriteRole}
                                isPortrayalsEditingAllowed={isPortrayalsEditingAllowed}
                                hasWIPData={hasWIPData || isWIPLoading}
                                onPortrayalModalChange={handlePortrayalsDetailsModalChange}
                            />
                        )
                    }
                </Formik>
                {characterCommentsModal.castMember && (
                    <CharacterCommentsModal
                        character={characterCommentsModal.castMember}
                        isOpen={characterCommentsModal.open}
                        closeModal={handleCharactersCommentsModalClose}
                    />
                )}
                <PortrayalDetailsModal
                    state={portrayalDetailsModal}
                    onPortrayalModalChange={handlePortrayalsDetailsModalChange}
                    hasLibReadRole={hasLibReadRole}
                    hasLibWriteRole={hasLibWriteRole}
                    isPortrayalsEditingAllowed={isPortrayalsEditingAllowed}
                />
                {unlinkConfirmationPortrayalModal.portrayal && (
                    <UnlinkPortrayalConfirmationModal
                        isOpen={unlinkConfirmationPortrayalModal.open}
                        portrayal={unlinkConfirmationPortrayalModal.portrayal}
                        onCancel={handleUnlinkModalClose}
                    />
                )}
            </CharacterCommentsProvider>
        </PortrayalsProvider>
    )
}

export default Cast
