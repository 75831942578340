import React, { useCallback, useEffect, ChangeEvent, useState, memo } from 'react'
import { format } from 'date-fns'

import { MyIDUser, PERMISSION_DEBUG } from '@genome-web-forms/common/auth'

import { CastMember } from 'codecs/CastMember'

import LoaderSVG from 'shared/components/Loader/LoadingSVG'
import { Dialog } from 'shared/components/Dialog'
import TextualButton from 'shared/components/TextualButton'
import { StyledMultilineInput } from 'shared/components/Input'
import Button from 'shared/components/Button'
import { CharacterComment } from 'model/characters/CharacterComment'
import styled from 'shared/theme'
import { EditIconButton } from 'shared/components/Icons/EditIcon'
import { TrashBinButton } from 'shared/components/Icons/TrashBinIcon'
import { useUser } from 'auth/Auth'
import Container from 'shared/components/Container'
import { ReactComponent as CloseIcon } from 'shared/components/Icons/close-icon.svg'
import { useHasPermission } from 'shared/components/UserHasPermission'

import { ErrorText } from './ErrorText'
import { useCharacterCommentsContext } from './characterCommentsContext'

const CloseModalIconBtn = styled(CloseIcon)`
    position: fixed;
    cursor: pointer;
    right: 2rem;
    top: 1rem;
`

const CommentAttributes = styled.div`
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.textSecondary};
`

const Author = styled.span`
    color: ${({ theme }) => theme.colors.textPrimary};
    font-weight: bold;
`

const LastUpdated = styled.span`
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.textSecondary};
    padding-left: 10px;
`

const CommentAttrsGridLayout = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
`

type TCommentListItem = CharacterComment & {
    isEditing: boolean
    isDeletion: boolean
}

const canModify = (user: MyIDUser, comment: CharacterComment, isAdmin: boolean) => {
    return isAdmin || comment.authorId === user['relationship.employeeId']
}

const CharacterCommentListItem: React.FC<{
    comment: TCommentListItem
    modifiable: boolean
    onEditingStart(comment: TCommentListItem): void
    onEditingCancel(): void
    onDeletionStart(comment: TCommentListItem): void
    onDeletionCancel(): void
}> = ({
    comment,
    modifiable,
    onEditingStart,
    onEditingCancel,
    onDeletionStart,
    onDeletionCancel,
}) => {
    const [currentValue, setCurrentValue] = useState('')
    const {
        updateCharacterComment,
        isCommentUpdating,
        isCommentUpdateError,
        deleteCharacterComment,
        isCommentDeleting,
        isCommentDeletionError,
    } = useCharacterCommentsContext()

    const handleTextAreaChange = useCallback(
        (value: string) => {
            setCurrentValue(value)
        },
        [setCurrentValue],
    )

    useEffect(() => {
        setCurrentValue(comment.content)
    }, [comment, setCurrentValue])

    return (
        <>
            <CommentAttrsGridLayout>
                <CommentAttributes>
                    <div style={{ lineHeight: '1rem' }}>
                        <Author>{comment.authorName}</Author>
                        <LastUpdated>
                            {format(new Date(comment.updatedAt), 'yyyy-MM-dd hh:mm a')}
                        </LastUpdated>
                    </div>
                </CommentAttributes>
                {modifiable &&
                    !comment.isEditing &&
                    !comment.isDeletion &&
                    !isCommentDeleting &&
                    !isCommentUpdating && (
                        <div style={{ textAlign: 'right', lineHeight: '1rem' }}>
                            <EditIconButton
                                label="Edit"
                                style={{ verticalAlign: 'middle', marginRight: '1rem' }}
                                onClick={() => {
                                    onEditingStart(comment)
                                }}
                            />
                            <TrashBinButton
                                label="Delete"
                                style={{ verticalAlign: 'middle' }}
                                onClick={() => {
                                    onDeletionStart(comment)
                                }}
                            />
                        </div>
                    )}
            </CommentAttrsGridLayout>
            {comment.isEditing && (
                <div>
                    <StyledMultilineInput
                        disabled={!comment.isEditing}
                        value={currentValue}
                        onChange={(e: any) => {
                            handleTextAreaChange(e.target.value)
                        }}
                        rows={5}
                        maxLength={1000}
                    />
                </div>
            )}
            {!comment.isEditing && (
                <div style={{ whiteSpace: 'pre-wrap', lineHeight: '1rem' }}>{comment.content}</div>
            )}
            {comment.isEditing && (
                <Container p={1} style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        disabled={isCommentUpdating}
                        size="small"
                        type="button"
                        variant="outline"
                        onClick={() => {
                            onEditingCancel()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={
                            !currentValue || currentValue === comment.content || isCommentUpdating
                        }
                        isLoading={isCommentUpdating}
                        size="small"
                        type="button"
                        onClick={() => {
                            updateCharacterComment(
                                currentValue,
                                comment.characterId,
                                comment.noteId,
                            )
                        }}
                    >
                        {isCommentUpdating ? 'Saving...' : 'Save Comment'}
                    </Button>
                </Container>
            )}
            {comment.isDeletion && (
                <Container p={1} style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        disabled={isCommentDeleting}
                        size="small"
                        type="button"
                        variant="outline"
                        onClick={() => {
                            onDeletionCancel()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isCommentDeleting}
                        isLoading={isCommentDeleting}
                        size="small"
                        type="button"
                        variant="danger"
                        onClick={() => {
                            deleteCharacterComment(comment.characterId, comment.noteId)
                        }}
                    >
                        {isCommentDeleting ? 'Deleting...' : 'Delete'}
                    </Button>
                </Container>
            )}
            {comment.isEditing && isCommentUpdateError && (
                <ErrorText>
                    Error has occurred while updating the comments. Please try again.
                </ErrorText>
            )}
            {comment.isDeletion && isCommentDeletionError && (
                <ErrorText>
                    Error has occurred while deleting the comment. Please try again.
                </ErrorText>
            )}
        </>
    )
}

const CommentsList: React.FC<{
    comments: TCommentListItem[]
    user: MyIDUser
    onEditingStart(comment: TCommentListItem): void
    onEditingCancel(): void
    onDeletionStart(comment: TCommentListItem): void
    onDeletionCancel(): void
}> = ({ comments, user, onEditingStart, onEditingCancel, onDeletionStart, onDeletionCancel }) => {
    const isAdmin = useHasPermission(PERMISSION_DEBUG)

    return (
        <ul style={{ textAlign: 'left', listStyleType: 'none', paddingLeft: 0 }}>
            {comments.map(comment => {
                return (
                    <li key={comment.noteId} style={{ marginBottom: '1.5rem' }}>
                        <CharacterCommentListItem
                            comment={comment}
                            modifiable={canModify(user, comment, isAdmin)}
                            onEditingStart={onEditingStart}
                            onEditingCancel={onEditingCancel}
                            onDeletionStart={onDeletionStart}
                            onDeletionCancel={onDeletionCancel}
                        />
                    </li>
                )
            })}
        </ul>
    )
}

const MemoizedCommentsList = memo(CommentsList)

export const CharacterCommentsModal: React.FC<{
    closeModal: () => void
    isOpen: boolean
    character: CastMember
}> = ({ isOpen, character, closeModal }) => {
    const {
        portrayal: { portrayalName },
    } = character

    const [commentsList, setCommentsList] = useState<TCommentListItem[]>([])
    const [commentContent, setCommentContent] = useState('')
    const {
        fetchComments: loadComments,
        isLoadingComments,
        isLoadingCommentsError,
        comments,
        createCharacterComment,
        isCommentSaving,
        isCommentSavingError,
    } = useCharacterCommentsContext()

    const user = useUser()

    useEffect(() => {
        loadComments(character)
    }, [character, loadComments])

    useEffect(() => {
        setCommentsList(
            comments.map(comment => {
                return {
                    ...comment,
                    isEditing: false,
                    isDeletion: false,
                }
            }),
        )
    }, [comments, setCommentsList])

    const handleTextareaContentChange = useCallback(
        (e: ChangeEvent<HTMLTextAreaElement>) => {
            setCommentContent(e.target.value)
        },
        [setCommentContent],
    )

    const handleCommentCreation = useCallback(() => {
        createCharacterComment(commentContent, character).then(() => {
            setCommentContent('')
        })
    }, [commentContent, character, createCharacterComment, setCommentContent])

    const handleCommentEditing = useCallback(
        (commentToEdit: TCommentListItem) => {
            const newValue = comments.map(comment => {
                if (comment.noteId === commentToEdit.noteId) {
                    return { ...comment, isEditing: true, isDeletion: false }
                }
                return { ...comment, isEditing: false, isDeletion: false }
            })
            setCommentsList(newValue)
        },
        [comments, setCommentsList],
    )

    const handleCommentEditingCancel = useCallback(() => {
        const newValue = comments.map(comment => {
            return { ...comment, isEditing: false, isDeletion: false }
        })
        setCommentsList(newValue)
    }, [comments, setCommentsList])

    const handleCommentDeletion = useCallback(
        (commentToDelete: TCommentListItem) => {
            const newValue = comments.map(comment => {
                if (comment.noteId === commentToDelete.noteId) {
                    return { ...comment, isEditing: false, isDeletion: true }
                }
                return { ...comment, isEditing: false, isDeletion: false }
            })
            setCommentsList(newValue)
        },
        [comments, setCommentsList],
    )

    const handleCommentDeletionCancel = useCallback(() => {
        const newValue = comments.map(comment => {
            return { ...comment, isEditing: false, isDeletion: false }
        })
        setCommentsList(newValue)
    }, [comments, setCommentsList])

    return (
        <Dialog isOpen={isOpen} onDismiss={closeModal} aria-label="Character Comments">
            <CloseModalIconBtn onClick={closeModal} />
            <h1 style={{ marginBottom: '1rem' }}>Comments for {portrayalName}</h1>
            <div>
                <StyledMultilineInput
                    disabled={isCommentSaving}
                    value={commentContent}
                    onChange={handleTextareaContentChange}
                    placeholder="Add your comment here"
                    rows={5}
                    maxLength={1000}
                />
            </div>
            <div style={{ textAlign: 'right' }}>
                <Button
                    disabled={!commentContent || isCommentSaving}
                    isLoading={isCommentSaving}
                    size="small"
                    type="button"
                    onClick={handleCommentCreation}
                >
                    {'Add Comment'}
                </Button>
            </div>
            <div>
                {isLoadingComments && (
                    <span style={{ width: '50px', height: '50px', display: 'inline-block' }}>
                        <LoaderSVG />
                    </span>
                )}
                {isLoadingCommentsError && (
                    <ErrorText>Error has occurred while loading comments.</ErrorText>
                )}
                {isCommentSavingError && (
                    <ErrorText>
                        Error has occurred while saving the comment. Please, try again.
                    </ErrorText>
                )}
                {!isLoadingComments && !isLoadingCommentsError && !comments.length && (
                    <p>No comments were found for this character.</p>
                )}
                {!isLoadingComments && !isLoadingCommentsError && !!comments.length && (
                    <MemoizedCommentsList
                        comments={commentsList}
                        user={user}
                        onEditingStart={handleCommentEditing}
                        onEditingCancel={handleCommentEditingCancel}
                        onDeletionStart={handleCommentDeletion}
                        onDeletionCancel={handleCommentDeletionCancel}
                    />
                )}
            </div>

            <div>
                <TextualButton type="button" onClick={() => closeModal()}>
                    Close
                </TextualButton>
            </div>
        </Dialog>
    )
}
