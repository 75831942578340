import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { CellProps, Column } from 'react-table'
import Button from './Button'
import Container from './Container'
import TableHeaderContent from './Table/TableHeaderContent'

import Text from 'shared/components/Text'
import SearchInput from 'shared/components/SearchInput'
import Table, { CheckboxFilter, RowComponentDefault } from 'shared/components/Table'
import AssociationForm from './Associations/AssociationsForm'

import { AssociationsProvider, useAssociationsContext } from './Associations/AssociationsProvider'
import styled from 'styled-components'
import { Association, TAssociationsTaxonomy } from '../../model/Associations'
import { fetchAssociationsList } from './Associations/useAssociations'
import _ from 'lodash'
import { useUser } from 'auth/Auth'
import Loader from './Loader'
import { notification } from 'shared/notification'
import { TOP_CONCEPT_LIST } from './Associations/constants'

type AssociationsProps = {
    data?: Association[]
    searchText?: string
}

const AssociationsTableColumns: Column<any>[] = [
    {
        Header: 'Type',
        accessor: op => op.type.label,
        Cell: ({ row: { original: op } }: CellProps<any>) => {
            return op.type.label
        },
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    {
        Header: 'Name',
        accessor: op => op.type.associations,
        Cell: ({ row: { original: op } }: CellProps<any>) => {
            return <TaxonomyCell items={op.type.associations} />
        },
    },
]

const initialTableState = {
    sortBy: [{ id: 'Type' }],
}

export const Associations: React.FC<{ resourceId: string }> = ({ resourceId }) => {
    return (
        <AssociationsProvider resourceId={resourceId}>
            <AssociationsComponent />
        </AssociationsProvider>
    )
}

const AssociationsComponent: React.FC<AssociationsProps> = () => {
    const {
        associationsList,
        isAssociationInEditMode,
        association,
        isCreatingNew,
        setAssociationsList,
        editAssociation,
        createNew,
    } = useAssociationsContext()
    const [isLoading, setIsLoading] = useState(false)
    const [searchText, setSearchText] = useState('')
    const onSearchTextChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value)
        },
        [setSearchText],
    )

    const user = useUser()
    const { resourceId } = useAssociationsContext()

    useEffect(() => {
        const associationsListPromises = TOP_CONCEPT_LIST.map(({ typeURI, label }) => {
            return fetchAssociationsList(user, typeURI, resourceId).catch(e => {
                notification.error(`Fail to fetch ${label} with type ${typeURI}  `)
            })
        })

        setIsLoading(true)

        Promise.all(associationsListPromises)
            .then(data => {
                const list: Association[] = []

                data.forEach(item => {
                    if (!item || !item.associations.length) {
                        return
                    }

                    const mapped = _.find(TOP_CONCEPT_LIST, { typeURI: item.typeURI })
                    if (!mapped) return

                    list.push({
                        type: {
                            ...mapped,
                            associations: item.associations,
                        },
                    })
                })

                setAssociationsList(list)
            })
            .finally(() => setIsLoading(false))
    }, [user, resourceId, setIsLoading, setAssociationsList])

    return (
        <Container flexDirection={'column'}>
            <TableHeaderContent>
                <Text as="h3" size="2" my="2">
                    Associations
                </Text>
                <SearchInput value={searchText} onChange={onSearchTextChange} />

                <Button
                    variant="primary"
                    size="small"
                    onClick={createNew}
                    style={{ marginLeft: 'auto' }}
                >
                    <span>+</span>
                    <span style={{ paddingLeft: '5px' }}>Add Association</span>
                </Button>
            </TableHeaderContent>

            {isAssociationInEditMode || isCreatingNew ? (
                <AssociationForm association={association} />
            ) : null}

            {!isLoading && (
                <Container mt={2} flexDirection={'column'}>
                    <Table<any>
                        {...{
                            data: associationsList,
                            searchText,
                            initialState: initialTableState,
                            columns: AssociationsTableColumns,
                            RowComponent: RowComponentDefault,
                            onRowClick: ({ row: { original: association } }) => {
                                editAssociation(association)
                            },
                        }}
                        hasRowHover={true}
                        emptyMessage={`No Associations added yet`}
                    />
                </Container>
            )}
            {isLoading && <Loader center size="normal" />}
        </Container>
    )
}

const TaxonomyContainer = styled.div`
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: auto min-content;
`

const TaxonomyRows = styled.div`
    display: grid;
    grid-row-gap: 0.5rem;
`
const TaxonomyText = styled(Text).attrs<{}>({
    size: 5,
    variant: 'secondary',
})`
    padding: 0.25rem 0;
`

const TaxonomyCell: React.FC<{ items: TAssociationsTaxonomy }> = ({
    items,
}): React.ReactElement => {
    if (!Array.isArray(items)) {
        return <TaxonomyText>{items.label}</TaxonomyText>
    }
    return (
        <TaxonomyRows>
            {items.map(i => {
                return (
                    <TaxonomyContainer key={`${i.uri}-${i.label}`}>
                        <TaxonomyText>{i.label}</TaxonomyText>
                    </TaxonomyContainer>
                )
            })}
        </TaxonomyRows>
    )
}
