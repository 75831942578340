import React, { useEffect } from 'react'
import { LinkProps } from 'react-router-dom'

import {
    useTable,
    useSortBy,
    useExpanded,
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    Column,
    TableOptions,
    TableRowComponentProps,
    CellProps,
    TableInstance,
    usePagination,
} from 'react-table'

import isFunction from 'lodash/isFunction'
import noop from 'lodash/noop'
import Empty from 'shared/components/Empty'
import { useStableParamsWarning } from 'shared/hooks/useStableParamsWarning'
import Optional from 'shared/components/Optional'
import TablePagination from 'shared/components/TablePagination'

import CheckboxListFilter, { checkboxFilterType } from './Filters/CheckboxListFilter'
import DateRangeBetweenFilter from './Filters/DateRangeBetweenListFilter'

import {
    ScrollableTable,
    TableEl,
    TableRow,
    HeaderRow,
    Tbody,
    Thead,
    Header,
    HeaderAlignComponent,
    TableCell,
    TableLinkCell,
    TableSortIndicator,
    HeaderContentWrap,
} from './styles'
import { filterTypes } from './filterTypes'
import { sortTypes } from './sortTypes'
import { globalFilterBySearchText } from './globalFilterBySearchText'

export {
    TableRow,
    TableCell,
    CheckboxListFilter as CheckboxFilter,
    checkboxFilterType as checkboxListFilter,
    DateRangeBetweenFilter,
}

export function OptionalCell<D extends DataItem>({
    cell: { value },
}: CellProps<D>): React.ReactElement {
    return <Optional value={value} />
}

export function RouteLinkCell({
    children,
    ...linkProps
}: React.PropsWithChildren<LinkProps>): React.ReactElement {
    return <TableLinkCell {...linkProps}>{children}</TableLinkCell>
}

export type ColumnsFromData<T extends object[]> = Column<T[number]>[]

export type DataItem = object
type TableProps<D extends DataItem> = TableOptions<D> & {
    extraHooks?: ((...args: any[]) => void)[]
}

export function Table<D extends DataItem>(
    props: TableProps<D> & {
        scrollable?: boolean
        customHeight?: string | undefined
        pagination?: boolean
        onTableInstInit?(table: TableInstance<D>): void
        onAllRowsExpandedChange?(areAllRowsExpanded: boolean): void
    },
): React.ReactElement {
    // for most params, warn after 1 change
    useStableParamsWarning(props, ['columns', 'hasRowHover'], '<Table>', 1)
    // for data, warn after 10 changes
    useStableParamsWarning(props, ['data'], '<Table>', 10)

    const {
        scrollable = false,
        customHeight = '600px',
        searchText,
        extraHooks = [],
        emptyMessage = false,
        RowComponent = RowComponentDefault,
        onTableInstInit = noop,
        onAllRowsExpandedChange = noop,
        ...tableOptions
    } = props

    const tableInstance = useTable<D>(
        {
            filterTypes,
            globalFilter: globalFilterBySearchText,
            autoResetGlobalFilter: false,
            autoResetFilters: false,
            autoResetSortBy: false,
            autoResetExpanded: false,
            sortTypes,
            ...tableOptions,
        },
        ...[
            useFilters,
            useGlobalFilter,
            useSortBy,
            useExpanded,
            usePagination,
            useFlexLayout,
            ...extraHooks,
        ],
    )

    const { setGlobalFilter, isAllRowsExpanded } = tableInstance
    const { data } = tableOptions

    useEffect(() => {
        setGlobalFilter(searchText)
    }, [setGlobalFilter, searchText])

    useEffect(() => {
        onTableInstInit(tableInstance)
    }, [onTableInstInit, tableInstance])

    useEffect(() => {
        onAllRowsExpandedChange(isAllRowsExpanded)
    }, [isAllRowsExpanded, onAllRowsExpandedChange])

    if (data.length === 0 && emptyMessage) {
        return React.isValidElement(emptyMessage) ? emptyMessage : <Empty title={emptyMessage} />
    }

    return (
        <TableNode
            {...{
                tableInstance,
                RowComponent,
                scrollable,
                customHeight,
                pagination: Boolean(props?.pagination),
            }}
        />
    )
}

type TableNodeProps<D extends DataItem> = {
    tableInstance: TableInstance<D>
    RowComponent?: TableOptions<D>['RowComponent']
    pagination?: boolean
}

export function TableNode<D extends DataItem>({
    scrollable,
    customHeight,
    tableInstance,
    RowComponent = RowComponentDefault,
    pagination,
}: TableNodeProps<D> & {
    scrollable?: boolean
    customHeight?: string | undefined
}): React.ReactElement {
    const {
        rows,
        allColumns: { length: colSpan },
        headerGroups,
        getTableProps,
        getTableBodyProps,
        // For client-side pagination
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        page,
    } = tableInstance

    const rowList = pagination ? page : rows

    const table = (
        <>
            <TableEl {...getTableProps()}>
                <Thead>
                    {headerGroups.map(headerGroup => (
                        /* eslint-disable-next-line react/jsx-key */
                        <HeaderRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                /* eslint-disable-next-line react/jsx-key */
                                <Header {...column.getHeaderProps()}>
                                    <HeaderContentWrap
                                        isSorted={column.isSorted}
                                        isSortedDesc={column.isSortedDesc}
                                    >
                                        <HeaderAlignComponent
                                            {...(column.getSortByToggleProps
                                                ? column.getSortByToggleProps()
                                                : {})}
                                        >
                                            {(column.getSortByToggleProps as
                                                | typeof column.getSortByToggleProps
                                                | undefined) && column.canSort !== false ? (
                                                <TableSortIndicator {...{ column }} />
                                            ) : null}
                                            <span>{column.render('Header')}</span>
                                        </HeaderAlignComponent>
                                        {column.canFilter && column.Filter
                                            ? column.render('Filter')
                                            : null}
                                    </HeaderContentWrap>
                                </Header>
                            ))}
                        </HeaderRow>
                    ))}
                </Thead>

                <Tbody {...getTableBodyProps()}>
                    {rows.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={colSpan}>No matches</TableCell>
                        </TableRow>
                    ) : (
                        rowList.map((row, index) => {
                            tableInstance.prepareRow(row)
                            return (
                                // https://github.com/tannerlinsley/react-table/blob/4eb1720b99e669fa54beb149b353730ab47e1f68/src/makeDefaultPluginHooks.js#L36
                                <RowComponent
                                    key={`row_${row.id}`}
                                    {...{ ...tableInstance, row, index }}
                                />
                            )
                        })
                    )}
                </Tbody>
            </TableEl>
            {pagination ? (
                <TablePagination
                    {...{
                        canPreviousPage,
                        canNextPage,
                        pageCount,
                        gotoPage,
                        nextPage,
                        previousPage,
                        pageIndex,
                    }}
                />
            ) : null}
        </>
    )

    return scrollable ? <ScrollableTable maxHeight={customHeight}>{table}</ScrollableTable> : table
}

const defaultPropGetter = (): object => ({})

export const RowComponentDefault = <D extends DataItem>(
    tableInstanceWithRow: TableRowComponentProps<D>,
): React.ReactElement => {
    const {
        row,
        hasRowHover = true,
        onRowClick,
        highlightRow,
        scrollToRow,
        getRowProps = defaultPropGetter,
        getCellProps = defaultPropGetter,
    } = tableInstanceWithRow

    const hasHover: boolean = isFunction(hasRowHover)
        ? hasRowHover(tableInstanceWithRow)
        : hasRowHover
    const highlight = isFunction(highlightRow) ? highlightRow(tableInstanceWithRow) : false
    const scrollToSelf = isFunction(scrollToRow) ? scrollToRow(tableInstanceWithRow) : false

    return (
        <TableRow
            {...{ clickable: !!onRowClick }}
            {...row.getRowProps(getRowProps(row))}
            {...{ highlight, hasHover, scrollToSelf }}
            onClick={
                onRowClick
                    ? event =>
                          onRowClick({
                              ...tableInstanceWithRow,
                              event,
                          })
                    : undefined
            }
        >
            {row.cells.map(cell => {
                return (
                    /* eslint-disable-next-line react/jsx-key */
                    <TableCell {...cell.getCellProps(getCellProps(row, cell))}>
                        {cell.render('Cell')}
                    </TableCell>
                )
            })}
        </TableRow>
    )
}

export default Table
