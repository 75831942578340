import React, { FC } from 'react'
import { uniqueId } from 'lodash'
import { CellProps, Column, TableRowComponentProps, TableInstance } from 'react-table'
import styled from 'styled-components'

import { CastMember, DEIAttribute } from 'codecs/CastMember'

import { DiffableComboAttribute } from 'model/characters/Characters'
import { toDiffableCharacter, CharacterDiffSchema } from 'model/characters/Characters'

import Table, { CheckboxFilter } from 'shared/components/Table'
import * as s from 'shared/diff/schema'
import { DiffArrayPrimitive } from 'shared/diff/DiffArrayPrimitive'
import { Pill, PillsWrapper } from 'shared/components/Table/TablePills'
import { RowComponentDefault, TableCell, TableRow } from 'shared/components/Table'
import ToggleButton from 'shared/components/ToggleButton'
import Text from 'shared/components/Text'
import { useWIPDiff } from 'shared/resource/ResourceMachineProvider'
import UnavailableText from 'shared/components/UnavailableText'
import { hasAttr } from './utils'
import { portrayalPermanentFilterDiffCOlDef } from './constants'
import { CharactersDiffOps } from './types'

const initialTableState = {
    sortBy: [{ id: 'Talent' }, { id: 'Character' }],
    hiddenColumns: ['portrayalFilter'],
    filters: [{ id: 'portrayalFilter', value: null }],
}

type CastTableDiffProps = {
    searchText: string
    label?: string
    onTableInstInit(tbl: TableInstance<any>): void
    onAllRowsExpandedChange(areAllRowsExpanded: boolean): void
}

const columns: Column<CharactersDiffOps>[] = [
    {
        Header: 'Character',
        accessor: op => op.value.character?.characterName,
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    {
        Header: 'Talent',
        accessor: op => op.value.__meta__?.portrayedBy,
        Filter: CheckboxFilter,
        filter: 'checkbox',
        Cell: ({ row: { original: op } }: CellProps<CharactersDiffOps>) => {
            return (
                <PillsWrapper>
                    <Pill>{op.value.__meta__?.portrayedBy}</Pill>
                </PillsWrapper>
            )
        },
    },
    {
        Header: 'Role',
        accessor: op => op.value.role?.roleLabel,
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    {
        Header: 'Has Attributes',
        accessor: op => {
            const hasData = hasAttr(op.value as CastMember)
            return hasData ? 'Yes' : 'No'
        },
        Filter: props => <CheckboxFilter {...props} hasSearch={false} />,
        filter: 'checkbox',
    },
    portrayalPermanentFilterDiffCOlDef,
]

const ExpandButtonContainer = styled.div`
    text-align: center;
    padding: 0.25rem;
`

const OuterGridGrid = styled.div`
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-column-gap: 1rem;
    padding: 3rem 0.5rem 0;
`

const SingleAttrsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0.5rem;
    place-content: baseline;
`

const SimpleAttrContainer = styled.div`
    margin-bottom: 1rem;
`

const ComboAttrsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
`

const UNAVAILABLE_TEXT = 'Unavailable'

const CompoundAttrDiff: FC<{
    primaryLabel: string
    secondaryLabel: string
    diff: CharactersDiffOps
    id: DEIAttribute.Disability | DEIAttribute.MilitaryBody
}> = ({ primaryLabel, secondaryLabel, diff, id }) => {
    const attrDiffRow = s.isSchemaDiff(diff) ? (diff.ops.attributes as any).ops[id] : null
    const hasItems = !!attrDiffRow && attrDiffRow.ops.length

    const rows = !!attrDiffRow
        ? attrDiffRow.ops.map((op: s.DiffOpBase<DiffableComboAttribute>) => (
              <ComboAttrsGrid key={uniqueId()}>
                  <div>
                      {op.value.primary ? (
                          <Pill diffType={op.type}>{op.value.primary}</Pill>
                      ) : (
                          <UnavailableText>{UNAVAILABLE_TEXT}</UnavailableText>
                      )}
                  </div>
                  <div>
                      {op.value.secondary ? (
                          <Pill diffType={op.type}>{op.value.secondary}</Pill>
                      ) : (
                          <UnavailableText>{UNAVAILABLE_TEXT}</UnavailableText>
                      )}
                  </div>
              </ComboAttrsGrid>
          ))
        : null

    return hasItems ? (
        <div style={{ marginBottom: '0.5rem' }}>
            <ComboAttrsGrid>
                <Text size="5" weight="bold" as="h5">
                    {primaryLabel}
                </Text>
                <Text size="5" weight="bold" as="h5">
                    {secondaryLabel}
                </Text>
            </ComboAttrsGrid>
            {rows}
        </div>
    ) : null
}

const SimpleMultiFieldDiff: FC<{
    label: string
    diff: CharactersDiffOps
    id:
        | DEIAttribute.AgeGroups
        | DEIAttribute.Genders
        | DEIAttribute.PlaceOfOrigin
        | DEIAttribute.RaceOrEthnicity
        | DEIAttribute.SexualOrientation
        | DEIAttribute.Religion
}> = ({ label, diff, id }) => {
    const notEmpty = !!diff.value.attributes[id].length
    const values = diff.value.attributes[id]
    const attrDiff = s.isSchemaDiff(diff) ? (diff.ops.attributes as any).ops[id] : null

    return (
        <div>
            <Text size="5" weight="bold" as="h5">
                {label}
            </Text>
            {s.isSchemaDiff(diff) ? (
                !!attrDiff?.ops?.length ? (
                    <DiffArrayPrimitive diff={attrDiff} />
                ) : (
                    <UnavailableText>{UNAVAILABLE_TEXT}</UnavailableText>
                )
            ) : notEmpty ? (
                <div>
                    {values.map((name, i) => (
                        <Pill key={name + String(i)} diffType={diff.type}>
                            {name}
                        </Pill>
                    ))}
                </div>
            ) : (
                <UnavailableText>{UNAVAILABLE_TEXT}</UnavailableText>
            )}
        </div>
    )
}

export const AttributesDiff: FC<{ op: CharactersDiffOps }> = ({ op }) => {
    return (
        <OuterGridGrid>
            <SingleAttrsGrid>
                <SimpleAttrContainer>
                    <SimpleMultiFieldDiff diff={op} label={'Genders'} id={DEIAttribute.Genders} />
                </SimpleAttrContainer>
                <SimpleAttrContainer>
                    <SimpleMultiFieldDiff
                        diff={op}
                        label={'Race/Ethnicity'}
                        id={DEIAttribute.RaceOrEthnicity}
                    />
                </SimpleAttrContainer>
                <SimpleMultiFieldDiff
                    diff={op}
                    label={'Sexual Orientation'}
                    id={DEIAttribute.SexualOrientation}
                />
                <SimpleMultiFieldDiff diff={op} label={'Religion'} id={DEIAttribute.Religion} />
                <SimpleAttrContainer>
                    <SimpleMultiFieldDiff diff={op} label={'Age'} id={DEIAttribute.AgeGroups} />
                </SimpleAttrContainer>

                <SimpleAttrContainer>
                    <SimpleMultiFieldDiff
                        diff={op}
                        label={'Place of Origin'}
                        id={DEIAttribute.PlaceOfOrigin}
                    />
                </SimpleAttrContainer>
            </SingleAttrsGrid>
            <div>
                <CompoundAttrDiff
                    diff={op}
                    primaryLabel={'Disabillity'}
                    secondaryLabel={'Disabillity State'}
                    id={DEIAttribute.Disability}
                />
                <CompoundAttrDiff
                    diff={op}
                    primaryLabel={'Military Body'}
                    secondaryLabel={'Military Status'}
                    id={DEIAttribute.MilitaryBody}
                />
            </div>
        </OuterGridGrid>
    )
}

export const CharactersDiff = (
    props: TableRowComponentProps<CharactersDiffOps>,
): React.ReactElement => {
    const {
        allColumns: { length: colSpan },
        row,
        row: { original: op },
    } = props

    return (
        <>
            <RowComponentDefault {...props} />

            <TableRow>
                <TableCell
                    removeBottomBorderRadius
                    removeTopBorderRadius
                    alignWithPreviousRow
                    colSpan={colSpan}
                    removePadding
                >
                    <ExpandButtonContainer>
                        <ToggleButton
                            onClick={() => row.toggleRowExpanded()}
                            toggled={row.isExpanded}
                        >
                            {row.isExpanded
                                ? 'Hide Character Attributes'
                                : 'Show Character Attributes'}
                        </ToggleButton>
                    </ExpandButtonContainer>
                </TableCell>
            </TableRow>

            {row.isExpanded && (
                <TableRow style={{ position: 'relative', top: '-0.75rem' }}>
                    <TableCell colSpan={props.columns.length}>
                        <AttributesDiff op={op} />
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

export const CastTableDiff: React.FC<CastTableDiffProps> = ({
    label = 'Cast',
    searchText,
    onTableInstInit,
    onAllRowsExpandedChange,
}) => {
    const { ops } = useWIPDiff(
        'characters',
        s.array(CharacterDiffSchema, 'portrayalId'),
        characters => characters.map(toDiffableCharacter),
    )

    const expanded = React.useMemo(() => {
        let expanded: Record<string, boolean> = {}
        const len = ops.length
        for (let i = 0; i < len; i++) {
            const op = ops[i]
            if (!s.isDiffOpSame(op)) {
                expanded[i] = true
            }
        }
        return expanded
    }, [ops])

    const initialState = React.useMemo(() => {
        return { ...initialTableState, expanded }
    }, [expanded])

    return (
        <Table<CharactersDiffOps>
            data={ops}
            columns={columns}
            initialState={initialState}
            RowComponent={CharactersDiff}
            hasRowHover={true}
            emptyMessage={`No ${label.toLocaleLowerCase()} added yet`}
            searchText={searchText}
            onTableInstInit={onTableInstInit}
            onAllRowsExpandedChange={onAllRowsExpandedChange}
        />
    )
}
