import React, { useMemo } from 'react'

import { seriesLink } from 'routing/routes'

import { SeasonJSONViewOmittedValues, Season } from 'model/Season'

import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import ResourceTabs from 'shared/components/Tabs/ResourceTabs'
import PageTitle from 'shared/components/PageTitle'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Cast from 'shared/components/Cast'
import { useResourceMachine } from 'shared/resource/ResourceMachineProvider'
import { JSONViewer } from 'shared/components/JSONViewer'
import WIPInfoAndAquire from 'shared/resource/WIPInfoAndAquire'
import Metadata from 'shared/components/Metadata'
import AllWorkflowControls from 'shared/components/Workflow/all-workflow-controls'
import { flags } from 'shared/flags'
import { useHasLibReadRole, useHasPortrayalsEditingRoles } from 'shared/hooks/useHasRole'

import MasterData from './MasterData'
import Episodes from './Episodes'
import { Associations } from 'shared/components/Associations'
import { useSeasonPageView } from 'shared/hooks/useSnowPlowPageView'

const SeasonNode = (): React.ReactElement => {
    const [state] = useResourceMachine<Season>()
    const {
        context: { resource },
    } = state

    useSeasonPageView(resource)

    const { publishErrorManager } = state.context

    const {
        seriesCwmClassTypeLabel,
        static: { seriesId, seriesTitle },
        seasonTitle,
        characters,
        episodes,
    } = resource
    const canEditPortrayals = useHasPortrayalsEditingRoles()
    const hasViewAssociationsPermission = useHasLibReadRole()

    const tabsToDisplay = useMemo(() => {
        const associationTab = {
            id: 'Associations',
            panel: <Associations resourceId={resource.seasonId} />,
        }
        const debugTab = {
            id: 'JSON',
            panel: <JSONViewer json={resource} omit={SeasonJSONViewOmittedValues} />,
        }
        const primaryTabs = [
            {
                id: 'Primary Metadata',
                panel: <MasterData />,
            },
            {
                id: 'Descriptors',
                label: <WIPInfoAndAquire title="Descriptors" dataType="metadata" />,
                panel: <Metadata />,
                hasErrors: publishErrorManager.hasComponentErrors('metadata'),
            },
            {
                id: 'Episodes',
                panel: <Episodes data={episodes} />,
            },
            {
                id: 'Portrayals',
                panel: <Cast label="Portrayals" data={characters} />,
                label: canEditPortrayals ? (
                    <WIPInfoAndAquire title="Portrayals" dataType="characters" />
                ) : undefined,
            },
        ]

        return hasViewAssociationsPermission
            ? [...primaryTabs, associationTab, debugTab]
            : [...primaryTabs, debugTab]
    }, [
        publishErrorManager,
        canEditPortrayals,
        characters,
        hasViewAssociationsPermission,
        resource,
        episodes,
    ])

    return (
        <Container px="3" pb="3" pt="1" flexDirection="column">
            <Breadcrumbs
                items={[
                    { title: seriesCwmClassTypeLabel },
                    { title: seriesTitle, link: seriesLink({ seriesId }) },
                    { title: 'Season' },
                ]}
            />
            <PageTitle title={seasonTitle ?? 'Unknown season'} />
            <Container flexDirection="row" justifyContent="space-between" alignItems="center">
                <Text size="1" as="h2">
                    {seasonTitle}
                </Text>

                {flags.workflows && <AllWorkflowControls />}
            </Container>
            <ResourceTabs tabs={tabsToDisplay} />
        </Container>
    )
}

export default SeasonNode
