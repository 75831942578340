import { rest } from 'msw'
import config from 'shared/config'
import * as cwm from '@genome-web-forms/common/model/CWMClassType'

// TODO: Replace with real endpoints
const mock = [
    rest.get(`${config.urlTRS}/queries/taxonomy/types`, (_req, res, ctx) => {
        return res(ctx.json(title_types))
    }),
]

export default mock

export const title_types = [
    {
        label: 'Non-Serial',
        options: [
            { value: cwm.CWM_CLASS_TYPE_FEATURE_FILM, label: 'Feature' },
            { value: cwm.CWM_CLASS_TYPE_SPECIAL, label: 'Special' },
            { value: cwm.CWM_CLASS_TYPE_SHORT, label: 'Short' },
        ],
    },
    {
        label: 'Serial',
        options: [
            { value: cwm.CWM_CLASS_TYPE_MINISERIES, label: 'Miniseries' },
            { value: cwm.CWM_CLASS_TYPE_SERIES, label: 'Series' },
            { value: cwm.CWM_CLASS_TYPE_EPISODE, label: 'Episode' },
            { value: cwm.CWM_CLASS_TYPE_SEASON_OF_SERIES, label: 'Season' },
            { value: cwm.CWM_CLASS_TYPE_NEWS_PROGRAM, label: 'News Program' },
            { value: cwm.CWM_CLASS_TYPE_TALK_SHOW, label: 'Talk Show' },
        ],
    },
    {
        label: 'Pilot',
        options: [
            { value: cwm.CWM_CLASS_TYPE_PRE_AIR_EPISODE, label: 'Pre-air Episode' },
            { value: cwm.CWM_CLASS_TYPE_PILOT_SERIES, label: 'Pilot' },
        ],
    },
]
