import React from 'react'

import Container from 'shared/components/Container'
import Text from 'shared/components/Text'

import { usePureFn } from 'shared/hooks/usePureFn'
import { useTRSSimpleFind } from 'shared/hooks/useTRS'
import { FormikTRSSelect } from 'shared/components/TRS/TRSSelect'
import ErrorMessage from 'shared/components/ErrorMessage'

import { TaxonomyTerm } from 'codecs/TRS/Taxonomy'
import { TAssociationsTaxonomy } from '../../../model/Associations'

export type TaxonomyTermWithBroaderId = TaxonomyTerm & { broaderId?: string }

const SELECT_NAME = 'type.associations'

type AssociationTaxonomySelectProps = {
    type: string
    isMulti?: boolean
    isClearable?: boolean
}
const AssociationTaxonomySelect: React.FC<AssociationTaxonomySelectProps> = ({
    type,
    isMulti = true,
    isClearable = true,
}) => {
    return (
        <Container mb="1" flexDirection="column">
            <Text size="5" weight="bold" as="h5">
                Name
            </Text>

            <AssociationTaxonomySelectActual {...{ isMulti, isClearable, type }} />

            <ErrorMessage name={SELECT_NAME} />
        </Container>
    )
}

const AssociationTaxonomySelectActual: React.FC<Required<AssociationTaxonomySelectProps>> = ({
    type,
    isMulti,
    isClearable,
}) => {
    const { terms } = useTRSSimpleFind(type, true)

    const termToOption = React.useCallback(
        (term: TaxonomyTermWithBroaderId): TAssociationsTaxonomy => ({
            uri: term.id,
            label: term.label,
            broaderId: term.broaderId,
        }),
        [],
    )
    const getOptionValue = usePureFn((taxonomy: TAssociationsTaxonomy) => taxonomy.uri)
    const getOptionLabel = usePureFn((taxonomy: TAssociationsTaxonomy, term?: TaxonomyTerm) =>
        term ? term.label : taxonomy.label,
    )

    return (
        <FormikTRSSelect<TAssociationsTaxonomy>
            {...{
                name: SELECT_NAME,
                isMulti,
                terms,
                termToOption,
                getOptionValue,
                getOptionLabel,
                isDisabled: false,
                placeholder: 'Search',
                menuPlacement: 'auto',
                isClearable,
            }}
        />
    )
}

export default AssociationTaxonomySelect
