import * as cwm from '@genome-web-forms/common/model/CWMClassType'
import * as t from 'io-ts'
import { WIP, WIPCodec, WIPDataForType, WIPDataType } from 'model/WIP'
import { request } from '@genome-web-forms/common/api'
import { authGWF } from 'api/auth'
import config from 'shared/config'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { ResourceType } from 'api/fetch/fetchResource'
import { queryClient } from 'shared/queryClient'
import { Workflow } from '@genome-web-forms/server'
import {
    CREATIVE_WORK_QA_STARTED,
    CREATIVE_WORK_TAGGING_STARTED,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
} from '@genome-web-forms/server'
import { buildMergedPortrayalsData } from 'shared/components/Characters/utils'
import { CastMember } from 'codecs/CastMember'

const ResourceTypeToLockType: { [K in ResourceType]: string } = {
    feature: 'features',
    series: 'series',
    season: 'seasons',
    episode: 'episodes',
}

/**
 * react-query query identifier for wips
 */
const wipsIdentifier = (resourceId: string): [string, string] => ['wips', resourceId]

export async function fetchWIP({
    user,
    dataType,
    resourceId,
    resourceType,
}: {
    user: MyIDUser
    dataType: WIPDataType
    resourceId: string
    resourceType: ResourceType
}): Promise<WIP | undefined> {
    const wips: WIP[] = await queryClient.fetchQuery(wipsIdentifier(resourceId), () =>
        request(
            t.array(WIPCodec),
            authGWF(user, {
                url: `${config.urlGWF}/${ResourceTypeToLockType[resourceType]}/${encodeURIComponent(
                    resourceId,
                )}/lock`,
            }),
        ),
    )

    const result = wips.map(w => {
        if (w.dataType === 'characters') {
            const characters: CastMember[] = w.characters as CastMember[]
            w.characters = buildMergedPortrayalsData(characters)
        }
        return w
    })

    return result.find(wip => wip.dataType === dataType)
}

function getCurrentWorkflowFromStorage(): Workflow | null {
    const workflowString = localStorage.getItem('CURRENT_WORKFLOW') ?? 'null'
    const workflow: Workflow | null = JSON.parse(workflowString)
    return workflow
}

function getWorkflowStateTableUsingDataType(dataType: WIPDataType): string | undefined {
    const roleByState: { [x: string]: string } = {
        [CREATIVE_WORK_TAGGING_STARTED]: 'tagger',
        [CREATIVE_WORK_QA_STARTED]: 'qa',
    }

    const workflow = getCurrentWorkflowFromStorage()
    if (!workflow) return undefined

    const {
        state = undefined,
        workflowConfig: { task = undefined },
    } = workflow

    const workflowDataType =
        dataType === WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA
            ? dataType
            : WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS

    return task === workflowDataType && state ? roleByState?.[state] : undefined
}

export async function updateWIP<T extends WIPDataForType[WIPDataType]>({
    wip,
    user,
    data,
}: {
    wip: WIP
    user: MyIDUser
    data: T
}): Promise<WIP> {
    const tableName = getWorkflowStateTableUsingDataType(wip.dataType)

    const encodedData = WIPCodec.encode({
        ...wip,
        [wip.dataType]: data,
    })

    const res = await request(
        WIPCodec,
        authGWF(user, {
            url: `${config.urlGWF}/${wip.dataType}/${encodeURIComponent(wip.resourceId)}/wip`,
            params: { tableName },
            method: 'POST',
            data: encodedData,
        }),
    )

    queryClient.invalidateQueries(wipsIdentifier(wip.resourceId))

    return res
}

export async function aquireWIPLock({
    user,
    resourceId,
    resourceType,
    dataType,
}: {
    user: MyIDUser
    resourceId: string
    resourceType: ResourceType
    dataType: WIPDataType
}): Promise<WIP> {
    const tableName = getWorkflowStateTableUsingDataType(dataType)

    const res = await request(
        WIPCodec,
        authGWF(user, {
            method: 'POST',
            params: { tableName },
            url: `${config.urlGWF}/${ResourceTypeToLockType[resourceType]}/${encodeURIComponent(
                resourceId,
            )}/lock?tabName=${dataType}`,
        }),
    )

    queryClient.invalidateQueries(wipsIdentifier(resourceId))

    if (res.dataType === 'characters') {
        const characters: CastMember[] = res.characters as CastMember[]
        res.characters = buildMergedPortrayalsData(characters)
    }

    return res
}

export async function releaseWIPLock({ user, wip }: { user: MyIDUser; wip: WIP }): Promise<WIP> {
    const res = request(
        WIPCodec,
        authGWF(user, {
            method: 'DELETE',
            url: `${config.urlGWF}/${getLockType(wip)}/${encodeURIComponent(
                wip.resourceId,
            )}/lock?tabName=${wip.dataType}`,
        }),
    )
    queryClient.invalidateQueries(wipsIdentifier(wip.resourceId))

    return res
}

const getLockType = (wip: WIP): string => {
    switch (true) {
        case cwm.isFeature(wip.cwmClassType):
            return ResourceTypeToLockType.feature
        case cwm.isSeries(wip.cwmClassType):
            return ResourceTypeToLockType.series
        case cwm.isEpisode(wip.cwmClassType):
            return ResourceTypeToLockType.episode
        case cwm.isSeason(wip.cwmClassType):
            return ResourceTypeToLockType.season
    }
    throw new Error(`Cannot get lock type for cwm class type "${wip.cwmClassType}"`)
}
