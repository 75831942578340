import * as cwm from '@genome-web-forms/common/model/CWMClassType'
import { SupportedFacet, SupportedQueryParamFacet } from 'shared/search/useFacetOptions'
import warning from 'tiny-warning'

const pad = (str: string): string => {
    const NON_BREAKING_SPACE = '\u00a0'
    return NON_BREAKING_SPACE.repeat(4) + str
}

const NON_SERIAL = Object.keys(cwm.CWM_FEATURE_CLASSES).join(',')

const SERIAL = [
    cwm.CWM_CLASS_TYPE_MINISERIES,
    cwm.CWM_CLASS_TYPE_SERIES,
    cwm.CWM_CLASS_TYPE_NEWS_PROGRAM,
    cwm.CWM_CLASS_TYPE_TALK_SHOW,
    cwm.CWM_CLASS_TYPE_EPISODE,
].join(',')

const SERIES = [cwm.CWM_CLASS_TYPE_SERIES, cwm.CWM_CLASS_TYPE_MINISERIES].join(',')

const PILOT = [cwm.CWM_CLASS_TYPE_PRE_AIR_EPISODE, cwm.CWM_CLASS_TYPE_PILOT_SERIES].join(',')

export const options = [
    { value: undefined, label: 'All' },

    { value: NON_SERIAL, label: 'Non-Serial', disabled: true },
    { value: cwm.CWM_CLASS_TYPE_FEATURE_FILM, label: pad('Feature') },

    { value: SERIAL, label: 'Serial', disabled: true },
    { value: SERIES, label: pad('Series') },
    { value: cwm.CWM_CLASS_TYPE_SEASON_OF_SERIES, label: pad('Season') },
    { value: cwm.CWM_CLASS_TYPE_EPISODE, label: pad('Episode') },

    { value: PILOT, label: 'Pilots' },
]

export const groupedTypeOptions = [
    {
        label: 'Non-Serial',
        options: [{ value: cwm.CWM_CLASS_TYPE_FEATURE_FILM, label: 'Feature' }],
    },
    {
        label: 'Serial',
        options: [
            { value: SERIES, label: 'Series' },
            { value: cwm.CWM_CLASS_TYPE_SEASON_OF_SERIES, label: 'Season' },
            { value: cwm.CWM_CLASS_TYPE_EPISODE, label: 'Episode' },
        ],
    },
]

export type GroupedDateOptions = {
    label: string
    key: SupportedFacet | SupportedQueryParamFacet
    options: { value: any; label: string }[]
}

export const TitleSearchGroupedDateOptions: GroupedDateOptions[] = [
    {
        label: 'Earliest Release Date',
        key: 'data_disney_com_cpm.earliestReleaseDate',
        options: [{ value: null, label: 'Earliest Release Date' }],
    },
    {
        label: 'Published Date',
        key: 'data_disney_com_creativeWork.hasModifiedDateTime',
        options: [{ value: null, label: 'Published Date' }],
    },
]

export const WorkflowTitleSearchGroupedDateOptions: GroupedDateOptions[] = [
    ...TitleSearchGroupedDateOptions,
    {
        label: 'Due Date',
        key: 'dueDate',
        options: [{ value: null, label: 'Due Date' }],
    },
]

export const labelForValue = (value: string): string => {
    const label = options.find(o => o.value === value)?.label?.trim()
    warning(label, `Cannot find label for option value "${value}"`)
    return label ?? ''
}
