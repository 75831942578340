import React from 'react'
import { UserPermission, extractPermissions, MyIDUser } from '@genome-web-forms/common/auth'
import { AuthContext } from 'auth/Auth'

export const useHasPermission = (permission: UserPermission): boolean => {
    const auth = React.useContext(AuthContext)

    return React.useMemo(
        () => (auth.user ? hasPermission(auth.user, permission) : false),
        [auth.user, permission],
    )
}

export const hasPermission = (user: MyIDUser, permission: string): boolean => {
    return extractPermissions(user).includes(permission)
}

export type UserHasPermissionProps = {
    permission: UserPermission
}

export const UserHasPermission: React.FC<UserHasPermissionProps> = ({
    permission,
    children,
}): React.ReactElement | null => {
    return useHasPermission(permission) ? (children as React.ReactElement) : null
}

export default UserHasPermission
