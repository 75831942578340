import React, { useMemo } from 'react'

import { Feature, FeatureJSONViewOmittedValues } from 'model/Feature'

import Breadcrumbs from 'shared/components/Breadcrumbs'
import Cast from 'shared/components/Cast'
import Container from 'shared/components/Container'
import { JSONViewer } from 'shared/components/JSONViewer'
import Metadata from 'shared/components/Metadata'
import PageTitle from 'shared/components/PageTitle'
import PreviewControl from 'shared/components/PreviewControl'
import Relationships from 'shared/components/Relationships'
import RelationshipsDiff from 'shared/components/Relationships/RelationshipsDiff'
import SaveIndicator from 'shared/components/SaveIndicator'
import ResourceTabs from 'shared/components/Tabs/ResourceTabs'
import Text from 'shared/components/Text'
import AllWorkflowControls from 'shared/components/Workflow/all-workflow-controls'
import { flags } from 'shared/flags'
import {
    useIsSaving,
    usePreviewModeDiff,
    useResourceMachine,
} from 'shared/resource/ResourceMachineProvider'
import WIPInfoAndAquire from 'shared/resource/WIPInfoAndAquire'
import FeatureStorylinesDiff from './Storylines/FeatureStorylinesDiff'
import { useHasPortrayalsEditingRoles, useHasLibReadRole } from 'shared/hooks/useHasRole'

import MasterData from './MasterData'
import Storylines from './Storylines'
import { Associations } from 'shared/components/Associations'
import { useFeaturePageView } from 'shared/hooks/useSnowPlowPageView'

const FeatureNode = (): React.ReactElement => {
    const [state] = useResourceMachine<Feature>()
    const {
        context: { resource },
    } = state
    const canEditPortrayals = useHasPortrayalsEditingRoles()
    const hasViewAssociationsPermission = useHasLibReadRole()

    const { featureTitle, cwmClassTypeLabel, characters } = state.context.resource

    useFeaturePageView(resource)

    const { publishErrorManager } = state.context
    const previewModeDiff = usePreviewModeDiff()

    const tabsToDisplay = useMemo(() => {
        const associationTab = {
            id: 'Associations',
            panel: <Associations resourceId={resource.featureId} />,
        }
        const debugTab = {
            id: 'JSON',
            panel: <JSONViewer json={resource} omit={FeatureJSONViewOmittedValues} />,
        }

        const primaryTabs = [
            {
                id: 'Primary Metadata',
                panel: <MasterData />,
            },
            {
                id: 'Descriptors',
                label: <WIPInfoAndAquire title="Descriptors" dataType="metadata" />,
                panel: <Metadata />,
                hasErrors: publishErrorManager.hasComponentErrors('metadata'),
            },
            {
                id: 'Storylines',
                label: <WIPInfoAndAquire title="Storylines" dataType="storylines" />,
                panel: previewModeDiff ? <FeatureStorylinesDiff /> : <Storylines />,
                hasErrors: publishErrorManager.hasComponentErrors('storylines'),
            },
            {
                id: 'Relationships',
                label: <WIPInfoAndAquire title="Relationships" dataType="relationships" />,
                panel: previewModeDiff ? <RelationshipsDiff /> : <Relationships />,
                hasErrors: publishErrorManager.hasComponentErrors('relationships'),
            },
            {
                id: 'Portrayals',
                panel: <Cast label="Portrayals" data={characters} />,
                label: canEditPortrayals ? (
                    <WIPInfoAndAquire title="Portrayals" dataType="characters" />
                ) : undefined,
            },
        ]

        return hasViewAssociationsPermission
            ? [...primaryTabs, associationTab, debugTab]
            : [...primaryTabs, debugTab]
    }, [
        publishErrorManager,
        previewModeDiff,
        canEditPortrayals,
        characters,
        hasViewAssociationsPermission,
        resource,
    ])

    return (
        <Container px="3" pb="3" pt="1" flexDirection="column">
            <Breadcrumbs items={[{ title: cwmClassTypeLabel }]} />
            <PageTitle title={featureTitle} />
            {useIsSaving() && <SaveIndicator />}
            <Container flexDirection="row" justifyContent="space-between" alignItems="center">
                <Text size="1" as="h2">
                    {featureTitle}
                </Text>

                {flags.workflows && <AllWorkflowControls />}

                <PreviewControl />
            </Container>

            <ResourceTabs tabs={tabsToDisplay} />
        </Container>
    )
}

export default FeatureNode
